<template>
  <div class="relative mb-5">
    <div class="left-2 absolute flex items-center justify-center h-full">
      <Icon
        icon="heroicons-outline:search"
        class="w-6 h-6 text-gray-300"
      />
    </div>
    <input
      v-model="keywords"
      type="text"
      :placeholder="placeholder ? placeholder : $t('search_an_article')"
      class="w-full h-12 pl-10 border border-gray-300 rounded"
      @input="onKeywordChange"
    >
  </div>

  <div class="relative">
    <BaseLoadingCover
      v-model="loading"
      backdrop-class="bg-white opacity-50"
    />
    <template v-if="ready">
      <div v-if="articles.length">
        <div
          class="md:grid-cols-3 sm:grid-cols-2 grid gap-3"
        >
          <ArticleSelectorItem
            v-for="article in articles"
            :key="article.id"
            :article="article"
            :selected-id="selectedId"
            :button-text="buttonText"
            :selected-button-text="selectedButtonText"
            @select="selectArticle($event)"
          />
        </div>
        <BasePagination
          :model-value="page"
          :last-page="lastPage"
          class="mt-10"
          @model-value:update="changePage($event)"
        />
      </div>

      <div
        v-else
        class="py-6 text-center text-gray-500"
      >
        {{ $t('no_result') }}
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { getRoute } from "@/services/utils";
import { defineComponent } from "vue";
import { Article } from "@/models/Article";
import { debounce } from "lodash-es";
import ArticleSelectorItem from "./ArticleSelectorItem.vue";

export default defineComponent({
  components: { ArticleSelectorItem },
  props: {
    internal: {
      default: false,
      type: Boolean
    },
    type: {
      default: "",
      type: String
    },
    placeholder: {
      default: "",
      type: String
    },
    buttonText: {
      default: "",
      type: String
    },
    selectedButtonText: {
      default: "",
      type: String,
    },
    perPage: {
      default: 6,
      type: Number
    }
  },
  emits: ["select"],
  data() {
    return {
      ready: false,
      loading: false,
      page: 1,
      lastPage: 0,
      keywords: "",
      selectedId: null as string | null,
      articles: [] as Article[]
    };
  },
  computed: {
    onKeywordChange() {
      return debounce(() => {
        this.page = 1;
        this.fetch();
      }, 300);
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$api.get(getRoute("api/content/articles"), {
        params: {
          search: this.keywords,
          is_internal: this.internal,
          type: this.type,
          page: this.page,
          per_page: this.perPage,
        }
      })
        .then(response => {
          this.ready = true;
          this.articles = response.data.data;
          this.page = response.data.meta.current_page;
          this.lastPage = response.data.meta.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePage(page: number) {
      this.page = page;
      this.fetch();
    },
    selectArticle(article: Article) {
      this.$emit("select", article);
      this.selectedId = article.id;
    }
  }
});
</script>
