<template>
  <BaseModal
    :close-on-backdrop="closeOnBackdrop"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #default="{close}">
      <div class="sm:max-w-lg sm:rounded-xl z-[1] sm:shadow-xl pb-safe rounded-t-xl relative block w-full mx-auto bg-white">
        <img
          src="@/assets/img/logo/logo.svg"
          alt="Effet Tandem"
          class="-top-6 left-1/2 sm:-top-8 sm:w-14 absolute z-10 w-10 mx-auto mb-4 -translate-x-1/2"
        >

        <button
          v-if="closeButton"
          type="button"
          class="top-2 hover:bg-gray-100 hover:text-gray-900 right-2 transition-color absolute z-10 p-2 text-gray-700 duration-150 bg-transparent border-none rounded-full appearance-none"
          @click="close"
        >
          <Icon
            class="w-8 h-8"
            icon="mdi:close"
          />
        </button>

        <div class="sm:p-8 sm:pt-14 relative flex flex-col p-6 pt-10 overflow-hidden text-left">
          <h2
            class="font-display sm:mb-8 sm:text-3xl mb-7 relative text-2xl font-semibold leading-6 text-center text-gray-700"
          >
            {{ title }}
          </h2>

          <div
            v-if="videoUrl"
            class="sm:mb-8 mb-5"
          >
            <ResponsiveIframe :url="videoUrl" />
          </div>

          <slot :close="close" />

          <slot
            name="confirm"
            :close="close"
          >
            <div class="sm:mt-8 mt-10">
              <BaseButton
                type="button"
                class="btn-xl btn-primary w-full"
                @click="confirm(close)"
              >
                {{ normalizedText }}
              </BaseButton>
            </div>
          </slot>
        </div>
        <div
          v-if="loading"
          class="rounded-3xl absolute inset-0 w-full h-full overflow-hidden"
        >
          <BaseLoadingCover
            :model-value="true"
            backdrop-class="opacity-60 bg-white"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean
    },
    videoUrl: {
      default: "",
      type: String
    },
    title: {
      required: true,
      type: String
    },
    buttonText: {
      default: "",
      type: String
    },
    closeOnConfirm: {
      default: true,
      type: Boolean
    },
    closeOnBackdrop: {
      default: true,
      type: Boolean
    },
    closeButton: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  emits: ["update:modelValue", "confirm"],
  computed: {
    normalizedText () : string {
      if (this.buttonText) {
        return this.buttonText;
      }
      return this.$t("ok");
    }
  },
  methods: {
    confirm(close: () => void) {
      if (this.closeOnConfirm) {
        close();
      }
      this.$emit("confirm");
    }
  }
});

</script>
