<template>
  <div>
    <div v-if="favorites.length > 0">
      <BaseContainer class="pt-safe">
        <BaseTitle
          :previous="false"
          :title="$t('page.favorites.title')"
          :subtitle="$t('page.favorites.subtitle')"
        />
        <div class="pb-10">
          <div class="flex flex-wrap -m-2">
            <div
              v-for="member in favorites"
              :key="member.id"
              :class="itemWidthClasses"
            >
              <MemberItem
                :member="member"
                :anonymous-mode="$store.getters.anonymousMode"
              />
            </div>
          </div>
        </div>
      </BaseContainer>

      <BaseFooter />
    </div>

    <transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="!loading && firstLoad && favorites.length == 0">
        <div class="sm:py-20 flex items-center justify-center px-6 py-16">
          <div class="max-w-lg text-center">
            <img
              src="@/assets/img/tree/tree-primary-gradient.svg"
              alt="Tandem tree"
              class="w-64 mx-auto mb-8"
            >

            <h2 class="font-display mb-3 text-3xl font-semibold">
              {{ $t('page.favorites.empty_state.title') }}
            </h2>
            <p class="text-gray-700">
              {{ $t('page.favorites.empty_state.subtitle') }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <BaseLoadingCover
      v-if="loading"
      :model-value="loading"
      icon="spinner"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Member } from "@/models/Member";
import { SUSPENDED, UNSUBSCRIBED } from "@/services/errorCodes";
import { get } from "lodash";

export default defineComponent({
  data () {
    return {
      firstLoad: false,
      loading: true,
      favorites: [] as Member[],
      itemWidthClasses: "w-full xs:w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 p-2"
    };
  },
  computed: {
    showEmptyState () : boolean {
      return !this.loading && this.favorites.length == 0;
    }
  },
  created () {
    this.loading = true;
    this.$api.get("members?filter[favorite]=true")
      .then(response => {
        this.firstLoad = true;
        this.favorites = response.data.data;
      })
      .catch(error => {
        const code = get(error, "response.data.code");
        if ([UNSUBSCRIBED, SUSPENDED].includes(code)) {
          this.$store.dispatch("fetchUser");
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
</script>
