<template>
  <slot
    name="default"
    :toggle-recording="toggleRecording"
    :initializing="initializing"
    :recording="recording"
    :loading="loading"
    :milliseconds="milliseconds"
    :seconds="seconds"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VoiceRecorder, RecordingData } from "capacitor-voice-recorder";
import { base64ToBlob } from "@/services/utils";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

const MAX_SIZE = 1024 * 1024 * 10; // 10mb

export default defineComponent({
  props: {
    max: {
      default: 30,
      type: Number
    }
  },
  emits: ["success", "recorded"],
  data () {
    return {
      initializing: false,
      recording: false,
      loading: false,
      canDeviceVoiceRecord: false,
      hasAudioPermission: false,
      milliseconds: 0,
      setIntervalId: null as any,
    };
  },
  computed: {
    seconds() :number{
      return Math.floor(this.milliseconds / 1000);
    }
  },
  created () {
    VoiceRecorder.canDeviceVoiceRecord().then((canRecord) => {
      this.canDeviceVoiceRecord = canRecord.value;
    });
  },
  async beforeUnmount() {
    if (await (await VoiceRecorder.getCurrentStatus()).status == "RECORDING") {
      await VoiceRecorder.stopRecording();
    }
    this.stopInterval();
  },
  methods: {
    async toggleRecording() {
      if (this.recording) {
        this.save();
      } else {
        this.record();
      }
    },
    async record () {

      if (!this.canDeviceVoiceRecord) {
        return;
      }

      if (this.recording) {
        return;
      }

      this.initializing = true;

      const permissionRequestResult = await VoiceRecorder.requestAudioRecordingPermission();
      this.hasAudioPermission = permissionRequestResult.value;

      if (!this.hasAudioPermission) {
        this.initializing = false;
        return;
      }

      await VoiceRecorder.startRecording();

      this.initializing = false;
      this.recording = true;
      this.milliseconds = 0;

      this.setIntervalId = setInterval(() => {
        this.milliseconds += 10;

        if (this.milliseconds >= (this.max * 1000)) {
          this.stopInterval();
          this.save();
        }
      }, 10);
    },
    async save () {

      if (this.loading) {
        return;
      }

      this.loading = true;
      this.recording = false;
      const data = await VoiceRecorder.stopRecording();
      this.stopInterval();
      this.$emit("recorded", data.value.recordDataBase64);
      await this.send(data);
      this.loading = false;
    },
    stopInterval() {
      if (this.setIntervalId) {
        this.milliseconds = 0;
        clearInterval(this.setIntervalId);
        this.setIntervalId = null;
      }
    },
    async send(data: RecordingData) {
      this.loading = true;

      try {
        let blob = await base64ToBlob(data.value.recordDataBase64);

        if (blob.size > MAX_SIZE) {
          this.$notify({
            type: Type.danger,
            message: this.$t("the_file_size_must_not_exceed_x", {x: this.$display.fileSize(MAX_SIZE)})
          });
          return;
        }

        const response = await this.$vapor.store(blob);

        this.$emit("success", response.key);

      } finally {
        this.loading = false;
      }
    }
  },
});
</script>
