<template>
  <div class="sm:flex-row sm:space-x-2 sm:items-center flex flex-col items-stretch justify-between">
    <div
      class="sm:order-1 order-2"
    >
      <PreviousButton
        v-if="previousButton"
        @click="$emit('previous')"
      />
    </div>
    <div class="sm:order-2 sm:flex-row sm:space-x-2 sm:mb-0 flex flex-col order-1 mb-3">
      <SkipButton
        v-if="skippable"
        :loading="loading"
        class="sm:order-1 order-2"
        @click="$emit('skip')"
      />
      <NextButton
        :id="`onboarding-button-next-${step.path}`"
        :loading="loading"
        :disabled="nextDisabled"
        type="submit"
        class="sm:order-2 order-1"
        @click="$emit('next')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import PreviousButton from "./PreviousButton.vue";
import NextButton from "./NextButton.vue";
import SkipButton from "./SkipButton.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: {
    PreviousButton,
    NextButton,
    SkipButton
  },
  props: {
    previousButton: {
      default: true,
      type: Boolean
    },
    nextDisabled: {
      default: false,
      type: Boolean
    },
    skippable: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  emits: ["previous", "next", "skip"],
  setup() {
    const step = inject("currentStep") as Step;
    return {
      step,
    };
  }
});
</script>
