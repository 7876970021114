<template>
  <BasePanel
    :title="$t('page.settings.profile.questions.title')"
    :description="$t('page.settings.profile.questions.subtitle')"
    :boxed="false"
  >
    <QuestionItems
      :questions="otherQuestions"
      :answers="answers"
    />
  </BasePanel>

  <BasePanel
    :title="$t('page.settings.profile.long_questions.title')"
    :description="$t('page.settings.profile.long_questions.subtitle')"
    :boxed="false"
  >
    <QuestionItems
      :questions="longQuestions"
      :answers="answers"
      :max="2"
    />
  </BasePanel>

  <BasePanel
    :title="$t('page.settings.profile.short_questions.title')"
    :description="$t('page.settings.profile.short_questions.subtitle')"
    :boxed="false"
  >
    <QuestionItems
      :questions="shortQuestions"
      :answers="answers"
    />
  </BasePanel>
</template>

<script lang="ts">
import { Question } from "@/models/Question";
import { defineComponent } from "vue";
import { AnswerList } from "@/store";

export default defineComponent({
  data () {
    return {
      ready: false,
      loading: false,
    };
  },
  computed: {
    answers () : AnswerList {
      return this.$store.getters["answers"] as AnswerList;
    },
    shortQuestions () : Question[] {
      return this.$store.state.questions.items
        .filter(q => q.type.name == "ShortQuestionType");
    },
    longQuestions () : Question[] {
      return this.$store.state.questions.items
        .filter(q => q.type.name == "LongQuestionType");
    },
    otherQuestions() : Question[] {
      let ids = this.shortQuestions.map(q => q.id);
      ids.push(...this.longQuestions.map(q => q.id));
      return this.$store.state.questions.items
        .filter(q => !ids.includes(q.id))
        .filter(q => q.dependencies && q.dependencies.length == 0);
    }
  }
});
</script>
