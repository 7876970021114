<template>
  <div class="w-full max-w-xl pt-10 mx-auto">
    <div class="sm:flex items-center justify-between text-sm">
      <div class="grow flex items-center space-x-2">
        <div>
          <a
            :href="$websiteUrl('/' + $i18n.locale)"
            class="flex items-center text-gray-600 underline"
          >
            Effet Tandem
          </a>
        </div>
        <div class="text-gray-400">
          •
        </div>
        <div>
          <a
            :href="$websiteUrl($t('urls.contact'))"
            class="flex items-center text-gray-600 underline"
          >
            {{ $t('contact_us') }}
          </a>
        </div>
      </div>
      <div class="grow sm:border-none sm:justify-end sm:pt-0 sm:mt-0 flex items-center justify-between pt-5 mt-5 space-x-4 border-t border-gray-200">
        <div
          v-if="$auth.check()"
          class="flex items-center"
        >
          <Icon
            icon="heroicons-outline:logout"
            class="w-4 h-4 mr-1 text-gray-600"
          />
          <button
            class="flex items-center text-gray-600 border-none outline-none appearance-none"
            @click="$auth.logout()"
          >
            {{ $t('logout') }}
          </button>
        </div>
        <div
          v-else
          class="flex items-center"
        >
          <Icon
            icon="heroicons-outline:login"
            class="w-4 h-4 mr-1 text-gray-600"
          />
          <router-link
            class="flex items-center text-gray-600 border-none outline-none appearance-none"
            to="/login"
          >
            {{ $t('sign_in') }}
          </router-link>
        </div>
        <div class="flex items-center">
          <Icon
            icon="heroicons-outline:globe-alt"
            class="w-4 h-4 mr-1 text-gray-600"
          />

          <button
            v-for="locale in locales"
            v-show="locale.code != $i18n.locale"
            :key="locale.code"
            class="text-gray-600 bg-transparent outline-none appearance-none"
            @click="updateLanguage(locale.code)"
          >
            {{ locale.label }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <BaseLoadingCover v-model="loading" />
  </teleport>
</template>

<script lang="ts">
import { locales } from "@/plugins/i18n";
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      loading: false,
      locales: locales,
    };
  },
  methods: {
    async updateLanguage(locale: string) {
      this.loading = true;
      try {
        await this.$store.dispatch("updateLanguage", locale);
      } finally {
        this.loading = false;
      }
    }
  }
});
</script>
