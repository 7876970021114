/**
 * Status Bar
 * https://capacitorjs.com/docs/apis/status-bar
 */

import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

export default (): void => {
  if (Capacitor.isNativePlatform()) {
    StatusBar.setStyle({ style: Style.Light });
    if (Capacitor.getPlatform() == "android") {
      StatusBar.setBackgroundColor({ color: "#FFFFFF" });
    }
  }
};
