<template>
  <button
    type="button"
    aria-label="Remove filter"
    class="focus:outline-none focus:text-steel-300 hover:text-steel-700 text-steel-700 flex items-center h-full py-1 pl-2 pr-3 transition-colors duration-100"
    @click="$emit('click')"
  >
    <svg
      stroke="currentColor"
      fill="none"
      viewBox="0 0 8 8"
      class="w-2 h-2"
    ><path
      stroke-linecap="round"
      stroke-width="1.5"
      d="M1 1l6 6m0-6L1 7"
    />
    </svg>
  </button>
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"]
});
</script>
