<template>
  <div
    class="md:w-[600px] flex shrink-0 px-2 first:pl-3 last:pr-3 pt-[9px]"
  >
    <button
      class="btn btn-steel-outline md:whitespace-normal md:p-4 flex items-start py-2 overflow-visible text-sm leading-tight text-left text-gray-800 whitespace-pre"
      type="button"
      @click="$emit('click')"
    >
      <slot />

      <div class="absolute top-0 left-0">
        <div class="bg-steel-200 -top-2.5 -left-2.5 border-steel-400 relative flex items-center justify-center w-6 h-6 border rounded-full">
          <Icon
            icon="heroicons-solid:paper-airplane"
            class="text-steel-700 w-3 h-3"
          />
        </div>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
});
</script>
