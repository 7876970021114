<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="p-8">
      <h2 class="mb-3 text-lg font-semibold leading-5 text-gray-900">
        {{ $t('page.profile.block_modal.title', {x: firstName}) }}
      </h2>

      <p class="mb-6 leading-snug text-gray-700">
        {{ $t('page.profile.block_modal.description') }}
      </p>

      <div>
        <VForm
          :data="form"
          method="post"
          url="member-blocks"
          loading-mask-class="bg-white"
          @success="onSuccess"
        >
          <div class="xs:flex xs:space-x-3 xs:space-y-0 space-y-3">
            <button
              type="submit"
              class="btn btn-primary btn-lg xs:w-auto w-full"
            >
              {{ $t('block') }}
            </button>

            <button
              type="button"
              class="btn btn-lg xs:w-auto w-full"
              @click="close()"
            >
              {{ $t('cancel') }}
            </button>
          </div>
        </VForm>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    memberId: {
      required: true,
      type: String
    },
    firstName: {
      required: true,
      type: String
    }
  },
  emits: ["update:modelValue", "blocked"],
  data () {
    return {
      form: {
        blocked_member_id: this.memberId,
      }
    };
  },
  methods: {
    onSuccess (response: any) {
      this.close();
      this.$emit("blocked", response.data.member_block.id ?? null);
    },
    close () {
      this.$emit("update:modelValue", false);
    }
  }
});
</script>
