<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="sm:p-7 relative p-6">
      <h2 class="mb-5 text-xl font-semibold">
        {{ $t('share_by_email') }}
      </h2>

      <div class="mb-5">
        <label
          for="body"
          class="block mb-2 text-gray-600"
        >
          {{ $t('emails') }}
        </label>
        <FreeTagInput
          v-model="mail.emails"
          :placeholder="$t('type_an_email')"
          input-type="email"
          required
        />
      </div>

      <div class="mb-5">
        <label
          for="subject"
          class="block mb-2 text-gray-600"
        >
          {{ $t('subject') }}
        </label>
        <input
          v-model="mail.subject"
          type="text"
          required
          name="subject"
          class="w-full border-gray-300 rounded"
        >
      </div>

      <div>
        <label
          for="body"
          class="block mb-2 text-gray-600"
        >
          {{ $t('message') }}
        </label>
        <textarea
          id="body"
          v-model="mail.body"
          required
          name="body"
          cols="40"
          rows="6"
          class="h-72 block w-full border-gray-300 rounded"
        />
      </div>

      <a
        :href="mailToLink"
        class="btn btn-primary btn-xl mt-5"
      >
        {{ $t('send_email') }}
      </a>
      <button
        type="button"
        class="btn btn-white btn-xl"
        @click="$emit('update:modelValue', false)"
      >
        {{ $t('cancel') }}
      </button>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FreeTagInput from "@/plugins/form/components/elements/FreeTagInput.vue";

export default defineComponent({
  components: { FreeTagInput },
  props: {
    subject: {
      default: "",
      type: String
    },
    body: {
      default: "",
      type: String
    },
    modelValue: {
      required: true,
      type: Boolean
    }
  },
  emits: ["update:modelValue"],
  data () {
    return {
      mail: {
        subject: this.subject,
        body: this.body,
        emails: [],
      }
    };
  },
  computed: {
    mailToLink() : string {
      let link = `mailto:${this.mail.emails.join(",")}?subject=${encodeURIComponent(this.mail.subject)}}`;
      if (this.body) {
        link += `&body=${encodeURIComponent(this.mail.body)}`;
      }
      return link;
    }
  }
});
</script>
