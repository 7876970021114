<template>
  <div class="bg-gray-50 pt-safe">
    <div class="pb-16">
      <BaseContainer
        size="4xl"
      >
        <h2 class="mb-4 text-lg font-semibold">
          {{ $t('page.ambassador.media_kit.promotional_videos') }}
        </h2>

        <ArticleSelector
          :internal="true"
          :placeholder="$t('search')"
          type="video"
          :per-page="3"
          :button-text="$t('copy_link')"
          :selected-button-text="$t('copied')"
          @select="copyLink"
        />
      </BaseContainer>
    </div>

    <div class="pb-16">
      <BaseContainer
        size="4xl"
      >
        <h2 class="mb-4 text-lg font-semibold">
          {{ $t('page.ambassador.media_kit.expert_article') }}
        </h2>

        <ArticleSelector
          :internal="false"
          :placeholder="$t('search')"
          :button-text="$t('copy_link')"
          :selected-button-text="$t('copied')"
          @select="copyLink"
        />
      </BaseContainer>
    </div>
    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { Article } from "@/models/Article";
import { Clipboard } from "@capacitor/clipboard";
import { defineComponent } from "vue";
import ArticleSelector from "./components/ArticleSelector.vue";
export default defineComponent({
  components: {
    ArticleSelector
  },
  methods: {
    copyLink(article: Article) {
      Clipboard.write({
        string: `${article.website_url}?reference=${this.$store.state?.user?.ambassador?.code}`
      });
    },
  }
});
</script>
