<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.visibility.visibility.title')"
        :subtitle="$t('page.settings.menu.visibility.visibility.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
      paddingless-mobile
    >
      <RadioGroup
        v-model="form.visibility"
        as="div"
        @update:model-value="onUpdate"
      >
        <RadioGroupLabel class="sr-only">
          {{ $t('visibility') }}
        </RadioGroupLabel>
        <BaseCard>
          <BaseCardRow
            v-for="option in options"
            :key="option.value"
          >
            <RadioGroupOption
              v-slot="{ checked }"
              as="div"
              :value="option.value"
              class="focus:outline-none flex cursor-pointer"
            >
              <div class="pt-1 pr-4">
                <div
                  class="w-4 h-4 rounded-full"
                  :class="{
                    'border-primary-600 border-[5px]': checked,
                    'border-gray-400 border': !checked,
                  }"
                />
              </div>
              <div>
                <RadioGroupLabel
                  as="span"
                  class="font-semibold"
                >
                  {{ option.title }}
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="p"
                  class="leading-tight text-gray-500"
                >
                  {{ option.description }}
                </RadioGroupDescription>
              </div>
            </RadioGroupOption>
          </BaseCardRow>
        </BaseCard>
      </RadioGroup>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { Account, Visibility } from "@/models/Account";
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption, RadioGroupDescription } from "@headlessui/vue";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

export default defineComponent({
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    RadioGroupDescription,
  },
  data() {
    return {
      loading: false,
      form: {
        visibility: this.$store.state.user?.member?.visibility ?? Visibility.private,
      },
      options: [
        {value: Visibility.private, title: this.$t("_visiblity.private.title"), description: this.$t("_visiblity.private.description")},
        {value: Visibility.public, title: this.$t("_visiblity.public.title"), description: this.$t("_visiblity.public.description")}
      ]
    };
  },
  methods: {
    onUpdate() {
      if (this.loading) {
        return;
      }
      if (!this.$store.state.user?.member) {
        return;
      }
      this.loading = true;
      const oldVisibility = this.$store.state.user.member.visibility;
      this.$api.patch("account/member/visibility", this.form)
        .then(response => {

          if (response.data.message) {
            this.$notify({
              message: response.data.message as string,
              type: Type.success,
            });
          }

          const user = cloneDeep(this.$store.state.user) as Account;
          if (!user.member) {
            return;
          }
          user.member.visibility = this.form.visibility;
          this.$store.commit("setUser", user);
        })
        .catch(() => {
          this.form.visibility = oldVisibility;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
