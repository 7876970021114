<template>
  <div class="border-t-primary-500 bg-white border-t-4 border-b border-gray-200 shadow">
    <BaseContainer>
      <div class="flex items-center justify-between h-16 space-x-4">
        <a
          :href="$websiteUrl('/')"
          class="block h-10"
        >
          <img
            src="@/assets/img/logo/logo-name-gray.svg"
            class="xs:block hidden h-full"
          >
          <img
            src="@/assets/img/logo/logo.svg"
            class="xs:hidden block h-full"
          >
        </a>

        <div class="shrink-0 flex">
          <router-link
            class="btn btn-primary"
            to="/onboarding"
          >
            {{ $t('join_effet_tandem') }}
          </router-link>
        </div>
      </div>
    </BaseContainer>
  </div>

  <MemberProfile
    v-if="member"
    class="md:pt-12"
    :member="member"
    :show-interactions="false"
    :show-fake-interactions="true"
    :show-previous="false"
  />

  <div
    v-if="notFound"
    class="bg-primary-50 sm:pt-20 h-screen pt-6 text-center"
  >
    <div class=" relative">
      <img
        src="@/assets/img/tree/tree-primary-200.svg"
        alt="tree"
        class="w-96 left-1/2 absolute top-0 -translate-x-1/2 opacity-75"
      >
      <div class="pt-52 relative">
        <h1 class="text-primary-600 text-6xl font-bold">
          404
        </h1>
        <p class="text-primary-900 text-lg">
          {{ $t('page.not_found.title') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { isString } from "lodash";
import { defineComponent, ref, Ref } from "vue";

import MemberProfile from "../member/profile/MemberProfile.vue";

import { computed } from "vue";

import i18n from "@/plugins/i18n";

import { useHead } from "@vueuse/head";

export default defineComponent({
  components: {
    MemberProfile
  },
  emits: ["loaded"],
  setup() {
    const member = ref(null) as Ref<Member|null>;
    const notFound = ref(false) as Ref<boolean>;

    useHead({
      title: computed(
        () => {
          return member.value?.user?.gender == "male" || member.value?.user?.gender == "non_binary" ?
            i18n.global.t("share_member_header_m") : i18n.global.t("share_member_header_f");
        }
      ),
      meta: [
        {
          name: "description",
          content: computed(() => i18n.global.t("meta_description")),
        },
      ]
    });

    return {
      member: member,
      notFound: notFound
    };
  },
  async created () {

    if (isString(this.$route.query.locale)) {
      this.$i18n.locale = this.$route.query.locale;
    }

    await this.$store.dispatch("question/fetch");

    this.$api.get("share/member/" + this.$route.params.id)
      .then(response => {
        this.member = response.data.data;
      })
      .catch((error : any) => {
        this.notFound = true;
      })
      .finally(() => {
        this.$emit("loaded");
      });
  }
});
</script>
