import router from "@/router";
import { api } from "@/services/api";
import { Capacitor } from "@capacitor/core";
import { Badge } from "@robingenz/capacitor-badge";

type UnseenConversations = {
  [id: string]: {
    id: string,
    seen: boolean,
    priority: string,
    unseen_flower: boolean,
  }
};

export interface ConversationState {
  unseenConversations: UnseenConversations,
}

export const defaultConversationState = {
  unseenConversations: {},
} as ConversationState;

export const conversationModule = {
  namespaced: true,
  state() {
    return defaultConversationState;
  },
  mutations: {
    setUnseenConversations(state: ConversationState, conversations: UnseenConversations) {
      state.unseenConversations = conversations;
    },
    setSeen(state: ConversationState, conversationId: string) {
      if (state.unseenConversations[conversationId] !== undefined) {
        state.unseenConversations[conversationId].seen = true;
      }
    }
  },
  getters: {
    seen: (state: ConversationState) => (conversationId: string): boolean => {
      return state.unseenConversations[conversationId]?.seen ?? true;
    },
    unseenCount: (state: ConversationState) => (priority: string | null = null): number => {
      return Object.values(state.unseenConversations)
        .filter(c => {
          if (priority == null) {
            return true;
          }

          return c.priority == priority;
        })
        .filter(c => !c.seen)
        .length;
    },
    unseenFlowerCount: (state: ConversationState) => (priority: string | null = null): number => {
      return Object.values(state.unseenConversations)
        .filter(c => {
          if (priority == null) {
            return true;
          }

          return c.priority == priority;
        })
        .filter(c => !c.seen)
        .filter(c => c.unseen_flower)
        .length;
    }
  },
  actions: {
    fetchUnseenConversations(store: any, filters?: { priority: string }): Promise<null> {
      return new Promise((resolve, reject) => {
        api.get("conversations/unseen", {
          params: {
            filter: filters
          }
        })
          .then((response: any) => {

            const unseenConversations = response.data.data as UnseenConversations;

            let currentConversationId = null;

            const route = router.currentRoute;

            if (route && route.value && route.value.params.conversation) {
              currentConversationId = route.value.params.conversation as keyof UnseenConversations;
            }

            if (currentConversationId && unseenConversations[currentConversationId] !== undefined) {
              delete unseenConversations[currentConversationId];
            }

            store.commit("setUnseenConversations", unseenConversations);

            if (Capacitor.isNativePlatform()) {
              const count = store.getters.unseenCount();
              Badge.set(count);
            }

            resolve(null);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    },
  }
};
