<template>
  <form
    @submit.prevent="submit()"
  >
    <div class="flex">
      <template
        v-for="r in ratings"
        :key="r"
      >
        <button
          aria-hidden="true"
          :title="r + ''"
          :value="r"
          type="submit"
          name="score"
          class="focus:outline-none hover:scale-110 focus:shadow-outline block w-8 p-px text-gray-200 transition-transform cursor-pointer"
          :class="{'text-gray-600': hoverRating >= r, 'text-yellow-400': rating >= r && hoverRating >= r}"
          @click="rating = r"
          @mouseover="hoverRating = r"
          @mouseleave="hoverRating = rating"
        >
          <svg
            class="w-full transition duration-150"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
            />
          </svg>
        </button>
      </template>
    </div>
  </form>
</template>

<script lang="ts">
import { Article } from "@/models/Article";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { getRoute } from "@/services/utils";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    article: {
      required: true,
      type: Object as PropType<Article>,
    }
  },
  data () {
    return {
      loading: false,
      rating: this.article.my_vote ?? 0,
      hoverRating: this.article.my_vote ?? 0,
      submittedRating: 0,
      ratings: [1,2,3,4,5],
      success: false,
    };
  },
  methods: {
    submit() {

      if (this.loading) {
        return;
      }

      if (this.rating == this.submittedRating) {
        return;
      }

      this.loading = true;

      this.$api.post(getRoute("/api/content/articles/" + this.article.id + "/votes"), {score: this.rating})
        .then(() => {
          this.success = true;
          this.submittedRating = this.rating;
          this.$notify({
            type: Type.success,
            message: this.$t("vote_sent_successfully")
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
