<template>
  <InfoModal
    :model-value="modelValue"
    :title="$t('new_terms_modal.title')"
  >
    <div class="relative">
      <TermsText />

      <p class="mt-6 text-sm leading-snug text-gray-700">
        {{ $t('you_scroll_read_the_terms_and_conditions_before_accepting') }}
      </p>
    </div>

    <template
      #confirm="{close}"
    >
      <div class="sm:mt-5 mt-6">
        <BaseButton
          type="button"
          :loading="loading"
          class="btn-xl btn-primary w-full"
          @click="accept(close)"
        >
          {{ $t('accept_terms_and_conditions') }}
        </BaseButton>
      </div>
    </template>
  </InfoModal>
</template>

<script lang="ts">
import { Account } from "@/models/Account";
import { getRoute } from "@/services/utils";
import { cloneDeep } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean
    }
  },
  emits: ["update:modelValue", "send"],
  data () {
    return {
      loading: false
    };
  },
  methods: {
    accept(close: any) {

      this.loading = true;

      this.$api.patch(getRoute("/api/content/terms/accept"))
        .then(() => {

          const user = cloneDeep(this.$store.state.user) as Account;
          user.accepted_terms_date = this.$luxon.now().toISO();
          this.$store.commit("setUser", user);

          this.$emit("update:modelValue", false);
        })
        .finally(()=> {
          this.loading = false;
          if (typeof close == "function") {
            close();
          }
        });
    }
  }
});
</script>
