<template>
  <div
    v-if="mobile"
    class=""
  >
    <div
      v-if="!$route.params.conversation"
      class="pt-safe max-w-2xl mx-auto"
    >
      <div class="max-w-2xl px-4 mx-auto">
        <BaseTitle
          :previous="false"
          :title="$t('inbox')"
        />
      </div>

      <ConversationTabs
        v-model="priority"
        @update:model-value="updatePriority"
      />
      <ConversationList />
    </div>

    <div
      v-if="$route.params.conversation"
      class="md:z-0 z-menu absolute inset-0 flex w-full h-full overflow-hidden"
    >
      <router-view :key="$route.fullPath" />
    </div>
  </div>

  <div
    v-else
    class="absolute inset-0 flex flex-col w-full h-full overflow-hidden"
  >
    <DesktopMenuSpacer class="shrink-0" />
    <div class="grow flex overflow-hidden">
      <div class="w-96 shrink-0 flex flex-col overflow-hidden">
        <ConversationTabs
          v-model="priority"
          @update:model-value="updatePriority"
        />
        <ConversationList />
      </div>

      <div class="bg-gray-50 relative flex-1 overflow-hidden border-l border-gray-200">
        <router-view
          v-if="$route.params.conversation"
          :key="$route.fullPath"
        />
        <BaseConversationEmpty v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import { inject, Ref, ref, provide, reactive, onBeforeUnmount, watch } from "vue";
import ConversationList from "./components/ConversationList.vue";
import ConversationTabs from "./components/ConversationTabs.vue";
import BaseConversationEmpty from "./components/BaseConversationEmpty.vue";
import DesktopMenuSpacer from "@/layouts/DesktopMenuSpacer.vue";
import { Conversation } from "@/models/Conversation";
import { get } from "lodash";
import { echo } from "@/services/echo";
import { api } from "@/services/api";
import { useRoute, useRouter } from "vue-router";
import { store } from "@/store";

const route = useRoute();
const router = useRouter();

const mobile = inject("mobile") as Ref<boolean>;

const userChannelName = "user." + store.state.user?.id ?? "";

const loading = ref(false);
const priority = ref("priority");
const conversations = ref({}) as Ref<{ [id: string]: Conversation }>;
const currentConversationIds = reactive({
  priority: null as string|null,
  secondary: null as string|null,
});

const deleteConversationById = (id: string) => {
  delete conversations.value[id];
};

const setCurrentConversationId = (priority: "priority"|"secondary", id: string) => {
  currentConversationIds[priority] = id;
};

provide("priority", priority);
provide("loading", loading);
provide("fetch", fetch);
provide("conversations", conversations);
provide("deleteConversationById", deleteConversationById);
provide("setCurrentConversationId", setCurrentConversationId);
provide("updatePriority", updatePriority);

// When the user navigates to /member/messages,
// it is important to re-select the first conversation
watch(
  () => route.name,
  (routeName) => {
    if (
      routeName == "member.messages" &&
      // If conversation id is specified, abort
      !route.params.conversation &&
      // If already fetching, abort : the conversation will be selected
      !loading.value
    ) {
      selectFirstConversation();
    }
  }
);

async function fetch() {

  const filters = {
    priority: priority.value
  };

  const response = await api.get("conversations", {
    params: {
      filter: filters
    }
  });

  const newConversations = {} as {[id: string]: Conversation};

  // Key by Id
  (response.data.data as Conversation[]).forEach(conversation => {
    newConversations[conversation.id] = conversation;
  });

  conversations.value = newConversations;

  // Select first conversation
  selectFirstConversation();
}

async function hardFetch() {

  loading.value = true;

  try {
    await fetch();
  } finally {
    loading.value = false;
  }
}

function selectFirstConversation() {

  if (mobile.value) {
    return;
  }

  if (route.name != "member.messages") {
    return;
  }

  if (Object.keys(conversations.value).length == 0) {
    return;
  }

  if (route.params.conversation) {
    return;
  }

  let conversationId = null as string|null;

  // 1) Try with currentConversationIds list

  if (conversationId == null) {
    const currentConversationForPriorityId = get(currentConversationIds, priority.value, null) as string|null;

    if (currentConversationForPriorityId != null) {
      const currentConversationForPriority = conversations.value[currentConversationForPriorityId] as Conversation|undefined;
      if (currentConversationForPriority) {
        const currentConversationForPriorityMe = currentConversationForPriority.users.find(u => u.is_me);
        if (currentConversationForPriorityMe?.pivot.priority == priority.value) {
          conversationId = currentConversationForPriority.id;
        }
      }
    }
  }

  // 2) Pick first conversation (make sure to call await fetch() before!)

  if (conversationId == null) {
    const conversationFromList = Object.values(conversations.value)[0];
    if (conversationFromList) {
      const conversationFromListMe = conversationFromList.users.find(u => u.is_me);
      if (conversationFromListMe?.pivot.priority == priority.value) {
        conversationId = conversationFromList.id;
      }
    }
  }

  if (conversationId) {
    // Redirect to first or current conversation if desktop and none is selected
    router.push("/member/messages/" + conversationId);
  }
}

async function updatePriority(p: string) {

  priority.value = p;

  // Deselect current conversation
  router.push("/member/messages");

  // Fetch conversation list
  await hardFetch();
}

const userChannel = echo.private(userChannelName);

userChannel.listen(".conversations.messages.created", fetch);

// Created

hardFetch();

// Before Mount

onBeforeUnmount(() => {
  userChannel.stopListening(".conversations.messages.created", fetch);
  echo.private(userChannelName).stopListening(".conversations.messages.created");
});

</script>
