import { Storage } from "@capacitor/storage";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import { v4 } from "uuid";
import { getRootDomain } from "./utils";

const TANDEM_DEVICE_REFERENCE = "tandem_device_reference";

export async function createDeviceReference() {

  const value = await getDeviceReference();

  if (!value) {

    const deviceRef = v4();

    Cookies.set(TANDEM_DEVICE_REFERENCE, deviceRef, {
      domain: getRootDomain(),
      path: "/",
      sameSite: "Lax",
      expires: DateTime.now().plus({ weeks: 3 }).toJSDate()
    });

    await Storage.set({
      key: TANDEM_DEVICE_REFERENCE,
      value: deviceRef,
    });
  }
}

export async function getDeviceReference(): Promise<string | null> {

  const value = Cookies.get(TANDEM_DEVICE_REFERENCE);

  if (value) {
    return value;
  }

  const storageValue = await Storage.get({
    key: TANDEM_DEVICE_REFERENCE
  });

  return storageValue.value;
}
