<template>
  <Teleport to="body">
    <transition
      name="modal"
      enter-active-class="duration-300"
      leave-active-class="duration-200"
    >
      <div v-show="modelValue">
        <div
          class="z-modal fixed inset-0 overflow-y-auto"
          scroll-lock-target
        >
          <div
            class="sm:px-6 sm:pb-6 sm:pt-20 flex items-end justify-center min-h-full pt-10"
            :class="{
              'sm:items-center': verticalAlign == 'center',
              'sm:items-start': verticalAlign == 'top',
            }"
          >
            <div class="pt-safe grow">
              <transition
                appear
                enter-active-class="duration-300 ease-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-show="modelValue"
                  class="bg-opacity-40 fixed inset-0 transition-opacity bg-gray-900"
                  @click="closeOnBackdrop ? close() : null"
                />
              </transition>

              <transition
                appear
                enter-active-class="duration-300 ease-out"
                enter-from-class="translate-y-20 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-20 opacity-0"
              >
                <div
                  v-if="modelValue"
                  class="sm:rounded-xl z-[1] sm:shadow-xl pb-safe rounded-t-xl relative block mx-auto bg-white"
                  :class="width"
                >
                  <slot :close="close" />
                </div>
              </transition>
            </div>

            <!-- This div is used to make the bottom white when using elastic scroll on iOS -->
            <transition
              appear
              enter-active-class="duration-100 delay-500"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-0 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                v-show="modelValue"
                class="sm:hidden fixed bottom-0 w-full h-40 bg-white"
                :class="width"
              />
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { disableScroll, enableScroll } from "@/services/scrollPreventer";


export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean
    },
    verticalAlign: {
      default: "center",
      type: String
    },
    width: {
      default: "sm:max-w-lg w-full",
      type: String,
    },
    closeOnBackdrop: {
      default: true,
      type: Boolean,
    }
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          disableScroll();
        } else {
          enableScroll();
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (this.modelValue) {
      disableScroll();
    }
  },
  beforeUnmount() {
    enableScroll();
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    }
  }
});

</script>
