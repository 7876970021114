<template>
  <div class="relative overflow-hidden pt-[56.25%]">
    <iframe
      class="absolute inset-0 w-full h-full border-none"
      :src="url"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    />
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    url: {
      required: true,
      type: String
    }
  }
});
</script>
