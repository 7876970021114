<template>
  <div class="flex flex-wrap -m-1">
    <div
      v-if="hasEmpty"
      class="p-1"
    >
      <CheckBoxGroupOption
        :model-value="modelValue_"
        value=""
        :active="modelValue_.filter((i:any) => i).length == 0"
        :label="$t('no_preference')"
        @update:model-value="$emit('update:modelValue', [])"
      />
    </div>
    <div
      v-for="option in options"
      :key="optionKey(option)"
      class="p-1"
    >
      <CheckBoxGroupOption
        :model-value="modelValue_"
        :active="modelValue_.includes(option.value)"
        :value="option.value"
        :label="option.label"
        @update:model-value="$emit('update:modelValue', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import { uuid } from "@/services/uuid";
import { isArray } from "lodash-es";
import { CheckBoxOption, CheckBoxValues } from "./types";

export default defineComponent({
  props: {
    modelValue: {
      required: false,
      default: undefined,
      type: Array as PropType<CheckBoxValues|undefined>
    },
    options: {
      required: true,
      type: Array as PropType<CheckBoxOption[]>
    },
    hasEmpty: {
      default: true,
      type: Boolean
    },
    emptyLabel: {
      default: "",
      type: String
    }
  },
  emits: ["update:modelValue"],
  computed: {
    modelValue_() : CheckBoxValues {
      if (!isArray(this.modelValue)) {
        return [];
      }

      return this.modelValue as CheckBoxValues;
    },
    empty() : boolean {
      return this.modelValue_.filter(i => i).length == 0;
    },
    emptyLabelNormalized() : string {
      if (this.emptyLabel) {
        return this.emptyLabel;
      }
      return this.$t("no_preference");
    }
  },
  methods: {
    optionKey(option: CheckBoxOption): number | string {
      if (option.value === null) {
        return uuid();
      }
      return option.value;
    }
  }
});
</script>
