<template>
  <button
    type="button"
    class="flex px-4 py-6 space-x-4 border-b border-gray-200 outline-none appearance-none"
    @click="onClick"
  >
    <div>
      <div
        class="w-4 h-4 mt-1 rounded-full"
        :class="{
          'border-[5px] border-primary-500': sortKey == $store.getters.sort,
          'border border-gray-300': sortKey != $store.getters.sort,
        }"
      />
    </div>
    <div class="text-left">
      <h3 class="mb-1 font-semibold leading-tight text-gray-900">
        {{ title }}
      </h3>
      <p class="text-sm leading-tight text-gray-600">
        {{ description }}
      </p>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from "vue";
import { Sort } from "../../models/Sort";

export default defineComponent({
  props: {
    sortKey: {
      required: true,
      type: String as PropType<Sort>
    },
    title: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String
    }
  },
  setup() {
    const hideSorts = inject("hideSorts") as () => void;
    return {
      hideSorts
    };
  },
  methods: {
    onClick() {
      this.$store.commit("setSort", this.sortKey);
      this.hideSorts();
    }
  }
});
</script>
