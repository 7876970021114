import { Notification } from "./models/Notification";
import { store } from "@/store";
import { NotificationConfigInterface } from "./models/NotificationConfig.interface";

class Notifications {
  public init(): void {
    setInterval(this.cleanNotifications, 100);
  }

  public cleanNotifications(): void {
    Object.values(store.state.notification.items).forEach((notification: Notification) => {
      if (notification.shouldHide()) {
        store.commit("notification/pop", notification.id);
      }
    });
  }

  public notify(notification: Notification | NotificationConfigInterface) {
    store.commit("notification/push", notification);
  }
}

const notificator = new Notifications();

export { notificator };
