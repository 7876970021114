import { App } from "vue";
import { padStart, truncate } from "lodash-es";
import i18n from "./i18n";
import { store } from "@/store";
import { assetUrl } from "@/services/assets";
import { markdownToHtml } from "@/services/markdown";

export default {
  install: (app: App): void => {

    app.config.globalProperties.$tg = (key: string): string => {
      const genderi18n = store.state.user?.gender_i18n ?? "m";
      return i18n.global.t(key + "_" + genderi18n);
    };

    app.config.globalProperties.$assetUrl = assetUrl;

    String.prototype.limit = function (this: string, limit = 32) {
      return truncate(this, {
        length: limit
      });
    };

    String.prototype.capitalize = function (this: string) {
      return this.charAt(0).toUpperCase() + this.slice(1);
    };

    String.prototype.markdownToHtml = function (this: string, classes?: string) {
      return markdownToHtml(this, classes);
    };

    Number.prototype.timerFormat = function (this: number): string {

      const seconds = Math.round(this);

      let secondsStr = (seconds % 60) + "";
      secondsStr = padStart(secondsStr, 2, "0");

      let minutesStr = Math.floor(seconds / 60) + "";
      minutesStr = padStart(minutesStr, 2, "0");

      return minutesStr + ":" + secondsStr;
    };

    Number.prototype.percentageFormat = function (this: number, n = 0): string {
      return new Intl.NumberFormat("en-US", { style: "percent", minimumFractionDigits: n, maximumFractionDigits: n }).format(this);
    };

    Number.prototype.priceFormat = function (this: number, n = 2): string {
      const rounder = Math.pow(10, n);
      const price = (Math.round(this * rounder) / rounder).toFixed(2) + "$";

      if (i18n.global.locale == "fr") {
        return price.replace(".", ",");
      }

      return price;
    };
  }
};
