<template>
  <div class="bg-gray-50 pt-safe">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.visibility.inbox.title')"
        :subtitle="$t('page.settings.menu.visibility.inbox.subtitle')"
      />
    </BaseContainer>

    <div class="min-h-[500px] relative">
      <BaseContainer size="2xl">
        <BaseAlert
          type="info"
          class="mb-5 border border-blue-300"
        >
          <p>{{ $t('page.settings.secondary_conversation_rules.notice') }}</p>
        </BaseAlert>
      </BaseContainer>
      <BaseContainer
        size="2xl"
        :paddingless-mobile="true"
      >
        <SecondaryConversationRuleForm
          :secondary-conversation-rules="secondaryConversationRules"
        />
      </BaseContainer>

      <BaseLoadingCover
        v-model="loading"
        backdrop-class="bg-gray-50"
      />
    </div>

    <BaseFooter />
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { SecondaryConversationRule } from "@/models/SecondaryConversationRule";
import SecondaryConversationRuleForm from "./SecondaryConversationRuleForm.vue";

export default defineComponent({
  components: { SecondaryConversationRuleForm },
  data () {
    return {
      loading: false,
      secondaryConversationRules: [] as SecondaryConversationRule[]
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch () {
      this.loading = true;

      this.$api.get("account/member/secondary-conversation-rules")
        .then(response => {
          this.secondaryConversationRules = response.data.data as SecondaryConversationRule[];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
});
</script>
