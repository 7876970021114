<template>
  <AnswerItem
    v-for="(item, index) in questionAndAnswers"
    :key="item.question.id"
    :answer="item.answer"
    :question="item.question"
  >
    <template
      v-if="index > 0"
      #header
    >
      <h4 class="mt-2 font-medium text-gray-900">
        {{ item.question.text }}
      </h4>
    </template>
  </AnswerItem>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Question, QuestionType } from "@/models/Question";
import { QuestionOption } from "@/models/QuestionOption";
import { Answer } from "@/models/Answer";
import { AnswerList } from "@/store";

type QuestionAnswerList = {question: Question, answer: Answer}[];

export default defineComponent({
  props: {
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    answer: {
      required: true,
      type: Object as PropType<Answer>
    },
    answers: {
      required: true,
      type: Object as PropType<AnswerList>
    }
  },
  computed: {
    options(): QuestionOption[] {
      if ([QuestionType.select, QuestionType.multi_select].includes(this.question.type.input_type) && this.answer.options.length) {
        return this.answer.options as QuestionOption[];
      }
      return [];
    },
    optionIds () : string[] {
      return this.options.map(o => o.id);
    },
    questionAndAnswers(): QuestionAnswerList {
      const items = [] as QuestionAnswerList;

      items.push({
        question: this.question,
        answer: this.answer,
      });

      if (this.options.length == 0) {
        return items;
      }

      const dependantQuestions = (this.$store.getters["question/dependantQuestions"](this.question, this.optionIds)) as Question[];

      dependantQuestions.forEach(question => {
        if (this.answers[question.id]) {
          items.push({
            question: question,
            answer: this.answers[question.id],
          });
        }
      });

      return items;
    }
  },
});
</script>
