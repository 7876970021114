<template>
  <BaseButton
    type="button"
    class="sm:grow-0 btn-primary btn-lg grow"
    :loading="loading"
    :disabled="disabled"
    @click="sendMessage"
  >
    <ChatAlt2Icon class=" w-6 h-6 mr-2" />
    <span class="sm:inline hidden">{{ $t('send_a_message') }}</span>
    <span class="sm:hidden">{{ $t('message') }}</span>
  </BaseButton>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ChatAlt2Icon } from "@heroicons/vue/solid";
import { Member } from "@/models/Member";
import { SUSPENDED, UNSUBSCRIBED } from "@/services/errorCodes";
import { get } from "lodash";

export default defineComponent({
  components: {
    ChatAlt2Icon
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false
    };
  },
  methods: {
    sendMessage() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.post("conversations", {
        user_id: this.member.user.id,
      })
        .then((response: any) => {
          this.$router.push(`/member/messages/${response.data.id}`);
        })
        .catch(error => {
          const code = get(error, "response.data.code");
          if ([UNSUBSCRIBED, SUSPENDED].includes(code)) {
            this.$store.dispatch("fetchUser");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
