<template>
  <BaseModal
    :model-value="modelValue"
    :close-on-backdrop="false"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="p-7 sm:pb-6 sm:p-8 rounded-3xl relative pb-5 overflow-hidden">
      <button
        type="button"
        class="top-2 hover:bg-gray-100 hover:text-gray-900 right-2 transition-color absolute z-10 p-2 text-gray-700 duration-150 bg-transparent border-none rounded-full appearance-none"
        @click="close"
      >
        <Icon
          class="w-8 h-8"
          icon="mdi:close"
        />
      </button>

      <!-- Product description -->
      <div class="mb-4">
        <div class="flex mb-3">
          <img
            v-for="i in product.number_of_months"
            :key="i"
            src="@/assets/img/logo/logo-icon-primary-600.svg"
            alt="Logo Effet Tandem"
            class="w-7"
          >
        </div>

        <h2 class="font-display sm:text-3xl mb-4 text-2xl font-semibold leading-tight">
          {{ product.name }}
        </h2>

        <div class="leading-snug text-gray-700">
          <p
            v-if="['inactive', 'trial'].includes(member.subscription_status)"
            class="mb-3"
          >
            {{ $t('complete_access_for_x_months', {x: product.number_of_months}) }}.
          </p>
          <p
            v-else
            class="mb-3"
          >
            {{ $tc('complete_access_for_an_additional_x_months', product.number_of_months, {x: product.number_of_months}) }}.
          </p>
          <template v-if="estimate">
            <template
              v-if="product.is_renewable"
            >
              <!-- <p class="mb-2 text-sm">
                {{ $tc('page.subscription.subscription_end_notice.message', product.number_of_months, {price: product.price.priceFormat()}) }}
                {{ $t('page.subscription.subscription_end_notice.message_2', {end: estimate.end_date.setLocale($i18n.locale).toFormat('DD')}) }}
              </p>
              <p class="text-sm">
                {{ $t('page.subscription.subscription_end_notice.message_3') }}
              </p> -->
            </template>
            <span v-else>
              {{ $t('page.subscription.product_end_notice.message', {x: estimate.end_date.setLocale($i18n.locale).toFormat('DD')}) }}
            </span>
          </template>
        </div>
      </div>

      <!-- Estimate Details -->
      <div
        v-if="estimate"
        class="mb-4"
      >
        <div v-if="estimate.order.total > 0">
          <p class="text-3xl font-normal leading-tight text-gray-900">
            {{ estimate.order.total.priceFormat() }}
          </p>
          <p
            v-if="estimate.order.taxes"
            class="text-base leading-tight text-gray-500"
          >
            {{ $t('x_tax_included', {x: estimate.order.taxes.priceFormat()}) }}
          </p>
        </div>
        <p
          v-else
          class="text-3xl font-normal leading-tight text-gray-900"
        >
          {{ $t('free') }}
        </p>

        <!-- Promo Code -->
        <div
          v-if="estimate.promo_code"
          class="mt-3"
        >
          <div class="inline-flex pl-3 font-semibold text-gray-700 bg-white border border-gray-200 rounded">
            <div class="mr-2">
              <span class="mr-1 uppercase">
                {{ estimate.promo_code.code }}
              </span>
              <span
                v-if="estimate.promo_code.additional_months > 0"
                class="font-medium text-green-600"
              >
                +{{ $tc('x_free_months', {x: estimate.promo_code.additional_months}) }}
              </span>
              <span
                v-else-if="estimate.promo_code.rebate_type == 'percentage'"
                class="font-medium text-green-600"
              >
                -{{ estimate.promo_code.rebate_value.percentageFormat() }}
              </span>
              <span
                v-else-if="estimate.promo_code.rebate_type == 'amount'"
                class="font-medium text-green-600"
              >
                -{{ estimate.promo_code.rebate_value.priceFormat() }}
              </span>
            </div>
            <button
              type="button"
              class="hover:opacity-80 pr-2 pl-1.5 border-l border-gray-300 hover:bg-gray-50"
              @click="resetPromoCode"
            >
              <Icon
                class="w-5 h-5"
                icon="mdi:close"
              />
            </button>
          </div>
        </div>
      </div>
      <!-- End Estimate Details -->

      <!-- Actions -->
      <div class="mb-6">
        <button
          class="flex items-center space-x-2 bg-transparent appearance-none"
          @click="showPromoCodeModal = true"
        >
          <Icon
            icon="ri:coupon-3-line"
            class="text-gray-800"
          />
          <span class="text-gray-600">
            {{ $t('add_a_promo_code') }}
          </span>
        </button>
      </div>

      <!-- End Actions -->

      <div
        v-if="paymentSource && !updatingPaymentMethod && requiresPayment"
        class="sm:mb-8 mb-10"
      >
        <div class="relative px-4 py-3 mt-4 bg-white border border-gray-300 rounded-md">
          <PaymentSourceVue :payment-source="paymentSource" />
        </div>

        <button
          type="button"
          class="mt-1 text-gray-700 underline"
          @click="updatingPaymentMethod = true"
        >
          {{ $t('update_payment_method') }}
        </button>
      </div>

      <div v-if="showPaymentForm">
        <div
          v-if="paymentSource"
          class="relative"
        >
          <button
            type="button"
            class="bg-none mb-5 underline border-none appearance-none"
            @click="updatingPaymentMethod = false"
          >
            ‹ {{ $t('back_to_payment_methods') }}
          </button>
        </div>

        <StorePaymentSource
          :button-text="buttonText"
          :button-id="buttonId"
          :disabled="estimating"
          @success="onPaymentSourceStoreSuccess()"
        />
      </div>

      <BaseButton
        v-else
        :id="buttonId"
        type="button"
        :loading="loading"
        :disabled="estimating"
        data-cy="confirm"
        class="btn-primary btn-lg w-full"
        @click="pay()"
      >
        {{ buttonText }}
      </BaseButton>

      <BaseButton
        type="button"
        data-cy="cancel"
        class="btn-white btn-lg w-full mt-1"
        @click="close()"
      >
        {{ $t('cancel') }}
      </BaseButton>

      <BaseLoadingCover :model-value="loading || estimating" />
    </div>
  </BaseModal>

  <PromoCodeModal
    v-model="showPromoCodeModal"
    :product="product"
    @success="onPromoCodeSuccess"
  />
</template>

<script lang="ts">
import { AccountMember } from "@/models/Account";
import { PaymentSource } from "@/models/PaymentSource";
import { PromoCode } from "@/models/PromoCode";
import { Product } from "@/models/Product";
import { getDeviceReference } from "@/services/deviceReference";
import { Capacitor } from "@capacitor/core";
import { get } from "lodash";
import { defineComponent, PropType } from "vue";
import StorePaymentSource from "./StorePaymentSource.vue";
import PaymentSourceVue from "./PaymentSource.vue";
import { DateTime } from "luxon";
import PromoCodeModal from "../PromoCodeModal.vue";

interface Estimate {
  end_date: DateTime,
  promo_code: PromoCode|null,
  order: {
    item_total: number,
    taxes: number,
    total: number,
  }
}

export default defineComponent({
  components: {
    StorePaymentSource,
    PaymentSourceVue,
    PromoCodeModal
  },
  props: {
    product: {
      required: true,
      type: Object as PropType<Product>
    },
    modelValue: {
      default: false,
      type: Boolean
    },
  },
  emits: ["update:modelValue", "success", "badRequest"],
  data() {
    return {
      // Payment source state
      paymentSource: null as PaymentSource|null,
      hasPaymentSource: true,
      // Loading state
      updatingPaymentMethod: false,
      loading: false,
      // Locked state
      locked: false, // prevent double payments by clicking twice
      // Payment estimation
      promoCode: null as string|null,
      estimating: false,
      estimate: null as Estimate|null,
      // Action modals
      showPromoCodeModal: false,
    };
  },
  computed: {
    isFree () : boolean {
      if (this.estimate && this.estimate.order.total == 0) {
        return true;
      }
      if (this.product.price == 0) {
        return true;
      }

      return false;
    },
    requiresPayment () : boolean {
      return ! this.isFree;
    },
    showPaymentForm(): boolean {
      if (this.hasPaymentSource && !this.updatingPaymentMethod) {
        return false;
      }
      return this.requiresPayment;
    },
    member(): AccountMember {
      return this.$store.state.user?.member as AccountMember;
    },
    buttonId () : string {
      if (this.product.price > 0) {
        return "onboarding-button-activate-product-" + this.product.number_of_months + "-month";
      }
      return "onboarding-button-activate-product-free";
    },
    buttonText(): string {
      if (this.isFree) {
        return this.$t("activate");
      }
      return this.$t("pay_now");
    }
  },
  watch: {
    product: {
      handler() {
        this.reset();
      }
    },
    modelValue: {
      handler(newValue: boolean, oldValue) {
        if (newValue && !oldValue) {
          this.reset();
        }
      },
      immediate: true
    }
  },
  methods: {
    pay() {
      if (Capacitor.isNativePlatform()) {
        alert("not implemented yet");
      }
      else {
        this.payWeb();
      }
    },
    async payWeb() {

      if (this.loading) {
        return;
      }

      if (this.locked) {
        return;
      }

      this.loading = true;
      this.locked = true;

      this.$api.post("pay/" + this.product.id, {
        promo_code: this.promoCode,
        device_ref: await getDeviceReference(),
        price_hash: this.product.price_hash,
      })
        .then(async () => {
          await this.onPaymentSuccess();
        })
        .catch((error) => {
          this.locked = false;
          const status = get(error, "response.status");
          if (status == 400) {
            this.$emit("badRequest");
            this.$emit("update:modelValue", false);
            this.$store.dispatch("fetchUser");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPaymentSourceStoreSuccess() {
      this.hasPaymentSource = true;
      this.payWeb();
    },
    async onPaymentSuccess() {
      this.$emit("success");
      this.$emit("update:modelValue", false);
    },
    reset () {
      this.fetchSubscriptionEstimate();
      this.fetchPaymentSources();
      this.locked = false;
    },
    fetchSubscriptionEstimate() {

      if (this.estimating) {
        return;
      }

      this.estimating = true;

      this.$api.get("products/" + this.product.id + "/estimate", {
        params: {
          promo_code: this.promoCode,
        }
      })
        .then(response => {
          this.estimate = response.data.data as Estimate;
          this.estimate.end_date = this.$luxon.fromISO(response.data.data.end_date);
        })
        .finally(() => {
          this.estimating = false;
        });
    },
    fetchPaymentSources() {
      if (Capacitor.isNativePlatform()) {
        this.hasPaymentSource = true;
        return;
      }
      this.$api.get("payment-sources")
        .then(response => {
          this.hasPaymentSource = response.data.data.length > 0;
          this.paymentSource = response.data.data[0] as PaymentSource;
        });
    },
    onPromoCodeSuccess(promoCode: PromoCode) {
      this.promoCode = promoCode.code;
      this.fetchSubscriptionEstimate();
    },
    resetPromoCode() {
      if (this.estimate?.promo_code) {
        this.estimate.promo_code = null;
      }

      this.promoCode = null;

      this.fetchSubscriptionEstimate();
    },
    close() {
      this.$emit("update:modelValue", false);
    }
  },
});
</script>
