<template>
  <OnboardingLayout back-url="/onboarding/home">
    <div class="relative max-w-sm mx-auto mt-10 mb-10">
      <OnboardingTitle>
        <div class="text-center">
          {{ $t('create_your_account') }}
        </div>
      </OnboardingTitle>

      <div class="space-y-3">
        <button
          id="onboarding-button-register-email"
          type="button"
          class="btn btn-lg btn-primary inline-flex items-center justify-center w-full"
          @click="signUpWithEmail()"
        >
          {{ $t('sign_up_with_email') }}
        </button>

        <button
          v-if="platform == 'ios'"
          id="onboarding-button-register-apple"
          type="button"
          class="btn btn-lg btn-white-outline inline-flex items-center justify-center w-full"
          @click="signUpWithApple()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1024 1024"
            class="w-5 h-5 mr-2 text-black"
          ><path
            fill="currentColor"
            d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5c-34.9-50-87.7-77.5-157.3-82.8c-65.9-5.2-138 38.4-164.4 38.4c-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8c23.8 68.2 109.6 235.3 199.1 232.6c46.8-1.1 79.9-33.2 140.8-33.2c59.1 0 89.7 33.2 141.9 33.2c90.3-1.3 167.9-153.2 190.5-221.6c-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7c-44.8 2.6-96.6 30.5-126.1 64.8c-32.5 36.8-51.6 82.3-47.5 133.6c48.4 3.7 92.6-21.2 129-63.7z"
          /></svg>
          <span class="text-black">{{ $t('sign_up_with_x', {x: 'Apple'}) }}</span>
        </button>

        <button
          id="onboarding-button-register-facebook"
          type="button"
          class="btn btn-lg btn-white-outline inline-flex items-center justify-center w-full"
          @click="signUpWithFacebook()"
        >
          <svg
            class="w-5 h-5 mr-2 text-black"
            aria-hidden="true"
            viewBox="0 0 20 20"
          >
            <g fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                clip-rule="evenodd"
              />
            </g>
          </svg>
          <span class="text-black">{{ $t('sign_up_with_x', {x: 'Facebook'}) }}</span>
        </button>

        <button
          id="onboarding-button-register-google"
          type="button"
          class="btn btn-lg btn-white-outline inline-flex items-center justify-center w-full"
          @click="signUpWithGoogle()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
            class="w-5 h-5 mr-2 text-black"
          >
            <g fill="currentColor">
              <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
            </g>
          </svg>
          <span class="text-black">{{ $t('sign_up_with_x', {x: 'Google'}) }}</span>
        </button>
      </div>

      <div class="mt-6">
        <button
          type="button"
          class="inline leading-snug text-left text-gray-700"
          @click="termsOpened = true"
        >
          {{ $t('by_signing_up_you_agree_to') }}
          <span class="text-blue-600 underline lowercase">
            {{ $t('the_terms_and_conditions') }}
          </span>.
        </button>
      </div>

      <BaseModal v-model="termsOpened">
        <div class="p-6">
          <TermsText />

          <div class="flex justify-end mt-4">
            <button
              type="button"
              class="btn flex items-center space-x-2"
              @click="termsOpened = false"
            >
              <Icon icon="heroicons-solid:x" />
              <span>{{ $t('close') }}</span>
            </button>
          </div>
        </div>
      </BaseModal>

      <EmailAlreadyExists
        v-if="errorEmailAlreadyExists"
        class="mt-8"
      />

      <BaseAlert
        v-else-if="error"
        class="mt-8 border border-red-300"
        type="danger"
      >
        {{ error }}
      </BaseAlert>

      <BaseLoadingCover :model-value="loading" />
    </div>
  </OnboardingLayout>
</template>

<script lang="ts">
import { Capacitor } from "@capacitor/core";
import { get, isPlainObject } from "lodash";
import { defineComponent } from "vue";
import OnboardingTitle from "./components/OnboardingTitle.vue";
import EmailAlreadyExists from "./components/EmailAlreadyExists.vue";
import { facebookClient } from "@/services/facebook";
import { googleClient } from "@/services/google";
import { appleClient } from "@/services/apple";
import { SignInWithAppleResponse } from "@capacitor-community/apple-sign-in";
import OnboardingLayout from "./OnboardingLayout.vue";

export default defineComponent({
  components: {
    OnboardingTitle,
    EmailAlreadyExists,
    OnboardingLayout
  },
  data () {
    return {
      platform: Capacitor.getPlatform(),
      loading: false,
      error: "",
      errorEmailAlreadyExists: false,
      termsOpened: false,
    };
  },
  async created () {
    if (!Capacitor.isNativePlatform() && import.meta.env.VITE_TESTING != "true") {
      await googleClient.init();
      await facebookClient.init();
    }
  },
  methods: {
    signUpWithEmail () {
      this.$router.push({
        path: "/onboarding/register",
      });
    },
    async signUpWithApple() {

      this.resetState();

      let result = null as SignInWithAppleResponse|null;

      try {
        result = await appleClient.getToken();
      } catch (e: any) {
        this.onFail(e);
        return;
      }

      try {

        const response = await this.$api_.post("onboarding/user/apple", {
          apple_token: result.response.authorizationCode,
          first_name: result.response.givenName,
          last_name: result.response.familyName,
        });

        await this.login(response.data.token);

        this.onSuccess();

      } catch (e: any) {
        this.onFail(e);
      }

    },
    async signUpWithFacebook() {

      this.resetState();

      let token = null as string|null;

      try {
        token = await facebookClient.getToken();
      } catch {
        this.onFail(new Error("Invalid"));
        return;
      }

      if (!token) {
        this.onFail(new Error("Invalid"));
        return;
      }

      const result = await facebookClient.getProfile();

      if (!isPlainObject(result)) {
        this.onFail(new Error("Invalid"));
        return;
      }

      if (result.email == "" || result.email == null || result.email == undefined) {
        this.redirectToRegister({
          first_name: result.first_name,
          last_name: result.last_name,
          facebook_id: result.id,
          facebook_access_token: token,
        });
        return;
      }

      try {
        const response = await this.$api_.post("onboarding/user/facebook", {
          facebook_token: token,
        });

        await this.login(response.data.token);

        this.onSuccess();

      } catch (error) {
        this.onFail(error);
      }

    },
    async signUpWithGoogle() {

      this.resetState();

      const token = await googleClient.getToken();

      if (!token) {
        this.onFail(new Error("Invalid"));
        return;
      }

      try {
        const response = await this.$api_.post("onboarding/user/google", {
          google_token: token,
          google_client_id: googleClient.clientId(),
        });

        await this.login(response.data.token);

        this.onSuccess();

      } catch (error) {
        this.onFail(error);
      }

    },
    async login(token: string) {
      await this.$auth.onLoginSuccess(token);
    },
    resetState() {
      this.error = "";
      this.errorEmailAlreadyExists = false;
      this.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFail(error: any) {
      this.loading = false;

      if (get(error.response,"data.error.code") == "EMAIL_ALREADY_EXISTS") {
        this.errorEmailAlreadyExists =true;
        return;
      }

      this.error = get(error.response, "data.message", "Invalid");
    },
    async onSuccess () {
      this.loading = false;
      this.$router.push("/onboarding/terms");
    },
    redirectToRegister(data: {first_name?: string, last_name?: string, email?: string, facebook_id?: string, facebook_access_token?:string}) {
      this.$router.push({
        path: "/onboarding/register",
        query: data
      });
    }
  }
});
</script>
