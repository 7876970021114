<template>
  <h2
    v-if="$slots.title"
    class="font-medium"
    :class="questionFont"
  >
    <slot
      name="title"
    />
  </h2>

  <ReadMore
    v-if="$slots.default"
    :max-lines="maxLines"
    class="mt-3 text-gray-600"
  >
    <slot
      name="default"
    />
  </ReadMore>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    maxLines: {
      default: 3,
      type: Number
    },
    calligraphy: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    questionFont () {
      if (this.calligraphy) {
        return "font-display font-medium text-[1.5rem] leading-[1.15]";
      }

      return "text-[1.4rem] font-semibold leading-[1.1]";
    }
  }
});
</script>
