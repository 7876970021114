<template>
  <Teleport to="body">
    <transition
      name="modal"
      enter-active-class="duration-300"
      leave-active-class="duration-200"
    >
      <div v-show="modelValue">
        <div
          class="z-modal fixed inset-0 overflow-y-auto"
          scroll-lock-target
        >
          <div class="flex items-start h-full min-h-full overflow-y-hidden">
            <div class="grow h-full">
              <transition
                appear
                enter-active-class="duration-300 ease-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-show="modelValue"
                  :class="modalOverlayClasses"
                  class="fixed inset-0 transition-opacity"
                  @click="close()"
                />
              </transition>

              <transition
                appear
                enter-active-class="duration-300 ease-out"
                enter-from-class="-translate-x-20 opacity-0"
                enter-to-class="transition-x-0 opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="transition-x-0 opacity-100"
                leave-to-class="-translate-x-20 opacity-0"
              >
                <div
                  v-show="modelValue"
                  :class="width"
                  class="z-[1] pb-safe relative bg-white shadow-xl py-safe flex flex-col w-full h-full overflow-hidden text-left"
                >
                  <slot :close="close" />
                </div>
              </transition>
            </div>

            <!-- This div is used to make the bottom white when using elastic scroll on iOS -->
            <transition
              appear
              enter-active-class="duration-100 delay-500"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-0 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                v-show="modelValue"
                class="xs:hidden fixed bottom-0 w-full h-40 bg-white"
              />
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { disableScroll, enableScroll } from "@/services/scrollPreventer";


export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean
    },
    width: {
      default: "max-w-lg",
      type: String
    },
    modalOverlayClasses: {
      default: "bg-opacity-40 bg-gray-900",
      type: String
    }
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          disableScroll();
        } else {
          enableScroll();
        }
      },
      immediate: true
    }
  },
  beforeUnmount() {
    enableScroll();
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    }
  }
});

</script>
