<template>
  <div class="flex flex-wrap -m-1.5">
    <template v-if="isNativePlatform">
      <div class="p-1.5">
        <a
          :href="`https://www.facebook.com/dialog/feed?link=${linkEncoded}&app_id=${facebookAppId}&display=touch`"
          :class="buttonClass"
          :title="$t('share_on_facebook')"
        >
          <Icon
            class="iconify w-6 h-6"
            style="color: #4064ac;"
            icon="mdi:facebook"
          />
        </a>
      </div>
      <div class="p-1.5">
        <a
          :href="`fb-messenger://share?link=${linkEncoded}&app_id=${facebookAppId}`"
          :class="buttonClass"
          :title="$t('share_on_messenger')"
        >
          <Icon
            class="iconify w-6 h-6"
            style="color: #4064ac;"
            icon="mdi:facebook-messenger"
          />
        </a>
      </div>
    </template>
    <template v-else>
      <div class="p-1.5">
        <a
          :href="`https://www.facebook.com/sharer/sharer.php?u=${linkEncoded}`"
          onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
          :class="buttonClass"
          :title="$t('share_on_facebook')"
        >
          <Icon
            class="iconify w-6 h-6"
            style="color: #4064ac;"
            icon="mdi:facebook"
          />
        </a>
      </div>
      <div class="p-1.5">
        <a
          :href="`http://www.facebook.com/dialog/send?link=${linkEncoded}&app_id=${facebookAppId}&redirect_uri=${encodeURIComponent(currentUrl)}`"
          :class="buttonClass"
          onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
          :title="$t('share_on_messenger')"
        >
          <Icon
            class="iconify w-6 h-6"
            style="color: #4064ac;"
            icon="mdi:facebook-messenger"
          />
        </a>
      </div>
      <div class="p-1.5">
        <a
          :href="`https://twitter.com/share?url=${linkEncoded}`"
          :class="buttonClass"
          onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
          target="_blank"
          :title="$t('share_on_twitter')"
        >
          <Icon
            class="iconify w-6 h-6"
            style="color: #1c9cea;"
            icon="mdi:twitter"
          />
        </a>
      </div>
    </template>
    <div class="p-1.5">
      <button
        type="button"
        :class="buttonClass"
        :title="$t('share_by_email')"
        @click="openShareByMail"
      >
        <Icon
          class="w-6 h-6 text-gray-400"
          icon="mdi:email"
        />
      </button>
    </div>
  </div>

  <ShareByMailModal
    v-model="shareByMailModal"
    :subject="mailSubject"
    :body="mailBody ?? link"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Capacitor } from "@capacitor/core";
import ShareByMailModal from "./ShareByMailModal.vue";

export default defineComponent({
  components: { ShareByMailModal },
  props: {
    link: {
      required: true,
      type: String
    },
    mailSubject: {
      required: true,
      type: String,
    },
    mailBody: {
      default: "",
      type: String,
    },
    buttonClass: {
      default: "",
      type: String,
    }
  },
  data () {
    return {
      shareByMailModal: false,
      isNativePlatform: Capacitor.isNativePlatform()
    };
  },
  computed: {
    currentUrl() : string {
      return this.mutateUrlOnLocal(window.location.href);
    },
    linkEncoded() : string {
      let url = this.mutateUrlOnLocal(this.link);
      return encodeURIComponent(url);
    },
    facebookAppId() : string {
      return import.meta.env.VITE_FACEBOOK_CLIENT_ID;
    },
    defaultSubject () : string {
      return this.$t("_mail_body.referral_link.subject");
    },
  },
  methods: {
    mutateUrlOnLocal(url: string) : string {
      if (url.startsWith("http://localhost:8101")) {
        return url.replace("http://localhost:8101", "https://effettandem.com");
      }
      return url;
    },
    openShareByMail() {
      this.shareByMailModal = true;
    }
  }
});
</script>
