{
  "SIN": "SIN",
  "_article_access_levels": {
    "member": "For subscribers",
    "public": "Public",
    "vip": "Bonus Effet 6 Months"
  },
  "_article_paywall": {
    "auth_text": "This article is reserved for Effet Tandem's {member_type}. You must upgrade your membership to gain access.",
    "subscription_options": "See subscription options",
    "title": "Thank you for viewing the Effet Tandem's articles!",
    "update_subscription": "Upgrade my subscription"
  },
  "_login": {
    "email_already_exists": "This email is already associated with an account. If this is your account, you can login with the form below."
  },
  "_mail_body": {
    "referral_link": {
      "body": "Hello, \n\nI just made a very interesting discovery that I want to share with you. Effet Tandem is an innovative website that helps singles to unite and blossom! It offers video clips to accompany you on an authentic journey to find love, safely. You also have access to experts in personal development. I really believe that this dating site is for you.\n\nI invite you to click on this link right now to get a free month when you sign up.\n{url}\n\nWho knows! You may be just a few clicks away from finding love.\n\n\n{from}\n",
      "subject": "One month free - Authentic dating site"
    }
  },
  "_manual_referral_form": {
    "text": "Enter the referral code in the box below:",
    "title": "Have you been referred by someone?"
  },
  "_media_kit_description": "Share promotional images and videos",
  "_placeholder_long_question_type": "Reveal a little more who you are to the Effet Tandem Community",
  "_placeholder_short_question_type": "Be spontaneous, let your inspiration flow!",
  "_visiblity": {
    "private": {
      "description": "If your profile is private, it will not be displayed on the home page of the Effet Tandem website. Members will also not be able to share your profile via email with a friend outside of the website.",
      "title": "Private"
    },
    "public": {
      "description": "If your profile is public, your first name, photo and city will be publicly available on the Effet Tandem homepage. Members will also be able to share your profile with a friend using secure links.",
      "title": "Public"
    }
  },
  "about": "About",
  "about_me": "About me",
  "about_x": "About {x}",
  "accept": "Accept",
  "accept_terms_and_conditions": "I accept the terms and conditions",
  "account_already_associated_with_this_email": "This email is already associated with an account.",
  "account_balance": "Account balance",
  "activate": "Activate",
  "activate_anonymous_mode": "Activate anonymous mode",
  "add_a_payment_method": "Add a payment method",
  "add_a_promo_code": "Click here to add a promo code",
  "add_a_referral_code": "Add a referral code",
  "add_a_tag": "Add a tag",
  "add_additional_months_to_your_subscription": "Add additional months to your subscription :",
  "add_criteria": "Add a criteria",
  "add_x_months": "Add {x} months",
  "additional_details": "Additional details",
  "address": "Address",
  "address_1_placeholder": "Postal address",
  "address_2_description": "Apartment, unit, suite, or floor #",
  "age": "Age",
  "all": "All",
  "all_rights_reserved": "All rights reserved.",
  "already_logged_in": "You are already logged in",
  "ambasador_taxes_notice": "This section is reserved for businesses and individuals with tax numbers. All tax numbers will be validated internally.",
  "ambassador_info": {
    "line_1": "Thanks to your invitation, your friends or colleagues will receive **1 additional free month** when subscribing to a new 3-month or 6-month plan. By doing so, you **get $10** for contributing to the growth of our community through your personal link.",
    "personal_link": "Here is your personal link to share:",
    "text_2": "Go to section \"Refer your friends\" for more details. Your username remains the same throughout the Effet Tandem community.",
    "title": "Refer a friend!\n"
  },
  "amount": "Amount",
  "and": "and",
  "anonymous_mode": "Anonymous mode",
  "apply": "Apply",
  "articles": {
    "filters": {
      "labels": {
        "apply_filters": "Apply filters",
        "keywords": "keywords",
        "premium_content": "Premium content",
        "search_by_keywords": "Search by keywords",
        "specialist": "Specialist",
        "type": "Type"
      },
      "title": "Filters",
      "types": {
        "all": "All",
        "article": "Article",
        "video": "Video"
      }
    },
    "sort": {
      "articles": "articles",
      "by": "Sort by",
      "of": "of",
      "sorts_list": {
        "publishing_date": "Publishing date",
        "rating": "Rating",
        "specialist": "Specialist"
      }
    }
  },
  "astrological_sign": "Astrological sign",
  "at_least_size": "At least {size}",
  "audio_message": "Audio message",
  "audio_message_description": "In a few words, introduce yourself or welcome visitors to your profile.",
  "authenticated": "Authenticated",
  "author": "Author",
  "author_name": "Author name",
  "authorize_video_chat": "Authorize video chat",
  "automatic_renewal": "Automatic renewal",
  "available": "Available",
  "back": "Back",
  "back_to_payment_methods": "Back to payment methods",
  "bank_balance": "Bank balance",
  "best_time_to_contact_you": "Best time to contact you",
  "birthday": "Date of birth",
  "block": "Block",
  "block_users_description": "Manage the users you have blocked. You can unblock them at any time.",
  "blocked_users": "Blocked users",
  "blossom": "Flourish",
  "both": "Both",
  "by": "By",
  "by_signing_up_you_agree_to": "By signing up you agree to",
  "by_x": "By {x}",
  "call": "Call",
  "call_x": "Call {name}",
  "calling_x": "Calling {x}",
  "cancel": "Cancel",
  "cancel_auto_renew": "Cancel automatic renewal",
  "cancel_subscription_modal": {
    "cta": "Cancel automatic renewal",
    "subtitle": "After cancelling the automatic renewal, your current subscription will remain active until {x}.",
    "title": "Cancel automatic renewal"
  },
  "card_number": "Card number",
  "card_type": "Type",
  "centimeters": "Centimeters",
  "change_the_visibility_of_my_profile": "Change the visibility of my profile",
  "chat": {
    "no_conversation_selected": "No conversation selected",
    "placeholder": "Type a message...",
    "received_flower": {
      "title": "You are {user}'s flower of the day"
    },
    "responses_0": "Thank you for showing interest in me. I greatly appreciate it but unfortunately I don't see a possibility between us. I wish you to meet the person you are looking for.",
    "responses_1": "Thanks for showing interest in me, but I just struck up a conversation with someone potentially interesting. If you allow me, I may come back to you eventually.",
    "secondary": {
      "notice": {
        "button": "Configure my secondary inbox",
        "text": "You can limit who can contact you through your priority inbox at any time. The secondary inbox contains all the low priority messages according to your preferences."
      }
    },
    "sent_flower": {
      "title": "You offered {user} a flower"
    },
    "you_sent_a_flower": {
      "title": "You offered a flower"
    }
  },
  "city": "City",
  "claim_sent_at": "Claim sent on",
  "click_here": "Click here",
  "click_on_icon_to_share": "Click on an icon to share",
  "close": "Close",
  "coming_soon": "Coming soon",
  "complete_access_for_an_additional_x_months": "Complete access for an additional month | Complete access for an additional {x} months",
  "complete_access_for_x_months": "Complete access for {x} months",
  "connection": "Connection",
  "contact_information": "Contact information",
  "contact_us": "Contact us",
  "continue": "Continue",
  "continue_my_registration": "Continue my registration",
  "continue_the_conversation": "Continuer la conversation",
  "conversion_rate": "Conversion rate",
  "conversions": "Conversions",
  "copied": "Copied!",
  "copy": "Copy",
  "copy_link": "Copy link",
  "country": "Country",
  "create_an_account": "Create an account",
  "create_my_account": "Create my account",
  "create_your_account": "Create your account",
  "current_password": "Current password",
  "cvv": "CVV",
  "daily_quote": "Quote of the day",
  "daily_quote_cta": {
    "button": "Click here to send us a thought of the day",
    "description": "Participate in the Effet Tandem Community by sharing your thought for the day. This thought may be displayed on the various Effet Tandem platforms with your name and photo.",
    "title": "Inspired?"
  },
  "daily_quote_successfully_submitted": "Thought of the day successfully submitted",
  "dashboard": "Dashboard",
  "decline": "Decline",
  "define_your_search_filters": "Define your search filters",
  "delete": "Delete",
  "delete_account_component": {
    "button": "Delete my account",
    "confirm": {
      "title": "Delete my account"
    },
    "pause": {
      "button": "Take a break",
      "subtitle": "If you don't want to permanently lose all your data and access your information in the future, you can pause your account.",
      "title": "Need a break?"
    },
    "subtitle": "By deleting your account, your profile and your messages will also be deleted. This action is irreversible.",
    "title": "Delete my account"
  },
  "deleted": "Deleted",
  "deleted_user": "Deleted user",
  "details": "Details",
  "discount": "Discount",
  "distance": "Distance",
  "do_you_want_to_create_a_profile_on_the_dating_website": "Do you want to create a profile on the dating site?",
  "done": "Done",
  "download_pdf": "Download PDF",
  "drag_to_reposition": "Drag to reposition",
  "earned": "Earned",
  "effet_tandem_homepage": "The Effet Tandem homepage",
  "eg": "E.g.",
  "email": "Email",
  "email_address": "Email address",
  "email_confirmation": "Email confirmation",
  "emails": "Emails",
  "enter_email_address": "Enter email address",
  "enter_your_new_email_address": "Enter your new email address",
  "error": "Error",
  "expiry": "Expiry",
  "explore": "Explore",
  "favorites": "Favorites",
  "feet": "Feet",
  "female": "Female",
  "filters": "Filters",
  "find_your_partner_in_love": "Find your partner in love",
  "find_your_soulmate": "Find your soulmate",
  "first_name": "First name",
  "flower_match": "Flower match",
  "flower_match_tagline": "Wow, it's mutual!",
  "forgot_your_password": "Forgot your password?",
  "free": "Free",
  "free_content": "Free content",
  "gallery_review_status": {
    "done": {
      "title": "Your photo album has been validated"
    },
    "pending": {
      "description": "Your photo album is awaiting approval. Please make sure you have at least **a portrait photo** and **a validated photo**. Please note that we manually review all photos. If you upload a new photo, it may take up to 24 hours for it to be approved.",
      "title": "Your photo album is pending review"
    },
    "refused": {
      "description": "To use the application, you must have at least **a portrait photo** and **a validated photo.** If you do not resolve this situation within the next 72 hours, your account will be automatically suspended. Please note that we manually review all photos. If you upload a new photo, it may take up to 24 hours for it to be approved.",
      "title": "You photo album is incomplete"
    }
  },
  "gender": "Gender",
  "general_information": "General information",
  "generated_rewards": "Generated rewards",
  "go_to_effettandemcom_to_manage_your_subscription": "Go to effettandem.com to manage your subscription.",
  "gst_hst": "GST/HST",
  "has_children": "Has children",
  "height": "Height",
  "home": "Home",
  "i_accept": "I accept",
  "i_accept_the_terms_and_conditions": "I accept the Terms and Conditions",
  "i_prefer_not_to_answer": "I prefer not to answer",
  "i_subscribe": "I subscribe",
  "i_subscribe_to_the_newsletter": "I would like to subscribe to the Effet Tandem newsletter",
  "i_validated_my_email": "I validated my email",
  "if_you_have_a_promotional_code_select_a_package_and_you_can_enter_it_later": "If you have a promotional code, select a plan and you can enter it later.",
  "im_the_author": "I'm the author",
  "imperial": "Imperial",
  "inbox": "Inbox",
  "inches": "Inches",
  "includes_bonus_course": "Includes a bonus course (494$ value)",
  "interac_password": "Interac e-Transfer Password",
  "interested_by": "Interested by",
  "invalid_promo_code": "Invalid promo code",
  "its": "it's",
  "its_mutual": "It's mutual!",
  "join_call": "Join call",
  "join_effet_tandem": "Join Effet Tandem",
  "just_now": "Just now",
  "keywords": "Keywords",
  "language": "Language",
  "last_connection": "Last connection",
  "last_name": "Last name",
  "layout": {
    "tagline": "Effet Tandem, the secure dating site based on authenticity and personal development."
  },
  "learn_more": "Learn more",
  "learn_more_about_eid_compare": "Learn more about eID Compare Equifax",
  "less_than_24h": "Less than 24h",
  "lifestyle": "Lifestyle",
  "limited_time_offer": "Limited time offer",
  "listen_to_my_voice": "Listen to my voice",
  "loading": "Loading",
  "logout": "Logout",
  "logout_component": {
    "subtitle": "Safely logout from your account.",
    "title": "Sign out"
  },
  "main_picture": "Main picture",
  "male": "Male",
  "man": "Man",
  "manual_authentication": "Manual authentication",
  "maximum_x": "Maximum {x}",
  "me": "Me",
  "media_kit": "Media kit",
  "member_is_deleted": "This member is deleted",
  "member_is_suspended": "This member is suspended",
  "member_is_unsubscribed": "This member is unsubscribed",
  "member_on_pause": "Member on pause",
  "members": "Members",
  "membership_successfully_activated": "Membership successfully activated",
  "men": "Men",
  "men_and_women": "Men and women",
  "message": "Message",
  "messages": "Messages",
  "meta_description": "Register in a few minutes to the Quebec dating site Effet Tandem. Join thousands of singles on the online platform.",
  "meta_title": "Sign up for the Effet Tandem dating site [Serious relationships]",
  "metric": "Metric",
  "min_x_characters": "Minimum {x} characters",
  "minimum_x": "Minimum {x}",
  "modify_my_email": "Update my email address",
  "modify_my_email_description": "Change my email on the entire Effet Tandem network.",
  "modify_my_password": "Update my password",
  "modify_my_password_description": "Change my password on the entire Effet Tandem network. ",
  "month": "month",
  "more_than_a_week": "More than a week",
  "move_to_priority_inbox": "Move to primary inbox",
  "mutual": "mutual",
  "my_favorites": "My favorites",
  "my_profile": "My profile",
  "n_days": "1 day | {count} days",
  "new_password": "New password",
  "new_password_confirmation": "Password confirmation",
  "new_payment_method": "Add a payment method",
  "new_terms_modal": {
    "cta": "I accept",
    "title": "The terms and conditions of use have been updated"
  },
  "next": "Next",
  "no_article": "No article",
  "no_preference": "No preference",
  "no_quote_today": "No thoughts of the day today!",
  "no_result": "No result",
  "non_binaries": "Non binaries",
  "non_binary": "Non binary",
  "none": "None",
  "note": "No",
  "objective": "Objective",
  "offline": "Offline",
  "ok": "Ok",
  "old_password": "Old password",
  "on_pause": "On pause",
  "onboarding": {
    "email_already_exists": {
      "text": " If this is your account, you can login to your account below.",
      "title": "This email is already associated with an account."
    },
    "home": {
      "line_1": "You're taking the first step towards a genuine relationship.",
      "line_2": "Our secure registration process is designed to ensure you have a quality experience in a trusted community.",
      "line_3": "Our greatest wish is to help you in your quest and provide you with tools to help you find true love.",
      "line_4": "We wish you wonderful and happy encounters.",
      "title": "Welcome to the Effet Tandem Community"
    },
    "success": {
      "congrats_subtitle": "You are now just a few clicks away from experiencing the Effet Tandem. We hope that your experience will be enriching, rewarding and filled with wonderful discoveries.",
      "cta": "Start the experience",
      "subtitle": "Thank you for taking the time to complete your registration.",
      "title": "Congratulations! We thank you for completing the registration process."
    }
  },
  "one_single_payment_of_x_plus_taxes": "Single payment of {x} + tax",
  "online": "Online",
  "online_x_ago": "Online {interval} ago",
  "optional": "Optional",
  "optional_response": "Optional response",
  "or": "or",
  "or_continue_with": "Or continue with",
  "other": "Other",
  "page": {
    "ambassador": {
      "media_kit": {
        "expert_article": "Expert articles",
        "promotional_videos": "Promotional videos"
      },
      "share": {
        "content_types": {
          "article": {
            "description": "Share an expert article",
            "title": "Expert article"
          },
          "promo": {
            "description": "Share a promotional video ",
            "title": "Promotional video"
          },
          "website": {
            "description": "Share the home page of the Effet Tandem website",
            "title": "Effet Tandem's website"
          }
        }
      }
    },
    "blocked": {
      "line_1": "If you think this is a mistake, please contact customer support.",
      "title": "You account was blocked"
    },
    "favorites": {
      "empty_state": {
        "subtitle": "To add favorites, click on the heart-shaped button on the profile of the singles you are interested in. You will be able to more easily follow the updates of their profile.",
        "title": "You have not chosen any favorites"
      },
      "subtitle": "These singles put a smile on your face even if you are alone in front of your screen! For one reason or another, they have caught your attention. So follow your instincts and reserve that special space for those you'd like to get to know better.",
      "title": "My favorites"
    },
    "forgot_password": {
      "subtitle": "We will email you instructions on how to change it.",
      "success": "We have emailed your password reset link!",
      "title": "Forgot your password?"
    },
    "login": {
      "title": "Sign in to your account"
    },
    "messages": {
      "empty_list": {
        "subtitle": "When you send or receive your first message, it will appear here.",
        "title": "No message received"
      },
      "empty_secondary_list": {
        "subtitle": "When you receive a message in your secondary inbox, it will appear here.",
        "title": "No message received"
      },
      "no_conversation": {
        "button": "Find your partner in love",
        "subtitle": "You have not yet sent or received a message. To send a message, visit a single's profile and click on the \"Send a message\" button.",
        "title": "Your priority inbox is empty"
      },
      "no_secondary_conversation": {
        "subtitle": "You can limit who can contact you through your priority inbox at any time. The secondary inbox contains all the low priority messages according to your preferences.",
        "title": "Your secondary inbox is empty"
      },
      "no_selection": {
        "subtitle": "You can select a conversation from the menu on the left. "
      }
    },
    "not_found": {
      "title": "Page not found"
    },
    "personal_development": {
      "new": "New",
      "subtitle": "Designed by specialists and accessible to all, explore capsules that encourage avenues of personal reflection. Go beyond yourself towards the success of a healthy and lasting love life.",
      "title": "Personal development"
    },
    "profile": {
      "block_modal": {
        "description": "This person will not be able to send you a message or find your profile. They will not receive a notification about this.",
        "title": "Block {x} ?"
      },
      "call_init_modal": {
        "subtitle": "Once you have both authorized the video chat, you can call each other through Effet Tandem.",
        "switch": {
          "notice": "You can change your mind at any time by withdrawing the authorization.",
          "title": "I allow video conversations with {name}"
        },
        "title": "Allow {name} to start a video chat with you"
      },
      "call_unlocked": {
        "subtitle": "You can now video chat with {name}",
        "title": "Video chat unlocked"
      },
      "report_modal": {
        "description": "If someone is in immediate danger, call emergency services immediately.",
        "reason_codes": {
          "attempted_fraud": "Attempted fraud",
          "false_identity": "False identity",
          "graphic_picture": "Inappropriate picture",
          "harassment": "Harassment",
          "inappropriate_comments": "Inappropriate comments",
          "monetary_solicitation": "Monetary solicitation",
          "other": "Other"
        },
        "title": "Report {x} ?"
      },
      "share_member_modal": {
        "description": "You can use the link below to share this member's profile with your friends and family. The link is available for three (3) days.",
        "error": "An error occurred while generating the share link.",
        "title": "Share {x}'s profile"
      },
      "unblock_modal": {
        "description": "This person will be able to send you a message or find your profile again.",
        "title": "Unblock {x} ?"
      }
    },
    "reset_password": {
      "title": "Reset your password\n"
    },
    "search": {
      "end": {
        "cta": "Modify filters",
        "subtitle": "You can modify or remove certain filters to broaden your search. You may be on the verge of finding a soul mate!",
        "title": "You have reached the end of the results list"
      },
      "filter_box": {
        "description": "You can add a maximum of 5 filters"
      },
      "filters": {
        "accepts_persons_with_limitations": {
          "title": "Would accept to live with a person with limitations"
        },
        "age": {
          "title": "Age"
        },
        "alcohol_consumption_frequency": {
          "title": "Person's frequency of alcohol consumption"
        },
        "anonymous_mode": {
          "description": "Explore singles without seeing their photos in order to emphasize their personality and your common values.",
          "title": "Anonymous mode"
        },
        "areas_of_interest": {
          "title": "Interests"
        },
        "current_occupation": {
          "title": "Current occupation"
        },
        "education_level": {
          "title": "Education level"
        },
        "family_situation": {
          "title": "Family situation"
        },
        "food_preferences": {
          "title": "Food preferences"
        },
        "genders": {
          "title": "Genders"
        },
        "has_children": {
          "title": "Has children"
        },
        "height": {
          "title": "Height"
        },
        "keyword_research": {
          "description": "View profiles containing at least one of the keywords\nSeparate them with a comma.",
          "placeholder": "Eg. Horse, Horses, stable, stables",
          "title": "Keyword research"
        },
        "kids_living_with": {
          "title": "Children still live at home"
        },
        "language": {
          "title": "Languages"
        },
        "objective": {
          "title": "Looking for"
        },
        "personal_development_importance": {
          "title": "Personal development importance"
        },
        "personality_traits": {
          "title": "Personality traits"
        },
        "religion": {
          "title": "Belief / Religion"
        },
        "religious_belief": {
          "title": "Religious beliefs"
        },
        "silhouette": {
          "description": "You can select more than one",
          "title": "Silhouette"
        },
        "smoking": {
          "title": "Smoker"
        },
        "smoking_frequency": {
          "title": "Smoking frequency"
        },
        "smoking_tolerance_level": {
          "title": "Tolerance to smokers"
        },
        "values": {
          "title": "Values"
        },
        "wants_children": {
          "title": "Wants children"
        }
      },
      "learn_more": "Learn more",
      "max_filters": {
        "description": "You cannot add more than 5 filters at a time. You can modify some filters and perform a new search.",
        "title": "You cannot add more than 5 filters"
      },
      "onboarding": {
        "line_1": "The singles presented to you match all of the search criteria you selected. Please note, the more restrictive criteria you apply, the more you limit the number of potential candidates.",
        "line_2": "You have the choice to sort them according to the order of presentation that suits you best, either: those who share your values, those closest to you or according to their last connection on Effet Tandem.",
        "line_3": "Have a good search!",
        "title": "Find your partner in love"
      },
      "subtitle": "Find singles who match your values using advanced search filters.",
      "title": "Find your partner in love"
    },
    "settings": {
      "advanced": {
        "subtitle": "Advanced parameters",
        "title": "Advanced"
      },
      "blocks": {
        "empty_state": {
          "title": "No user blocked"
        }
      },
      "contact_us": {
        "subtitle": "If you have any questions or problems, do not hesitate to contact us.",
        "title": "Contact us"
      },
      "daily_quote": {
        "checkbox_description": "By sending my thought of the day, I accept that it may be corrected or modified by the Effet Tandem team.",
        "profil_not_public_message": "To post a thought of the day, your profile must be public.",
        "retry": "Submit another thought",
        "subtitle": "Participate in the Effet Tandem Community by sharing your thought for the day. This thought may be reflected on the Effet Tandem website with your name and photo.",
        "success": {
          "description": "Thank you for participating in the community! If selected, you will be notified by email within a few days of when your thought will be broadcast."
        },
        "title": "Write your thought of the day"
      },
      "menu": {
        "preferences": {
          "address": {
            "subtitle": "Change my residential address",
            "title": "My address"
          },
          "advanced": {
            "subtitle": "Advanced parameters",
            "title": "Advanced"
          },
          "anonymous_mode": {
            "subtitle": "Enable your custom search filters for an optimized experience. Trust your feelings, dare to discover the person beyond your first look at their file.",
            "title": "Anonymous mode"
          },
          "communications": {
            "subtitle": "Be notified by email when a message is received?",
            "title": "Communication preferences"
          },
          "language": {
            "subtitle": "Change my preferred language",
            "title": "Preferred language"
          },
          "resources": {
            "subtitle": "Additional resources and settings",
            "title": "Additional resources"
          },
          "title": "Preferences",
          "units": {
            "subtitle": "Choose my favorite units of measure",
            "title": "My units of measure"
          }
        },
        "profile": {
          "pictures": {
            "subtitle": "Submit, delete or reorder my profile pictures.",
            "title": "Pictures"
          },
          "profile": {
            "subtitle": "Complete my profile by updating or answering new questions.",
            "title": "Description card"
          },
          "sample_picture": {
            "subtitle": "Update your sample picture.",
            "title": "Sample picture"
          },
          "title": "Update my profile"
        },
        "security": {
          "blocks": {
            "subtitle": "Manage the users you have blocked. \nYou can unblock them at any time.",
            "title": "Blocked users"
          }
        },
        "subscription": {
          "payment_methods": {
            "subtitle": "Update my payment method.",
            "title": "Payment method"
          },
          "subscription": {
            "subtitle": "View information about the current subscription, renewal date and available packages. Also allows you to cancel the next automatic renewal.",
            "title": "Manage my subscription"
          },
          "title": "Subscription"
        },
        "visibility": {
          "inbox": {
            "subtitle": "Receive certain messages in your secondary inbox. Define your selection criteria.",
            "title": "Limit access to my priority inbox"
          },
          "pause": {
            "subtitle": "Activate this option to take a pause",
            "title": "Pause my profile"
          },
          "title": "Visibility",
          "visibility": {
            "subtitle": "Display my profile in public or private mode",
            "title": "Manage the visibility of my profile"
          }
        }
      },
      "pause": {
        "button": "Take a break",
        "description": "Want to take a break? By activating this option, you will no longer receive messages and will no longer be visible in the search tool. You may at any time reactivate your account to continue using Effet Tandem. Your subscription will continue to run.",
        "title": "Pause my profile"
      },
      "profile": {
        "about": {
          "description": "Stand out! Make yourself known in an authentic way so that the right person can find you. Describe who you are, what's important to you, what you like, your personality or your perception of life.",
          "placeholder": "Help the community discover you",
          "title": "Show your colors"
        },
        "introduction": {
          "subtitle": "Catch other members' eyes with an authentic text that highlight you.",
          "title": "Introduction"
        },
        "long_questions": {
          "subtitle": "The Effet Tandem community would like to get to know you better. You are invited to reveal more about yourself by answering one or two optional questions.",
          "title": "Long questions"
        },
        "pictures": {
          "delete_image": {
            "message": "Are you sure you want to remove this picture?",
            "title": "Remove picture?"
          },
          "delete_image_danger": {
            "message": "If you delete this picture, your photo album will be incomplete.",
            "title": "Be careful!"
          },
          "ordering_warning": "Your photo album's first picture must be a portrait.",
          "subtitle": "Add a new profile picture, or reorder delete exiting ones.",
          "title": "Profile pictures",
          "warning_min_1": "You need at least one picture"
        },
        "questions": {
          "subtitle": "Present who you are to other members in an authentic way in order to find the perfect match.",
          "title": "General information"
        },
        "seeking": {
          "description": "Be realistic. Wishing to meet a globetrotting athlete if you are sedentary would not make sense.",
          "description_2": "Watch out ladies, there are excellent candidates under 6 feet...",
          "placeholder": "His/her lifestyle, values",
          "title": "What are you looking for in your partner?"
        },
        "sexual_orientation": {
          "subtitle": "Update your sexual orientation",
          "title": "Sexual orientation"
        },
        "short_questions": {
          "subtitle": "Keep it light by answering these fanciful questions.",
          "title": "Fanciful questions"
        },
        "title": {
          "description": "This title will accompany your main photo in the “Search” section.",
          "placeholder": "Title or quote",
          "title": "Profile title or Favorite quote"
        }
      },
      "sample_picture": {
        "text_1": "We request that you take a picture with your hand and fingers, as shown in the example below.\nThis picture will be used for identification purposes only.",
        "text_2": "It will not be visible on your profile."
      },
      "secondary_conversation_rules": {
        "notice": "You receive a lot of messages, it becomes difficult to find your way! Direct some messages to your secondary inbox according to your own criteria (3 maximum).",
        "summary": "The message will be moved if the person answered \"Never\" to the question \"How often do you smoke?\" ",
        "summary_age": "The message will be directed to the secondary inbox if it comes from a person who has **less than {min}** and **more than {max}**.",
        "summary_distance": "The message will be directed to the secondary inbox if it comes from a person who is more than **{distance}** away.",
        "summary_height": "The message will be directed to the secondary inbox if it comes from a person who is **less than {min}** or **more than {max}** tall.",
        "summary_question": "The message will be directed to the secondary inbox if it comes from a person who has replied **{answers}** to the question **{question}**"
      },
      "subtitle": "Update your profile and adjust your personal preferences"
    },
    "subscribe": {
      "feature_1": {
        "name": "Send flowers to members"
      },
      "feature_2": {
        "name": "Advanced search filters"
      },
      "feature_3": {
        "name": "Thrilling encounters!"
      }
    },
    "subscription": {
      "product_end_notice": {
        "message": "Your subscription will be valid until {x}."
      },
      "subscription_end_notice": {
        "message": "Your subscription will automatically renew each month for {price} before taxes. | Your subscription will automatically renew every {count} months for {price} before taxes.",
        "message_2": "The next renewal will take place on {end}.",
        "message_3": "You can cancel the automatic renewal at any time in your account settings."
      },
      "subtitle": "All packages give access to all features."
    }
  },
  "pages": {
    "ambassador": {
      "balance": {
        "claim": "Claim a payment",
        "claim_notice": "You must have a minimum balance of {amount} to request a payment. If you have earned more than 500$ with us in a calendar year, we will ask you to provide your SIN. Payments will be made within 7 business days. A confirmation email will be sent to you once our team has processed your request.",
        "title": "Balance"
      },
      "info": {
        "description": "Let your friends know about Effet Tandem and <b>receive $10</b> for each new subscription of 3 months or more.",
        "title": "Give a free month a receive a cash reward"
      },
      "objective": {
        "additional_income": "Additional income",
        "for_fun": "Some references for fun",
        "fundraising": "Fundraising (coming soon)"
      },
      "objective_title": "What is your motivation for joining the Effet Tandem referral program?",
      "payments": {
        "no_payments": "No past payments",
        "title": "Payments"
      },
      "rewards": {
        "description": "See your rewards and claim a payment."
      },
      "settings": {
        "link": "Change my address, tax numbers and other financial information.",
        "title": "Parameters"
      },
      "share": {
        "description": "Copy your personal referral link and share it with your friends and followers.",
        "title": "Share your link"
      },
      "share_content": {
        "description": "Share articles to followers and friends. You will receive credit if they subscribe after visiting the article via your link."
      },
      "sharing_tools": {
        "description": "Share promotional articles and videos via email or social media.",
        "steps": {
          "article": {
            "subtitle": "Select the expert article you want to share.",
            "title": "Select the expert article"
          },
          "content": {
            "subtitle": "Select the type of content you want to share with your friends or subscribers.",
            "title": "What would you like to share ?"
          },
          "media": {
            "subtitle": "Select the platform on which you want to share the content.",
            "title": "Select the sharing platform"
          },
          "share": {
            "subtitle": "Click the button below to share your referral link with your friends or subscribers.",
            "title": "Share with your friends !"
          },
          "video": {
            "subtitle": "Select the promotional video you want to share.",
            "title": "Select the promotional video"
          }
        }
      },
      "sin_warning": {
        "button": "Add my SIN",
        "title": "As you earned more than $500 in the current or previous fiscal year, you must provide your SIN."
      },
      "stats": {
        "title": "Referral stats"
      },
      "subtitle": "Help Build a better world, one relationship at a time",
      "title": "Refer your friends"
    },
    "onboarding": {
      "email_verification": {
        "alert": "Oops, your email address has not been verified, please check your email.",
        "body": {
          "line_1": "Look for the verification email in the **{email}** inbox and follow the instructions.",
          "line_2": "If you do not see the email, please check your spam folder."
        },
        "issue": {
          "line_1": "Check your spam folder to make sure it didn't end up there. You can also add the email address {from} to your address book and then try sending the email again.",
          "line_2": "If you still don't receive the email, check your spam. Otherwise contact our customer support to help you solve the problem.",
          "title": "Didn't get the email?"
        },
        "title": "Check your emails"
      },
      "email_verification_success": {
        "text": "Your email address has been successfully verified. You can now continue with your registration.",
        "title": "Email successfully verified"
      },
      "terms": {
        "body": "By using Effet Tandem, you accept the terms and conditions of use.",
        "title": "Terms and conditions"
      }
    },
    "personalDevelopment": {
      "article": {
        "description": "Expert articles to encourage self-discovery.",
        "subtitle": "Encouraging self-discovery.",
        "title": "Articles"
      },
      "bonus": {
        "description": "Here are 3 bonus courses worth a total of $494 offered as a bonus for singles who have chosen to subscribe to the Effet Tandem 6 month package.",
        "subtitle": "Gifts for subscribers to the 6-month plan.",
        "title": "Bonus Effet 6 Months"
      },
      "specialists": {
        "subtitle": "Collaboration of renowned multidisciplinary specialists.",
        "title": "Our specialists"
      },
      "video": {
        "description": "Video capsules promoting awareness towards the success of your love life.",
        "subtitle": "Promoting the success of your love life.",
        "title": "Video capsules"
      }
    },
    "referrals": {
      "no_referral": "No referral at the moment",
      "title": "Referrals"
    }
  },
  "paid": "Paid",
  "password": "Password",
  "password_confirmation": "Password confirmation",
  "password_rule": {
    "lower_and_cap": "Must contain at least one uppercase and one lowercase",
    "min_size": "Must contain a minimum of 8 characters",
    "number": "Must contain at least one number"
  },
  "past_payments": "Past payments",
  "pay_now": "Pay now",
  "payment_method": "Payment method",
  "payment_success_component": {
    "subtitle": "Thank you for your confidence, we are pleased that you have decided to continue the Effet Tandem experience with our community.",
    "title": "Payment successfully completed"
  },
  "pending": "Pending",
  "pending_claim": "Pending claim",
  "pending_names_authorization": "Awaiting {name}'s authorization",
  "pending_pictures": "Pictures pending",
  "pending_validation": "Pending validation",
  "personal_development": "Personal development",
  "personality_traits": "Personality traits",
  "phone": "Phone",
  "picture_mode": "Picture mode",
  "picture_successfully_uploaded": "Picture successfully uploaded",
  "picture_validation_notice": {
    "attributes": {
      "match": "Matches your sample photo",
      "portrait": "Clearly shows your face, without hat, sunglasses or any other item covering a substantial part of you face",
      "terms": "Complies with the terms of use"
    },
    "message": "At Effet Tandem, we are committed to offering you an authentic experience and a safe space where you can evolve in complete confidence. That's why our team is committed to validate and authenticate the photos of each member to ensure their identity and guarantee peace of mind during your encounters.",
    "message_2": "A picture can have one of three statuses: validated, authenticated or portrait.",
    "status": {
      "authenticated": "Authenticated picture",
      "portrait": "Portrait picture",
      "validated": "Validated picture"
    },
    "title": "How does picture validation and authentication work?"
  },
  "please_enter_your_phone_number": "Please enter your phone number",
  "portrait": "Portrait",
  "postal_code_zip_code": "Postal Code / Zip Code",
  "premium_content": "Premium content",
  "preview_my_profile": "Preview my profile",
  "previous": "Previous",
  "primary": "Primary",
  "priority": "Priority",
  "priority_inbox": "Priority inbox",
  "priority_inbox_description": "Indicates whether you want to receive an email notification when you receive a message in your priority inbox.",
  "privacy_policy": "Privacy policy",
  "product_not_found": "Product not found!",
  "profile_preview": "Profile preview",
  "proximity": "Proximity",
  "pst_qst": "PST/QST",
  "re_enter_email_address": "Re-enter email address",
  "re_enter_your_new_email_address": "Re-enter your new email address",
  "read_more": "Read more",
  "redeem": "Redeem",
  "refer_your_friends": "Refer your friends",
  "referral_code": "Referral code",
  "referral_link": "Referral link",
  "referrals": "Referrals",
  "refine_your_search": "Refine your search",
  "refused": "Refused",
  "refused_pictures": "Refused pictures",
  "register_modal": {
    "line_1": "Effet Tandem is a safe dating site based on authenticity and personal development."
  },
  "register_now": "Register now",
  "religion": "Belief / Religion",
  "religious_beliefs": "Religious beliefs",
  "remove": "Remove",
  "report": "Report",
  "required": "Required",
  "resend_email": "Resend email",
  "resend_link": "Resend link",
  "reset": "Reset",
  "reset_all": "Reset all",
  "reset_password": "Reset password",
  "return_home": "Return home",
  "return_to_login": "Return to login",
  "return_to_settings": "Return to settings",
  "reveal_my_pictures": "Reveal my pictures",
  "rewards": "Rewards",
  "sample_picture_specifications": "Sample picture specifications",
  "save": "Save",
  "scroll": "Scroll",
  "search": "Search",
  "search_a_new_address": "Search a new address",
  "search_an_article": "Search an article",
  "search_filters": "Search filters",
  "search_is_done_from_this_address": "The search is made from this address",
  "secondary": "Secondary",
  "secondary_inbox": "Secondary inbox",
  "secondary_inbox_description": "Indicates whether you want to receive an email notification when you receive a message in your secondary inbox.",
  "security": "Security",
  "see_more": "See more",
  "seen": "Seen",
  "select": "Select",
  "select_a_question": "Select a question",
  "select_an_article": "Select an article",
  "select_an_item": "Select an item",
  "select_an_option": "Select an option",
  "select_at_least_x": "Select at least {x}",
  "selected": "Selected",
  "send": "Send",
  "send_a_flower": "Offer a flower",
  "send_a_flower_back": "Offer a flower back",
  "send_a_message": "Send a message",
  "send_email": "Send email",
  "send_flower_confirmation": {
    "line_1": "You only have one flower per day. Make sure you offer it to the right person.",
    "title": "Offer a flower?"
  },
  "settings": "Settings",
  "share": "Share",
  "share_by_email": "Share by email",
  "share_content": "Share content",
  "share_member_header_f": "What do you think of this single ?",
  "share_member_header_m": "What do you think of this single ?",
  "share_on_facebook": "Share on Facebook",
  "share_on_messenger": "Share on Messenger",
  "share_on_twitter": "Share on Twitter",
  "sharing_tools": "Sharing tools",
  "sign_in": "Sign in",
  "sign_in_with": "Sign in with",
  "sign_in_with_x": "Sign in with {x}",
  "sign_up_with_email": "Sign up with email",
  "sign_up_with_x": "Sign up with {x}",
  "silhouette": "Silhouette",
  "skip": "Skip",
  "skip_authentication": "Skip authentication",
  "skip_question": "Skip question",
  "sort": "Sort",
  "sorts": {
    "last_connection": {
      "description": "Shows the singles who logged in recently first.",
      "title": "Last connection"
    },
    "proximity": {
      "description": "Show the singles closest to you first.",
      "title": "Proximity"
    },
    "values": {
      "description": "Show the singles who have the most values in common with me first.",
      "title": "Values"
    }
  },
  "special_offer": "Special offer",
  "spoken_languages": "Spoken languages",
  "start_recording": "Start recording",
  "start_your_search": "Start your search",
  "start_your_search_for_love": "Start your search for love",
  "state_paused": {
    "button": "End pause",
    "message": "This means that you want to temporarily take a break. Your profile will no longer be visible to anyone on this site. Your subscription time will continue to run out.",
    "message_2": "You can end your break using the button below.",
    "message_3": "Take the opportunity to consult our Flourish section. It presents food for thought via articles and videos produced by specialists from different fields.",
    "title": "You are currently on a break"
  },
  "state_prelaunch": {
    "message": "We are in the pre-launch period and not all sections are available yet. Check back on June 20th, 2022 for our official launch!",
    "message_1": "Congratulations on your registration and thank you for joining the Effet Tandem community! Your profile is now ready for the June 20th launch.",
    "message_2": "From the launch on June 20th, you will have access to all the features of the site and you will finally be able to meet your future half.",
    "message_3": "During this pre-registration period, take the time to recharge your batteries in the Flourish section, which features several specialist videos.",
    "message_4": "Share the word with your friends and relatives to enlarge the community while being rewarded thanks to the Referral program.",
    "title": "In prelaunch!"
  },
  "state_province": "State / Province",
  "state_suspended": {
    "incomplete_gallery": {
      "cta": "Update my photo album",
      "line_1": "Unfortunately, your photo album has still not been adjusted even though it did not meet the standards of our site.",
      "line_2": "Following the grace period, we temporarily suspended your account. During this time, you will not be visible to the rest of the community.",
      "line_3": "We strongly encourage you to make the necessary adjustments to enhance your profile and meet your future partner."
    },
    "other": {
      "line_1": "You account was suspended by the administration. If you think this was a mistake, please contact customer support."
    },
    "title": "Your account has been suspended"
  },
  "state_unsubscribed": {
    "message": "You are currently unsubscribed and no longer have access to Effet Tandem's features. To subscribe, please choose one of the packages in the section below.",
    "title": "You are unsubscribed"
  },
  "status": {
    "email_not_verified": {
      "title": "Your email address has not been verified"
    },
    "pause": {
      "title": "Profile paused"
    },
    "pending_authentication": {
      "title": "Profile pending authentication"
    }
  },
  "step": {
    "audio_introduction": {
      "text": "In a few words, present yourself or welcome visitors to your profile. Simple and brilliant!",
      "title": "Record a quick welcome message on your profile."
    },
    "authentication": {
      "accept": {
        "text": "I authorize the company Effet Tandem Inc to use the eIDComparaison service to confirm the validity of my name, my date of birth and my place of residence.",
        "text_2": "This service, operated by the Equifax company, is used exclusively for the identification of individuals.",
        "text_3": "Your details will never be shared or sold."
      },
      "error": {
        "cta": "Understood",
        "line_1": "Your identity will be verified manually by an administrator. In the meantime, you can continue your registration process.",
        "line_2": "Our customer service will contact you shortly. Please enter your phone number and availability in the fields below.",
        "title": "Error while validating your profile"
      },
      "no_match_skip_text": "Your identity will be verified manually by an administrator. In the meantime, you can continue your registration process.",
      "no_match_skip_text_2": "Our customer service will contact you shortly. Please enter your phone number and availability in the fields below.",
      "remaining_attempts": "You have no attempt remaining. | You only have one attempt remaining. | You have {count} attempts remaining.",
      "success": {
        "line_1": "You have been authenticated! You are now authorized to use the Effet Tandem site.",
        "title": "Your identity has been successfully authenticated"
      },
      "success_text": "Your identity has been authenticated by the eIDCompare system",
      "text": "Effet Tandem has hired a \"cyber-doorman\" to protect you! By verifying our users’ identity with eIDCompare®, we reduce the risk of fraudulent profiles. It allows protection against schemes and guarantees the users’ exact age and location. **Only your first name and the city you live in will be visible on your profile.**",
      "title": "Identity verification",
      "warning": "Make sure you provide your name, date of birth and place of residence duly indicated on your official documents **OR YOU WILL BE REFUSED**.",
      "warning_modal": {
        "title": "Authentication issue"
      },
      "warning_same_user_modal": {
        "contact": "If you think the situation does not apply to you or you have questions, contact our customer service at {phone}.",
        "line_1": "A user with the same name and birthday is already registered using the following email: {email}",
        "line_2": "This user was registered using {login}.",
        "logout": "Sign in to another account",
        "title": "A user with the same name and birthday is already registered"
      }
    },
    "introduction": {
      "title": "Catch the eye of members with authentic texts that highlight you."
    },
    "introduction_video": {
      "title": "A helping hand to help you introduce yourself?"
    },
    "long_question": {
      "text": "You can answer a maximum of {max} questions (optional).",
      "title": "The Effet Tandem community would like to know you better"
    },
    "payment": {
      "title": "Choose your plan",
      "trial": {
        "cta": "Start free trial",
        "title": "Or continue with a free trial for 5 days!"
      }
    },
    "pictures": {
      "main_photo_notice": {
        "line_1": "Close-up photo that can identify you",
        "line_2": "Front view with no sunglasses nor hat",
        "line_3": "Recently taken and of good quality",
        "line_4": "Posing alone",
        "line_5": "Avoid being too suggestive",
        "line_6": "Avoid filters or retouching on your photos",
        "text": "The main picture for your profile must meet to the following criteria:"
      },
      "text": "Share your nicest photos. Smiling is contagious!",
      "title": "Create your photo album",
      "warning": "A minimum of two pictures must be submitted. Pictures will be validated and identified.\n"
    },
    "pictures_video": {
      "text_1": "The choice of your photos must be done with care. They are your virtual profile. Your main photo should be of the passport type (but with a smile). We suggest that you also post a head-to-toe photo.",
      "text_2": "We reserve the right to refuse certain pictures.",
      "title": "7 tips to carefully choose your pictures"
    },
    "sample_picture": {
      "image_no_accessories": "No hats, glasses or accessories that hide a substantial part of your face.",
      "info": "If your picture ID does not follow our specifications, your profile will not be displayed on our website.",
      "text_1": "Effet Tandem is mindful in identifying its users. We request that you take a picture with your hand and fingers, as shown in the example below.",
      "text_2": "This picture will be used for identification purposes only. It will not be visible on your profile.",
      "title": "Your picture ID for authentication purposes"
    },
    "short_question": {
      "text": "You may answer as many questions as you like (optional)",
      "title": "Keep it light by answering these fanciful questions."
    }
  },
  "subject": "Subject",
  "submit": "Submit",
  "submitted_by_x": "Suggested by {name}",
  "subscribe": "Subscribe",
  "subscribe_now": "Subscribe now",
  "subscription": "Subscription",
  "success": "Success",
  "support": "Support",
  "suspended": "Suspended",
  "tagline": "Connect and flourish",
  "tax_numbers": "Tax numbers",
  "terms_and_conditions": "Terms and conditions",
  "the_file_size_must_not_exceed_x": "The file size must not exceed {x}",
  "the_image_must_be_at_least_x_high": "the image must be at least {x} high",
  "the_image_must_be_at_least_x_wide": "the image must be at least {x} wide",
  "the_terms_and_conditions": "The Terms and Conditions",
  "the_thought_of_the_day": "Thought of the day",
  "this_conversation_is_in_x_secondary_inbox": "{x} will see this conversation in the secondary inbox",
  "title_quote": "Title / Quote",
  "try_again": "Try again",
  "try_again_with_another_email": "Try with another email",
  "try_another_email": "Try another email",
  "type_an_email": "Type an email",
  "unavailable": "Unavailable",
  "unavailable_user": "Unavailable user",
  "unblock": "Unblock",
  "understood": "Understood",
  "units": {
    "b": "B",
    "gb": "GB",
    "kb": "kB",
    "mb": "MB",
    "tb": "TB"
  },
  "unknown_author": "Unknown author",
  "unsubscribed": "Unsubscribed",
  "up_to_x": "up to {x}",
  "update": "Update",
  "update_address": "Update address",
  "update_payment_method": "Update payment method",
  "upload_a_picture": "Upload a picture",
  "upload_a_sample_picture": "Upload your sample picture",
  "upload_new_file": "Upload new file",
  "upload_or_drag_and_drop": "Upload a file or drag and drop",
  "upload_your_invoice": "Upload your invoice",
  "urls": {
    "contact": "en/contact-us",
    "privacy": "en/privacy-policy",
    "terms": "en/terms-and-conditions"
  },
  "use_my_default_address": "Use my default address",
  "validated": "Validated",
  "values": "Values",
  "video_clips": "Video clips",
  "view_profile": "View profile",
  "vip_members": "6 Months Members",
  "visibility": "Visibility",
  "visit_website": "Visit website",
  "visits": "Visits",
  "voice": "Voice",
  "vote_sent_successfully": "Rating successfully sent",
  "wants_children": "Wants children",
  "warning": "Warning",
  "what_do_you_think_of_the_article": "What do you think of the article?",
  "what_i_am_looking_for_in_my_partner": "What I am looking for in my partner",
  "what_is_your_gender": "What gender do you identify with?",
  "whats_your_sexual_orientation": "Who are you attracted to?",
  "whoops": "Whoops!",
  "with_camera": "With camera",
  "with_video": "With video",
  "woman": "Woman",
  "women": "Women",
  "x_days_remaining": "No remaining days | 1 remaining day | {count} days remaining",
  "x_did_not_answer_the_call": "{x} did not answer the call",
  "x_digits_code": "{x} digits codes",
  "x_free_months": "{count} free month|{count} free months",
  "x_seconds_recording_maximum": "Record up to {x} seconds",
  "x_selected": "{x} selected",
  "x_tax_included": "{x} tax included",
  "x_values_in_common": "<span class=\"font-bold\">No values</span> in common | <span class=\"font-bold\">1 value</span> in common | <span class=\"font-bold\">{count} values</span> in common",
  "x_wants_to_start_a_video_call": "{x} wants to start a video call",
  "year": "Year",
  "years_old": "years old",
  "yes": "Yes",
  "you_already_sent_a_flower_in_conversation": "You already offered a flower to this person",
  "you_already_sent_a_flower_today": "You already sent a flower today, wait for it to grow back!",
  "you_are_in_a_trial_period": "You are in a trial period",
  "you_are_receiving_a_call_from_x": "{x} is calling you",
  "you_are_subscribed": "You are subscribed",
  "you_are_unsubscribed": "You are unsubscribed",
  "you_are_unsubscribed_description": "You are currently unsubscribed and no longer have access to Effet Tandem's features. To subscribe, please choose one of the packages in the section below.",
  "you_can_answer_up_to_x_questions": "You can answer up to {x} questions",
  "you_can_select_more_than_one": "You can select multiple options",
  "you_can_upload_up_to_x_pictures": "You can upload up to {x} pictures",
  "you_cannot_select_more_than_x_items": "You cannot select more than {x} items",
  "you_cant_offer_a_flower": "You can't offer a flower",
  "you_currently_have_no_payment_method": "You currently have no payment method",
  "you_did_not_receive_the_email": "You didn't receive the email ?",
  "you_dont_have_an_account": "You don't have an account?",
  "you_have_this_in_common": "You have this in common",
  "you_may_add_additional_months_to_your_subscription_below": "You may add additional months to your subscription in the section below.",
  "you_may_select_more_than_one": "You can select more than one",
  "you_must_read_the_terms_and_conditions_before_accepting": "You must read the terms and conditions before accepting them",
  "you_must_upload_at_least_x_pictures": "You must upload at least {x} pictures",
  "you_must_upload_between_x_to_y_pictures": "You must upload {x} to {y} photos",
  "you_scroll_read_the_terms_and_conditions_before_accepting": "You must scroll and read the terms and conditions above before accepting.",
  "your_address": "Your address",
  "your_current_email": "Your current email",
  "your_current_email_address": "Your current email address",
  "your_first_picture_must_be_a_portrait": "Your first picture must be a portrait",
  "your_payment_method_was_successfully_added": "Your payment method was successfully added",
  "your_phone_number": "Your phone number",
  "your_profile": "Your profile",
  "your_subscription_ends_on_x": "Your subscription ends on {x}",
  "your_thought_of_the_day": "Your thought of the day",
  "your_trial_ends_on_x": "Your trial ends on {x}"
}
