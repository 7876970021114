<template>
  <div class="bg-gray-50 pt-safe">
    <BaseContainer size="xl">
      <BaseTitle
        :title="$t('page.settings.menu.subscription.payment_methods.title')"
        :subtitle="$t('page.settings.menu.subscription.payment_methods.subtitle')"
        :previous="false"
      />
    </BaseContainer>

    <div class="mb-10">
      <BaseContainer size="xl">
        <BaseCard>
          <BaseCardRow>
            <div class="mb-0">
              <router-link
                class="bg-none flex items-center border-none appearance-none"
                :to="{
                  name: 'member.settings.payment_source'
                }"
              >
                <Icon
                  class="w-6 h-6 mr-2 text-gray-600"
                  icon="mdi-chevron-left"
                />
                <span>{{ $t('back_to_payment_methods') }}</span>
              </router-link>
            </div>
          </BaseCardRow>
          <BaseCardRow>
            <div class="max-w-md">
              <StorePaymentSource
                :button-text="$t('update_payment_method')"
                @success="$router.push({
                  name: 'member.settings.payment_source'
                })"
              />
            </div>
          </BaseCardRow>
        </BaseCard>
      </BaseContainer>
    </div>

    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StorePaymentSource from "./components/web/StorePaymentSource.vue";

export default defineComponent({
  components: {
    StorePaymentSource
  },
});
</script>
