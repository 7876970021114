<template>
  <div
    ref="wrapper"
    class="wrapper"
  >
    <textarea
      :value="modelValue"
      :name="name"
      :placeholder="placeholder"
      rows="1"
      class="focus:outline-none focus:ring-0"
      @input="onInput"
      @keyup="onKeyUp"
      @keydown="onKeyDown"
      @focus="onFocus"
    />
  </div>
</template>

<script lang="ts">
import { ComputedRef, defineComponent, inject } from "vue";

const keys = {} as {[key:string]: boolean};

export default defineComponent({
  props: {
    modelValue: {
      default: "",
      type: String
    },
    placeholder: {
      default: "",
      type: String
    },
    name: {
      required: true,
      type: String
    }
  },
  emits: ["update:modelValue", "submit", "focus", "input"],
  setup () {
    const mobile = inject("mobile") as ComputedRef<boolean>;
    return {
      mobile
    };
  },
  watch: {
    modelValue (value: string) {
      this.updateReplicatedValue(value);
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onInput (e: any) {
      const value = e.target.value ?? "";
      this.$emit("update:modelValue", value);
      this.$emit("input", e);
      this.updateReplicatedValue(value);
    },
    updateReplicatedValue(value: string) {
      const wrapper = this.$refs.wrapper as HTMLElement;
      wrapper.dataset.replicatedValue = value;
    },
    onKeyDown(event: any) {
      keys[event.key as number] = true;
    },
    onKeyUp(event: any) {
      delete keys[event.key];
    },
    onFocus(event: any) {
      this.$emit("focus", event);
    }
  }
});
</script>

<style lang="postcss" scoped>
.wrapper {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.wrapper::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.wrapper > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  @apply focus:outline-none;
  @apply focus-visible:outline-none;
}
.wrapper > textarea,
.wrapper::after {
  /* Identical styling required!! */
  padding: 0.5rem;
  font: inherit;

  @apply text-base;
  @apply max-h-28;
  @apply border-transparent;
  @apply bg-gray-100;
  @apply rounded-lg;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
</style>
