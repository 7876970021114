<template>
  <div
    class="mb-6"
    :class="backUrl ? 'flex items-center' : 'block'"
  >
    <RouterLink
      v-if="backUrl"
      :to="backUrl"
    >
      <Icon
        class="shrink-0 w-8 h-8 mr-3 -ml-2 text-gray-700"
        icon="heroicons-outline:chevron-left"
      />
    </RouterLink>
    <component
      :is="native ? 'div' : 'a'"
      :href="$websiteUrl('/' + $i18n.locale)"
    >
      <img
        src="@/assets/img/logo/logo-name-gray.svg"
        alt="Effet Tandem"
        class="w-52"
      >
    </component>
  </div>
</template>

<script lang="ts">
import { Capacitor } from "@capacitor/core";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    backUrl: {
      default: null,
      type: String,
    }
  },
  data () {
    return {
      native: Capacitor.isNativePlatform()
    };
  },
  computed: {
    showBack() : boolean {
      return ["onboarding.home", "onboarding.start", "onboarding.register"].includes(this.$route.name as string);
    },
  },
});
</script>
