<template>
  <nav
    v-if="lastPage > 1 || (lastPage < modelValue)"
    class="sm:px-0 flex items-center justify-between px-4 border-t border-gray-200"
  >
    <div class="flex flex-1 w-0 -mt-px">
      <button
        :disabled="modelValue == 1"
        class="hover:text-gray-700 hover:border-gray-300 disabled:cursor-not-allowed disabled:opacity-60 inline-flex items-center pt-4 pr-1 text-sm font-medium text-gray-500 border-t-2 border-transparent"
        @click="previous()"
      >
        <Icon
          class="w-5 h-5 mr-3 text-gray-400"
          icon="heroicons-solid/arrow-narrow-left"
        />
        {{ $t('previous') }}
      </button>
    </div>
    <div class="md:-mt-px md:flex hidden">
      <button
        v-for="i in lastPage"
        :key="i"
        type="button"
        class="hover:text-gray-700 hover:border-gray-300 inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2"
        :class="i == modelValue ? 'border-primary-500 text-primary-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'"
        @click="$emit('model-value:update', i)"
      >
        {{ i }}
      </button>
    </div>
    <div class="flex justify-end flex-1 w-0 -mt-px">
      <button
        :disabled="modelValue >= lastPage"
        class="hover:text-gray-700 hover:border-gray-300 disabled:cursor-not-allowed disabled:opacity-60 inline-flex items-center pt-4 pl-1 text-sm font-medium text-gray-500 border-t-2 border-transparent"
        @click="next()"
      >
        {{ $t('next') }}
        <Icon
          class="w-5 h-5 mr-3 text-gray-400"
          icon="heroicons-solid/arrow-narrow-right"
        />
      </button>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    lastPage: {
      required: true,
      type: Number
    },
    modelValue: {
      required: true,
      type: Number
    }
  },
  emits: {"model-value:update": null},
  methods: {
    next() {
      if (this.modelValue >= this.lastPage) {
        return;
      }
      this.$emit("model-value:update", this.modelValue + 1);
    },
    previous() {
      if (this.modelValue == 1) {
        return;
      }
      this.$emit("model-value:update", this.modelValue - 1);
    }
  }
});
</script>
