<template>
  <div
    ref="card"
    class="bg-white border-t border-b border-gray-200"
    :class="{
      'rounded-lg border-r border-l shadow-sm': width != windowWidth,
      'overflow-hidden': clipped
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    clipped: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      windowWidth: 0,
      width: 0,
    };
  },
  mounted () {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = document.body.clientWidth;
      this.width = (this.$refs.card as HTMLElement).offsetWidth;
    }
  }
});
</script>
