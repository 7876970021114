<template>
  <BaseAlert
    type="warning"
    class="border border-yellow-400"
  >
    <p>{{ $t('pages.ambassador.sin_warning.title') }}</p>
    <router-link
      class="inline-block mt-2 font-semibold text-yellow-700 underline"
      to="/ambassador/settings#sin"
    >
      {{ $t('pages.ambassador.sin_warning.button') }}
    </router-link>
  </BaseAlert>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
