<template>
  <div class="shrink-0 pb-safe static flex flex-col w-full overflow-hidden border-t border-gray-200">
    <div
      v-if="showResponsesWidget"
      class="pt-2"
    >
      <WidgetResponses
        @write-message="writeMessage($event)"
      />
    </div>

    <!-- Input -->
    <!-- Height must be auto since BaseAutoResizeTextarea can grow vertically  -->
    <div class="min:h-16 static flex items-end w-full h-auto px-3 py-3 space-x-2">
      <div class="static flex items-center justify-center -space-x-1">
        <EmojiTooltip
          v-if="!mobile"
          class="w-10 h-10"
          :close-on-select="true"
          @select="addEmoji"
        >
          <div
            class="text-primary-500 hover:bg-gray-100 hover:text-primary-600 inline-flex items-center justify-center w-10 h-10 transition-colors duration-150 rounded-full"
          >
            <Icon
              icon="heroicons-solid:emoji-happy"
              class="w-6 h-6"
            />
          </div>
        </EmojiTooltip>
        <SendImage />
        <SendAudio />
      </div>
      <form
        action=""
        class="grow"
        @submit.prevent="sendMessage()"
      >
        <BaseAutoResizeTextarea
          v-model="input"
          :placeholder="$t('chat.placeholder')"
          name="message"
          @submit="sendMessage()"
          @focus="onFocus"
          @input="onInput"
        />
      </form>
      <button
        class="btn btn-primary shrink-0 inline-flex items-center justify-center w-10 h-10 p-0 rounded-lg"
        @click="sendMessage()"
      >
        <div>
          <Icon
            icon="heroicons-solid:paper-airplane"
            class="static w-5 h-5 text-white"
          />
        </div>
      </button>
      <button
        v-if="showFlower"
        ref="flowerButton"
        type="button"
        class="shrink-0 inline-flex items-center justify-center w-10 h-10 p-0 duration-150 bg-white rounded-lg outline-none appearance-none"
        :class="{
          'opacity-50 cursor-not-allowed': conversation.cant_send_flower_message,
          'hover:scale-105': !conversation.cant_send_flower_message,
        }"
        @click="sendFlower()"
      >
        <FlowerIllustration
          size="small"
          :gender="me.gender"
        />
      </button>
    </div>
  </div>

  <SendFlowerConfirmation
    v-model="showFlowerConfirmation"
    @send="sendFlowerRequest"
  />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, inject, onBeforeUnmount, Ref } from "vue";
import WidgetResponses from "./WidgetResponses.vue";
import { Conversation, ConversationUser } from "@/models/Conversation";
import FlowerIllustration from "@/plugins/icons/FlowerIllustration.vue";
import SendAudio from "./SendAudio.vue";
import { throttle } from "lodash-es";
import { echo } from "@/services/echo";
import { store } from "@/store";
import SendImage from "./SendImage.vue";
import { uuid } from "@/services/uuid";

export default defineComponent({
  components: {
    WidgetResponses,
    FlowerIllustration,
    // Emoji Tooltip is a big component, async loading for first load perf improvement
    "EmojiTooltip": defineAsyncComponent(() => import("./EmojiTooltip.vue")),
    SendAudio,
    SendImage
  },
  emits: ["fetch"],
  setup () {
    const mobile = inject("mobile") as boolean;
    const conversation = inject("conversation") as Ref<Conversation>;
    const softFetch = inject("softFetch") as () => void;
    const resetTypingCountdown = inject("resetTypingCountdown") as () => void;
    const scrollBottom = inject("scrollBottom") as () => void;
    const addMessage = inject("addMessage") as (message: string) => string|null;
    const removeMessage = inject("removeMessage") as (id: string|null) => void;
    const user = inject("user") as ConversationUser;
    const me = inject("me") as ConversationUser;

    const whisperChannel = `conversation.${conversation.value.id}`;

    const channel = echo.private(whisperChannel) as any;

    channel
      .listenForWhisper("typing", (e: any) => {

        if (e.user_id != store.state.user?.id) {
          resetTypingCountdown();
        }
      });

    const sendTyping = throttle(() => {
      channel.whisper("typing", {
        user_id: store.state.user?.id
      });
    }, 900);

    onBeforeUnmount(() => {
      echo.leave(whisperChannel);
    });

    return {
      mobile,
      conversation,
      softFetch,
      scrollBottom,
      user,
      me,
      sendTyping,
      addMessage,
      removeMessage,
    };
  },
  data () {
    return {
      input: "",
      showFlowerConfirmation: false,
      sending: false,
    };
  },
  computed: {
    showFlower() : boolean {
      if (this.user.admin) {
        return false;
      }

      if (this.me.pivot.sent_flower) {
        return false;
      }

      return true;
    },
    showResponsesWidget() : boolean {
      if (this.user.admin) {
        return false;
      }

      if (this.me.pivot.has_responded) {
        return false;
      }

      if (this.conversation.user_id == (this.$store.state.user?.id ?? null)) {
        return false;
      }

      if (this.conversation.last_message?.is_me ?? false) {
        return false;
      }

      return true;
    }
  },
  mounted () {
    if (this.conversation.cant_send_flower_message && this.$refs.flowerButton) {

      const message = this.$display.cantSendFlowerMessage(this.conversation.cant_send_flower_message);

      this.$tippy(this.$refs.flowerButton as HTMLElement, {
        content: message,
      });
    }
  },
  methods: {
    writeMessage (message: string) {
      this.input = message;
    },
    sendMessage() {
      if (this.input == "") {
        return;
      }

      if (this.sending) {
        return;
      }

      this.sending = true;

      const content = this.input;

      const fakeMessageId = this.sendFakeMessage(content);

      this.input = "";

      this.$api.post(`/conversations/${this.conversation.id}/messages`, {
        type: "content",
        content: content,
      })
        .then(() => {
          this.afterSend();
        })
        .catch(() => {
          this.removeMessage(fakeMessageId);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    sendFlower() {

      if (this.conversation.cant_send_flower_message) {
        return;
      }

      this.showFlowerConfirmation = true;
    },
    sendFlowerRequest() {
      this.$api.post(`/conversations/${this.conversation.id}/messages`, {
        type: "flower",
      })
        .then(() => {
          this.afterSend();
        });
    },
    afterSend() {
      this.softFetch();
    },
    addEmoji(unicode:string) {
      this.input += unicode;

      this.sendTyping();
    },
    onFocus() {
      this.scrollBottom();
    },
    onInput() {
      this.sendTyping();
    },
    sendFakeMessage(content: string) : string|null {
      return this.addMessage(content);
    }
  }
});
</script>
