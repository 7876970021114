<template>
  <div
    class="mb-1"
    :class="{'opacity-70 hover:opacity-100': modelValue == undefined}"
  >
    <div class="flex justify-between mb-3 text-sm text-gray-600">
      <div
        v-for="label in labels"
        :key="label"
        class="font-mono text-sm text-gray-600"
      >
        {{ label }}
      </div>
    </div>
    <div class="px-5">
      <div
        ref="node"
        :class="reversed ? 'noUi-tandem-revert' : ''"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import noUiSlider, { API, Options } from "nouislider";
import { cloneDeep, isArray, isNumber, isString, round } from "lodash-es";

type RangeValue = number[]|number;

export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: false,
      default: undefined
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      required: true,
      type: Number
    },
    range: {
      default: false,
      type: Boolean
    },
    step: {
      default: 1,
      type: Number
    },
    format: {
      default: (value: string|number) : string => {
        return value + "";
      },
      type: Function
    },
    reversed: {
      default: false,
      type: Boolean
    },
  },
  emits: ["update:model-value"],
  data () {
    return {
      labels: [] as string[],
      slider: null as API|null
    };
  },
  computed: {
    precision() {
      return Math.max(0, Math.ceil(Math.log10(1/this.step)));
    },
    modelValueFormatted() : RangeValue {
      return this.getModelValue(this.modelValue);
    },
    defaultValue() : RangeValue {
      if (this.range) {
        return [this.min, this.max];
      }
      return this.max;
    }
  },
  watch: {
    modelValue() {
      if (this.slider) {
        this.slider.set(this.modelValueFormatted);
      }
    }
  },
  mounted() {
    const config = {} as Options;

    config.start = this.modelValueFormatted as RangeValue;
    config.step = this.step;

    if (this.range) {
      config.connect = true;
    } else {
      config.connect = "lower";
    }

    config.range = {
      min: this.min,
      max: this.max,
    };

    setTimeout(() => {

      if (!this.$refs.node) {
        // Since the UI Slider is initialized in a setTimeout,
        // the $refs.node Element might be destroyed by the time it reaches this code.
        // This may happen if the the component is mounted and unmounted very quickly
        return;
      }

      this.slider = noUiSlider.create(this.$refs.node as HTMLElement, config) as any;

      this.slider?.on("update", (e) => {
        const modelValue = this.getModelValue(e as number[]);
        this.labels = this.getLabels(modelValue);
      });

      this.slider?.on("change", (e) => {
        const modelValue = this.getModelValue(e as number[]);
        this.emitUpdate(modelValue);
      });
    }, 1);
  },
  methods: {
    round(value: number) : number {
      return round(value, this.precision);
    },
    isString: isString,
    isArray: isArray,
    reset() {
      this.$emit("update:model-value", undefined);
    },
    getModelValue(e: RangeValue|undefined) : RangeValue {
      let value = cloneDeep(e) as RangeValue;

      if (isNumber(value)) {
        return this.round(value);
      }

      if (isArray(value) && value.length > 0) {
        if (!this.range) {
          return this.round(value[0]);
        }
        return value.map(this.round);
      }

      return this.defaultValue;
    },
    getLabels(value: RangeValue) : string[] {
      let labels = cloneDeep(value);
      if (isNumber(labels)) {
        labels = [labels];
      }
      return labels.map((label: number)=> this.format(label));
    },
    emitUpdate(modelValue: RangeValue) {

      let valueToEmit = cloneDeep(modelValue) as RangeValue|undefined;

      if (
        this.range &&
        isArray(modelValue) &&
        modelValue.length == 2 &&
        modelValue[0] == this.min &&
        modelValue[1] == this.max
      ) {
        valueToEmit = undefined;
      }

      if (
        !this.range &&
        isNumber(modelValue) &&
        modelValue == this.max
      ) {
        valueToEmit = undefined;
      }

      this.$emit("update:model-value", valueToEmit);
    }
  }
});
</script>
