<template>
  <VForm
    :data="form"
    method="patch"
    url="/account/member"
    loading-mask-class="bg-white"
    @success="onSuccess"
  >
    <template #default="{loading}">
      <div class="mb-6">
        <label
          for="about"
          class="block mb-1 font-medium leading-tight"
        >
          <span>{{ $t('page.settings.profile.title.title') }}</span>
          <span class="text-red-500">*</span>
        </label>

        <p class="mb-5 leading-tight text-gray-600">
          {{ $t('page.settings.profile.title.description') }}
        </p>

        <VInputTextarea
          v-model="form.title"
          label=""
          name="title"
          required
          input-class="w-full"
          :min="TITLE_MIN"
          :max="TITLE_MAX"
          :maxlength="TITLE_MAX"
          :placeholder="$t('page.settings.profile.title.placeholder')"
        />
      </div>

      <div class="mb-6">
        <label
          for="about"
          class="block mb-1 font-medium leading-tight"
        >
          <span>{{ $t('page.settings.profile.about.title') }}</span>
          <span class="text-red-500">*</span>
        </label>

        <p class="mb-5 leading-tight text-gray-600">
          {{ $t('page.settings.profile.about.description') }}
        </p>

        <VInputTextarea
          v-model="form.about"
          label=""
          name="about"
          required
          :rows="5"
          input-class="w-full"
          :min="ABOUT_MIN"
          :max="ABOUT_MAX"
          :maxlength="ABOUT_MAX"
          :placeholder="$t('page.settings.profile.about.placeholder')"
        />
      </div>

      <div class="mb-6">
        <label
          for="about"
          class="block mb-4 font-medium leading-tight"
        >
          <span>{{ $t('page.settings.profile.seeking.title') }}</span>
          <span class="text-red-500">*</span>
        </label>

        <div class="mb-5 leading-tight text-gray-600">
          <p class="">
            {{ $t('page.settings.profile.seeking.description') }}
          </p>
          <p class="mt-2">
            {{ $t('page.settings.profile.seeking.description_2') }}
          </p>
        </div>


        <VInputTextarea
          v-model="form.seeking"
          label=""
          name="seeking"
          required
          :rows="5"
          input-class="w-full"
          :min="SEEKING_MIN"
          :max="SEEKING_MAX"
          :maxlength="SEEKING_MAX"
          :placeholder="$t('page.settings.profile.seeking.placeholder')"
        />
      </div>

      <slot
        name="submit"
        :completed="completed"
      >
        <BaseButton
          type="submit"
          :disabled="loading || !completed"
          :loading="loading"
          class="btn-primary btn-lg sm:w-auto inline-block w-full mb-0"
        >
          {{ $t('update') }}
        </BaseButton>
      </slot>
    </template>
  </VForm>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";

const TITLE_MIN = 5;
const TITLE_MAX = 100;
const ABOUT_MIN = 10;
const ABOUT_MAX = 3000;
const SEEKING_MIN = 10;
const SEEKING_MAX = 3000;

export default defineComponent({
  emits: ["success"],
  setup () {
    return {
      TITLE_MIN: TITLE_MIN,
      TITLE_MAX: TITLE_MAX,
      ABOUT_MIN: ABOUT_MIN,
      ABOUT_MAX: ABOUT_MAX,
      SEEKING_MIN: SEEKING_MIN,
      SEEKING_MAX: SEEKING_MAX,
    };
  },
  data() {
    return {
      form: {
        title: this.$store.state.user?.member?.title ?? "",
        about: this.$store.state.user?.member?.about ?? "",
        seeking: this.$store.state.user?.member?.seeking ?? ""
      }
    };
  },
  computed: {
    completed(): boolean {
      if (this.form.title.length < TITLE_MIN || this.form.title.length > TITLE_MAX) {
        return false;
      }
      if (this.form.about.length < ABOUT_MIN || this.form.about.length > ABOUT_MAX) {
        return false;
      }
      if (this.form.seeking.length < SEEKING_MIN || this.form.seeking.length > SEEKING_MAX) {
        return false;
      }
      return true;
    }
  },
  methods: {
    onSuccess() {
      const user = cloneDeep(this.$store.state.user);
      if (user?.member) {
        user.member.title = this.form.title;
        user.member.about = this.form.about;
        user.member.seeking = this.form.seeking;
        this.$store.commit("setUser", user);
      }
      this.$emit("success");
    }
  }
});
</script>
