<template>
  <VForm
    :data="mail"
    url="account/ambassador/referral-mail"
    method="post"
    @success="onSuccess()"
  >
    <template #default="{errors}">
      <div class="mb-5">
        <label
          for="emails"
          class="block mb-2 text-gray-600"
        >
          {{ $t('emails') }}<span class="text-red-600">*</span>
        </label>
        <FreeTagInput
          v-model="mail.emails"
          :placeholder="$t('type_an_email')"
          input-type="email"
          required
        />
      </div>

      <BaseAlert
        v-if="errors.emails && errors.emails.length"
        type="danger"
        class="mb-7 border border-red-300"
      >
        {{ errors.emails[0] }}
      </BaseAlert>

      <div class="mb-5">
        <VInput
          v-model="mail.subject"
          :label="$t('subject')"
          name="subject"
          prevent-submit
          :col="40"
          :rows="6"
          input-class="w-full"
        />
      </div>

      <VInputTextarea
        v-model="mail.message"
        :label="$t('message')"
        required
        name="message"
        :col="40"
        :rows="6"
        input-class="h-[400px] block w-full"
      />

      <button
        type="submit"
        class="btn btn-primary btn-lg mt-5"
      >
        {{ $t('share_by_email') }}
      </button>
    </template>
  </VForm>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FreeTagInput from "@/plugins/form/components/elements/FreeTagInput.vue";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

export default defineComponent({
  components: { FreeTagInput },
  props: {
    subject: {
      default: "",
      type: String
    },
    body: {
      default: "",
      type: String
    }
  },
  data () {
    return {
      mail: {
        subject: this.subject,
        message: this.body,
        emails: [],
      }
    };
  },
  methods: {
    onSuccess() {
      this.mail.emails = [];
    }
  }
});
</script>
