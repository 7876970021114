<template>
  <div class="bg-gray-50 relative">
    <div
      v-if="isMe"
      class="md:border-b md:mb-5 py-4 text-center text-blue-600 bg-white"
    >
      <BaseContainer>
        <div class="xs:text-base flex justify-between text-sm leading-none">
          <div class="mr-2 font-semibold leading-none">
            {{ $t('profile_preview') }}
          </div>
          <router-link
            to="/member/settings"
            class="block leading-none underline"
          >
            {{ $t('return_to_settings') }}
          </router-link>
        </div>
      </BaseContainer>
    </div>
    <MemberProfile
      v-if="member"
      :member="member"
      :show-previous="!isMe"
      :disable-interactions="isMe"
      @update="updateMember"
    />
    <ProfileSkeleton
      v-else
      :class="{'md:pt-12': !isMe}"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Member } from "@/models/Member";
import MemberProfile from "./MemberProfile.vue";
import ProfileSkeleton from "./components/ProfileSkeleton.vue";
import { get } from "lodash";

export default defineComponent({
  components: {
    MemberProfile,
    ProfileSkeleton,
  },
  props: {
    memberId: {
      required: true,
      type: String,
    }
  },
  data () {
    return {
      loading: false,
      member: null as Member|null,
    };
  },
  computed: {
    isMe () : boolean {
      return this.memberId == (this.$store.state.user?.member?.id ?? null);
    },
  },
  created () {

    this.loading = true;

    this.$api.get(`members/${this.memberId}`)
      .then((response: any) => {
        if (get(response.data.data, "id")) {
          this.member = response.data.data as Member;
        } else {
          this.$router.push("/member/search");
        }
      })
      .catch((e: Error) => {
        this.$router.push("/member/search");
        console.error(e);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    updateMember(member: Member) {
      this.member = member;
    }
  }
});
</script>
