<template>
  <VForm
    ref="form"
    :data="form"
    url="answers"
    method="post"
    loading-mask-class="bg-white"
    :success-handler="onSuccess"
  >
    <template #default="{}">
      <div class="mb-6">
        <div
          v-for="(answer, i) in form.answers"
          :key="answer.question_id"
          class="last:mb-0 mb-6"
        >
          <QuestionInput
            :name="`answers.${i}.value`"
            :data="answer"
            @update="onInputUpdate(i, $event)"
            @submit="submit()"
          />
        </div>
      </div>
      <slot
        name="footer"
        :completed="completed"
        :skippable="skippable"
        :skip="skip"
      />
    </template>
  </VForm>
</template>

<script lang="ts">
import { Question, QuestionType } from "@/models/Question";
import { defineComponent, PropType } from "vue";
import { cloneDeep, difference, get, isArray, isString } from "lodash-es";
import { Answer } from "@/models/Answer";
import { AnswerList } from "@/store";
import { AnswerData, AnswerValue } from "./types";

export default defineComponent({
  props: {
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    answers: {
      required: true,
      type: Object as PropType<AnswerList>
    },
  },
  emits: ["success"],
  data() {
    return {
      form: {
        answers: [] as AnswerData[],
      }
    };
  },
  computed: {
    completed(): boolean {
      if (!this.question.required) {
        return true;
      }

      for (let i = 0; i < this.form.answers.length; i++) {
        const answer = this.form.answers[i];
        const answerValue = answer.value;
        const question = this.$store.getters["question/listById"][answer.question_id] as Question;

        if (!question.required) {
          continue;
        }

        if (this.answerValueIsNull(answerValue)) {
          return false;
        }

        if (
          question.type.min &&
          [QuestionType.input, QuestionType.textarea].includes(question.type.input_type) &&
          isString(answerValue) &&
          answerValue.length < question.type.min
        ) {
          return false;
        }

        if (
          question.type.min &&
          [QuestionType.multi_select].includes(question.type.input_type) &&
          isArray(answerValue) &&
          answerValue.length < question.type.min
        ) {
          return false;
        }
      }

      return true;
    },
    skippable () : boolean {
      if (this.question.required) {
        return false;
      }

      for (let i = 0; i < this.form.answers.length; i++) {
        const answer = this.form.answers[i];
        const question = this.$store.getters["question/listById"][answer.question_id] as Question;
        if (question.required) {
          return false;
        }
      }

      return true;
    }
  },
  created () {
    this.form.answers.push(this.getAnswerData(this.question));
    this.updateDependantQuestions();
  },
  methods: {
    submit () {
      (this.$refs.form as HTMLFormElement).submit();
    },
    skip () {
      this.form.answers = this.form.answers.map(a => {
        a.value = null;
        return a;
      });
      this.submit();
    },
    onInputUpdate(index: number, value: AnswerValue) {
      this.form.answers[index].value = value;
      this.updateDependantQuestions();
    },
    updateDependantQuestions () {

      const value = get(this.form, "answers.0.value", null) as AnswerValue|null;

      if (!value) {
        return;
      }

      let optionIds = [] as string[];

      if (isArray(value)) {
        optionIds = value as string[];
      } else if (value) {
        optionIds = [value as string];
      }

      const dependantQuestionsToShow = this.$store.getters["question/dependantQuestions"](this.question, optionIds) as Question[];

      // Remove questions

      this.form.answers = this.form.answers.filter(a => {
        return (
          // Keep main question
          this.question.id == a.question_id ||
          // Keep dependant questions
          dependantQuestionsToShow.map(q => q.id).includes(a.question_id)
        );
      });

      // Add questions

      const dependantQuestionsToAdd = dependantQuestionsToShow.filter(question => {
        return !this.form.answers.map(a => a.question_id).includes(question.id);
      });

      dependantQuestionsToAdd.forEach(question => {
        this.form.answers.push(this.getAnswerData(question));
      });

    },
    getAnswerData(question: Question) : AnswerData {
      const answer = this.answers[question.id] ?? null;

      let value = null;

      if (answer) {
        value = answer.value;

        if (isArray(answer.options)) {
          if (question.type.input_type == QuestionType.select) {
            value = answer.options[0]?.id ?? null;
          } else if (question.type.input_type == QuestionType.multi_select) {
            value = answer.options.map(o => o.id);
          }
        }
      }

      return {
        question_id: question.id,
        value: value,
      };
    },
    async onSuccess() {

      const questionIds = this.form.answers.map(a => a.question_id);

      const response = await this.$api.get("/answers", {
        params: {
          filter: {
            question_id: questionIds
          }
        }
      });

      const answers = response.data.data as Answer[];

      const newAnswers = cloneDeep(this.answers) as AnswerList;

      const answerQuestionsIds = answers.map(a => a.question_id);

      const deletedQuestionIds = difference(questionIds, answerQuestionsIds);

      answers.forEach(a => {
        newAnswers[a.question_id] = a;
      });

      deletedQuestionIds.forEach(id => {
        delete newAnswers[id];
      });

      this.$store.commit("setUserMemberAnswers", Object.values(newAnswers));

      this.$emit("success");
    },
    answerValueIsNull(answerValue: AnswerValue) : boolean {
      return answerValue === null || answerValue === "" || (isArray(answerValue) && answerValue.length == 0);
    }
  }
});
</script>
