<template>
  <BaseModal
    :model-value="modelValue"
    width="sm:max-w-[400px] w-full"
  >
    <div class="rounded-xl relative px-8 py-10 overflow-hidden">
      <div class="max-w-[280px] mx-auto">
        <div class="mb-6 text-center">
          <img
            v-if="user.member?.avatar_path"
            :src="$assetUrl(user.member?.avatar_path, {resize: {width: 400, height: 400}})"
            :alt="user.first_name"
            class="aspect-1 object-cover max-w-[150px] w-full mx-auto mb-5 rounded-full"
          >

          <h2 class="mb-2 text-xl font-semibold leading-6">
            {{ $t('x_wants_to_start_a_video_call', {x: user.first_name}) }}...
          </h2>

          <p class="text-gray-600" />
        </div>

        <div class="mb-7 flex justify-center">
          <BaseSwitch
            v-model="withVideo"
            color="info"
          >
            <div class="flex items-center justify-center">
              <Icon
                icon="heroicons-solid:video-camera"
                class="shrink-0 w-5 h-5 mr-1"
              />
              {{ $t('with_video') }}
            </div>
          </BaseSwitch>
        </div>

        <div class="flex justify-between w-full space-x-2">
          <button
            type="button"
            :disabled="loading"
            class="flex flex-col items-center bg-transparent outline-none appearance-none"
            @click="decline()"
          >
            <div
              class="btn btn-danger flex items-center justify-center w-16 h-16 mb-1 text-center rounded-full shadow-lg"
            >
              <Icon
                icon="heroicons-solid:phone"
                class="w-6 h-6 translate-y-[2px] rotate-[135deg]"
              />
            </div>
            <p>{{ $t('decline') }}</p>
          </button>

          <button
            type="button"
            :disabled="loading"
            class="flex flex-col items-center bg-transparent outline-none appearance-none"
            @click="sendMessage()"
          >
            <div
              class="btn flex items-center justify-center w-16 h-16 mb-1 text-center rounded-full shadow-lg"
            >
              <Icon
                icon="heroicons-solid:chat"
                class="w-6 h-6"
              />
            </div>
            <p>{{ $t('message') }}</p>
          </button>

          <button
            type="button"
            :disabled="loading"
            class="flex flex-col items-center bg-transparent outline-none appearance-none"
            @click="onSuccess()"
          >
            <div
              class="btn btn-success flex items-center justify-center w-16 h-16 mb-1 text-center rounded-full shadow-lg"
            >
              <Icon
                v-show="withVideo"
                icon="heroicons-solid:video-camera"
                class="w-6 h-6"
              />
              <Icon
                v-show="!withVideo"
                icon="heroicons-solid:phone"
                class="w-6 h-6"
              />
            </div>
            <p>{{ $t('accept') }}</p>
          </button>
        </div>
      </div>

      <BaseLoadingCover
        v-model="loading"
        icon="spinner"
        icon-class="w-16 h-16"
        backdrop-class="bg-opacity-70 bg-white"
      />
    </div>
  </BaseModal>
</template>


<script lang="ts">
import { defineComponent, PropType } from "vue";

interface VideoCallUser {
  id: string,
  first_name: string,
  member?: {
    avatar_path: string|null,
  }
}

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    user: {
      required: true,
      type: Object as PropType<VideoCallUser>
    },
    conversationId: {
      required: true,
      type: String,
    }
  },
  emits: ["update:modelValue", "blocked"],
  data () {
    return {
      loading: false,
      withVideo: true,
    };
  },
  methods: {
    onSuccess () {
      this.close();
      this.$router.push({
        name: "member.messages.conversation.video-chat",
        params: {
          conversation: this.conversationId,
        },
        query: {
          no_video: this.withVideo ? "0" : "1"
        }
      });
    },
    sendMessage() {
      this.decline();
      this.$router.push({
        name: "member.messages.conversation",
        params: {
          conversation: this.conversationId
        }
      });
    },
    decline() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api
        .post(`conversations/${this.conversationId}/video-chat/decline`)
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
            this.close();
          }, 500);
        });
    },
    close () {
      this.$emit("update:modelValue", false);
    }
  }
});
</script>
