<template>
  <div class="md:grow md:overflow-y-auto relative">
    <div
      v-if="priority == 'secondary' && !empty && !loading"
      class="px-2 pt-4 mb-2"
    >
      <div class="bg-blue-50 p-4 rounded-md">
        <div class="flex">
          <div class="shrink-0">
            <svg
              class="w-5 h-5 text-blue-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3">
            <p class="mb-1 text-sm text-blue-700">
              {{ $t('chat.secondary.notice.text') }}
            </p>
            <router-link
              class="text-sm font-semibold text-blue-700 underline"
              to="/member/settings/secondary-conversation-rules"
            >
              {{ $t('chat.secondary.notice.button') }} →
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ConversationListSkeleton
      v-if="loading"
    />

    <div
      v-else-if="!empty"
      class="px-2 py-2"
    >
      <div
        v-for="conversation in conversations"
        :key="conversation.id"
      >
        <ConversationItem
          :conversation="conversation"
          :selected="conversation.id == $route.params.conversation"
        />
      </div>
    </div>

    <div
      v-else-if="empty && priority == 'priority'"
      class="px-4 py-8"
    >
      <div class="text-center">
        <h2 class="mb-2 font-medium leading-tight">
          {{ $t('page.messages.empty_list.title') }}
        </h2>
        <p class="text-sm leading-tight text-gray-700">
          {{ $t('page.messages.empty_list.subtitle') }}
        </p>
      </div>
    </div>

    <div
      v-else-if="empty && priority == 'secondary'"
      class="px-4 py-8"
    >
      <div class="mb-6 text-center">
        <h2 class="mb-2 font-medium leading-tight">
          {{ $t('page.messages.empty_secondary_list.title') }}
        </h2>
        <p class="mb-6 text-sm leading-tight text-gray-700">
          {{ $t('page.messages.empty_secondary_list.subtitle') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Conversation } from "@/models/Conversation";
import { ComputedRef, defineComponent, inject } from "vue";
import ConversationItem from "./ConversationItem.vue";
import ConversationListSkeleton from "./ConversationListSkeleton.vue";

export default defineComponent({
  components: {
    ConversationItem,
    ConversationListSkeleton
  },
  setup() {
    const priority = inject("priority") as ComputedRef<string>;
    const loading = inject("loading") as ComputedRef<boolean>;
    const conversations = inject("conversations") as {[id: string] : Conversation};

    return {
      priority,
      loading,
      conversations
    };
  },
  computed: {
    empty () :boolean {
      return Object.keys(this.conversations).length == 0;
    }
  }
});
</script>
