import { uuid } from "@/services/uuid";
import { NotificationConfigInterface, Type } from "./NotificationConfig.interface";

class Notification {

  public id: string;
  public message: string;
  public type: Type = Type.info;
  public duration = 3000;
  public timestamp: number;
  public icon = true;

  constructor(config: NotificationConfigInterface) {
    this.id = uuid();
    this.timestamp = Date.now();
    this.message = config.message;

    if (config) {
      if (config.type) {
        this.type = config.type;
      }
      if (config.duration) {
        this.duration = config.duration;
      }
      if (config.icon) {
        this.icon = config.icon;
      }
    }
  }

  shouldHide(): boolean {
    return this.timestamp + this.duration < Date.now();
  }
}

export { Notification };
