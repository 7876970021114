<template>
  <div
    v-if="article"
    :class="{'absolute overflow-hidden max-h-full w-full top-0 left-0 pt-safe md:pt-menu-desktop-safe': !canView}"
  >
    <BaseContainer
      size="3xl"
      class="pt-safe"
    >
      <button
        class="md:hidden flex items-center pt-6 space-x-2 border-0 appearance-none"
        @click="$router.back()"
      >
        <Icon
          icon="heroicons-outline:arrow-left"
          class="w-4 h-4 text-gray-500"
        />
        <span class="text-gray-600">{{ $t('back') }}</span>
      </button>

      <BaseTitle
        :previous="false"
        :title="article.title"
        :subtitle="article.subtitle"
      />
    </BaseContainer>

    <BaseContainer
      size="3xl"
    >
      <div class="md:flex md:justify-between md:space-y-0 space-y-8">
        <div class="md:order-2 shrink-0">
          <ShareButtons
            :link="article.website_url"
            :mail-subject="article.title"
          />
        </div>
        <a
          v-if="article.owner"
          :href="article.owner.url"
          target="_blank"
          class="md:order-1 flex items-center space-x-3"
        >
          <img
            v-if="article.owner.profile_image_path"
            :src="$assetUrl(article.owner.profile_image_path, {resize: {width: 100}})"
            :alt="article.owner.name"
            class="shrink-0 object-cover object-center w-10 h-10 rounded-full"
          >
          <div>
            <h2 class="mb-1 text-base leading-4">{{ article.owner.name }}</h2>
            <p class="text-sm font-light leading-tight text-gray-600">{{ article.owner.title }}</p>
          </div>
        </a>
      </div>

      <div
        v-if="article.image_path && article.type == 'article'"
        class="mt-8"
      >
        <img
          :src="$assetUrl(article.image_path, {resize: {width: 600}})"
          :alt="article.title"
          class="min-h-[100px] w-full rounded-lg"
        >
      </div>
    </BaseContainer>

    <BaseContainer
      v-if="canView"
      size="3xl"
    >
      <div
        v-if="article.video_url && article.type == 'video' && article.embed_video_url"
        class="mt-8"
      >
        <ResponsiveIframe
          class="rounded-lg"
          :url="article.embed_video_url"
        />
      </div>

      <article
        class="lg:prose-lg prose-h2:font-display prose-h3:leading-6 prose-a:underline prose-a:underline-offset-4 prose-a:decoration-1 hover:prose-a:decoration-2 prose-a:decoration-blue-400 max-w-full mt-10 prose"
        v-html="article.body"
      />

      <div class="mt-10" />
      <!-- <div class="flex -m-2">
        <div
          v-for="tag in article.tags"
          :key="tag.id"
          class="p-2"
        >
          <router-link
            :to="`/member/personal-development?tag=${tag.id}`"
            class="px-3 py-2 text-sm text-gray-500 bg-gray-100 rounded"
          >
            {{ tag.name }}
          </router-link>
        </div>
      </div> -->

      <div class="pb-14 pt-10 mt-16 border-t border-gray-100">
        <h3 class="font-display mb-3 text-xl font-semibold">
          {{ $t('what_do_you_think_of_the_article') }}
        </h3>

        <ArticleRate :article="article" />
      </div>

      <div
        v-if="article.owner"
        class="sm:py-16 py-10 mt-10 border-t border-gray-100"
      >
        <div class="sm:flex sm:space-x-8 sm:space-y-0 space-y-8">
          <div
            v-if="article.owner && article.owner.profile_image_path"
            class="shrink-0"
          >
            <img
              :src="$assetUrl(article.owner.profile_image_path, {resize: {width: 400}})"
              alt="{{ article.owner.name }}"
              class="media-outline object-cover object-center w-20 h-20 rounded-full"
            >
          </div>
          <div class="grow">
            <h2 class="font-display text-xl font-semibold leading-tight">
              <span class="mr-1 font-normal text-gray-700">{{ $t('about_x') }}</span>
              <span class="underline-offset-2 decoration-primary-300 underline">{{ article.owner.name }}</span>
            </h2>

            <div class="mt-2">
              <p class="leading-snug text-gray-500">
                {{ article.owner.short_description }}
              </p>
            </div>

            <div
              v-if="article.owner.url"
              class="mt-4"
            >
              <div class="flex flex-wrap -m-1">
                <div
                  v-if="false"
                  class="p-1"
                >
                  <a
                    href="{{ route('website.specialist', ['locale' => app().getLocale(), 'slug' => article.owner.slug]) }}"
                    class="btn btn-primary"
                  >
                    {{ $t('learn_more') }}
                  </a>
                </div>
                <div
                  class="p-1"
                >
                  <a
                    :href="article.owner.url"
                    target="_blank"
                    class="btn btn-primary btn-lg flex items-center"
                  >
                    {{ $t('learn_more') }}
                    <Icon
                      class="ml-1"
                      icon="heroicons-solid:external-link"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>

    <BaseFooter />

    <ArticlePaywall
      v-if="!canView"
      :article="article"
    />
  </div>
  <BaseLoadingCover :model-value="loading" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Article } from "@/models/Article";
import { getRoute } from "@/services/utils";
import ArticleRate from "./components/ArticleRate.vue";
import ArticlePaywall from "./components/ArticlePaywall.vue";

export default defineComponent({
  components: {
    ArticleRate,
    ArticlePaywall
  },
  data() {
    return {
      article: null as Article|null,
      loading: false,
    };
  },
  computed: {
    canView() : boolean {
      return this.article != null && this.article.can != undefined && this.article.can.view;
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch () {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.get(getRoute("api/content/articles/" + this.$route.params.article))
        .then(response => {
          this.article = response.data.data;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
