<template>
  <div class="static">
    <SendReferralCode
      v-if="showReferralForm && false"
      @success="fetch()"
    />

    <ProductItems
      :products="products"
      :loading="loading"
      @select="onSelect"
    />

    <Teleport to="body">
      <BaseLoadingCover
        v-model="paying"
        position="fixed"
        backdrop-class="bg-gray-50 bg-opacity-80"
      />
    </Teleport>
  </div>
</template>

<script lang="ts">
import { getDeviceReference } from "@/services/deviceReference";
import { defineComponent, PropType } from "vue";
import { Product } from "@/models/Product";
import { Capacitor } from "@capacitor/core";
import SendReferralCode from "../SendReferralCode.vue";
import ProductItems from "../ProductItems.vue";
import { IAPProduct, InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { getProductNativeKey } from "@/services/nativeProducts";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

export default defineComponent({
  components: {
    SendReferralCode,
    ProductItems
  },
  props: {
    showReferralForm: {
      default: true,
      type: Boolean
    }
  },
  emits: ["loaded", "success"],
  data() {
    return {
      paying: false,
      loading: false,
      products: [] as Product[],
    };
  },
  computed: {
    platform() : string {
      return Capacitor.getPlatform();
    }
  },
  created() {
    this.fetch();

    this.$mitt.on("inAppPurchaseVerified", () => {
      this.fetch();
      this.$emit("success");
      this.paying = false;
    });

    this.$mitt.on("inAppPurchaseCancelled", () => {
      this.paying = false;
    });

    this.$mitt.on("inAppPurchaseError", () => {
      this.paying = false;
    });

  },
  methods: {
    async fetch() {
      this.loading = true;

      const params = {
        device_ref: await getDeviceReference(),
        platform: this.platform,
      } as Record<string,string|string[]>;

      this.$api.get("products", {
        params: params
      })
        .then(async (response: any) => {
          const products = response.data.data as Product[];

          const nativeProductIds = Object.keys(this.$store.state.product.list);

          this.products = products.filter(p => {

            const nativeKey = p[getProductNativeKey()];

            if (!nativeKey) {
              return false;
            }

            return nativeProductIds.includes(nativeKey);
          });

          this.$emit("loaded", this.products);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSelect(product: Product) {

      this.paying = true;

      let id = product[getProductNativeKey()] as string;

      const inAppProduct = this.$store.getters["product/getById"](id) as IAPProduct|null;

      if (!inAppProduct) {
        this.$notify({
          type: Type.danger,
          message: this.$t("product_not_found")
        });
        return;
      }

      InAppPurchase2.order(inAppProduct.id);
    }
  }
});
</script>
