<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div v-if="loaded" class="static w-full max-w-full min-h-full overflow-hidden">
    <ResponsiveLayout :links="links">
      <router-view v-slot="{ Component }">
        <keep-alive include="PageSearch,PageSettings,PageArticles,PageArticlesArticle,PageArticlesBonus,PageArticlesVideo,PageMessages">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </ResponsiveLayout>
  </div>

  <BaseLoadingCover :model-value="!loaded" icon="spinner" />

  <TermsModal v-model="showTermsModal" />
</template>

<script lang="ts">

import { defineComponent, inject, PropType, Ref } from "vue";
import TermsModal from "@/layouts/TermsModal.vue";
import ResponsiveLayout from "@/layouts/ResponsiveLayout.vue";
import { Link } from "@/layouts/models/Link";
import { store } from "@/store";
import { getRoute } from "@/services/utils";

export default defineComponent({
  components: {
    TermsModal,
    ResponsiveLayout,
  },
  props: {
    beforeCreated: {
      required: true,
      type: Function as PropType<() => Promise<void>>
    }
  },
  emits: ["loaded"],
  setup() {
    const mobile = inject("mobile") as Ref<boolean>;
    return {
      mobile
    };
  },
  data () {
    return {
      loaded: false,
      showTermsModal: false,
      channel: this.$echo.private("user." + this.$store.state.user?.id)
    };
  },
  computed: {
    links () : Link[] {
      if (this.$store.state.user?.member && this.$store.state.user.member.onboarding_completed) {
        const links = [
          {title: "search", route: "member.search", iconActive: "EpiconSearchSolid", iconInactive: "EpiconSearchLine"},
          {title: "favorites", route: "member.favorites", iconActive: "EpiconHeartSolid", iconInactive: "EpiconHeartLine"},
          {title: "messages", route: "member.messages", iconActive: "EpiconMessageSolid", iconInactive: "EpiconMessageLine", count() { return store.getters["conversation/unseenCount"]("priority"); }},
          {title: "blossom", route: "member.articles", iconActive: "EpiconPlayCircleSolid", iconInactive: "EpiconPlayCircleLine", subnav: [
            {
              title: "pages.personalDevelopment.video.title",
              subtitle: "pages.personalDevelopment.video.subtitle",
              icon: "heroicons-outline:play",
              route: {
                name: "member.articles.video",
              },
            },
            {
              title: "pages.personalDevelopment.article.title",
              subtitle: "pages.personalDevelopment.article.subtitle",
              icon: "heroicons-outline:document-text",
              route: {
                name: "member.articles.article",
              },
            },
            {
              title: "pages.personalDevelopment.bonus.title",
              subtitle: "pages.personalDevelopment.bonus.subtitle",
              icon: "heroicons-outline:star",
              route: {
                name: "member.articles.bonus",
              },
            },
            {
              title: "pages.personalDevelopment.specialists.title",
              subtitle: "pages.personalDevelopment.specialists.subtitle",
              icon: "heroicons-outline:academic-cap",
              href: getRoute("/specialists")
            }
          ]},
        ];

        if (this.$store.state.user.ambassador) {
          links.push({title: "refer_your_friends", route: "ambassador.index", iconActive: "EpiconAnnouncement2Solid", iconInactive: "EpiconAnnouncement2Line"});
        }

        links.push({title: "settings", route: "member.settings", iconActive: "EpiconSettingsSolid", iconInactive: "EpiconSettingsLine"});

        return links;
      }

      if (this.$store.state.user?.ambassador) {
        return [
          {title: "dashboard", route: "ambassador.index", iconActive: "EpiconHomeSolid", iconInactive: "EpiconHomeLine", exact: true},
          {title: "sharing_tools", route: "ambassador.share", iconActive: "EpiconAnnouncement2Solid", iconInactive: "EpiconAnnouncement2Line", exact: true},
          {title: "settings", route: "ambassador.settings", iconActive: "EpiconSettingsSolid", iconInactive: "EpiconSettingsLine", exact: true},
        ];
      }

      return [];
    },
  },
  async created () {
    await this.beforeCreated();
    this.loaded = true;
    this.$emit("loaded");

    this.channel
      .listen(".email-verified", this.onEventEmailVerified)
      .listen(".suspended", this.onEventSuspended)
      .listen(".unsuspended", this.onEventUnsuspended)
      .listen(".blocked", this.onEventBlocked)
      .listen(".unblocked", this.onEventUnblocked);
  },
  mounted () {
    if (!this.hasAcceptedTerms()) {
      // A small delay is necessary on iphone to avoid a scroll lock issue on load
      setTimeout(() => {
        if (!this.showTermsModal) {
          this.showTermsModal = true;
        }
      }, 800);
    }
  },
  beforeUnmount() {
    this.channel
      .stopListening(".email-verified", this.onEventEmailVerified)
      .stopListening(".suspended", this.onEventSuspended)
      .stopListening(".unsuspended", this.onEventUnsuspended)
      .stopListening(".blocked", this.onEventBlocked)
      .stopListening(".unblocked", this.onEventUnblocked);
  },
  methods: {
    onEventEmailVerified() {
      this.$store.dispatch("fetchUser");
    },
    onEventSuspended() {
      this.$store.dispatch("fetchUser");
    },
    onEventUnsuspended() {
      this.$store.dispatch("fetchUser");
    },
    onEventBlocked() {
      this.$store.dispatch("fetchUser");
    },
    onEventUnblocked() {
      this.$store.dispatch("fetchUser");
    },
    hasAcceptedTerms() :boolean {

      if (!this.$store.state.user) {
        return true;
      }

      if (!this.$store.state.user.accepted_terms_date) {
        return false;
      }

      let publishedAt = this.$store.state.term?.published_at;

      if (!publishedAt) {
        return true;
      }

      const termDate = this.$luxon.fromISO(publishedAt);
      const acceptedTermsDate = this.$luxon.fromISO(this.$store.state.user.accepted_terms_date);

      return acceptedTermsDate >= termDate;
    }
  }
});
</script>
