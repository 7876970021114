<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="sm:p-10 p-8 pb-12">
      <div class="max-w-lg mx-auto">
        <div class=" flex flex-col items-center justify-center mb-6">
          <div class="top-3 right-3 sm:top-5 sm:right-5 absolute flex justify-end w-full">
            <button
              type="button"
              class="flex items-center justify-center w-10 h-10 border border-gray-200 rounded-full appearance-none"
              @click="close()"
            >
              <Icon icon="heroicons-outline:x" />
            </button>
          </div>

          <div class="mt-6" />

          <h2 class="font-display mb-3 text-2xl font-semibold leading-tight text-center">
            {{ $t('cancel_subscription_modal.title') }}
          </h2>

          <p
            v-if="member.subscription_end_date"
            class="leading-tight text-center text-gray-600"
          >
            {{ $t('cancel_subscription_modal.subtitle', {x: $luxon.fromISO(member.subscription_end_date).setLocale($i18n.locale).toFormat('DD') }) }}
          </p>
        </div>
        <div class="mt-4 text-center">
          <BaseButton
            :loading="loading"
            type="button"
            class="btn btn-warning btn-lg"
            @click="cancelSubscription"
          >
            {{ $t('cancel_subscription_modal.cta') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { AccountMember } from "@/models/Account";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    member: {
      required: true,
      type: Object as PropType<AccountMember>
    }
  },
  emits: ["update:modelValue"],
  data () {
    return {
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
    cancelSubscription () {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.delete("account/subscriptions")
        .then(() => {
          this.$store.dispatch("fetchUser");
          this.close();
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
});
</script>
