{
  "SIN": "NAS",
  "_article_access_levels": {
    "member": "Pour abonnés",
    "public": "Public",
    "vip": "Bonus Effet 6 Mois"
  },
  "_article_paywall": {
    "auth_text": "Cet article est réservé aux {member_type} d'Effet Tandem. Vous devez mettre à niveau votre abonnement pour avoir accès.",
    "subscription_options": "Voir les options d'abonnement",
    "title": "Merci de visionner les capsules Effet Tandem!",
    "update_subscription": "Mettre à niveau mon abonnement"
  },
  "_login": {
    "email_already_exists": "Ce courriel est déjà associé à un compte. S'il s'agit de votre compte, vous pouvez vous connectez avec le formulaire ci-dessous."
  },
  "_mail_body": {
    "referral_link": {
      "body": "Bonjour, \n\nJe viens de faire une découverte très intéressante que je souhaite te partager. Effet Tandem est un site web innovateur qui aide les célibataires à s’unir et à s’épanouir ! Il offre des capsules vidéo pour t’accompagner dans un cheminement authentique dont l’objectif est de rencontrer l’amour, en toute sécurité. Tu as aussi accès à des experts dans le développement personnel. Je crois vraiment que ce site de rencontre est fait pour toi.\n\nJe t’invite à cliquer sur ce lien dès maintenant pour bénéficier d’un mois gratuit lors de ton inscription.\n{url}\n\nQui sait! Tu es peut-être à quelques clics de rencontrer l’amour.\n\n{from}",
      "subject": "Un mois gratuit - Site de rencontre authentique"
    }
  },
  "_manual_referral_form": {
    "text": "Entrez le code de référencement dans la boîte ci-dessous:",
    "title": "Vous avez été référé par quelqu'un ?"
  },
  "_media_kit_description": "Partagez des images et vidéos promotionnelles",
  "_placeholder_long_question_type": "Révélez un peu plus qui vous êtes à la Communauté Effet Tandem",
  "_placeholder_short_question_type": "Soyez spontanés, laissez libre cours à votre inspiration!",
  "_visiblity": {
    "private": {
      "description": "Si votre profil est privé, il ne sera ne pourra pas affiché sur la page d’accueil du site web Effet Tandem. Les membres ne pourront pas non plus partager votre profil par courriel avec un ami à l’extérieur du site web.",
      "title": "Privé"
    },
    "public": {
      "description": "Si votre profil est public, votre prénom, votre photo et votre ville seront disponibles publiquement sur la page d’accueil du site web Effet Tandem. Les membres pourront également partager votre profil avec un ami à l’aide de liens sécurisés.",
      "title": "Public"
    }
  },
  "about": "À propos",
  "about_me": "À propos de moi",
  "about_x": "À propos de {x}",
  "accept": "Accepter",
  "accept_terms_and_conditions": "J'accepte les conditions d'utilisation",
  "account_already_associated_with_this_email": "Ce courriel est déjà associé à un compte.",
  "account_balance": "Solde du compte",
  "activate": "Activer",
  "activate_anonymous_mode": "Activer le mode anonyme",
  "add_a_payment_method": "Ajouter un moyen de paiement",
  "add_a_promo_code": "Cliquer ici pour ajouter un code promo",
  "add_a_referral_code": "Ajouter un code de référencement",
  "add_a_tag": "Ajouter un mot-clé",
  "add_additional_months_to_your_subscription": "Ajouter des mois supplémentaires à votre abonnement :",
  "add_criteria": "Ajouter un critère",
  "add_x_months": "Ajouter {x} mois",
  "additional_details": "Détails supplémentaires",
  "address": "Adresse",
  "address_1_placeholder": "Adresse postale",
  "address_2_description": "Appartement, unité, suite ou étage",
  "age": "Âge",
  "all": "Tous",
  "all_rights_reserved": "Tous droits réservés.",
  "already_logged_in": "Vous êtes déjà connecté",
  "ambasador_taxes_notice": "Cette section est réservée aux entreprises et individus possédant des numéros de taxes. Tous les numéros de taxes seront validés à l'interne.",
  "ambassador_info": {
    "line_1": "Grâce à votre invitation, vos amis ou collègues recevront **1 mois gratuit supplémentaire** lors d’un nouvel abonnement à un forfait de 3 mois ou de 6 mois. Ce faisant, vous **obtenez 10$** pour avoir contribué à la croissance de notre communauté grâce à votre lien personnel.",
    "personal_link": "Voici votre lien personnel à partager :",
    "text_2": "Rendez vous dans la section « Référez vos amis » pour plus de détails. Votre identifiant sera le même pour toutes les plateformes du réseau Effet Tandem.",
    "title": "Référez un ami!"
  },
  "amount": "Montant",
  "and": "et",
  "anonymous_mode": "Mode anonyme",
  "apply": "Appliquer",
  "articles": {
    "filters": {
      "labels": {
        "apply_filters": "Appliquer les filtres",
        "keywords": "Mot clés",
        "premium_content": "Contenu Premium",
        "search_by_keywords": "Recherche par mots clés",
        "specialist": "Spécialiste",
        "type": "Type"
      },
      "title": "Filtres",
      "types": {
        "all": "Tous",
        "article": "Article",
        "video": "Vidéo"
      }
    },
    "sort": {
      "articles": "articles",
      "by": "Trier par",
      "of": "sur",
      "sorts_list": {
        "publishing_date": "Date de publication",
        "rating": "Note",
        "specialist": "Spécialiste"
      }
    }
  },
  "astrological_sign": "Signe astrologique",
  "at_least_size": "{size} au minimum",
  "audio_message": "Message audio",
  "audio_message_description": "En quelques mots, présentez-vous ou souhaitez la bienvenue aux visiteurs de votre profil.",
  "authenticated": "Authentifié",
  "author": "Auteur",
  "author_name": "Nom de l'auteur",
  "authorize_video_chat": "Autoriser la conversation vidéo",
  "automatic_renewal": "Renouvellement automatique",
  "available": "Disponible",
  "back": "Retour",
  "back_to_payment_methods": "Retour aux modes de paiement",
  "bank_balance": "Solde en banque",
  "best_time_to_contact_you": "Meilleur moment pour vous contacter",
  "birthday": "Date de naissance",
  "block": "Bloquer",
  "block_users_description": "Gérez les utilisateurs que vous avez bloqués. \nVous pouvez les débloquer à tout moment.",
  "blocked_users": "Utilisateurs bloqués",
  "blossom": "S'épanouir",
  "both": "Les deux",
  "by": "Par",
  "by_signing_up_you_agree_to": "En vous inscrivant, vous acceptez",
  "by_x": "Par {x}",
  "call": "Appel",
  "call_x": "Appeler {name}",
  "calling_x": "Appel à {x}",
  "cancel": "Annuler",
  "cancel_auto_renew": "Annuler le renouvellement automatique",
  "cancel_subscription_modal": {
    "cta": "Annuler le renouvellement automatique",
    "subtitle": "Après l’annulation du renouvellement automatique, votre abonnement actuel restera actif jusqu’au {x}.",
    "title": "Annuler le renouvellement automatique"
  },
  "card_number": "Numéro de carte",
  "card_type": "Type",
  "centimeters": "Centimètres",
  "change_the_visibility_of_my_profile": "Changer la visibilité de mon profil",
  "chat": {
    "no_conversation_selected": "Aucune conversation sélectionnée",
    "placeholder": "Tapez un message...",
    "received_flower": {
      "title": "Vous êtes la fleur de la journée de {user}"
    },
    "responses_0": "Merci de l’intérêt témoigné à mon égard. J’apprécie grandement mais malheureusement je n’entrevois pas de possibilité entre nous. Je vous souhaite de rencontrer la personne que vous recherchez.",
    "responses_1": "Merci de l’intérêt témoigné à mon égard, mais je viens d’entamer la conversation avec quelqu’un de potentiellement intéressant. Si vous le permettez, je vous reviendrai peut-être éventuellement.",
    "secondary": {
      "notice": {
        "button": "Configurer ma messagerie secondaire",
        "text": "Vous pouvez en tout temps limiter qui peut vous contacter à travers votre messagerie prioritaire. La messagerie secondaire contient tous les messages non prioritaires selon vos préférences."
      }
    },
    "sent_flower": {
      "title": "Vous avez offert une fleur à {user}"
    },
    "you_sent_a_flower": {
      "title": "Vous avez offert une fleur"
    }
  },
  "city": "Ville",
  "claim_sent_at": "Réclamation envoyée le",
  "click_here": "Cliquez ici",
  "click_on_icon_to_share": "Cliquez sur une icône pour partager",
  "close": "Fermer",
  "coming_soon": "Bientôt disponible",
  "complete_access_for_an_additional_x_months": "Accès complet pendant un 1 mois supplémentaire | Accès complet pendant {x} mois supplémentaires",
  "complete_access_for_x_months": "Accès complet pendant {x} mois",
  "connection": "Connexion",
  "contact_information": "Coordonnées",
  "contact_us": "Nous joindre",
  "continue": "Continuer",
  "continue_my_registration": "Continuer mon inscription",
  "continue_the_conversation": "Poursuivre la conversation",
  "conversion_rate": "Taux de conversion",
  "conversions": "Conversions",
  "copied": "Copié!",
  "copy": "Copier",
  "copy_link": "Copier le lien",
  "country": "Pays",
  "create_an_account": "Créer un compte",
  "create_my_account": "Créer mon compte",
  "create_your_account": "Créez votre compte",
  "current_password": "Mot de passe actuel",
  "cvv": "CVV",
  "daily_quote": "Pensée du jour",
  "daily_quote_cta": {
    "button": "Cliquer ici pour nous transmettre une pensée.",
    "description": "Participez à la Communauté Effet Tandem en partageant votre pensée du jour. Cette pensée pourra se retrouver sur les différentes plateformes Effet Tandem avec votre nom et votre photo.",
    "title": "Vous êtes inspiré?"
  },
  "daily_quote_successfully_submitted": "Pensée du jour soumise avec succès",
  "dashboard": "Tableau de bord",
  "decline": "Refuser",
  "define_your_search_filters": "Définir mes filtres de recherche",
  "delete": "Supprimer",
  "delete_account_component": {
    "button": "Supprimer mon compte",
    "confirm": {
      "title": "Supprimer mon compte"
    },
    "pause": {
      "button": "Prendre une pause",
      "subtitle": "Si vous ne voulez pas perdre définitivement toutes vos données et avoir accès à vos informations dans le futur, vous pouvez mettre votre compte en pause.",
      "title": "Besoin d'une pause?"
    },
    "subtitle": "En supprimant votre compte, votre profil et vos messages seront également supprimés. Cette action est irréversible.",
    "title": "Supprimer mon compte"
  },
  "deleted": "Supprimé",
  "deleted_user": "Utilisateur supprimé",
  "details": "Détails",
  "discount": "Rabais",
  "distance": "Distance",
  "do_you_want_to_create_a_profile_on_the_dating_website": "Vous souhaitez vous créer un profil sur le site de rencontre?",
  "done": "Terminer",
  "download_pdf": "Télécharger le PDF",
  "drag_to_reposition": "Faites glisser pour repositionner",
  "earned": "Gagné",
  "effet_tandem_homepage": "La page d'accueil d'Effet Tandem",
  "eg": "Ex.",
  "email": "Courriel",
  "email_address": "Adresse courriel",
  "email_confirmation": "Confirmation du courriel",
  "emails": "Adresses courriels",
  "enter_email_address": "Saisissez l'adresse courriel",
  "enter_your_new_email_address": "Saisissez votre nouvelle adresse courriel",
  "error": "Erreur",
  "expiry": "Expiration",
  "explore": "Explorer",
  "favorites": "Favoris",
  "feet": "Pieds",
  "female": "Femelle",
  "filters": "Filtres",
  "find_your_partner_in_love": "Trouvez votre complice amoureux",
  "find_your_soulmate": "Trouvez votre âme sœur",
  "first_name": "Prénom",
  "flower_match": "Match de Fleurs",
  "flower_match_tagline": "Wow, c'est réciproque!",
  "forgot_your_password": "Mot de passe oublié?",
  "free": "Gratuit!",
  "free_content": "Contenu gratuit",
  "gallery_review_status": {
    "done": {
      "title": "Votre album photo a été validé"
    },
    "pending": {
      "description": "Votre album photo est en attente d'approbation. Assurez-vous d'avoir au moins **une photo portrait** et **une photo validée**. Veuillez noter que nous examinons manuellement toutes les photos. Si vous téléchargez une nouvelle photo, cela peut prendre jusqu’à 24 heures avant qu’elle ne soit approuvée.",
      "title": "Votre album photo est attente d'approbation"
    },
    "refused": {
      "description": "Pour utiliser l'application, vous devez avoir au minimum **une photo portrait** et **une photo validée.** Si vous ne réglez pas cette situation dans les prochaines 72h, votre compte sera automatiquement suspendu. Veuillez noter que nous examinons manuellement toutes les photos. Si vous téléchargez une nouvelle photo, cela peut prendre jusqu’à 24 heures avant qu’elle ne soit approuvée.",
      "title": "Votre album photo est incomplet"
    }
  },
  "gender": "Genre",
  "general_information": "Informations générales",
  "generated_rewards": "Récompenses générées",
  "go_to_effettandemcom_to_manage_your_subscription": "Rendez-vous sur effettandem.com pour gérer votre abonnement.",
  "gst_hst": "TPS/TVH",
  "has_children": "A des enfants",
  "height": "Taille",
  "home": "Accueil",
  "i_accept": "J'accepte",
  "i_accept_the_terms_and_conditions": "J'accepte les conditions d'utilisation",
  "i_prefer_not_to_answer": "Je préfère ne pas répondre",
  "i_subscribe": "Je m’inscris",
  "i_subscribe_to_the_newsletter": "Je souhaite m'abonner à l'infolettre Effet Tandem",
  "i_validated_my_email": "J'ai validé mon courriel",
  "if_you_have_a_promotional_code_select_a_package_and_you_can_enter_it_later": "Si vous avez un code promotionnel, sélectionnez un forfait et vous pourrez l'entrer par la suite.",
  "im_the_author": "Je suis l'auteur",
  "imperial": "Impérial",
  "inbox": "Messagerie",
  "inches": "Pouces",
  "includes_bonus_course": "Inclut une formation bonus (valeur de 494$)",
  "interac_password": "Mot de passe du virement Interac",
  "interested_by": "Intéressé par",
  "invalid_promo_code": "Code promo invalide",
  "its": "c'est",
  "its_mutual": "C'est réciproque!",
  "join_call": "Rejoindre l'appel",
  "join_effet_tandem": "Rejoindre Effet Tandem",
  "just_now": "À l'instant",
  "keywords": "Mots-clés",
  "language": "Langue",
  "last_connection": "Dernière connexion",
  "last_name": "Nom de famille",
  "layout": {
    "tagline": "Effet Tandem, le site de rencontre sécuritaire fondé sur l'authenticité et le développement personnel."
  },
  "learn_more": "En apprendre plus",
  "learn_more_about_eid_compare": "En apprendre davantage sur eID Compare Equifax",
  "less_than_24h": "Moins de 24h",
  "lifestyle": "Style de vie",
  "limited_time_offer": "Offre d'une durée limitée",
  "listen_to_my_voice": "Écouter ma voix",
  "loading": "En téléchargement",
  "logout": "Déconnexion",
  "logout_component": {
    "subtitle": "Déconnectez-vous en toute sécurité de votre compte.",
    "title": "Déconnexion"
  },
  "main_picture": "Photo principale",
  "male": "Male",
  "man": "Homme",
  "manual_authentication": "Authentification manuelle",
  "maximum_x": "Maximum {x}",
  "me": "Moi",
  "media_kit": "Kit média ",
  "member_is_deleted": "Ce membre est supprimé",
  "member_is_suspended": "Ce membre est suspendu",
  "member_is_unsubscribed": "Ce membre est désabonné",
  "member_on_pause": "Membre en pause",
  "members": "Membres",
  "membership_successfully_activated": "Abonnement activé avec succès",
  "men": "Hommes",
  "men_and_women": "Hommes et femmes",
  "message": "Message",
  "messages": "Messages",
  "meta_description": "Inscrivez-vous en quelques minutes au site de rencontre québécois Effet Tandem. Rejoignez des milliers de célibataires sur la plateforme en ligne.",
  "meta_title": "S’inscrire au site de rencontre Effet Tandem [Relations sérieuses]",
  "metric": "Métrique",
  "min_x_characters": "Minimum {x} caractères",
  "minimum_x": "Minimum {x}",
  "modify_my_email": "Modifier mon adresse courriel",
  "modify_my_email_description": "Modifier mon adresse courriel sur l'ensemble du réseau Effet Tandem.",
  "modify_my_password": "Modifier mon mot de passe",
  "modify_my_password_description": "Modifier mon mot de passe sur l'ensemble du réseau Effet Tandem.",
  "month": "mois",
  "more_than_a_week": "Plus d'une semaine",
  "move_to_priority_inbox": "Déplacer vers la messagerie prioritaire",
  "mutual": "réciproque",
  "my_favorites": "Mes favoris",
  "my_profile": "Mon profil",
  "n_days": "1 jour | {count} jours",
  "new_password": "Nouveau mot de passe",
  "new_password_confirmation": "Confirmation du mot de passe",
  "new_payment_method": "Ajouter un mode de paiement",
  "new_terms_modal": {
    "cta": "J'accepte",
    "title": "Les conditions d'utilisation ont été mises à jour"
  },
  "next": "Suivant",
  "no_article": "Aucun article",
  "no_preference": "Aucune préférence",
  "no_quote_today": "Aucune pensée aujourd'hui!",
  "no_result": "Aucun résultat",
  "non_binaries": "Non binaires",
  "non_binary": "Non binaire",
  "none": "Aucun",
  "note": "",
  "objective": "Objectif",
  "offline": "Hors ligne",
  "ok": "Ok",
  "old_password": "Ancien mot de passe",
  "on_pause": "En pause",
  "onboarding": {
    "email_already_exists": {
      "text": "S'il s'agit de votre compte, vous pouvez vous connecter à votre compte ci-dessous.",
      "title": "Ce courriel est déjà associé à un compte."
    },
    "home": {
      "line_1": "Vous franchisez la première étape vers une relation authentique.",
      "line_2": "Notre processus d’inscription sécuritaire est conçu de manière à vous garantir une expérience de qualité, au sein d’une communauté digne de confiance.",
      "line_3": "Notre plus grand souhait est de vous aider dans votre quête et de vous proposer des outils qui vous aideront à trouver l’amour véritable.",
      "line_4": "Nous vous souhaitons de faire de belles et heureuses rencontres.",
      "title": "Bienvenue dans la communauté Effet Tandem"
    },
    "success": {
      "congrats_subtitle": "Vous êtes maintenant à quelques clics de vivre l’Effet Tandem. Nous espérons que votre expérience sera enrichissante, valorisante et remplie de belles découvertes.",
      "cta": "Commencer l'expérience",
      "subtitle": "Merci d’avoir pris le temps de compléter votre inscription.",
      "title": "Félicitations! Vous avez terminé le processus d’inscription."
    }
  },
  "one_single_payment_of_x_plus_taxes": "Un seul paiement de {x} + taxes",
  "online": "En ligne",
  "online_x_ago": "En ligne il y a {interval}",
  "optional": "Facultatif",
  "optional_response": "Réponse facultative",
  "or": "ou",
  "or_continue_with": "Ou continuer avec",
  "other": "Autre",
  "page": {
    "ambassador": {
      "media_kit": {
        "expert_article": "Capsules d'experts",
        "promotional_videos": "Vidéos promotionnelles"
      },
      "share": {
        "content_types": {
          "article": {
            "description": "Partager un article d'expert",
            "title": "Capsule d'expert"
          },
          "promo": {
            "description": "Partager une vidéo promotionnelle",
            "title": "Vidéo promotionelle"
          },
          "website": {
            "description": "Partager la page d'accueil du site web Effet Tandem",
            "title": "Le site web d'Effet Tandem"
          }
        }
      }
    },
    "blocked": {
      "line_1": "Si vous pensez qu'il s'agit d'une erreur, veuillez contacter le service client.",
      "title": "Votre compte a été bloqué"
    },
    "favorites": {
      "empty_state": {
        "subtitle": "Pour ajouter des favoris, cliquez sur le bouton en forme de cœur sur le profil des célibataires qui vous intéressent. Vous pourrez plus facilement suivre les mises à jour de leur profil.",
        "title": "Vous n'avez pas choisi de favoris"
      },
      "subtitle": "Ces célibataires vous mettent un sourire aux lèvres même si vous êtes seul(e) devant votre écran! Pour une raison ou une autre, ils ont retenu votre attention. Alors suivez votre instinct et réservez cet espace privilégié pour ceux ou celles que vous aimeriez apprendre à connaître davantage.",
      "title": "Mes favoris"
    },
    "forgot_password": {
      "subtitle": "Nous vous acheminerons par courriel les instructions pour le changer.",
      "success": "Nous avons envoyé votre lien de réinitialisation de mot de passe par courriel !",
      "title": "Mot de passe oublié?"
    },
    "login": {
      "title": "Se connecter"
    },
    "messages": {
      "empty_list": {
        "subtitle": "Lorsque vous enverrez ou recevrez votre premier message, il apparaitra ici.",
        "title": "Aucun message reçu"
      },
      "empty_secondary_list": {
        "subtitle": "Lorsque vous recevrez un message dans votre messagerie secondaire, il apparaitra ici.",
        "title": "Aucun message reçu"
      },
      "no_conversation": {
        "button": "Trouvez votre complice amoureux",
        "subtitle": "Vous n'avez pas encore envoyé ni reçu de message. Pour envoyer un message, visitez le profil d'un célibataire et cliquez sur le bouton « Envoyer un message ».",
        "title": "Votre messagerie prioritaire est vide"
      },
      "no_secondary_conversation": {
        "subtitle": "Vous pouvez limiter à tout moment qui peut vous contacter via votre boîte de messagerie prioritaire. \nLa boîte aux lettres secondaire contient tous les messages de faible priorité selon vos préférences.",
        "title": "Votre messagerie secondaire est vide"
      },
      "no_selection": {
        "subtitle": "Vous pouvez sélectionner une conversation dans le menu de gauche. "
      }
    },
    "not_found": {
      "title": "Page non trouvée"
    },
    "personal_development": {
      "new": "Nouveauté",
      "subtitle": "Conçues par des spécialistes et accessibles pour tous, explorez des capsules qui favorisent des pistes de réflexions personnelles. Dépassez-vous vers la réussite d'une vie amoureuse saine et durable.",
      "title": "Capsules de pros"
    },
    "profile": {
      "block_modal": {
        "description": "Cette personne ne pourra pas vous envoyer de message ou trouver votre profil. La personne ne recevra pas de notification à ce sujet.",
        "title": "Bloquer {x} ?"
      },
      "call_init_modal": {
        "subtitle": "Lorsque que vous aurez tous les deux autorisé la conversation vidéo, vous pourrez vous appeler à travers Effet Tandem.",
        "switch": {
          "notice": "Vous pouvez changer d'idée à tout moment en retirant l'autorisation.",
          "title": "J'autorise les conversations vidéos avec {name}"
        },
        "title": "Autoriser {name} à lancer une conversation vidéo avec vous"
      },
      "call_unlocked": {
        "subtitle": "Vous pouvez maintenant appeler {name}",
        "title": "Conversations vidéo débloquées"
      },
      "report_modal": {
        "description": "Si une personne est en danger immédiat, appelez les services d'urgence sans attendre.",
        "reason_codes": {
          "attempted_fraud": "Tentative de fraude",
          "false_identity": "Fausse identité",
          "graphic_picture": "Photo inappropriée",
          "harassment": "Harcèlement",
          "inappropriate_comments": "Propos inappropriés",
          "monetary_solicitation": "Solicitation monétaire",
          "other": "Autre"
        },
        "title": "Signaler {x} ?"
      },
      "share_member_modal": {
        "description": "Vous pouvez utiliser le lien ci-dessous pour partager le profil de ce membre avec vos amis et votre famille. Le lien est accesible pour une durée de trois (3) jours.",
        "error": "Une erreur est survenue pendant la génération du lien de partage.",
        "title": "Partager le profil de {x}"
      },
      "unblock_modal": {
        "description": "Cette personne pourra à nouveau vous envoyer un message ou trouver votre profil.",
        "title": "Débloquer {x} ?"
      }
    },
    "reset_password": {
      "title": "Réinitialiser votre mot de passe"
    },
    "search": {
      "end": {
        "cta": "Modifier vos filtres",
        "subtitle": "Vous pouvez modifier ou retirer certains filtres pour élargir votre recherche. Vous êtes peut-être à deux doigts de trouver l'âme soeur!",
        "title": "Vous avez atteint la fin de la liste des résultats"
      },
      "filter_box": {
        "description": "Vous pouvez ajouter un maximum de 5 filtres"
      },
      "filters": {
        "accepts_persons_with_limitations": {
          "title": "Accepterait de vivre avec une personne ayant des limitations"
        },
        "age": {
          "title": "Âge"
        },
        "alcohol_consumption_frequency": {
          "title": "Fréquence de consommation d'alcool de la personne"
        },
        "anonymous_mode": {
          "description": "Explorer les célibataires sans voir leurs photos afin de mettre l'emphase sur leur personnalité et vos valeurs en commun.",
          "title": "Mode anonyme"
        },
        "areas_of_interest": {
          "title": "Intérêts"
        },
        "current_occupation": {
          "title": "Occupation actuelle"
        },
        "education_level": {
          "title": "Niveau d'éducation"
        },
        "family_situation": {
          "title": "Situation familiale"
        },
        "food_preferences": {
          "title": "Préférences alimentaires"
        },
        "genders": {
          "title": "Genres"
        },
        "has_children": {
          "title": "A des enfants"
        },
        "height": {
          "title": "Taille"
        },
        "keyword_research": {
          "description": "Afficher les profils contenant au moins un des mots-clés\n\nSéparez-les par une virgule.",
          "placeholder": "Ex: Cheval, chevaux, écurie, écuries",
          "title": "Recherche par mots-clés"
        },
        "kids_living_with": {
          "title": "Les enfants vivent toujours à la maison"
        },
        "language": {
          "title": "Langues"
        },
        "objective": {
          "title": "Recherche"
        },
        "personal_development_importance": {
          "title": "Importance du développement personnel"
        },
        "personality_traits": {
          "title": "Traits de personnalité"
        },
        "religion": {
          "title": "Croyance / Religion"
        },
        "religious_belief": {
          "title": "Croyances religieuses"
        },
        "silhouette": {
          "description": "Vous pouvez sélectionner plusieurs",
          "title": "Silhouette"
        },
        "smoking": {
          "title": "Fumeur"
        },
        "smoking_frequency": {
          "title": "Fumeur - Fréquence"
        },
        "smoking_tolerance_level": {
          "title": "Tolérance aux fumeurs"
        },
        "values": {
          "title": "Valeurs"
        },
        "wants_children": {
          "title": "Veut des enfants"
        }
      },
      "learn_more": "En savoir plus",
      "max_filters": {
        "description": "Vous ne pouvez pas ajouter plus de 5 filtres à la fois. Vous pouvez modifier certains filtres et effectuer une nouvelle recherche.",
        "title": "Attention, vous ne pouvez pas ajouter plus de 5 filtres"
      },
      "onboarding": {
        "line_1": "Les célibataires qui vous sont présentés correspondent à tous les critères de recherche que vous avez sélectionnés. Attention, plus vous appliquez de critères restrictifs, plus vous limitez le nombre de candidats potentiels.",
        "line_2": "Vous avez le choix de les trier selon l'ordre de présentation qui vous convient le mieux, soit: ceux qui partagent vos valeurs, les plus près de chez vous ou selon leur dernière connexion sur Effet Tandem.",
        "line_3": "Bonne recherche!",
        "title": "Trouvez votre complice amoureux"
      },
      "subtitle": "Recherchez des célibataires compatibles avec vos valeurs grâce aux filtres de recherche avancés.",
      "title": "Trouvez votre complice amoureux"
    },
    "settings": {
      "advanced": {
        "subtitle": "Paramètres avancés",
        "title": "Avancé"
      },
      "blocks": {
        "empty_state": {
          "title": "Aucun utilisateur bloqué"
        }
      },
      "contact_us": {
        "subtitle": "Si vous avez des questions ou des problèmes, n'hésitez pas à nous joindre.",
        "title": "Nous joindre"
      },
      "daily_quote": {
        "checkbox_description": "En envoyant ma pensée du jour, j'accepte qu'elle soit corrigée ou modifiée par l'équipe Effet Tandem.",
        "profil_not_public_message": "Pour publier une pensée du jour, votre profil doit être public.",
        "retry": "Soumettre une nouvelle pensée",
        "subtitle": "Participez à la Communauté Effet Tandem en partageant votre pensée du jour. Cette pensée pourra se retrouver sur le site web Effet Tandem avec votre nom et votre photo.",
        "success": {
          "description": "Merci de participer à la communauté! Si elle est retenue, vous serai avisé par courriel d’ici quelques jours de la date à laquelle votre pensée sera diffusée."
        },
        "title": "Rédiger votre pensée du jour"
      },
      "menu": {
        "preferences": {
          "address": {
            "subtitle": "Modifier mon adresse résidentielle",
            "title": "Mon adresse"
          },
          "advanced": {
            "subtitle": "Paramètres avancés",
            "title": "Avancé"
          },
          "anonymous_mode": {
            "subtitle": "Activez vos filtres de recherche personnalisée pour une expérience optimisée. Faites confiance à votre ressenti, osez découvrir la personne au-delà de votre premier regard sur sa fiche.",
            "title": "Mode anonyme"
          },
          "communications": {
            "subtitle": "Être avisé par courriel de la réception d’un message?",
            "title": "Préférences de communication"
          },
          "language": {
            "subtitle": "Modifier ma langue préférée",
            "title": "Langue préférée"
          },
          "resources": {
            "subtitle": "Ressources et paramètres supplémentaires",
            "title": "Ressources supplémentaires"
          },
          "title": "Préférences",
          "units": {
            "subtitle": "Choisir mes unités de mesure favoris",
            "title": "Mes unités de mesure"
          }
        },
        "profile": {
          "pictures": {
            "subtitle": "Soumettre, supprimer ou réorganiser mes photos de profil.",
            "title": "Photos"
          },
          "profile": {
            "subtitle": "Compléter mon profil en mettant à jour ou en répondant à de nouvelles questions.",
            "title": "Fiche descriptive"
          },
          "sample_picture": {
            "subtitle": "Mettre à jour votre photo-témoin",
            "title": "Photo-témoin"
          },
          "title": "Modifier mon profil"
        },
        "security": {
          "blocks": {
            "subtitle": "Gérez les utilisateurs que vous avez bloqués. \nVous pouvez les débloquer à tout moment.",
            "title": "Utilisateurs bloqués"
          }
        },
        "subscription": {
          "payment_methods": {
            "subtitle": "Mettre à jour mon mode de paiement.",
            "title": "Mode de paiement"
          },
          "subscription": {
            "subtitle": "Consulter les informations sur l’abonnement en cours, la date de renouvellement et les forfaits disponibles. Permet également d’annuler le prochain renouvellement automatique.",
            "title": "Gérer mon abonnement"
          },
          "title": "Abonnement"
        },
        "visibility": {
          "inbox": {
            "subtitle": "Recevez certains messages dans votre boîte de messagerie secondaire. Définissez vos critères de sélection.",
            "title": "Limiter l’accès à ma messagerie prioritaire"
          },
          "pause": {
            "subtitle": "Activer cette option pour faire une pause",
            "title": "Mettre mon profil en pause"
          },
          "title": "Visibilité",
          "visibility": {
            "subtitle": "Afficher mon profil en mode public ou privé",
            "title": "Gérer la visibilité de mon profil"
          }
        }
      },
      "pause": {
        "button": "Prendre une pause",
        "description": "Vous souhaitez prendre une pause? En activant cette option, vous ne recevrez plus de messages et ne serez plus visible dans l’outil de recherche. Vous pouvez en tout temps remettre votre compte actif pour poursuivre votre démarche sur ce site. Votre abonnement continuera de s'écouler.",
        "title": "Mettre mon profil en pause"
      },
      "profile": {
        "about": {
          "description": "Démarquez-vous! Faites-vous connaître de façon authentique pour que la bonne personne puisse vous trouver. Décrivez qui vous êtes, ce qui est important pour vous, ce que vous aimez, votre personnalité, votre perception de la vie ou autre.",
          "placeholder": "Aidez la communauté à vous découvrir",
          "title": "Présentez vos couleurs"
        },
        "introduction": {
          "subtitle": "Captez l'attention des membres avec un texte authentique qui vous met en valeur.",
          "title": "Introduction"
        },
        "long_questions": {
          "subtitle": "La communauté Effet Tandem aimerait mieux vous connaître. Nous vous invitons à vous dévoiler davantage en répondant à une ou deux questions optionnelles.",
          "title": "Questions à développement"
        },
        "pictures": {
          "delete_image": {
            "message": "Êtes-vous certain de retirer cette photo?",
            "title": "Retirer la photo?"
          },
          "delete_image_danger": {
            "message": "Si vous supprimez cette image, votre album photo sera incomplet.",
            "title": "Attention!"
          },
          "ordering_warning": "La première image de votre album photo doit obligatoirement être un portait.",
          "subtitle": "Ajoutez une nouvelle photo de profil ou réorganisez et supprimez les photos existantes.",
          "title": "Photos de profil",
          "warning_min_1": "Vous avez besoin d'au moins une image"
        },
        "questions": {
          "subtitle": "Présentez qui vous êtes aux autres membres de façon authentique afin de trouver la personne idéale.",
          "title": "Informations générales"
        },
        "seeking": {
          "description": "Soyez réaliste. Souhaiter rencontrer un(e) athlète globetrotter si vous êtes sédentaire n'aurait pas de sens.",
          "description_2": "Attention mesdames, il y a d’excellents candidats de moins de 6 pieds… ",
          "placeholder": "Son mode de vie, ses valeurs",
          "title": "Que recherchez-vous chez votre partenaire?"
        },
        "sexual_orientation": {
          "subtitle": "Mettre à jour votre orientation sexuelle",
          "title": "Orientation sexuelle"
        },
        "short_questions": {
          "subtitle": "Ajoutez une touche de légèreté en répondant à ces questions fantaisistes.",
          "title": "Questions fantaisistes"
        },
        "title": {
          "description": "Ce titre accompagnera votre photo principale dans la section « Recherche ».",
          "placeholder": "Titre ou citation",
          "title": "Titre de votre profil ou citation préférée"
        }
      },
      "sample_picture": {
        "text_1": "Nous vous demandons de prendre un selfie de vous avec une main et les doigts placés comme dans l’exemple ci-dessous.\nCette photo-témoin servira uniquement à des fins d’authentification.",
        "text_2": "Elle ne sera pas visible sur votre profil."
      },
      "secondary_conversation_rules": {
        "notice": "Vous recevez beaucoup de messages, il devient difficile de s’y retrouver! Dirigez certains messages vers votre messagerie secondaire en fonction de vos propres critères (3 maximum).",
        "summary": "Le message sera déplacé si la personne a répondu “Jamais” à la question “À quelle fréquence fumez-vous?” ",
        "summary_age": "Le message sera dirigé dans la messagerie secondaire s’il provient d’une personne qui **a moins de {min}** et **plus de {max}**.",
        "summary_distance": "Le message sera dirigé dans la messagerie secondaire s’il provient d’une personne à plus de **{distance}**.",
        "summary_height": "Le message sera dirigé dans la messagerie secondaire s’il provient d’une personne qui mesure **moins de {min}** ou **plus de {max}**.",
        "summary_question": "Le message sera dirigé dans la messagerie secondaire s’il provient d’une personne qui a répondu **{answers}** à la question **{question}**"
      },
      "subtitle": "Mettre à jour votre profil et ajustez vos préférences personnelles"
    },
    "subscribe": {
      "feature_1": {
        "name": "Envoyer des fleurs aux membres"
      },
      "feature_2": {
        "name": "Filtres de recherche avancés"
      },
      "feature_3": {
        "name": "Des rencontres palpitantes!"
      }
    },
    "subscription": {
      "product_end_notice": {
        "message": "Votre abonnement sera valide jusqu'au {x}."
      },
      "subscription_end_notice": {
        "message": "Votre abonnement se renouvellera automatiquement tous les mois au montant de {price} avant taxes. | Votre abonnement sera automatiquement renouvelé tous les {count} mois au montant de {price} avant taxes.",
        "message_2": "Le prochain renouvellement aura lieu le {end}.",
        "message_3": "Vous pouvez annuler en tout temps le renouvellement automatiquement dans vos paramètres de compte."
      },
      "subtitle": "Tous les forfaits donnent accès à l'entièreté des fonctionnalités."
    }
  },
  "pages": {
    "ambassador": {
      "balance": {
        "claim": "Réclamer un paiement",
        "claim_notice": "Vous devez avoir un solde de {amount} minimum pour demander un paiement. Si vous avez gagné avec nous plus de 500$ au cours d'une année civile, nous vous demanderons de fournir votre NAS. Les paiements seront effectués dans un délai de 7 jours ouvrables. Un courriel de confirmation vous sera acheminé lorsque notre équipe aura traité votre requête.",
        "title": "Balance"
      },
      "info": {
        "description": "Faites connaître Effet Tandem à vos amis et <b>recevez 10$</b> pour chaque nouvel abonnement de 3 mois ou plus.",
        "title": "Offrez un mois gratuit et recevez une récompense en argent"
      },
      "objective": {
        "additional_income": "Revenu supplémentaire",
        "for_fun": "Quelques références pour le plaisir",
        "fundraising": "Campagne de financement (à venir)"
      },
      "objective_title": "Quelle est votre motivation pour adhérer au programme de référencement Effet Tandem ?",
      "payments": {
        "no_payments": "Aucun paiement antérieur",
        "title": "Paiements"
      },
      "rewards": {
        "description": "Voir vos récompenses et réclamer un paiement."
      },
      "settings": {
        "link": "Modifier mon adresse, mes numéros de taxe et autres informations financières.",
        "title": "Paramètres"
      },
      "share": {
        "description": "Copiez votre lien de référencement personnel et partagez-le avec vos amis et abonnés.",
        "title": "Partagez votre lien"
      },
      "share_content": {
        "description": "Partagez des articles avec vos abonnés et vos amis. \nVous recevrez un crédit s'ils s'abonnent après avoir visité l'article via votre lien."
      },
      "sharing_tools": {
        "description": "Partagez des articles et des vidéos par courriel ou sur les médias sociaux.",
        "steps": {
          "article": {
            "subtitle": "Sélectionnez l'article d'expert que vous souhaitez partager.",
            "title": "Sélectionnez la capsule d'expert"
          },
          "content": {
            "subtitle": "Sélectionnez le type de contenu que vous souhaitez partager avec vos amis ou abonnés.",
            "title": "Que souhaitez-vous partager ?"
          },
          "media": {
            "subtitle": "Sélectionnez la plateforme sur laquelle vous souhaitez partager le contenu.",
            "title": "Sélectionnez la plateforme de partage"
          },
          "share": {
            "subtitle": "Cliquez sur le bouton ci-dessous pour partager votre lien de référencement avec vos amis ou abonnés.",
            "title": "Partagez avec vos amis !"
          },
          "video": {
            "subtitle": "Sélectionnez la vidéo promotionnelle que vous souhaitez partager.",
            "title": "Sélectionnez la vidéo promotionnelle"
          }
        }
      },
      "sin_warning": {
        "button": "Ajouter mon NAS",
        "title": "Comme vous avez gagné plus de 500$ lors de l'année financière actuelle ou précédente, vous devez fournir votre NAS. "
      },
      "stats": {
        "title": "Statistiques"
      },
      "subtitle": "Aidez à Bâtir un monde meilleur, une relation à la fois",
      "title": "Référez vos amis"
    },
    "onboarding": {
      "email_verification": {
        "alert": "Oups! Votre adresse courriel n'a pas été vérifiée, veuillez vérifier vos courriels.",
        "body": {
          "line_1": "Recherchez le courriel de vérification dans la boîte de réception de **{email}** et suivez les instructions.",
          "line_2": "Si vous ne voyez pas le courriel, veuillez vérifier vos courriels indésirables."
        },
        "issue": {
          "line_1": "Vérifiez votre boite de courriels indésirable pour vous assurer qu'il ne s'y est pas retrouvé. \nVous pouvez également ajouter l'adresse courriel {from} à votre carnet d'adresses, puis réessayer d'envoyer le courriel.",
          "line_2": "Si vous ne recevez toujours pas le courriel, regardez vos courriels indésirables. Sinon contactez notre support à la clientèle pour vous aider à résoudre le problème.",
          "title": "Vous n'avez pas reçu le courriel ?"
        },
        "title": "Vérifiez vos courriels"
      },
      "email_verification_success": {
        "text": "Votre adresse courriel a été vérifiée avec succès. Vous pouvez à présent poursuivre votre inscription.",
        "title": "Courriel vérifié avec succès"
      },
      "terms": {
        "body": "En utilisant Effet Tandem, vous acceptez les conditions d’utilisation.",
        "title": "Conditions d'utilisation"
      }
    },
    "personalDevelopment": {
      "article": {
        "description": "Des articles d’experts pour encourager la découverte de soi.",
        "subtitle": "Encourageant la découverte de soi.",
        "title": "Articles"
      },
      "bonus": {
        "description": "Voici 3 formations d’une valeur total de 494 $ offertes en bonus pour les célibataires qui ont choisi de souscrire au forfait mois Effet 6 mois sur Effet Tandem.",
        "subtitle": "Cadeaux pour abonnés au forfait Effet 6 mois.",
        "title": "Bonus Effet 6 Mois"
      },
      "specialists": {
        "subtitle": "Collaboration de spécialistes multidisciplinaires renommés.",
        "title": "Nos spécialistes"
      },
      "video": {
        "description": "Des capsules vidéos favorisant les prises de conscience vers la réussite de votre vie amoureuse.",
        "subtitle": "Favorisant la réussite de votre vie amoureuse.",
        "title": "Capsules Vidéo"
      }
    },
    "referrals": {
      "no_referral": "Pas de référence pour le moment",
      "title": "Références"
    }
  },
  "paid": "Payé",
  "password": "Mot de passe",
  "password_confirmation": "Confirmation du mot de passe",
  "password_rule": {
    "lower_and_cap": "Doit contenir au moins une majuscule et une minuscule",
    "min_size": "Doit contenir un minimum de 8 caractères",
    "number": "Doit contenir au moins un chiffre"
  },
  "past_payments": "Paiements antérieurs",
  "pay_now": "Payez maintenant",
  "payment_method": "Mode de paiement",
  "payment_success_component": {
    "subtitle": "Merci de votre confiance, nous sommes heureux que vous décidiez de poursuivre l'expérience Effet Tandem avec notre communauté.",
    "title": "Paiement complété avec succès"
  },
  "pending": "En attente",
  "pending_claim": "Réclamation en attente",
  "pending_names_authorization": "En attente de l'autorisation de {name}",
  "pending_pictures": "Photos en attente",
  "pending_validation": "En attente de validation",
  "personal_development": "Développement personnel",
  "personality_traits": "Traits de personalité",
  "phone": "Téléphone",
  "picture_mode": "Mode photo",
  "picture_successfully_uploaded": "Image téléchargée avec succès",
  "picture_validation_notice": {
    "attributes": {
      "match": "Correspond à votre photo-témoin",
      "portrait": "Montre clairement votre visage, sans chapeau, lunettes de soleil ou tout autre objet couvrant une partie notable de votre visage",
      "terms": "Respecte les conditions d'utilisation"
    },
    "message": "Chez Effet Tandem, nous avons à cœur de vous offrir une expérience authentique et un espace sécuritaire au sein duquel vous pouvez évoluer en toute confiance. C’est pourquoi notre équipe s’engage à valider et authentifier les photos de chacun des membres afin de s’assurer de leur identité et de vous garantir la tranquillité d’esprit lors de vos rencontres.",
    "message_2": "Une photo peut avoir un des trois statuts suivants: validé, authentifié ou portrait.",
    "status": {
      "authenticated": "Photo authentifiée",
      "portrait": "Photo portrait",
      "validated": "Photo validée"
    },
    "title": "Comment fonctionne la validation et l'authentification des photos?"
  },
  "please_enter_your_phone_number": "Veuillez entrer votre numéro de téléphone",
  "portrait": "Portrait",
  "postal_code_zip_code": "Code postal",
  "premium_content": "Contenu Premium",
  "preview_my_profile": "Prévisualiser mon profil",
  "previous": "Précédent",
  "primary": "Primaire",
  "priority": "Prioritaire",
  "priority_inbox": "Messagerie prioritaire",
  "priority_inbox_description": "Indique si vous désirez recevoir un courriel de notification lorsque vous recevez un message dans votre boite de messagerie prioritaire.",
  "privacy_policy": "Politique de confidentialité",
  "product_not_found": "Produit non trouvé!",
  "profile_preview": "Aperçu du profil",
  "proximity": "Proximité",
  "pst_qst": "TVP/TVQ",
  "re_enter_email_address": "Ressaisissez l'adresse courriel",
  "re_enter_your_new_email_address": "Ressaisissez à nouveau votre nouvelle adresse courriel",
  "read_more": "Lire plus",
  "redeem": "Racheter",
  "refer_your_friends": "Référez vos amis",
  "referral_code": "Code de référencement",
  "referral_link": "Lien de référencement",
  "referrals": "Références",
  "refine_your_search": "Affiner votre recherche",
  "refused": "Refusé",
  "refused_pictures": "Photos refusées",
  "register_modal": {
    "line_1": "Effet Tandem est un site de rencontre sécuritaire fondé sur l'authenticité et le développement personnel."
  },
  "register_now": "Inscrivez-vous dès maintenant",
  "religion": "Croyance / Religion",
  "religious_beliefs": "Croyances religieuses",
  "remove": "Retirer",
  "report": "Signaler",
  "required": "Obligatoire",
  "resend_email": "Ré-envoyer le courriel",
  "resend_link": "Renvoyer le lien",
  "reset": "Réinitialiser",
  "reset_all": "Effacer tout",
  "reset_password": "Réinitialiser le mot de passe",
  "return_home": "Retour à l'accueil",
  "return_to_login": "Revenir à la page de connexion",
  "return_to_settings": "Revenir aux paramètres",
  "reveal_my_pictures": "Révéler mes photos",
  "rewards": "Récompenses",
  "sample_picture_specifications": "Spécifications de la photo-témoin",
  "save": "Enregistrer",
  "scroll": "Faire défiler",
  "search": "Recherche",
  "search_a_new_address": "Rechercher une nouvelle adresse",
  "search_an_article": "Rechercher un article",
  "search_filters": "Filtres de recherche",
  "search_is_done_from_this_address": "La recherche est effectuée à partir de cette adresse",
  "secondary": "Secondaire",
  "secondary_inbox": "Messagerie secondaire",
  "secondary_inbox_description": "Indique si vous désirez recevoir un courriel de notification lorsque vous recevez un message dans votre boite de messagerie secondaire.",
  "security": "Sécurité",
  "see_more": "Voir plus",
  "seen": "Vu",
  "select": "Sélectionner",
  "select_a_question": "Sélectionner une question",
  "select_an_article": "Sélectionner un article",
  "select_an_item": "Sélectionner un élement",
  "select_an_option": "Sélectionner une option",
  "select_at_least_x": "Sélectionnner au moins {x}",
  "selected": "Sélectionné",
  "send": "Envoyer",
  "send_a_flower": "Offrir une fleur",
  "send_a_flower_back": "Offrir une fleur en retour",
  "send_a_message": "Envoyer un message",
  "send_email": "Envoyer courriel",
  "send_flower_confirmation": {
    "line_1": "Vous n'avez qu'une seule fleur par jour. Assurez-vous de l'offrir à la bonne personne.",
    "title": "Offrir une fleur?"
  },
  "settings": "Paramètres",
  "share": "Partager",
  "share_by_email": "Partager avec un courriel",
  "share_content": "Partager du contenu",
  "share_member_header_f": "Que penses-tu de cette célibataire ?",
  "share_member_header_m": "Que penses-tu de ce célibataire ?",
  "share_on_facebook": "Partager sur Facebook",
  "share_on_messenger": "Partager sur Messenger",
  "share_on_twitter": "Partager sur Twitter",
  "sharing_tools": "Outils de partage",
  "sign_in": "Se connecter",
  "sign_in_with": "Se connecter avec",
  "sign_in_with_x": "Se connecter avec {x}",
  "sign_up_with_email": "S'inscrire avec courriel",
  "sign_up_with_x": "S'inscrire avec {x}",
  "silhouette": "Silhouette",
  "skip": "Passer",
  "skip_authentication": "Sauter l'étape d'authentification",
  "skip_question": "Passer la question",
  "sort": "Ordonner",
  "sorts": {
    "last_connection": {
      "description": "Affiche les célibataires qui se sont connecté récemment en premier.",
      "title": "Dernière connexion"
    },
    "proximity": {
      "description": "Afficher les célibataires les plus proche de vous en premier.",
      "title": "Proximité"
    },
    "values": {
      "description": "Afficher les célibataires qui ont le plus de valeurs en commun avec moi en premier.",
      "title": "Valeurs"
    }
  },
  "special_offer": "Offre spéciale",
  "spoken_languages": "Langues parlées",
  "start_recording": "Commencer l'enregistrement",
  "start_your_search": "Commencez votre recherche",
  "start_your_search_for_love": "Commencer votre recherche d'amour",
  "state_paused": {
    "button": "Mettre fin à ma pause",
    "message": "Cela signifie que vous désirez prendre temporairement un moment d'arrêt. Votre profil ne sera plus visible par personne sur ce site. Le temps de votre abonnement continuera de s'écouler.",
    "message_2": "Vous pouvez mettre fin à votre pause en utilisant le bouton ci-dessous.",
    "message_3": "Profitez-en pour consulter notre section s'épanouir. Elle propose des pistes de réflexion via des articles et vidéos réalisés par des spécialistes issus de différents domaines.",
    "title": "Vous êtes présentement en pause"
  },
  "state_prelaunch": {
    "message": "Nous sommes en période de pré-lancement et pas toutes les sections sont encore disponibles. Revenez le 20 juin 2022 pour notre lancement officiel!",
    "message_1": "Félicitations pour votre inscription et merci d’avoir rejoint la communauté Effet Tandem ! Votre profil est désormais prêt pour le lancement du 20 juin 2022.",
    "message_2": "Dès le lancement du 20 juin, vous aurez accès à toutes les fonctionnalités du site et vous pourrez finalement rencontrer votre futur complice.",
    "message_3": "Pendant cette période de pré-inscription, prenez le temps de vous ressourcer dans la section S’épanouir qui met en valeur plusieurs capsules de spécialiste. ",
    "message_4": "Partagez le mot à vos amis et vos proches pour agrandir la communauté tout en étant récompensé grâce au programme Référencement.",
    "title": "En prélancement !"
  },
  "state_province": "État / Province",
  "state_suspended": {
    "incomplete_gallery": {
      "cta": "Mettre à jour mon album photo",
      "line_1": "Malheureusement, votre album photo n’a toujours pas été ajusté alors que celui-ci ne répondait pas aux normes de notre site.",
      "line_2": "Suivant la période de grâce, nous avons temporairement suspendu votre compte. Pendant ce temps, vous ne serez pas visible par le reste de la communauté.",
      "line_3": "Nous vous encourageons grandement à effectuer les ajustements requis pour mettre en valeur votre profil et rencontrer votre futur partenaire de cœur."
    },
    "other": {
      "line_1": "Votre compte a été suspendu par l'administration. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'assistance clientèle."
    },
    "title": "Votre compte a été suspendu"
  },
  "state_unsubscribed": {
    "message": "Vous êtes actuellement désabonné et n'avez plus accès aux fonctionnalités d'Effet Tandem. \nPour vous abonner, veuillez choisir l'un des forfaits dans la section ci-dessous.",
    "title": "Vous êtes désinscrit"
  },
  "status": {
    "email_not_verified": {
      "title": "Votre adresse courriel n'a pas été vérifiée"
    },
    "pause": {
      "title": "Profil en pause"
    },
    "pending_authentication": {
      "title": "Profil en attente d'authentification"
    }
  },
  "step": {
    "audio_introduction": {
      "text": "En quelques mots, présentez-vous ou souhaitez la bienvenue aux visiteurs de votre profil. \nSimple et génial !",
      "title": "Enregistrez un court message d’accueil sur votre profil."
    },
    "authentication": {
      "accept": {
        "text": "J’autorise l’entreprise Effet Tandem Inc à utiliser le service eIDComparaison pour confirmer la validité de mon nom, ma date de naissance et mon lieu de résidence.",
        "text_2": "Ce service est opéré par la compagnie Equifax, il sert exclusivement à l’identification des individus.",
        "text_3": "Vos coordonnées ne seront jamais partagées ni vendues."
      },
      "error": {
        "cta": "J'ai compris",
        "line_1": "Votre identité sera vérifiée manuellement par un administrateur. En attendant, vous pouvez poursuivre votre processus d’inscription.",
        "line_2": "Notre service à la clientèle vous contactera sous peu. Veuillez entrer votre numéro de téléphone ainsi que vos disponibilités dans les champs ci-dessous.\n",
        "title": "Erreur lors de la validation de votre profil"
      },
      "no_match_skip_text": "Votre identité sera vérifiée manuellement par un administrateur. En attendant, vous pouvez poursuivre votre processus d’inscription.",
      "no_match_skip_text_2": "Notre service à la clientèle vous contactera sous peu. Veuillez entrer votre numéro de téléphone ainsi que vos disponibilités dans les champs ci-dessous.",
      "remaining_attempts": "Il ne vous reste aucun essai. | \nIl ne vous reste qu'un seul essai. | \nIl vous reste {count} essais.",
      "success": {
        "line_1": "Vous avez été authentifié! Vous êtes désormais autorisés à utiliser le site Effet Tandem.",
        "title": "Votre identité a été authentifiée avec succès"
      },
      "success_text": "Votre identité a été authentifiée par le système eIDComparaison",
      "text": "Effet Tandem a embauché un « cyberportier » pour vous protéger ! En vérifiant l’identité de nos utilisateurs avec eIDComparaisonMD, nous réduisons les risques de faux profils. Cela vous protège contre l'escroquerie et garantit l’exactitude de l’âge des utilisateurs. **Seuls votre prénom et le nom de votre ville seront affichés sur votre profil.**",
      "title": "Vérification de votre identité",
      "warning": "Assurez-vous d’inscrire votre nom, votre date de naissance et votre lieu de résidence exactement comme ils sont écrits dans vos documents officiels, **SINON VOUS SEREZ REFUSÉ**.",
      "warning_modal": {
        "title": "Problème d'authentification"
      },
      "warning_same_user_modal": {
        "contact": "Si vous pensez que la situation ne s’applique pas à votre cas ou que vous avez des questions, rejoignez notre service à la clientèle au {phone}.",
        "line_1": "Un utilisateur avec le même nom et la même date de naissance est déjà enregistré en utilisant l'e-mail suivant : {email}",
        "line_2": "Cet utilisateur a été enregistré avec {login}.",
        "logout": "Connectez-vous à un autre compte",
        "title": "Un utilisateur avec le même nom et la même date de naissance est déjà enregistré"
      }
    },
    "introduction": {
      "title": "Captez l'attention des membres avec un texte authentique qui vous met en valeur."
    },
    "introduction_video": {
      "title": "Un coup de pouce pour vous aider à vous présenter ?"
    },
    "long_question": {
      "text": "Vous pouvez répondre au maximum à {max} questions (facultatif).",
      "title": "La communauté Effet Tandem aimerait mieux vous connaître"
    },
    "payment": {
      "title": "Choisissez votre forfait",
      "trial": {
        "cta": "Démarrer l'essai gratuit",
        "title": "Ou continuez pendant une période d'essai de 5 jours!"
      }
    },
    "pictures": {
      "main_photo_notice": {
        "line_1": "Une photo de près qui permet de vous identifier",
        "line_2": "Vue de face sans lunettes de soleil ni chapeau",
        "line_3": "Prise récemment et de bonne qualité",
        "line_4": "Être seul sur sa photo",
        "line_5": "Éviter d’être trop suggestif",
        "line_6": "Éviter les filtres ou les retouches sur vos photos",
        "text": "La photo principale de votre profil doit répondre aux critères suivants :"
      },
      "text": "Partagez vos plus belles photos. \nLe sourire est contagieux !",
      "title": "Créez votre album photos",
      "warning": "Un minimum de deux photos doit être soumis. \nLes photos seront validées et identifiées."
    },
    "pictures_video": {
      "text_1": "Le choix de vos photos doit se faire avec minutie. Elles sont votre carte de visite virtuelle. Votre photo principale doit être du type passeport (mais avec le sourire). Nous vous suggérons d’afficher également une photo prise de la tête aux pieds.",
      "text_2": "Nous nous réservons le droit de refuser certaines photos.",
      "title": "7 conseils pour bien choisir vos photos\n"
    },
    "sample_picture": {
      "image_no_accessories": "Pas de chapeaux, lunettes ou accessoires qui cachent une partie importante de votre visage.",
      "info": "Votre photo-témoin doit répondre à nos critères pour que nous acceptions de publier votre profil.",
      "text_1": "Effet Tandem est soucieux d'identifier ses utilisateurs. Nous vous demandons de prendre une photo de vous avec une main et les doigts placés comme dans l’exemple ci-dessous.",
      "text_2": "Cette photo-témoin servira uniquement à des fins d’authentification. Elle ne sera pas visible sur votre profil.",
      "title": "Votre photo-témoin aux fins d'authentification"
    },
    "short_question": {
      "text": "Vous pouvez répondre à autant de questions que vous le souhaitez (facultatif).",
      "title": "Ajoutez une touche de légèreté en répondant à ces questions fantaisistes."
    }
  },
  "subject": "Sujet",
  "submit": "Soumettre",
  "submitted_by_x": "Proposée par {name}",
  "subscribe": "S'abonner",
  "subscribe_now": "S'abonner maintenant",
  "subscription": "Abonnement",
  "success": "Succès",
  "support": "Support",
  "suspended": "Suspendu",
  "tagline": "S'unir et s'épanouir",
  "tax_numbers": "Numéros de taxes",
  "terms_and_conditions": "Conditions d'utilisation",
  "the_file_size_must_not_exceed_x": "La taille du fichier ne doit pas dépasser {x}",
  "the_image_must_be_at_least_x_high": "l'image doit avoir au moins {x} de haut",
  "the_image_must_be_at_least_x_wide": "l'image doit être {x} de large au minimum",
  "the_terms_and_conditions": "Les Conditions d'utilisation",
  "the_thought_of_the_day": "La pensée du jour",
  "this_conversation_is_in_x_secondary_inbox": "{x} verra cette conversation dans sa messagerie secondaire",
  "title_quote": "Titre / Citation",
  "try_again": "Réessayer",
  "try_again_with_another_email": "Essayer avec une autre adresse courriel",
  "try_another_email": "Essayez un autre courriel",
  "type_an_email": "Tapez un courriel",
  "unavailable": "Indisponible",
  "unavailable_user": "Utilisateur indisponible",
  "unblock": "Débloquer",
  "understood": "Bien compris",
  "units": {
    "b": "o",
    "gb": "Go",
    "kb": "Ko",
    "mb": "Mo",
    "tb": "To"
  },
  "unknown_author": "Auteur inconnu",
  "unsubscribed": "Désabonné",
  "up_to_x": "jusqu'à {x}",
  "update": "Mettre à jour",
  "update_address": "Mettre à jour l'adresse",
  "update_payment_method": "Mettre à jour le mode de paiement",
  "upload_a_picture": "Télécharger une photo",
  "upload_a_sample_picture": "Télécharger votre photo-témoin",
  "upload_new_file": "Télécharger un nouveau fichier",
  "upload_or_drag_and_drop": "Télécharger un fichier ou glisser-déposer",
  "upload_your_invoice": "Téléchargez votre facture",
  "urls": {
    "contact": "fr/nous-joindre",
    "privacy": "fr/politique-de-confidentialite",
    "terms": "fr/termes-et-conditions"
  },
  "use_my_default_address": "Utiliser mon adresse par défaut",
  "validated": "Validé",
  "values": "Valeurs",
  "video_clips": "Capsules",
  "view_profile": "Voir le profil",
  "vip_members": "Membres 6 Mois",
  "visibility": "Visibilité",
  "visit_website": "Visitez le site Web",
  "visits": "Visites",
  "voice": "Voix",
  "vote_sent_successfully": "Note envoyée avec succès",
  "wants_children": "Veut des enfants",
  "warning": "Avertissement",
  "what_do_you_think_of_the_article": "Que pensez-vous de l'article?",
  "what_i_am_looking_for_in_my_partner": "Ce que je recherche chez mon partenaire",
  "what_is_your_gender": "À quel genre vous identifiez-vous?",
  "whats_your_sexual_orientation": "Par qui êtes-vous attiré?",
  "whoops": "Oups !",
  "with_camera": "Avec caméra",
  "with_video": "Avec vidéo",
  "woman": "Femme",
  "women": "Femmes",
  "x_days_remaining": "Aucun jour restant | 1 journée restante | {count} jours restants",
  "x_did_not_answer_the_call": "{x} n'a pas répondu à l'appel",
  "x_digits_code": "Codes à {x} chiffres",
  "x_free_months": "{count} mois gratuit|{count} mois gratuits",
  "x_seconds_recording_maximum": "Enregistrement de {x} secondes maximum",
  "x_selected": "{x} sélectionnés",
  "x_tax_included": "Taxe de {x} incluse",
  "x_values_in_common": "<span class=\"font-bold\">Aucune valeur</span> en commun | <span class=\"font-bold\">1 valeur</span> en commun | <span class=\"font-bold\">{count} valeurs</span> en commun",
  "x_wants_to_start_a_video_call": "{x} souhaite démarrer un appel vidéo",
  "year": "Année",
  "years_old": "ans",
  "yes": "Oui",
  "you_already_sent_a_flower_in_conversation": "Vous avez déjà offert une fleur à cette personne",
  "you_already_sent_a_flower_today": "Vous avez déjà offert une fleur aujourd'hui, attendez qu'elle repousse!",
  "you_are_in_a_trial_period": "Vous êtes en période d'essai",
  "you_are_receiving_a_call_from_x": "{x} vous appelle",
  "you_are_subscribed": "Vous êtes abonné",
  "you_are_unsubscribed": "Vous êtes désinscrit",
  "you_are_unsubscribed_description": "Vous êtes présentement désinscrit et n'avez plus accès aux fonctionnalités d'Effet Tandem. Pour vous inscrire, veuillez choisir un des forfait dans la section ci-dessous.",
  "you_can_answer_up_to_x_questions": "Vous pouvez répondre au maximum à {x} questions",
  "you_can_select_more_than_one": "Vous pouvez sélectionner plusieurs options",
  "you_can_upload_up_to_x_pictures": "Vous pouvez télécharger jusqu'à {x} photos",
  "you_cannot_select_more_than_x_items": "Vous ne pouvez pas sélectionner plus de {x} éléments",
  "you_cant_offer_a_flower": "Vous ne pouvez pas offrir de fleur",
  "you_currently_have_no_payment_method": "Vous n'avez actuellement aucun mode de paiement",
  "you_did_not_receive_the_email": "Vous n'avez pas reçu de courriel ?",
  "you_dont_have_an_account": "Vous n'avez pas de compte?",
  "you_have_this_in_common": "Vous avez cela en commun!",
  "you_may_add_additional_months_to_your_subscription_below": "Vous pouvez ajouter des mois supplémentaires à votre abonnement dans la section ci-dessous.",
  "you_may_select_more_than_one": "Vous pouvez sélectionner plusieurs",
  "you_must_read_the_terms_and_conditions_before_accepting": "Vous devez lire les conditions d'utilisation avant de les accepter",
  "you_must_upload_at_least_x_pictures": "Vous devez télécharger au moins {x} photos",
  "you_must_upload_between_x_to_y_pictures": "Vous devez télécharger {x} à {y} photos",
  "you_scroll_read_the_terms_and_conditions_before_accepting": "Vous devez faire défiler et lire les conditions d'utilisation ci-dessus avant d'accepter.",
  "your_address": "Votre adresse",
  "your_current_email": "Votre courriel actuel",
  "your_current_email_address": "Votre adresse courriel actuelle",
  "your_first_picture_must_be_a_portrait": "Votre première photo doit être un portrait",
  "your_payment_method_was_successfully_added": "Votre mode de paiement a été ajouté avec succès",
  "your_phone_number": "Votre numéro de téléphone",
  "your_profile": "Votre profil",
  "your_subscription_ends_on_x": "Votre abonnement prendra fin le {x}",
  "your_thought_of_the_day": "Votre pensée du jour",
  "your_trial_ends_on_x": "Votre période d'essai se termine le {x}"
}
