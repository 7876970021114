import { getHost } from "./utils";

interface Edits {
  resize?: {
    width?: number,
    height?: number,
    fit?: "cover" | "contain" | "fill" | "inside" | "outside",
  },
  sharpen?: boolean,
}

const assetUrl = (path: string, edits: Edits = {}): string => {
  return assetUrlBuilder(path, edits);
};

const assetUrlBuilder = (path: string, edits: Edits = {}): string => {

  let baseURL = import.meta.env.VITE_ASSET_URL;

  if (!baseURL) {
    baseURL = getHost();
  }

  if (baseURL.endsWith("/")) {
    baseURL = baseURL.slice(0, -1);
  }

  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  if (!baseURL.includes("cloudfront")) {
    return `${baseURL}/storage/${path}`;
  }

  const bucket = import.meta.env.VITE_AWS_BUCKET ?? "tandem-local-bucket";

  if (edits.sharpen === undefined && !path.endsWith(".svg")) {
    edits.sharpen = true;
  }

  const imageRequest = JSON.stringify({
    bucket: bucket,
    key: path,
    edits: edits
  });

  return baseURL + "/" + btoa(imageRequest);
};

export { assetUrl };
