<template>
  <div class="relative w-full py-3 mb-6 bg-white">
    <BaseContainer :size="containerSize">
      <div class="flex mb-2 space-x-2">
        <div>
          <button
            type="button"
            class="btn btn-secondary-outline flex items-center rounded"
            @click="showFilters()"
          >
            <Icon
              icon="heroicons-outline:adjustments"
              class="w-5 h-5 mr-2"
            />
            <span class="whitespace-nowrap mr-1">{{ $t('define_your_search_filters') }}</span>
            <span
              v-if="numberOfFilters"
              class="bg-primary-600 px-1.5 text-xs text-white rounded-full"
            >{{ numberOfFilters }}</span>
          </button>
        </div>

        <FilterList />
      </div>
      <div
        class="flex justify-between"
      >
        <!-- Anonymous -->
        <AnonymousMode class="grow-0 shrink-0" />
        <!-- Sort -->
        <SortButtons />
      </div>
    </BaseContainer>
  </div>
</template>

<script lang="ts">

import { defineComponent, inject } from "vue";
import AnonymousMode from "./AnonymousMode.vue";
import SortButtons from "./SortButtons.vue";
import FilterList from "../filterList/FilterList.vue";

export default defineComponent({
  components: {
    AnonymousMode,
    SortButtons,
    FilterList
  },
  props: {
    containerSize: {
      required: true,
      type: String
    }
  },
  setup() {
    const numberOfFilters = inject("numberOfFilters") as boolean;
    const showFilters = inject("showFilters") as () => void;

    return {
      numberOfFilters,
      showFilters
    };
  }
});
</script>
