<template>
  <ProtectionLayout>
    <PageSearchContent />
  </ProtectionLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageSearchContent from "./PageSearchContent.vue";
import ProtectionLayout from "../ProtectionLayout.vue";

export default defineComponent({
  components: { PageSearchContent, ProtectionLayout }
});
</script>
