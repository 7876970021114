<template>
  <div class="min-h-14 relative">
    <BaseLoadingCover
      v-model="loading"
      backdrop-class="bg-gray-50"
    />
    <div
      v-for="paymentSource in paymentSources"
      :key="paymentSource.id"
      class="px-4 py-5 mb-2 bg-white border border-gray-300 rounded-lg shadow"
    >
      <PaymentSourceVue
        :payment-source="paymentSource"
        :show-delete="showDelete"
        @deleted="fetch"
      />
    </div>
    <BaseCard v-if="firstLoad && paymentSources.length == 0">
      <BaseCardRow>
        {{ $t('you_currently_have_no_payment_method') }}
      </BaseCardRow>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PaymentSource } from "@/models/PaymentSource";
import PaymentSourceVue from "./PaymentSource.vue";

export default defineComponent({
  components: {
    PaymentSourceVue,
  },
  props: {
    showDelete: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      firstLoad: false,
      paymentSources: [] as PaymentSource[]
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;

      this.$api.get("payment-sources")
        .then(response => {
          this.paymentSources = response.data.data as PaymentSource[];
          this.firstLoad = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
