<template>
  <BaseSideModal
    :model-value="visibleFilters"
    @update:model-value="$event == false ? applyFilters() : null"
  >
    <div class="shrink-0 sm:py-6 p-4 border-b border-gray-200">
      <h3
        class="text-xl font-semibold leading-6 text-gray-900"
      >
        {{ $t('refine_your_search') }}
      </h3>
      <p class="mt-1 leading-tight text-gray-600">
        <Icon
          icon="heroicons-outline:information-circle"
          class="-top-px relative inline w-5 h-5 text-gray-400"
        />
        {{ $t('page.search.filter_box.description') }}
      </p>
    </div>

    <div
      class="grow pb-6 pl-4 pr-6 overflow-y-auto"
      scroll-lock-target
    >
      <BaseLazy>
        <SearchFilter
          v-if="sexualOrientationOptions.length > 1"
          :title="$t('page.search.filters.genders.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('sexual_orientations')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.sexual_orientations"
            :options="sexualOrientationOptions"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.age.title')"
          :active="filterIsActive('age')"
        >
          <InputSlider
            v-model="localFilters.data.age"
            :min="minAge"
            :max="maxAge"
            range
            :format="(value: number) => `${value} ${$t('years_old')}`"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('distance')"
          :active="filterIsActive('distance')"
        >
          <InputSlider
            v-model="localFilters.data.distance"
            :min="minDistance"
            :max="maxDistance"
            :format="(value: number) => $display.distance(value)"
          />

          <div class="mt-6">
            <AddressField
              :filters="localFilters.data"
              @update="(data: any) => {
                if (data) {
                  localFilters.data.latitude = data.latitude;
                  localFilters.data.longitude = data.longitude;
                  localFilters.data.location_label = data.label;
                } else {
                  localFilters.data.latitude = undefined;
                  localFilters.data.longitude = undefined;
                  localFilters.data.location_label = undefined;
                }
              }"
            />
          </div>
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.objective.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__objective')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__objective"
            :options="options('objective')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.language.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__languages')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__languages"
            :options="options('languages')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.family_situation.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__family_situation')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__family_situation"
            :options="options('family_situation')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.kids_living_with.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__kids_living_with')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__kids_living_with"
            :options="options('kids_living_with')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.wants_children.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__want_to_have_children')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__want_to_have_children"
            :options="options('want_to_have_children')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.education_level.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__education_level')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__education_level"
            :options="options('education_level')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.current_occupation.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__current_occupation')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__current_occupation"
            :options="options('current_occupation')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.alcohol_consumption_frequency.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__alcohol_consumption_frequency')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__alcohol_consumption_frequency"
            :options="options('alcohol_consumption_frequency')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.smoking.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__smoking')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__smoking"
            :options="options('smoking')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.smoking_frequency.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__smoking_frequency')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__smoking_frequency"
            :options="options('smoking_frequency')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.smoking_tolerance_level.title')"
          :active="filterIsActive('smoking_tolerance_level')"
        >
          <BaseSwitch v-model="localFilters.data.smoking_tolerance_level" />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.food_preferences.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__food_preferences')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__food_preferences"
            :options="options('food_preferences')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.personality_traits.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__personality_traits')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__personality_traits"
            :options="options('personality_traits')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.values.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__values')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__values"
            :options="options('values')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.religious_belief.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__religious_beliefs')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__religious_beliefs"
            :options="options('religious_beliefs')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.religion.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__religion')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__religion"
            :options="options('religion')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.personal_development_importance.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__personal_development_importance')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__personal_development_importance"
            :options="options('personal_development_importance')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.accepts_persons_with_limitations.title')"
          :active="filterIsActive('accepts_persons_with_limitations')"
        >
          <BaseSwitch v-model="localFilters.data.accepts_persons_with_limitations" />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.areas_of_interest.title')"
          :subtitle="$t('you_may_select_more_than_one')"
          :active="filterIsActive('question__areas_of_interest')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__areas_of_interest"
            :options="options('areas_of_interest')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.keyword_research.title')"
          :subtitle="$t('page.search.filters.keyword_research.description')"
          :active="filterIsActive('keywords')"
        >
          <FreeTagInput
            :model-value="localFilters.data.keywords || []"
            :max="5"
            :placeholder="$t('add_a_tag')"
            @update:model-value="localFilters.data.keywords = $event"
          />
          <p class="mt-1 text-sm text-gray-500">
            {{ $t('maximum_x', {x: 5}) }}
          </p>
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.silhouette.title')"
          :subtitle="$t('page.search.filters.silhouette.description')"
          :active="filterIsActive('question__silhouette')"
        >
          <CheckBoxGroup
            v-model="localFilters.data.question__silhouette"
            :options="options('silhouette')"
          />
        </SearchFilter>

        <SearchFilter
          :title="$t('page.search.filters.height.title')"
          :active="filterIsActive('question__height')"
        >
          <InputSlider
            :model-value="[localFilters.data.question__height?.min ?? minHeight, localFilters.data.question__height?.max ?? maxHeight]"
            :min="minHeight"
            :max="maxHeight"
            range
            :format="(value: number) => $display.height(value)"
            @update:model-value="onHeightUpdate"
          />
        </SearchFilter>
      </BaseLazy>
    </div>

    <div class="shrink-0 border-t border-gray-200">
      <div
        v-if="maxFilters"
        class="px-4 pt-4"
      >
        <div class="bg-yellow-50 p-4 border border-yellow-400 rounded-md">
          <div class="flex">
            <div class="sm:block shrink-0 hidden">
              <Icon
                icon="heroicons-solid:exclamation"
                class="w-5 h-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div class="sm:ml-3">
              <h3 class="font-semibold leading-tight text-yellow-800">
                {{ $t('page.search.max_filters.title') }}
              </h3>
              <div class="sm:block hidden mt-1 text-sm leading-tight text-yellow-700">
                <p>
                  {{ $t('page.search.max_filters.description') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sm:py-6 p-4 space-x-2">
        <BaseButton
          class="btn-lg"
          @click="resetFilters()"
        >
          {{ $t('reset_all') }}
        </BaseButton>
        <BaseButton
          class="btn-primary btn-lg"
          :disabled="!canApplyFilters"
          @click="applyFilters()"
        >
          {{ $t('apply') }}
        </BaseButton>
      </div>
    </div>
  </BaseSideModal>
</template>

<script lang="ts">
import { defineComponent, inject, Ref, reactive, provide } from "vue";
import SearchFilter from "./SearchFilter.vue";
import { Filters } from "../../models/Filters";
import { QuestionOption } from "@/models/QuestionOption";
import { CheckBoxOption } from "@/components/form/types";
import { cloneDeep, isArray } from "lodash-es";
import FreeTagInput from "@/plugins/form/components/elements/FreeTagInput.vue";
import { SexualOrientation } from "@/models/User";
import AddressField from "./components/AddressField.vue";

export default defineComponent({
  components: {
    SearchFilter,
    FreeTagInput,
    AddressField,
  },
  setup() {
    const localFilters = reactive({data: {}}) as {data: Filters};

    const mobile = inject("mobile") as Ref<boolean>;
    const getNumberOfFilters = inject("getNumberOfFilters") as (filters: Filters) => number;
    const visibleFilters = inject("visibleFilters") as Ref<boolean>;
    const hideFilters = inject("hideFilters") as () => void;

    const resetEventListeners = {} as {[id: string] : {() : void}};

    provide("resetEventListeners", resetEventListeners);

    const minAge = 18;
    const maxAge = 99;

    const minDistance = 3;
    const maxDistance = 400;

    const minHeight = 122;
    const maxHeight = 213;

    return {
      mobile,
      getNumberOfFilters,
      visibleFilters,
      hideFilters,
      minAge,
      maxAge,
      minDistance,
      maxDistance,
      minHeight,
      maxHeight,
      localFilters,
      resetEventListeners,
    };
  },
  computed: {
    maxFilters () : boolean {
      return this.getNumberOfFilters(this.localFilters.data) > 5;
    },
    canApplyFilters() : boolean {
      return !this.maxFilters;
    },
    sexualOrientationOptions() : {value: SexualOrientation, label: string}[] {
      const orientations = [
        {value: SexualOrientation.man, label: this.$t("man")},
        {value: SexualOrientation.woman, label: this.$t("woman")},
        {value: SexualOrientation.non_binary, label: this.$t("non_binary")},
      ];

      const sexualOrientations = (this.$store.state.user?.sexual_orientations ?? []) as SexualOrientation[];

      return orientations.filter(o => {
        return sexualOrientations.includes(o.value);
      });
    }
  },
  watch: {
    "$store.getters.filters": {
      handler() {
        this.localFilters.data = cloneDeep(this.$store.getters.filters);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    options(questionName: string) : CheckBoxOption[] {

      const options = this.$store.getters["question/options"](questionName) as QuestionOption[];

      return options.map(p => {
        return {
          value: p.id,
          label: p.label
        };
      });
    },
    applyFilters() {
      if (!this.canApplyFilters) {
        return;
      }

      const filters = cloneDeep(this.localFilters.data) as any;

      // Remove legacy keys
      // TODO find a better solution, use Filters keys to dynamically remove unwanted keys?
      filters.height = undefined;

      this.$store.commit("setFilters", filters);

      this.hideFilters();
    },
    resetFilters () {

      Object.values(this.resetEventListeners).forEach(listener => {
        listener();
      });

      this.$store.commit("resetFilters");
    },
    filterIsActive(filter: keyof Filters) : boolean {
      const value = this.localFilters.data[filter];

      if (value === undefined) {
        return false;
      }

      if (value === null) {
        return false;
      }

      if (value === false) {
        return false;
      }

      if (isArray(value) && value.length === 0) {
        return false;
      }

      return true;
    },
    onHeightUpdate(heightRange: [number,number]|null) {

      if (heightRange && heightRange.length == 2) {

        this.localFilters.data.question__height = {
          min: heightRange[0],
          max: heightRange[1],
        };
      }
    },
  }
});
</script>
