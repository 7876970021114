<template>
  <div>
    <BaseLoadingCover
      :model-value="deleteLoading"
    />
    <div class="flex items-center">
      <div class="mr-4">
        <Icon
          v-if="brand == 'visa'"
          icon="logos:visa"
          class="h-7 w-10"
        />
        <Icon
          v-else-if="brand == 'mastercard'"
          icon="logos:mastercard"
          class="h-7 w-10"
        />
        <Icon
          v-else-if="brand == 'amex'"
          icon="logos:amex"
          class="h-7 w-10"
        />
      </div>
      <div class="font-mono">
        **** **** **** {{ lastFour }}
      </div>
    </div>
    <div
      v-if="showDelete"
      class="flex justify-end"
    >
      <span
        class="underline cursor-pointer"
        @click="deletePaymentSource"
      >
        {{ $t('delete') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PaymentSource } from "@/models/PaymentSource";

export default defineComponent({
  props: {
    paymentSource: {
      required: true,
      type: Object as PropType<PaymentSource>
    },
    showDelete: {
      default: false,
      type: Boolean
    }
  },
  emits: ["deleted"],
  data() {
    return {
      deleteLoading: false
    };
  },
  computed: {
    lastFour () : string {
      return this.paymentSource.description.slice(this.paymentSource.description.length - 4);
    },
    brand () : string|null {

      const descriptionToLower = this.paymentSource.description.toLowerCase();

      const brands = ["visa", "amex", "mastercard"];

      for (let i = 0; i < brands.length; i++) {
        const brand = brands[i];
        if (descriptionToLower.includes(brand)) {
          return brand;
        }
      }

      return null;
    }
  },
  methods: {
    deletePaymentSource() {
      this.deleteLoading = true;
      this.$api.delete("payment-sources/"+this.paymentSource.id).then(
        (response) => {
          this.deleteLoading = false;
        }
      ).finally(
        () => {
          this.deleteLoading = false;
          this.$emit("deleted");
        }
      );
    }
  }
});
</script>
