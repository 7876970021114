import { createApp } from "vue";
import { createHead } from "@vueuse/head";

import App from "@/App.vue";

// Providers
import "@/providers/polyfills";
import initSmoothScroll from "@/providers/smoothScroll";
import initSuppressLongPressGesture from "@/providers/suppressLongPressGesture";
import initNotificator from "@/providers/notificator";
import initStatusBar from "@/providers/statusBar";
import initPwa from "@/providers/pwa";
import initMitt from "@/providers/mitt";
import initGoogleAnalytics from "@/providers/googleAnalytics";
import initInAppPurchase from "@/providers/inAppPurchase";

// Plugins
import router from "./router";
import { store, key } from "./store";
import i18n from "@/plugins/i18n";
import sentry from "@/plugins/sentry";
import http from "@/plugins/http";
import vapor from "@/plugins/vapor";
import notificator from "@/plugins/notificator";
import auth from "./plugins/auth";
import form from "./plugins/form";
import icons from "./plugins/icons";
import swiper from "./plugins/swiper";
import tippy from "./plugins/tippy";
import audio from "./plugins/audio";
import luxon from "./plugins/luxon";
import display from "./plugins/display";
import urls from "./plugins/urls";
import helpers from "./plugins/helpers";
import echo from "./plugins/echo";

// Misc
import { websiteUrl } from "./services/utils";

// Components
import { Icon } from "@iconify/vue";
import InputSlider from "./plugins/form/components/elements/InputSlider.vue";

// Styles
import "@/assets/css/layout.css";
import "@/assets/css/ios.css";
import "@/assets/css/tippy.css";
import "@/assets/css/tailwind.css";
import "@/assets/css/slider.css";
import "@/assets/css/table.css";

/**
 * Setup Vue App
 */

const app = createApp(App);
const head = createHead();

app.use(i18n);
app.use(store, key);
app.use(router);

/**
 * Providers
 */

initSmoothScroll();
initStatusBar();
initNotificator();
initSuppressLongPressGesture();
initPwa(router);
initGoogleAnalytics(app, router);
initMitt(app);
initInAppPurchase(store);

/**
 * Create Vue App
 */

app.use(head);
app.use(sentry, router);
app.use(http);
app.use(vapor);
app.use(notificator);
app.use(auth);
app.use(form);
app.use(icons);
app.use(swiper);
app.use(tippy);
app.use(audio);
app.use(helpers);
app.use(luxon);
app.use(display);
app.use(urls);
app.use(echo);

app.config.globalProperties.$websiteUrl = websiteUrl;

// eslint-disable-next-line vue/multi-word-component-names
app.component("Icon", Icon);
app.component("InputSlider", InputSlider);

app.mount("#app");
