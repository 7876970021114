<template>
  <InfoModal
    :model-value="modelValue"
    :close-on-confirm="false"
    :title="$t('register_now')"
    :button-text="$t('create_my_account')"
    @update:model-value="$emit('update:modelValue', $event)"
    @confirm="goToOnboarding"
  >
    <p class="mb-3 leading-snug text-center text-gray-700">
      {{ $t('register_modal.line_1') }}
    </p>
  </InfoModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    }
  },
  emits: ["update:modelValue"],
  methods: {
    goToOnboarding () {
      this.$router.push("/onboarding");
    }
  }
});
</script>
