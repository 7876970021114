<template>
<div>
  <BaseContainer size="4xl">
    <h2 class="mb-6 text-xl font-medium leading-tight">
      {{ $t('referrals') }}
    </h2>
  </BaseContainer>

  <BaseContainer paddingless-mobile size="4xl">
    <BaseCard class="relative overflow-hidden">
      <div class="min-h-[60px]">
        <BaseLoadingCover
          v-model="loading"
          icon-class="text-primary-600 w-6 h-6"
        />
        <template v-if="ready">
          <table class="table-lg table w-full">
            <tr
              v-for="referral in referrals"
              :key="referral.id"
            >
              <td class="px-3 py-2 border-b border-gray-200">
                <p class="font-semibold">
                  {{ referral.full_name }}
                </p>
              </td>
              <td
                v-if="!mobile"
                class="px-3 py-2 border-b border-gray-200"
              >
                <p class="text-gray-600">
                  {{ referral.city }}
                </p>
              </td>
              <td class="px-3 py-2 border-b border-gray-200">
                <p class="text-gray-900">
                  {{ referral.amount?.priceFormat() }}
                </p>
              </td>
              <td class="px-3 py-2 border-b border-gray-200">
                <p class="text-gray-600">
                  {{ $luxon.fromISO(referral.claimed_at).toISODate() }}
                </p>
              </td>
            </tr>
          </table>

          <div
            v-if="referrals.length == 0"
            class="p-6 leading-tight"
          >
            {{ $t('pages.referrals.no_referral') }}
          </div>
        </template>
      </div>
    </BaseCard>
  </BaseContainer>
</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import mq from "@/composables/mq";

export default defineComponent({
  setup () {
    const { mobile } = mq();
    return {
      mobile
    };
  },
  data() {
    return {
      ready: false,
      loading: false,
      referrals: [] as any[]
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$api.get("account/ambassador/referrals")
        .then(response => {
          this.ready = true;
          this.referrals = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
