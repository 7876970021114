<template>
  <OnboardingLayout back-url="/onboarding/start">
    <OnboardingTitle margin-bottom="mb-2">
      {{ $t('create_your_account') }}
    </OnboardingTitle>

    <div class="mt-8" />

    <VForm
      url="onboarding/user"
      method="post"
      :data="form"
      loading-mask-class="bg-white"
      @success="onSuccess"
      @error="onError"
    >
      <VInput
        v-model="form.email"
        autofocus
        name="email"
        type="email"
        required
        :label="$t('email')"
        class="mb-2"
        input-class="w-full"
        placeholder="me@example.com"
      />

      <EmailAlreadyExists
        v-if="errorEmailAlreadyExists"
        :email="form.email"
        class="mt-3 mb-6"
      />

      <div class="sm:space-x-3 sm:flex sm:space-y-0 mb-2 space-y-2">
        <VInput
          v-model="form.first_name"
          name="first_name"
          required
          :label="$t('first_name')"
          class="flex-1"
          input-class="w-full"
        />

        <VInput
          v-model="form.last_name"
          name="last_name"
          required
          :label="$t('last_name')"
          class="flex-1"
          input-class="w-full"
        />
      </div>

      <VInputPasswordWithValidation
        v-model="form.password"
        name="password"
        required
        :label="$t('password')"
        input-class="w-full"
        class="mb-4"
        @validity="validPassword = $event"
      />

      <VInputPassword
        v-model="form.password_confirmation"
        name="password_confirmation"
        required
        :label="$t('password_confirmation')"
        class="flex-1"
        input-class="w-full"
      />

      <button
        id="onboarding-button-register"
        type="submit"
        :disabled="!canSubmit"
        class="btn btn-primary btn-lg sm:w-auto flex items-center justify-center w-full mt-6"
      >
        <span class="mr-2">{{ $t('next') }}</span>
        <Icon
          icon="heroicons-outline:arrow-right"
          class="w-5 h-5"
        />
      </button>

      <div class="mt-6">
        <button
          type="button"
          class="inline leading-snug text-left text-gray-700"
          @click="termsOpened = true"
        >
          {{ $t('by_signing_up_you_agree_to') }}
          <span class="text-blue-600 underline lowercase">
            {{ $t('the_terms_and_conditions') }}
          </span>.
        </button>
      </div>

      <BaseModal v-model="termsOpened">
        <div class="p-6">
          <TermsText />

          <div class="flex justify-end mt-4">
            <button
              type="button"
              class="btn flex items-center space-x-2"
              @click="termsOpened = false"
            >
              <Icon icon="heroicons-solid:x" />
              <span>{{ $t('close') }}</span>
            </button>
          </div>
        </div>
      </BaseModal>
    </VForm>
  </OnboardingLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OnboardingTitle from "./components/OnboardingTitle.vue";
import { isString, get } from "lodash-es";
import EmailAlreadyExists from "./components/EmailAlreadyExists.vue";
import OnboardingLayout from "./OnboardingLayout.vue";

export default defineComponent({
  components: {
    OnboardingTitle,
    EmailAlreadyExists,
    OnboardingLayout
  },
  data () {
    return {
      validPassword: false,
      errorEmailAlreadyExists: false,
      termsOpened: false,
      form: {
        email: (this.$route.query.email ?? "") as string,
        first_name: this.$route.query.first_name ?? "",
        last_name: this.$route.query.last_name ?? "",
        password: "",
        password_confirmation: "",
        locale: this.$i18n.locale,
        facebook_id: this.$route.query.facebook_id ?? "",
        facebook_access_token: this.$route.query.facebook_access_token ?? "",
      }
    };
  },
  computed: {
    canSubmit () : boolean {
      return this.validPassword;
    }
  },
  created () {
    if (isString(this.$route.query.email)) {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    onError(error: any) {
      if (get(error.response,"data.error.code") == "EMAIL_ALREADY_EXISTS") {
        this.errorEmailAlreadyExists =true;
      }
    },
    async onSuccess () {

      this.errorEmailAlreadyExists = false;

      await this.$auth.login({
        email: this.form.email,
        password: this.form.password,
      });

      this.$router.push("/onboarding/terms");
    }
  }
});
</script>
