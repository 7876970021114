<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.security.blocks.title')"
        :subtitle="$t('page.settings.menu.security.blocks.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
    >
      <div class="flex flex-wrap -m-2">
        <div
          class="xs:w-1/2 w-full p-2"
        >
          <MemberBlockItem
            v-for="memberBlock in memberBlocks"
            :key="memberBlock.id"
            :member-block="memberBlock"
            @fetch="fetch()"
          />
        </div>
      </div>

      <BaseCard v-if="firstLoad && memberBlocks.length == 0">
        <BaseCardRow>
          <p class="leading-snug text-center text-gray-700">
            {{ $t('page.settings.blocks.empty_state.title') }}
          </p>
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { MemberBlock } from "@/models/MemberBlock";
import { defineComponent } from "vue";
import MemberBlockItem from "./MemberBlockItem.vue";

export default defineComponent({
  components: {
    MemberBlockItem
  },
  data () {
    return {
      loading: false,
      firstLoad: false,
      memberBlocks: [] as MemberBlock[]
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch () {
      this.loading = false;
      this.$api.get("member-blocks")
        .then(response => {
          this.memberBlocks = response.data.data;
          this.firstLoad = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
});
</script>
