<template>
  <div ref="targetEl">
    <slot v-if="shouldRender" />
  </div>
</template>

<script lang="ts">
import { useIntersectionObserver } from "@vueuse/core";
import { defineComponent, nextTick, ref } from "vue";

function onIdle(cb = () => {return;}) {
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}

export default defineComponent({
  props: {
    renderOnIdle: Boolean,
  },
  setup(props: any) {

    const shouldRender = ref(false);

    const targetEl = ref();

    const { stop } = useIntersectionObserver(
      targetEl,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          shouldRender.value = true;
          stop();
        }
      }, {
        rootMargin: "600px"
      }
    );

    if (props.renderOnIdle) {
      onIdle(() => {
        shouldRender.value = true;
        stop();
      });
    }

    return { targetEl, shouldRender };
  }
});
</script>

