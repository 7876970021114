<template>
  <div class="absolute inset-0 z-40 w-full h-full bg-gray-900">
    <div class="flex flex-col w-full h-full">
      <div class="grow shrink relative min-h-0 pt-6">
        <div class="flex items-center justify-center h-full px-5">
          <UserVideo
            v-show="connected"
            :mode="'block'"
            :name="user?.first_name ?? ''"
            :has-audio="userHasAudio"
            :has-video="userHasVideo"
          >
            <video
              ref="userVideo"
              autoplay
              class="w-full h-full"
            />
          </UserVideo>
          <UserVideo
            class="z-[1]"
            :mode="connected ? 'satellite' : 'block'"
            :name="`${me?.first_name} (${$t('me').toLowerCase()})`"
            :has-audio="meHasAudio"
            :has-video="meHasVideo"
          >
            <video
              ref="meVideo"
              muted
              autoplay
              class="w-full h-full"
            />
          </UserVideo>
        </div>

        <CallingBox
          v-if="user && calling && !connected"
          :user="user"
        />
      </div>

      <div class="shrink-0 px-6 py-5">
        <div class="flex items-center justify-center space-x-4">
          <button
            type="button"
            class="p-2 text-white duration-100 rounded-full"
            :class="[!meHasAudio ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-700 hover:bg-gray-800']"
            @click="toggleAudio"
          >
            <Icon
              class="w-7 h-7"
              :icon="meHasAudio ? 'mdi:microphone' : 'mdi:microphone-off'"
            />
          </button>
          <button
            type="button"
            class="p-2 text-white duration-100 rounded-full"
            :class="[!meHasVideo ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-700 hover:bg-gray-800']"
            @click="toggleVideo"
          >
            <Icon
              class="w-7 h-7"
              :icon="meHasVideo ? 'mdi:video' : 'mdi:video-off'"
            />
          </button>
          <button
            type="button"
            class="hover:bg-red-700 px-5 py-2 text-white duration-100 bg-red-600 rounded-full"
            @click="endCall"
          >
            <Icon
              class="w-7 h-7"
              icon="mdi:phone-hangup"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Conversation } from "@/models/Conversation";
import { api } from "@/services/api";
import { echo } from "@/services/echo";
import { store } from "@/store";
import { computed, onBeforeUnmount, onMounted, Ref, ref } from "vue";
import { useRoute } from "vue-router";
import { getPermissions } from "./permissions";
import UserVideo from "./UserVideo.vue";
import { SimplePeer } from "@/services/simplePeer";
import router from "@/router";
import CallingBox from "./CallingBox.vue";
import { notificator } from "@/services/notificator";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import i18n from "@/plugins/i18n";

const TURN_URL = "turn:coturn.effettandem.com:443";
const TURN_USERNAME = "tandem";
const TURN_PASSWORD = "Gklsd5ffT7zx67";

let channelUsers = [] as any[];

const PEER_CONFIG = {
  iceServers: [
    {
      urls: TURN_URL,
      username: TURN_USERNAME,
      credential: TURN_PASSWORD,
    },
  ],
};

const CALL_RETRY_INTERVAL = 2500;

let stream = null as null|MediaStream;

const route = useRoute();

let peer = null as SimplePeer|null;
let callRetryIntervalId = null as any;
let globalCallIntervalId = null as any;
let unmounting = false;

const connected = ref(false);
const calling = ref(true);

// State

const startWithVideo = (route.query.no_video == "1") ? false : true;

const meHasVideo = ref(startWithVideo);
const meHasAudio = ref(true);

// HTML Video Elements
const meVideo = ref(null) as Ref<null|HTMLVideoElement>;
const userVideo = ref(null) as Ref<null|HTMLVideoElement>;

// Users
const me = computed(() => {
  return conversation.value?.users.find(u => u.is_me) ?? store.state.user;
});

const user = computed(() => {
  return conversation.value?.users.find(u => !u.is_me) ?? null;
});

// Conversation fetch

const conversationId = route.params.conversation;
const conversation = ref(null) as Ref<null|Conversation>;

async function fetchConversation() {
  const response = await api.get(`/conversations/${conversationId}`);
  conversation.value = response.data.data as Conversation;
}

// Pusher Presence Channel Events

const channelName = "conversation." + conversationId + ".video-chat";
const channel = echo.join(channelName)
  .here((users: any[]) => {
    channelUsers = users;
  })
  .joining((user: any) => {
    if (channelUsers.find(u => u.id == user.id) == undefined) {
      channelUsers.push(user);
    }
  })
  .leaving((user: any) => {
    channelUsers = channelUsers.filter(u => u.id != user.id);
  });

// User video state

const userHasAudio = ref(true);
const userHasVideo = ref(true);

channel.listenForWhisper("toggle-audio", (e: any) => {
  if (e.user_id == user.value?.id) {
    userHasAudio.value = e.value = e.value == true;
  }
});

channel.listenForWhisper("toggle-video", (e: any) => {
  if (e.user_id == user.value?.id) {
    userHasVideo.value = e.value = e.value == true;
  }
});

channel.listen(".decline", onEventVideoChatDecline);

// Before Unmount - Remove listeners and intervals

onBeforeUnmount(() => {

  unmounting = true;

  clearInterval(callRetryIntervalId);
  clearInterval(globalCallIntervalId);

  channel.stopListening(".accept", onEventVideoChatAccept);
  channel.stopListening(".decline", onEventVideoChatDecline);
  channel.stopListening(".call", onEventVideoChatCall);
  channel.stopListening("toggle-audio");
  channel.stopListening("toggle-video");
  echo.leave(channelName);

  peer?.destroy();

  // Stop video / audio recording
  stream?.getTracks().forEach(function(track) {
    track.stop();
  });
});

// Setup stream and attempt peer handshake

onMounted(async () => {

  await fetchConversation();

  stream = await getPermissions();

  if (meVideo.value) {
    meVideo.value.srcObject = stream;
    meVideo.value.playsInline = true;
  }

  const isCaller = store.state.user?.id == conversation.value?.user_id;

  if (isCaller) {
    startCalling();
  } else {
    channel.listen(".call", onEventVideoChatCall);
  }

  sendAudioState();
  sendVideoState();

  // Send notification if not connected in 1 second

  globalCallIntervalId = setInterval(() => {

    if (channelUsers.length >= 2) {
      calling.value = false;
      clearInterval(globalCallIntervalId);
      return;
    }

    if (connected.value) {
      calling.value = false;
      clearInterval(globalCallIntervalId);
      return;
    }

    // Send call notification
    api.post(`conversations/${conversationId}/video-chat/notify`)
      .then(() => {
        console.log("call notification");
      })
      .catch((error) => {
        console.error(error);
        goBackToConversationPage();
      });

  }, 2000);

});

async function startCalling () {

  call();

  // Make sure interval is clear
  clearInterval(callRetryIntervalId);

  // Retry calling each CALL_RETRY_INTERVAL milliseconds
  callRetryIntervalId = setInterval(() => {

    if (connected.value) {
      return;
    }

    call();

  }, CALL_RETRY_INTERVAL);

  // Wait for answer...
  channel.listen(".accept", onEventVideoChatAccept);
}

async function call () {

  // Skip if currently unmounting
  if (unmounting) {
    return;
  }

  // Each time we call, we create a new peer

  peer = setPeer(true);

  // send user call signal
  peer.on("signal", (data: any) => {

    api
      .post(`conversations/${conversationId}/video-chat/call`, {
        signal: data,
      })
      .then(() => {
        console.log("call requested");
      })
      .catch((error) => {
        console.error(error);
        goBackToConversationPage();
      });
  });
}

function setPeer(caller: boolean) : SimplePeer {

  peer = new window.SimplePeer({
    initiator: caller,
    trickle: false,
    stream: stream as MediaStream,
    config: PEER_CONFIG,
  }) as SimplePeer;

  peer.on("stream", (stream: MediaStream) => {
    if (userVideo.value) {
      userVideo.value.srcObject = stream;
      userVideo.value.playsInline = true;
    }
  });

  peer.on("connect", () => {
    connected.value = true;
    console.log("peer connected");
  });

  peer.on("error", (err: Error) => {
    console.log(err);
  });

  peer.on("close", () => {
    connected.value = false;
    console.log("peer close");
  });

  return peer;
}

function onEventVideoChatAccept(data : any) {

  const signal = {
    ...data.signal,
    sdp: `${data.signal.sdp}\n`,
  };

  peer?.signal(signal);
}

function onEventVideoChatDecline() {

  notificator.notify({
    message: i18n.global.t("x_did_not_answer_the_call", {x: user.value?.first_name}),
    type: Type.info,
  });

  goBackToConversationPage();
}

function onEventVideoChatCall(data: any) {

  const callerSignal = {
    ...data.signal,
    sdp: `${data.signal.sdp}\n`,
  };

  // Each time a new call is received, delete old peer an create a new one with a new signal

  peer = setPeer(false);

  peer.on("signal", (data: any) => {
    api
      .post(`conversations/${conversationId}/video-chat/accept`, {
        signal: data,
      })
      .then(() => {
        console.log("call accepted");
      })
      .catch((error) => {
        console.log(error);
        router.push(`/member/messages/${conversationId}`);
      });
  });

  peer.signal(callerSignal);
}

function toggleVideo() {
  if (meHasVideo.value) {
    if (meVideo.value?.srcObject) {
      (meVideo.value.srcObject as any).getVideoTracks()[0].enabled = false;
    }
    meHasVideo.value = false;
  } else {
    if (meVideo.value?.srcObject) {
      (meVideo.value.srcObject as any).getVideoTracks()[0].enabled = true;
    }
    meHasVideo.value = true;
  }

  sendVideoState();
}

function toggleAudio() {
  if (meHasAudio.value) {
    if (meVideo.value?.srcObject) {
      (meVideo.value.srcObject as any).getAudioTracks()[0].enabled = false;
    }
    meHasAudio.value = false;
  } else {
    if (meVideo.value?.srcObject) {
      (meVideo.value.srcObject as any).getAudioTracks()[0].enabled = true;
    }
    meHasAudio.value = true;
  }

  sendAudioState();
}

function sendVideoState() {
  (channel as any).whisper("toggle-video", {
    user_id: store.state.user?.id,
    value: meHasVideo.value
  });
}

function sendAudioState() {
  (channel as any).whisper("toggle-audio", {
    user_id: store.state.user?.id,
    value: meHasAudio.value
  });
}

function stopStreamedVideo(videoElem : HTMLVideoElement|null) {

  if (!videoElem) {
    return;
  }

  const stream = videoElem.srcObject;

  if (!stream) {
    return;
  }

  const tracks = (stream as any).getTracks();
  tracks.forEach((track: any) => {
    track.stop();
  });

  videoElem.srcObject = null;
}

function endCall() {
  // if video or audio is muted, enable it so that the stopStreamedVideo method will work
  if (meHasVideo.value) toggleVideo();
  if (meHasAudio.value) toggleAudio();

  stopStreamedVideo(meVideo.value);

  peer?.destroy();

  goBackToConversationPage();
}

function goBackToConversationPage() {
  router.push({
    name: "member.messages.conversation",
    params: {
      conversation: conversationId,
    }
  });
}

</script>
