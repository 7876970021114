<template>
  <div class="bg-gray-50 pt-safe relative min-h-screen pb-20">
    <BaseContainer size="3xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.preferences.resources.title')"
        :subtitle="$t('page.settings.menu.preferences.resources.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="3xl"
      paddingless-mobile
    >
      <BaseCard class="mb-6">
        <BaseCardRow>
          <div class="flex justify-between text-sm">
            <p class="font-semibold leading-tight text-gray-900">
              Version
            </p>
            <p class="leading-tight text-gray-500">
              {{ version }}
            </p>
          </div>
        </BaseCardRow>
        <BaseCardRow>
          <div class="sm:flex justify-between text-sm">
            <p class="sm:mb-0 mb-2 font-semibold leading-tight text-gray-900">
              {{ $t('contact_us') }}
            </p>
            <a
              :href="$websiteUrl($t('urls.contact'))"
              class="leading-tight text-blue-600 underline"
            >
              {{ $websiteUrl($t('urls.contact')) }}
            </a>
          </div>
        </BaseCardRow>
        <BaseCardRow>
          <div class="sm:flex justify-between text-sm">
            <p class="sm:mb-0 mb-2 font-semibold leading-tight text-gray-900">
              {{ $t('terms_and_conditions') }}
            </p>
            <a
              :href="$websiteUrl($t('urls.terms'))"
              class="leading-tight text-blue-600 underline"
            >
              {{ $websiteUrl($t('urls.terms')) }}
            </a>
          </div>
        </BaseCardRow>
        <BaseCardRow>
          <div class="sm:flex justify-between text-sm">
            <p class="sm:mb-0 mb-2 font-semibold leading-tight text-gray-900">
              {{ $t('privacy_policy') }}
            </p>
            <a
              :href="$websiteUrl($t('urls.privacy'))"
              class="leading-tight text-blue-600 underline"
            >
              {{ $websiteUrl($t('urls.privacy')) }}
            </a>
          </div>
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent} from "vue";
export default defineComponent({
  data () {
    return {
      version: import.meta.env.PACKAGE_VERSION,
    };
  },
});
</script>
