<template>
  <BaseContainer size="3xl">
    <div
      :class="disabled ? 'opacity-50 cursor-not-allowed' : ''"
      class="pb-8 mb-8 border-b border-gray-200"
    >
      <div class="flex mb-6 space-x-4">
        <div class="bg-primary-600 top-1 shrink-0 relative flex items-center justify-center w-8 h-8 text-white rounded-full">
          {{ number }}
        </div>

        <div class="max-w-md">
          <h2 class="mb-0.5 font-semibold leading-tight">
            {{ title }}
          </h2>
          <p class="text-base leading-tight text-gray-600">
            {{ subtitle }}
          </p>
        </div>
      </div>

      <slot />
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    number: {
      required: true,
      type: Number
    },
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: true,
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    }
  }
});
</script>
