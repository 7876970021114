<template>
  <div class="relative">
    <OnboardingTitle>
      {{ $t('what_is_your_gender') }}
      <span class="text-red-500">*</span>
    </OnboardingTitle>

    <VForm
      method="patch"
      url="/onboarding/user/gender"
      :data="form"
      loading-mask-class="bg-white"
      :success-handler="() => {}"
      @success="onSuccess"
    >
      <template #default="{errors}">
        <div class="flex flex-wrap -m-1.5">
          <div class="w-full p-1.5">
            <button
              type="button"
              class="btn btn-xl w-full shadow"
              :class="{'btn-steel-outline': form.gender == 'male'}"
              @click="form.gender = 'male'"
            >
              {{ $t('man') }}
            </button>
          </div>
          <div class="w-full p-1.5">
            <button
              type="button"
              class="btn btn-xl w-full shadow"
              :class="{'btn-steel-outline': form.gender == 'female'}"
              @click="form.gender = 'female'"
            >
              {{ $t('woman') }}
            </button>
          </div>
          <div class="w-full p-1.5">
            <button
              type="button"
              class="btn btn-xl w-full shadow"
              :class="{'btn-steel-outline': form.gender == 'non_binary'}"
              @click="form.gender = 'non_binary'"
            >
              {{ $t('non_binary') }}
            </button>
          </div>
        </div>

        <BaseAlert
          v-if="errors.gender && errors.gender.length"
          type="danger"
          class="mt-5"
        >
          {{ errors.gender[0] }}
        </BaseAlert>

        <div class="mt-8">
          <StepButtons
            :previous-button="false"
            :next-disabled="form.gender == null"
            @previous="previousStep"
          />
        </div>
      </template>
    </VForm>
  </div>
</template>

<script lang="ts">
import { Account } from "@/models/Account";
import { Gender } from "@/models/User";
import Cookies from "js-cookie";
import { cloneDeep } from "lodash";
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: { OnboardingTitle, StepButtons },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  },
  data () {
    return {
      form: {
        gender: null as string|null
      },
    };
  },
  created () {

    const gender = Cookies.get("onboarding.gender");

    if (gender && Object.values(Gender).includes(gender as Gender)) {
      this.form.gender = gender as Gender;
    }

    if (this.$store.state.user?.gender) {
      this.form.gender = this.$store.state.user.gender;
    }
  },
  methods: {
    onSuccess () {
      const user = cloneDeep(this.$store.state.user) as Account;
      user.gender = this.form.gender as Gender;
      this.$store.commit("setUser", user);

      this.nextStep();
    }
  }
});
</script>
