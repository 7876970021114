<template>
  <div class="relative">
    <FilePicker
      :button-class="buttonClass"
      :disabled="uploading || disabled"
      @upload="onPictureUpload"
    >
      <template #default="props">
        <slot
          name="default"
          v-bind="{uploading: uploading, selecting: props.selecting, dragging: props.dragging}"
        />
        <slot
          name="loading"
          v-bind="{uploading: uploading, selecting: props.selecting, dragging: props.dragging}"
        >
          <BaseLoadingCover
            icon-class="text-primary-600 w-6 h-6"
            :model-value="loading || uploading || props.selecting"
          />
        </slot>
      </template>
    </FilePicker>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { notificator } from "@/services/notificator";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

export default defineComponent({
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    },
    beforeUpload: {
      default: () : boolean => {
        return true;
      },
      type: Function as PropType<() => boolean>
    },
    buttonClass: {
      default: "",
      type: String
    },
    maxSize: {
      default: 1024 * 1024 * 20, // 20 MB,
      type: Number
    },
  },
  emits: ["success", "fail"],
  data () {
    return {
      uploading: false,
    };
  },
  methods: {
    async onPictureUpload(file: File) {

      if (!(await this.beforeUpload())) {
        return;
      }

      this.uploading = true;

      try {

        if (file.size > this.maxSize) {
          notificator.notify({
            type: Type.danger,
            message: this.$t("the_file_size_must_not_exceed_x", {x: this.$display.fileSize(this.maxSize)})
          });
          return;
        }

        const formData = new FormData;

        formData.append("file", file);

        const response = await this.$vapor.store(file, {}) as any;

        response.original_file = file;

        this.$emit("success", response);
      }
      catch (e: unknown) {
        this.$emit("fail");
      } finally {
        this.uploading = false;
      }
    }
  }
});
</script>
