<template>
  <slot
    :active="favorite"
    :toggle-like="toggleLike"
    :loading="loading"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { defineComponent, PropType } from "vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Method } from "axios";
import { get } from "lodash";
import { SUSPENDED, UNSUBSCRIBED } from "@/services/errorCodes";

export default defineComponent({
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      favorite: this.member.favorite,
    };
  },
  methods: {
    toggleLike() {
      this.loading = true;
      let method = "post";
      if (this.favorite) {
        method = "delete";
      }
      this.favorite = !this.favorite;
      Haptics.impact({ style: ImpactStyle.Light });
      this.$api
        .request({
          method: method as Method,
          url: `/members/${this.member.id}/favorite`
        })
        .catch(error => {
          this.favorite = !this.favorite;
          const code = get(error, "response.data.code");
          if ([UNSUBSCRIBED, SUSPENDED].includes(code)) {
            this.$store.dispatch("fetchUser");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
});
</script>

