<template>
  <div
    v-if="member"
    class="bg-gray-50 pt-safe relative pb-20"
  >
    <BaseContainer size="4xl">
      <BaseTitle
        :previous="false"
        :title="$t('subscription')"
        :subtitle="$t('page.subscription.subtitle')"
      />
    </BaseContainer>

    <BaseLoadingCover :model-value="loading" />

    <template v-if="canManageSubscriptionInPlatform">
      <BaseContainer
        size="4xl"
        paddingless-mobile
      >
        <div
          v-if="member.subscription_status == 'active'"
        >
          <BaseCard class="relative overflow-hidden">
            <BaseCardRow class="md:px-10 relative p-6">
              <img
                src="@/assets/img/tree/tree-black.svg"
                alt="Tree Tandem"
                class="opacity-[0.04] pointer-events-none right-0 top-10 absolute h-[150%]"
              >

              <div class="max-w-lg mx-auto">
                <div class=" flex flex-col items-center justify-center mb-6">
                  <div class="p-2 mb-2 bg-green-100 rounded-full">
                    <Icon
                      icon="mdi:check-circle"
                      class="w-7 h-7 text-green-600"
                    />
                  </div>
                  <h2 class="font-display text-2xl font-semibold">
                    {{ $t('you_are_subscribed') }}
                  </h2>
                </div>

                <div v-if="user.active_subscription && user.active_subscription.product">
                  <div class="flex flex-col items-center justify-center mb-4">
                    <h3 class="text-xl font-semibold text-center">
                      {{ $t('automatic_renewal') }}: {{ user.active_subscription.product.name }}
                    </h3>
                  </div>
                  <div class="text-center">
                    <p class="leading-tight text-gray-700">
                      <span>
                        {{ $tc('page.subscription.subscription_end_notice.message', user.active_subscription.product.number_of_months, {price: user.active_subscription.product.price.priceFormat()}) }}
                      </span>

                      <span>&nbsp;</span>
                      <span
                        v-if="member.subscription_end_date"
                      >
                        {{ $t('page.subscription.subscription_end_notice.message_2', {end: $luxon.fromISO(member.subscription_end_date).setLocale($i18n.locale).toFormat('DD')}) }}
                      </span>
                    </p>

                    <div class="mt-5">
                      <button
                        type="button"
                        class="btn btn-secondary btn-lg"
                        @click="openCancelModal()"
                      >
                        {{ $t('cancel_auto_renew') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="text-center"
                >
                  <p class="mb-0.5 text-base font-medium leading-tight text-gray-700">
                    {{ $t('your_subscription_ends_on_x', {x: $luxon.fromISO(member.subscription_end_date ?? '').setLocale($i18n.locale).toFormat('DD')}) }}
                  </p>
                  <p class="mb-4 text-lg font-semibold leading-tight text-gray-900">
                    {{ $tc('x_days_remaining', daysRemaining, {count: daysRemaining}) }}
                  </p>
                  <p class="leading-tight text-gray-500">
                    {{ $t('you_may_add_additional_months_to_your_subscription_below') }}
                  </p>
                </div>
              </div>
            </BaseCardRow>
          </BaseCard>
        </div>

        <div
          v-else-if="member.subscription_status == 'trial'"
        >
          <BaseCard>
            <BaseCardRow>
              <div class="max-w-lg mx-auto">
                <div class=" flex flex-col items-center justify-center mb-6">
                  <div class="p-2 mb-2 bg-blue-100 rounded-full">
                    <Icon
                      icon="mdi:information-outline"
                      class="w-7 h-7 text-blue-600"
                    />
                  </div>
                  <h2 class="font-display text-2xl font-semibold">
                    {{ $t('you_are_in_a_trial_period') }}
                  </h2>
                </div>
                <div class="text-center">
                  <p class="mb-0.5 text-base font-medium leading-tight text-gray-700">
                    {{ $t('your_trial_ends_on_x', {x: $luxon.fromISO(member.trial_end_date ?? '').plus({days: 1}).setLocale($i18n.locale).toFormat('DD')}) }}
                  </p>
                  <p class="mb-4 text-lg font-semibold leading-tight text-gray-900">
                    {{ $tc('x_days_remaining', trialDaysRemaining, {count: trialDaysRemaining }) }}
                  </p>
                  <p class="leading-tight text-gray-500">
                    {{ $t('you_may_add_additional_months_to_your_subscription_below') }}
                  </p>
                </div>
              </div>
            </BaseCardRow>
          </BaseCard>
        </div>

        <div
          v-else-if="member.subscription_status == 'inactive'"
        >
          <BaseCard>
            <BaseCardRow>
              <div class="max-w-lg mx-auto">
                <div class=" flex flex-col items-center justify-center mb-4">
                  <div class="p-2 mb-2 bg-red-100 rounded-full">
                    <Icon
                      icon="mdi:close-circle-outline"
                      class="w-7 h-7 text-red-600"
                    />
                  </div>
                  <h2 class="font-display text-2xl font-semibold">
                    {{ $t('you_are_unsubscribed') }}
                  </h2>
                </div>
                <div class="text-center">
                  <p class="mb-0.5 leading-tight text-gray-600">
                    {{ $t('you_are_unsubscribed_description') }}
                  </p>
                </div>
              </div>
            </BaseCardRow>
          </BaseCard>
        </div>
      </BaseContainer>

      <BaseContainer size="4xl">
        <div class="mt-8" />
        <h2
          v-if="member.subscription_status == 'active'"
          class="mb-6 text-lg leading-tight text-gray-600"
        >
          {{ $t('add_additional_months_to_your_subscription') }}
        </h2>

        <div class="mb-8">
          <ProductList @success="onPaymentSuccess" />
        </div>
      </BaseContainer>

      <PastPayments ref="pastPayments" />

      <SuccessModal
        v-if="$store.state.user?.member"
        v-model="showSuccessModel"
        :member="$store.state.user?.member"
        @confirm="$router.push('/member/search')"
      />

      <CancelModal
        v-if="$store.state.user?.member"
        v-model="showCancelModal"
        :member="$store.state.user?.member"
      />
    </template>
    <template v-else>
      <BaseContainer>
        <BaseCard>
          <BaseCardRow>
            <p class="leading-tight text-gray-600">
              {{ $t('go_to_effettandemcom_to_manage_your_subscription') }}
            </p>
          </BaseCardRow>
        </BaseCard>
      </BaseContainer>
    </template>

    <BaseContainer size="4xl">
      <BaseCard>
        <BaseCardRow>
          <PauseAccount />
        </BaseCardRow>

        <BaseCardRow>
          <DeleteAccount />
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { Account, AccountMember } from "@/models/Account";
import { Order } from "@/models/Order";
import { Capacitor } from "@capacitor/core";
import { defineComponent } from "vue";
import ProductList from "./components/ProductList.vue";
import PastPayments from "./components/PastPayments.vue";
import SuccessModal from "./components/SuccessModal.vue";
import CancelModal from "./components/CancelModal.vue";
import { PaymentSource } from "@/models/PaymentSource";

export default defineComponent({
  components: {
    ProductList,
    PastPayments,
    SuccessModal,
    CancelModal
  },
  setup () {
    const native = Capacitor.isNativePlatform();

    return {
      native
    };
  },
  data () {
    return {
      loading: true,
      paymentSources: [] as PaymentSource[],
      orders: [] as Order[],
      showSuccessModel: false,
      showCancelModal: false,
    };
  },
  computed: {
    user () : Account {
      return this.$store.state.user as Account;
    },
    member () : AccountMember {
      return this.$store.state.user?.member as AccountMember;
    },
    canManageSubscriptionInPlatform () : boolean {
      if (this.loading) {
        return false;
      }
      if (Capacitor.isNativePlatform() && this.paymentSources.length > 0) {
        return false;
      }

      return true;
    },
    daysRemaining () : number {
      return Math.max(0, Math.round(this.$luxon.fromISO(this.member.subscription_end_date ?? "").plus({days: 1}).diffNow("days").days));
    },
    trialDaysRemaining () : number {
      return Math.max(0, Math.round(this.$luxon.fromISO(this.member.trial_end_date ?? "").plus({days: 1}).diffNow("days").days));
    }
  },
  created () {
    this.fetchPaymentSources();
  },
  methods: {
    async onPaymentSuccess() {
      this.showSuccessModel = true;
      this.fetchPastPayments();
    },
    fetchPastPayments() {
      if (!this.$refs.pastPayments) {
        return;
      }

      (this.$refs.pastPayments as any).fetch();
    },
    openCancelModal() {
      this.showCancelModal = true;
    },
    fetchPaymentSources() {
      this.loading = true;

      this.$api.get("payment-sources")
        .then(response => {
          this.paymentSources = response.data.data as PaymentSource[];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
