<template>
  <div>
    <VForm
      :data="form"
      url="payment-sources"
      method="post"
      loading-mask-class="bg-white opacity-50"
      @success="onSuccess"
    >
      <div class="mb-3">
        <VInputSelect
          v-model="form.card_type"
          name="card_type"
          :label="$t('card_type')"
          required
          input-class="w-full"
        >
          <option value="VISA">
            Visa
          </option>
          <option value="MASTERCARD">
            Mastercard
          </option>
          <option value="AMEX">
            American Express
          </option>
        </VInputSelect>
      </div>

      <div class="mb-3">
        <VInput
          v-model="form.card_number"
          name="card_number"
          :label="$t('card_number')"
          required
          input-class="w-full"
        />
      </div>

      <div class="mb-3">
        <VInput
          v-model="form.card_cvv"
          name="card_cvv"
          :label="$t('cvv')"
          required
          class="relative"
          input-class="w-full"
          input-wrapper-class="store-payment-source-input-cvv"
        />
      </div>

      <div class="mb-3">
        <InputLabel
          :label="$t('expiry')"
          required
        />

        <div class="flex space-x-2">
          <VInputSelect
            v-model="form.card_expiry_month"
            name="card_expiry_month"
            required
            :placeholder="$t('month').capitalize()"
            input-class="w-full"
            class="grow"
          >
            <option
              v-for="month in monthList"
              :key="month"
              :value="month"
            >
              {{ month }}
            </option>>
          </VInputSelect>

          <VInputSelect
            v-model="form.card_expiry_year"
            name="card_expiry_year"
            required
            :placeholder="$t('year')"
            input-class="w-full"
            class="grow"
          >
            <option
              v-for="year in yearList"
              :key="year"
              :value="year"
            >
              {{ year }}
            </option>>
          </VInputSelect>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex items-center justify-center w-full space-x-3">
          <img
            src="@/assets/img/payments/visa.svg"
            alt="Visa"
            class="block w-10"
          > <img
            src="@/assets/img/payments/mastercard.svg"
            alt="MasterCard"
            class="block w-10"
          > <img
            src="@/assets/img/payments/american-express.svg"
            alt="American Express"
            class="block w-10"
          >
        </div>
      </div>

      <BaseButton
        :id="buttonId"
        :loading="loading"
        :disabled="disabled"
        type="submit"
        data-cy="add-payment-source"
        class="btn btn-primary btn-lg w-full mt-5"
      >
        {{ buttonText }}
      </BaseButton>
    </VForm>
  </div>
</template>

<script lang="ts">
import { padStart } from "lodash-es";
import { defineComponent } from "vue";
import InputLabel from "@/plugins/form/components/utils/InputLabel.vue";
import i18n from "@/plugins/i18n";
import { cloneDeep } from "lodash";

const FORM = {
  card_type: "",
  card_number: "",
  card_expiry_month: "",
  card_expiry_year: "",
  card_cvv: ""
};

export default defineComponent({
  components: { InputLabel },
  props: {
    buttonId: {
      default: "",
      type: String,
    },
    buttonText: {
      default: i18n.global.t("save"),
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  },
  emits: ["success"],
  data() {
    return {
      loading: false,
      form: cloneDeep(FORM)
    };
  },
  computed: {
    monthList(): string[] {
      let max = 12;
      let list = [];
      for (let i = 1; i <= max; i++) {
        list.push(padStart(i + "", 2, "0"));
      }
      return list;
    },
    yearList(): string[] {
      let max = this.$luxon.now().year + 10;
      let list = [];
      for (let i = this.$luxon.now().year; i <= max; i++) {
        list.push((i + "").slice(-2));
      }
      return list;
    }
  },
  methods: {
    onSuccess(payload: any) {
      this.$emit("success", payload);
      this.form = cloneDeep(FORM);
    }
  }
});
</script>

<style lang="scss">
.store-payment-source-input-cvv {
  position: relative;
}
.store-payment-source-input-cvv input {
  padding-right: 3rem;
}
.store-payment-source-input-cvv::after {
  position: absolute;
  content: "";
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC4xNzA0IDEuMDYyNDlIMi4xMDE0N0MxLjUyMTA3IDEuMDYyNDkgMS4wNTA1NyAxLjUzODE5IDEuMDUwNTcgMi4xMjQ5OVYxNC44NzQ5QzEuMDUwNTcgMTUuNDYxNyAxLjUyMTA3IDE1LjkzNzQgMi4xMDE0NyAxNS45Mzc0SDI0LjE3MDRDMjQuNzUwOCAxNS45Mzc0IDI1LjIyMTMgMTUuNDYxNyAyNS4yMjEzIDE0Ljg3NDlWMi4xMjQ5OUMyNS4yMjEzIDEuNTM4MTkgMjQuNzUwOCAxLjA2MjQ5IDI0LjE3MDQgMS4wNjI0OVpNMi4xMDE0NyAwQzAuOTQwNjc1IDAgLTAuMDAwMzM1NjkzIDAuOTUxMzg5IC0wLjAwMDMzNTY5MyAyLjEyNDk5VjE0Ljg3NDlDLTAuMDAwMzM1NjkzIDE2LjA0ODUgMC45NDA2NzQgMTYuOTk5OSAyLjEwMTQ3IDE2Ljk5OTlIMjQuMTcwNEMyNS4zMzEyIDE2Ljk5OTkgMjYuMjcyMiAxNi4wNDg1IDI2LjI3MjIgMTQuODc0OVYyLjEyNDk5QzI2LjI3MjIgMC45NTEzODkgMjUuMzMxMiAwIDI0LjE3MDQgMEgyLjEwMTQ3WiIgZmlsbD0iI0M5QzlENCIvPgo8cGF0aCBkPSJNLTAuMDAwMzM1NjkzIDMuMDkwODJIMjYuMjcyMlY2LjE4MTcxSC0wLjAwMDMzNTY5M1YzLjA5MDgyWiIgZmlsbD0iI0M5QzlENCIvPgo8cGF0aCBkPSJNMTguNTQ1MSA5LjI3MjcxSDIzLjE4MTRWMTIuMzYzNkgxOC41NDUxVjkuMjcyNzFaIiBmaWxsPSIjQzlDOUQ0Ii8+Cjwvc3ZnPgo=) center center / 2rem no-repeat;
  right: 0.75rem;
  height: 2rem;
  top: 0.275rem;
  width: 2rem;
  pointer-events: none;
}
</style>
