<template>
  <div class="pb-5">
    <BaseContainer class="pt-safe">
      <BaseTitle
        :previous="false"
        :title="$t('page.personal_development.title')"
        :subtitle="$t('page.personal_development.subtitle')"
      />

      <div class="sm:flex -m-2">
        <div class="sm:flex flex-1 p-2">
          <router-link
            to="/member/personal-development/video"
            class="hover:shadow-2xl block overflow-hidden duration-700 rounded-lg shadow"
          >
            <div class="relative h-40">
              <img
                src="/img/articles/video.jpg"
                alt=""
                class="absolute inset-0 object-cover object-center w-full h-full"
              >
              <div class="bg-opacity-60 absolute inset-0 flex items-center justify-center w-full h-full bg-gray-900">
                <Icon
                  icon="heroicons-outline:play"
                  class="w-10 h-10 text-white"
                />
              </div>
            </div>
            <div class="p-5 bg-white">
              <h2 class="mb-1 text-lg font-semibold leading-tight">
                {{ $t('pages.personalDevelopment.video.title') }}
              </h2>
              <p class="leading-tight text-gray-600">
                {{ $t('pages.personalDevelopment.video.subtitle') }}
              </p>
            </div>
          </router-link>
        </div>
        <div class="sm:flex flex-1 p-2">
          <router-link
            to="/member/personal-development/article"
            class="hover:shadow-2xl block overflow-hidden duration-150 rounded-lg shadow"
          >
            <div class="relative h-40">
              <img
                src="/img/articles/article.jpg"
                alt=""
                class="absolute inset-0 object-cover object-center w-full h-full"
              >
              <div class="bg-opacity-60 absolute inset-0 flex items-center justify-center w-full h-full bg-gray-900">
                <Icon
                  icon="heroicons-outline:document-text"
                  class="w-10 h-10 text-white"
                />
              </div>
            </div>
            <div class="p-5 bg-white">
              <h2 class="mb-1 text-lg font-semibold leading-tight">
                {{ $t('pages.personalDevelopment.article.title') }}
              </h2>
              <p class="leading-tight text-gray-600">
                {{ $t('pages.personalDevelopment.article.subtitle') }}
              </p>
            </div>
          </router-link>
        </div>
        <div class="sm:flex flex-1 p-2">
          <router-link
            to="/member/personal-development/bonus"
            class="hover:shadow-2xl block overflow-hidden duration-150 rounded-lg shadow"
          >
            <div class="relative h-40">
              <img
                src="/img/articles/bonus.jpg"
                alt=""
                class="absolute inset-0 object-cover object-center w-full h-full"
              >
              <div class="bg-opacity-60 absolute inset-0 flex items-center justify-center w-full h-full bg-gray-900">
                <Icon
                  icon="heroicons-outline:star"
                  class="w-10 h-10 text-white"
                />
              </div>
            </div>
            <div class="p-5 bg-white">
              <h2 class="mb-1 text-lg font-semibold leading-tight">
                {{ $t('pages.personalDevelopment.bonus.title') }}
              </h2>
              <p class="leading-tight text-gray-600">
                {{ $t('pages.personalDevelopment.bonus.subtitle') }}
              </p>
            </div>
          </router-link>
        </div>
        <div class="sm:flex flex-1 p-2">
          <a
            :href="specialistsRoute"
            target="_blank"
            class="hover:shadow-2xl block overflow-hidden duration-150 rounded-lg shadow"
          >
            <div class="relative h-40">
              <img
                src="/img/articles/specialists.jpg"
                alt=""
                class="absolute inset-0 object-cover object-center w-full h-full"
              >
              <div class="bg-opacity-60 absolute inset-0 flex items-center justify-center w-full h-full bg-gray-900">
                <Icon
                  icon="heroicons-outline:academic-cap"
                  class="w-10 h-10 text-white"
                />
              </div>
            </div>
            <div class="p-5 bg-white">
              <h2 class="mb-1 text-lg font-semibold leading-tight">
                {{ $t('pages.personalDevelopment.specialists.title') }}
              </h2>
              <p class="leading-tight text-gray-600">
                {{ $t('pages.personalDevelopment.specialists.subtitle') }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </BaseContainer>
    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { getRoute } from "@/services/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageArticles",
  computed: {
    specialistsRoute() : string {
      return getRoute("/specialists");
    }
  }
});
</script>
