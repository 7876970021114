<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.daily_quote.title')"
        :subtitle="$t('page.settings.daily_quote.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      v-if="!success"
      size="2xl"
      paddingless-mobile
    >
      <BaseAlert
        v-if="$store.state.user?.member?.visibility == 'private'"
        type="danger"
        class="mb-6 border border-red-300"
      >
        <p>{{ $t('page.settings.daily_quote.profil_not_public_message') }}</p>
        <router-link
          to="/member/settings/visibility"
          class="inline-block mt-2 underline"
        >
          {{ $t('change_the_visibility_of_my_profile') }}
        </router-link>
      </BaseAlert>

      <BaseCard>
        <BaseCardRow>
          <VForm
            url="member/daily-quotes"
            method="post"
            :data="form"
            loading-mask-class="bg-white"
            @success="onSuccess"
          >
            <VInputTextarea
              v-model="form.text"
              required
              name="text"
              :label="$t('your_thought_of_the_day')"
              input-class="w-full"
              :max="200"
              class="mb-4"
            />

            <div
              class="bloc xs:inline-flex relative z-0 mb-4 overflow-hidden border border-gray-300 rounded-md"
            >
              <button
                v-for="type in authorTypes"
                :key="type.value"
                type="button"
                class="xs:last:border-r-0 last:border-b-0 xs:border-b-0 xs:w-auto xs:border-r w-full px-5 py-4 leading-tight border-b border-gray-300 outline-none"
                :class="{
                  'text-gray-500 hover:text-gray-700 hover:bg-gray-50': form.author_type != type.value,
                  'text-gray-900 bg-gray-200': form.author_type == type.value,
                }"
                @click="updateAuthorType(type.value)"
              >
                <span class="font-medium text-center">{{ type.label }}</span>
              </button>
            </div>

            <VInput
              v-if="form.author_type == 'known'"
              v-model="form.author"
              input-class="w-full"
              name="author"
              class="mb-4"
              :placeholder="$t('author')"
            />
            <div
              v-else
              class="mb-4"
            />

            <VInputSelect
              v-model="form.locale"
              :label="$t('language')"
              name="locale"
              required
              input-class="w-full"
              class="mb-4"
            >
              <option value="fr">
                Français
              </option>
              <option value="en">
                English
              </option>
            </VInputSelect>

            <VInputCheckbox
              v-model="form.accept"
              name="accept"
              ping
              class="mt-6 mb-6"
              :description="$t('page.settings.daily_quote.checkbox_description')"
            />

            <div
              ref="tooltip"
              class="inline-block"
            >
              <BaseButton
                type="submit"
                :disabled="!canSubmit"
                class="btn btn-primary btn-lg sm:w-auto w-full mt-2"
              >
                {{ $t('send') }}
              </BaseButton>
            </div>
          </VForm>
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>
    <BaseContainer
      v-else
      size="2xl"
    >
      <BaseAlert
        class="border border-green-400"
        type="success"
        :title="$t('daily_quote_successfully_submitted')"
      >
        <p>{{ $t('page.settings.daily_quote.success.description') }}</p>

        <button
          type="button"
          class="mt-2 font-semibold underline border-none outline-none appearance-none"
          @click="success = false"
        >
          {{ $t('page.settings.daily_quote.retry') }}
        </button>
      </BaseAlert>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { cloneDeep } from "lodash-es";
import i18n from "@/plugins/i18n";
import { Visibility } from "@/models/Account";

const DEFAULT_FORM = {
  text: "",
  locale: i18n.global.locale as string,
  author: "",
  author_type: "known",
  accept: false,
};

export default defineComponent({
  data () {
    return {
      tippy: null as any,
      authorTypes: [
        {value: "known", label: this.$t("author_name")},
        {value: "unknown", label: this.$t("unknown_author")},
        {value: "member", label: this.$t("im_the_author")},
      ],
      success: false,
      form: cloneDeep(DEFAULT_FORM)
    };
  },
  computed: {
    canSubmit() : boolean {
      return this.form.text != "" && this.form.accept && this.$store.state.user?.member?.visibility == "public";
    }
  },
  created () {
    this.resetForm();
  },
  mounted() {
    if (this.$store.state.user?.member?.visibility == Visibility.private) {

      this.tippy = this.$tippy(this.$refs.tooltip as HTMLElement, {
        content: this.$t("page.settings.daily_quote.profil_not_public_message"),
        maxWidth: 250,
      });
    }
  },
  methods: {
    updateAnonymousMode(value: boolean) {
      this.$store.dispatch("updateAnonymousMode", value);
    },
    resetForm() {
      this.form = cloneDeep(DEFAULT_FORM);
      this.form.locale = this.$i18n.locale;
    },
    onSuccess() {
      this.resetForm();
      this.success = true;
    },
    updateAuthorType(type: string) {
      this.form.author_type = type;
    }
  }
});
</script>
