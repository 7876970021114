import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const KEY = "token";

export class TokenStore {

  private token: string | null = null;
  private sync = false;

  public async set(token: string) {
    this.token = token;

    await SecureStoragePlugin.set({
      key: KEY,
      value: token
    });

    this.sync = true;
  }

  public async get(): Promise<string | null> {

    if (this.sync) {
      return this.token;
    }

    let token = null;

    try {
      const value = await SecureStoragePlugin.get({
        key: KEY
      });

      token = value.value ?? null;
    } catch (e: any) {
      console.error(e);
    }

    this.token = token;
    this.sync = true;

    return token;
  }

  public async clear() {
    try {
      await SecureStoragePlugin.remove({
        key: KEY
      });
    } catch (e: any) {
      console.error(e);
      // Do not throw error if key does not exists
    }
  }

}

export const tokenStore = new TokenStore();
