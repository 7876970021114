<template>
  <div>
    <div class="p-1.5 border border-gray-300 bg-white rounded min-h-[46px]">
      <div class="flex flex-wrap -m-0.5">
        <div
          v-for="(value, i) in modelValue"
          :key="value"
          class="p-0.5"
        >
          <div class="flex items-stretch py-1 text-gray-700 bg-gray-100 border border-gray-300 rounded">
            <div class="py-px pl-3 pr-1 leading-tight">
              {{ value }}
            </div>
            <button
              type="button"
              class="shrink-0 flex items-center justify-center pl-1 pr-3 text-sm bg-transparent border-0 outline-none appearance-none"
              @click="remove(i)"
            >
              ✕
            </button>
          </div>
        </div>
        <div class="grow p-1">
          <input
            ref="input"
            v-model="input"
            :type="inputType"
            :placeholder="placeholder"
            class="h-7 p-0 min-w-[50px] focus:outline-none focus:shadow-none focus:border-none focus:ring-0 w-full border-none shadow-none outline-none"
            @keydown.enter.prevent="add()"
            @keydown.backspace="removeLast()"
            @blur="add()"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Array as PropType<string[]>
    },
    placeholder: {
      default: "",
      type: String
    },
    max: {
      default: null,
      type: Number
    },
    inputType: {
      default: "text",
      type: String
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      input: "",
    };
  },
  computed: {
    inputElement() : HTMLInputElement {
      return this.$refs.input as HTMLInputElement;
    },
  },
  methods: {
    remove(i: number) {
      const newValue = cloneDeep(this.modelValue);
      newValue.splice(i,1);
      this.$emit("update:modelValue", newValue);
    },
    add() {

      if (this.input == "") {
        return;
      }

      if (this.max && this.modelValue.length >= this.max) {
        this.input = "";
        return;
      }

      if (this.modelValue.includes(this.input)) {
        this.input = "";
        return;
      }

      this.$emit("update:modelValue", [...this.modelValue, this.input]);
      this.input = "";
    },
    removeLast() {
      if (this.modelValue.length) {
        this.remove(this.modelValue.length - 1);
      }
    }
  }
});
</script>
