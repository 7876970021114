<template>
  <div
    class="md:pt-10 max-w-3xl pt-6 pr-4"
    :class="paddingBottom"
  >
    <div class="md:mx-auto flex items-start">
      <button
        v-if="previous"
        type="button"
        class="-left-1 top-1 relative p-1"
        @click="back()"
      >
        <Icon
          icon="heroicons-outline:chevron-left"
          class="md:w-7 md:h-7 w-6 h-6 mr-2"
        />
      </button>
      <div class="pt-0.5 md:pt-0">
        <h1 class="md:text-4xl font-display md:leading-tight text-3xl font-semibold leading-9 text-gray-900">
          {{ title }}
        </h1>
      </div>
    </div>
    <div
      v-if="subtitle"
      class="md:pt-2 pt-2"
    >
      <h2 class="md:text-lg md:leading-snug text-base leading-snug text-gray-600">
        {{ subtitle }}
      </h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      default: "",
      type: String
    },
    previous: {
      default: true,
      type: Boolean
    },
    paddingBottom: {
      default: "pb-10",
      type: String
    }
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
});
</script>
