<template>
  <div>
    <p
      ref="content"
      class="whitespace-pre-line"
      :class="lineClampClass"
    >
      <slot />
    </p>
    <button
      v-if="!active && !fullyVisible"
      type="button"
      class="inline mt-1 px-0.5 py-0 text-gray-900 bg-transparent border-b border-gray-400 border-dashed appearance-none hover:text-gray-600"
      @click="active = true"
    >
      <span class="text-base font-semibold">{{ $t('read_more') }}</span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    maxLines: {
      default: 3,
      type: Number
    }
  },
  data () {
    return {
      active: false,
      numberOfLines: 1
    };
  },
  computed: {
    lineClampClass () {

      if (this.active || this.fullyVisible) {
        return "";
      }

      if (this.maxLines == 5) {
        return "line-clamp-5";
      }

      if (this.maxLines == 3) {
        return "line-clamp-3";
      }

      if (this.maxLines == 2) {
        return "line-clamp-2";
      }

      return "line-clamp-1";
    },
    fullyVisible() {
      return this.numberOfLines <= this.maxLines;
    }
  },
  mounted () {
    const el = this.$refs.content as HTMLElement|null;
    if (!el) {
      return;
    }
    const style = window.getComputedStyle(el);
    const divHeight = +el.offsetHeight;
    const lineHeight = +style.lineHeight.replace("px", "");
    const lines = divHeight / lineHeight;

    this.numberOfLines = lines;
  }
});
</script>
