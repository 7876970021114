<template>
  <OnboardingLayout>
    <OnboardingTitle>
      {{ $t('onboarding.home.title') }}
    </OnboardingTitle>

    <OnboardingText class="mb-10">
      <p class="mb-3">
        {{ $t('onboarding.home.line_1') }}
      </p>
      <p class="mb-3">
        {{ $t('onboarding.home.line_2') }}
      </p>
      <p class="mb-3">
        {{ $t('onboarding.home.line_3') }}
      </p>
      <p class="mb-3">
        {{ $t('onboarding.home.line_4') }}
      </p>
    </OnboardingText>

    <div class="flex justify-start">
      <img
        src="/img/signature-fr.png"
        alt="Signature"
        class="w-54"
      >
    </div>

    <div class="mt-8">
      <router-link
        id="onboarding-button-next-home"
        to="/onboarding/start"
        class="btn btn-xl btn-primary sm:w-auto inline-flex items-center justify-center w-full"
      >
        {{ $t('create_my_account') }}
      </router-link>
    </div>

    <div class="sm:text-left w-full mt-2 text-base text-center text-gray-600">
      {{ $t('or') }}
      <router-link
        to="/login"
        class="text-primary-600 inline font-medium underline"
      >
        {{ $t('sign_in').toLowerCase() }}
      </router-link>
    </div>
  </OnboardingLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OnboardingTitle from "./components/OnboardingTitle.vue";
import OnboardingText from "./components/OnboardingText.vue";
import OnboardingLayout from "./OnboardingLayout.vue";

export default defineComponent({
  components: {
    OnboardingTitle,
    OnboardingText,
    OnboardingLayout
  }
});
</script>
