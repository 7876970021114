<template>
  <div
    class="relative"
    :style="{
      width: width + 'px',
    }"
  >
    <slot
      name="header"
      :save="save"
      :saving="saving"
      :initializing="initializing"
    />

    <div
      ref="wrapper"
      class="tandem-image-cropper-wrapper relative w-full"
      :style="{height: height + zoomerPadding + 'px', width: width + 'px'}"
    >
      <div
        ref="container"
        class="relative"
        :style="{height: height + 'px', width: width + 'px'}"
      >
        <div
          ref="croppie"
        />

        <div
          v-if="showDragHelp"
          class="top-14 animate-pulse absolute left-0 z-10 flex justify-center w-full pointer-events-none"
        >
          <div class="flex items-center px-3 py-1.5 text-center text-white bg-black bg-opacity-75 rounded-lg">
            <Icon
              icon="ri:drag-move-2-fill"
              class="w-5 h-5 mr-1"
            />
            <span>
              {{ $t('drag_to_reposition') }}
            </span>
          </div>
        </div>

        <div
          class="absolute top-0 left-0 z-10 pointer-events-none"
          :style="{height: height + 'px', width: width + 'px'}"
        >
          <svg viewBox="0 0 100 100">
            <mask id="mask-cropper">
              <!-- Everything under a white pixel will be visible -->
              <rect
                x="0"
                y="0"
                width="100"
                height="100"
                fill="white"
              />

              <!-- Everything under a black pixel will be invisible -->
              <rect
                x="2"
                y="2"
                width="96"
                height="96"
                fill="black"
              />
            </mask>

            <!-- with this mask applied, we "punch" a heart shape hole into the circle -->
            <rect
              height="100"
              width="100"
              x="0"
              y="0"
              fill="rgba(0,0,0,0.4)"
              mask="url(#mask-cropper)"
            />
          </svg>
        </div>
      </div>
      <div class="bottom-2 left-6 absolute">
        <span class="text-2xl font-normal">-</span>
      </div>
      <div class="bottom-2 right-6 absolute">
        <span class="text-2xl font-normal">+</span>
      </div>
    </div>

    <slot
      name="footer"
      :save="save"
      :saving="saving"
      :initializing="initializing"
      :rotate-left="rotateLeft"
      :rotate-right="rotateRight"
    />

    <div
      v-if="initializing"
      class="absolute inset-0 z-10 flex items-center justify-center w-full h-full bg-white"
    >
      <BaseSpinner />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, Ref } from "vue";
import Croppie from "croppie";
import "croppie/croppie.css";
import { ref, onMounted } from "vue";
import { resizeImageFromURI } from "@/services/utils";
import { debounce } from "lodash";

export default defineComponent({
  props: {
    width: {
      default: 300,
      type: Number,
    },
    height: {
      default: 300,
      type: Number,
    },
    sourceImage: {
      default: "",
      type: String
    }
  },
  setup (props) {

    const container = ref(null) as Ref<HTMLElement|null>;
    const croppie = ref(null) as Ref<HTMLElement|null>;
    const initializing = ref(true);
    const croppable = ref(false);
    const saving = ref(false);
    const zoomerPadding = 48;
    const showDragHelp = ref(true);

    let cropper = null as Croppie|null;

    const init = async () => {

      initializing.value = true;

      // Give time to the loading state to render before doing CPU intensive tasks
      setTimeout(async () => {

        if (croppie.value == null) {
          initializing.value = false;
          return;
        }

        try {

          cropper = new Croppie(croppie.value, {
            enableExif: true,
            enableResize: false,
            enableZoom: true,
            enableOrientation: true,
            showZoomer: true,
            viewport: {
              type: "square",
              height: props.height,
              width: props.width
            }
          });

          let url = props.sourceImage;

          // Try to resize image
          try {
            url = await resizeImageFromURI(url, 1400, 1400);
          } catch (e: any) {
          // Do nothing
          }

          await cropper.bind({
            url: url,
            zoom: 0,
          });

          croppie.value.addEventListener("update", () => {
            showDragHelp.value = false;
          });

        } finally {
          initializing.value = false;
        }

      }, 100);
    };

    const resetViewPort = () => {
      if (!initializing.value) {
        destroy();
        init();
      }
    };

    const resetViewPortDebounced = debounce(() => {
      resetViewPort();
    }, 100);

    const rotateLeft = () => {
      if (!cropper) {
        return;
      }

      cropper.rotate(90);
    };

    const rotateRight = () => {
      if (!cropper) {
        return;
      }

      cropper.rotate(-90);
    };

    const destroy = () => {
      if (!cropper) {
        return;
      }

      cropper.destroy();
    };

    onMounted(() => {
      init();
    });

    onBeforeUnmount(() => {
      destroy();
    });

    const save = async (type: Croppie.Type = "blob") : Promise<Blob|string|null> => {
      if (!cropper) {
        return null;
      }

      saving.value = true;

      const r = await cropper.result({
        type: type as any,
        size: {
          width: 1000,
          height: 1000,
        }
      });

      saving.value = false;

      return r;
    };

    return {
      resetViewPortDebounced,
      container,
      croppie,
      croppable,
      zoomerPadding,
      initializing,
      saving,
      save,
      rotateLeft,
      rotateRight,
      showDragHelp,
    };
  },
  watch: {
    width() {
      this.resetViewPortDebounced();
    }
  }
});
</script>

<style lang="scss">
.tandem-image-cropper-wrapper {
  .cr-slider::-webkit-slider-runnable-track {
    @apply bg-gray-300;
    height: 5px;
  }
   .cr-slider::-moz-range-track {
    @apply bg-gray-300;
    height: 5px;
  }
  .cr-slider::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    top: -3px;
    @apply bg-white;
    @apply shadow;
    @apply ring ring-gray-300 ring-1;
  }
  .cr-slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    top: -3px;
    @apply bg-white;
    @apply shadow;
    @apply ring ring-gray-300 ring-1;
  }
  .croppie-container .cr-viewport, .croppie-container .cr-resizer {
    border: none;
  }
}
</style>
