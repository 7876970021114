import { SexualOrientation } from "@/models/User";
import { endsWith, startsWith } from "lodash-es";

function getHost(): string {
  let host = import.meta.env.VITE_API_URL || "https://effettandem.com";
  host = host.replace(/\/$/, "");

  return host + "/";
}

function getRootDomain(): string {
  let host = getHost();
  host = host.replace(/\/$/, "");
  const url = new URL(host);
  url.port = "";
  host = url.host;
  const parts = host.split(".");

  if (parts.length >= 2) {
    return parts[parts.length - 2] + "." + parts[parts.length - 1];
  }

  return "effettandem.com";
}

function getRoute(route: string): string {
  const host = getHost();
  route = route.replace(/^\/|\/$/g, "");
  return host + route;
}

function blobToBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

function validateBase64(base64: string, prefix = "data:application/octet-stream"): string {
  if (!base64.match(/^data:.*\/.*;base64,/)) {
    base64 = `${prefix};base64,${base64}`;
  }

  return base64;
}

async function base64ToBlob(base64: string, prefix = "data:application/octet-stream"): Promise<Blob> {

  base64 = validateBase64(base64, prefix);

  const response = await fetch(base64);

  return response.blob();
}

function hideString(w: string, n = 2) {
  if (w.length < n + 1) return w;
  return w.substring(0, n) + "*".repeat(w.length - n);
}

function websiteUrl(path = ""): string {
  let websiteUrl = import.meta.env.VITE_WEBSITE_URL ?? "https://effettandem.com";

  if (endsWith(websiteUrl, "/")) {
    websiteUrl = websiteUrl.slice(0, -1);
  }
  if (startsWith(path, "/")) {
    path = path.substring(1);
  }
  return websiteUrl + "/" + path;
}

function resizeImageFromURI(source: string, height: number, width: number): Promise<string> {

  const img = new Image();
  img.src = source;

  return new Promise((resolve, reject) => {


    img.onload = () => {

      // Check if the image require resize at all
      if (img.height <= height && img.width <= width) {
        resolve(source);
        return;
      }

      // Make sure the width and height preserve the original aspect ratio and adjust if needed
      if (img.height > img.width) {
        width = Math.floor(height * (img.width / img.height));
      }
      else {
        height = Math.floor(width * (img.height / img.width));
      }

      const resizingCanvas: HTMLCanvasElement = document.createElement("canvas");
      const resizingCanvasContext = resizingCanvas.getContext("2d");

      if (!resizingCanvasContext) {
        reject("Could not acquire context 2d");
        return;
      }

      // Start with original image size
      resizingCanvas.width = img.width;
      resizingCanvas.height = img.height;

      // Draw the original image on the (temp) resizing canvas
      resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

      const curImageDimensions = {
        width: Math.floor(img.width),
        height: Math.floor(img.height)
      };

      const halfImageDimensions = {
        width: null as number | null,
        height: null as number | null
      };

      // Quickly reduce the size by 50% each time in few iterations until the size is less then
      // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
      // created with direct reduction of very big image to small image
      while (curImageDimensions.width * 0.5 > width) {
        // Reduce the resizing canvas by half and refresh the image
        halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
        halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

        resizingCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
          0, 0, halfImageDimensions.width, halfImageDimensions.height);

        curImageDimensions.width = halfImageDimensions.width;
        curImageDimensions.height = halfImageDimensions.height;
      }

      // Now do final resize for the resizingCanvas to meet the dimension requirements
      // directly to the output canvas, that will output the final image
      const outputCanvas: HTMLCanvasElement = document.createElement("canvas");
      const outputCanvasContext = outputCanvas.getContext("2d");

      outputCanvas.width = width;
      outputCanvas.height = height;

      if (!outputCanvasContext) {
        reject("Could not acquire context 2d");
        return;
      }

      outputCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height, 0, 0, width, height);

      // output the canvas pixels as an image. params: format, quality
      const base64ResizedImage = outputCanvas.toDataURL("image/jpeg", 0.85);

      // Cleanup
      resizingCanvas.remove();
      outputCanvas.remove();

      resolve(base64ResizedImage);
    };

  });

}

function sexualOrientationsToGenders(sexualOrientations: string[]): string[] {
  return sexualOrientations.map(o => {
    if (o == SexualOrientation.non_binary) {
      return "non_binary";
    }

    if (o == SexualOrientation.woman) {
      return "female";
    }

    if (o == SexualOrientation.man) {
      return "male";
    }

    return null;
  })
    .filter(o => o != null) as string[];
}

export { getHost, getRoute, blobToBase64, base64ToBlob, validateBase64, hideString, getRootDomain, websiteUrl, resizeImageFromURI, sexualOrientationsToGenders };
