<template>
  <ProfileSection v-if="answers.length">
    <ProfileSectionBox>
      <ProfileSectionBoxRow
        v-for="shortQuestionAnswer in answers"
        :key="shortQuestionAnswer.question_id"
      >
        <ProfileQuestion>
          <template #title>
            {{ shortQuestionAnswer.question.text }}
          </template>

          <template #default>
            {{ shortQuestionAnswer.value }}
          </template>
        </ProfileQuestion>
      </ProfileSectionBoxRow>
    </ProfileSectionBox>
  </ProfileSection>
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { defineComponent, inject, PropType } from "vue";
import ProfileSection from "./ProfileSection.vue";
import ProfileSectionBox from "./ProfileSectionBox.vue";
import ProfileSectionBoxRow from "./ProfileSectionBoxRow.vue";
import ProfileQuestion from "./components/ProfileQuestion.vue";
import { AnswerWithQuestion } from "../../types";

export default defineComponent({
  components: {
    ProfileSection,
    ProfileSectionBox,
    ProfileSectionBoxRow,
    ProfileQuestion
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    }
  },
  setup () {
    const answersByType = inject("answersByType") as (type: string) => AnswerWithQuestion[];

    return {
      answersByType
    };
  },
  computed: {
    answers () : AnswerWithQuestion[] {
      return this.answersByType("LongQuestionType");
    }
  }
});
</script>
