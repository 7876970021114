<template>
  <!-- Body -->
  <div class="md:pt-4 sm:pt-6 pt-10">
    <!-- Title -->
    <div class="pt-safe">
      <BaseContainer :size="containerSize">
        <div class="md:mt-6 mb-8">
          <h1
            class="font-display md:text-4xl md:leading-8 mb-2 text-3xl font-semibold leading-7 text-gray-800"
          >
            {{ $t('page.search.title') }}
          </h1>
          <p class="leading-tight text-gray-600">
            {{ $t('page.search.subtitle') }}
            <button
              type="button"
              class="inline-block border-b border-gray-400 border-dashed"
              @click="openOnboarding()"
            >
              <Icon
                icon="heroicons-outline:information-circle"
                class="-top-px relative inline-block w-4 h-4 mr-1"
              />
              <span>{{ $t("page.search.learn_more") }}</span>
            </button>
          </p>
        </div>
      </BaseContainer>
    </div>

    <!-- Header Desktop -->
    <SearchHeaderDesktop
      v-if="!mobile"
      :container-size="containerSize"
    />

    <!-- Header Mobile -->
    <SearchHeaderMobile
      v-if="mobile"
    />

    <BaseContainer
      class="relative"
      :size="containerSize"
    >
      <!-- Tree - Desktop -->
      <div
        v-if="!mobile"
        class="absolute top-0 right-0 pt-5 translate-x-1/2 translate-y-0"
      >
        <img
          src="@/assets/img/tree/tree-primary-500.svg"
          alt="Tandem Tree"
          class="opacity-[0.08] pointer-events-none max-w-full w-auto"
        >
      </div>

      <div class="flex flex-wrap -m-3">
        <template v-if="loading">
          <div
            v-for="i in 16"
            :key="i"
            :class="itemWidthClasses"
          >
            <MemberItemSkeleton />
          </div>
        </template>
        <template v-else>
          <div
            v-for="member in members"
            :key="member.id"
            :class="itemWidthClasses"
          >
            <MemberItem
              :display-mode="memberItemDisplayMode"
              :anonymous-mode="$store.getters.anonymousMode"
              :member="member"
            />
          </div>
        </template>
      </div>

      <SearchEnd v-if="endOfList" />
    </BaseContainer>
  </div>

  <!-- Filters -->
  <SearchFilters />

  <!-- Sorts -->
  <SearchSorts />

  <!-- Onboarding Modal -->
  <OnboardingModal v-model="showOnboarding" />

  <div
    ref="footerRef"
    class="relative"
  >
    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, provide, inject, Ref, computed, onMounted, onBeforeUnmount } from "vue";
import type { Member } from "@/models/Member";
import SearchFilters from "./layout/filters/SearchFilters.vue";
import SearchEnd from "./components/SearchEnd.vue";
import OnboardingModal from "./components/OnboardingModal.vue";
import SearchHeaderDesktop from "./layout/header/SearchHeaderDesktop.vue";
import SearchSorts from "./layout/sort/SearchSorts.vue";
import type { Filters } from "./models/Filters";
import type { Account } from "@/models/Account";
import { debounce, get } from "lodash-es";
import { api } from "@/services/api";
import { store } from "@/store";
import SearchHeaderMobile from "./layout/header/SearchHeaderMobile.vue";
import { SUSPENDED, UNSUBSCRIBED } from "@/services/errorCodes";
import { Sort } from "./models/Sort";

export default defineComponent({
  components: {
    SearchFilters,
    OnboardingModal,
    SearchHeaderDesktop,
    SearchSorts,
    SearchEnd,
    SearchHeaderMobile
  },
  setup() {
    const mobile = inject("mobile") as Ref<boolean>;

    // Footer

    const footerRef = ref(null) as Ref<HTMLElement|null>;

    // Sort modal

    const visibleSorts = ref(false);

    const showSorts = () => {
      visibleSorts.value = true;
    };

    const hideSorts = () => {
      visibleSorts.value = false;
    };

    provide("visibleSorts", visibleSorts);
    provide("showSorts", showSorts);
    provide("hideSorts", hideSorts);

    // Filters modal

    const visibleFilters = ref(false);

    const showFilters = () => {
      visibleFilters.value = true;
    };

    const hideFilters = () => {
      visibleFilters.value = false;
    };

    provide("visibleFilters", visibleFilters);
    provide("showFilters", showFilters);
    provide("hideFilters", hideFilters);

    // Search

    const page = ref(1);
    const endOfList = ref(false);
    const memberItemDisplayMode = ref("default");

    const numberOfFilters = computed(() => {
      return getNumberOfFilters(store.getters.filters);
    });

    const getNumberOfFilters = (filters: Filters): number => {

      const excludeFromCount = ["latitude", "longitude", "location_label"];

      return Object.entries(filters)
        .filter(v => v[1] != "" && v[1] != null)
        .filter(v => {
          if (excludeFromCount.includes(v[0])) {
            return false;
          }
          return true;
        })
        .length;
    };

    provide("getNumberOfFilters", getNumberOfFilters);
    provide("numberOfFilters", numberOfFilters);
    provide("memberItemDisplayMode", memberItemDisplayMode);

    const loading = ref(false);
    const fetching = ref(false);
    const lastHash = ref(null) as Ref<string | null>;
    const members = ref([]) as Ref<Member[]>;

    const requestParams = () => {

      let sort = store.getters.sort;

      if (store.getters.sort != Sort.proximity) {
        sort = "-" + sort;
      }

      return {
        sort: sort,
        filter: store.getters.filters,
        page: page.value,
      };
    };

    const requestHash = (): string => {
      return JSON.stringify(requestParams());
    };

    const fetchDebounceWithLoading = (() => {
      loading.value = true;
      fetchDebounce();
    });

    const fetchDebounce = debounce(() => {
      fetch();
    }, 300);

    const fetch = () => {

      if (fetching.value) {
        return cancelRequest();
      }

      if (requestHash() == lastHash.value) {
        return cancelRequest();
      }

      if (endOfList.value) {
        return cancelRequest();
      }

      // loading can be set by fetchDebounce too
      // but fetch may get called directly
      loading.value = true;

      window.scrollTo(0, 0);

      makeRequest();

      store.dispatch("updateSearchSettings");
    };

    const resetPagination = () => {
      page.value = 1;
      endOfList.value = false;
    };

    const cancelRequest = () => {
      fetching.value = false;
      loading.value = false;
    };

    const makeRequest = () => {
      fetching.value = true;

      api.get("members", {
        params: requestParams()
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          lastHash.value = requestHash();
          memberItemDisplayMode.value = "default";
          if (store.getters.sort == "values") {
            memberItemDisplayMode.value = "values";
          }

          if (response.data.data.length == 0) {
            endOfList.value = true;
          }

          if (page.value == 1) {
            members.value = response.data.data;
          } else {
            members.value.push(...response.data.data);
          }
        })
        .catch(error => {
          const code = get(error, "response.data.code");
          if ([UNSUBSCRIBED, SUSPENDED].includes(code)) {
            store.dispatch("fetchUser");
          }
        })
        .finally(() => {
          fetching.value = false;
          loading.value = false;
        });
    };

    onMounted(() => {
      window.addEventListener("scroll", onScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", onScroll);
    });

    let isScrollBottom = false;

    const onScroll = () => {

      if (footerRef.value == null) {
        return;
      }

      const offsetY = footerRef.value.getBoundingClientRect().top + document.documentElement.scrollTop;

      const buffer = Math.min(offsetY, 300);

      const oldIsScrollBottom = isScrollBottom;

      if (window.innerHeight + window.scrollY + buffer >= offsetY) {
        isScrollBottom = true;
      } else {
        isScrollBottom = false;
      }

      if (!oldIsScrollBottom && isScrollBottom) {
        nextPage();
      }
    };

    const nextPage = () => {
      if (endOfList.value) {
        return;
      }
      page.value++;
      makeRequest();
    };

    return {
      footerRef,
      resetPagination,
      mobile,
      loading,
      fetch,
      fetchDebounce,
      fetchDebounceWithLoading,
      members,
      memberItemDisplayMode,
      endOfList
    };
  },
  data() {
    return {
      showOnboarding: false,
    };
  },
  computed: {
    itemWidthClasses(): string {
      return "w-full xs:w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 p-3";
    },
    containerSize(): string {
      return "7xl";
    }
  },
  watch: {
    "$store.getters.filters": {
      handler() {
        this.resetPagination();
        this.fetchDebounce();
      },
      deep: true,
    },
    "$store.getters.sort": {
      handler(newSort, oldSort) {
        if (newSort != oldSort) {
          this.resetPagination();
          this.fetchDebounce();
        }
      }
    }
  },
  created() {
    this.fetch();
  },
  mounted () {
    // A small delay is necessary on iphone to avoid a scroll lock issue on load
    setTimeout(() => {
      if (this.$store.state.user && !this.showOnboarding) {
        const user = this.$store.state.user as Account;
        this.showOnboarding = user.metadata.first_search;
      }
    }, 800);
  },
  methods: {
    openOnboarding () {
      this.showOnboarding = true;
    }
  }
});
</script>
