<template>
  <label
    class="form-input-label mb-1"
  >{{ label }}<span
    v-if="required"
    class="text-red-600"
  > *</span></label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    required: {
      default: false,
      type: Boolean
    },
    label: {
      required: true,
      type: String
    }
  }
});
</script>
