export interface AuthState {
  loggedIn: boolean,
  token: string | null
}

export const defaultAuthState = {
  loggedIn: false,
  token: null
} as AuthState;

export const authModule = {
  namespaced: true,
  state() {
    return defaultAuthState;
  },
  mutations: {
    loggedIn(state: AuthState, loggedIn: boolean) {
      state.loggedIn = loggedIn;
    },
    token(state: AuthState, token: string | null) {
      state.token = token;
    }
  },
};
