<template>
  <div class="">
    <div class="pt-safe pl-safe pr-safe bg-white">
      <div class="flex justify-between w-full h-16 max-w-full">
        <div class="flex min-w-0">
          <router-link
            v-if="mobile"
            class="shrink-0 flex items-center px-3"
            to="/member/messages"
          >
            <Icon
              icon="heroicons-solid:chevron-left"
              class="w-5 h-5 text-gray-500"
            />
          </router-link>
          <component
            :is="url ? 'router-link' : 'div'"
            v-if="user"
            :to="url"
            class="grow flex items-center min-w-0 pr-3 space-x-4 overflow-hidden"
          >
            <div class="md:pl-3 shrink-0 relative">
              <div class="w-10 h-10 overflow-hidden rounded-full">
                <MemberAvatar
                  :member="user.member"
                  :size="45"
                  :show-state-text="false"
                />
              </div>
              <div class="absolute bottom-0 right-0">
                <div
                  class="-bottom-1 relative w-3 h-3 bg-green-500 border-2 border-white rounded-full"
                  :class="{
                    'bg-green-500': user.online,
                    'bg-gray-300': !user.online
                  }"
                />
              </div>
            </div>
            <div class="grow overflow-hidden">
              <h1 class="mb-0.5 text-xl font-semibold leading-none truncate">
                {{ user.first_name }}
              </h1>
              <p class="text-sm leading-none text-gray-500">
                <span v-if="user.online">{{ $t('online') }}</span>
                <span v-else>{{ $t('offline') }}</span>
              </p>
            </div>
          </component>
        </div>

        <div
          v-if="user && !user.admin"
          class="shrink-0 flex items-center pr-5"
        >
          <div class="static">
            <div class="relative">
              <button
                v-if="canCall"
                class="text-primary-600 hover:bg-gray-100 w-9 h-9 flex items-center justify-center text-left bg-white border-none rounded-full outline-none appearance-none"
                @click="initCall"
              >
                <div
                  v-if="showVideoCallUnlocked"
                  class="bg-primary-100 animate-ping w-9 h-9 absolute inset-0 rounded-full"
                />
                <Icon
                  icon="heroicons-solid:phone"
                  class="relative w-5 h-5"
                />
              </button>
            </div>

            <div
              v-if="showVideoCallUnlocked"
              class="top-[70px] absolute max-w-full px-2 right-0 z-[1]"
            >
              <div class="arrow-top drop-shadow xs:max-w-sm w-full max-w-full p-4 bg-white rounded-md shadow-md">
                <div class="flex space-x-2 overflow-hidden">
                  <div class="grow shrink-0">
                    <Icon
                      icon="mdi:phone"
                      class="text-primary-600 w-5 h-5"
                    />
                  </div>
                  <div class="grow min-w-0 overflow-hidden">
                    <p class="whitespace-nowrap mb-1 font-semibold leading-none truncate">
                      {{ $t('page.profile.call_unlocked.title') }}
                    </p>
                    <p class="text-sm leading-none text-gray-500 truncate">
                      {{ $t('page.profile.call_unlocked.subtitle', {name: user.first_name}) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="text-primary-600 hover:bg-gray-100 w-9 h-9 flex items-center justify-center text-left bg-white border-none rounded-full outline-none appearance-none"
            @click="showShareMemberModal = true"
          >
            <Icon
              icon="heroicons-solid:share"
              class="w-5 h-5"
            />
          </button>

          <!-- Button More Options -->
          <Menu
            as="div"
            class="relative"
          >
            <div>
              <MenuButton
                class="text-primary-500 hover:bg-gray-100 w-9 h-9 flex items-center justify-center bg-transparent bg-white border-none rounded-full outline-none appearance-none"
              >
                <Icon
                  icon="heroicons-solid:shield-exclamation"
                  class="w-5 h-5"
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="scale-95 opacity-0"
              enter-to-class="scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="scale-100 opacity-100"
              leave-to-class="scale-95 opacity-0"
            >
              <MenuItems class="focus:outline-none absolute right-0 z-10 w-48 py-2 mt-2 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg">
                <MenuItem v-slot="{ active }">
                  <button
                    type="button"
                    class="w-full leading-tight text-left border-none outline-none appearance-none"
                    :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                    @click="showReportModal = true"
                  >
                    {{ $t("report") }}
                  </button>
                </MenuItem>
                <MenuItem
                  v-if="user.member?.member_block_id == null"
                  v-slot="{ active }"
                >
                  <button
                    type="button"
                    class="w-full leading-tight text-left border-none outline-none appearance-none"
                    :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                    @click="showBlockModal = true"
                  >
                    {{ $t("block") }}
                  </button>
                </MenuItem>
                <MenuItem
                  v-else
                  v-slot="{ active }"
                >
                  <button
                    type="button"
                    class="w-full leading-tight text-left border-none outline-none appearance-none"
                    :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                    @click="showUnblockModal = true"
                  >
                    {{ $t("unblock") }}
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    type="button"
                    class="w-full leading-tight text-left border-none outline-none appearance-none"
                    :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                    @click="showCallInitModal = true"
                  >
                    {{ $t("authorize_video_chat") }}
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <button
      v-if="showMoveToPrimaryInbox"
      type="button"
      class="hover:bg-blue-600 sm:text-base flex items-center justify-center w-full py-1 space-x-2 text-sm text-white transition-colors duration-150 bg-blue-500 border-0"
      @click="moveToPrimaryInbox()"
    >
      <span>{{ $t('move_to_priority_inbox') }}</span>
      <Icon
        icon="heroicons-solid:external-link"
        class="text-primary-100 sm:w-5 sm:h-5 w-4 h-4"
      />
    </button>

    <div
      v-else-if="showIsSecondaryInbox"
      class="sm:text-base flex items-center justify-center w-full py-1 space-x-2 text-sm text-blue-900 transition-colors duration-150 bg-blue-100 border-b border-blue-200"
    >
      <span>{{ $t('this_conversation_is_in_x_secondary_inbox', {x: user.first_name}) }}</span>
    </div>

    <div
      v-else
      class="border-b border-gray-200"
    />
  </div>

  <CallInitModal
    v-if="user.member"
    v-model="showCallInitModal"
    :conversation-id="conversation.id"
    :first-name="user.first_name"
    :me="me"
  />

  <ReportMemberModal
    v-if="user.member"
    v-model="showReportModal"
    :member-id="user.member.id"
    :first-name="user.first_name"
  />

  <BlockMemberModal
    v-if="user.member"
    v-model="showBlockModal"
    :member-id="user.member.id"
    :first-name="user.first_name"
    @blocked="setMemberBlockId(user.id, $event)"
  />

  <UnblockMemberModal
    v-if="user.member && user.member.member_block_id"
    v-model="showUnblockModal"
    :member-block-id="user.member.member_block_id"
    :first-name="user.first_name"
    @unblocked="setMemberBlockId(user.id, null)"
  />

  <ShareMemberModal
    v-if="user.member"
    v-model="showShareMemberModal"
    :member-id="user.member.id"
    :first-name="user.first_name"
  />
</template>

<script lang="ts" setup>
import { computed, ComputedRef, defineEmits, inject, onBeforeUnmount, Ref, ref } from "vue";
import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/vue";
import { Conversation, ConversationUser } from "@/models/Conversation";
import MemberAvatar from "../../profile/components/sections/components/MemberAvatar.vue";
import { api } from "@/services/api";
import { useRouter } from "vue-router";
import { echo } from "@/services/echo";
import { urls } from "@/services/urls";


defineEmits(["updateVideoChatAuthorization"]);

const router = useRouter();

const mobile = inject("mobile") as Ref<boolean>;
const conversation = inject("conversation") as ComputedRef<Conversation>;
const user = inject("user") as ComputedRef<ConversationUser>;
const me = inject("me") as ComputedRef<ConversationUser>;
const updatePriority = inject("updatePriority") as (priority: string) => void;
const softFetch = inject("softFetch") as () => void;

const videoChatAuthorized = ref(conversation.value.video_chat_authorized);

const showCallInitModal = ref(false);
const showReportModal = ref(false);
const showUnblockModal = ref(false);
const showBlockModal = ref(false);
const showShareMemberModal = ref(false);

const showVideoCallUnlocked = ref(false);

let videoChatAuthorizedOld = conversation.value.video_chat_authorized;

const setMemberBlockId = inject("setMemberBlockId") as (userId: string, value: string|null) => void;

function moveToPrimaryInbox() {
  api.patch(`conversations/${conversation.value.id}/priority`, {
    priority: "priority"
  })
    .then(() => {
      updatePriority("priority");
      softFetch();
    });
}

function initCall() {
  showCallInitModal.value = true;
}

function getVideoChatAuthorization() {
  api.get(`conversations/${conversation.value.id}/video-chat/authorized`)
    .then(response => {
      videoChatAuthorized.value = response.data.data.authorized;

      if (!videoChatAuthorizedOld && videoChatAuthorized.value) {
        showVideoCallUnlocked.value = true;
      }
    });
}

const channel = echo.private("user." + me.value.id);
channel.listen(".conversations.video-chat.authorized", onEventVideoChatAuthorized);

onBeforeUnmount(() => {
  channel.stopListening(".conversations.video-chat.authorized", onEventVideoChatAuthorized);
});

function onEventVideoChatAuthorized() {
  getVideoChatAuthorization();
}

getVideoChatAuthorization();

const url = computed(() => {
  if (!user.value.member.can.view) {
    return null;
  }
  return urls.member(user.value.member);
});

const showMoveToPrimaryInbox = computed(() => {
  if (!conversation.value) {
    return false;
  }
  if (user.value.admin) {
    return false;
  }
  return me.value.pivot.priority == "secondary";
});

const showIsSecondaryInbox = computed(() => {
  if (!conversation.value) {
    return false;
  }
  if (user.value.admin) {
    return false;
  }
  if (user.value.pivot.has_responded) {
    return false;
  }
  return user.value.pivot.priority == "secondary";
});

const canCall = computed(() => {
  return true;
});

</script>

<style lang="postcss" scoped>
.arrow-top::after {
  content: "";
  position: absolute;
  right: 92px;
  top: -20px;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  border-left: 10px solid transparent;
}

@media (min-width: 415px) {
  .arrow-top::after {
    right: 92px;
  }
}

</style>
