<template>
  <ProfileSection v-if="answer && options.length">
    <template #title>
      {{ $t('personality_traits') }}
    </template>
    <div class="md:px-0 px-4">
      <div class="flex flex-wrap -m-1">
        <div
          v-for="option in options"
          :key="option.id"
          class="xs:w-1/3 w-full p-1"
        >
          <ProfileSectionPersonalityItem
            :answer="answer"
            :option="option"
          />
        </div>
      </div>
    </div>
  </ProfileSection>
</template>

<script lang="ts">
import { Answer } from "@/models/Answer";
import { Member } from "@/models/Member";
import { QuestionOption } from "@/models/QuestionOption";
import { defineComponent, inject, PropType } from "vue";
import { AnswerWithQuestion } from "../../types";
import ProfileSection from "./ProfileSection.vue";
import ProfileSectionPersonalityItem from "./ProfileSectionPersonalityItem.vue";

export default defineComponent({
  components: {
    ProfileSection,
    ProfileSectionPersonalityItem,
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    }
  },
  setup () {
    const answerByName = inject("answerByName") as (name: string) => AnswerWithQuestion|null;

    return {
      answerByName
    };
  },
  computed: {
    answer () : Answer|null {
      return this.answerByName("personality_traits");
    },
    options () :  QuestionOption[] {
      if (!this.answer) {
        return [];
      }
      return this.answer.options;
    }
  }
});
</script>
