import { readonly, ref, Ref, onBeforeUnmount } from "vue";

const bpToNumber = (breakpointString: string): number => {
  return +breakpointString.replace("px", "") as unknown as number;
};

export default (): { width: Ref<number>, breakpoint: Ref<string>, mobile: Ref<boolean> } => {

  const breakpoints = {
    xs: bpToNumber("415px"),
    sm: bpToNumber("640px"),
    md: bpToNumber("1024px"),
    lg: bpToNumber("1280px"),
    xl: bpToNumber("1536px"),
  };

  const width = ref(1200);
  const breakpoint = ref("lg");
  const mobile = ref(false);

  const onResize = (): void => {
    width.value = window.innerWidth;

    let bp = Object.keys(breakpoints).reverse()[0] as string;

    Object.entries(breakpoints).reverse().forEach(b => {
      const bpKey = b[0];
      const bpWidth = b[1];

      if (width.value < bpWidth) {
        bp = bpKey;
      }
    });

    breakpoint.value = bp;
    mobile.value = ["xs", "sm", "md"].includes(bp);
  };

  onResize();

  window.addEventListener("resize", onResize);

  onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize);
  });

  // exposed
  return {
    width: readonly(width),
    breakpoint: readonly(breakpoint),
    mobile: readonly(mobile),
  };
};
