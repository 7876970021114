<template>
  <div class="first:border-t-0 first:pt-0 last:pb-0 md:py-0 py-6 border-t border-gray-200">
    <div class="md:p-6 px-4">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

});
</script>
