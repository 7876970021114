<template>
  <div>
    <div
      v-if="platform == 'ios'"
      class="mb-3"
    >
      <button
        type="button"
        class="hover:bg-gray-50 inline-flex items-center justify-center w-full px-4 py-2 font-semibold text-black bg-white border border-gray-300 rounded-md shadow-sm"
        @click="loginWithApple()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 1024 1024"
          class="w-6 h-6 mr-2 text-black"
        ><path
          fill="currentColor"
          d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5c-34.9-50-87.7-77.5-157.3-82.8c-65.9-5.2-138 38.4-164.4 38.4c-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8c23.8 68.2 109.6 235.3 199.1 232.6c46.8-1.1 79.9-33.2 140.8-33.2c59.1 0 89.7 33.2 141.9 33.2c90.3-1.3 167.9-153.2 190.5-221.6c-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7c-44.8 2.6-96.6 30.5-126.1 64.8c-32.5 36.8-51.6 82.3-47.5 133.6c48.4 3.7 92.6-21.2 129-63.7z"
        /></svg>

        <span>{{ $t('sign_in_with_x', {x: 'Apple'}) }}</span>
      </button>
    </div>

    <div
      class="grid grid-cols-2 gap-3 mt-1"
    >
      <div>
        <button
          type="button"
          class="hover:bg-gray-50 inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm"
          @click="loginWithFacebook()"
        >
          <span class="sr-only">Sign in with Facebook</span>
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div>
        <button
          type="button"
          class="hover:bg-gray-50 inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm"
          @click="loginWithGoogle()"
        >
          <span class="sr-only">Sign in with Google</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
            class="w-5 h-5"
          >
            <g fill="currentColor">
              <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
            </g>
          </svg>
        </button>
      </div>
    </div>

    <div class="relative my-6">
      <div
        class="absolute inset-0 flex items-center"
        aria-hidden="true"
      >
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span class="px-2 text-gray-600 bg-white">
          {{ $t('or_continue_with') }}
        </span>
      </div>
    </div>

    <form
      method="POST"
      @submit.prevent="submit()"
    >
      <div class="mb-4 space-y-3">
        <div>
          <label
            for="email"
            class="block text-gray-600"
          >
            {{ $t('email_address') }}
          </label>
          <div class="mt-1">
            <input
              id="email"
              ref="emailField"
              v-model="form.email"
              name="email"
              type="email"
              autocomplete="email"
              :required="true"
              class="form-input w-full h-12 border-gray-300 rounded"
            >
          </div>
        </div>
        <div>
          <label
            for="password"
            class="block mb-1 text-gray-600"
          >
            {{ $t('password') }}
          </label>
          <div class="mt-1">
            <BasePasswordInput
              ref="passwordField"
              v-model="form.password"
              name="password"
              :required="true"
              class="form-input w-full h-12 border-gray-300 rounded"
            />
          </div>
        </div>
      </div>

      <BaseAlert
        v-if="error"
        class="mb-3 border border-red-300"
        type="danger"
      >
        {{ error }}
      </BaseAlert>

      <div>
        <BaseButton
          type="submit"
          class="btn-lg btn-primary w-full mt-3"
          :loading="loading"
          :disabled="loading"
        >
          {{ $t('sign_in') }}
        </BaseButton>
      </div>

      <div class="mt-2 text-base text-left">
        <router-link
          to="/forgot-password"
          class="hover:text-gray-800 text-gray-500 underline"
        >
          {{ $t('forgot_your_password') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { get } from "lodash-es";
import { defineComponent } from "vue";
import { RouteLocationRaw } from "vue-router";
import { Capacitor } from "@capacitor/core";
import { facebookClient } from "@/services/facebook";
import { googleClient } from "@/services/google";
import { appleClient } from "@/services/apple";

export default defineComponent({
  props: {
    email: {
      default: "",
      type: String
    }
  },
  emits: ["loaded"],
  data () {
    return {
      platform: Capacitor.getPlatform(),
      initialized: false,
      loading: false,
      error: "",
      form: {
        email: "",
        password: "",
      }
    };
  },
  async created ()  {

    if (!import.meta.env.PROD) {
      this.form.email = "member@witify.io";
      this.form.password = "password";
    }

    if (this.email) {
      this.form.email = this.email;
    }

    if (import.meta.env.VITE_TESTING != "true") {
      await googleClient.init();
      await facebookClient.init();
    }

    this.initialize();
  },
  mounted () {

    const passwordField = (this.$refs.passwordField as any)?.$refs?.input ?? null as HTMLInputElement|null;

    if (this.email) {
      if (passwordField) {
        passwordField.focus();
      }
    } else {
      if (!Capacitor.isNativePlatform()) {
        (this.$refs.emailField as HTMLInputElement).focus();
      }
    }
  },
  methods: {
    async submit() {

      if (!this.initialized) {
        return;
      }

      if (this.loading) {
        return;
      }

      this.resetState();

      this.loading = true;

      try {
        const payload = {
          email: this.form.email,
          password: this.form.password,
        };
        await this.$auth.login(payload);
        this.onSuccess();
      } catch(error: any) {
        this.onFail(error);
      }
    },
    async loginWithApple() {

      this.resetState();

      try {
        const result = await appleClient.getToken();

        await this.$auth.login({
          apple_token: result.response.authorizationCode,
          first_name: result.response.givenName,
          last_name: result.response.familyName,
        });

        this.onSuccess();

      } catch (e: any) {
        this.onFail(e);
      }

    },
    async loginWithFacebook() {

      this.resetState();

      try {

        const token = await facebookClient.getToken();

        await this.$auth.login({
          facebook_token: token,
        });

        this.onSuccess();

      } catch (e: any) {
        this.onFail(e);
      }
    },
    async loginWithGoogle() {

      this.resetState();

      try {
        const token = await googleClient.getToken();

        await this.$auth.login({
          google_token: token,
          google_client_id: googleClient.clientId(),
        });

        this.onSuccess();

      } catch (e: any) {
        this.onFail(e);
      }
    },
    resetState() {
      this.error = "";
      this.loading = true;
    },
    onFail(error: any) {
      this.loading = false;
      this.error = get(error.response, "data.message", "Invalid");
    },
    onSuccess () {
      this.loading = false;

      let redirect = {name: "home"} as RouteLocationRaw;

      if (this.$route.query.redirect) {
        redirect = this.$route.query.redirect as string;
      }

      this.$router.push(redirect);
    },
    initialize () {
      this.initialized = true;
      this.$emit("loaded");
    }
  }
});
</script>
