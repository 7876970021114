<template>
  <ProfileSection>
    <template #title>
      {{ $t('details') }}
    </template>
    <ProfileSectionBox>
      <DetailRow :title="$t('interested_by')">
        <DetailRowItem
          v-for="item in interestedBy"
          :key="item"
        >
          {{ item }}
        </DetailRowItem>
      </DetailRow>

      <DetailRow
        v-for="answer in answers"
        :key="answer.id"
        :title="answer.question.label"
      >
        <template v-if="answer.value">
          <DetailRowItem>
            {{ answer.value }}
          </DetailRowItem>
        </template>
        <template v-else-if="answer.options.length">
          <DetailRowItem
            v-for="option in answer.options"
            :key="option.id"
            :answer="answer"
            :option="option"
          >
            {{ option.label }}
          </DetailRowItem>
        </template>
        <template v-else>
          <p class="text-gray-300">
            —
          </p>
        </template>
      </DetailRow>
    </ProfileSectionBox>
  </ProfileSection>
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { SexualOrientation } from "@/models/User";
import { defineComponent, inject, PropType } from "vue";
import { AnswerWithQuestion } from "../../types";
import ProfileSection from "./ProfileSection.vue";
import ProfileSectionBox from "./ProfileSectionBox.vue";
import DetailRow from "./ProfileSectionDetailsRow.vue";
import DetailRowItem from "./ProfileSectionDetailsRowItem.vue";

export default defineComponent({
  components: {
    DetailRow,
    DetailRowItem,
    ProfileSection,
    ProfileSectionBox,
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    }
  },
  setup () {
    const answerByName = inject("answerByName") as (name: string) => AnswerWithQuestion|null;
    const answerList = inject("answerList") as (answer: AnswerWithQuestion|null) => AnswerWithQuestion[];

    return {
      answerByName,
      answerList,
    };
  },
  computed: {
    interestedBy () : string[] {

      if (!this.member.user.sexual_orientations) {
        return [];
      }

      return this.member.user.sexual_orientations.map(o => {
        if (o == SexualOrientation.non_binary) {
          return this.$t("non_binaries");
        }

        if (o == SexualOrientation.woman) {
          return this.$t("women");
        }

        if (o == SexualOrientation.man) {
          return this.$t("men");
        }

        return null;
      })
        .filter(o => o != null && o != "") as string[];
    },
    answers () : AnswerWithQuestion[] {
      const questionNames = [
        "current_occupation",
        "education_level",
        "religious_beliefs",
        "smoking",
        "smoking_frequency",
        "smoking_tolerance_level",
        "alcohol_consumption_frequency",
        "family_situation",
        "kids_living_with",
        "want_to_have_children",
        "food_preferences",
        "accepts_persons_with_limitations",
      ];

      return questionNames.map(name => {
        return this.answerByName(name);
      })
        .filter(item => item != null && (item.value || (item?.options.length ?? 0) > 0)) as AnswerWithQuestion[];
    },
  }
});
</script>
