<template>
  <div class="bg-gray-50 pt-safe">
    <BaseContainer size="xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.subscription.payment_methods.title')"
        :subtitle="$t('page.settings.menu.subscription.payment_methods.subtitle')"
      />
    </BaseContainer>

    <BaseContainer size="xl">
      <div class="mb-10">
        <PaymentSources show-delete />
      </div>

      <div class="mt-4">
        <router-link
          :to="{
            name: 'member.settings.payment_source.update'
          }"
          class="btn btn-primary btn-lg sm:w-auto w-full"
        >
          {{ $t('update_payment_method') }}
        </router-link>
      </div>
    </BaseContainer>
    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PaymentSources from "./components/web/PaymentSources.vue";

export default defineComponent({
  components: {
    PaymentSources
  },
});
</script>
