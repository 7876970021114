import { App } from "vue";
import { http, api, api_ } from "@/services/api";

/*
 |--------------------------------------------------------------------------
 | HTTP Request Service
 |--------------------------------------------------------------------------
 */

export default {
  install: (app: App): void => {
    app.config.globalProperties.$http = http;
    app.config.globalProperties.$api = api;
    app.config.globalProperties.$api_ = api_;
  }
};
