<template>
  <button
    class=" whitespace-nowrap flex px-1 py-4 text-sm font-medium border-b-2"
    :class="{
      'border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-200': !active,
      'border-primary-500 text-primary-500': active,
    }"
    @click="$emit('update:modelValue', tab)"
  >
    {{ label }}
    <span
      v-if="count"
      class="inline-flex items-center space-x-1 px-2 py-1 ml-1.5 text-xs font-medium leading-none rounded-full"
      :class="{
        'text-gray-900 bg-gray-100 ': !active,
        'text-white bg-primary-600 ': active,
      }"
    >
      <span>
        {{ count }}
      </span>

      <Icon
        v-if="flowerCount"
        icon="mdi:flower"
        class="w-3 h-3"
      />
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: String
    },
    tab: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    }
  },
  emits: ["update:modelValue"],
  computed: {
    active(): boolean {
      return this.modelValue == this.tab;
    },
    count() : number {
      return this.$store.getters["conversation/unseenCount"](this.tab);
    },
    flowerCount() : number {
      return this.$store.getters["conversation/unseenFlowerCount"](this.tab);
    }
  }
});
</script>
