<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />

    <nav
      class="relative z-0 flex mb-6 -space-x-px"
      aria-label="Units"
    >
      <div class="grow border-b border-gray-300" />
      <button
        v-for="unit in units"
        :key="unit.value"
        type="button"
        class="rounded-t-md px-5 py-2 border outline-none"
        :class="{
          'text-gray-500 border-transparent border-b-gray-300 hover:text-gray-700 hover:bg-gray-50': currentUnit != unit.value,
          'text-gray-900 z-[1] border-gray-300 border-b-transparent': currentUnit == unit.value,
        }"
        @click="currentUnit = unit.value"
      >
        <span class="font-medium text-center">{{ unit.label }}</span>
      </button>
      <div class="grow border-b border-gray-300" />
    </nav>

    <div
      v-if="currentUnit == 'metric'"
      class="flex"
    >
      <input
        :value="modelValue"
        type="number"
        :name="name"
        :disabled="disabled"
        placeholder="160"
        :required="required"
        :step="1"
        :min="min"
        :max="max"
        class="grow border-r-0 rounded-l"
        :class="[{
          'border-red-600': hasError(),
          'border-gray-300': !hasError(),
        }, inputClass]"
        @input="inputListener(transformInputValue($event))"
      >
      <div class="shrink-0 bg-gray-50 flex items-center justify-center px-3 text-sm text-gray-600 border border-gray-300 rounded-r">
        cm
      </div>
    </div>

    <div
      v-if="currentUnit == 'imperial'"
      class="flex space-x-2"
    >
      <div class="flex flex-1">
        <input
          :value="feet"
          type="number"
          :name="name"
          :disabled="disabled"
          placeholder="5"
          :required="required"
          :step="1"
          :min="minFoot ? minFoot : undefined"
          :max="maxFoot ? maxFoot : undefined"
          class="grow border-r-0 rounded-l"
          :class="[{
            'border-red-600': hasError(),
            'border-gray-300': !hasError(),
          }, inputClass]"
          @input="updateFoot"
          @blur="onFootBlur"
        >
        <div class="shrink-0 bg-gray-50 flex items-center justify-center px-3 text-sm text-gray-600 border border-gray-300 rounded-r">
          {{ $t('feet') }}
        </div>
      </div>

      <div class="flex flex-1">
        <input
          :value="inches"
          type="number"
          :name="name"
          :disabled="disabled"
          placeholder="6"
          :required="required"
          :min="0"
          :max="11"
          class="grow border-r-0 rounded-l"
          :class="[{
            'border-red-600': hasError(),
            'border-gray-300': !hasError(),
          }, inputClass]"
          @input="updateInch"
          @blur="onInchBlur"
        >
        <div class="shrink-0 bg-gray-50 flex items-center justify-center px-3 text-sm text-gray-600 border border-gray-300 rounded-r">
          {{ $t('inches') }}
        </div>
      </div>
    </div>

    <p
      v-if="hasError()"
      class="form-input-error mt-1"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";
import { convertLength, LengthUnits } from "@/services/units";
import { get, toNumber } from "lodash-es";

export default defineComponent({
  name: "VInputSize",
  components: { InputLabel },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [Number,String,null] as PropType<number|string|null>,
    },
    type: {
      type: String,
      default: "text"
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    }
  },
  data () {
    return {
      currentUnit: "metric",
    };
  },
  computed: {
    units() {
      return [
        {label: `${this.$t("metric")} (cm)`, value: "metric"},
        {label: `${this.$t("imperial")} (${this.$t("inches").toLowerCase()})`, value: "imperial"},
      ];
    },
    normalizedModelValue() : number|null {
      let normalized = toNumber(this.modelValue);
      if (isNaN(normalized)) {
        return null;
      }
      return normalized;
    },
    feet() : number|null {
      if (this.normalizedModelValue === null) {
        return null;
      }

      return Math.floor(this.$display.centimeterToFoot(this.normalizedModelValue));
    },
    inches() : number|null {
      if (this.normalizedModelValue === null) {
        return null;
      }
      return this.$display.centimeterToInchRemain(this.normalizedModelValue);
    },
    minFoot() : number|null {
      if (!this.min) {
        return null;
      }
      return Math.floor(this.$display.centimeterToFoot(this.min));
    },
    maxFoot() : number|null {
      if (!this.max) {
        return null;
      }
      return Math.floor(this.$display.centimeterToFoot(this.max));
    },
    maxInch() : number {
      return 11;
    }
  },
  methods: {
    updateFoot(event: Event | null) {
      const value = this.transformInputValue(event);

      if (value === null || (this.maxFoot && value > this.maxFoot)) {
        return;
      }

      this.udpateImperialToMetric(value, this.inches ?? 0);
    },
    updateInch(event: Event | null) {
      const value = this.transformInputValue(event);

      if (value === null || value > 11) {
        return;
      }

      this.udpateImperialToMetric(this.feet ?? 0, value);
    },
    onFootBlur(event: Event|null) {
      const value = this.transformInputValue(event);

      if (value === null) {
        this.udpateImperialToMetric(0, this.inches ?? 0);
        this.$forceUpdate();
      }
    },
    onInchBlur(event: Event|null) {
      const value = this.transformInputValue(event);

      if (value === null) {
        this.udpateImperialToMetric(this.feet ?? 0, 0);
        this.$forceUpdate();
      }
    },
    udpateImperialToMetric(feet: number, inches: number) {
      const feetToCm = convertLength(feet, LengthUnits.foot, LengthUnits.centimeter);
      const inchesToCm = convertLength(inches, LengthUnits.inch, LengthUnits.centimeter);

      const newValue = Math.round(feetToCm + inchesToCm);

      this.inputListener(newValue);
    },
    transformInputValue(event: Event | null): number | null {
      if (event === null) {
        return null;
      }

      const value = get(event, "target.value", null);

      if (value === null || value === "") {
        return null;
      }

      if (isNaN(toNumber(value))) {
        return null;
      }

      return value as number;
    }
  }
});
</script>
