import i18n from "@/plugins/i18n";
import { round } from "lodash-es";
import { DateTime } from "luxon";
import { convertLength, LengthUnits, LengthUnitsAbbreviations } from "./units";

const SEPARATOR = "-";

export class Display {
  length(length: number, unit: LengthUnits, precision = 0): string {

    if (LengthUnits.foot && precision == 1) {
      const feet = Math.floor(this.centimeterToFoot(length));
      const inches = this.centimeterToInchRemain(length);
      return feet + LengthUnitsAbbreviations.foot + "\xa0" + inches + LengthUnitsAbbreviations.inch;
    }

    const value = round(length, precision);
    return value + "\xa0" + LengthUnitsAbbreviations[unit];
  }

  kilometerToMile(length: number, precision = 0) {
    return this.length(convertLength(length, LengthUnits.kilometer, LengthUnits.mile), LengthUnits.mile, precision);
  }

  centimeterToInch(length: number): number {
    return +convertLength(length, LengthUnits.centimeter, LengthUnits.inch).toFixed(0);
  }

  centimeterToFoot(length: number): number {
    const lengthInInches = this.centimeterToInch(length);
    return +lengthInInches / 12;
  }

  centimeterToInchRemain(length: number): number {
    const lengthInInches = this.centimeterToInch(length);
    return +lengthInInches % 12;
  }

  centimeterToFootAndInches(length: number) {
    return this.length(length, LengthUnits.foot, 1);
  }

  distance(distance: number, max = 0): string {
    if (max && distance > max) {
      return "+ " + this.length(max, LengthUnits.kilometer, 0);
    }

    return this.length(distance, LengthUnits.kilometer, 0);
  }

  publicDistance(distance: number): string {
    // Never show less than 3 km
    distance = Math.max(3, distance);

    return this.distance(distance, 200);
  }

  height(height: number): string {
    return `${this.length(height, LengthUnits.centimeter, 0)}\xa0${SEPARATOR}\xa0${this.centimeterToFootAndInches(height)}`;
  }

  connectionInterval(dateString: string): string {
    const date = DateTime.fromISO(dateString);
    const hours = Math.floor(Math.abs(date.diffNow().as("hours")));

    const days = Math.floor(hours / 24);

    if (hours <= 24) {
      return i18n.global.t("less_than_24h");
    }

    if (days > 7) {
      return i18n.global.t("more_than_a_week");
    }

    return i18n.global.tc("n_days", days);
  }

  fileSize(size: number): string {
    const i = Math.floor(Math.log(size) / Math.log(1024));

    const units = [
      i18n.global.t("units.b"),
      i18n.global.t("units.kb"),
      i18n.global.t("units.mb"),
      i18n.global.t("units.gb"),
      i18n.global.t("units.tb"),
    ];

    return (+(size / Math.pow(1024, i)).toFixed(2)) * 1 + " " + units[i];
  }

  cantSendFlowerMessage(code: string | null | undefined): string {
    let message = i18n.global.t("you_cant_offer_a_flower");

    if (code == "ALREADY_SENT_FLOWER_TODAY") {
      message = i18n.global.t("you_already_sent_a_flower_today");
    }

    if (code == "ALREADY_SENT_FLOWER_IN_CONVERSATION") {
      message = i18n.global.t("you_already_sent_a_flower_in_conversation");
    }

    return message;
  }

  list(items: string[], conjunction: "and" | "or"): string {
    let text = "";

    items.forEach((item, i) => {

      if (i > 0) {

        if (i >= items.length - 1) {
          text += " " + i18n.global.t(conjunction) + " ";
        } else {
          text += ", ";
        }
      }

      text += item;
    });

    return text;
  }
}
