import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import PageLogin from "@/views/auth/PageLogin.vue";
import PageForgotPassword from "@/views/auth/PageForgotPassword.vue";
import PageResetPassword from "@/views/auth/PageResetPassword.vue";

import OnboardingLayout from "@/views/onboarding/OnboardingLayout.vue";
import PageOnboardingHome from "@/views/onboarding/PageHome.vue";
import PageOnboardingStart from "@/views/onboarding/PageStart.vue";
import PageOnboardingRegister from "@/views/onboarding/PageRegister.vue";
import PageOnboardingTerms from "@/views/onboarding/PageTerms.vue";
import PageOnboardingStep from "@/views/onboarding/PageStep.vue";
import PageOnboardingSuccess from "@/views/onboarding/PageSuccess.vue";

import MemberLayout from "@/views/member/MemberLayout.vue";
import PageSearch from "@/views/member/search/PageSearch.vue";
import PageProfile from "@/views/member/profile/PageProfile.vue";
import PageFavorites from "@/views/member/favorites/PageFavorites.vue";
import PageMessages from "@/views/member/messages/PageMessages.vue";
import BaseConversation from "@/views/member/messages/components/BaseConversation.vue";
import BaseVideoChat from "@/views/member/messages/videoChat/BaseVideoChat.vue";
import PageArticles from "@/views/member/clips/PageArticles.vue";
import PageArticlesVideo from "@/views/member/clips/PageArticlesVideo.vue";
import PageArticlesArticle from "@/views/member/clips/PageArticlesArticle.vue";
import PageArticlesBonus from "@/views/member/clips/PageArticlesBonus.vue";
import PageArticle from "@/views/member/clips/PageArticle.vue";

import PageSubscription from "@/views/member/subscription/PageSubscription.vue";
import PagePaymentSource from "@/views/member/subscription/PagePaymentSource.vue";
import PagePaymentSourceUpdate from "@/views/member/subscription/PagePaymentSourceUpdate.vue";

import PageSettings from "@/views/member/settings/PageSettings.vue";
import PageDailyQuote from "@/views/member/settings/pages/PageDailyQuote.vue";
import PageAddress from "@/views/member/settings/pages/PageAddress.vue";
import PageSettingsProfile from "@/views/member/settings/pages/profile/PageProfile.vue";
import PagePause from "@/views/member/settings/pages/PagePause.vue";
import PageEmail from "@/views/member/settings/pages/PageEmail.vue";
import PagePassword from "@/views/member/settings/pages/PagePassword.vue";
import PageLanguage from "@/views/member/settings/pages/PageLanguage.vue";
import PageAnonymousMode from "@/views/member/settings/pages/PageAnonymousMode.vue";
import PageVisibility from "@/views/member/settings/pages/PageVisibility.vue";
import PageNotifications from "@/views/member/settings/pages/PageNotifications.vue";
import PagePictures from "@/views/member/settings/pages/PagePictures.vue";
import PageSamplePicture from "@/views/member/settings/pages/PageSamplePicture.vue";
import PageResources from "@/views/member/settings/pages/PageResources.vue";
import BlockedUsers from "@/views/member/settings/pages/blocks/BlockedUsers.vue";
import PageSecondaryConversationRules from "@/views/member/settings/pages/secondaryConversationRules/PageSecondaryConversationRules.vue";

import MainAmbassadorLayout from "@/views/ambassador/MainAmbassadorLayout.vue";
import AmbassadorLayout from "@/views/ambassador/AmbassadorLayout.vue";
import PageAmbassador from "@/views/ambassador/PageAmbassador.vue";
import PageAmbassadorShare from "@/views/ambassador/PageAmbassadorShare.vue";
import PageAmbassadorRewards from "@/views/ambassador/PageAmbassadorRewards.vue";
import PageAmbassadorMediaKit from "@/views/ambassador/PageAmbassadorMediaKit.vue";
import PageAmbassadorSettings from "@/views/ambassador/PageAmbassadorSettings.vue";
import PageMember from "@/views/share/PageMember.vue";

import PageBlocked from "@/views/PageBlocked.vue";
import { authenticated, blocked, guest, isAmbassador, isMember, notBlocked, onboardingAuthGuard, onboardingCompleted, onboardingGuestGuard } from "./guards";
import { store } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect(to) {

      if (!store.state.user) {
        return { path: "/login", query: to.query };
      }

      // Ambassador only
      if (store.state.user.ambassador && !store.state.user.member) {
        return { path: "/ambassador", query: to.query };
      }

      // Ambassador, but Member not completed
      if (store.state.user.ambassador && store.state.user.member && !store.state.user.member.onboarding_completed) {
        return { path: "/ambassador", query: to.query };
      }

      // Member, completed or not
      if (store.state.user.member) {
        return { path: "/member/search", query: to.query };
      }

      const url = import.meta.env.VITE_WEBSITE_URL ?? "https://effettandem.com";

      window.location.href = url;

      return { path: url };
    },
  },
  {
    path: "/blocked",
    name: "blocked",
    beforeEnter: [authenticated, blocked],
    component: PageBlocked,
  },
  {
    path: "/login",
    name: "login",
    beforeEnter: [guest],
    component: PageLogin,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    beforeEnter: [guest],
    component: PageForgotPassword,
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    beforeEnter: [guest],
    component: PageResetPassword,
  },
  {
    path: "/onboarding",
    name: "onboarding",
    redirect: "/onboarding/home",
  },
  {
    path: "/onboarding/home",
    name: "onboarding.home",
    component: PageOnboardingHome,
    beforeEnter: [onboardingGuestGuard],
  },
  {
    path: "/onboarding/start",
    name: "onboarding.start",
    component: PageOnboardingStart,
    beforeEnter: [onboardingGuestGuard],
  },
  {
    path: "/onboarding/register",
    name: "onboarding.register",
    component: PageOnboardingRegister,
    beforeEnter: [onboardingGuestGuard],
  },
  {
    path: "/onboarding/terms",
    name: "onboarding.terms",
    component: PageOnboardingTerms,
    beforeEnter: [notBlocked, onboardingAuthGuard],
  },
  {
    path: "/onboarding/step/:step",
    name: "onboarding.step",
    component: PageOnboardingStep,
    beforeEnter: [notBlocked, onboardingAuthGuard],
  },
  {
    path: "/onboarding/success",
    name: "onboarding.success",
    component: PageOnboardingSuccess,
    beforeEnter: [notBlocked, onboardingCompleted],
  },
  {
    path: "/onboarding/:catchAll(.*)",
    redirect: {
      name: "onboarding.home"
    },
  },
  {
    path: "/member",
    name: "member",
    component: MemberLayout,
    beforeEnter: [authenticated, isMember, notBlocked, onboardingCompleted],
    redirect: {
      name: "member.search"
    },
    children: [
      {
        path: "search",
        name: "member.search",
        component: PageSearch,
      },
      {
        path: "profile/:member",
        name: "member.profile",
        component: PageProfile,
      },
      {
        path: "favorites",
        name: "member.favorites",
        component: PageFavorites,
      },
      {
        path: "messages",
        name: "member.messages",
        component: PageMessages,
        children: [
          {
            path: ":conversation",
            name: "member.messages.conversation",
            component: BaseConversation,
          },
        ]
      },
      {
        path: "messages/:conversation/video-chat",
        name: "member.messages.conversation.video-chat",
        component: BaseVideoChat,
      },
      {
        path: "personal-development",
        name: "member.articles",
        component: PageArticles,
      },
      {
        path: "personal-development/video",
        name: "member.articles.video",
        component: PageArticlesVideo,
      },
      {
        path: "personal-development/article",
        name: "member.articles.article",
        component: PageArticlesArticle,
      },
      {
        path: "personal-development/bonus",
        name: "member.articles.bonus",
        component: PageArticlesBonus,
      },
      {
        path: "personal-development/:article",
        name: "member.articles.show",
        component: PageArticle,
      },
      {
        path: "subscription",
        name: "member.subscription",
        component: PageSubscription,
      },
      {
        path: "settings",
        name: "member.settings",
        component: PageSettings,
      },
      {
        path: "settings/daily-quote",
        name: "member.settings.daily_quote",
        component: PageDailyQuote,
      },
      {
        path: "settings/payment-source",
        name: "member.settings.payment_source",
        component: PagePaymentSource,
      },
      {
        path: "settings/payment-source/update",
        name: "member.settings.payment_source.update",
        component: PagePaymentSourceUpdate,
      },
      {
        path: "settings/secondary-conversation-rules",
        name: "member.settings.secondary_conversation_rules",
        component: PageSecondaryConversationRules,
      },
      {
        path: "settings/address",
        name: "member.settings.address",
        component: PageAddress,
      },
      {
        path: "settings/profile",
        name: "member.settings.profile",
        component: PageSettingsProfile,
      },
      {
        path: "settings/pause",
        name: "member.settings.pause",
        component: PagePause,
      },
      {
        path: "settings/email",
        name: "member.settings.email",
        component: PageEmail,
      },
      {
        path: "settings/password",
        name: "member.settings.password",
        component: PagePassword,
      },
      {
        path: "settings/language",
        name: "member.settings.language",
        component: PageLanguage,
      },
      {
        path: "settings/anonymous-mode",
        name: "member.settings.anonymous_mode",
        component: PageAnonymousMode,
      },
      {
        path: "settings/visibility",
        name: "member.settings.visibility",
        component: PageVisibility,
      },
      {
        path: "settings/notifications",
        name: "member.settings.notifications",
        component: PageNotifications,
      },
      {
        path: "settings/pictures",
        name: "member.settings.pictures",
        component: PagePictures,
      },
      {
        path: "settings/sample-pictures",
        name: "member.settings.sample_picture",
        component: PageSamplePicture,
      },
      {
        path: "settings/resources",
        name: "member.settings.resources",
        component: PageResources,
      },
      {
        path: "settings/blocked-users",
        name: "member.settings.blocked_users",
        component: BlockedUsers,
      }
    ]
  },
  {
    path: "/ambassador",
    name: "ambassador",
    component: MainAmbassadorLayout,
    beforeEnter: [authenticated, isAmbassador],
    children: [
      {
        path: "",
        component: AmbassadorLayout,
        children: [
          {
            path: "",
            name: "ambassador.index",
            component: PageAmbassador,
          },
          {
            path: "media-kit",
            name: "ambassador.media-kit",
            component: PageAmbassadorMediaKit,
          },
          {
            path: "sharing-tools",
            name: "ambassador.share",
            component: PageAmbassadorShare,
          },
          {
            path: "rewards",
            name: "ambassador.rewards",
            component: PageAmbassadorRewards,
          },
          {
            path: "settings",
            name: "ambassador.settings",
            component: PageAmbassadorSettings,
          },
        ]
      },
    ]
  },
  {
    path: "/share/member/:id",
    name: "share.member",
    component: PageMember
  }
];

const scrollPositions = {
  "member.search": 0,
  "member.settings": 0,
  "member.messages": 0,
  "member.articles": 0,
  "member.articles.video": 0,
  "member.articles.article": 0,
  "member.articles.bonus": 0,
} as { [key: string]: number };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {

    const toName = to.name?.toString() ?? "";

    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    if (scrollPositions[toName] !== undefined) {
      return {
        top: scrollPositions[toName],
        left: 0,
      };
    }

    return { top: 0, left: 0 };
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach(async (to, from, next) => {

  if (!store.state.initialized) {

    await store.dispatch("init");

  }

  let ambassadorCode = to.query.ambassador ?? null;

  if (to.query.reference) {
    ambassadorCode = to.query.reference;
  }

  if (!store.state.ambassadorCodeReferral && ambassadorCode) {
    store.commit("ambassadorCodeReferral", ambassadorCode);
  }

  await store.dispatch("saveReferral");

  const fromName = from.name?.toString() ?? "";

  if (scrollPositions[fromName] !== undefined) {
    scrollPositions[fromName] = window.scrollY;
  }

  next();
});

export default router;
