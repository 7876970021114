import { Capacitor } from "@capacitor/core";
import { AxiosResponse } from "axios";
import { api } from "./api";
import { getDeviceReference } from "./deviceReference";

async function fetchNativeProducts(): Promise<AxiosResponse<any, any>> {
  const params = {
    device_ref: await getDeviceReference(),
    platform: Capacitor.getPlatform(),
  } as Record<string, string | string[]>;

  return api.get("products/native", {
    params: params
  });
}

type ProductNativeKey = "google_id" | "apple_id";

function getProductNativeKey(): ProductNativeKey {
  let queryKey = "google_id" as ProductNativeKey;
  if (Capacitor.getPlatform() == "ios") {
    queryKey = "apple_id";
  }
  return queryKey;
}

export { fetchNativeProducts, getProductNativeKey };
