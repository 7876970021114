<template>
  <AuthLayout back-url="/login">
    <div>
      <h1 class="font-display mt-6 text-3xl font-bold leading-none text-gray-900">
        {{ $t('page.reset_password.title') }}
      </h1>
    </div>

    <div class="mt-6">
      <VForm
        method="post"
        :data="form"
        :url="url"
        :http-client="httpClient"
        loading-mask-class="bg-white"
        @success="onSuccess"
      >
        <div class="mt-8" />

        <div class="mb-3">
          <VInput
            id="email"
            v-model="form.email"
            :label="$t('email')"
            name="email"
            type="email"
            required
            input-class="w-full"
          />
        </div>

        <div class="mb-3">
          <VInputPassword

            v-model="form.password"
            name="password"
            :label="$t('new_password')"
            required
            input-class="w-full"
          />
        </div>

        <div class="mb-3">
          <VInputPassword
            v-model="form.password_confirmation"
            name="password_confirmation"
            :label="$t('password_confirmation')"
            required
            input-class="w-full"
          />
        </div>

        <div class="mt-4">
          <BaseButton
            type="submit"
            class="btn-primary btn-lg w-full mb-0"
          >
            {{ $t('reset_password') }}
          </BaseButton>
        </div>

        <div class="mt-4">
          <router-link
            class="text-base text-gray-600 underline"
            to="/login"
          >
            {{ $t('return_to_login') }}
          </router-link>
        </div>
      </VForm>
    </div>
  </AuthLayout>
</template>

<script lang="ts">
import { getRoute } from "@/services/utils";
import { defineComponent } from "vue";
import AuthLayout from "./AuthLayout.vue";
import InputLabel from "@/plugins/form/components/utils/InputLabel.vue";
import { RouteLocationRaw } from "vue-router";
import axios from "axios";
import { sendLocale, showNotificationOnError } from "@/services/api";

const httpClient = axios.create({
  withCredentials: true,
});

httpClient.interceptors.request.use(sendLocale);
httpClient.interceptors.response.use(response => response, showNotificationOnError);

export default defineComponent({
  components: {
    AuthLayout,
    InputLabel
  },
  emits: ["loaded"],
  data () {
    return {
      httpClient,
      form: {
        email: this.$route.query.email,
        password: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    url (): string {
      return getRoute("reset-password") + "?token=" + this.$route.params.token;
    }
  },
  created () {
    this.$emit("loaded");
  },
  methods: {
    async onSuccess() {
      await this.$auth.login({
        email: this.form.email,
        password: this.form.password,
      });

      let redirect = {name: "home"} as RouteLocationRaw;

      if (this.$route.query.redirect) {
        redirect = this.$route.query.redirect as string;
      }

      this.$router.push(redirect);
    }
  }
});
</script>
