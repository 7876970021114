import { Account } from "@/models/Account";
import { api_, http } from "@/services/api";
import { getRoute } from "@/services/utils";
import { State, store } from "@/store";
import localStore from "./localStore";
import router from "@/router";
import { get, isString } from "lodash-es";
import { tokenStore } from "./tokenStore";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Storage } from "@capacitor/storage";

export enum AuthStrategy {
  session = "session",
  token = "token",
}

class Auth {

  public token: string | null = null;

  async init() {
    let loggedIn = await localStore.getLoggedIn();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (this.strategy == AuthStrategy.session && params.authenticated_session) {
      await this.setLoggedIn();
      loggedIn = true;
    }

    store.commit("auth/loggedIn", loggedIn);
  }

  get state(): State {
    return store.state;
  }

  get user(): Account | null {
    return this.state.user;
  }

  get strategy(): AuthStrategy {
    if (Capacitor.isNativePlatform()) {
      return AuthStrategy.token;
    }

    if (import.meta.env.VITE_AUTH_STRATEGY == AuthStrategy.token) {
      return AuthStrategy.token;
    }

    return AuthStrategy.session;
  }

  check(): boolean {
    return this.state.auth.loggedIn;
  }

  guest(): boolean {
    return !this.check();
  }

  async login(payload: any): Promise<any> {
    try {

      const route = (this.strategy == AuthStrategy.token) ? "api/app/token" : "api/app/login";

      if (this.strategy == AuthStrategy.token) {
        const info = await Device.getInfo();
        const deviceName = (info.name ?? "default") as string;
        payload.device_name = deviceName;
      }

      const response = await http.post(getRoute(route), payload);

      const token = get(response, "data.token");

      await this.onLoginSuccess(token);

      return response;
    } catch (e: any) {
      this.setLoggedOut(false);
      throw e;
    }
  }

  async logout(): Promise<void> {
    if (this.strategy == "session") {
      await http.post(getRoute("api/app/logout"));
    }
    await this.setLoggedOut(true);
  }

  public async onLoginSuccess(token: string) {
    try {

      // Set token
      if (this.strategy == AuthStrategy.token) {
        if (token == null) {
          throw new Error("No token was returned");
        }
        await this.setToken(token);
      }

      // Set logged In
      await this.setLoggedIn();

      // Fetch user in store
      await store.dispatch("fetchUser");

      const pushNotificationToken = await Storage.get({
        key: "push_notification_token"
      });

      if (pushNotificationToken.value)
        api_.post("account/push-notification-tokens", {
          token: pushNotificationToken.value
        });

    } catch (e: any) {
      await this.setLoggedOut(true);
      throw e;
    }
  }

  private async setLoggedIn() {
    store.commit("auth/loggedIn", true);

    await localStore.setLoggedIn(true);
  }

  public async setLoggedOut(redirect = false) {
    store.commit("auth/loggedIn", false);
    store.commit("setUser", null);
    store.commit("notification/clear");

    await localStore.setLoggedIn(false);

    await this.clearToken();

    if (redirect) {
      this.redirectToLogin();
    }
  }

  private async setToken(token: string) {
    await tokenStore.set(token);
  }

  private async clearToken() {
    await tokenStore.clear();
  }

  public redirectToLogin() {

    const currentRoute = router.currentRoute.value;

    let redirect = undefined;

    if (!currentRoute.path.includes("login")) {
      redirect = currentRoute.path;
    }

    if (isString(currentRoute.query.redirect)) {
      redirect = currentRoute.query.redirect;
    }

    router.push({
      name: "login",
      query: {
        redirect: redirect
      }
    });
  }
}

const auth = new Auth;

export { Auth, auth };
