<template>
  <BaseModal
    :model-value="modelValue"
    width="max-w-sm w-full"
    @update:model-value="onModelValueUpdate"
  >
    <div class="rounded-3xl relative p-6 overflow-hidden">
      <h2 class="mb-3 text-lg font-semibold">
        {{ $t('add_a_promo_code') }}
      </h2>

      <form
        @submit.prevent="validatePromoCode()"
      >
        <input
          v-model="promoCode"
          type="text"
          name="promo_code"
          :placeholder="$t('add_a_promo_code')"
          class="w-full bg-white border-gray-300 rounded"
        >
        <p
          v-if="promoCodeNotFound"
          class="text-sm font-medium text-red-600"
        >
          {{ $t('invalid_promo_code') }}
        </p>

        <button
          type="submit"
          class="btn btn-primary mt-5"
        >
          {{ $t('apply') }}
        </button>
      </form>

      <BaseLoadingCover v-model="loading" />
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { Product } from "@/models/Product";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean
    },
    product: {
      required: true,
      type: Object as PropType<Product>
    },
  },
  emits: ["update:modelValue", "success", "fail"],
  data() {
    return {
      promoCode: "",
      promoCodeNotFound: false,
      loading: false,
    };
  },
  methods: {
    validatePromoCode() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.promoCodeNotFound = false;

      this.$api.get("promo-codes/test", {
        params: {
          product_id: this.product.id,
          code: this.promoCode,
        }
      })
        .then(response => {
          const data = response.data;

          if (data.valid) {
            this.$emit("success", data.promo_code);
            this.close();
          } else {
            this.$emit("fail");
            this.promoCodeNotFound = true;
          }
        })
        .catch(() => {
          this.$emit("fail");
          this.promoCodeNotFound = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onModelValueUpdate(value: boolean) {
      if (value == false) {
        this.promoCode = "";
      }
      this.$emit("update:modelValue", value);
    },
    close() {
      this.promoCode = "";
      this.$emit("update:modelValue", false);
    }
  },
});
</script>
