<template>
  <BaseContainer size="4xl">
    <div class="flex items-center justify-between mb-5">
      <h2 class="text-xl font-medium leading-tight">
        {{ $t('pages.ambassador.balance.claim') }}
      </h2>
    </div>
  </BaseContainer>

  <BaseContainer
    size="4xl"
    paddingless-mobile
  >
    <BaseCard>
      <table class="table w-full">
        <tr>
          <td>
            <p class="text-lg font-semibold">
              {{ $t('account_balance') }}
            </p>
          </td>
          <td>
            <p class="text-lg font-medium">
              {{ balance.balance.priceFormat() }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="font-semibold">
              {{ $t('pending') }}
            </p>
          </td>
          <td>
            {{ balance.pending.priceFormat() }}
          </td>
        </tr>
        <tr>
          <td class="px-3 py-2">
            <p class="font-semibold">
              {{ $t('paid') }}
            </p>
          </td>
          <td class="px-3 py-2 text-gray-600">
            {{ balance.paid.priceFormat() }}
          </td>
        </tr>
      </table>
    </BaseCard>
  </BaseContainer>

  <AmbassadorBalanceClaim
    :balance="balance.balance"
    @success="fetch()"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AmbassadorBalanceClaim from "./AmbassadorBalanceClaim.vue";

export default defineComponent({
  components: {
    AmbassadorBalanceClaim,
  },
  data () {
    return {
      loading: false,
      balance: {
        total: 0,
        paid: 0,
        pending: 0,
        balance: 0
      }
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.get("account/ambassador/balance")
        .then(response => {
          this.balance = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
