<template>
  <MessageTimestamp
    v-if="showTimestamp"
    :timestamp="createdAt"
  />

  <div
    class="flex flex-col"
    :class="{
      'items-end': message.is_me,
      'items-start': !message.is_me,
    }"
  >
    <div
      v-if="message.content"
      class="inline-block px-3 py-2"
      :class="[boxClasses + ' ' + maxWithClasses, messageSpacingClasses]"
    >
      <div class="conversation-message leading-snug whitespace-pre-line">
        <p
          v-if="isAdmin"
        >
          <component
            :is="transformedMessageComponent"
          />
        </p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p
          v-else
          v-html="message.content"
        />
      </div>
    </div>

    <div
      v-if="message.audio"
      class="inline-block"
      :class="[maxWithClasses, messageSpacingClasses]"
    >
      <div class="leading-snug whitespace-pre-line">
        <audio
          controls
          controlsList="nodownload noremoteplayback nofullscreen"
        >
          <source
            :src="message.audio"
            type="audio/mp3"
          >
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>

    <div
      v-if="message.image_path"
      class="inline-block my-0.5"
      :class="[maxWithClasses, messageSpacingClasses]"
    >
      <img
        :src="imageSrc"
        alt="image"
        class="block rounded-lg"
        @load="scrollBottom"
      >
    </div>

    <div
      v-if="message.flower && !message.flower_match"
      class="flex flex-col items-center w-full my-10"
    >
      <div class="flower shrink-0 md:w-24 w-20 mb-2">
        <FlowerIllustration
          size="large"
          :gender="message.is_me ? ($store.state.user?.gender ?? '') : user.gender"
          @load="scrollBottom"
        />
      </div>

      <p class="flower-text text-xl font-medium tracking-tight text-center text-gray-700">
        <span v-if="message.is_me">
          {{ $t('chat.sent_flower.title', {user: user.first_name}) }}
        </span>
        <span v-else>
          {{ $t('chat.received_flower.title', {user: user.first_name}) }}
        </span>
      </p>
    </div>

    <template
      v-if="message.flower && message.flower_match"
    >
      <FlowerMatch class="mt-8 mb-6" />
    </template>
  </div>
</template>

<script lang="ts">

import { ConversationMessage } from "@/models/ConversationMessage";
import { defineComponent, inject, PropType } from "vue";
import { Conversation, ConversationUser } from "@/models/Conversation";
import FlowerMatch from "./FlowerMatch.vue";
import FlowerIllustration from "@/plugins/icons/FlowerIllustration.vue";
import MessageTimestamp from "./MessageTimestamp.vue";
import { DateTime } from "luxon";

export default defineComponent({
  components: {
    FlowerMatch,
    FlowerIllustration,
    MessageTimestamp,
  },
  props: {
    message: {
      required: true,
      type: Object as PropType<ConversationMessage>
    },
    previousMessage: {
      default: null,
      type: [Object,null] as PropType<ConversationMessage|null>
    }
  },
  setup () {
    const mobile = inject("mobile") as boolean;
    const user = inject("user") as ConversationUser;
    const me = inject("me") as ConversationUser;
    const conversation = inject("conversation") as Conversation;
    const scrollBottom = inject("scrollBottom") as () => void;
    return {
      mobile,
      user,
      me,
      conversation,
      scrollBottom,
    };
  },
  computed: {
    isAdmin () :boolean {
      if (this.message.is_me) {
        return this.me.admin;
      }

      return this.user.admin;
    },
    boxClasses() : string {
      let classes = "rounded-xl ";

      if (this.conversation.flower_match) {
        classes += " shadow ";
      }

      if (this.message.is_me) {
        return classes += "bg-primary-500 text-white";
      }

      if (this.conversation.flower_match) {
        return classes += "bg-white text-gray-900";
      }

      return classes += "bg-gray-100 text-gray-900";
    },
    maxWithClasses () : string {
      return "lg:max-w-lg md:max-w-md sm:max-w-lg max-w-xs";
    },
    transformedMessageComponent() {
      return {
        template: this.transformedMessage,
      };
    },
    transformedMessage() : string {
      // eslint-disable-next-line no-control-regex
      return this.message.content.replace(new RegExp("\r?\n","g"), "<br />");
    },
    imageSrc() : string {
      if (!this.message.image_path) {
        return "";
      }
      if (this.message.image_path.startsWith("data:")) {
        return this.message.image_path;
      }
      return this.$assetUrl(this.message.image_path, {resize: {width: 800, height: 800, fit: "inside"}});
    },
    createdAt() : DateTime {
      return this.$luxon.fromISO(this.message.created_at);
    },
    previousCreatedAt() : DateTime|null {
      if (!this.previousMessage) {
        return null;
      }
      return this.$luxon.fromISO(this.previousMessage.created_at);
    },
    previousMessageSecondsDiff() : number {
      if (this.previousCreatedAt == null) {
        return 0;
      }

      return this.createdAt.diff(this.previousCreatedAt, "seconds").seconds;
    },
    showTimestamp() : boolean {

      if (this.previousCreatedAt == null) {
        return true;
      }

      if (this.previousMessageSecondsDiff < (60*60)) {
        return false;
      }

      return true;
    },
    messageSpacingClasses() : string {

      const smallSpace = "mt-0.5";
      const largeSpace = "mt-2";

      // If not same person
      if (this.previousMessage && this.previousMessage.is_me != this.message.is_me) {
        return largeSpace;
      }

      if (this.previousMessageSecondsDiff < (90)) {
        return smallSpace;
      }

      return largeSpace;
    }
  }
});
</script>

<style lang="scss" scoped>

@keyframes rotate-in-2-fwd-cw {
  0% {
    transform: perspective(300px) translateZ(-200px) rotate(-20deg);
    opacity: 0;
  }
  100% {
    transform: perspective(300px) translateZ(0) rotate(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.flower {
  animation: rotate-in-2-fwd-cw 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.2s;
}

.flower-text {
  animation: fade-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.5s;
}
</style>


<style lang="scss">
.conversation-message {
  a {
    @apply underline;
  }
}
</style>
