<template>
  <VForm
    method="patch"
    url="/account/sexual-orientation"
    :data="form"
    @success="onSuccess"
  >
    <VInputMultiSelect
      v-model="form.sexual_orientations"
      name="sexual_orientations"
      input-class="w-full"
      :required="true"
      :options="sexualOrientations"
      label-key="label"
      value-key="value"
      wrapper-class="-m-1"
      item-class="grow xs:w-auto w-full p-1"
      button-class="btn-lg w-full shadow"
      :min="1"
    />

    <button
      type="submit"
      class="btn sm:w-auto btn-primary btn-lg w-full mt-6"
    >
      {{ $t('save') }}
    </button>
  </VForm>
</template>

<script lang="ts">
import { Account } from "@/models/Account";
import { Option } from "@/plugins/form/types/types";
import i18n from "@/plugins/i18n";
import { cloneDeep } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  setup () {
    const sexualOrientations = [
      {data: {value: "man", label: i18n.global.t("men")}},
      {data: {value: "woman", label: i18n.global.t("women")}},
      {data: {value: "non_binary", label: i18n.global.t("non_binaries")}},
    ] as Option[];

    return {
      sexualOrientations
    };
  },
  data () {
    return {
      loading: false,
      form: {
        sexual_orientations: this.$store.state.user?.sexual_orientations ?? [],
      }
    };
  },
  methods: {
    onSuccess () {
      const user = cloneDeep(this.$store.state.user) as Account;

      user.sexual_orientations = this.form.sexual_orientations;

      this.$store.commit("setUser", user);
    }
  }
});
</script>
