<template>
  <div class="relative">
    <OnboardingTitle>
      {{ $t('step.audio_introduction.title') }}
    </OnboardingTitle>

    <OnboardingText class="mb-8">
      {{ $t('step.audio_introduction.text') }}
    </OnboardingText>

    <AudioIntroduction>
      <template #footer="{deleteAudio}">
        <div class="mt-8">
          <StepButtons
            :skippable="true"
            @skip="deleteAudio(); nextStep();"
            @previous="previousStep"
            @next="nextStep"
          />
        </div>
      </template>
    </AudioIntroduction>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import AudioIntroduction from "@/views/member/settings/pages/profile/components/AudioIntroduction.vue";
import OnboardingText from "../components/OnboardingText.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: { OnboardingTitle, StepButtons, AudioIntroduction, OnboardingText },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  }
});
</script>
