<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.preferences.language.title')"
        :subtitle="$t('page.settings.menu.preferences.language.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
    >
      <form
        method="POST"
        @submit.prevent="submit()"
      >
        <select
          id="locale"
          v-model="form.locale"
          name="locale"
          class="form-select sm:px-5 sm:py-5 w-full px-4 py-3 mb-5 text-lg border-gray-300 rounded"
        >
          <option value="fr">
            Français
          </option>
          <option value="en">
            English
          </option>
        </select>

        <BaseButton
          type="submit"
          :loading="loading"
          :disabled="loading"
          class="btn-primary btn-xl"
        >
          {{ $t('save') }}
        </BaseButton>
      </form>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      loading: false,
      form: {
        locale: this.$store.state.user?.locale ?? "en",
      }
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$store.dispatch("updateLanguage", this.form.locale)
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
