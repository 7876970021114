<template>
  <div
    v-show="showProduct"
    ref="item"
    class="flex"
    :class="{
      'pt-0': product.featured,
      'sm:py-3': !product.featured,
    }"
  >
    <div
      class="rounded-2xl relative flex flex-col flex-1 text-center bg-white shadow"
      :class="{
        'border-[3px] border-primary-500 shadow-primary-400 shadow-lg': product.featured,
        'border border-gray-300': !product.featured,
        'pb-8 px-8 pt-14': size == 'lg',
        'pb-4 px-4 pt-12': size == 'sm',
      }"
    >
      <div
        v-if="product.sale_percentage && product.sale_percentage <= 1"
        class="-top-4 absolute"
        :class="{
          'w-20 left-3': size == 'lg',
          'w-[63px] left-2': size == 'sm',
        }"
      >
        <div class="w-full flex justify-center pt-3 pb-1 text-sm font-semibold text-white rounded bg-primary-500 relative z-[1]">
          <div>
            <p
              class="text-2xl leading-none"
              :class="{
                'text-2xl': size == 'lg',
                'text-xl': size == 'sm',
              }"
            >
              {{ product.sale_percentage.percentageFormat() }}
            </p>
            <p class="leading-none">
              {{ $t('discount') }}
            </p>
          </div>
        </div>
        <svg
          viewBox="0 0 50 20"
          xmlns="http://www.w3.org/2000/svg"
          class="text-primary-500 -top-1 relative w-full h-auto"
        >
          <polygon
            style="stroke-linejoin: round; stroke: currentColor; stroke-width: 6; fill: currentColor;"
            points="3,0 47,0 25,10"
            class="triangle"
          />
        </svg>
      </div>

      <div class="flex-1">
        <h3
          class="font-display font-semibold leading-tight text-gray-900"
          :class="{
            'text-3xl': size == 'lg',
            'text-2xl': size == 'sm',
          }"
        >
          <template v-if="platform == 'web'">
            {{ product.name }}
          </template>
          <template v-else>
            {{ nativeTitle }}
          </template>
        </h3>

        <div class="flex justify-center mt-3 space-x-0">
          <img
            v-for="i in realNumberOfMonths"
            :key="i"
            src="@/assets/img/logo/logo-icon-primary-600.svg"
            alt="Logo Effet Tandem"
            :class="{
              'w-8': size == 'lg',
              'w-6': size == 'sm',
            }"
          >
        </div>

        <p
          class="mt-4 leading-tight text-gray-600"
          :class="{
            'text-base': size == 'lg',
            'text-base': size == 'sm',
          }"
        >
          <template v-if="platform == 'web'">
            {{ product.description }}
          </template>
          <template v-else>
            {{ nativeDescription }}
          </template>
        </p>

        <div class="flex items-baseline justify-center mt-6 text-gray-900">
          <template v-if="platform == 'web'">
            <div
              v-if="product.price > 0"
              :class="{
                'text-3xl': size == 'lg',
                'text-2xl': size == 'sm',
              }"
            >
              <span
                class="font-semibold tracking-tight"
              >
                {{ (product.price/realNumberOfMonths).priceFormat() }}
              </span>
              <span
                class="ml-1 font-semibold"
              >/{{ $t('month') }}
              </span>
            </div>
            <p
              v-else
              class="font-semibold tracking-tight"
              :class="{
                'text-3xl': size == 'lg',
                'text-2xl': size == 'sm',
              }"
            >
              {{ $t('free') }}
            </p>
          </template>
          <template v-else>
            <span class="text-2xl font-semibold tracking-tight">{{ nativePrice }}</span>
          </template>
        </div>

        <div
          v-if="platform == 'web' && product.price_before_sale && product.price_before_sale != product.price"
          class="relative inline-block px-2 mx-auto"
        >
          <p
            class="flex items-baseline justify-center font-medium tracking-tight text-red-500"
            :class="{
              'text-2xl': size == 'lg',
              'text-xl': size == 'sm',
            }"
          >
            <span class=""> {{ (product.price_before_sale/realNumberOfMonths).priceFormat() }} </span>
            <span class="">/{{ $t('month') }}</span>
          </p>

          <div class="top-1/2 absolute left-0 -translate-y-1/2">
            <svg
              viewBox="0 0 50 6"
              xmlns="http://www.w3.org/2000/svg"
              class="w-full text-red-500"
            >
              <line
                x1="1"
                y1="1"
                x2="49"
                y2="5"
                stroke="currentColor"
                stroke-width="0.75"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>

        <div
          v-if="product.number_of_extra_months > 0"
          class="text-center"
          :class="{
            'text-lg': size == 'lg',
            'text-md': size == 'sm',
          }"
        >
          <p class="leading-tight text-gray-700 underline">
            + {{ $tc('x_free_months', product.number_of_extra_months) }} !
          </p>
        </div>

        <div
          v-if="product.vip"
          class="mt-4 text-center"
        >
          <p class="text-primary-700 font-semibold leading-tight">
            {{ $t('includes_bonus_course') }}
          </p>
        </div>
      </div>

      <template v-if="platform == 'web'">
        <p
          v-if="product.price > 0"
          class="mt-6 leading-tight text-gray-600"
          :class="{
            'text-base': size == 'lg',
            'text-sm': size == 'sm',
          }"
        >
          {{ $t('one_single_payment_of_x_plus_taxes', {x: product.price.priceFormat()}) }}
        </p>
        <p
          v-else
          class="font-medium leading-tight"
        >
          {{ $t('limited_time_offer') }}!
        </p>
      </template>

      <BaseButton
        class="btn btn-lg w-full mt-6 text-center"
        type="button"
        :class="{
          'btn-primary': product.featured,
          'btn-secondary': !product.featured,
        }"
        @click="select()"
      >
        <span
          v-if="member && ['inactive', 'trial'].includes(member.subscription_status)"
          data-cy="select-product"
        >
          {{ $t('select') }}
        </span>
        <span
          v-else
          data-cy="select-product"
        >
          {{ $t('add_x_months', {x: product.number_of_months}) }}
        </span>
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { AccountMember } from "@/models/Account";
import { Product } from "@/models/Product";
import { Capacitor } from "@capacitor/core";
import { IAPProduct } from "@ionic-native/in-app-purchase-2";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    product: {
      required: true,
      type: Object as PropType<Product>
    }
  },
  emits: ["select"],
  data() {
    return {
      width: 400,
    };
  },
  computed: {
    member () : AccountMember {
      return this.$store.state.user?.member as AccountMember;
    },
    realNumberOfMonths () : number {
      return this.product.number_of_months - this.product.number_of_extra_months;
    },
    size () : string {
      if (this.width < 400) {
        return "sm";
      }
      return "lg";
    },
    platform() : string {
      return Capacitor.getPlatform();
    },
    showProduct() : boolean {
      if (this.platform == "web") {
        return true;
      }
      return this.nativeProduct != null;
    },
    nativeKey() : string {
      let queryKey = "google_id";
      if (this.platform == "ios") {
        queryKey = "apple_id";
      }
      return queryKey;
    },
    nativeProduct() : IAPProduct|null {
      let id = this.product[this.nativeKey as keyof Product] as string;

      return this.$store.getters["product/getById"](id) ?? null;
    },
    nativeTitle() : string|null {
      return this.nativeProduct?.title ?? null;
    },
    nativeDescription() : string|null {
      return this.nativeProduct?.description ?? null;
    },
    nativePrice() : string|null {
      return this.nativeProduct?.price ?? null;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    select() {
      this.$emit("select", this.product);
    },
    onResize () {
      const item = this.$refs.item as HTMLElement;
      if (!item) {
        return;
      }
      this.width = item.clientWidth;
    }
  },
});
</script>
