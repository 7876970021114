<template>
  <div class="bg-gray-50 pt-safe">
    <div class="pb-16">
      <BaseContainer
        size="4xl"
        paddingless-mobile
      >
        <BaseCard class="mb-6 overflow-hidden">
          <div class="sm:space-x-6 sm:flex">
            <div class="sm:flex-1 p-8">
              <div>
                <h2 class="mb-3 text-xl font-medium leading-tight">
                  {{ $t('pages.ambassador.info.title') }}
                </h2>
                <p
                  class="text-sm text-gray-600"
                  v-html="$t('pages.ambassador.info.description')"
                />
              </div>
            </div>
            <div class="sm:flex-1 flex items-center bg-gray-200">
              <div class="w-full max-w-md mx-auto">
                <div class="relative overflow-hidden pt-[56.25%]">
                  <iframe
                    class="absolute inset-0 w-full h-full border-none"
                    :src="`https://youtube.com/embed/O-joW4LqYwM?hl=${$i18n.locale}&iv_load_policy=3&modestbranding=1`"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                  />
                </div>
              </div>
            </div>
          </div>
        </BaseCard>
      </BaseContainer>

      <div class="mb-10" />

      <BaseContainer size="4xl">
        <div class="flex items-center justify-between mb-5">
          <h2 class="text-xl font-medium leading-tight">
            {{ $t('pages.ambassador.stats.title') }}
          </h2>

          <router-link
            to="/ambassador/rewards"
            class="hover:text-gray-800 flex items-center text-gray-600"
          >
            <span class="mr-1">{{ $t('see_more') }}</span>
            <span>
              <Icon
                icon="heroicons-outline:chevron-right"
                class="w-4 h-4"
              />
            </span>
          </router-link>
        </div>
      </BaseContainer>

      <AmbassadorStats />

      <div class="my-10 border-t border-gray-200" />

      <BaseContainer
        size="4xl"
        paddingless-mobile
      >
        <BaseCard class="mb-6">
          <BaseCardRow size="lg">
            <h2 class="mb-3 text-xl font-medium leading-tight">
              {{ $t('pages.ambassador.share.title') }}
            </h2>

            <p class="mb-6 text-gray-600">
              {{ $t('pages.ambassador.share.description') }}
            </p>

            <h3 class="mb-3 text-xs font-medium tracking-wider text-gray-600 uppercase">
              {{ $t('referral_link') }}
            </h3>

            <BaseClipboard
              :value="$store.state.user?.ambassador?.referral_link"
            />
          </BaseCardRow>
        </BaseCard>
      </BaseContainer>

      <BaseContainer
        paddingless-mobile
        size="4xl"
      >
        <div class="mb-8">
          <div class="sm:flex -m-1">
            <div class="flex-1 p-1">
              <router-link to="/ambassador/media-kit">
                <div class="sm:rounded-lg flex flex-col h-full p-6 bg-white border border-gray-200 shadow-sm">
                  <div class="grow mb-4">
                    <h2 class="mb-1 text-xl font-medium leading-tight">
                      {{ $t('media_kit') }}
                    </h2>
                    <p class="text-sm leading-snug text-gray-600">
                      {{ $t('_media_kit_description') }}
                    </p>
                  </div>
                  <button
                    class="btn btn-primary"
                    type="button"
                  >
                    {{ $t('explore') }}
                  </button>
                </div>
              </router-link>
            </div>
            <div class="flex-1 p-1">
              <router-link to="/ambassador/sharing-tools">
                <div class="sm:rounded-lg flex flex-col h-full p-6 bg-white border border-gray-200 shadow-sm">
                  <div class="grow mb-4">
                    <h2 class="mb-1 text-xl font-medium leading-tight">
                      {{ $t('sharing_tools') }}
                    </h2>
                    <p class="text-sm leading-snug text-gray-600">
                      {{ $t('pages.ambassador.sharing_tools.description') }}
                    </p>
                  </div>
                  <button
                    class="btn btn-primary"
                    type="button"
                  >
                    {{ $t('explore') }}
                  </button>
                </div>
              </router-link>
            </div>
            <div class="flex-1 p-1">
              <router-link to="/ambassador/rewards">
                <div class="sm:rounded-lg flex flex-col h-full p-6 bg-white border border-gray-200 shadow-sm">
                  <div class="grow mb-4">
                    <h2 class="mb-1 text-xl font-medium leading-tight">
                      {{ $t('rewards') }}
                    </h2>
                    <p class="text-sm leading-snug text-gray-600">
                      {{ $t('pages.ambassador.rewards.description') }}
                    </p>
                  </div>
                  <button
                    class="btn btn-primary"
                    type="button"
                  >
                    {{ $t('explore') }}
                  </button>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </BaseContainer>

      <BaseContainer size="4xl">
        <h2 class="mb-4 text-xl font-medium leading-tight">
          {{ $t('pages.ambassador.settings.title') }}
        </h2>
      </BaseContainer>

      <BaseContainer
        size="4xl"
        paddingless-mobile
      >
        <BaseCard class="overflow-hidden">
          <router-link
            to="/ambassador/settings"
            class="hover:bg-gray-50 flex items-center p-6"
          >
            <div class="shrink-0">
              <Icon
                icon="heroicons-outline:cog"
                class="w-8 h-8 mr-4 text-gray-400"
              />
            </div>
            <div class="grow ">
              <h3 class="mb-0.5 text-lg font-medium leading-tight">
                {{ $t('pages.ambassador.settings.title') }}
              </h3>
              <p class="text-gray-600">
                {{ $t('pages.ambassador.settings.link') }}
              </p>
            </div>
            <div class="shrink-0 ml-4">
              <Icon
                icon="heroicons-outline:chevron-right"
                class="w-5 h-5 text-gray-600"
              />
            </div>
          </router-link>
        </BaseCard>
      </BaseContainer>

      <BaseContainer
        v-if="!$store.state.user?.member"
        size="4xl"
        paddingless-mobile
      >
        <BaseCard class="mt-5 overflow-hidden">
          <BaseCardRow>
            <LogoutButton />
          </BaseCardRow>
        </BaseCard>
      </BaseContainer>
    </div>

    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AmbassadorStats from "./components/AmbassadorStats.vue";

export default defineComponent({
  name: "PageAmbassador",
  components: {
    AmbassadorStats,
  },
});
</script>
