<template>
  <div class="flex">
    <button
      v-for="sort in sorts"
      :key="sort.name"
      type="button"
      :class="[
        'btn last:rounded-r rounded-r-none rounded-l-none first:rounded-l focus-visible:z-[1] flex items-center justify-center pl-2 pr-3 border-t border-b border-l last:border-r border-r-0 outline-none',
        $store.getters.sort == sort.name ? 'btn-primary' : 'btn-white-outline',
      ]"
      @click="$store.commit('setSort', sort.name)"
    >
      <component
        :is="sort.icon"
        class="w-5 h-5 mr-1"
      />
      <span class="text-sm font-medium">{{ sort.label }}</span>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LocationMarkerIcon, StarIcon, ClockIcon } from "@heroicons/vue/solid";

export default defineComponent({
  components: {
    LocationMarkerIcon,
    StarIcon,
    ClockIcon
  },
  data () {
    return {
      sorts: [
        {name: "proximity", icon: "LocationMarkerIcon", label: this.$t("proximity")},
        {name: "values", icon: "StarIcon", label: this.$t("values")},
        {name: "connection", icon: "ClockIcon", label: this.$t("connection")},
      ]
    };
  }
});
</script>
