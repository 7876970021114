<template>
  <div
    class="swiper-short-questions md:rounded-lg md:border-r md:border-l md:shadow-sm relative overflow-hidden bg-gray-900 bg-center border-t border-b border-gray-200"
    :style="{
      backgroundImage: `url(${backgroundImage})`
    }"
  >
    <!-- Backdrop -->
    <div class="bg-steel-800 opacity-80 absolute object-cover w-full h-full" />
    <div
      ref="swiper"
      class="swiper"
    >
      <div class="swiper-wrapper">
        <div
          v-if="member.user.zodiac"
          class="swiper-slide"
        >
          <div class="px-10 py-20 text-center">
            <img
              :src="`/img/zodiac/${member.user.zodiac.name}.svg`"
              :alt="member.user.zodiac.label"
              class="inline-block w-24 mx-auto"
            >
            <p class="font-display text-3xl leading-tight text-white">
              {{ member.user.zodiac.label }}
            </p>
          </div>
        </div>
        <div
          v-for="shortQuestionAnswer in answersByType('ShortQuestionType')"
          :key="shortQuestionAnswer.question_id"
          class="swiper-slide"
        >
          <div class="pb-36 relative px-10 pt-32 text-center">
            <h2 class="mb-2 text-lg text-white">
              {{ shortQuestionAnswer.question.text }}
            </h2>
            <p class="font-display text-3xl leading-tight text-white break-words">
              {{ shortQuestionAnswer.value }}
            </p>
          </div>
        </div>
      </div>
      <div
        ref="pagination"
        class="swiper-pagination"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { random } from "lodash-es";
import { defineComponent, inject, PropType } from "vue";
import { AnswerWithQuestion } from "../../types";

export default defineComponent({
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    }
  },
  setup () {
    const answersByType = inject("answersByType") as (type: string) => AnswerWithQuestion[];

    return {
      answersByType
    };
  },
  computed: {
    numberOfSlides () {
      return 3;
    },
    backgroundImage () {
      const images = [
        "https://images.unsplash.com/photo-1508672019048-805c876b67e2?auto=format&fit=crop&w=1219&q=80&sat=-100",
        "https://images.unsplash.com/photo-1575540576545-3db8561c29e4?auto=format&fit=crop&w=2051&q=80&sat=-100"
      ];
      const index = random(0, images.length - 1);
      return images[index];
    }
  },
  mounted () {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      const ref = this.$refs.swiper as HTMLElement|null;
      if (ref) {
        this.$swiper(ref, {
          loop: this.numberOfSlides > 1 ? true : false,
          autoplay: {
            delay: 4000,
            disableOnInteraction: true
          },
          pagination: {
            el: this.$refs.pagination as HTMLElement,
            type: "bullets",
            clickable: true,
          },
        });
      }
    }
  }
});
</script>

<style lang="scss">
.swiper-short-questions {
  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    padding: 5px 16px;
  }
  .swiper-pagination-bullet {
    @apply bg-white;
    @apply rounded;
    width: auto;
    flex-grow: 1;
    height: 5px;
    max-width: 40px;
  }
}
</style>
