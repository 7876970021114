<template>
  <button
    type="button"
    class="btn-lg btn sm:btn-xl btn-gray sm:w-auto flex items-center justify-center w-full"
    @click="$emit('click')"
  >
    <Icon
      class="-left-2 sm:inline relative hidden w-5 h-5 text-gray-600"
      icon="mdi:chevron-left"
    />
    <span>
      {{ $t('previous') }}
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"]
});
</script>
