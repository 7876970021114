import { App } from "vue";

import FlowerIcon from "./FlowerIcon.vue";
import FilmLine from "./FilmLine.vue";
import FilmSolid from "./FilmSolid.vue";
import HeartLine from "./HeartLine.vue";
import HeartSolid from "./HeartSolid.vue";
import MessageLine from "./MessageLine.vue";
import MessageSolid from "./MessageSolid.vue";
import SearchLine from "./SearchLine.vue";
import SearchSolid from "./SearchSolid.vue";
import SettingsLine from "./SettingsLine.vue";
import SettingsSolid from "./SettingsSolid.vue";
import PlayCircleLine from "./PlayCircleLine.vue";
import PlayCircleSolid from "./PlayCircleSolid.vue";
import Announcement2Line from "./Announcement2Line.vue";
import Announcement2Solid from "./Announcement2Solid.vue";
import HomeLine from "./HomeLine.vue";
import HomeSolid from "./HomeSolid.vue";

export default {
  install: (app: App): void => {
    app.component("FlowerIcon", FlowerIcon);
    app.component("EpiconFilmLine", FilmLine);
    app.component("EpiconFilmSolid", FilmSolid);
    app.component("EpiconHeartLine", HeartLine);
    app.component("EpiconHeartSolid", HeartSolid);
    app.component("EpiconMessageLine", MessageLine);
    app.component("EpiconMessageSolid", MessageSolid);
    app.component("EpiconSearchLine", SearchLine);
    app.component("EpiconSearchSolid", SearchSolid);
    app.component("EpiconSettingsLine", SettingsLine);
    app.component("EpiconSettingsSolid", SettingsSolid);
    app.component("EpiconPlayCircleLine", PlayCircleLine);
    app.component("EpiconPlayCircleSolid", PlayCircleSolid);
    app.component("EpiconAnnouncement2Line", Announcement2Line);
    app.component("EpiconAnnouncement2Solid", Announcement2Solid);
    app.component("EpiconHomeLine", HomeLine);
    app.component("EpiconHomeSolid", HomeSolid);
  }
};
