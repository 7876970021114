import { store } from "@/store";
import { cloneDeep } from "lodash-es";
import { api } from "./api";
import { notificator } from "./notificator";
import { Type } from "./notificator/models/NotificationConfig.interface";

async function pauseAccount(paused: boolean) {
  const response = await api.patch("account/member/pause", {
    active: paused
  });

  if (response.data.message) {
    notificator.notify({
      message: response.data.message,
      type: Type.success,
    });

    const user = cloneDeep(store.state.user);

    if (user?.member) {
      user.member.paused = paused;
      store.commit("setUser", user);
      store.commit("status/set", user);
    }
  }
}

export { pauseAccount };
