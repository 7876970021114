<template>
  <div class="sm:flex sm:items-center sm:justify-between sm:space-x-10 sm:space-y-0 space-y-5">
    <div class="grow">
      <h3 class="text-md mb-2 text-lg font-medium leading-tight">
        {{ $t('delete_account_component.title') }}
      </h3>
      <p class="leading-tight text-gray-600">
        {{ $t('delete_account_component.subtitle') }}
      </p>
    </div>

    <div class="shrink-0">
      <BaseButton
        class="btn-lg btn-danger-outline"
        @click="onClick()"
      >
        {{ $t('delete_account_component.button') }}
      </BaseButton>
    </div>
  </div>

  <BaseModal v-model="showModal">
    <div class="sm:p-8 p-6">
      <h3 class="text-md mb-2 text-lg font-medium leading-tight">
        {{ $t('delete_account_component.pause.title') }}
      </h3>

      <p class="leading-tight text-gray-600">
        {{ $t('delete_account_component.pause.subtitle') }}
      </p>

      <BaseButton
        :loading="pausing"
        class="btn-lg mt-6"
        @click="pauseAccount()"
      >
        {{ $t('delete_account_component.pause.button') }}
      </BaseButton>

      <hr class="my-6">

      <h4 class="text-md mb-2 text-lg font-medium leading-tight">
        {{ $t('delete_account_component.confirm.title') }}
      </h4>

      <p class="leading-tight text-gray-600">
        {{ $t('delete_account_component.subtitle') }}
      </p>

      <BaseButton
        :loading="loading"
        class="btn-lg btn-danger mt-6"
        @click="deleteAccount()"
      >
        {{ $t('delete_account_component.button') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { pauseAccount } from "@/services/pause";
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      loading: false,
      pausing: false,
      showModal: false,
    };
  },
  methods: {
    async onClick() {
      this.showModal = true;
    },
    async deleteAccount() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      await this.$api.delete("account");
      await this.$auth.logout();

      this.loading = false;

      this.$router.push({ name: "login" });
    },
    pauseAccount() {
      this.pausing = true;

      pauseAccount(true)
        .then(() => {
          this.showModal = false;
          this.$router.push({name: "member.search"});
        })
        .finally(() => {
          this.pausing = false;
        });
    }
  }
});
</script>
