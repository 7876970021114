<template>
  <div
    v-if="answer && options.length"
    class="md:px-0 px-4"
  >
    <h2 class="md:font-semibold md:text-xl mb-4 text-lg">
      {{ $t('lifestyle') }}
    </h2>
    <div class="flex flex-wrap -m-1">
      <div
        v-for="option in options"
        :key="option.id"
        class="xs:w-1/3 sm:w-1/4 w-1/2 p-1"
      >
        <ProfileSectionLifestyleItem
          :answer="answer"
          :option="option"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Answer } from "@/models/Answer";
import { Member } from "@/models/Member";
import { QuestionOption } from "@/models/QuestionOption";
import { defineComponent, inject, PropType } from "vue";
import { AnswerWithQuestion } from "../../types";
import ProfileSectionLifestyleItem from "./ProfileSectionLifestyleItem.vue";

export default defineComponent({
  components: {
    ProfileSectionLifestyleItem
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    }
  },
  setup () {
    const answerByName = inject("answerByName") as (name: string) => AnswerWithQuestion|null;

    return {
      answerByName
    };
  },
  computed: {
    answer () : Answer|null {
      return this.answerByName("areas_of_interest");
    },
    options () :  QuestionOption[] {
      if (!this.answer) {
        return [];
      }
      return this.answer.options;
    }
  }
});
</script>
