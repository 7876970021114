import { Account } from "@/models/Account";
import { LocationQuery, RouteLocationRaw } from "vue-router";

export function homeRoute(user: Account | null, query?: LocationQuery): RouteLocationRaw {
  if (!user) {
    return { path: "/login", query: query };
  }

  // Ambassador only
  if (user.ambassador && !user.member) {
    return { path: "/ambassador", query: query };
  }

  // Ambassador, but Member not completed
  if (user.ambassador && user.member && !user.member.onboarding_completed) {
    return { path: "/ambassador", query: query };
  }

  // Member, completed or not
  if (user.member) {
    return { path: "/member/search", query: query };
  }

  const url = import.meta.env.VITE_WEBSITE_URL ?? "https://effettandem.com";

  window.location.href = url;

  return { path: url };
}
