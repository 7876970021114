<template>
  <router-link
    :to="to"
    class="md:space-x-5 hover:bg-gray-50 sm:px-6 flex items-start px-4 py-3 space-x-3 transition-colors duration-200"
  >
    <div class="sm:top-0.5 relative grow-0 shrink-0 w-6 h-6 text-gray-400">
      <slot name="icon" />
    </div>
    <div>
      <h3 class="sm:text-lg text-base font-semibold leading-tight text-gray-900">
        {{ title }}
      </h3>
      <p class="sm:text-base sm:leading-5 text-sm leading-tight text-gray-600">
        {{ subtitle }}
      </p>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      default: "",
      type: String
    },
    to: {
      required: true,
      type: Object
    }
  }
});
</script>
