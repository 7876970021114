<template>
  <BaseAlert
    type="warning"
    class="border border-yellow-400"
  >
    <h3 class="mb-1 font-semibold">
      {{ $t('onboarding.email_already_exists.title') }}
    </h3>
    <p class="mb-2">
      {{ $t('onboarding.email_already_exists.text') }}
    </p>
    <router-link
      :to="`/login?email=${email}`"
      class="font-semibold text-yellow-800 underline"
    >
      {{ $t('sign_in') }}
    </router-link>
  </BaseAlert>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    email: {
      default: "",
      type: String
    }
  }
});
</script>
