import type { Member } from "./Member";

export enum Gender {
  male = "male",
  female = "female",
  non_binary = "non_binary"
}

export enum SexualOrientation {
  man = "man",
  woman = "woman",
  non_binary = "non_binary"
}

interface User {
  id: string,
  full_name: string;
  first_name: string;
  last_name: string;
  online: boolean;
  age: number,
  city: string,
  distance?: number,
  birthday: string,
  blocked: boolean,
  failed_authentication: boolean,
  zodiac: {
    name: string,
    label: string,
    html: string,
  },
  last_activity: string,
  conversation?: {
    id: string,
    flower_match: boolean,
    users: {
      id: string,
      pivot: {
        sent_flower: boolean
      }
    }[]
  },
  member?: Member,
  gender: Gender,
  gender_i18n: string,
  sexual_orientations?: SexualOrientation[] | null
}

export { User };
