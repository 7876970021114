<template>
  <div
    ref="button"
    class="h-full border rounded-md shadow-sm"
    :class="{
      'border-steel-300 bg-steel-100': active,
      'md:border-gray-100 border-gray-200 bg-white': !active,
    }"
  >
    <div class="flex items-center justify-center h-full p-2 px-6 pt-5 pb-4">
      <div>
        <img
          v-if="option.properties.icon_path"
          :src="$assetUrl(option.properties.icon_path ?? '')"
          class="w-9 h-9 mx-auto mb-2"
        >
        <p class="leading-tight text-center">
          {{ option.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Answer } from "@/models/Answer";
import { QuestionOption } from "@/models/QuestionOption";
import { defineComponent, inject, PropType } from "vue";

export default defineComponent({
  props: {
    answer: {
      required: true,
      type: Object as PropType<Answer>
    },
    option: {
      required: true,
      type: Object as PropType<QuestionOption>
    }
  },
  setup () {
    const answerOptionInCommon = inject("answerOptionInCommon") as (answer: Answer, option: QuestionOption) => boolean;
    return {
      answerOptionInCommon
    };
  },
  computed: {
    active () :boolean {
      return this.answerOptionInCommon(this.answer, this.option);
    }
  },
  mounted () {
    if (this.active) {
      this.$tippy(this.$refs.button as HTMLElement, {
        content: this.$t("you_have_this_in_common"),
      });
    }
  }
});
</script>
