<template>
  <div>
    <SendReferralCode
      v-if="showReferralForm && false"
      @success="fetch()"
    />

    <ProductItems
      :products="products"
      :loading="loading"
      @select="onSelect"
    />

    <ProductConfirmation
      v-if="selectedProduct"
      :model-value="selectedProduct != null"
      :product="selectedProduct"
      @bad-request="onBadRequest()"
      @success="onSuccess()"
      @update:model-value="!$event ? selectedProduct = null : false;"
    />
  </div>
</template>


<script lang="ts">
import { getDeviceReference } from "@/services/deviceReference";
import { defineComponent } from "vue";
import { Product } from "@/models/Product";
import ProductConfirmation from "./ProductConfirmation.vue";
import SendReferralCode from "../SendReferralCode.vue";
import ProductItems from "../ProductItems.vue";

export default defineComponent({
  components: {
    ProductConfirmation,
    SendReferralCode,
    ProductItems
  },
  props: {
    showReferralForm: {
      default: true,
      type: Boolean
    }
  },
  emits: ["loaded", "success"],
  data() {
    return {
      selectedProduct: null as null | Product,
      loading: false,
      products: [] as Product[],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.$api.get("products", {
        params: {
          device_ref: await getDeviceReference()
        }
      })
        .then((response: any) => {
          this.products = (response.data.data as Product[]).sort((a,b) => {
            if (a.number_of_months > b.number_of_months) {
              return 1;
            }
            if (a.number_of_months < b.number_of_months) {
              return -1;
            }
            return 0;
          });
          this.$emit("loaded", this.products);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBadRequest() {
      this.fetch();
    },
    async onSuccess () {
      this.fetch();
      await this.$store.dispatch("fetchUser");
      this.$emit("success");
    },
    onSelect(product: Product) {
      this.selectedProduct = product;
    }
  }
});
</script>
