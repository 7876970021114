<template>
  <div
    ref="avatar"
    class="bg-steel-200 aspect-1 relative"
  >
    <div :class="[blurClass, animationDurationClass]">
      <slot />
    </div>

    <template v-if="member && !isMe">
      <transition
        :enter-active-class="`transition ease-out ${animationDurationClass}`"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        :leave-active-class="`transition ease-in ${animationDurationClass}`"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <!-- Deleted -->
        <div v-if="member.deleted">
          <div class="backdrop" />

          <div class="absolute inset-0 flex items-center justify-center w-full h-full">
            <div class="flex flex-col items-center">
              <Icon
                icon="heroicons-outline:x-circle"
                class="block text-white"
                :class="iconSize"
              />
              <p
                v-if="showStateText"
                class="block font-medium text-white"
              >
                {{ $t('deleted') }}
              </p>
            </div>
          </div>
        </div>
        <!-- Unavailable -->
        <div v-else-if="member.unavailable">
          <div class="backdrop" />

          <div class="absolute inset-0 flex items-center justify-center w-full h-full">
            <div class="flex flex-col items-center">
              <Icon
                icon="heroicons-outline:x-circle"
                class="block text-white"
                :class="iconSize"
              />
              <p
                v-if="showStateText"
                class="block font-medium text-white"
              >
                {{ $t('unavailable') }}
              </p>
            </div>
          </div>
        </div>
        <!-- Unsubscribed -->
        <div v-else-if="!member.subscribed">
          <div class="backdrop" />

          <div class="absolute inset-0 flex items-center justify-center w-full h-full">
            <div class="flex flex-col items-center">
              <Icon
                icon="heroicons-outline:x-circle"
                class="opacity-60 block text-white"
                :class="iconSize"
              />
              <p
                v-if="showStateText"
                class="opacity-60 block font-medium text-white"
              >
                {{ $t('unsubscribed') }}
              </p>
            </div>
          </div>
        </div>
        <!-- Suspended -->
        <div v-else-if="member.suspended">
          <div class="backdrop" />

          <div class="absolute inset-0 flex items-center justify-center w-full h-full">
            <div class="flex flex-col items-center">
              <Icon
                icon="heroicons-outline:x-circle"
                class="opacity-60 block text-white"
                :class="iconSize"
              />
              <p
                v-if="showStateText"
                class="opacity-60 block font-medium text-white"
              >
                {{ $t('suspended') }}
              </p>
            </div>
          </div>
        </div>
        <!-- Paused -->
        <div v-else-if="member.paused">
          <div class="backdrop" />

          <div class="absolute inset-0 flex items-center justify-center w-full h-full">
            <div class="flex flex-col items-center">
              <Icon
                icon="heroicons-outline:pause"
                class="opacity-60 block text-white"
                :class="iconSize"
              />
              <p
                v-if="showStateText"
                class="opacity-60 block font-medium text-white"
              >
                {{ $t('on_pause') }}
              </p>
            </div>
          </div>
        </div>
        <!-- Pending pictures -->
        <div v-else-if="member.gallery_review_status != 'done'">
          <div class="backdrop" />

          <div class="absolute inset-0 flex items-center justify-center w-full h-full">
            <div class="flex flex-col items-center">
              <Icon
                icon="heroicons-outline:photo"
                class="opacity-60 block text-white"
                :class="iconSize"
              />
              <p
                v-if="showStateText"
                class="opacity-60 block font-medium text-white"
              >
                {{ $t('pending_pictures') }}
              </p>
            </div>
          </div>
        </div>
        <!-- Anonymous mode -->
        <div v-else-if="$store.state.user?.settings.anonymous_mode && !reveal_">
          <div class="backdrop" />

          <div class="absolute inset-0 flex items-center justify-center w-full h-full">
            <div class="flex flex-col items-center">
              <Icon
                icon="heroicons-outline:eye-off"
                class="opacity-60 text-white"
                :class="iconSize"
              />
              <button
                v-if="reveal"
                type="button"
                class="opacity-70 hover:opacity-100 mt-1 font-semibold text-white transition-opacity duration-150 bg-transparent border-none outline-none appearance-none"
                @click="reveal_ = true"
              >
                {{ $t('reveal_my_pictures') }}
              </button>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script lang="ts">

import { Member } from "@/models/Member";
import { defineComponent, PropType } from "vue";
import { ConversationMember } from "@/models/Conversation";
import { Instance } from "tippy.js";
import { DailyQuoteMember } from "@/models/DailyQuote";
import { MemberBlock } from "@/models/MemberBlock";

export default defineComponent({
  props: {
    member: {
      required: true,
      type: Object as PropType<Member|ConversationMember|DailyQuoteMember|MemberBlock|undefined>
    },
    size: {
      default: 400,
      type: Number
    },
    reveal: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      tippy: null as Instance|null,
      reveal_: false,
      animationDurationClass: "duration-200",
    };
  },
  computed: {
    isMe () : boolean {
      if (!this.member) {
        return false;
      }
      return this.$store.state.user?.member?.id == this.member?.id;
    },
    hasBlur() : boolean {
      if (!this.member) {
        return false;
      }
      if (this.isMe) {
        return false;
      }
      if (this.member.suspended) {
        return true;
      }
      if (!this.member.subscribed) {
        return true;
      }
      if (this.member.paused) {
        return true;
      }
      if ((this.$store.state.user?.settings.anonymous_mode ?? false) && !this.reveal_) {
        return true;
      }

      return false;
    },
    blurClass () : string {
      if (!this.hasBlur) {
        return "";
      }
      if (this.size > 100) {
        return "blur-lg";
      }
      return "blur-xs";
    },
    iconSize () : string {
      if (this.size > 100) {
        return "w-10 h-10";
      }
      if (this.size > 50) {
        return "w-6 h-6";
      }
      return "w-5 h-5";
    },
    showStateText () : boolean {
      if (this.size > 200) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    let content = "";

    if (this.member?.deleted) {
      content = this.$t("member_is_deleted");
    } else if (this.member?.suspended) {
      content = this.$t("member_is_suspended");
    } else if (!this.member?.subscribed) {
      content = this.$t("member_is_unsubscribed");
    } else if (this.member?.paused) {
      content = this.$t("member_on_pause");
    }

    if (content) {
      this.tippy = this.$tippy(this.$refs.avatar as HTMLElement, {
        content: content
      }) as any;
    }
  },
  beforeUnmount () {
    if (this.tippy) {
      this.tippy.unmount();
    }
  }
});
</script>

<style lang="postcss" scoped>

.backdrop {
  @apply bg-gradient-to-t from-steel-800 opacity-70 absolute inset-0 w-full h-full;
}
</style>
