<template>
  <template v-if="pendingClaim">
    <PendingClaim :balance-claim="pendingClaim" />
  </template>
  <BaseContainer
    v-else
    size="4xl"
    class="mt-4"
  >
    <BaseCard>
      <BaseCardRow>
        <VForm
          :data="form"
          url="account/ambassador/balance-claims"
          method="post"
          @success="onSuccess()"
        >
          <template #default="{errors}">
            <VInput
              v-model="form.email"
              required
              :label="$t('email')"
              name="email"
              class="mb-3"
            />

            <VInputNumber
              v-model="form.amount"
              required
              :label="$t('amount') + ' (' + (balance.priceFormat()) + ' ' + $t('available').toLowerCase() + ')'"
              name="amount"
              input-class="border-gray-300"
              :min="min"
              class="mb-6"
            />

            <div
              v-if="$store.state.user?.has_taxes"
              class="mb-6"
            >
              <FileUploader
                v-if="invoice == null"
                @success="onFileSuccess"
              >
                <template #default="{dragging}">
                  <div
                    class="hover:bg-gray-100 flex items-start w-full p-5 space-x-4 duration-150 border-2 border-gray-200 border-dashed rounded-lg"
                    :class="[ dragging ? 'bg-gray-100': 'bg-white' ]"
                  >
                    <div class="flex items-center mb-1 space-x-4">
                      <div class="p-2 bg-gray-200 rounded-full">
                        <Icon
                          icon="mdi:receipt-text"
                          class="w-6 h-6 text-gray-700"
                        />
                      </div>
                      <div class="text-left">
                        <p class="font-semibold leading-tight text-gray-800 mb-0.5">
                          {{ $t('upload_your_invoice') }}
                        </p>
                        <p class="text-sm leading-tight text-gray-500">
                          .pdf {{ $t('or') }} .docx <br>
                          {{ $t('up_to_x', {x: $display.fileSize(20 * 1024 * 1024) }).capitalize() }}<br>
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </FileUploader>

              <div v-else>
                <div class="overflow-hidden">
                  <div class="inline-block overflow-hidden w-full p-2.5 border border-gray-200 rounded-md">
                    <div class="flex items-center space-x-3 overflow-hidden">
                      <div class="shrink-0">
                        <Icon
                          icon="mdi:receipt-text-check"
                          class="w-6 h-6 text-gray-500"
                        />
                      </div>
                      <div class="grow overflow-hidden text-left">
                        <p class="truncate leading-tight text-gray-800 mb-0.5">
                          {{ invoice.original_file.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  class="mt-0.5 text-sm underline bg-white border-none appearance-none"
                  @click="removeInvoice()"
                >
                  {{ $t('upload_new_file') }}
                </button>
              </div>

              <p
                v-if="errors.invoice && errors.invoice.length"
                class="mt-1 font-semibold text-red-600"
              >
                {{ errors.invoice[0] }}
              </p>
            </div>

            <BaseButton
              type="submit"
              :disabled="!canClaim"
              class="btn btn-primary btn-lg sm:w-auto w-full text-center"
            >
              {{ $t('pages.ambassador.balance.claim') }}
            </BaseButton>
          </template>
        </VForm>

        <p class="mt-5 text-sm leading-tight text-gray-600">
          {{ $t('pages.ambassador.balance.claim_notice', {amount: min.priceFormat()}) }}
        </p>
      </BaseCardRow>
    </BaseCard>
  </BaseContainer>
</template>

<script lang="ts">
import { AmbassadorBalanceClaim } from "@/models/AmbassadorBalanceClaim";
import { defineComponent } from "vue";
import PendingClaim from "./PendingClaim.vue";

export default defineComponent({
  components: {
    PendingClaim
  },
  props: {
    balance: {
      required: true,
      type: Number
    }
  },
  emits: ["success"],
  data () {
    return {
      min: 50,
      loading: false,
      pendingClaim: null as AmbassadorBalanceClaim|null,
      balance_claims: [],
      invoice: null as Record<string,any>|null,
      form: {
        email: this.$store.state.user?.email,
        amount: null,
        invoice: null,
      }
    };
  },
  computed: {
    canClaim() : boolean {

      if (this.form.amount == 0 || this.form.amount == null) {
        return false;
      }

      if (this.$store.state.user?.has_taxes && this.form.invoice == null) {
        return false;
      }

      if (this.$store.state.user?.ambassador?.requires_nas && !this.$store.state.user.nas) {
        return false;
      }

      if (this.balance < this.min) {
        return false;
      }

      return true;
    }
  },
  created () {
    this.fetch();
  },
  methods: {
    onSuccess() {
      this.$emit("success");
      this.fetch();
    },
    fetch() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.get("account/ambassador/balance-claims", {
        params: {
          filter: {
            status: "pending"
          },
          sort: "-created_at",
          limit: 1,
        }
      })
        .then(response => {
          this.pendingClaim = response.data.data[0] ?? null;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFileSuccess(payload: any) {
      this.invoice = payload;
      this.form.invoice = payload.key;
    },
    removeInvoice() {
      this.invoice = null;
      this.form.invoice = null;
    }
  }
});
</script>
