<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />
    <MultiSelectInput
      :model-value="modelValue"
      :name="name"
      :placeholder="placeholder"
      :options="options"
      :label-key="labelKey"
      :value-key="valueKey"
      :wrapper-class="wrapperClass"
      :item-class="itemClass"
      :button-class="buttonClass"
      :quantity-notice-class="quantityNoticeClass"
      :min="min"
      :max="max"
      :class="[{
        'border-red-600': hasError(),
        'border-gray-300': !hasError(),
      }, inputClass]"
      @update:model-value="onUpdate"
    />

    <BaseAlert
      v-if="hasError()"
      type="danger"
      class="mt-5"
      :title="errorMessage()"
    />
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";
import MultiSelectInput from "../components/elements/MultiSelectInput.vue";
import { Option, OptionValue } from "../types/types";

export default defineComponent({
  name: "VInputMultiSelect",
  components: {
    InputLabel,
    MultiSelectInput,
  },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [Array, null] as PropType<OptionValue[]|null>
    },
    placeholder: {
      default: undefined,
      type: String,
    },
    options: {
      required: true,
      type: Array as PropType<Option[]>
    },
    labelKey: {
      required: true,
      type: String
    },
    valueKey: {
      required: true,
      type: String
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    wrapperClass: {
      default: "",
      type: String
    },
    itemClass: {
      default: "",
      type: String
    },
    buttonClass: {
      default: "",
      type: String
    },
    quantityNoticeClass: {
      default: "",
      type: String
    }
  },
  methods: {
    onUpdate(selections: OptionValue[]) {
      this.inputListener(selections);
    }
  }
});
</script>
