<template>
  <div class="md:px-0 px-4">
    <div class="md:mb-5">
      <h1 class="text-3xl font-semibold">
        {{ member.user.first_name }}
      </h1>
      <p class="md:text-lg text-xl text-gray-700">
        {{ member.user.age }} {{ $t('years_old') }} •
        {{ member.user.city }}
        <template v-if="member.user.distance">
          • {{ $display.publicDistance(member.user.distance) }}
        </template>
      </p>
      <p class="whitespace-nowrap text-sm text-gray-500">
        {{ $t('online_x_ago', {interval: $display.connectionInterval(member.user.last_activity).toLowerCase()}) }}
      </p>
    </div>
    <div class="md:mt-0 md:flex md:justify-between mt-4">
      <div
        v-if="showInteractions"
        class="md:space-x-3 flex space-x-2 align-bottom"
      >
        <SendMessage
          :member="member"
          :disabled="disableInteractions"
        />
        <SendFlower
          :member="member"
          :disabled="disableInteractions"
        />
      </div>
      <div
        v-if="showFakeInteractions"
        class="md:space-x-3 flex space-x-2 align-bottom"
      >
        <button
          type="button"
          class="btn btn-primary btn-lg"
          @click="showRegisterModal = true"
        >
          {{ $t('send_a_message') }}
        </button>
      </div>
      <div class="md:hidden mt-4" />
      <div class="md:space-x-3 flex space-x-2 align-bottom">
        <!-- Button Like -->
        <AudioPlayer
          v-if="mobile && member.audio_introduction_url"
          :audio="member.audio_introduction_url"
        >
          <template #default="{toggle, playing}">
            <button
              class="focus-visible:outline-none bg-primary-200 border-primary-400 text-primary-700 h-14 relative flex items-center justify-center px-5 text-lg font-medium border rounded-full"
              @click="toggle"
            >
              <div
                class=" flex items-center transition-opacity duration-100"
                :class="playing ? 'opacity-0' : ''"
              >
                <Icon
                  icon="heroicons-outline:volume-up"
                  class="w-6 h-6 mr-2"
                />
                <span>{{ $t('voice') }}</span>
              </div>
              <div
                v-if="playing"
                class="absolute inset-0 flex items-center justify-center w-full h-full"
              >
                <AnimatedWave class="text-primary-400 w-10 h-6" />
              </div>
            </button>
          </template>
        </AudioPlayer>

        <!-- Button Like -->
        <LikeButton
          v-if="showInteractions"
          :member="member"
          :disabled="disableInteractions"
        >
          <template #default="{active, toggleLike, loading, disabled}">
            <button
              class="focus-visible:outline-none focus:ring-offset-2 focus:ring-primary-500 disabled:cursor-not-allowed h-14 w-14 flex items-center justify-center text-sm bg-white border border-gray-300 rounded-full"
              :color="active ? 'secondaryOutline': 'whiteOutline'"
              :loading="loading"
              :disabled="disabled"
              :class="{'hover:bg-gray-100': !disabled}"
              @click="toggleLike"
            >
              <div class="relative w-6 h-6">
                <AnimatedHeart
                  :active="active"
                  class="left-1/2 top-1/2 w-14 h-14 absolute -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            </button>
          </template>
        </LikeButton>

        <!-- Button More Options -->
        <Menu
          v-if="showInteractions"
          as="div"
          class="relative ml-3"
        >
          <div>
            <MenuButton
              :disabled="disableInteractions"
              class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:cursor-not-allowed h-14 w-14 flex items-center justify-center text-sm bg-white border border-gray-300 rounded-full"
              :class="{'hover:bg-gray-100' : !disableInteractions}"
            >
              <Icon
                icon="heroicons-solid:dots-vertical"
                class="w-6 h-6"
                :class="{'text-gray-400': disableInteractions, 'text-gray-500': !disableInteractions}"
              />
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="scale-95 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
          >
            <MenuItems class="focus:outline-none md:right-0 md:left-auto absolute left-0 z-10 w-48 py-2 mt-2 origin-top-right bg-white border border-gray-200 rounded-md shadow-lg">
              <MenuItem
                v-slot="{ active }"
              >
                <button
                  type="button"
                  class="w-full text-left border-none outline-none appearance-none"
                  :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                  @click="showShareMemberModal = true"
                >
                  {{ $t("share") }}
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button
                  type="button"
                  class="w-full text-left border-none outline-none appearance-none"
                  :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                  @click="showReportModal = true"
                >
                  {{ $t("report") }}
                </button>
              </MenuItem>
              <MenuItem
                v-if="member.member_block_id == null"
                v-slot="{ active }"
              >
                <button
                  type="button"
                  class="w-full text-left border-none outline-none appearance-none"
                  :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                  @click="showBlockModal = true"
                >
                  {{ $t("block") }}
                </button>
              </MenuItem>
              <MenuItem
                v-else
                v-slot="{ active }"
              >
                <button
                  type="button"
                  class="w-full text-left border-none outline-none appearance-none"
                  :class="[active ? 'bg-gray-100' : 'bg-white', 'block px-4 py-2 text-gray-700']"
                  @click="showUnblockModal = true"
                >
                  {{ $t("unblock") }}
                </button>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </div>

  <ReportMemberModal
    v-if="member"
    v-model="showReportModal"
    :member-id="(member.id as string)"
    :first-name="member.user.first_name"
  />

  <BlockMemberModal
    v-if="member"
    v-model="showBlockModal"
    :member-id="(member.id as string)"
    :first-name="member.user.first_name"
    @blocked="setMemberBlockId($event)"
  />

  <UnblockMemberModal
    v-if="member && member.member_block_id"
    v-model="showUnblockModal"
    :member-block-id="member.member_block_id"
    :first-name="member.user.first_name"
    @unblocked="setMemberBlockId(null)"
  />

  <ShareMemberModal
    v-if="member"
    v-model="showShareMemberModal"
    :member-id="(member.id as string)"
    :first-name="member.user.first_name"
  />

  <RegisterModal
    v-if="showFakeInteractions"
    v-model="showRegisterModal"
  />
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { defineComponent, PropType, inject, Ref } from "vue";
import SendMessage from "../SendMessage.vue";
import SendFlower from "../SendFlower.vue";

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SendMessage,
    SendFlower
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    },
  },
  setup() {
    const mobile = inject("mobile") as Ref<boolean>;
    const isMe = inject("isMe") as Ref<boolean>;
    const setMemberBlockId = inject("setMemberBlockId") as (value: string|null) => void;
    const showInteractions = inject("showInteractions") as boolean;
    const showFakeInteractions = inject("showFakeInteractions") as boolean;
    const disableInteractions = inject("disableInteractions") as boolean;

    return {
      mobile,
      isMe,
      setMemberBlockId,
      showInteractions,
      showFakeInteractions,
      disableInteractions,
    };
  },
  data () {
    return {
      showReportModal: false,
      showUnblockModal: false,
      showBlockModal: false,
      showShareMemberModal: false,
      showRegisterModal: false,
    };
  }
});
</script>
