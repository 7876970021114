<template>
  <div>
    <WebProductList
      v-if="!isNativePlatform"
      :show-referral-form="showReferralForm"
      @loaded="$emit('loaded', $event)"
      @success="onSuccess()"
    />
    <NativeProductList
      v-else
      :show-referral-form="showReferralForm"
      @loaded="$emit('loaded', $event)"
      @success="onSuccess()"
    />
  </div>
</template>

<script lang="ts">
import { Capacitor } from "@capacitor/core";
import { defineComponent } from "vue";
import WebProductList from "./web/WebProductList.vue";
import NativeProductList from "./native/NativeProductList.vue";

export default defineComponent({
  components: {
    WebProductList,
    NativeProductList,
  },
  props: {
    showReferralForm: {
      default: true,
      type: Boolean
    }
  },
  emits: ["success", "loaded"],
  setup() {
    return {
      isNativePlatform: Capacitor.isNativePlatform()
    };
  },
  methods: {
    onSuccess() {
      // Actions performed on success should only be related to view / UI adjustment, **they should not be functional!**.
      // Ex: do not perform updates on the membership using the API
      // This event should should only be used for cosmetic purposes, ex: show a success modal
      this.$emit("success");
    }
  }
});
</script>
