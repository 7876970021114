<template>
  <div class="bg-gradient-to-b from-transparent to-black opacity-60 absolute top-0 left-0 z-10 w-full h-full" />

  <div class="bottom-menu-mobile-safe md:bottom-0 fixed left-0 z-10 w-full max-h-full pt-20 overflow-y-auto">
    <div class="bg-white">
      <div class="max-w-lg mx-auto">
        <div class="sm:pt-16 px-6 pt-10 pb-12">
          <div>
            <h2 class="font-display mb-1 text-2xl font-semibold text-center">
              {{ $t('_article_paywall.title') }}
            </h2>

            <p class="mt-4 leading-6 text-center text-gray-600">
              {{ $t('_article_paywall.auth_text', {member_type: articleMemberString}) }}
            </p>

            <div class="flex justify-center mt-5 space-x-3">
              <router-link
                to="/member/subscription"
                class="btn btn-primary btn-lg text-center"
              >
                {{ $t('_article_paywall.update_subscription', {member_type: articleMemberString}) }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { Article } from "@/models/Article";
import { PropType } from "vue";

export default defineComponent({
  props: {
    article: {
      required: true,
      type: Object as PropType<Article>
    }
  },
  computed: {
    articleMemberString() : string {
      let articleMemberString = this.$t("members");

      if (this.article.access_level == "vip") {
        articleMemberString = this.$t("vip_members");
      }

      return articleMemberString;
    }
  }
});
</script>
