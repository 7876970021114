<template>
  <div class="md:pb-10 py-20">
    <div class="flex flex-col items-center max-w-md mx-auto">
      <Icon
        icon="heroicons-solid:exclamation-circle"
        class="w-6 h-6 mb-2 text-gray-700"
      />

      <h2 class="text-md mb-1 font-semibold leading-tight text-center">
        {{ $t('page.search.end.title') }}
      </h2>

      <p class="text-sm leading-tight text-center text-gray-600">
        {{ $t('page.search.end.subtitle') }}
      </p>

      <button
        type="button"
        class="btn btn-sm flex items-center mt-4"
        @click="showFilters"
      >
        <Icon
          icon="heroicons-solid:adjustments"
          class="w-4 h-4 mr-1.5"
        />
        {{ $t('page.search.end.cta') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";

export default defineComponent({
  setup() {
    const showFilters = inject("showFilters") as () => void;
    return {
      showFilters
    };
  }
});
</script>
