<template>
  <OnboardingLayout>
    <div class="relative w-full overflow-x-hidden">
      <div class="absolute top-0 right-0 pt-5 translate-x-1/2 translate-y-0">
        <img
          src="@/assets/img/tree/tree-black.svg"
          alt="Tandem Tree"
          class="opacity-[0.05] max-w-full w-auto"
        >
      </div>

      <div class="relative">
        <OnboardingTitle margin-bottom="mb-5">
          {{ $t('onboarding.success.title') }}
        </OnboardingTitle>

        <OnboardingText class="mb-8">
          <p class="mb-2">
            {{ $t('onboarding.success.congrats_subtitle') }}
          </p>
          <p>
            {{ $t('onboarding.success.subtitle') }}
          </p>
        </OnboardingText>

        <BaseCard>
          <BaseCardRow>
            <h2 class="mb-3 text-xl font-semibold leading-tight">
              {{ $t('ambassador_info.title') }}
            </h2>

            <div class="text-gray-600">
              <p
                class="mb-3"
                v-html="$t('ambassador_info.line_1').markdownToHtml('underline text-gray-900 underline-offset-1 font-semibold decoration-2 decoration-gray-300')"
              />

              <div class="mb-3">
                <p class="">
                  {{ $t('ambassador_info.personal_link') }}
                </p>
                <BaseClipboard :value="$store.state.user?.ambassador?.referral_link" />
              </div>

              <p class="">
                {{ $t('ambassador_info.text_2') }}
              </p>
            </div>
          </BaseCardRow>
        </BaseCard>

        <img
          class="mt-8"
          src="/img/signature-fr.png"
          alt="Effet Tandem Signature"
        >

        <div class="sm:flex-row sm:space-x-2 flex flex-col items-center justify-center mt-5">
          <router-link
            id="onboarding-button-next-success"
            class="btn btn-primary sm:order-2 btn-xl sm:w-auto flex items-center justify-center order-1 w-full text-center"
            to="/member/search"
          >
            <span class="mr-2">
              {{ $t('onboarding.success.cta') }}
            </span>
            <Icon
              class="w-4 h-4"
              icon="heroicons-solid:arrow-right"
            />
          </router-link>
        </div>
      </div>
    </div>
  </OnboardingLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OnboardingTitle from "./components/OnboardingTitle.vue";
import OnboardingText from "./components/OnboardingText.vue";
import OnboardingLayout from "./OnboardingLayout.vue";

export default defineComponent({
  components: { OnboardingTitle, OnboardingText, OnboardingLayout },
});
</script>
