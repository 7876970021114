import axios from "axios";
import { api } from "./api";

interface VaporStoreOptions {
  bucket?: string,
  contentType?: string,
  expires?: string,
  visibility?: string,
  progress?: (progress: number) => void,
}

interface VaporStoreResponse {
  key: string
}

class Vapor {
  /**
     * Store a file in S3 and return its UUID, key, and other information.
     */
  async store(file: Blob, options: VaporStoreOptions = {}): Promise<VaporStoreResponse> {
    const response = await api.post("/vapor/signed-storage-url", {
      "bucket": options.bucket || "",
      "content_type": options.contentType || file.type,
      "expires": options.expires || "",
      "visibility": options.visibility || "",
    });

    const headers = response.data.headers;

    if ("Host" in headers) {
      delete headers.Host;
    }

    let progress = options.progress as (progress: number) => void;

    if (progress === undefined) {
      progress = () => {
        //
      };
    }

    await axios.put(response.data.url, file, {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        progress(progressEvent.loaded / progressEvent.total);
      }
    });

    return response.data;
  }
}

const vapor = new Vapor;

export { vapor, Vapor };
