<template>
  <div class="overflow-hidden bg-white rounded-lg shadow">
    <component
      :is="url ? 'router-link' : 'div'"
      :to="url"
      class="aspect-w-1 group-hover:shadow-lg aspect-h-1 relative block overflow-hidden transition-all duration-200 bg-gray-100"
    >
      <div class="absolute inset-0 w-full h-full">
        <MemberAvatar
          :member="memberBlock"
        />
      </div>
    </component>

    <div class="sm:p-5 p-3">
      <h2 class="text-lg font-semibold leading-snug">
        {{ memberBlock.user.first_name }}
      </h2>

      <BaseButton
        :loading="loading"
        type="button"
        class="btn btn-secondary w-full mt-3"
        @click="unblock()"
      >
        {{ $t('unblock') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { MemberBlock } from "@/models/MemberBlock";
import { defineComponent, PropType } from "vue";
import MemberAvatar from "@/views/member/profile/components/sections/components/MemberAvatar.vue";

export default defineComponent({
  components: {
    MemberAvatar
  },
  props: {
    memberBlock: {
      required: true,
      type: Object as PropType<MemberBlock>
    }
  },
  emits: ["fetch"],
  data () {
    return {
      loading: false,
    };
  },
  computed: {
    url () :string|null {
      if (!this.memberBlock.can.view) {
        return null;
      }
      return this.$urls.member(this.memberBlock);
    },
  },
  methods: {
    unblock() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.delete("member-blocks/" + this.memberBlock.pivot.id)
        .then(() => {
          this.$emit("fetch");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
