<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="p-8">
      <h2 class="mb-3 text-lg font-semibold leading-5 text-gray-900">
        {{ $t('page.profile.report_modal.title', {x: firstName}) }}
      </h2>

      <p class="mb-6 leading-snug text-gray-700">
        {{ $t('page.profile.report_modal.description') }}
      </p>

      <div>
        <VForm
          :data="form"
          method="post"
          url="member-reports"
          loading-mask-class="bg-white"
          @success="onSuccess"
        >
          <div class="mb-5 space-y-2">
            <VInputRadio
              v-for="code in reasonCodes"
              :key="code"
              v-model="form.reason_code"
              name="reason_code"
              size="lg"
              :value="code"
              :description="$t('page.profile.report_modal.reason_codes.' + code)"
            />
          </div>
          <div class="mb-4">
            <VInputTextarea
              v-model="form.note"
              :label="$t('additional_details')"
              name="note"
              input-class="w-full"
            />
          </div>

          <div class="xs:flex xs:space-x-3 xs:space-y-0 space-y-3">
            <button
              type="submit"
              class="btn btn-primary btn-lg xs:w-auto w-full"
            >
              {{ $t('submit') }}
            </button>

            <button
              type="button"
              class="btn btn-lg xs:w-auto w-full"
              @click="close()"
            >
              {{ $t('cancel') }}
            </button>
          </div>
        </VForm>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    memberId: {
      required: true,
      type: String
    },
    firstName: {
      required: true,
      type: String
    }
  },
  emits: ["update:modelValue"],
  data () {
    return {
      reasonCodes: [
        "graphic_picture",
        "false_identity",
        "monetary_solicitation",
        "attempted_fraud",
        "harassment",
        "inappropriate_comments",
        "other",
      ],
      form: {
        member_id: this.memberId,
        reason_code: "",
        note: "",
      }
    };
  },
  methods: {
    onSuccess () {
      this.close();
    },
    close () {
      this.form.note = "";
      this.form.reason_code = "";
      this.$emit("update:modelValue", false);
    }
  }
});
</script>
