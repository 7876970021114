import { Account } from "@/models/Account";
import { Question } from "@/models/Question";
import { store } from "@/store";

export enum StepComponent {
  StepGender = "StepGender",
  StepSexualOrientation = "StepSexualOrientation",
  StepQuestion = "StepQuestion",
  StepShortQuestions = "StepShortQuestions",
  StepLongQuestions = "StepLongQuestions",
  StepIntroduction = "StepIntroduction",
  StepIntroductionVideo = "StepIntroductionVideo",
  StepAudioIntroduction = "StepAudioIntroduction",
  StepAuthentication = "StepAuthentication",
  StepPictures = "StepPictures",
  StepPicturesVideo = "StepPicturesVideo",
  StepSamplePicture = "StepSamplePicture",
  StepPayment = "StepPayment",
}

export interface Step {
  path: string,
  component: StepComponent,
  data?: {
    question_id?: string,
  },
}

export class Steps {

  public steps: Step[] = [];
  private questions: { [questionName: string]: Question } = {};

  async init(): Promise<Step[]> {

    if (store.state.questions.items.length == 0) {
      await store.dispatch("question/fetch");
    }

    this.questions = store.getters["question/listByName"];

    this.steps.push({
      path: "gender",
      component: StepComponent.StepGender,
    });

    this.steps.push({
      path: "sexual-orientation",
      component: StepComponent.StepSexualOrientation,
    });

    this.addQuestionStep("objective");
    this.addQuestionStep("silhouette");
    this.addQuestionStep("height");
    this.addQuestionStep("languages");
    this.addQuestionStep("family_situation");
    this.addQuestionStep("want_to_have_children");
    this.addQuestionStep("education_level");
    this.addQuestionStep("current_occupation");
    this.addQuestionStep("alcohol_consumption_frequency");
    this.addQuestionStep("smoking");
    this.addQuestionStep("food_preferences");
    this.addQuestionStep("personality_traits");
    this.addQuestionStep("values");
    this.addQuestionStep("religious_beliefs");
    this.addQuestionStep("personal_development_importance");
    this.addQuestionStep("accepts_persons_with_limitations");
    this.addQuestionStep("areas_of_interest");

    this.steps.push({ path: "introduction-video", component: StepComponent.StepIntroductionVideo });
    this.steps.push({ path: "introduction", component: StepComponent.StepIntroduction });

    this.steps.push({ path: "short-questions", component: StepComponent.StepShortQuestions });
    this.steps.push({ path: "long-questions", component: StepComponent.StepLongQuestions });

    this.steps.push({ path: "audio-introduction", component: StepComponent.StepAudioIntroduction });
    this.steps.push({ path: "authentication", component: StepComponent.StepAuthentication });
    this.steps.push({ path: "pictures-video", component: StepComponent.StepPicturesVideo });
    this.steps.push({ path: "sample-picture", component: StepComponent.StepSamplePicture });
    this.steps.push({ path: "pictures", component: StepComponent.StepPictures });
    this.steps.push({ path: "payment", component: StepComponent.StepPayment });

    return this.steps;
  }

  private addQuestionStep(questionName: string): void {

    const question = this.questions[questionName];

    if (!question) {
      return;
    }

    this.steps.push({
      path: questionName.replaceAll("_", "-"),
      component: StepComponent.StepQuestion,
      data: {
        question_id: question.id
      }
    });
  }

  public async currentStepPath(): Promise<string> {
    const user = store.state.user as Account;
    const onboardingStep = user.member?.onboarding_step ?? "";

    if (this.steps.length == 0) {
      await this.init();
    }

    let stepPath = this.steps[0].path;

    if (onboardingStep && this.steps.find(s => s.path == onboardingStep) !== undefined) {
      stepPath = onboardingStep;
    }

    return stepPath;
  }
}
