import { Storage } from "@capacitor/storage";

const AUTH_LOCAL_STORAGE_KEY = "tandem.auth.loggedIn";

export default {
  async setLoggedIn(loggedIn: boolean) {
    return await Storage.set({
      key: AUTH_LOCAL_STORAGE_KEY,
      value: loggedIn ? "1" : "0"
    });
  },
  async getLoggedIn(): Promise<boolean> {
    const data = await Storage.get({
      key: AUTH_LOCAL_STORAGE_KEY
    });

    if (!data.value) {
      return false;
    }

    return data.value == "1" ? true : false;
  }
};
