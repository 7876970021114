// eslint-disable-next-line @typescript-eslint/no-unused-vars
import adapter from "webrtc-adapter";

export const getPermissions = (constraints?: MediaStreamConstraints): Promise<MediaStream> => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia(constraints ?? { video: true, audio: true })
      .then((stream: MediaStream) => {
        resolve(stream);
      })
      .catch(err => {
        reject(err);
        //   throw new Error(`Unable to fetch stream ${err}`);
      });
  });
};
