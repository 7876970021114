<template>
  <div class="relative flex h-full text-white bg-gray-900">
    <div class="absolute inset-0 flex w-full h-full">
      <template
        v-for="(items, index) in imageMatrix"
        :key="index"
      >
        <transition-group
          enter-active-class="transition duration-[2000ms] ease-out"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition duration-[2000ms] ease-in delay-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-for="(slide, j) in items"
            v-show="j == randomIndexes[index]"
            :key="j"
            class="absolute inset-0 flex w-1/3 h-full"
            :class="{
              'left-1/3': index == 1,
              'left-2/3': index == 2,
            }"
          >
            <img
              :src="`/img/onboarding/slides/optimized/${slide}`"
              class="object-cover object-center w-full h-full"
            >
          </div>
        </transition-group>
      </template>
    </div>
    <div class="absolute inset-0 flex w-full h-full bg-black opacity-50" />
    <div class="from-black bg-gradient-to-t opacity-80 absolute inset-0 flex w-full h-full" />
    <div class="relative flex items-end w-full h-full">
      <div class="xl:p-28 max-w-3xl p-20">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const SLIDE_PER_WINDOW = 4;

export default defineComponent({
  data() {
    return {
      i: 0,
      randomIndexes: [0, 0, 0],
      interval: 0 as any,
    };
  },
  computed: {
    imageMatrix() {
      return [
        [
          "AdobeStock_35186829.jpg",
          "pexels-rodnae-productions-8151742.jpg",
          "AdobeStock_388266193.jpg",
          "Stocksy_comp_4138643.jpg",
        ],
        [
          "pexels-rodnae-productions-4921081.jpg",
          "Stocksy_comp_735586.jpg",
          "Stocksy_comp_2316290.jpg",
          "pexels-ono-kosuki-5999894.jpg",
        ],
        [
          "Stocksy_comp_2859536.jpg",
          "Stocksy_comp_3524706.jpg",
          "Stocksy_comp_3752488.jpg",
          "Stocksy_comp_3808805.jpg",
        ],
      ];
    }
  },
  created() {
    this.interval = setInterval(() => {
      if (this.i >= this.imageMatrix.length - 1) {
        this.i = 0;
      }
      else {
        this.i++;
      }
      if (this.randomIndexes[this.i] >= SLIDE_PER_WINDOW - 1) {
        this.randomIndexes[this.i] = 0;
      }
      else {
        this.randomIndexes[this.i]++;
      }
    }, 3000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
});
</script>
