<template>
  <h1
    class="font-display text-3xl font-bold leading-tight text-gray-900"
    :class="marginBottom"
  >
    <slot />
  </h1>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    marginBottom: {
      default: "sm:mb-8 mb-6",
      type: String
    }
  }
});
</script>
