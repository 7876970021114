<template>
  <div class="flex bg-white border border-gray-300 rounded">
    <input
      ref="input"
      :value="modelValue"
      :type="showPassword ? 'text' : 'password'"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :required="required"
      class="grow border-none rounded"
      :class="inputClass"
      @input="onInput"
    >
    <div class="shrink-0 flex pl-3">
      <button
        tabindex="-1"
        type="button"
        class="pr-3 text-gray-500"
        @click="showPassword = !showPassword"
      >
        <Icon
          v-if="!showPassword"
          icon="heroicons-outline:eye-off"
          class="w-6 h-6"
        />
        <Icon
          v-else
          icon="heroicons-outline:eye"
          class="w-6 h-6"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      default: "",
      type: [String, null] as PropType<string|null>,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    name: {
      default: undefined,
      type: String,
    },
    placeholder: {
      default: undefined,
      type: String,
    },
    required: {
      default: undefined,
      type: Boolean
    },
    inputClass: {
      default: "",
      type: String,
    }
  },
  emits: ["update:modelValue"],
  data () {
    return {
      showPassword: false,
    };
  },
  methods: {
    onInput(event: any) {
      this.$emit("update:modelValue", event.target.value);
    }
  }
});
</script>
