<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.preferences.anonymous_mode.title')"
        :subtitle="$t('page.settings.menu.preferences.anonymous_mode.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
    >
      <BaseCard>
        <BaseCardRow>
          <div class="flex space-x-3">
            <BaseSwitch
              :model-value="$store.getters.anonymousMode"
              @update:model-value="updateAnonymousMode"
            >
              <p>
                {{ $t('activate_anonymous_mode') }}
              </p>
            </BaseSwitch>
          </div>
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      active: false,
    };
  },
  methods: {
    updateAnonymousMode(value: boolean) {
      this.$store.dispatch("updateAnonymousMode", value);
    }
  }
});
</script>
