import { Notification } from "@/services/notificator/models/Notification";
import { NotificationConfigInterface } from "@/services/notificator/models/NotificationConfig.interface";
import { NotificationList } from "@/services/notificator/models/NotificationList.interface";

export interface NotificationState {
  items: NotificationList,
}

export const defaultNotificationState = {
  items: {}
} as NotificationState;

export const notificationModule = {
  namespaced: true,
  state() {
    return defaultNotificationState;
  },
  mutations: {
    push(state: NotificationState, notification: Notification | NotificationConfigInterface) {
      if (notification instanceof Notification) {
        state.items[notification.id] = notification;
      } else {
        const notificationModel = new Notification(notification);
        state.items[notificationModel.id] = notificationModel;
      }
    },
    pop(state: NotificationState, id: string) {
      delete state.items[id];
    },
    clear(state: NotificationState) {
      state.items = {};
    },
  },
};
