<template>
  <div>
    <div>
      <InputLabel
        v-if="labelValue"
        :label="labelValue"
        :required="required"
      />

      <BasePasswordInput
        :model-value="modelValue"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :required="required"
        :class="[{
          'border-red-600': hasError(),
          'border-gray-300': !hasError(),
        }, inputClass]"
        @input="onInput"
      />

      <p
        v-if="hasError()"
        class="form-input-error mt-1"
      >
        {{ errorMessage() }}
      </p>
    </div>

    <div
      v-if="touched && modelValue"
      class="p-5 mt-3 border rounded"
      :class="{
        'bg-green-100 border-green-300 text-green-900': validPassword,
        'bg-red-100 border-red-300 text-red-900': !validPassword
      }"
    >
      <div class="flex mb-3">
        <Icon
          v-if="rules.min_size(normalizedModelValue)"
          icon="heroicons-solid:check"
          :class="[iconClasses, 'text-green-600']"
        />
        <Icon
          v-else
          icon="heroicons-solid:ban"
          :class="[iconClasses, 'text-red-600']"
        />
        <p class="leading-tight">
          {{ $t('password_rule.min_size') }}
        </p>
      </div>
      <div class="flex mb-3">
        <Icon
          v-if="rules.lower_and_cap(normalizedModelValue)"
          icon="heroicons-solid:check"
          :class="[iconClasses, 'text-green-600']"
        />
        <Icon
          v-else
          icon="heroicons-solid:ban"
          :class="[iconClasses, 'text-red-600']"
        />
        <p class="leading-tight">
          {{ $t('password_rule.lower_and_cap') }}
        </p>
      </div>
      <div class="flex">
        <Icon
          v-if="rules.number(normalizedModelValue)"
          icon="heroicons-solid:check"
          :class="[iconClasses, 'text-green-600']"
        />
        <Icon
          v-else
          icon="heroicons-solid:ban"
          :class="[iconClasses, 'text-red-600']"
        />
        <p class="leading-tight">
          {{ $t('password_rule.number') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { get, isString, trim } from "lodash-es";
import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";

type Rule = (password: string) => boolean;

const rules = {
  min_size: (value: string) => {
    return value.length >= 8;
  },
  lower_and_cap: (value: string) => {
    return value.toLowerCase() != value && value.toUpperCase() != value;
  },
  number: (value: string) => {
    return /\d/.test(value);
  }
} as {[key: string]: Rule};

export default defineComponent({
  name: "VInputPasswordWithValidation",
  components: {
    InputLabel,
  },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [String,null] as PropType<string|null>,
    }
  },
  emits: ["validity"],
  data () {
    return {
      touched: false,
      iconClasses: "w-6 h-6 mr-3 shrink-0",
      rules: rules,
      showPassword: false,
    };
  },
  computed: {
    normalizedModelValue() : string {
      if (isString(this.modelValue)) {
        return this.modelValue;
      }

      return "";
    },
    validPassword () : boolean {
      return Object.keys(rules).reduce((value: boolean, key: keyof typeof rules) => {
        return value && rules[key](this.normalizedModelValue);
      }, true);
    },
  },
  created () {
    this.$emit("validity", this.validPassword);
  },
  methods: {
    onInput(event: Event|null) {

      this.touched = true;

      const password = this.transformInputValue(event);

      this.inputListener(password);

      this.$nextTick(() => {
        this.$emit("validity", this.validPassword);
      });
    },
    transformInputValue(event: Event | null): string | null {
      if (event === null) {
        return null;
      }
      const value = get(event, "target.value", null);
      if (!isString(value)) {
        return null;
      }
      return trim(value as string);
    }
  }
});
</script>
