<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="sm:p-7 relative flex flex-col p-6 overflow-hidden text-left">
      <button
        type="button"
        class="focus:ring-0 focus:outline-none focus:border-none w-1 h-1 bg-transparent border-transparent outline-none"
      />
      <QuestionForm
        :answers="answers"
        :question="question"
        @success="$emit('update:modelValue', false)"
      >
        <template #footer>
          <div class="flex flex-wrap -m-1">
            <div class="grow sm:grow-0 p-1">
              <BaseButton
                type="submit"
                class="btn btn-primary sm:w-auto w-full mt-4"
              >
                {{ $t('save') }}
              </BaseButton>
            </div>
            <div class="grow sm:grow-0 p-1">
              <BaseButton
                type="button"
                class="btn sm:w-auto w-full mt-4"
                @click="$emit('update:modelValue', false)"
              >
                {{ $t('cancel') }}
              </BaseButton>
            </div>
          </div>
        </template>
      </QuestionForm>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Question } from "@/models/Question";
import { AnswerList } from "@/store";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean
    },
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    answers: {
      required: true,
      type: Object as PropType<AnswerList>
    }
  },
  emits: ["update:modelValue"],
});
</script>
