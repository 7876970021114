<template>
  <div class="bg-gray-50 pt-safe">
    <BaseContainer size="4xl">
      <BaseTitle
        :previous="false"
        :title="$t('pages.ambassador.title')"
        :subtitle="$t('pages.ambassador.subtitle')"
      />
    </BaseContainer>

    <div
      v-if="!($store.state.user?.member && $store.state.user.member.onboarding_completed)"
      class="mb-6"
    >
      <BaseContainer
        size="4xl"
        paddingless-mobile
      >
        <CreateMember />
      </BaseContainer>
    </div>

    <BaseContainer
      v-if="$store.state.user?.ambassador?.requires_nas && !$store.state.user.nas"
      size="4xl"
      paddingless-mobile
    >
      <SINWarning class="mb-8" />
    </BaseContainer>

    <BaseContainer
      size="4xl"
    >
      <div class="mb-6">
        <TabLinks
          :links="[
            {label: $t('dashboard'), link: '/ambassador'},
            {label: $t('media_kit'), link: '/ambassador/media-kit'},
            {label: $t('sharing_tools'), link: '/ambassador/sharing-tools'},
            {label: $t('rewards'), link: '/ambassador/rewards'},
            {label: $t('settings'), link: '/ambassador/settings'},
          ]"
        />
      </div>
    </BaseContainer>

    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SINWarning from "./components/SINWarning.vue";
import CreateMember from "./components/CreateMember.vue";

export default defineComponent({
  components: {
    SINWarning,
    CreateMember
  }
});
</script>
