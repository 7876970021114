<template>
  <VForm
    :data="form"
    method="patch"
    url="/account/password"
    @success="onSuccess"
  >
    <template #default="{loading}">
      <div class="mb-5">
        <VInputPassword
          v-model="form.old_password"
          :label="$t('current_password')"
          name="old_password"
          required
          input-class="w-full"
        />
      </div>
      <div class="mb-5">
        <VInputPasswordWithValidation
          v-model="form.new_password"
          :label="$t('new_password')"
          name="new_password"
          required
          input-class="w-full"
        />
      </div>
      <div class="mb-5">
        <VInputPassword
          v-model="form.new_password_confirmation"
          :label="$t('new_password_confirmation')"
          name="new_password_confirmation"
          required
          input-class="w-full"
        />
      </div>

      <BaseButton
        type="submit"
        :disabled="loading"
        :loading="loading"
        class="btn-primary btn-lg sm:w-auto w-full mt-4"
      >
        {{ $t('update') }}
      </BaseButton>
    </template>
  </VForm>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";

const DEFAULT_FORM = {
  old_password: "",
  new_password: "",
  new_password_confirmation: ""
};

export default defineComponent({
  data () {
    return {
      form: cloneDeep(DEFAULT_FORM)
    };
  },
  methods: {
    onSuccess() {
      this.form = cloneDeep(DEFAULT_FORM);
    }
  }
});
</script>
