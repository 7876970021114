<template>
  <div class="mb-8">
    <template v-if="showReviewStatus">
      <BaseSkeleton
        v-if="loading"
        class="h-16"
      />

      <template v-else>
        <PictureReviewStatus
          v-if="review_status"
          :status="review_status"
        />
      </template>
    </template>

    <div
      v-if="showSampleLink"
      class="mt-6"
    >
      <router-link
        class="btn btn-lg inline-flex items-center"
        :to="{'name': 'member.settings.sample_picture'}"
      >
        <span class="mr-2">{{ $t('page.settings.menu.profile.sample_picture.subtitle') }}</span>
        <Icon icon="heroicons:arrow-top-right-on-square-20-solid" />
      </router-link>
    </div>

    <div class="mt-10 mb-10">
      <div
        v-if="loading"
        class="flex flex-wrap -m-3"
      >
        <div
          v-for="i in 3"
          :key="i"
          class="aspect-1 relative p-3"
          :class="widthClasses"
        >
          <div class="relative h-full">
            <BaseSkeleton
              class="absolute inset-0 w-full h-full"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        data-cy="pictures"
      >
        <!--
          tag="transition-group" was remove since it causes a bug since vue 3.2.32
          This is a #wontfix until a new version of vue-draggable fixing this issue is released
          See: https://github.com/SortableJS/vue.draggable.next
        -->
        <draggable
          v-model="pictures"
          item-key="id"
          handle=".handle"
          :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
          :delay="200"
          :delay-on-touch-only="true"
          :animation="200"
          class="flex flex-wrap -m-3"
          :move="onChange"
          @choose="onChoose"
          @unchoose="onUnchoose"
          @start="drag = true"
          @end="drag = false; onDragend();"
        >
          <template #item="{element, index}">
            <PicturesFormItem
              :show-status="showPictureStatus"
              :allow-empty="allowEmpty"
              :width-classes="widthClasses"
              :picture="element"
              :index="index"
              @fetch="fetch(false)"
            />
          </template>
        </draggable>
      </div>
    </div>

    <div
      v-if="refusedPictures.length > 0"
      class="mb-10 mt-12 text-lg font-semibold"
    >
      <h2 class="mb-6 text-base flex items-center">
        <Icon
          class="inline mr-1 text-gray-600"
          icon="heroicons:x-circle-20-solid"
        />
        <span class="text-gray-600">{{ $t('refused_pictures') }}</span>
      </h2>

      <div class="flex flex-wrap -m-3">
        <div
          v-for="picture in refusedPictures"
          :key="picture.id"
          class="sm:w-1/4 xs:w-1/3 w-1/2 p-3"
        >
          <div class="aspect-1 relative">
            <div
              class="-top-2 -left-3 h-7 w-7 absolute flex items-center justify-center text-sm font-semibold text-white bg-red-500 rounded-full shadow"
            >
              <Icon
                icon="heroicons-solid:x"
                class="w-4 h-4"
              />
            </div>
            <div
              class="handle w-full h-full overflow-hidden bg-gray-500 rounded-md shadow-lg"
            >
              <img
                :src="$assetUrl(picture.base_path, {resize: {height: 300, width: 300}})"
                :alt="picture.id"
                class="object-cover object-center w-full h-full pointer-events-none select-none"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ImageUploader
      button-class="hover:bg-gray-50 relative w-full h-full max-w-sm overflow-hidden transition-colors bg-white border-2 border-gray-300 border-dashed rounded-md"
      :min-width="minWidth"
      :min-height="minHeight"
      :max-size="maxSize"
      croppable
      :before-upload="beforeUpload"
      :after-upload="afterUpload"
    >
      <template #default="{dragging}">
        <div
          class="group relative flex items-center justify-center h-full px-8 py-12"
          :class="{
            'bg-gray-50': dragging
          }"
        >
          <div class="relative">
            <div
              class="inline-block p-2 mx-auto bg-gray-200 rounded-full"
            >
              <Icon
                icon="heroicons-outline:upload"
                class="w-6 h-6 text-gray-600"
              />
            </div>
            <div class="text-center">
              <p
                class="font-medium leading-none mb-0.5 text-gray-800"
              >
                {{ $t('upload_a_picture') }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </ImageUploader>

    <div class="mt-6">
      <p class="text-md mb-2 font-semibold text-gray-900">
        {{ $t('you_must_upload_between_x_to_y_pictures', {x: MIN_PICTURES, y: MAX_PICTURES}) }}
      </p>
      <div class="leading-tight text-gray-600">
        <p class="mb-1">
          PNG, JPG {{ $t('or') }} GIF <br>
        </p>
        <p class="mb-1">
          {{ $t('up_to_x', {x: $display.fileSize(maxSize)}).capitalize() }}<br>
        </p>
        <p class="mb-1">
          {{ $t('at_least_size', {size: `${minWidth} x ${minHeight} pixels`}) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent} from "vue";
import draggable from "vuedraggable";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { MemberGalleryItem } from "@/models/Member";

const MIN_PICTURES = 2;
const MAX_PICTURES = 6;

export default defineComponent({
  components: {
    draggable,
  },
  provide() {
    return {
      pictures: computed(() => this.pictures),
    };
  },
  props: {
    showReviewStatus: {
      default: false,
      type: Boolean
    },
    showPictureStatus: {
      default: false,
      type: Boolean
    },
    showSampleLink: {
      default: false,
      type: Boolean
    },
    widthClasses: {
      default: "w-1/2 sm:w-56",
      type: String
    },
    forceOrder: {
      default: false,
      type: Boolean
    },
    allowEmpty: {
      default: false,
      type: Boolean
    }
  },
  emits: ["update"],
  setup() {
    return {
      MIN_PICTURES,
      MAX_PICTURES,
    };
  },
  data () {
    return {
      loading: true,
      drag: false,
      review_status: null,
      review_expired: false,
      pictures: [] as MemberGalleryItem[],
      refusedPictures: [] as MemberGalleryItem[],
      maxSize: 1024 * 1024 * 10,
      minWidth: 250,
      minHeight: 250,
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch(showLoading = true) {
      if (showLoading) {
        this.loading = true;
      }
      this.$api.get("/account/member/pictures")
        .then(response => {
          const allPictures = response.data.data as MemberGalleryItem[];
          this.pictures = allPictures.filter(p => p.status != "refused");
          this.refusedPictures = allPictures.filter(p => p.status == "refused");
          this.review_status = response.data.review_status;
          this.review_expired = response.data.review_expired;

          this.$emit("update", this.pictures);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChoose() {
      Haptics.impact({
        style: ImpactStyle.Heavy
      });
    },
    onUnchoose() {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    },
    onChange() {
      /* Haptics.impact({
        style: ImpactStyle.Light
      }); */
    },
    onDragend() {

      const firstPortrait = this.pictures.find(picture => {
        return picture.status == "portrait";
      });

      if (firstPortrait && this.forceOrder) {

        if (firstPortrait.id != this.pictures[0].id) {
          this.$notify({
            message: this.$t("your_first_picture_must_be_a_portrait"),
            type: Type.warning
          });
        }

        this.pictures = this.pictures.sort((x,y) => {
          return x.id == firstPortrait.id ? -1 : y.id == firstPortrait.id ? 1 : 0;
        });
      }

      this.$api.patch("/account/member/pictures/reorder", {
        ids: this.pictures.map(p => p.id)
      });
    },
    beforeUpload () : boolean {
      if (this.pictures.length >= MAX_PICTURES) {
        this.$notify({
          message: this.$t("you_can_upload_up_to_x_pictures", {x: MAX_PICTURES}),
          type: Type.danger
        });
        return false;
      }
      return true;
    },
    async afterUpload(key: string) {
      await this.$api.post("/account/member/pictures", {
        key: key,
      });
      this.fetch(false);
      return true;
    }
  }
});
</script>

<style lang="postcss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.sortable-ghost {
  @apply scale-100 opacity-50;
}

.sortable-ghost.sortable-chosen {
  @apply scale-100;
}

.sortable-chosen {
  @apply scale-105;
}
</style>
