<template>
  <VForm
    :data="form"
    url="account/member/secondary-conversation-rules/batch"
    method="post"
  >
    <BaseCard>
      <SecondaryConversationRuleFormItem
        v-for="(rule, i) in form.secondary_conversation_rules"
        :key="rule.key"
        :model-value="rule"
        :index="i"
        :type-options="typeOptions"
        :selected-type-options="selectedTypeOptions"
        :question-prefix="questionPrefix"
        :field-prefix="fieldPrefix"
        @update:model-value="onRuleUpdate(i, $event)"
        @remove="removeRule(i)"
      />

      <BaseCardRow
        v-if="form.secondary_conversation_rules.length < 3"
      >
        <button
          class="btn btn-lg flex items-center justify-center w-full"
          type="button"
          @click="addRule()"
        >
          <Icon
            class="mr-2"
            icon="mdi:plus"
          />
          <span>{{ $t('add_criteria') }}</span>
        </button>
      </BaseCardRow>

      <BaseCardRow>
        <button
          type="submit"
          class="btn btn-lg btn-primary w-full"
        >
          {{ $t('save') }}
        </button>
      </BaseCardRow>
    </BaseCard>
  </VForm>
</template>

<script lang="ts">
import { ConversationRuleRequest, TypeOption } from "./types";
import { SecondaryConversationRule } from "@/models/SecondaryConversationRule";
import { defineComponent, PropType } from "vue";
import SecondaryConversationRuleFormItem from "./SecondaryConversationRuleFormItem.vue";
import { uuid } from "@/services/uuid";
import { Question } from "@/models/Question";

export default defineComponent({
  components: {
    SecondaryConversationRuleFormItem
  },
  props: {
    secondaryConversationRules: {
      required: true,
      type: Array as PropType<SecondaryConversationRule[]>
    }
  },
  setup () {
    const questionPrefix = "question__";
    const fieldPrefix = "field__";

    return {
      questionPrefix,
      fieldPrefix,
    };
  },
  data () {
    return {
      form: {
        secondary_conversation_rules: [] as ConversationRuleRequest[]
      }
    };
  },
  computed: {
    typeOptions () : TypeOption[] {

      const types = [];

      types.push({
        value: this.fieldPrefix + "age",
        label: this.$t("age"),
      });

      types.push({
        value: this.fieldPrefix + "distance",
        label: this.$t("distance"),
      });

      const questionNames = [
        "objective",
        "silhouette",
        "height",
        "languages",
        "family_situation",
        "want_to_have_children",
        "education_level",
        "current_occupation",
        "alcohol_consumption_frequency",
        "smoking",
        "smoking_frequency",
        "smoking_tolerance_level",
        "food_preferences",
        "personality_traits",
        "religious_beliefs",
        "religion",
        "personal_development_importance",
        "accepts_persons_with_limitations",
        "areas_of_interest",
      ];

      const questions = questionNames
        .map(name => {
          return this.$store.getters["question/getByName"](name) as Question|null;
        }).
        filter((q: Question|null) => {
          if (!q) {
            return false;
          }
          return true;
        }) as Question[];

      types.push(...questions
        .map(q => {
          return {
            value: this.questionPrefix + q.id,
            label: q.label,
          };
        }));

      return types;
    },
    selectedTypeOptions() : TypeOption[] {
      const types = this.form.secondary_conversation_rules.map(r => {
        if (r.question_id) {
          return this.questionPrefix + r.question_id;
        }
        if (r.field) {
          return this.fieldPrefix + r.field;
        }
        return null;
      });
      return this.typeOptions.filter(o => types.includes(o.value));
    }
  },
  watch: {
    secondaryConversationRules: {
      immediate: true,
      handler() {
        this.form.secondary_conversation_rules = this.secondaryConversationRules.map(rule => {
          return {
            key: rule.id,
            question_id: rule.question_id,
            field: rule.field,
            value: rule.value,
            options: rule.options.map(o => o.id),
          } as ConversationRuleRequest;
        });
      }
    }
  },
  methods: {
    addRule () {
      this.form.secondary_conversation_rules.push({
        key: uuid(),
        value: null,
        field: null,
        question_id: null,
        options: [] as string[]
      });
    },
    removeRule (index: number) {
      this.form.secondary_conversation_rules.splice(index, 1);
    },
    onRuleUpdate(index: number, rule: ConversationRuleRequest) {
      this.form.secondary_conversation_rules[index] = rule;
    },
  }
});
</script>
