<template>
  <li
    :class="{
      'underline decoration-steel-200 underline-offset-2 decoration-[2px]': active
    }"
  >
    <span ref="text">
      {{ option.label }}
    </span>
  </li>
</template>

<script lang="ts">
import { Answer } from "@/models/Answer";
import { QuestionOption } from "@/models/QuestionOption";
import { defineComponent, inject, PropType } from "vue";

export default defineComponent({
  props: {
    answer: {
      required: true,
      type: Object as PropType<Answer>
    },
    option: {
      required: true,
      type: Object as PropType<QuestionOption>
    }
  },
  setup () {
    const answerOptionInCommon = inject("answerOptionInCommon") as (answer: Answer, option: QuestionOption) => boolean;
    return {
      answerOptionInCommon
    };
  },
  computed: {
    active () :boolean {
      return this.answerOptionInCommon(this.answer, this.option);
    }
  },
  mounted() {
    if (this.active) {
      this.$tippy(this.$refs.text as HTMLElement, {
        content: this.$t("you_have_this_in_common"),
      });
    }
  }
});
</script>
