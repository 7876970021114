<template>
  <BaseAlert
    v-if="status == 'pending'"
    :title="$t('gallery_review_status.pending.title')"
    type="warning"
    class="border border-yellow-300"
  >
    <div v-html="$t('gallery_review_status.pending.description').markdownToHtml()" />
  </BaseAlert>
  <BaseAlert
    v-else-if="status == 'refused'"
    :title="$t('gallery_review_status.refused.title')"
    type="danger"
    class="border border-red-300"
  >
    <div v-html="$t('gallery_review_status.refused.description').markdownToHtml()" />
  </BaseAlert>
  <BaseAlert
    v-else-if="status == 'done'"
    :title="$t('gallery_review_status.done.title')"
    type="success"
    class="border border-green-300"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    status: {
      required: true,
      type: String
    }
  }
});
</script>
