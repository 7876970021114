<template>
  <div class="sm:flex sm:items-start sm:justify-between sm:space-x-10 sm:space-y-0 space-y-5">
    <div class="grow">
      <h3 class="text-md mb-2 text-lg font-medium leading-tight">
        {{ $t('page.settings.pause.title') }}
      </h3>
      <p class="leading-tight text-gray-600">
        {{ $t('page.settings.pause.description') }}
      </p>
    </div>

    <div class="shrink-0">
      <BaseButton
        class="btn-lg"
        :loading="loading"
        @click="pauseAccount()"
      >
        {{ $t('page.settings.pause.button') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { pauseAccount } from "@/services/pause";
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      loading: false,
    };
  },
  methods: {
    pauseAccount() {
      this.loading = true;

      pauseAccount(true)
        .then(() => {
          // this.$router.push({name: "member.search"});
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
