import mitt from "mitt";
import { App } from "vue";

const emitter = mitt();

export default (app: App<Element>): void => {
  app.config.globalProperties.$mitt = emitter;
};

export { emitter };
