<template>
  <div class="flex items-stretch w-full h-full overflow-hidden">
    <div class="md:w-2/3 lg:w-1/2 flex overflow-hidden">
      <div
        ref="body"
        class="flex flex-col w-full h-full px-8 py-16 overflow-x-hidden overflow-y-auto"
      >
        <div class="grow w-full max-w-xl mx-auto">
          <HeaderLogo :back-url="backUrl" />

          <slot />
        </div>
        <OnboardingLayoutFooter />
      </div>
    </div>
    <div class="md:w-1/3 lg:w-1/2 fixed top-0 right-0 h-full">
      <InspirationCarousel>
        <h2 class="font-display mb-6 text-4xl font-medium leading-none">
          {{ $t('tagline') }}
        </h2>
        <p class="text-xl font-light leading-snug">
          {{ $t('layout.tagline') }}
        </p>
      </InspirationCarousel>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OnboardingLayoutFooter from "./steps/OnboardingLayoutFooter.vue";
import InspirationCarousel from "@/layouts/InspirationCarousel.vue";
import HeaderLogo from "./components/HeaderLogo.vue";

export default defineComponent({
  components: { OnboardingLayoutFooter, InspirationCarousel, HeaderLogo },
  props: {
    backUrl: {
      default: null,
      type: String,
    }
  },
  computed: {
    showBack() : boolean {
      return ["onboarding.home", "onboarding.start", "onboarding.register"].includes(this.$route.name as string);
    }
  },
  watch: {
    "$route": {
      deep: true,
      handler() {
        this.scrollTop();
      }
    }
  },
  methods: {
    scrollTop() {
      (this.$refs.body as HTMLElement).scrollTop = 0;
    }
  }
});
</script>
