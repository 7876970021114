<template>
  <div class="relative overflow-hidden bg-white border border-gray-200 rounded">
    <div
      ref="wrapper"
      class="bg-white overflow-y-scroll overflow-x-hidden max-h-[246px]"
      @scroll="onScroll"
    >
      <div
        class="p-4"
      >
        <div
          class="prose-h1:text-lg prose-h1:font-semibold prose-p:text-gray-600 prose-sm"
          v-html="$store.state.term?.body ?? ''"
        />
      </div>
    </div>
    <div
      v-if="showScrollButton"
      class="bg-gradient-to-t from-white absolute bottom-0 left-0 w-full h-32 pointer-events-none"
    />
    <button
      v-show="showScrollButton"
      class="bottom-4 text-sm left-1/2 absolute inline-block -translate-x-1/2 pr-3 pl-2 py-0.5 bg-gray-600 rounded-full text-white"
      type="button"
      @click="scrollToBottom"
    >
      <Icon
        icon="mdi:arrow-down"
        class="animate-bounce inline"
      />
      {{ $t('scroll') }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["scrolledToBottom"],
  data () {
    return {
      intervalId: null as any,
      scrolled: false,
      scrolledToBottom: false,
    };
  },
  computed: {
    showScrollButton() : boolean {
      return !this.scrolledToBottom && !this.scrolled;
    }
  },
  mounted() {
    this.intervalId = setInterval(() => {
      this.onScroll();
    }, 500);
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    wrapper () : HTMLDivElement|null {
      const wrapper = this.$refs.wrapper as HTMLDivElement;

      if (!wrapper) {
        return null;
      }

      return wrapper;
    },
    onScroll() {
      const target = this.wrapper();

      if (target && target.scrollTop > 50) {
        this.scrolled = true;
      }

      if (target && target.childElementCount == 1 && target.children[0].textContent != "" && (target.offsetHeight + target.scrollTop + 10) >= target.scrollHeight) {
        this.setScrolledToBottom();
      }
    },
    setScrolledToBottom () {
      if (!this.scrolledToBottom) {
        this.$emit("scrolledToBottom");
      }

      this.scrolledToBottom = true;
    },
    scrollToBottom() {
      const wrapper = this.wrapper();

      if (!wrapper) {
        return ;
      }

      wrapper.scroll({ behavior: "smooth", top: wrapper.scrollHeight });
    }
  }
});
</script>
