<template>
  <div class="bg-gray-50 md:py-20 relative w-full h-full min-h-screen py-16 overflow-hidden">
    <BaseContainer size="2xl">
      <img
        src="@/assets/img/tree/tree-primary-100.svg"
        alt="Tree Tandem"
        class="opacity-[0.6] left-1/2 top-32 absolute h-[100%] -translate-x-1/2"
      >

      <div class="relative text-center">
        <h1 class="font-display mb-3 text-4xl font-semibold">
          {{ $t('state_unsubscribed.title') }}
        </h1>

        <p class="leading-normal text-gray-700">
          {{ $t('state_unsubscribed.message') }}
        </p>
      </div>
    </BaseContainer>

    <div class="mt-12">
      <BaseContainer
        size="6xl"
      >
        <ProductList @success="onSuccess()" />
      </BaseContainer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProductList from "../member/subscription/components/ProductList.vue";

export default defineComponent({
  components: {
    ProductList,
  },
  methods: {
    onSuccess () {
      this.$notify({
        message: this.$t("membership_successfully_activated")
      });
    },
  }
});
</script>
