<template>
  <InfoModal
    :model-value="modelValue"
    :title="$t('page.search.onboarding.title')"
    :button-text="$t('start_your_search')"
    video-url="https://www.youtube.com/embed/2pN0kDVWJOw"
    @confirm="close()"
    @update:model-value="onModalUpdate"
  >
    <div class="sm:text-base text-sm text-gray-600">
      <p class="">
        {{ $t('page.search.onboarding.line_1') }}
      </p>
      <p class="mt-4">
        {{ $t('page.search.onboarding.line_2') }}
      </p>
      <p class="mt-4">
        {{ $t('page.search.onboarding.line_3') }}
      </p>
    </div>
  </InfoModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {Account} from "@/models/Account";
import { cloneDeep } from "lodash-es";

export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean
    }
  },
  emits: ["update:modelValue"],
  computed: {
    firstSearch () :boolean {
      if (!this.$store.state.user) {
        return false;
      }

      return this.$store.state.user.metadata.first_search;
    }
  },
  methods: {
    onModalUpdate (payload: boolean) {
      if (!payload) {
        this.close();
      }
    },
    close () {
      this.$emit("update:modelValue", false);

      if (this.firstSearch) {
        this.sendFirstSearchDone();
      }
    },
    sendFirstSearchDone() {
      this.$api.patch("account/metadata/first-search");
      if (this.$store.state.user) {
        const user = cloneDeep(this.$store.state.user) as Account;
        user.metadata.first_search = false;
        this.$store.commit("setUser", user);
      }
    }
  }
});

</script>
