import { Product } from "@/models/Product";
import { api } from "@/services/api";
import { getDeviceReference } from "@/services/deviceReference";
import { fetchNativeProducts, getProductNativeKey } from "@/services/nativeProducts";
import { State } from "@/store";
import { Capacitor } from "@capacitor/core";
import { IAPError, IAPProduct, InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { Store } from "vuex";
import { emitter } from "./mitt";

export default async (store: Store<State>): Promise<void> => {

  document.addEventListener("deviceready", initStore);

  function initStore() {

    if (!Capacitor.isNativePlatform()) {
      return;
    }

    InAppPurchase2.error((error: IAPError) => {
      console.error("ERROR " + error.code + ": " + error.message);
    });

    fetchNativeProducts()
      .then(async (response: any) => {

        const products = response.data.data as Product[];

        products.forEach((p: Product) => {

          const inAppProductId = p[getProductNativeKey()] as string;

          // Skip if no in-app product id
          if (!inAppProductId) {
            return;
          }

          // Skip if already registered ...
          if (store.state.product.list[inAppProductId] != undefined) {
            return;
          }

          // 1. Register Product

          InAppPurchase2.register({
            id: inAppProductId,
            alias: inAppProductId,
            type: InAppPurchase2.CONSUMABLE
          });

          // 2. On update, save to vuex store

          InAppPurchase2.when(inAppProductId).updated((product: IAPProduct) => {

            // If product does not have a title, it may not exist on the App Store
            // We skip mutation to avoid missing products to the state
            if (!product.title) {
              return;
            }

            store.commit("product/set", {
              id: inAppProductId,
              product: product,
            });

          });

          // 3. On approve, verify

          InAppPurchase2.when(inAppProductId).approved((product: IAPProduct) => {
            product.verify();
          });

          // 4. On verified, perform payment success action

          InAppPurchase2.when(inAppProductId).verified(async (product: IAPProduct) => {

            await api.post("/pay/in-app", {
              product: product,
              platform: Capacitor.getPlatform(),
              device_ref: await getDeviceReference(),
            });

            await store.dispatch("fetchUser");

            product.finish();

            emitter.emit("inAppPurchaseVerified", product);
          });

          // 4.1 Cancelled or Error

          InAppPurchase2.when(inAppProductId).cancelled(async (product: IAPProduct) => {
            emitter.emit("inAppPurchaseCancelled", product);
          });

          InAppPurchase2.when(inAppProductId).error(async (product: IAPProduct) => {
            emitter.emit("inAppPurchaseError", product);
          });

          InAppPurchase2.when(inAppProductId).invalid(async (product: IAPProduct) => {
            emitter.emit("inAppPurchaseError", product);
          });

          InAppPurchase2.when(inAppProductId).expired(async (product: IAPProduct) => {
            emitter.emit("inAppPurchaseError", product);
          });

          InAppPurchase2.when(inAppProductId).unverified(async (product: IAPProduct) => {
            emitter.emit("inAppPurchaseError", product);
          });

        });

        InAppPurchase2.refresh();
      });
  }
};
