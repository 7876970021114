<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="5xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.profile.profile.title')"
        :subtitle="$t('page.settings.menu.profile.profile.subtitle')"
      />
    </BaseContainer>

    <BaseContainer size="5xl">
      <BasePanel
        :boxed="false"
        :title="$t('page.settings.profile.introduction.title')"
        :description="$t('page.settings.profile.introduction.subtitle')"
      >
        <BaseCard>
          <BaseCardRow>
            <IntroductionForm />
          </BaseCardRow>
        </BaseCard>
      </BasePanel>

      <BasePanel
        :title="$t('audio_message')"
        :description="$t('audio_message_description')"
      >
        <AudioIntroduction />
      </BasePanel>

      <BasePanel
        :title="$t('page.settings.profile.sexual_orientation.title')"
        :description="$t('page.settings.profile.sexual_orientation.subtitle')"
      >
        <UpdateSexualOrientation />
      </BasePanel>

      <BaseLazy>
        <QuestionItemsSection />
      </BaseLazy>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AudioIntroduction from "./components/AudioIntroduction.vue";
import QuestionItemsSection from "./components/QuestionItemsSection.vue";
import UpdateSexualOrientation from "./components/UpdateSexualOrientation.vue";

export default defineComponent({
  components: {
    AudioIntroduction,
    QuestionItemsSection,
    UpdateSexualOrientation,
  }
});
</script>
