<template>
  <div
    class="rounded-lg shadow-lg"
    :class="[
      mode == 'satellite' ?
        'absolute bottom-5 right-3 md:right-10 w-1/5 sm:w-1/4 md:w-1/5 lg:w-1/6' :
        'bg-black grow relative flex w-full h-full'
    ]"
  >
    <div
      class="relative items-center justify-center w-full h-full overflow-hidden rounded-lg"
      :class="{'invisible': !hasVideo}"
    >
      <slot />
    </div>
    <div
      v-if="!hasVideo"
      class="absolute z-[1] inset-0 flex items-center justify-center w-full h-full py-10 overflow-hidden bg-gray-700 rounded-lg"
    >
      <div class="p-5 text-white bg-gray-600 rounded-full">
        <Icon
          icon="mdi:video-off-outline"
          class="w-8 h-8"
        />
      </div>
    </div>
    <div
      v-if="mode == 'block'"
      class="bottom-3 left-3 absolute w-full text-left"
    >
      <div class="inline-block px-3 py-1 text-white bg-black bg-opacity-50 rounded-md">
        {{ name }}
      </div>
    </div>

    <div
      v-if="!hasAudio"
      class="top-3 right-3 absolute w-full text-right"
    >
      <div class="inline-block p-2 text-white bg-black bg-opacity-50 rounded-full">
        <Icon icon="mdi:microphone-off" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    mode: {
      required: true,
      type: String as PropType<"block"|"satellite">,
    },
    name: {
      required: true,
      type: String,
    },
    hasAudio: {
      default: false,
      type: Boolean,
    },
    hasVideo: {
      default: false,
      type: Boolean,
    }
  }
});
</script>
