
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "vue";
import { Router } from "vue-router";

export default {
  install: (app: App, router: Router): void => {

    if (import.meta.env.VITE_SENTRY_DSN) {

      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `tandem-app@${import.meta.env.PACKAGE_VERSION}`,
        environment: import.meta.env.MODE,
        allowUrls: [
          /http:\/\/((app)\.)?tandem\.test/,
          /https:\/\/((app|www|staging|staging-app)\.)?effettandem\.com/,
        ],
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "app.effettandem.com", "staging-app.effettandem.com", "tandem.test", /^\//],
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }

    app.config.globalProperties.$sentry = Sentry;
  }
};

export { Sentry };
