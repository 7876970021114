<template>
  <VInput
    ref="address1Field"
    :model-value="modelValue.address_1 ?? ''"
    :label="$t('address')"
    name="address_1"
    input-class="w-full"
    required
    :placeholder="$t('address_1_placeholder')"
    class="mb-2"
    prevent-submit
    @update:model-value="update('address_1', $event)"
  />
  <VInput
    :model-value="modelValue.address_2 ?? ''"
    name="address_2"
    :placeholder="$t('address_2_description')"
    input-class="w-full"
    class="mb-4"
    @update:model-value="update('address_2', $event)"
  />
  <div class="sm:flex sm:space-x-3">
    <VInput
      :model-value="modelValue.city ?? ''"
      :label="$t('city')"
      name="city"
      input-class="w-full"
      class="flex-1 mb-4"
      required
      @update:model-value="update('city', $event)"
    />
    <VInput
      :model-value="modelValue.postal_code ?? ''"
      :label="$t('postal_code_zip_code')"
      name="postal_code"
      input-class="w-full"
      class="flex-1 mb-4"
      required
      @update:model-value="update('postal_code', $event)"
    />
  </div>
  <div class="sm:flex sm:space-x-3">
    <VInput
      :model-value="modelValue.state ?? ''"
      :label="$t('state_province')"
      name="state"
      input-class="w-full"
      class="flex-1 mb-4"
      required
      @update:model-value="update('state', $event)"
    />
    <VInputSelect
      :model-value="modelValue.country ?? ''"
      :label="$t('country')"
      name="country"
      input-class="w-full"
      class="flex-1 mb-4"
      required
      @update:model-value="update('country', $event)"
    >
      <option
        v-for="country in countries"
        :key="country.id"
        :value="country.id"
      >
        {{ country.name }}
      </option>
    </VInputSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VInput from "@/plugins/form/inputs/Text.vue";
import { cloneDeep } from "lodash-es";
import { Country } from "@/models/Country";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Object
    }
  },
  emits: ["update:model-value"],
  data() {
    return {
      // eslint-disable-next-line no-undef
      autocomplete: null as google.maps.places.Autocomplete | null,
    };
  },
  computed: {
    autocompleteInput(): HTMLInputElement {
      return (this.$refs.address1Field as typeof VInput).$refs.input as HTMLInputElement;
    },
    countries () : Country[] {
      //return this.$store.state.countries.list;
      return [
        {id: "ca", name: "Canada"}
      ];
    }
  },
  created () {
    if (this.countries.length == 0) {
      this.$store.dispatch("countries/fetch");
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput, {
      fields: ["address_components"],
      types: ["address"],
      componentRestrictions: { country: "ca" },
    });
    this.autocomplete.addListener("place_changed", this.fillAddress);
  },
  methods: {
    fillAddress() {
      if (!this.autocomplete) {
        return;
      }
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace();
      let address1 = "";
      let postcode = "";


      const newForm = cloneDeep(this.modelValue);

      if (!place.address_components) {
        return;
      }

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      // eslint-disable-next-line no-undef
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case "route": {
            address1 += component.long_name;
            break;
          }
          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }
          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case "locality":
            newForm.city = component.long_name;
            break;
          case "administrative_area_level_1": {
            newForm.state = component.short_name;
            break;
          }
          case "country":
            newForm.country = component.short_name.toLowerCase();
            break;
        }
      }
      this.$nextTick(() => {
        newForm.address_1 = address1;
        // Force value change
        this.autocompleteInput.value = address1;
        newForm.postal_code = postcode;
        this.$emit("update:model-value", newForm);
      });
    },
    update(field: string, value: string) {
      const newForm = cloneDeep(this.modelValue);
      newForm[field] = value;
      this.$emit("update:model-value", newForm);
    }
  }
});
</script>


<style lang="postcss">
.pac-container {
  @apply font-sans shadow-md border border-gray-200 rounded-lg;

  & .pac-item {
    @apply flex items-center py-2 pl-2 cursor-pointer;
  }

  & .pac-icon {
    @apply m-0 mr-2;
  }

  & .pac-item, & .pac-item-query, & .pac-matched {
    @apply text-base;
  }

  & .pac-matched {
    @apply font-semibold;
  }

  &:after {
    background-image: none !important;
    @apply p-0 h-0;
  }
}
</style>
