<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    class="text-red-600"
  >
    <g>
      <g
        :style="{
          transformOrigin: '16px 16px',
          transform: 'scale(0.5)'
        }"
      >
        <g
          :class="{
            'text-gray-400': !active,
            'text-red-600': active,
            'heart-out': !active && touched,
            'heart-in': active && touched,
          }"
          :style="{
            transformOrigin: '16px 16px',
          }"
        >

          <path
            d="M26.132 8.064c-.498-.532-2.218-2.064-4.592-2.06-1.303-.002-2.592.563-3.679 1.367A9.755 9.755 0 0 0 16 9.19a9.767 9.767 0 0 0-1.86-1.82c-1.085-.803-2.373-1.37-3.672-1.367-2.475.006-4.098 1.535-4.59 2.06-2.504 2.677-2.504 6.944 0 9.621l1.631 1.743.002.002 7.76 8.255a1 1 0 0 0 1.46-.004l7.685-8.253.001-.002 1.623-1.734c2.6-2.68 2.596-6.951.092-9.628z"
            fill="currentColor"
          />
        </g>
      </g>

      <circle
        :class="{
          'opacity-0': !active && touched,
          'circle-animate': active && touched,
        }"
        :style="{
          transformOrigin: '16px 16px'
        }"
        fill="currentColor"
        opacity="0"
        cx="16"
        cy="16"
        r="0.6"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      touched: false,
    };
  },
  watch: {
    active () {
      this.touched = true;
    }
  }
});
</script>

<style scoped>

.circle-animate {
  animation: animateCircle .3s linear forwards;
}

.heart-in {
  animation: animateHeartIn .3s linear forwards;
}

.heart-out {
  animation: animateHeartOut .3s linear forwards;
}

@keyframes animateCircle {
  40%{transform:scale(10); opacity:0.1; fill:#DD4688;}
  55%{transform:scale(11); opacity:0.1; fill:#D46ABF;}
  65%{transform:scale(12); opacity:0.1; fill:#CC8EF5;}
  75%{transform:scale(13); opacity:0.1; fill:transparent; stroke:#CC8EF5; stroke-width:.5;}
  85%{transform:scale(17); opacity:0.1; fill:transparent; stroke:#CC8EF5; stroke-width:.2;}
  95%{transform:scale(18); opacity:0.1; fill:transparent; stroke:#CC8EF5; stroke-width:.1;}
  100%{transform:scale(19); opacity:0.1; fill:transparent; stroke:#CC8EF5; stroke-width:0;}
}

@keyframes animateHeartIn {
  0%{transform: scale(1.4);}
  100%{transform: scale(1);}
}

@keyframes animateHeartOut {
  0%{transform: scale(1.1);}
  100%{transform: scale(1);}
}
</style>
