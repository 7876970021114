<template>
  <a
    v-if="isNativePlatform"
    :href="`fb-messenger://share?link=${linkEncoded}&app_id=${facebookAppId}`"
    :title="$t('share_on_facebook')"
  >
    <slot />
  </a>

  <a
    v-else
    :href="`http://www.facebook.com/dialog/send?link=${linkEncoded}&app_id=${facebookAppId}&redirect_uri=${encodeURIComponent(currentUrl)}`"
    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
    :title="$t('share_on_facebook')"
  >
    <slot />
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  props: {
    link: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      shareByMailModal: false,
      isNativePlatform: Capacitor.isNativePlatform()
    };
  },
  computed: {
    currentUrl() : string {
      return this.mutateUrlOnLocal(window.location.href);
    },
    linkEncoded() : string {
      let url = this.mutateUrlOnLocal(this.link);
      return encodeURIComponent(url);
    },
    facebookAppId() : string {
      return import.meta.env.VITE_FACEBOOK_CLIENT_ID;
    }
  },
  methods: {
    mutateUrlOnLocal(url: string) : string {
      if (url.startsWith("http://localhost:8101")) {
        return url.replace("http://localhost:8101", "https://effettandem.com");
      }
      return url;
    }
  }
});
</script>
