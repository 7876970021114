<template>
  <AudioPlayer
    v-if="audio"
    :audio="audio"
  >
    <template #default="{toggle, playing, duration, currentTime, loading}">
      <button
        type="button"
        class="relative flex items-center justify-center w-full h-full py-1 pl-2 pr-3 mb-4 overflow-hidden bg-gray-100 border border-gray-200 rounded-lg shadow-sm"
        @click="toggle()"
      >
        <div class="shrink-0 mr-3">
          <Icon
            v-if="!playing"
            icon="heroicons-solid:play"
            class="w-8 h-8 text-gray-500"
          />
          <Icon
            v-else
            icon="heroicons-solid:pause"
            class="w-8 h-8 text-gray-500"
          />
        </div>
        <div class="grow relative h-2">
          <div class="w-full h-full bg-gray-200 rounded-full" />
          <div
            class="bg-primary-600 absolute top-0 left-0 h-full rounded-full"
            :style="{width: (100*currentTime/duration) + '%'}"
          />
          <div
            class="absolute top-0 w-4 h-4 -translate-x-1 -translate-y-1 bg-white rounded-full shadow"
            :style="{left: (100*currentTime/duration) + '%'}"
          />
        </div>
        <div class="shrink-0 ml-5 font-mono text-sm text-gray-700">
          {{ currentTime.timerFormat() }}
        </div>
        <BaseLoadingCover
          :model-value="loading"
          icon-class="w-6 h-6 text-blue-600"
        />
      </button>
    </template>
  </AudioPlayer>
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    audio: {
      required: true,
      type: String
    }
  }
});
</script>

