<template>
  <BaseButton
    :loading="loading"
    class="btn btn-lg sm:btn-xl btn-white"
    type="button"
    @click="$emit('click')"
  >
    {{ $t('i_prefer_not_to_answer') }}
  </BaseButton>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loading: {
      default: false,
      type: Boolean,
    }
  },
  emits: ["click"]
});
</script>
