<template>
  <svg
    v-once
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 10.5C10.3431 10.5 9 11.8431 9 13.5C9 15.1569 10.3431 16.5 12 16.5C13.6569 16.5 15 15.1569 15 13.5C15 11.8431 13.6569 10.5 12 10.5ZM10.5 13.5C10.5 12.6716 11.1716 12 12 12C12.8284 12 13.5 12.6716 13.5 13.5C13.5 14.3284 12.8284 15 12 15C11.1716 15 10.5 14.3284 10.5 13.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.427 3.13336C12.1701 2.95555 11.83 2.95555 11.5731 3.13336L1.82315 9.88336C1.55341 10.0701 1.43635 10.4105 1.53417 10.7236C1.63199 11.0368 1.92199 11.25 2.25006 11.25H3.75006C4.16427 11.25 4.50006 11.5858 4.50006 12V18.75C4.50006 19.9926 5.50741 21 6.75006 21H17.2501C18.4927 21 19.5001 19.9926 19.5001 18.75V12C19.5001 11.5858 19.8358 11.25 20.2501 11.25H21.7501C22.0781 11.25 22.3681 11.0368 22.4659 10.7236C22.5638 10.4105 22.4467 10.0701 22.177 9.88336L12.427 3.13336ZM6.00006 12C6.00006 11.0119 5.36312 10.1726 4.47743 9.87017L12.0001 4.6622L19.5227 9.87017C18.637 10.1726 18.0001 11.0119 18.0001 12V18.75C18.0001 19.1642 17.6643 19.5 17.2501 19.5H6.75006C6.33584 19.5 6.00006 19.1642 6.00006 18.75V12Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
