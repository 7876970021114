<template>
  <Transition
    enter-active-class="transition duration-300 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-300 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="modelValue"
      class="inset-0 flex items-center justify-center w-full h-full"
      :style="{position: position}"
    >
      <div
        class="absolute w-full h-full"
        :class="backdropClass"
      />
      <LoadingLine
        v-if="icon == 'line'"
        class="animate-spin"
        :class="iconClass"
      />
      <BaseSpinner
        v-else-if="icon == 'spinner'"
        class="animate-spin"
        :class="iconClass"
      />
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadingLine from "@/plugins/icons/LoadingLine.vue";

export default defineComponent({
  components: {
    LoadingLine,
  },
  props: {
    modelValue: {
      default: true,
      type: Boolean
    },
    backdropClass: {
      default: "bg-white",
      type: String
    },
    iconClass: {
      default: "text-primary-500 w-10 h-10",
      type: String
    },
    icon: {
      default: "line",
      type: String as PropType<"line"|"spinner">
    },
    position: {
      default: "absolute",
      type: String as PropType<any>,
    },
  },
});
</script>
