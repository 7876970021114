<template>
  <BaseContainer
    paddingless-mobile
  >
    <div class="md:space-x-9 md:flex">
      <div class="md:shrink-0 md:w-1/3">
        <div class="aspect-w-8 aspect-h-8 relative">
          <BaseSkeleton class="w-full h-full" />
        </div>

        <BaseSkeleton class="h-52 md:block hidden w-full mt-6 opacity-50" />
      </div>
      <div class="md:w-2/3 md:mt-0 md:px-0 px-4 mt-6">
        <div class="md:flex md:justify-between">
          <BaseSkeleton class="w-52 md:h-12 h-10" />
          <BaseSkeleton class="md:hidden w-64 h-8 mt-2" />
          <div class="md:mt-0 flex mt-4 space-x-4">
            <BaseSkeleton class="w-16 h-12" />
            <BaseSkeleton class="w-16 h-12" />
          </div>
        </div>
        <BaseSkeleton class="h-52 w-full mt-8" />
        <BaseSkeleton class="h-52 w-full mt-6" />
        <BaseSkeleton class="h-52 w-full mt-6" />
        <BaseSkeleton class="h-52 w-full mt-6" />
        <BaseSkeleton class="h-52 w-full mt-6" />
      </div>
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

});
</script>
