<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="onModelValueUpdate"
  >
    <div class="sm:p-10 p-8 pb-12">
      <div class="max-w-lg mx-auto">
        <div class=" flex flex-col items-center justify-center mb-6">
          <div class="p-3 mb-4 bg-green-100 rounded-full">
            <Icon
              icon="mdi:check-circle"
              class="w-10 h-10 text-green-600"
            />
          </div>
          <h2 class="font-display mb-3 text-2xl font-semibold leading-tight text-center">
            {{ $t('payment_success_component.title') }}
          </h2>
          <p class="leading-tight text-center text-gray-600">
            {{ $t('payment_success_component.subtitle') }} {{ $t('your_subscription_ends_on_x', {x: $luxon.fromISO(member.subscription_end_date ?? '').setLocale($i18n.locale).toFormat('DD')}) }}.
          </p>
        </div>
        <div class="mt-4 text-center">
          <button
            type="button"
            class="btn btn-primary btn-xl"
            @click="onConfirm"
          >
            {{ $t('find_your_partner_in_love') }}
          </button>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { AccountMember } from "@/models/Account";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    member: {
      required: true,
      type: Object as PropType<AccountMember>
    }
  },
  emits: ["confirm"],
  methods: {
    onModelValueUpdate(value: boolean) {
      if (value == false) {
        this.onConfirm();
      }
    },
    onConfirm () {
      this.$emit("confirm");
    }
  }
});
</script>
