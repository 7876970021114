<template>
  <BaseContainer
    size="4xl"
  >
    <div>
      <dl class="sm:grid-cols-4 grid grid-cols-1 gap-4 mt-5">
        <div class="sm:p-6 px-4 py-5 overflow-hidden bg-white rounded-lg shadow">
          <dt class="text-sm font-medium text-gray-500 truncate">
            <span v-if="!loading">{{ $t('visits') }}</span>
            <span v-else>--</span>
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.visits }}
          </dd>
        </div>

        <div class="sm:p-6 px-4 py-5 overflow-hidden bg-white rounded-lg shadow">
          <dt class="text-sm font-medium text-gray-500 truncate">
            <span v-if="!loading">{{ $t('conversions') }}</span>
            <span v-else>--</span>
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.conversions }}
          </dd>
        </div>

        <div class="sm:p-6 px-4 py-5 overflow-hidden bg-white rounded-lg shadow">
          <dt class="text-sm font-medium text-gray-500 truncate">
            <span v-if="!loading">{{ $t('generated_rewards') }}</span>
            <span v-else>--</span>
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.total.priceFormat() }}
          </dd>
        </div>

        <div class="sm:p-6 px-4 py-5 overflow-hidden bg-white rounded-lg shadow">
          <dt class="text-sm font-medium text-gray-500 truncate">
            <span v-if="!loading">{{ $t('bank_balance') }}</span>
            <span v-else>--</span>
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.balance.priceFormat() }}
          </dd>
        </div>
      </dl>
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      loading: false,
      stats: {
        visits: 0,
        conversions: 0,
        conversion_rate: 0,
        balance: 0,
        paid: 0,
        pending: 0,
        total: 0,
      }
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.get("account/ambassador/stats")
        .then(response => {
          this.stats = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
