<template>
  <div>
    <div class="sm:hidden">
      <label
        for="tabs"
        class="sr-only"
      >Select a tab</label>
      <select
        id="tabs"
        v-model="currentLink"
        name="tabs"
        class="focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md"
        @change="$router.push(currentLink.link)"
      >
        <option
          v-for="link in links"
          :key="link.link"
          :value="link"
        >
          {{ link.label }}
        </option>
      </select>
    </div>
    <div class="sm:block hidden">
      <div class="border-b border-gray-200">
        <nav
          class="flex -mb-px space-x-8"
          aria-label="Tabs"
        >
          <router-link
            v-for="link in links"
            :key="link.link"
            :to="link.link"
            exact-active-class="text-primary-600 border-primary-600 hover:border-primary-600 hover:text-primary-600"
            class="hover:text-gray-700 hover:border-gray-300 whitespace-nowrap px-1 py-4 text-base font-medium text-gray-500 border-b-2 border-transparent"
          >
            {{ link.label }}
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

interface Link {
  link: string,
  label: string
}

export default defineComponent({
  props: {
    links: {
      required: true,
      type: Array as PropType<Link[]>
    }
  },
  data () {
    return {
      currentLink: this.links[0]
    };
  }
});
</script>
