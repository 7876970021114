<template>
  <ProtectionLayout>
    <PageMessagesContent />
  </ProtectionLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageMessagesContent from "./PageMessagesContent.vue";
import ProtectionLayout from "../ProtectionLayout.vue";

export default defineComponent({
  name: "PageMessages",
  components: {
    PageMessagesContent,
    ProtectionLayout
  },
});
</script>
