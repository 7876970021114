import { Question } from "@/models/Question";
import { QuestionOption } from "@/models/QuestionOption";
import { api } from "@/services/api";
import { cloneDeep } from "lodash-es";
import i18n from "@/plugins/i18n";

export type QuestionList = { [questionName: string]: Question };

export interface QuestionState {
  items: Question[]
}

export const defaultQuestionState = {
  items: [],
} as QuestionState;

export const questionModule = {
  namespaced: true,
  state() {
    return defaultQuestionState;
  },
  mutations: {
    set(state: QuestionState, questions: Question[]) {
      state.items = questions;
    }
  },
  getters: {
    listByName(state: QuestionState) {
      const questionList = {} as { [name: string]: Question };
      state.items.forEach((q: Question) => {
        questionList[q.name] = cloneDeep(q);
      });
      return questionList;
    },
    listById(state: QuestionState) {
      const questionList = {} as { [id: string]: Question };
      state.items.forEach((q: Question) => {
        questionList[q.id] = cloneDeep(q);
      });
      return questionList;
    },
    getById: (state: QuestionState, getters: any) => (id: string): Question | null => {
      const question = (getters.listById[id] ?? null) as Question | null;
      if (question) {
        return question;
      }
      return null;
    },
    getByType: (state: QuestionState) => (questionType: string): Question[] => {
      return state.items.filter(question => {
        return question.type.name == questionType;
      });
    },
    getByName: (state: QuestionState, getters: any) => (questionName: string): Question | null => {
      const question = (getters.listByName[questionName] ?? null) as Question | null;
      if (question) {
        return question;
      }
      return null;
    },
    options: (state: QuestionState, getters: any) => (questionName: string): QuestionOption[] => {

      const question = getters.getByName(questionName) as Question | null;

      if (!question) {
        return [];
      }

      return question.options;
    },
    option: (state: QuestionState, getters: any) => (questionName: string, optionId: string): QuestionOption | null => {

      const options = getters.options(questionName) as QuestionOption[];

      const option = options.find(o => o.id == optionId);

      if (option) {
        return option;
      }
      return null;
    },
    dependantQuestions: (state: QuestionState) => (question: Question, optionIds: string[]): Question[] => {
      return Object.values(state.items)
        .filter(q => {
          return q.dependencies.filter(dependency => {
            const sameQuestion = dependency.question_id == question.id;
            const optionMatch = dependency.options.filter(value => optionIds.includes(value)).length > 0;
            return sameQuestion && optionMatch;
          }).length > 0;
        });
    }
  },
  actions: {
    fetch(store: any): Promise<null> {
      return new Promise((resolve, reject) => {
        api.get("questions")
          .then(response => {
            store.commit("set", response.data.data);
            resolve(null);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    },
  }
};
