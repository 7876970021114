<template>
  <slot
    :loading="loading"
    :playing="playing"
    :duration="duration"
    :currentTime="currentTime"
    :toggle="toggle"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

export default defineComponent({
  props: {
    audio: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      playing: false,
      audioElement: new Audio(),
      duration: 1,
      currentTime: 0,
    };
  },
  computed: {
    loading() : boolean {
      return !this.ready;
    }
  },
  watch: {
    audio () {
      this.init();
    }
  },
  created () {
    this.init();
    this.audioElement.addEventListener("canplay", this.onLoad);
    this.audioElement.addEventListener("pause", this.onPause);
    this.audioElement.addEventListener("ended", this.onEnded);
    this.audioElement.addEventListener("timeupdate", this.onTimeUpdate);
  },
  beforeUnmount() {
    this.audioElement.removeEventListener("canplay", this.onLoad);
    this.audioElement.removeEventListener("pause", this.onPause);
    this.audioElement.removeEventListener("ended", this.onEnded);
    this.audioElement.removeEventListener("timeupdate", this.onTimeUpdate);
  },
  methods: {
    init () {
      this.ready = false;
      this.audioElement.src = this.audio;
      this.audioElement.load();
      this.audioElement.currentTime = 0;
    },
    toggle() {
      if (!this.audioElement.paused) {
        this.audioElement.pause();
        return;
      }

      Haptics.impact({ style: ImpactStyle.Light });

      if (this.ready) {
        this.audioElement.play();
        this.playing = true;
      }
    },
    onLoad() {
      this.duration = this.audioElement.duration;
      this.ready = true;
    },
    onPause() {
      this.playing = false;
    },
    onEnded() {
      this.playing = false;
    },
    onTimeUpdate() {
      this.currentTime = this.audioElement.currentTime;
    }
  },
});
</script>

