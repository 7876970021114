<template>
  <ProfileSection>
    <ProfileSectionBox>
      <ProfileSectionBoxRow>
        <div class="space-y-5">
          <div
            v-if="objective.length"
            class="flex space-x-5"
          >
            <img
              src="/img/member/icons/streamline-icon-love-heart-arrow@30x30.svg"
              class="w-9 h-9"
            >
            <div>
              <h3 class="leading-tight text-gray-600">
                {{ $t('objective') }}
              </h3>
              <p class="text-lg font-semibold leading-tight text-gray-900">
                {{ objective.map((o: any) => o.label).join(', ') }}
              </p>
            </div>
          </div>
          <!---->
          <div
            v-if="height"
            class="flex space-x-5"
          >
            <img
              src="/img/member/icons/streamline-icon-ruler@30x30.svg"
              class="w-9 h-9"
            >
            <div>
              <h3 class="leading-tight text-gray-600">
                {{ $t('height') }}
              </h3>
              <p class="text-lg font-semibold leading-tight text-gray-900">
                {{ $display.height(height) }}
              </p>
            </div>
          </div>
          <!---->
          <div
            v-if="silhouette"
            class="flex space-x-5"
          >
            <img
              src="/img/member/icons/streamline-icon-single-man_1@30x30.svg"
              class="w-9 h-9"
            >
            <div>
              <h3 class="leading-tight text-gray-600">
                {{ $t('silhouette') }}
              </h3>
              <p class="text-lg font-semibold leading-tight text-gray-900">
                {{ silhouette.label }}
              </p>
            </div>
          </div>
          <!---->
          <div
            v-if="languages.length"
            class="flex space-x-5"
          >
            <img
              src="/img/member/icons/streamline-icon-ecology-globe-message@30x30.svg"
              class="w-9 h-9"
            >
            <div>
              <h3 class="leading-tight text-gray-600">
                {{ $t('spoken_languages') }}
              </h3>
              <p class="text-lg font-semibold leading-tight text-gray-900">
                {{ languages.map((l: any) => l.label).join(', ') }}
              </p>
            </div>
          </div>
        </div>
      </ProfileSectionBoxRow>
    </ProfileSectionBox>
  </ProfileSection>
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { QuestionOption } from "@/models/QuestionOption";
import { defineComponent, inject, PropType } from "vue";
import { AnswerWithQuestion } from "../../types";
import ProfileSection from "./ProfileSection.vue";
import ProfileSectionBox from "./ProfileSectionBox.vue";
import ProfileSectionBoxRow from "./ProfileSectionBoxRow.vue";

export default defineComponent({
  components: {
    ProfileSection,
    ProfileSectionBox,
    ProfileSectionBoxRow
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    }
  },
  setup () {
    const answerByName = inject("answerByName") as (name: string) => AnswerWithQuestion|null;

    return {
      answerByName
    };
  },
  computed: {
    objective () : QuestionOption[] {
      const answer = this.answerByName("objective");
      if (!answer) {
        return [];
      }
      return answer.options;
    },
    height () : number|null {
      const answer = this.answerByName("height");
      if (!answer) {
        return null;
      }
      return +answer.value;
    },
    silhouette() : QuestionOption|null {
      const answer = this.answerByName("silhouette");
      if (!answer) {
        return null;
      }
      return answer.options[0] ?? null;
    },
    languages() : QuestionOption[] {
      const answer = this.answerByName("languages");
      if (!answer) {
        return [];
      }
      return answer.options;
    }
  }
});
</script>
