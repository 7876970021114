<template>
  <div class="relative">
    <OnboardingTitle>
      {{ $t('step.introduction.title') }}
    </OnboardingTitle>

    <IntroductionForm @success="onSuccess">
      <template #submit="{completed}">
        <div class="mt-6">
          <StepButtons
            :next-disabled="!completed"
            @previous="previousStep"
          />
        </div>
      </template>
    </IntroductionForm>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: { OnboardingTitle, StepButtons },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  },
  methods: {
    onSuccess () {
      this.nextStep();
    }
  }
});
</script>
