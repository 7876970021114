<template>
  <div
    v-if="!loading"
    class="px-8 py-20 text-center"
  >
    <div class="max-w-sm mx-auto">
      <div v-if="!isEmpty">
        <h2 class="mb-2 font-semibold leading-tight text-gray-900">
          {{ $t('chat.no_conversation_selected') }}
        </h2>
        <p class="leading-tight text-gray-700">
          {{ $t('page.messages.no_selection.subtitle') }}
        </p>
      </div>
      <div v-else-if="isEmpty && priority == 'priority'">
        <img
          src="@/assets/img/empty-states/no-conversation.svg"
          alt="No conversation"
          class="max-w-[200px] w-full mx-auto mb-10"
        >
        <h2 class="mb-3 text-lg font-semibold leading-tight text-gray-900">
          {{ $t('page.messages.no_conversation.title') }}
        </h2>
        <p class="mb-6 leading-tight text-gray-700">
          {{ $t('page.messages.no_conversation.subtitle') }}
        </p>
        <router-link
          class="btn btn-primary btn-lg"
          to="/member/search"
        >
          {{ $t('page.messages.no_conversation.button') }}
        </router-link>
      </div>
      <div v-else-if="isEmpty && priority == 'secondary'">
        <img
          src="@/assets/img/empty-states/no-secondary-conversation.svg"
          alt="No conversation"
          class="max-w-[200px] w-full mx-auto mb-10"
        >
        <h2 class="mb-3 text-lg font-semibold leading-tight text-gray-900">
          {{ $t('page.messages.no_secondary_conversation.title') }}
        </h2>
        <p class="mb-6 leading-tight text-gray-700">
          {{ $t('page.messages.no_secondary_conversation.subtitle') }}
        </p>
        <router-link
          class="btn btn-primary btn-lg"
          to="/member/settings/secondary-conversation-rules"
        >
          {{ $t('chat.secondary.notice.button') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Conversation } from "@/models/Conversation";
import { computed, ComputedRef, defineComponent, inject } from "vue";

export default defineComponent({
  name: "BaseConversationEmpty",
  setup () {
    const loading = inject("loading") as boolean;
    const priority = inject("priority") as ComputedRef<string>;
    const conversations = inject("conversations") as { [id: string]: Conversation };

    const isEmpty = computed(() => {
      return Object.keys(conversations).length > 0;
    });

    return {
      loading,
      priority,
      isEmpty
    };
  }
});
</script>
