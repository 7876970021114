<template>
  <div class="relative">
    <OnboardingTitle>
      {{ $t('step.authentication.title') }}
    </OnboardingTitle>

    <OnboardingText class="mb-5">
      <div v-html="$t('step.authentication.text').markdownToHtml('underline-offset-1 decoration-2 decoration-gray-300 text-gray-700 font-medium underline')" />
    </OnboardingText>

    <div class="mb-10">
      <BaseAlert
        v-if="eidSuccess"
        type="success"
        class="mb-4 border border-green-400"
      >
        <p>{{ $t('step.authentication.success_text') }}</p>
      </BaseAlert>
      <template
        v-else
      >
        <BaseAlert
          type="warning"
          class="mb-4 border border-yellow-400"
        >
          <p
            class="text-lg leading-snug"
            v-html="$t('step.authentication.warning').markdownToHtml('underline-offset-1 decoration-2 decoration-yellow-500 text-yellow-800 font-semibold underline')"
          />
        </BaseAlert>
      </template>
    </div>

    <VForm
      :data="form"
      method="post"
      url="onboarding/authenticate"
      loading-mask-class="bg-white"
      :http-client="$api_"
      @success="onSuccess"
      @error="onError"
    >
      <div class="mb-5">
        <div class="flex flex-wrap -mx-2 -my-1">
          <VInput
            v-model="form.first_name"
            required
            :label="$t('first_name')"
            name="first_name"
            :disabled="eidSuccess"
            class="grow px-2 py-1"
            input-class="w-full"
          />
          <VInput
            v-model="form.last_name"
            required
            :label="$t('last_name')"
            name="last_name"
            :disabled="eidSuccess"
            class="grow px-2 py-1"
            input-class="w-full"
          />
        </div>
      </div>

      <VInputDate
        v-model="form.birthday"
        required
        :label="$t('birthday')"
        name="birthday"
        :disabled="eidSuccess"
        class="mb-5"
        input-class="w-full"
      />

      <div class="mb-6">
        <AddressForm v-model="form" />
      </div>

      <div>
        <VInputCheckbox
          v-model="form.accept"
          name="accept"
          size="lg"
          ping
        >
          <template #description>
            <div class="-mt-0.5 text-base leading-snug text-gray-700 cursor-pointer">
              <p class="mb-1">
                {{ $t('step.authentication.accept.text') }}
              </p>
              <p class="mb-1">
                {{ $t('step.authentication.accept.text_2') }}
              </p>
              <p>{{ $t('step.authentication.accept.text_3') }}</p>
            </div>
          </template>
        </VInputCheckbox>
      </div>

      <button
        type="button"
        class="bg-blue-50 flex w-full p-4 mt-4 space-x-3 rounded-md"
        @click="openEidCompareVideo"
      >
        <Icon
          icon="heroicons-outline:information-circle"
          class="shrink-0 w-4 h-4 text-blue-600"
        />
        <div class="text-sm leading-tight text-left text-blue-900">
          <p>{{ $t('learn_more_about_eid_compare') }}</p>
        </div>
      </button>

      <div class="mt-10">
        <StepButtons
          :next-disabled="nextDisabled"
          @previous="previousStep"
        />
      </div>
    </VForm>

    <!-- This modal is shown when no match found or wrong birthday was given -->
    <BaseModal :model-value="showWarningModal">
      <div class="-top-5 absolute flex justify-center w-full mb-3">
        <div class="flex items-center justify-center w-10 h-10 bg-white rounded-full">
          <Icon
            icon="heroicons-solid:exclamation-circle"
            class="w-7 h-7 inline text-center text-yellow-500"
          />
        </div>
      </div>
      <div class="p-8">
        <h2 class="mb-4 text-lg font-semibold leading-5 text-yellow-600">
          {{ $t('step.authentication.warning_modal.title') }}
        </h2>

        <p
          class="leading-tight text-gray-700"
          v-html="warningModalMessage"
        />

        <p
          v-if="($store.state.user?.eid_tries ?? 0) > 0"
          class="mt-5 font-semibold leading-tight text-gray-900"
          v-html="$tc('step.authentication.remaining_attempts', 3 - ($store.state.user?.eid_tries ?? 0)).markdownToHtml('font-semibold')"
        />

        <button
          type="button"
          class="btn btn-warning sm:w-auto w-full mt-5"
          @click="warningModalMessage = ''"
        >
          {{ $t('try_again') }}
        </button>

        <hr class="my-6 border-gray-200">

        <h2 class="mb-4 text-lg font-semibold leading-5 text-gray-900">
          {{ $t('manual_authentication') }}
        </h2>

        <p class="mb-4 leading-tight text-gray-700">
          {{ $t('step.authentication.no_match_skip_text') }}
        </p>

        <p class="mb-4 leading-tight text-gray-700">
          {{ $t('step.authentication.no_match_skip_text_2') }}
        </p>

        <AuthenticationPhoneForm
          @success="nextStep()"
        >
          <template #button>
            <button
              type="submit"
              class="btn btn-warning sm:w-auto w-full"
            >
              {{ $t('continue_my_registration') }}
            </button>
          </template>
        </AuthenticationPhoneForm>
      </div>
    </BaseModal>

    <!-- This modal is shown when error occurs (max tries, api error, server, error, etc.) -->
    <BaseModal :model-value="showErrorModal">
      <div class="-top-5 absolute flex justify-center w-full mb-3">
        <div class="flex items-center justify-center w-10 h-10 bg-white rounded-full">
          <Icon
            icon="heroicons-solid:exclamation-circle"
            class="w-7 h-7 inline text-center text-yellow-500"
          />
        </div>
      </div>
      <div class="p-8">
        <div class="flex items-start mb-3 space-x-2">
          <h2 class="text-lg font-semibold leading-5 text-yellow-600">
            {{ errorModalMessage }}
          </h2>
        </div>

        <p class="mb-5 leading-tight text-gray-700">
          {{ $t('step.authentication.error.line_1') }}
        </p>

        <p class="mb-5 leading-tight text-gray-700">
          {{ $t('step.authentication.error.line_2') }}
        </p>

        <AuthenticationPhoneForm
          @success="nextStep()"
        >
          <template #button>
            <button
              type="submit"
              class="btn btn-warning sm:w-auto w-full"
            >
              {{ $t('continue_my_registration') }}
            </button>
          </template>
        </AuthenticationPhoneForm>
      </div>
    </BaseModal>

    <!-- This modal is shown when a user with same name and birthday is found -->
    <BaseModal :model-value="showWarningSameUserModal">
      <div class="-top-5 absolute flex justify-center w-full mb-3">
        <div class="flex items-center justify-center w-10 h-10 bg-white rounded-full">
          <Icon
            icon="heroicons-solid:exclamation-circle"
            class="w-7 h-7 inline text-center text-yellow-500"
          />
        </div>
      </div>
      <div class="p-8">
        <div class="flex items-start mb-3 space-x-2">
          <h2 class="text-lg font-semibold leading-5 text-yellow-600">
            {{ $t('step.authentication.warning_same_user_modal.title') }}
          </h2>
        </div>

        <p class="mb-5 leading-tight text-gray-700">
          {{ $t('step.authentication.warning_same_user_modal.line_1', {email: warningSameUser?.email ?? ''}) }}
        </p>

        <p
          v-if="sameUserSocialLogin"
          class="mb-5 text-gray-700 font-semibold"
        >
          {{ $t('step.authentication.warning_same_user_modal.line_2', {login: sameUserSocialLogin}) }}
        </p>

        <p class="leading-tight text-gray-700">
          {{ $t('step.authentication.warning_same_user_modal.contact', {phone: '(514) 500-0078'}) }}
        </p>

        <div class="sm:flex sm:gap-3">
          <button
            type="button"
            class="btn btn-lg mt-6 sm:w-auto w-full"
            @click="warningSameUser = null"
          >
            {{ $t('close') }}
          </button>
          <button
            type="button"
            class="btn btn-warning btn-lg mt-6 sm:w-auto w-full"
            @click="$auth.logout()"
          >
            {{ $t('step.authentication.warning_same_user_modal.logout') }}
          </button>
        </div>
      </div>
    </BaseModal>

    <BaseModal :model-value="showSuccessModal">
      <div class="p-8">
        <div class="flex items-center mb-3 space-x-2">
          <Icon
            icon="heroicons-solid:badge-check"
            class="w-4 h-4 text-green-500"
          />
          <span class="text-sm font-semibold tracking-wider text-green-600 uppercase">{{ $t('success') }}</span>
        </div>

        <h2 class="mb-3 text-lg font-semibold leading-5 text-green-600">
          {{ $t('step.authentication.success.title') }}
        </h2>

        <p class="mb-5 leading-tight text-gray-700">
          {{ $t('step.authentication.success.line_1') }}
        </p>

        <button
          type="button"
          class="btn btn-success btn-lg sm:w-auto w-full"
          data-cy="button-success-next"
          @click="nextStep()"
        >
          {{ $t('next') }}
        </button>
      </div>
    </BaseModal>

    <BaseModal v-model="showEidCompareModal">
      <div class="relative">
        <button
          type="button"
          class="-top-8 absolute right-0 text-white"
          @click="showEidCompareModal = false"
        >
          <Icon
            icon="heroicons-solid:x"
            class="w-6 h-6"
          />
        </button>
        <ResponsiveIframe url="https://www.youtube.com/embed/xwR3XIR7qnY" />
      </div>
    </BaseModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import OnboardingText from "../components/OnboardingText.vue";
import AuthenticationPhoneForm from "../components/AuthenticationPhoneForm.vue";
import { AxiosResponse } from "axios";
import { Account } from "@/models/Account";
import { cloneDeep, get } from "lodash-es";
import { EID_API_ERROR, EID_BLACKLIST_ERROR, EID_MAX_TRY_ERROR, EID_NO_MATCH_ERROR, EID_WRONG_BIRTHDAY, EID_SERVER_ERROR, USER_BLACKLIST_ERROR, EID_SAME_NAME_AND_BIRTHDAY } from "@/services/errorCodes";
import { Step } from "../services/steps";

export default defineComponent({
  components: {
    OnboardingTitle,
    StepButtons,
    OnboardingText,
    AuthenticationPhoneForm,
  },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;

    return {
      step,
      nextStep,
      previousStep,
    };
  },
  data () {
    return {
      inlineErrorMessage: "",
      errorModalMessage: "",
      warningModalMessage: "",
      warningSameUser: null as null|Record<string, unknown>,
      showSuccessModal: false,
      showEidCompareModal: false,
      form: {
        first_name: "",
        last_name: "",
        birthday: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        country: "ca",
        postal_code: "",
        accept: false,
      },
    };
  },
  computed: {
    eidSuccess () : boolean {
      return this.$store.state.user?.authentication_flag == "success";
    },
    showErrorModal() : boolean {
      return this.errorModalMessage != "";
    },
    showWarningModal() : boolean {
      return this.warningModalMessage != "";
    },
    showWarningSameUserModal() : boolean {
      return this.warningSameUser != null;
    },
    sameUserSocialLogin(): string {
      if (!this.warningSameUser) {
        return "";
      }
      if (this.warningSameUser.facebook) {
        return "Facebook";
      }
      if (this.warningSameUser.google) {
        return "Google";
      }
      if (this.warningSameUser.apple) {
        return "Apple";
      }
      return "";
    },
    nextDisabled() : boolean {
      return !(
        this.form.accept
        && this.form.first_name
        && this.form.last_name
        && this.form.birthday
        && this.form.address_1
        && this.form.city
        && this.form.state
        && this.form.country
        && this.form.postal_code
      );
    }
  },
  created () {
    this.form.first_name = this.$store.state.user?.first_name ?? "";
    this.form.last_name = this.$store.state.user?.last_name ?? "";
    this.form.birthday = this.$store.state.user?.birthday ?? "";
    this.form.address_1 = this.$store.state.user?.address_1 ?? "";
    this.form.address_2 = this.$store.state.user?.address_2 ?? "";
    this.form.city = this.$store.state.user?.city ?? "";
    this.form.state = this.$store.state.user?.state ?? "";
    this.form.country = this.$store.state.user?.country ?? "ca";
    this.form.postal_code = this.$store.state.user?.postal_code ?? "";

    const eidTries = this.$store.state.user?.eid_tries ?? 0;

    if (eidTries > 0) {
      this.form.accept = true;
    }
  },
  methods: {
    onSuccess (response: AxiosResponse<any>) {
      this.handleResponse(response);
    },
    onError (error: any) {
      if (error && error.response) {
        this.handleResponse(error.response);
        return;
      }

      // If other unknown error
      this.errorModalMessage = "Unknown error while saving validating your profile";
    },
    handleResponse(response: AxiosResponse<any>) {

      if (response.status == 422) {
        return;
      }

      this.saveUserInfo(response.data);

      if (response.data.success) {
        this.showSuccessModal = true;
        return;
      }

      const defaultMessage = "Issue while validating your identity";

      const errorCode = get(response, "data.error.code");

      if ([EID_BLACKLIST_ERROR, USER_BLACKLIST_ERROR].includes(errorCode) || response.data.blocked) {
        this.$router.push("/blocked");
        return;
      }

      if ([EID_API_ERROR, EID_SERVER_ERROR, EID_MAX_TRY_ERROR].includes(errorCode)) {
        this.errorModalMessage = get(response.data, "error.message", defaultMessage);
        return;
      }

      if ([EID_NO_MATCH_ERROR, EID_WRONG_BIRTHDAY].includes(errorCode)) {
        this.warningModalMessage = get(response.data, "error.message", defaultMessage);
        return;
      }

      if ([EID_SAME_NAME_AND_BIRTHDAY].includes(errorCode)) {
        this.warningSameUser = response.data.data.user;
        return;
      }

      // If other unknown error
      this.errorModalMessage = get(response.data, "error.message", defaultMessage);
    },
    saveUserInfo(data: any) {
      const user = cloneDeep(this.$store.state.user) as Account;
      user.first_name = this.form.first_name;
      user.last_name = this.form.last_name;
      user.birthday = this.form.birthday;
      user.address_1 = this.form.address_1;
      user.address_2 = this.form.address_2;
      user.city = this.form.city;
      user.state = this.form.state;
      user.country = this.form.country;
      user.postal_code = this.form.postal_code;

      if (data.tries) {
        user.eid_tries = data.tries;
      }
      if (data.authentication_flag) {
        user.authentication_flag = data.authentication_flag;
      }
      if (data.blocked) {
        user.blocked = data.blocked;
      }

      this.$store.commit("setUser", user);
    },
    openEidCompareVideo() {
      this.showEidCompareModal = true;
    }
  }
});
</script>
