<template>
  <div>
    <div class="relative flex items-start">
      <div class="flex items-center h-5">
        <input
          :id="uuid"
          :aria-describedby="`${uuid}-description`"
          :name="name"
          :value="value"
          :required="required"
          type="radio"
          :checked="isChecked"
          class="focus:ring-primary-500 text-primary-600 bg-white border-gray-400 rounded-full cursor-pointer"
          :class="{
            'h-4 w-4': size == 'md',
            'h-5 w-5': size == 'lg',
          }"
          @change="onChange"
        >
      </div>
      <div class="ml-3">
        <label
          :for="uuid"
          class="cursor-pointer"
        >
          <div
            :id="`${uuid}-description`"
            class="text-gray-600"
          >
            <slot
              v-if="!description"
              name="description"
            />
            <span
              v-else
              :class="{
                'text-sm': size == 'md',
                'text-base': size == 'lg',
              }"
            >
              {{ description }}
            </span>
          </div>
        </label>
      </div>
    </div>

    <p
      v-if="hasError()"
      class="form-input-error mt-2"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { v4 } from "uuid";
import { defineComponent } from "vue";
import InputBase from "./InputBase";

export default defineComponent({
  name: "VInputRadio",
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String
    },
    description: {
      default: "",
      type: String
    },
    size: {
      default: "md",
      type: String,
    }
  },
  setup () {
    const uuid = v4();

    return { uuid };
  },
  computed: {
    isChecked () : boolean {
      return this.modelValue == this.value;
    }
  },
  methods: {
    onChange(e: any) {
      if (e.target?.checked) {
        this.inputListener(e.target?.value ?? null);
      }
    }
  }
});
</script>
