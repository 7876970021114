<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />
    <InputSlider
      :model-value="modelValue"
      :name="name"
      :disabled="disabled"
      :required="required"
      :min="min"
      :max="max"
      :range="range"
      :step="step"
      :format="format"
      :reversed="reversed"
      :class="[{
        'border-red-600': hasError(),
        'border-gray-300': !hasError(),
      }, inputClass]"
      @update:model-value="inputListener"
    />
    <p
      v-if="hasError()"
      class="form-input-error mt-4"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import InputBase from "./InputBase";
import InputSlider from "../components/elements/InputSlider.vue";
import InputLabel from "../components/utils/InputLabel.vue";

export default defineComponent({
  name: "VInputSlider",
  components: { InputLabel, InputSlider },
  extends: InputBase,
  props: {
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: false,
      default: undefined
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      required: true,
      type: Number
    },
    range: {
      default: false,
      type: Boolean
    },
    reversed: {
      default: false,
      type: Boolean
    },
    step: {
      default: 1,
      type: Number
    },
    format: {
      default: (value: string|number) : string => {
        return value + "";
      },
      type: Function
    }
  }
});
</script>
