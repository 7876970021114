import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { cloneDeep, isArray } from "lodash-es";
import { defineComponent, PropType } from "vue";
import { NormalizedOption, NormalizedOptionList, Option, OptionValue } from "../../types/types";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: [Array, null] as PropType<OptionValue[] | null>
    },
    options: {
      required: true,
      type: Array as PropType<Option[]>
    },
    labelKey: {
      required: true,
      type: String
    },
    valueKey: {
      required: true,
      type: String
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    }
  },
  emits: ["update:modelValue"],
  computed: {
    normalizedModelValue(): OptionValue[] {
      if (!isArray(this.modelValue)) {
        return [];
      }

      return this.modelValue;
    },
    normalizedOptions(): NormalizedOption[] {
      return this.options.map(option => {
        return {
          value: option.data[this.valueKey] as OptionValue,
          label: option.data[this.labelKey] as string,
          cancel_others: option.cancel_others ?? false,
          iconify: option.iconify ?? null,
          icon_path: option.icon_path ?? null,
          data: option.data,
        } as NormalizedOption;
      });
    },
    normalizedOptionList(): NormalizedOptionList {

      const list = {} as NormalizedOptionList;

      this.normalizedOptions.forEach(option => {
        list[option.value] = option;
      });

      return list;
    },
    filteredOptions(): NormalizedOption[] {
      return Object.values(this.normalizedOptions)
        .filter(option => {
          return this.selectionList[option.value] == undefined;
        });
    },
    selections(): NormalizedOption[] {
      return this.normalizedModelValue
        .filter((value: OptionValue) => this.normalizedOptionList[value])
        .map((value: OptionValue) => {
          return this.normalizedOptionList[value];
        });
    },
    selectionList(): NormalizedOptionList {
      const list = {} as NormalizedOptionList;

      this.selections
        .forEach((option: NormalizedOption) => {
          list[option.value] = option;
        });

      return list;
    }
  },
  methods: {
    toggleOption(option: NormalizedOption) {
      if (this.normalizedModelValue.includes(option.value)) {
        this.removeOption(option);
      } else {
        this.addOption(option);
      }
    },
    addOption(option: NormalizedOption) {

      if (this.max && this.normalizedModelValue.length >= this.max) {
        this.$notify({
          message: this.$t("you_cannot_select_more_than_x_items", { x: this.max }),
          type: Type.warning
        });
        return;
      }

      if (this.normalizedModelValue.includes(option.value)) {
        return;
      }

      this.beforeAddOption();

      if (option.cancel_others) {
        this.emitUpdate([option.value]);
        return;
      }

      const newModelValue = this.normalizedModelValue.filter(value => {

        const normalizedOption = this.normalizedOptions.find(o => o.value == value);
        if (!normalizedOption) {
          return false;
        }

        if (normalizedOption.cancel_others) {
          return false;
        }

        return true;
      });

      this.emitUpdate([...newModelValue, option.value]);
    },
    removeOption(option: NormalizedOption) {
      let newModelValue = cloneDeep(this.normalizedModelValue);
      newModelValue = newModelValue.filter(v => v != option.value);
      this.emitUpdate(newModelValue);
    },
    emitUpdate(value: OptionValue[]) {
      this.$emit("update:modelValue", value);
      this.afterUpdate();
    },
    beforeAddOption() {
      return;
    },
    afterUpdate() {
      return;
    }
  }
});
