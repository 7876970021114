<template>
  <div class="md:mb-0 md:shrink-0 mb-4">
    <div class="border-b border-gray-200">
      <nav
        class="flex w-full px-3 -mb-px space-x-3 overflow-x-auto"
        aria-label="Tabs"
      >
        <ConversationTabItem
          :model-value="modelValue"
          tab="priority"
          :label="$t('priority')"
          @update:model-value="$emit('update:model-value', $event)"
        />
        <ConversationTabItem
          :model-value="modelValue"
          tab="secondary"
          :label="$t('secondary')"
          @update:model-value="$emit('update:model-value', $event)"
        />
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ConversationTabItem from "./ConversationTabItem.vue";

export default defineComponent({
  components: {
    ConversationTabItem
  },
  props: {
    modelValue: {
      required: true,
      type: String,
    }
  },
  emits: ["update:model-value"],
});
</script>
