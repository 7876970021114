import { Country } from "@/models/Country";
import { api, http } from "@/services/api";
import { getRoute } from "@/services/utils";
import { sortBy } from "lodash-es";

export interface CountriesState {
  list: Country[]
}

export const defaultCountriesState = {
  list: [],
} as CountriesState;

export const countriesModule = {
  namespaced: true,
  state() {
    return defaultCountriesState;
  },
  mutations: {
    set(state: CountriesState, list: Country[]) {
      state.list = list;
    },
  },
  actions: {
    fetch(store: any): Promise<null> {
      return new Promise((resolve, reject) => {
        api.get(getRoute("/api/content/countries"))
          .then((response: any) => {
            store.commit("set", sortBy(response.data.data, "name"));
            resolve(null);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    },
  }
};
