import { Account } from "@/models/Account";
import i18n from "@/plugins/i18n";
import { Status } from "@/services/status/models/Status";
import { StatusType } from "@/services/status/models/StatusType";

export interface StatusState {
  status: Status | null,
}

export const defaultStatusState = {
  status: null
} as StatusState;

export const statusModule = {
  namespaced: true,
  state() {
    return defaultStatusState;
  },
  mutations: {
    set(state: StatusState, user: Account) {
      if (!user.email_verified_at) {

        let url = "/ambassador/settings#email-not-verified";

        if (user.member) {
          url = "/member/settings/email";
        }

        state.status = {
          message: i18n.global.t("status.email_not_verified.title"),
          type: StatusType.warning,
          url: url
        };
      } else if (user.authentication_flag == "warning") {
        state.status = {
          message: i18n.global.t("status.pending_authentication.title"),
          type: StatusType.warning,
        };
      } else if (user.member?.paused) {
        state.status = {
          message: i18n.global.t("status.pause.title"),
          type: StatusType.info,
          url: "/member/settings/pause"
        };
      } else {
        state.status = null;
      }
    },
  },
};
