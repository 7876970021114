<template>
  <div
    class="flex-col overflow-hidden text-left duration-200 border-gray-300 rounded-md shadow"
  >
    <div class="shrink-0 aspect-h-2 aspect-w-3 flex items-center w-full bg-gray-200">
      <iframe
        v-if="article.embed_video_url"
        :src="article.embed_video_url"
        class="absolute inset-0 w-full h-full border-none"
        width="640"
        height="360"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      />
      <img
        v-else-if="article.image_path"
        :src="$assetUrl(article.image_path, {resize: {height: 200, width: 300}})"
        :alt="article.title"
        class="w-full"
      >
    </div>
    <div class="shrink-0 basis-0 p-4">
      <h3 class="line-clamp-1 mb-0 font-medium leading-tight">
        {{ article.title }}
      </h3>
      <p
        v-if="article.owner"
        class="mt-0.5 text-sm text-gray-500 line-clamp-1"
      >
        {{ article.owner.name }}
      </p>
    </div>
    <div class="p-4">
      <button
        type="button"
        class="btn flex items-center justify-center w-full"
        :class="[selectedId != article.id ? 'btn-secondary' : 'btn-primary']"
        @click="selectArticle(article)"
      >
        <span v-if="selectedId != article.id">
          {{ buttonText ? buttonText : $t('select') }}
        </span>
        <template v-else>
          <Icon
            icon="heroicons-solid:check-circle"
            class="mr-2"
          />
          {{ selectedButtonText ? selectedButtonText :$t('selected') }}
        </template>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Article } from "@/models/Article";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    article: {
      required: true,
      type: Object as PropType<Article>
    },
    selectedId: {
      default: null,
      type: String as PropType<string|null>
    },
    buttonText: {
      default: "",
      type: String,
    },
    selectedButtonText: {
      default: "",
      type: String,
    }
  },
  emits: ["select"],
  methods: {
    selectArticle(article: Article) {
      this.$emit("select", article);
    }
  }
});
</script>
