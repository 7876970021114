<template>
  <div
    class="md:bg-gray-50 pb-28 md:pb-32 w-full overflow-x-hidden bg-white"
  >
    <BaseContainer
      class="relative"
      paddingless-mobile
    >
      <div
        v-if="showPrevious"
        class="md:block hidden py-3"
      >
        <a
          class="flex items-center bg-transparent border-none outline-none appearance-none cursor-pointer"
          type="button"
          @click="$router.push('/member/search')"
        >
          <Icon
            icon="heroicons-outline:chevron-left"
            class="w-4 h-4 mr-1.5 text-gray-600"
          />
          <span class="text-gray-700">{{ $t('back') }}</span>
        </a>
      </div>

      <!-- Tree - Desktop -->
      <div
        v-if="!mobile"
        class="translate-x-1/4 absolute top-0 right-0 pt-5 translate-y-0 pointer-events-none"
      >
        <img
          src="@/assets/img/tree/tree-black.svg"
          alt="Tandem Tree"
          class="opacity-[0.03] max-w-full w-auto"
        >
      </div>

      <div class="relative">
        <div class="md:-mx-5 md:flex relative">
          <div class="md:w-1/3 md:px-5 md:shrink-0">
            <div class="md:bg-transparent bg-gray-900">
              <div class="md:max-w-full relative max-w-lg mx-auto">
                <!-- Image Slider -->
                <ProfileSectionSlider
                  :anonymous-mode="anonymousMode"
                  :member="member"
                />

                <router-link
                  v-if="showPrevious"
                  to="/member/search"
                  class="left-5 top-5 md:hidden absolute z-10"
                >
                  <Icon
                    icon="heroicons-solid:arrow-left"
                    class="w-6 h-6 text-white"
                  />
                </router-link>
              </div>
            </div>

            <!-- Tree - Desktop -->
            <div
              v-if="mobile"
              class="absolute right-0 pt-20 translate-x-1/2"
            >
              <img
                src="@/assets/img/tree/tree-black.svg"
                alt="Tandem Tree"
                class="opacity-[0.05] pointer-events-none max-w-none w-[400px]"
              >
            </div>

            <!-- Header (name + age + city) -->
            <div
              v-if="mobile"
              class="relative mt-4"
            >
              <ProfileSectionHeader
                :member="member"
              />
            </div>

            <!-- Voice Button -->
            <div
              v-if="member.audio_introduction_url && !mobile"
              class="md:px-0 px-4 mt-6"
            >
              <AudioPlayer :audio="member.audio_introduction_url">
                <template #default="{toggle, playing}">
                  <BaseButton
                    type="button"
                    class="btn-xl btn-secondary-outline w-full"
                    @click="toggle()"
                  >
                    <template v-if="!playing">
                      <Icon
                        icon="heroicons-outline:volume-up"
                        class="w-6 h-6 mr-2"
                      />
                      <span class="md:hidden">{{ $t('voice') }}</span>
                      <span class="md:inline hidden">{{ $t('listen_to_my_voice') }}</span>
                    </template>
                    <template v-else>
                      <AnimatedWave class="text-primary-400 w-10 h-6" />
                    </template>
                  </BaseButton>
                </template>
              </AudioPlayer>
            </div>

            <ProfileSectionBasicInformation :member="member" />

            <ProfileSectionValues :member="member" />

            <ProfileSectionPersonality :member="member" />

            <ProfileSectionDetails
              v-if="!mobile"
              :member="member"
            />
          </div>

          <div class="md:w-2/3 md:px-5 md:grow md:shrink">
            <!-- Header (name + age + city) -->
            <ProfileSectionHeader
              v-if="!mobile"
              :member="member"
            />

            <ProfileSection>
              <ProfileSectionBox>
                <ProfileSectionBoxRow v-if="member.title || member.about">
                  <ProfileQuestion
                    :max-lines="5"
                  >
                    <template #title>
                      {{ member.title }}
                    </template>
                    <template #default>
                      {{ member.about }}
                    </template>
                  </ProfileQuestion>
                </ProfileSectionBoxRow>
                <ProfileSectionBoxRow v-if="personalDevelopmentImportance || answerByName('personal_development_importance_description')?.value">
                  <ProfileQuestion calligraphy>
                    <template
                      v-if="personalDevelopmentImportance"
                      #title
                    >
                      <span
                        v-html="personalDevelopmentImportance"
                      />
                    </template>
                    <template
                      v-if="answerByName('personal_development_importance_description')?.value"
                      #default
                    >
                      {{ answerByName('personal_development_importance_description')?.value }}
                    </template>
                  </ProfileQuestion>
                </ProfileSectionBoxRow>
                <ProfileSectionBoxRow v-if="member.seeking">
                  <ProfileQuestion>
                    <template #title>
                      {{ $t('what_i_am_looking_for_in_my_partner') }}
                    </template>
                    {{ member.seeking }}
                  </ProfileQuestion>
                </ProfileSectionBoxRow>
              </ProfileSectionBox>
            </ProfileSection>

            <ProfileSectionQuestionsShort
              class="md:mt-6"
              :member="member"
            />

            <ProfileSectionDetails
              v-if="mobile"
              :member="member"
            />

            <ProfileSectionQuestionsLong
              :member="member"
            />

            <ProfileSectionLifestyle
              class="mt-6"
              :member="member"
            />
          </div>
        </div>
      </div>
    </BaseContainer>

    <BaseFixed
      v-if="showInteractions"
      :show-after-scroll="200"
      position="bottom"
    >
      <div class="bg-gradient-to-t from-white absolute inset-0 w-full h-full opacity-100" />
      <div class="sm:p-4 flex items-center justify-center w-full p-2 space-x-2">
        <SendMessage
          :member="member"
          :disabled="disableInteractions"
        />
        <SendFlower
          v-if="member.user.conversation === null"
          :member="member"
          :disabled="disableInteractions"
        />
      </div>
    </BaseFixed>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, Ref } from "vue";
import { cloneDeep, keyBy } from "lodash-es";
import { Member } from "@/models/Member";
import ProfileSection from "./components/sections/ProfileSection.vue";
import ProfileSectionBox from "./components/sections/ProfileSectionBox.vue";
import ProfileSectionBoxRow from "./components/sections/ProfileSectionBoxRow.vue";
import ProfileSectionSlider from "./components/sections/ProfileSectionSlider.vue";
import ProfileSectionLifestyle from "./components/sections/ProfileSectionLifestyle.vue";
import ProfileSectionPersonality from "./components/sections/ProfileSectionPersonality.vue";
import ProfileSectionHeader from "./components/sections/ProfileSectionHeader.vue";
import ProfileSectionBasicInformation from "./components/sections/ProfileSectionBasicInformation.vue";
import ProfileSectionDetails from "./components/sections/ProfileSectionDetails.vue";
import ProfileSectionValues from "./components/sections/ProfileSectionValues.vue";
import ProfileSectionQuestionsShort from "./components/sections/ProfileSectionQuestionsShort.vue";
import ProfileSectionQuestionsLong from "./components/sections/ProfileSectionQuestionsLong.vue";
import ProfileQuestion from "./components/sections/components/ProfileQuestion.vue";
import SendMessage from "./components/SendMessage.vue";
import SendFlower from "./components/SendFlower.vue";
import { Answer } from "@/models/Answer";
import { Question } from "@/models/Question";
import { QuestionOption } from "@/models/QuestionOption";
import { AnswerList, AnswerWithQuestion } from "./types";

export default defineComponent({
  components: {
    ProfileSection,
    ProfileSectionBox,
    ProfileSectionBoxRow,
    ProfileSectionSlider,
    ProfileSectionLifestyle,
    ProfileSectionPersonality,
    ProfileSectionHeader,
    ProfileSectionBasicInformation,
    ProfileSectionDetails,
    ProfileSectionValues,
    ProfileSectionQuestionsShort,
    ProfileSectionQuestionsLong,
    ProfileQuestion,
    SendMessage,
    SendFlower
  },
  provide () {
    return {
      isMe: this.isMe,
      answers: this.answers,
      answerByName: this.answerByName,
      answersByType: this.answersByType,
      answerList: this.answerList,
      answerOptionInCommon: this.answerOptionInCommon,
      setMemberBlockId: this.setMemberBlockId,
      showInteractions: this.showInteractions,
      showFakeInteractions: this.showFakeInteractions,
      disableInteractions: this.disableInteractions,
    };
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>,
    },
    showInteractions: {
      default: true,
      type: Boolean
    },
    showFakeInteractions: {
      default: false,
      type: Boolean
    },
    disableInteractions: {
      default: false,
      type: Boolean
    },
    showPrevious: {
      default: true,
      type: Boolean
    }
  },
  emits: ["update"],
  setup() {
    const mobile = inject("mobile") as Ref<boolean>;
    return {
      mobile
    };
  },
  computed: {
    anonymousMode() : boolean {
      return this.$store.getters.anonymousMode;
    },
    isMe () : boolean {
      return this.member.id == (this.$store.state.user?.member?.id ?? null);
    },
    answers () : AnswerList {
      if (!this.member.answers) {
        return {};
      }

      let items = {} as AnswerList;

      this.member.answers.forEach(answer => {

        // Remove empty answers
        if (answer.value == "" && answer.options.length == 0) {
          return;
        }

        const question = this.$store.getters["question/getById"](answer.question_id) as Question|null;

        if (!question) {
          return;
        }

        const item = cloneDeep(answer) as AnswerWithQuestion;
        item.question = question;
        items[item.question.id] = item;
      });

      return items;
    },
    personalDevelopmentImportance() : string|null {
      let answer = this.answerByName("personal_development_importance")?.options[0] ?? null;

      if (!answer) {
        return null;
      }

      let importanceSentence = answer.properties?.sentence ?? null;

      if (!importanceSentence) {
        return null;
      }

      type importanceSentenceKey = keyof typeof importanceSentence;

      const firsKey = Object.keys(importanceSentence)[0] as importanceSentenceKey;
      const importance = importanceSentence[this.$i18n.locale as importanceSentenceKey] ?? importanceSentence[firsKey] ?? "";

      return this.member.user.first_name + " " + importance.markdownToHtml("text-primary-500 font-semibold underline");
    },
    myAnswersList () : {[question_id: string] : Answer} {
      return keyBy(this.$store.state.user?.member?.answers ?? [], "question_id");
    },
  },
  methods: {
    answerByName(questionName: string) : AnswerWithQuestion|null {
      const question = this.$store.getters["question/getByName"](questionName) as Question|null;

      if (question) {
        const answer = this.answers[question.id];
        if (answer) {
          return answer;
        }
      }
      return null;
    },
    answersByType(questionType: string) : AnswerWithQuestion[] {
      const questions = this.$store.getters["question/getByType"](questionType) as Question[];
      return questions
        .map(question => {
          return (this.answers[question.id] ?? null) as AnswerWithQuestion|null;
        })
        .filter(a => a !== null) as AnswerWithQuestion[];
    },
    answerList(answer: AnswerWithQuestion|null): AnswerWithQuestion[] {

      if (!answer) {
        return [];
      }

      const items = [] as AnswerWithQuestion[];

      items.push(answer);

      if (answer.options.length == 0) {
        return items;
      }

      const optionIds =answer.options.map(o => o.id);

      const dependantQuestions = (this.$store.getters["question/dependantQuestions"](answer.question, optionIds)) as Question[];

      dependantQuestions.forEach(question => {
        if (this.answers[question.id]) {
          items.push(this.answers[question.id]);
        }
      });

      return items;
    },
    answerOptionInCommon(answer: Answer, option: QuestionOption) :boolean {
      if (this.isMe) {
        return false;
      }

      const myAnswer = (this.myAnswersList[answer.question_id] ?? null) as Answer|null;

      return myAnswer !== null && myAnswer.options.map(o => o.id).includes(option.id);
    },
    setMemberBlockId(value: string|null) {
      if (!this.member) {
        return;
      }

      const member = cloneDeep(this.member) as Member;

      member.member_block_id = value;

      this.$emit("update", member);
    }
  }
});
</script>
