<template>
  <BaseContainer
    size="4xl"
    paddingless-mobile
  >
    <BaseAlert
      type="info"
      class="mt-4 border border-blue-400"
    >
      <h2 class="text-lg font-medium text-blue-700">
        {{ $t('pending_claim') }} - <b class="font-semibold">{{ balanceClaim.amount.priceFormat() }}</b>
      </h2>

      <p class="text-sm text-blue-700">
        {{ $t('claim_sent_at') }} {{ $luxon.fromISO(balanceClaim.created_at).setLocale($i18n.locale).toFormat('DDD') }}
      </p>
    </BaseAlert>
  </BaseContainer>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AmbassadorBalanceClaim } from "@/models/AmbassadorBalanceClaim";

export default defineComponent({
  props: {
    balanceClaim: {
      required: true,
      type: Object as PropType<AmbassadorBalanceClaim>
    }
  }
});
</script>
