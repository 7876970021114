<template>
  <div class="sm:rounded-lg py-5 overflow-hidden bg-white border border-gray-200 shadow-sm">
    <div class="sm:px-6 px-4 pb-3">
      <h2 class="font-display text-xl font-semibold leading-tight">
        {{ title }}
      </h2>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String
    }
  }
});
</script>
