<template>
  <div class="mt-2">
    <div
      class="flex flex-wrap"
      :class="wrapperClass"
    >
      <div
        v-for="option in normalizedOptions"
        :key="option.value"
        :class="itemClass"
      >
        <button
          type="button"
          class="btn"
          :class="dynamicOptionClass(option)"
          @click="toggleOption(option)"
        >
          <div
            v-if="option.iconify"
            class="mb-2"
          >
            <div
              class="bg-steel-100 flex items-center justify-center w-8 h-8 mx-auto rounded-full"
              :class="dynamicOptionIconClass(option)"
            >
              <Icon
                class="w-4 h-4"
                :icon="option.iconify"
              />
            </div>
          </div>
          <img
            v-else-if="option.icon_path"
            :src="$assetUrl(option.icon_path ?? '')"
            class="w-9 h-9 mx-auto mb-2"
          >
          <p
            :class="{'mt-3 mb-2 leading-4': option.icon_path}"
          >
            {{ option.label }}
          </p>
        </button>
      </div>
    </div>

    <div
      v-if="min && max && min == max"
      class="flex-grow flex-shrink-0 mt-2 text-base"
      :class="[quantityNoticeClass, {
        'text-red-600': normalizedModelValue.length != min,
        'text-gray-600': normalizedModelValue.length == min
      }]"
    >
      {{ $t('x_selected', {x: `${normalizedModelValue.length}/${min}`}) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { NormalizedOption } from "../../types/types";
import BaseMultiSelect from "./BaseMultiSelect";

export default defineComponent({
  extends: BaseMultiSelect,
  props: {
    wrapperClass: {
      default: "-m-1",
      type: String
    },
    itemClass: {
      default: "p-1",
      type: String
    },
    buttonClass: {
      default: "",
      type: String
    },
    quantityNoticeClass: {
      default: "",
      type: String
    }
  },
  methods: {
    dynamicOptionClass(option: NormalizedOption): string {

      let baseClass = this.buttonClass;

      if (this.normalizedModelValue.find(value =>  value == option.value)) {
        baseClass += " btn-steel-outline";
      } else {
        baseClass += " btn-outline";
      }

      return baseClass;
    },
    dynamicOptionIconClass(option: NormalizedOption): string {

      let baseClass = "";

      if (this.normalizedModelValue.find(value =>  value == option.value)) {
        baseClass += " bg-steel-600 text-white";
      } else {
        baseClass += " bg-steel-100 text-steel-700";
      }

      return baseClass;
    }
  }
});
</script>
