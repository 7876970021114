<template>
  <div class="pb-16">
    <div class="relative">
      <div class="absolute inset-0 w-full h-full">
        <img
          src="/img/articles/hero.jpg"
          alt=""
          class="absolute inset-0 object-cover w-full h-full origin-center"
        >
      </div>
      <div class="opacity-70 absolute inset-0 w-full h-full bg-gray-800" />

      <div class="xs:py-16 py-14 relative">
        <BaseContainer>
          <h1 class="font-display mb-3 text-4xl font-semibold text-white">
            {{ title }}
          </h1>

          <p class="text-white">
            {{ subtitle }}
          </p>
        </BaseContainer>
      </div>
    </div>

    <BaseContainer class="sm:mt-6 mt-4">
      <div class="md:space-x-10 md:flex max-w-7xl container static pb-16">
        <!-- filter block -->
        <transition
          name="custom-classes-transition"
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="-translate-x-3 opacity-0"
          enter-to-class="translate-x-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-x-0 opacity-100"
          leave-to-class="-translate-x-3 opacity-0"
        >
          <div
            v-if="showFilters"
            class="md:z-0 md:w-64 md:shrink-0 md:static md:shadow-none fixed top-0 left-0 z-30 w-full h-full bg-white shadow"
          >
            <form @submit="onSubmit">
              <div class="md:p-0 max-w-lg p-6 mx-auto">
                <div class="md:hidden flex items-center justify-between pb-4 mb-6 border-b border-gray-200">
                  <h2 class="text-lg font-semibold">
                    {{ $t('filters') }}
                  </h2>
                  <button
                    type="button"
                    class="hover:bg-gray-100 flex items-center justify-center w-10 h-10 rounded-full"
                    @click="toggleFilter()"
                  >
                    <Icon
                      icon="heroicons-outline:x"
                      class="iconify w-6 h-6"
                    />
                  </button>
                </div>

                <div class="mb-4">
                  <label
                    for="ownerable_id"
                    class="block mb-1 text-sm text-gray-600"
                  >
                    {{ $t("articles.filters.labels.specialist") }}
                  </label>
                  <select
                    v-model="ownerable_id"
                    name="ownerable_id"
                    class="w-full border border-gray-300 rounded-md"
                    @change="handleFilterChange()"
                  >
                    <option
                      :value="null"
                    >
                      {{ $t("all") }}
                    </option>
                    <option
                      v-for="specialist in specialists"
                      :key="specialist.id"
                      :value="specialist.id"
                    >
                      {{ specialist.name }}
                    </option>
                  </select>
                </div>

                <div class="mb-4">
                  <label
                    for="search"
                    class="block mb-1 text-sm text-gray-600"
                  >
                    {{ $t("articles.filters.labels.search_by_keywords") }}
                  </label>
                  <div class="relative">
                    <div class="absolute top-0 left-0 flex items-center h-full px-3">
                      <div class="absolute top-0 left-0 flex items-center h-full px-3">
                        <Icon
                          icon="heroicons-outline:search"
                          class="iconify w-5 h-5 text-gray-400"
                        />
                      </div>
                    </div>
                    <input
                      v-model="search"
                      name="search"
                      type="text"
                      class="w-full pl-10 border border-gray-300 rounded-md"
                      :placeholder="$t('articles.filters.labels.keywords')"
                    >
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-md btn-primary w-full"
                >
                  {{ $t("articles.filters.labels.apply_filters") }}
                </button>
              </div>
            </form>
          </div>
        </transition>

        <!-- articles section -->
        <div class="grow relative min-h-[600px]">
          <!-- sort section -->
          <div class="lg:mb-10 flex justify-between mb-8">
            <div class="">
              <p class="md:block hidden text-gray-700">
                {{ page }}-{{ lastPage }} {{ $t("articles.sort.of") }} {{ total }} {{ $t("articles.sort.articles") }}
              </p>
              <button
                type="button"
                class="md:hidden btn btn-lg flex items-center h-12 text-gray-900"
                @click="toggleFilter()"
              >
                <Icon
                  icon="heroicons-outline:filter"
                  class="iconify mr-2"
                />
                <span>
                  {{ $t("articles.filters.title") }}
                </span>
              </button>
            </div>
            <div class="flex items-center space-x-3">
              <label
                for="sort"
                class="sm:block hidden text-gray-600"
              >
                {{ $t("articles.sort.by") }}
              </label>
              <select
                v-model="sort"
                name="sort"
                class="h-12 border border-gray-300 rounded-md"
                @change="handleFilterChange(false)"
              >
                <option
                  v-for="s in sorts"
                  :key="s.key"
                  :value="s.value"
                >
                  {{ s.label }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-if="articles.length"
            class="static"
          >
            <!-- articles list section -->
            <div
              class="sm:-m-3 flex flex-wrap -m-3"
            >
              <div
                v-for="article in articles"
                :key="article.id"
                class="xs:w-1/2 sm:w-1/3 sm:p-3 w-full p-3"
              >
                <ClipItem :article="article" />
              </div>
            </div>

            <!-- articles pagination list section -->
            <BasePagination
              :model-value="page"
              :last-page="lastPage"
              class="mt-10"
              @model-value:update="changePage($event)"
            />
          </div>

          <div
            v-else
            class="static py-20 text-center text-gray-500 bg-white"
          >
            {{ $t('no_article') }}
          </div>

          <BaseLoadingCover
            backdrop-class="bg-white bg-opacity-50"
            :model-value="loading"
          />
        </div>
      </div>
    </BaseContainer>

    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ClipItem from "./components/ClipItem.vue";
import { Article, ArticleSpecialist, ArticleSortQuery, ArticleFilterQuery } from "@/models/Article";
import mq from "@/composables/mq";
import { getRoute } from "@/services/utils";

export default defineComponent({
  components: { ClipItem },
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
    filters: {
      default () {
        return {};
      },
      type: Object,
    }
  },
  setup () {
    const { mobile } = mq();
    return {
      mobile
    };
  },
  data() {
    return {
      showFilters:true,
      page: 1,
      lastPage: 0,
      total:0,
      articles: [] as Article[],
      specialists:[] as ArticleSpecialist[],
      ready: false,
      loading: false,
      sort: "-created_at",
      ownerable_id: null,
      search:"",
    };
  },
  computed: {
    sorts () {
      return [
        {
          key:"publishing_date",
          value: "-created_at",
          label: this.$t("articles.sort.sorts_list.publishing_date")
        },
        {
          key:"specialist",
          value: "-ownerable_id",
          label: this.$t("articles.sort.sorts_list.specialist")
        },
      ];
    }
  },
  created() {

    if(this.mobile) {
      this.showFilters = false;
    }

    this.fetch();

    this.fetchSpecialist();
  },
  methods: {
    toggleFilter () {
      this.showFilters = !this.showFilters;
    },
    onSubmit (e: Event) {
      e.preventDefault();

      this.fetch();

      if (this.mobile) {
        this.toggleFilter();
      }
    },
    handleFilterChange (updatePagination = true) {
      this.page = updatePagination ? 1 : this.page;
      this.fetch();
    },
    fetch () {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let params = {
        page: this.page,
        is_internal: false,
        ...this.filterQueryObject(),
        ...this.sortQueryObject()
      };
      this.$api.get(getRoute("api/content/articles"), {
        params
      })
        .then(response => {
          this.articles = response.data.data;
          this.page = response.data.meta.current_page;
          this.lastPage = response.data.meta.last_page;
          this.total = response.data.meta.total;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.ready = true;
          this.loading = false;
        });
    },
    filterQueryObject () {

      let params: ArticleFilterQuery = {};

      if (this.filters.type) {
        params.type = this.filters.type;
      }

      if (this.filters.access_level) {
        params.access_level = this.filters.access_level;
      }

      if (this.search != "") {
        params.search = this.search;
      }

      if (this.ownerable_id) {
        params.ownerable_id = this.ownerable_id;
      }

      return params;
    },
    sortQueryObject () {
      let params:ArticleSortQuery = {};
      params.sort = this.sort;
      return params;
    },
    fetchSpecialist () {
      this.$api.get(getRoute("api/content/specialists"))
        .then(response => {
          this.specialists = response.data.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    changePage(page: number) {
      this.page = page;
      this.fetch();
    }
  }
});
</script>
