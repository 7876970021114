<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />

    <input
      :value="modelValue"
      type="number"
      :required="required"
      :name="name"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
      class="rounded"
      :class="[{
        'bg-gray-100 cursor-not-allowed text-gray-500': disabled,
        'border-red-600': hasError(),
        'border-gray-300': !hasError(),
      }, inputClass]"
      @input="inputListener(transformInputValue($event))"
    >
    <p
      v-if="hasError()"
      class="form-input-error grow sm:order-1 order-2"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { get } from "lodash-es";
import { defineComponent } from "vue";

import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";

export default defineComponent({
  name: "VInputNumber",
  components: { InputLabel },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      validator: () => {
        return true;
      }
    },
    step: {
      default: 1,
      type: Number
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
  },
  methods: {
    transformInputValue(event: Event | null): number | null {
      const value = get(event, "target.value", null);
      return value as number;
    }
  }
});
</script>
