<template>
  <StateUnsubscribed v-if="!forceShow && $store.state.user?.member?.subscription_status == 'inactive'" />
  <StateSuspended v-else-if="!forceShow && ($store.state.user?.member?.suspended || $store.state.user?.authentication_flag == 'fail')" />
  <StateOnPause v-else-if="!forceShow && $store.state.user?.member?.paused" />
  <slot v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StateOnPause from "@/views/states/StateOnPause.vue";
import StateUnsubscribed from "@/views/states/StateUnsubscribed.vue";
import StateSuspended from "@/views/states/StateSuspended.vue";

export default defineComponent({
  components: {
    StateOnPause,
    StateUnsubscribed,
    StateSuspended,
  },
  props: {
    forceShow: {
      default: false,
      type: Boolean,
    }
  }
});
</script>
