<template>
  <div
    data-cy="picture-item"
    class="aspect-1 relative p-3"
    :class="widthClasses"
  >
    <template v-if="showStatus">
      <div
        v-if="picture.status == 'portrait'"
        class="bg-primary-500 max-w-full text-white"
        :class="statusClass"
      >
        <Icon
          icon="heroicons-solid:user"
          class="shrink-0 w-4 h-4 mr-2"
        />
        <span class="truncate">{{ $t('portrait') }}</span>
      </div>
      <div
        v-else-if="picture.status == 'authenticated'"
        class="max-w-full text-white bg-green-500"
        :class="statusClass"
      >
        <Icon
          icon="heroicons-solid:shield-check"
          class="shrink-0 w-4 h-4 mr-2"
        />
        <span class="truncate">{{ $t('authenticated') }}</span>
      </div>
      <div
        v-else-if="picture.status == 'validated'"
        class="max-w-full text-white bg-blue-500"
        :class="statusClass"
      >
        <Icon
          icon="heroicons-solid:check"
          class="shrink-0 w-4 h-4 mr-2"
        />
        <span class="truncate">{{ $t('validated') }}</span>
      </div>
      <div
        v-else-if="picture.status == 'refused'"
        class="max-w-full text-white bg-red-500"
        :class="statusClass"
      >
        <Icon
          icon="heroicons-solid:x"
          class="shrink-0 w-4 h-4 mr-2"
        />
        <span class="truncate">{{ $t('refused') }}</span>
      </div>
      <div
        v-else
        class="max-w-full text-gray-900 bg-gray-200"
        :class="statusClass"
      >
        <Icon
          icon="heroicons-solid:x"
          class="shrink-0 w-4 h-4 mr-2"
        />
        <span class="truncate">{{ $t('pending_validation') }}</span>
      </div>
    </template>
    <template v-else>
      <div
        v-if="index == 0"
        class="bg-primary-500 max-w-full text-white"
        :class="statusClass"
      >
        <Icon
          icon="heroicons-solid:user"
          class="shrink-0 w-4 h-4 mr-2"
        />
        <span class="truncate">{{ $t('main_picture') }}</span>
      </div>
    </template>

    <div
      class="bottom-0.5 left-0.5 w-7 h-7 absolute text-sm font-semibold flex items-center justify-center shadow rounded-full"
      :class="{
        'bg-primary-500 text-white': index == 0,
        'bg-white text-text-gray-600': index != 0
      }"
    >
      {{ index + 1 }}
    </div>

    <button
      type="button"
      class="right-5 -bottom-1 absolute flex items-center justify-center w-10 h-10 bg-transparent border-none outline-none"
      @click="confirmDeleteImage(picture)"
    >
      <div class="hover:bg-gray-100 flex items-center justify-center w-8 h-8 font-medium text-gray-500 transition-colors duration-200 bg-white rounded-full shadow-md">
        <Icon
          icon="heroicons-solid:trash"
          class="w-4 h-4"
        />
      </div>
    </button>

    <div
      class="handle w-full h-full overflow-hidden bg-gray-500 rounded-md shadow-lg cursor-move select-none"
    >
      <img
        :src="$assetUrl(picture.base_path, {resize: {height: 300, width: 300}})"
        :alt="picture.id"
        class="object-cover object-center w-full h-full pointer-events-none select-none"
      >
    </div>

    <BaseLoadingCover
      v-model="deleting"
      backdrop-class="bg-gray-50 opacity-90"
      icon-class="w-4 h-4"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, Ref} from "vue";
import { Dialog } from "@capacitor/dialog";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { MemberGalleryItem } from "@/models/Member";

export default defineComponent({
  props: {
    index: {
      required: true,
      type: Number,
    },
    picture: {
      required: true,
      type: Object as PropType<MemberGalleryItem>,
    },
    widthClasses: {
      required: true,
      type: String
    },
    showStatus: {
      default: false,
      type: Boolean
    },
    allowEmpty: {
      default: false,
      type: Boolean
    }
  },
  emits: ["fetch"],
  setup() {
    const pictures = inject("pictures") as Ref<MemberGalleryItem[]>;

    return {
      pictures
    };
  },
  data() {
    return {
      deleting: false,
      statusClass: "top-0.5 left-0.5 px-3 h-7 absolute text-sm font-semibold flex items-center justify-center shadow rounded-full"
    };
  },
  methods: {
    async confirmDeleteImage(media: MemberGalleryItem) {

      if (this.pictures.length <= 1 && !this.allowEmpty) {
        this.$notify({
          message: this.$t("page.settings.profile.pictures.warning_min_1"),
          type: Type.danger
        });
        return;
      }

      const completeBefore = this.isGalleryComplete(this.pictures);
      const completeAfter = this.isGalleryComplete(this.pictures.filter(p => p.id != media.id));

      let title = this.$t("page.settings.profile.pictures.delete_image.title");
      let message = this.$t("page.settings.profile.pictures.delete_image.message");

      if (completeBefore && !completeAfter) {
        title = this.$t("page.settings.profile.pictures.delete_image_danger.title");
        message = this.$t("page.settings.profile.pictures.delete_image_danger.message");
      }

      const { value } = await Dialog.confirm({
        title: title,
        message: message,
      });

      if (value) {
        this.deleteImage(media);
      }
    },
    isGalleryComplete(pictures: MemberGalleryItem[]) : boolean {
      const numberOfPortrait = pictures.filter(picture => {
        if (["portrait"].includes(picture.status as string)) {
          return true;
        }
        return false;
      }).length;

      const numberOfValidated = pictures.filter(picture => {
        if (["authenticated", "validated"].includes(picture.status as string)) {
          return true;
        }
        return false;
      }).length;

      return numberOfPortrait > 0 && numberOfValidated > 0;
    },
    deleteImage(media: any) {
      if (this.deleting) {
        return;
      }
      this.deleting = true;
      this.$api.delete("/account/member/pictures/" + media.id)
        .then(() => {
          this.fetch();
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    fetch() {
      this.$emit("fetch");
    }
  }
});
</script>
