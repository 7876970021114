<template>
  <div class="pt-safe">
    <div class="sm:block sm:w-full sm:h-full fixed inset-0 flex-1 hidden w-0">
      <InspirationCarousel />
    </div>
    <div class="sm:py-10 relative w-full overflow-y-auto">
      <div class="md:px-10 sm:px-8 px-6 py-10 bg-white rounded-lg max-w-[500px] mx-auto">
        <div class="mb-6">
          <div
            v-if="native"
            class="flex items-center"
          >
            <router-link
              v-if="backUrl"
              :to="backUrl"
              class="shrink-0 mr-3 -ml-2"
            >
              <Icon
                class="w-8 h-8 text-gray-700"
                icon="heroicons-outline:chevron-left"
              />
            </router-link>
            <img
              src="@/assets/img/logo/logo-name-gray.svg"
              alt="Effet Tandem"
              class="w-52"
            >
          </div>
          <div
            v-else
            class="flex items-center"
          >
            <component
              :is="backUrl ? 'router-link': 'a'"
              :to="backUrl ?? undefined"
              :href="backUrl ? undefined : $websiteUrl('/' + $i18n.locale)"
              class="shrink-0 mr-3 -ml-2"
            >
              <Icon
                class="w-8 h-8 text-gray-700"
                icon="heroicons-outline:chevron-left"
              />
            </component>
            <a :href="$websiteUrl('/' + $i18n.locale)">
              <img
                src="@/assets/img/logo/logo-name-gray.svg"
                alt="Effet Tandem"
                class="w-52"
              >
            </a>
          </div>

          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InspirationCarousel from "@/layouts/InspirationCarousel.vue";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  components: { InspirationCarousel },
  props: {
    backUrl: {
      default: undefined,
      type: String,
    }
  },
  data () {
    return {
      native: Capacitor.isNativePlatform(),
    };
  }
});
</script>
