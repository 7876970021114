<template>
  <BaseCard class="relative overflow-hidden">
    <BaseLoadingCover
      v-model="loading"
      icon-class="text-primary-500 w-6 h-6"
    />
    <BaseCardRow>
      <div class="flex space-x-3">
        <div class="shrink-0">
          <Icon
            icon="heroicons-outline:information-circle"
            class="w-6 h-6 text-gray-400"
          />
        </div>
        <div>
          <p class="text-gray-700">
            {{ $t('do_you_want_to_create_a_profile_on_the_dating_website') }}
            <a
              href="#"
              type="button"
              class="font-semibold underline bg-transparent border-none appearance-none"
              @click="createMemberAccount()"
            >{{ $t('click_here') }}</a>
          </p>
        </div>
      </div>
    </BaseCardRow>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async createMemberAccount() {
      this.loading = true;

      if (!this.$store.state.user?.member) {
        try {
          await this.$api.post("/onboarding/member");
        } catch(e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      }

      this.$store.dispatch("fetchUser");

      this.$router.push("/onboarding");
    },
  }
});
</script>
