enum Type {
  info = "info",
  success = "success",
  warning = "warning",
  danger = "danger",
}

interface NotificationConfigInterface {
  message: string,
  type?: Type,
  duration?: number,
  icon?: boolean
}

export type { NotificationConfigInterface };
export { Type };
