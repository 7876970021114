<template>
  <MemberAvatarWrapper
    :member="member"
    :size="size"
  >
    <img :src="$assetUrl(avatarPath, {resize: {height: size, width: size}})">
  </MemberAvatarWrapper>
</template>

<script lang="ts">

import { Member } from "@/models/Member";
import { defineComponent, PropType } from "vue";
import { ConversationMember } from "@/models/Conversation";
import { DailyQuoteMember } from "@/models/DailyQuote";
import MemberAvatarWrapper from "./MemberAvatarWrapper.vue";
import { MemberBlock } from "@/models/MemberBlock";

export default defineComponent({
  components: {
    MemberAvatarWrapper
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member|ConversationMember|DailyQuoteMember|MemberBlock|undefined>
    },
    size: {
      default: 400,
      type: Number
    }
  },
  computed: {
    isMe () : boolean {
      if (!this.member) {
        return false;
      }
      return this.$store.state.user?.member?.id == this.member?.id;
    },
    avatarPath () : string {
      const placeholder = "placeholders/avatar.png";

      if (!this.member) {
        return placeholder;
      }

      if (this.member.deleted || this.member.unavailable) {
        return placeholder;
      }

      if (!this.member.avatar_path && this.isMe) {
        return this.$store.state.user?.member?.avatar_path ?? placeholder;
      }

      return this.member.avatar_path ?? placeholder;
    },
  },
});
</script>

<style lang="postcss" scoped>

.backdrop {
  @apply bg-gradient-to-t from-steel-800 opacity-70 absolute inset-0 w-full h-full;
}
</style>
