<template>
  <BaseCard>
    <BaseCardRow>
      <h2 class="mb-3 text-lg font-semibold leading-tight">
        {{ $t('picture_validation_notice.title') }}
      </h2>

      <p class="mb-9 leading-snug text-gray-600">
        {{ $t('picture_validation_notice.message') }}
      </p>

      <div class="flex flex-wrap -m-1">
        <div class="sm:w-1/3 grow-1 w-full p-1">
          <h3 class="mb-3 font-semibold leading-tight">
            {{ $t('picture_validation_notice.status.validated') }}
          </h3>
          <ul>
            <li class="flex mb-2 text-sm leading-tight text-gray-600">
              <Icon
                icon="heroicons-outline:check"
                class="shrink-0 top-0.5 relative w-4 h-4 mr-1.5"
              />
              {{ $t('picture_validation_notice.attributes.terms') }}
            </li>
          </ul>
        </div>
        <div class="sm:w-1/3 grow-1 w-full p-1">
          <h3 class="mb-3 font-semibold leading-tight">
            {{ $t('picture_validation_notice.status.authenticated') }}
          </h3>
          <ul>
            <li class="flex mb-2 text-sm leading-tight text-gray-600">
              <Icon
                icon="heroicons-outline:check"
                class="shrink-0 top-0.5 relative w-4 h-4 mr-1.5"
              />
              {{ $t('picture_validation_notice.attributes.terms') }}
            </li>
            <li class="flex mb-2 text-sm leading-tight text-gray-600">
              <Icon
                icon="heroicons-outline:check"
                class="shrink-0 top-0.5 relative w-4 h-4 mr-1.5"
              />
              {{ $t('picture_validation_notice.attributes.match') }}
            </li>
          </ul>
        </div>
        <div class="sm:w-1/3 grow-1 w-full p-1">
          <h3 class="mb-3 font-semibold leading-tight">
            {{ $t('picture_validation_notice.status.portrait') }}
          </h3>
          <ul>
            <li class="flex mb-2 text-sm leading-tight text-gray-600">
              <Icon
                icon="heroicons-outline:check"
                class="shrink-0 top-0.5 relative w-4 h-4 mr-1.5"
              />
              {{ $t('picture_validation_notice.attributes.terms') }}
            </li>
            <li class="flex mb-2 text-sm leading-tight text-gray-600">
              <Icon
                icon="heroicons-outline:check"
                class="shrink-0 top-0.5 relative w-4 h-4 mr-1.5"
              />
              {{ $t('picture_validation_notice.attributes.match') }}
            </li>
            <li class="flex mb-2 text-sm leading-tight text-gray-600">
              <Icon
                icon="heroicons-outline:check"
                class="shrink-0 top-0.5 relative w-4 h-4 mr-1.5"
              />
              {{ $t('picture_validation_notice.attributes.portrait') }}
            </li>
          </ul>
        </div>
      </div>
    </BaseCardRow>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>
