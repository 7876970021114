import { App } from "vue";

import Swiper, { Autoplay, Navigation, Pagination, SwiperOptions } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  install: (app: App): void => {

    Swiper.use([Navigation, Pagination, Autoplay]);

    app.config.globalProperties.$swiper = (container: HTMLElement, options: SwiperOptions | undefined): Swiper => {
      return new Swiper(container, options);
    };
  }
};
