<template>
  <OnboardingTitle>
    {{ $t('step.payment.title') }}
  </OnboardingTitle>

  <ProductList
    :show-referral-form="false"
    @loaded="onProductsLoaded"
    @success="onPaymentSuccess"
  />

  <div class="mt-4">
    <p class="text-sm leading-snug text-gray-600">
      * {{ $t('if_you_have_a_promotional_code_select_a_package_and_you_can_enter_it_later') }}
    </p>
  </div>

  <div
    v-if="showTrial && false"
    class="mt-6"
  >
    <BaseCard>
      <BaseCardRow>
        <h3 class="mb-4 font-semibold leading-tight">
          {{ $t('step.payment.trial.title') }}
        </h3>

        <BaseButton
          id="onboarding-button-activate-trial"
          :loading="loading"
          class="btn-primary btn-lg"
          @click="freeTrial()"
        >
          {{ $t('step.payment.trial.cta') }}
        </BaseButton>
      </BaseCardRow>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import { Step } from "../services/steps";
import ProductList from "@/views/member/subscription/components/ProductList.vue";
import { Product } from "@/models/Product";

export default defineComponent({
  components: { OnboardingTitle, ProductList },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  },
  data () {
    return {
      showTrial: false,
      loading: false,
    };
  },
  methods: {
    onProductsLoaded (products: Product[]) {
      this.showTrial = products.filter(p => p.price == 0).length == 0;
    },
    async freeTrial() {
      this.loading = true;

      try {
        await this.$api.post("onboarding/trial");
        await this.$store.dispatch("fetchUser");
        this.nextStep();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    onPaymentSuccess() {
      this.nextStep();
    }
  }
});
</script>
