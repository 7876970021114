<template>
  <div class="mt-14" />

  <ShareStep
    :number="1"
    :title="$t('pages.ambassador.sharing_tools.steps.content.title')"
    :subtitle="$t('pages.ambassador.sharing_tools.steps.content.subtitle')"
  >
    <fieldset>
      <legend class="sr-only">
        Content
      </legend>
      <div class="-space-y-px bg-white rounded-md">
        <label
          v-for="(item, i) in contentTypes"
          :key="item.value"
          class="focus:outline-none relative flex p-4 border cursor-pointer"
          :class="[
            item.value == config.contentType ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
            i == 0 ? 'rounded-tl-md rounded-tr-md' : '',
            i == contentTypes.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
          ]"
        >
          <input
            v-model="config.contentType"
            type="radio"
            name="content_type"
            :value="item.value"
            class="h-4 w-4 mt-0.5 cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
            @change="onContentTypeChange"
          >
          <div class="flex flex-col ml-3">
            <span
              class="block font-medium"
              :class="item.value == config.contentType ? 'text-blue-900' : 'text-gray-900'"
            >
              {{ item.label }}
            </span>
            <span
              class="block text-sm"
              :class="item.value == config.contentType ? 'text-blue-700' : 'text-gray-600'"
            >
              {{ item.description }}
            </span>
          </div>
        </label>
      </div>
    </fieldset>
  </ShareStep>

  <ShareStep
    v-if="config.contentType == 'video'"
    :number="2"
    :title="$t('pages.ambassador.sharing_tools.steps.video.title')"
    :subtitle="$t('pages.ambassador.sharing_tools.steps.video.subtitle')"
  >
    <ArticleSelector
      :internal="true"
      type="video"
      @select="onArticleChange($event)"
    />
  </ShareStep>

  <ShareStep
    v-if="config.contentType == 'article'"
    :number="2"
    :title="$t('pages.ambassador.sharing_tools.steps.article.title')"
    :subtitle="$t('pages.ambassador.sharing_tools.steps.article.subtitle')"
  >
    <ArticleSelector
      :internal="false"
      @select="onArticleChange($event)"
    />
  </ShareStep>

  <ShareStep
    :disabled="!showPlatformStep"
    :number="config.contentType == 'website' ? 2 : 3"
    :title="$t('pages.ambassador.sharing_tools.steps.media.title')"
    :subtitle="$t('pages.ambassador.sharing_tools.steps.media.subtitle')"
  >
    <fieldset>
      <legend class="sr-only">
        Social Media
      </legend>
      <div class="-space-y-px bg-white rounded-md">
        <label
          v-for="(platform, i) in platforms"
          :key="platform.value"
          class="focus:outline-none relative flex p-4 border cursor-pointer"
          :class="[
            platform.value == config.platform ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
            i == 0 ? 'rounded-tl-md rounded-tr-md' : '',
            i == platforms.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
          ]"
        >
          <input
            v-model="config.platform"
            type="radio"
            name="platform"
            :disabled="!showPlatformStep"
            :value="platform.value"
            class="h-4 w-4 mt-0.5 disabled:cursor-not-allowed cursor-pointer text-blue-600 border-gray-300 focus:ring-blue-500"
          >
          <div class="flex items-center ml-3">
            <Icon
              :icon="platform.icon"
              class="w-5 h-5 mr-2"
              :style="{color: platform.iconColor}"
            />
            <span
              class="block font-medium"
              :class="platform.value == config.platform ? 'text-blue-900' : 'text-gray-900'"
            >
              {{ platform.label }}
            </span>
          </div>
        </label>
      </div>
    </fieldset>
  </ShareStep>


  <ShareStep
    :disabled="!showShareStep"
    :number="config.contentType == 'website' ? 3 : 4"
    :title="$t('pages.ambassador.sharing_tools.steps.share.title')"
    :subtitle="$t('pages.ambassador.sharing_tools.steps.share.subtitle')"
  >
    <div v-if="config.platform == 'facebook'">
      <ShareFacebook
        :link="shareableLink ?? ''"
        class="btn btn-lg btn-primary"
      >
        {{ $t('share_on_facebook') }}
      </ShareFacebook>
    </div>

    <div v-if="config.platform == 'messenger'">
      <ShareMessenger
        :link="shareableLink ?? ''"
        class="btn btn-lg btn-primary"
      >
        {{ $t('share_on_messenger') }}
      </ShareMessenger>
    </div>

    <div v-if="config.platform == 'email'">
      <ShareMail
        :subject="mailSubject"
        :body="mailBody"
      />
    </div>

    <div v-if="config.platform == 'link'">
      <BaseClipboard
        :value="shareableLink ?? ''"
      />
    </div>
  </ShareStep>

  <BaseFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShareStep from "./components/ShareStep.vue";
import ArticleSelector from "./components/ArticleSelector.vue";
import { Article } from "@/models/Article";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  components: {
    ShareStep,
    ArticleSelector
  },
  data () {
    return {
      isNativePlatform: Capacitor.isNativePlatform(),
      contentTypes: [
        {
          label: this.$t("page.ambassador.share.content_types.website.title"),
          value: "website",
          description: this.$t("page.ambassador.share.content_types.website.description")
        },
        {
          label: this.$t("page.ambassador.share.content_types.promo.title"),
          value: "video",
          description: this.$t("page.ambassador.share.content_types.promo.description")
        },
        {
          label: this.$t("page.ambassador.share.content_types.article.title"),
          value: "article",
          description: this.$t("page.ambassador.share.content_types.article.description")
        },
      ],
      platforms: [
        {
          label: "Facebook",
          value: "facebook",
          icon: "mdi:facebook",
          iconColor: "#4064ac"
        },
        {
          label: "Messenger",
          value: "messenger",
          icon: "mdi:facebook-messenger",
          iconColor: "#4064ac"
        },
        {
          label: this.$t("email"),
          value: "email",
          icon: "mdi:email",
          iconColor: "#9ca3af"
        },
        {
          label: this.$t("copy_link"),
          value: "link",
          icon: "mdi:link",
          iconColor: "#9ca3af"
        },
      ],
      config: {
        contentType: "website",
        article: null as Article|null,
        platform: null as string|null,
      }
    };
  },
  computed: {
    showPlatformStep() : boolean {
      if (this.config.contentType == "website") {
        return true;
      }

      if (!this.config.article) {
        return false;
      }

      return true;
    },
    showShareStep() : boolean {

      if (!this.shareableLink) {
        return false;
      }

      if (this.config.contentType == "website" && this.config.platform) {
        return true;
      }

      if (this.config.article && this.config.platform) {
        return true;
      }

      return false;
    },
    shareableLink() : string|null {
      if (this.config.contentType == "website") {
        return this.$store.state.user?.ambassador?.referral_link ?? null;
      }

      if (!this.config.article) {
        return null;
      }

      if (!this.$store.state.user?.ambassador?.code) {
        return null;
      }

      return `${this.config.article.website_url}?reference=${this.$store.state.user.ambassador.code}`;
    },
    mailSubject () : string {
      return this.$t("_mail_body.referral_link.subject");
    },
    mailBody () : string {
      return this.$t("_mail_body.referral_link.body", {
        url: this.shareableLink,
        from: this.$store.state.user?.full_name,
      });
    }
  },
  methods: {
    onContentTypeChange() {
      this.config.platform = null;
      this.config.article = null;
    },
    onArticleChange(article: Article) {
      this.config.article = article;
      this.config.platform = null;
    },
    mutateUrlOnLocal(url: string) : string {
      if (url.startsWith("http://localhost:8101")) {
        return url.replace("http://localhost:8101", "https://effettandem.com");
      }
      if (url.startsWith("http://tandem.test")) {
        return url.replace("http://tandem.test", "https://effettandem.com");
      }
      return url;
    },
  }
});
</script>
