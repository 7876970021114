<template>
  <div>
    <div
      class="sm:space-y-0 sm:gap-x-3 sm:grid sm:grid-cols-3 space-y-6"
    >
      <template v-if="loading">
        <BaseSkeleton
          v-for="i in 3"
          :key="i"
          class="h-52"
        />
      </template>
      <template v-else>
        <ProductItem
          v-for="product in products"
          :key="product.id"
          :product="product"
          @select="onSelect"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Product } from "@/models/Product";
import { defineComponent, PropType } from "vue";
import ProductItem from "./ProductItem.vue";

export default defineComponent({
  components: {
    ProductItem
  },
  props: {
    products: {
      required: true,
      type: Array as PropType<Product[]>
    },
    loading: {
      default: false,
      type: Boolean,
    }
  },
  emits: ["select"],
  methods: {
    onSelect(product: Product) {
      this.$emit("select", product);
    }
  },
});
</script>
