<template>
  <button
    type="button"
    :class="buttonClass"
    :disabled="disabled"
    @drop.prevent="dragging = false; handleDrop($event)"
    @dragleave.prevent="dragging = false"
    @dragover.prevent="dragging = true"
    @dragenter.prevent="dragging = true"
    @click="pickImage"
  >
    <slot
      :selecting="selecting"
      :dragging="dragging"
    />
  </button>
  <input
    ref="input"
    type="file"
    accept="image/*"
    hidden="true"
    @change="onInputChange"
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Camera, GalleryPhoto } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { blobToBase64 } from "@/services/utils";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { ImagePickerResult } from "./type";

export default defineComponent({
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    buttonClass: {
      default: "",
      type: String
    },
    minWidth: {
      default: 0,
      type: Number,
    },
    minHeight: {
      default: 0,
      type: Number,
    }
  },
  emits: ["upload"],
  data () {
    return {
      selecting: false,
      dragging: false,
    };
  },
  computed: {
    inputElement () : HTMLInputElement | undefined {
      return this.$refs.input as HTMLInputElement | undefined;
    }
  },
  methods: {
    async pickImage() {

      if (this.disabled){
        return;
      }

      if (Capacitor.isNativePlatform()) {
        await this.pickImageNative();
      } else {
        this.pickImageWeb();
      }
    },
    pickImageWeb() {
      (this.$refs.input as HTMLElement | undefined)?.click();
    },
    async pickImageNative() {

      this.selecting = true;

      try {

        await Camera.requestPermissions();

        const result = await Camera.pickImages({
          limit: 1
        });

        if (!result.photos.length) {
          return;
        }

        await this.uploadNative(result.photos[0]);

      } finally {
        this.selecting = false;
      }
    },
    onInputChange() {
      const files = (this.inputElement?.files ?? []) as File[];
      this.uploadWeb(files);
    },
    handleDrop(e: any) {

      if (this.disabled) {
        return;
      }

      let files = e?.dataTransfer?.files ?? [];

      this.uploadWeb(files);
    },
    async uploadWeb(files: File[]) {

      if (this.disabled){
        return;
      }

      if (!files || files.length == 0 || !(files[0] instanceof File)) {
        return;
      }

      this.selecting = true;

      try {
        const file = files[0];

        const base64 = await blobToBase64(file);

        if (! await this.validateImage(base64)) {
          return;
        }

        this.$emit("upload", {
          mime: file.type,
          data: base64,
          file: file,
        } as ImagePickerResult);
      } finally {
        if (this.inputElement) {
          this.inputElement.value = "";
        }
        this.selecting = false;
      }
    },
    async uploadNative (image: GalleryPhoto) {

      const contents = await Filesystem.readFile({
        path: image.path as string,
      });

      if (! await this.validateImage(image.webPath)) {
        return;
      }

      this.$emit("upload", {
        mime: "image/" + image.format,
        data: contents.data,
      } as ImagePickerResult);
    },
    async validateImage(src: string) : Promise<boolean> {
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = src;
        image.onload = () => {

          if (image.width < this.minWidth) {
            this.$notify({
              type: Type.danger,
              message: this.$t("the_image_must_be_at_least_x_wide", {x: this.minWidth + "px"})
            });
            resolve(false);
            return;
          }

          if (image.height < this.minHeight) {
            this.$notify({
              type: Type.danger,
              message: this.$t("the_image_must_be_at_least_x_high", {x: this.minHeight + "px"})
            });
            resolve(false);
            return;
          }

          resolve(true);
        };

        image.onerror = (e) => {
          reject(e);
        };
      });
    }
  },
});
</script>
