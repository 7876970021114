<template>
  <div class="bg-gray-50 pt-safe relative min-h-screen pb-20">
    <BaseContainer size="3xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.profile.pictures.title')"
        padding-bottom="pb-5"
      />
      <div class="mb-10">
        <PicturesForm
          force-order
          show-review-status
          show-picture-status
          show-sample-link
        />
      </div>

      <PictureValidationNotice />
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent} from "vue";
export default defineComponent({
});
</script>
