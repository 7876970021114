<template>
  <div class="absolute inset-0 z-10 flex items-center justify-center w-full h-full bg-black bg-opacity-25">
    <div class="text-center text-white">
      <p class="mb-1 text-2xl font-semibold leading-tight">
        {{ user.first_name }}
      </p>
      <p class="text-sm leading-tight">
        {{ $t('call') }}...
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { ConversationUser } from "@/models/Conversation";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    user: {
      required: true,
      type: Object as PropType<ConversationUser>,
    }
  }
});
</script>
