<template>
  <div>
    <AudioRecorder
      :max="max"
      @recorded="onRecorded"
      @success="onSuccess"
    >
      <template #default="{recording, initializing, loading, milliseconds, toggleRecording}">
        <button
          class="text-primary-500 hover:bg-gray-100 hover:text-primary-600 will-change-transform relative flex items-center justify-center w-10 h-10 overflow-hidden transition-colors duration-150 border-0 border-none rounded-full outline-none appearance-none"
          :class="{
            'bg-gray-100': recording
          }"
          @click="toggleRecording"
        >
          <div
            v-show="recording"
            class="absolute"
          >
            <ProcessRing
              class="text-gray-300"
              :radius="40"
              :stroke="11.5"
              :progress="milliseconds/(max*1000)"
            />
          </div>
          <div class="relative">
            <LoadingLine
              v-if="initializing || loading || uploading"
              class="animate-spin w-5 h-5"
            />
            <div
              v-else-if="recording"
              class="animate-pulse w-5 h-5 bg-red-600 rounded"
            />
            <Icon
              v-else
              icon="heroicons-solid:microphone"
              class="w-6 h-6"
            />
          </div>
        </button>
      </template>
    </AudioRecorder>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Conversation } from "@/models/Conversation";
import LoadingLine from "@/plugins/icons/LoadingLine.vue";
import { uuid } from "@/services/uuid";

export default defineComponent({
  components: {
    LoadingLine,
  },
  setup () {
    const conversation = inject("conversation") as Conversation;
    const softFetch = inject("softFetch") as () => void;
    const scrollBottom = inject("scrollBottom") as () => void;
    return {
      conversation,
      softFetch,
      scrollBottom,
    };
  },
  data () {
    return {
      max: 30,
      uploading: false
    };
  },
  methods: {
    async onSuccess(key: string) {
      this.uploading = true;

      try {

        await this.$api.post(`conversations/${this.conversation.id}/messages`, {
          type: "audio",
          key: key
        });

        this.softFetch();

      } finally {
        this.uploading = false;
      }
    },
    onRecorded() {
      this.conversation.messages?.push({
        id: uuid(),
        content: "",
        content_text: "[" + this.$t("audio_message") +"]",
        is_me: true,
        flower: false,
        flower_match: false,
        audio: "",
        created_at: this.$luxon.now().toISO(),
      });
      this.scrollBottom();
    },
  },
});
</script>
