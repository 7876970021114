import { Capacitor } from "@capacitor/core";
import { SuppressLongpressGesture } from "capacitor-suppress-longpress-gesture";

/**
 * Suppress long press on iOS (for drag and drop on ios web)
 */
export default async (): Promise<void> => {

  if (Capacitor.getPlatform() == "ios") {
    SuppressLongpressGesture.activateService();
  }
};
