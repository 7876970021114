<template>
  <div class="relative">
    <OnboardingTitle>
      {{ $t('whats_your_sexual_orientation') }}
      <span class="text-red-500">*</span>
    </OnboardingTitle>

    <p
      class="flex items-center text-gray-500"
    >
      <Icon
        icon="heroicons-outline:information-circle"
        class="inline w-4 h-4 mr-1"
      />
      {{ $t('you_can_select_more_than_one') }}
    </p>

    <VForm
      method="patch"
      url="/onboarding/user/sexual-orientation"
      :data="form"
      loading-mask-class="bg-white"
      :success-handler="() => {}"
      @success="onSuccess"
    >
      <VInputMultiSelect
        v-model="form.sexual_orientations"
        name="sexual_orientations"
        input-class="w-full"
        :required="true"
        :options="sexualOrientations"
        label-key="label"
        value-key="value"
        class="mt-5"
        wrapper-class="-m-1.5"
        item-class="w-full p-1.5"
        button-class="btn-xl w-full shadow"
        :min="1"
      />

      <div class="mt-8">
        <StepButtons
          :next-disabled="form.sexual_orientations.length == 0"
          :previous-button="true"
          @previous="previousStep"
        />
      </div>
    </VForm>
  </div>
</template>

<script lang="ts">
import { Account } from "@/models/Account";
import { SexualOrientation } from "@/models/User";
import { Option } from "@/plugins/form/types/types";
import i18n from "@/plugins/i18n";
import { cloneDeep, isString } from "lodash";
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import { Step } from "../services/steps";
import Cookies from "js-cookie";

export default defineComponent({
  components: { OnboardingTitle, StepButtons },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    const sexualOrientations = [
      {data: {value: "man", label: i18n.global.t("men")}},
      {data: {value: "woman", label: i18n.global.t("women")}},
      {data: {value: "non_binary", label: i18n.global.t("non_binaries")}},
    ] as Option[];

    return {
      step,
      nextStep,
      previousStep,
      sexualOrientations,
    };
  },
  data () {
    return {
      form: {
        sexual_orientations: [] as SexualOrientation[]
      },
    };
  },
  created () {

    const sexualOrientations = Cookies.get("onboarding.sexual_orientations");

    if (isString(sexualOrientations)) {

      const orientations = (sexualOrientations).split(",").filter(o => {
        return Object.values(SexualOrientation).includes(o as SexualOrientation);
      }) as SexualOrientation[];

      this.form.sexual_orientations = orientations;
    }

    if (this.$store.state.user?.sexual_orientations) {
      this.form.sexual_orientations = this.$store.state.user.sexual_orientations ?? [];
    }
  },
  methods: {
    onSuccess () {

      const user = cloneDeep(this.$store.state.user) as Account;
      user.sexual_orientations = this.form.sexual_orientations;
      this.$store.commit("setUser", user);

      this.nextStep();
    }
  }
});
</script>
