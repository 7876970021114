<template>
  <ImagePicker
    :button-class="buttonClass"
    :disabled="uploading || disabled"
    :min-width="minWidth"
    :min-height="minHeight"
    @upload="onPictureUpload"
  >
    <template #default="props">
      <slot
        name="default"
        v-bind="{uploading: uploading, selecting: props.selecting, dragging: props.dragging}"
      />
      <slot
        name="loading"
        v-bind="{uploading: uploading, selecting: props.selecting, dragging: props.dragging}"
      >
        <BaseLoadingCover
          icon-class="text-primary-600 w-6 h-6"
          :model-value="uploading || props.selecting"
        />
      </slot>
    </template>
  </ImagePicker>

  <ImageCropperModal
    v-model="showCropperModal"
    :source-image="croppableImage"
    :after-save="upload"
    @cancel="cancelCrop()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { base64ToBlob, blobToBase64, validateBase64 } from "@/services/utils";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { ImagePickerResult } from "./ImagePicker/type";

export default defineComponent({
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    beforeUpload: {
      default: () : boolean => {
        return true;
      },
      type: Function as PropType<() => boolean>
    },
    afterUpload: {
      default: (key: string) : Promise<boolean> => {
        return new Promise((resolve) => {
          resolve(true);
        });
      },
      type: Function as PropType<(key: string) => Promise<boolean>>
    },
    buttonClass: {
      default: "",
      type: String
    },
    maxSize: {
      default: 1024 * 1024 * 10, // 10 MB,
      type: Number
    },
    minWidth: {
      default: 0,
      type: Number,
    },
    minHeight: {
      default: 0,
      type: Number,
    },
    croppable: {
      default: false,
      type: Boolean,
    }
  },
  emits: ["success", "fail"],
  data () {
    return {
      uploading: false,
      showCropperModal: false,
      croppableImage: "",
    };
  },
  methods: {
    async onPictureUpload(result: ImagePickerResult) {

      if (!(await this.beforeUpload())) {
        return;
      }

      if (this.croppable) {
        this.launchCropper(result);
        return;
      }

      this.uploading = true;

      let blob = new Blob;

      if (result.file) {
        blob = result.file;
      } else {
        blob = await base64ToBlob(result.data);
      }

      this.upload(blob);
    },
    async launchCropper(result: ImagePickerResult) {
      if (result.file) {
        this.croppableImage = await blobToBase64(result.file);
      } else if (result.data) {
        let mime = result.mime ?? "image/png";
        let base64 = validateBase64(result.data, "data:" + mime);
        this.croppableImage = base64;
      }

      if (this.croppableImage) {
        this.showCropperModal = true;
      }
    },
    async upload(blob: Blob) : Promise<void> {
      try {

        if (blob.size > this.maxSize) {
          this.$notify({
            type: Type.danger,
            message: this.$t("the_file_size_must_not_exceed_x", {x: this.$display.fileSize(this.maxSize)})
          });
          return;
        }

        const response = await this.$vapor.store(blob as File, {});

        await this.afterUpload(response.key);

        this.$emit("success", response.key);
      }
      catch (e: any) {
        this.$emit("fail");
      } finally {
        this.showCropperModal = false;
        this.uploading = false;
      }
    },
    cancelCrop () {
      this.showCropperModal = false;
      this.croppableImage = "";
    }
  }
});
</script>
