import type { QuestionDependency } from "./QuestionDependency";
import type { QuestionOption } from "./QuestionOption";

interface Question {
  id: string,
  label: string,
  text: string,
  name: string,
  description: string | null,
  notice: string | null,
  required: boolean,
  type: {
    input_type: QuestionType,
    name: string,
    min?: number,
    max?: number,
    rows?: number,
  },
  properties: {
    alt_question_texts?: AltQuestionText[]
  },
  options: QuestionOption[],
  dependencies: QuestionDependency[]
}

interface AltQuestionText {
  text: { [key: string]: string },
  option_ids: string[],
}

export enum QuestionType {
  input = "input",
  textarea = "textarea",
  select = "select",
  multi_select = "multi_select",
  size = "size",
}

export type { Question };
