<template>
  <AuthLayout back-url="/login">
    <div>
      <h1 class="font-display mt-6 text-3xl font-bold leading-none text-gray-900">
        {{ $t('page.forgot_password.title') }}
      </h1>
      <p class="mt-3 leading-tight text-gray-600">
        {{ $t('page.forgot_password.subtitle') }}
      </p>
    </div>

    <div class="mt-6">
      <VForm
        method="post"
        :data="form"
        :url="url"
        :http-client="httpClient"
        loading-mask-class="bg-white"
        @success="onSuccess"
        @error="onError"
      >
        <InputLabel :label="$t('email_address')" />
        <div class="mt-1">
          <VInput
            id="email"
            v-model="form.email"
            name="email"
            type="email"
            required
            input-class="w-full"
          />
        </div>

        <BaseAlert
          v-if="success"
          type="success"
          class="mt-3 border border-green-200"
        >
          {{ $t('page.forgot_password.success') }}
        </BaseAlert>

        <div class="mt-4">
          <BaseButton
            type="submit"
            class="btn-primary btn-lg w-full mb-0"
          >
            {{ $t('reset_password') }}
          </BaseButton>
        </div>

        <div class="mt-4">
          <router-link
            class="text-base text-gray-600 underline"
            to="/login"
          >
            {{ $t('return_to_login') }}
          </router-link>
        </div>
      </VForm>
    </div>
  </AuthLayout>
</template>

<script lang="ts">
import { getRoute } from "@/services/utils";
import { defineComponent } from "vue";
import AuthLayout from "./AuthLayout.vue";
import InputLabel from "@/plugins/form/components/utils/InputLabel.vue";
import { sendLocale, showNotificationOnError } from "@/services/api";
import axios from "axios";

const httpClient = axios.create({
  withCredentials: true,
});

httpClient.interceptors.request.use(sendLocale);
httpClient.interceptors.response.use(response => response, showNotificationOnError);

export default defineComponent({
  components: {
    AuthLayout,
    InputLabel
  },
  emits: ["loaded"],
  data () {
    return {
      httpClient: httpClient,
      form: {
        email: "",
      },
      success: false
    };
  },
  computed: {
    url (): string {
      return getRoute("forgot-password");
    }
  },
  async created () {
    this.$emit("loaded");
  },
  methods: {
    onError() {
      this.success = false;
    },
    onSuccess() {
      this.form.email = "";
      this.success = true;
    }
  }
});
</script>
