<template>
  <div class="static">
    <MobileHead v-if="mobile" />
    <DesktopMenu
      v-else
      class="shrink-0 z-menu"
    >
      <DesktopMenuLink
        v-for="(link, i) in links"
        :key="i"
        :link="link"
      />
    </DesktopMenu>

    <div class="grow static w-full">
      <MobileHeadSpacer v-if="mobile" />
      <DesktopMenuSpacer v-else />
      <slot />
      <MobileMenuSpacer v-if="mobile" />
    </div>

    <NotificationList />

    <MobileMenu
      v-if="showMenu && mobile"
      class="fixed bottom-0 left-0 w-full"
    >
      <MobileMenuLink
        v-for="(link, i) in links"
        :key="i"
        :link="link"
      />
    </MobileMenu>
  </div>
</template>

<script lang="ts">

import { defineComponent, inject, PropType, Ref } from "vue";
import { Link } from "./models/Link";

import DesktopMenu from "./DesktopMenu.vue";
import DesktopMenuLink from "./DesktopMenuLink.vue";
import DesktopMenuSpacer from "./DesktopMenuSpacer.vue";

import MobileMenu from "./MobileMenu.vue";
import MobileMenuLink from "./MobileMenuLink.vue";
import MobileMenuSpacer from "./MobileMenuSpacer.vue";
import MobileHead from "./MobileHead.vue";
import MobileHeadSpacer from "./MobileHeadSpacer.vue";

import NotificationList from "./NotificationList.vue";

export default defineComponent({
  components: {
    DesktopMenu,
    DesktopMenuLink,
    DesktopMenuSpacer,

    MobileMenu,
    MobileMenuLink,
    MobileMenuSpacer,
    MobileHead,
    MobileHeadSpacer,

    NotificationList,
  },
  props: {
    links: {
      type: Array as PropType<Link[]>,
      default() {
        return [];
      }
    }
  },
  setup() {
    const mobile = inject("mobile") as Ref<boolean>;
    return {
      mobile
    };
  },
  computed: {
    showMenu () : boolean {
      return this.$route.name != "member.messages.conversation";
    }
  }
});
</script>
