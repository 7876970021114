import { ConversationMember } from "@/models/Conversation";
import { DailyQuoteMember } from "@/models/DailyQuote";
import { Member } from "@/models/Member";
import { MemberBlock } from "@/models/MemberBlock";

export class Urls {
  member(member: Member | ConversationMember | DailyQuoteMember | MemberBlock | undefined): string {
    if (!member) {
      return "";
    }
    return `/member/profile/${member.id}`;
  }
}

const urls = new Urls();

export { urls };
