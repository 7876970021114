<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.preferences.communications.title')"
        :subtitle="$t('page.settings.menu.preferences.communications.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
      paddingless-mobile
    >
      <BaseCard>
        <BaseCardRow>
          <BaseSwitch
            v-model="form.notifications_priority_message"
            @update:model-value="onUpdate"
          >
            <h3 class="font-semibold">
              {{ $t('priority_inbox') }}
            </h3>
            <p class="leading-tight text-gray-500">
              {{ $t('priority_inbox_description') }}
            </p>
          </BaseSwitch>
        </BaseCardRow>
        <BaseCardRow>
          <BaseSwitch
            v-model="form.notifications_secondary_message"
            @update:model-value="onUpdate"
          >
            <h3 class="font-semibold">
              {{ $t('secondary_inbox') }}
            </h3>
            <p class="leading-tight text-gray-500">
              {{ $t('secondary_inbox_description') }}
            </p>
          </BaseSwitch>
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { Account } from "@/models/Account";
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

export default defineComponent({
  data() {
    return {
      loading: false,
      form: {
        notifications_priority_message: this.$store.state.user?.settings.notifications_priority_message ?? false,
        notifications_secondary_message: this.$store.state.user?.settings.notifications_secondary_message ?? false,
      }
    };
  },
  methods: {
    onUpdate() {
      if (this.loading) {
        return;
      }

      if (!this.$store.state.user?.member) {
        return;
      }

      this.loading = true;

      const oldSettings = this.$store.state.user.settings;

      this.$api.patch("account/settings/notifications", this.form)
        .then(response => {

          if (response.data.message) {
            this.$notify({
              message: response.data.message as string,
              type: Type.success,
            });
          }

          const user = cloneDeep(this.$store.state.user) as Account;
          user.settings.notifications_priority_message = this.form.notifications_priority_message;
          user.settings.notifications_secondary_message = this.form.notifications_secondary_message;
          this.$store.commit("setUser", user);
        })
        .catch(() => {
          this.form.notifications_priority_message = oldSettings.notifications_priority_message;
          this.form.notifications_secondary_message = oldSettings.notifications_secondary_message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
