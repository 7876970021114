enum LengthUnits {
  centimeter = "centimeter",
  meter = "meter",
  kilometer = "kilometer",
  inch = "inch",
  foot = "foot",
  mile = "mile",
}

enum LengthUnitsAbbreviations {
  centimeter = "cm",
  meter = "m",
  kilometer = "km",
  inch = "\"",
  foot = "'",
  mile = "mi",
}

const lengthUnits = {
  [LengthUnits.centimeter]: 100000,
  [LengthUnits.meter]: 1000,
  [LengthUnits.kilometer]: 1,
  [LengthUnits.inch]: 39370.1,
  [LengthUnits.foot]: 3280.84,
  [LengthUnits.mile]: 0.621371
} as { [key: string]: number };

function convertLength(length: number, from: LengthUnits, to: LengthUnits): number {
  const ratio1 = lengthUnits[from];
  const ratio2 = lengthUnits[to];
  return length * ratio2 / ratio1;
}

export { convertLength, LengthUnitsAbbreviations, LengthUnits };
