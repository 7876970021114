<template>
  <div class="grow-0 shrink-0 z-menu w-full bg-white border-t border-gray-200">
    <div class="h-menu-mobile flex items-stretch justify-around w-full">
      <slot />
    </div>
    <div class="pb-safe" />
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  components: {
  }
});
</script>
