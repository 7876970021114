<template>
  <div
    v-if="value"
    class="relative flex overflow-hidden"
  >
    <input
      type="text"
      class="focus:ring-0 focus:border-gray-300 focus:outline-none h-14 w-full px-4 m-0 leading-tight text-gray-600 border border-r-0 border-gray-300 rounded-l"
      :value="value"
      readonly
    >
    <button
      type="button"
      class="btn btn-primary shrink-0 btn-lg h-14 relative rounded-l-none"
      @click="copy"
    >
      <span :class="{'opacity-0': showCopied}">{{ $t('copy') }}</span>

      <div
        class="absolute inset-0 flex items-center justify-center w-full h-full transition-transform duration-150"
        :class="{
          'opacity-0': !showCopied,
          'scale-150': showCopied
        }"
      >
        <Icon
          icon="heroicons-solid:check"
          class="w-5 h-5"
        />
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Clipboard } from "@capacitor/clipboard";

export default defineComponent({
  props: {
    value: {
      default: null,
      type: String,
    }
  },
  data () {
    return {
      showCopied: false,
    };
  },
  methods: {
    copy() {
      Clipboard.write({
        string: this.value
      });

      this.showSuccessMessage();
    },
    showSuccessMessage() {
      this.showCopied = true;
    }
  }
});
</script>
