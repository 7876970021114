<template>
  <div class="md:rounded-lg md:border md:bg-white md:border-gray-100 md:shadow-sm md:overflow-hidden">
    <slot />
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

});
</script>
