<template>
  <div class="bg-gray-50 pt-safe">
    <AmbassadorStats />

    <div class="mb-10" />

    <AmbassadorBalance />

    <div class="mb-10" />

    <PaymentsList />

    <div class="mb-10" />

    <ReferralList />
  </div>

  <BaseFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AmbassadorStats from "./components/AmbassadorStats.vue";
import AmbassadorBalance from "./components/AmbassadorBalance.vue";
import PaymentsList from "./components/PaymentsList.vue";
import ReferralList from "./components/ReferralList.vue";

export default defineComponent({
  components: {
    AmbassadorStats,
    AmbassadorBalance,
    PaymentsList,
    ReferralList
  },
});
</script>
