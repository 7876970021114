<template>
  <div class="my-4 text-xs font-medium text-center text-gray-400">
    {{ text }}
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    timestamp: {
      required: true,
      type: Object as PropType<DateTime>,
    }
  },
  computed: {
    text () : string {
      if (this.timestamp.hasSame(this.$luxon.now(), "day")) {
        return this.timestamp.toFormat("h:mm a");
      }

      if (this.timestamp > this.$luxon.now().minus({days: 7})) {
        return this.timestamp.toFormat("EEE h:mm a", {locale: this.$i18n.locale}).capitalize();
      }

      return this.timestamp.toFormat("yyyy-MM-dd, hh:mm a");
    }
  }
});
</script>
