<template>
  <div
    class="flex items-start justify-center p-4 rounded-lg shadow"
    :class="[background, text]"
  >
    <div
      v-if="notification.icon"
      class="top-[3px] relative mr-2"
    >
      <Icon
        v-if="notification.type == 'warning'"
        icon="heroicons-solid:exclamation"
        :class="iconClass"
      />
      <Icon
        v-else-if="notification.type == 'danger'"
        icon="heroicons-solid:exclamation-circle"
        :class="iconClass"
      />
      <Icon
        v-else-if="notification.type == 'success'"
        icon="heroicons-solid:check-circle"
        :class="iconClass"
      />
      <Icon
        v-else-if="notification.type == 'info'"
        icon="heroicons-solid:information-circle"
        :class="iconClass"
      />
    </div>
    <div class="leading-tight">
      {{ notification.message }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Notification } from "@/services/notificator/models/Notification";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

export default defineComponent({
  props: {
    notification: {
      required: true,
      type: Notification
    }
  },
  data () {
    return {
      iconClass: "w-4 h-4"
    };
  },
  computed: {
    background() {
      if (this.notification.type == Type.success) {
        return "bg-green-600";
      }
      if (this.notification.type == Type.danger) {
        return "bg-red-600";
      }
      if (this.notification.type == Type.warning) {
        return "bg-yellow-400";
      }
      return "bg-gray-900";
    },
    text() {
      if (this.notification.type == Type.warning) {
        return "text-yellow-900";
      }
      return "text-white";
    }
  },
});
</script>
