import { disablePageScroll, clearQueueScrollLocks, enablePageScroll, ScrollableTarget } from "scroll-lock";

function disableScroll(allow: ScrollableTarget | null = null) {

  if (allow == null) {
    allow = document.querySelectorAll("[scroll-lock-target]");
  }

  disablePageScroll(allow);
}

function enableScroll() {
  clearQueueScrollLocks();
  enablePageScroll();
}

export { disableScroll, enableScroll };
