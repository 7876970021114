<template>
  <div class="relative">
    <BaseAlert
      type="info"
      class="mb-10 border border-blue-300"
    >
      {{ $t('step.sample_picture.info') }}
    </BaseAlert>

    <div class="flex flex-wrap -m-2">
      <div class="flex-1 p-2">
        <img
          src="@/assets/img/onboarding/sample-picture-2.png"
          alt="Sample picture"
          class="rounded-lg"
        >
      </div>
      <div class="flex-1 p-2">
        <ImageUploader
          :min-width="minWidth"
          :min-height="minHeight"
          :max-size="maxSize"
          croppable
          button-class="hover:bg-gray-50 relative w-full h-full max-w-sm overflow-hidden transition-colors bg-white border border-gray-200 rounded-md shadow-sm"
          :after-upload="afterUpload"
        >
          <template #default>
            <div class="group relative flex items-center justify-center h-full px-5 py-4">
              <img
                v-if="samplePicture"
                :src="$assetUrl(samplePicture.base_path, {resize: {height: 600, width: 600}})"
                alt="Sample picture"
                class="absolute inset-0 object-cover object-center w-full h-full"
              >

              <div
                v-if="samplePicture"
                class="opacity-60 absolute inset-0 object-cover object-center w-full h-full bg-gray-900"
              />

              <div class="relative">
                <div
                  class="inline-block p-2 mx-auto rounded-full"
                  :class="{
                    'bg-white': samplePicture,
                    'bg-gray-200': !samplePicture,
                  }"
                >
                  <Icon
                    icon="heroicons-outline:upload"
                    class="w-6 h-6 text-gray-600"
                  />
                </div>
                <div class="text-center">
                  <p
                    style="text-shadow: 1px 1px 1px rgba(0,0,0,0.4);"
                    class="font-medium leading-none mb-0.5"
                    :class="{
                      'text-white': samplePicture,
                      'text-gray-800': !samplePicture,
                    }"
                  >
                    {{ $t('upload_a_sample_picture') }}
                  </p>
                </div>
              </div>
            </div>
          </template>

          <template
            #loading="props"
          >
            <BaseLoadingCover
              icon-class="w-6 h-6 text-white"
              backdrop-class="opacity-80 bg-gray-900"
              :model-value="props.uploading || fetching || props.selecting"
            />
          </template>
        </ImageUploader>
      </div>
    </div>

    <div class="mt-4">
      <img
        src="@/assets/img/onboarding/sample-examples.jpg"
        alt="Sample picture"
        class="block rounded-lg pointer-events-none"
      >
      <p class="mt-3 leading-tight text-gray-600">
        {{ $t('step.sample_picture.image_no_accessories') }}
      </p>
    </div>

    <div class="mt-6">
      <h3 class="text-md mb-2 font-semibold">
        {{ $t('sample_picture_specifications') }}
      </h3>
      <div class="leading-tight text-gray-600">
        <p class="mb-1">
          PNG, JPG {{ $t('or') }} GIF <br>
        </p>
        <p class="mb-1">
          {{ $t('up_to_x', {x: $display.fileSize(maxSize)}).capitalize() }}<br>
        </p>
        <p class="mb-1">
          {{ $t('at_least_size', {size: `${minWidth} x ${minHeight} pixels`}) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Media } from "@/models/Media";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";

export default defineComponent({
  data () {
    return {
      uploading: false,
      fetching: false,
      samplePicture: null as Media|null,
      minWidth: 250,
      minHeight: 250,
      maxSize: 1024 * 1024 * 10,
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch () {
      this.fetching = true;
      this.$api.get("/account/member/sample-picture")
        .then(response => {
          this.samplePicture = response.data.data as Media|null;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    async afterUpload(key: string) : Promise<boolean> {
      await this.$api.post("/account/member/sample-picture", {
        key: key,
      });
      this.$notify({
        message: this.$t("picture_successfully_uploaded"),
        type: Type.success
      });
      this.fetch();
      return true;
    }
  }
});
</script>
