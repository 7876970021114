import { IAPProduct } from "@ionic-native/in-app-purchase-2";

export interface ProductState {
  list: Record<string, IAPProduct>
}

export const defaultProductState = {
  list: {},
} as ProductState;

export const productModule = {
  namespaced: true,
  state() {
    return defaultProductState;
  },
  mutations: {
    set(state: ProductState, payload: { id: string, product: IAPProduct }) {
      state.list[payload.id] = payload.product;
    },
  },
  getters: {
    getById: (state: ProductState) => (productId: string): IAPProduct | null => {
      return state.list[productId] ?? null;
    },
  }
};
