<template>
  <div data-cy="input-date">
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
      class="mb-2"
    />

    <div class="flex flex-wrap -m-0.5">
      <div class="p-0.5">
        <select
          v-model="date.year"
          :disabled="disabled"
          data-cy="year"
          class="rounded"
          :class="[{
            'bg-gray-100 cursor-not-allowed text-gray-500': disabled,
            'border-red-600': hasError(),
            'border-gray-300': !hasError(),
          }, inputClass]"
          @change="update()"
        >
          <option
            v-for="year in years"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </select>
      </div>

      <div class="p-0.5">
        <select
          v-model="date.month"
          :disabled="disabled"
          data-cy="month"
          class="capitalize rounded"
          :class="[{
            'bg-gray-100 cursor-not-allowed text-gray-500': disabled,
            'border-red-600': hasError(),
            'border-gray-300': !hasError(),
          }, inputClass]"
          @change="update()"
        >
          <option
            v-for="(month, i) in months"
            :key="month"
            :value="i + 1"
          >
            {{ month }}
          </option>
        </select>
      </div>

      <div class="p-0.5">
        <select
          v-model="date.day"
          :disabled="disabled"
          data-cy="day"
          class="capitalize rounded"
          :class="[{
            'bg-gray-100 cursor-not-allowed text-gray-500': disabled,
            'border-red-600': hasError(),
            'border-gray-300': !hasError(),
          }, inputClass]"
          @change="update()"
        >
          <option
            v-for="(day) in days"
            :key="day"
            :value="day"
          >
            {{ day }}
          </option>
        </select>
      </div>
    </div>

    <p
      v-if="hasError()"
      class="form-input-error mt-1"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";
import { range, padStart } from "lodash-es";
import { DateTime, Info } from "luxon";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "VInputDate",
  components: {
    InputLabel,
  },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [String,null] as PropType<string|null>,
    },
  },
  data () {
    return {
      years: [] as number[],
      date: {
        year: null as number|null,
        month: null as number|null,
        day: null as number|null,
      }
    };
  },
  computed: {
    months () : string[] {
      return Info.months("short", {locale: i18n.global.locale});
    },
    days() : number[] {

      if (!this.date.year) {
        return [];
      }

      if (!this.date.month) {
        return [];
      }

      let d = new Date(this.date.year, this.date.month, 0);
      return range(1, d.getDate() + 1);

    },
  },
  watch: {
    modelValue: {
      handler () {
        if (this.modelValue) {
          const datetime = this.$luxon.fromISO(this.modelValue);
          this.date.year = datetime.year;
          this.date.month = datetime.month;
          this.date.day = datetime.day;
        }
      },
      immediate: true
    }
  },
  created () {
    this.fillYears();
  },
  methods: {
    fillYears() {
      const currentYear = this.$luxon.now().year;
      const maxYear = currentYear - 18;
      const minYear = currentYear - 120;

      this.years = range(maxYear, minYear) as number[];
    },
    update () {
      const dateTime = this.getDateTime();

      if (dateTime) {
        this.inputListener(dateTime.toISODate());
      } else {
        this.inputListener(null);
      }
    },
    getDateTime(): DateTime|null {
      if (!this.date.year || !this.date.month || !this.date.day) {
        return null;
      }

      return this.$luxon.fromISO(
        padStart(this.date.year + "", 4, "0") + "-" +
        padStart(this.date.month + "", 2, "0") + "-" +
        padStart(this.date.day + "", 2, "0")
      );
    },
  }
});
</script>
