<template>
  <BaseContainer
    size="4xl"
    paddingless-mobile
  >
    <div
      v-if="orders.length"
      class="mb-8"
    >
      <BaseCard class="relative overflow-hidden">
        <BaseLoadingCover v-model="loading" />

        <BaseCardRow>
          <h2 class="font-semibold">
            {{ $t('past_payments') }}
          </h2>
        </BaseCardRow>

        <hr class="border-gray-200">

        <ul
          role="list"
          class="divide-y divide-gray-200"
        >
          <li
            v-for="order in orders"
            :key="order.id"
          >
            <a
              :href="order.pdf_url"
              class="hover:bg-gray-50 block"
            >
              <div class="md:px-6 sm:items-center flex items-end px-4 py-4">
                <div class="sm:flex sm:items-center sm:justify-between flex-1 min-w-0">
                  <div class="truncate">
                    <div class="flex">
                      <p class="font-semibold leading-tight truncate">{{ $luxon.fromISO(order.created_at).setLocale($i18n.locale).toFormat('DD') }}</p>
                    </div>
                    <div class="flex mt-0.5">
                      <div class="flex items-center text-sm text-gray-500">
                        <p>
                          {{ order.lines[0]?.description ?? $t('subscription') }} • {{ $t(order.status) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="sm:mt-0 sm:ml-5 flex-shrink-0 mt-2">
                    <p class="font-normal text-gray-700">{{ order.total.priceFormat() }}</p>
                  </div>
                </div>
                <div class="flex-shrink-0 ml-5">
                  <Icon
                    class="w-5 h-5 text-gray-500"
                    icon="heroicons-solid:download"
                  />
                </div>
              </div>
            </a>
          </li>
        </ul>
      </BaseCard>
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import { AccountMember } from "@/models/Account";
import { Order } from "@/models/Order";
import { defineComponent } from "vue";
export default defineComponent({
  data () {
    return {
      loading: false,
      orders: [] as Order[],
    };
  },
  computed: {
    member () : AccountMember {
      return this.$store.state.user?.member as AccountMember;
    }
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$api.get("account/orders")
        .then(response => {
          this.orders = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
