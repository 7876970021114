<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.preferences.address.title')"
        :subtitle="$t('page.settings.menu.preferences.address.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
    >
      <VForm
        :data="form"
        url="account/address"
        method="patch"
        @success="onSuccess"
      >
        <AddressForm v-model="form" />
        <BaseButton class="btn btn-primary sm:w-auto w-full">
          {{ $t('save') }}
        </BaseButton>
      </VForm>
    </BaseContainer>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { cloneDeep } from "lodash-es";
import { Account } from "@/models/Account";

export default defineComponent({
  data() {
    return {
      form: {
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
      }
    };
  },
  created () {
    this.hydrateForm();
  },
  methods: {
    hydrateForm () {
      this.form.address_1 = this.$store.state.user?.address_1 ?? "";
      this.form.address_2 = this.$store.state.user?.address_2 ?? "";
      this.form.city = this.$store.state.user?.city ?? "";
      this.form.state = this.$store.state.user?.state ?? "";
      this.form.country = this.$store.state.user?.country ?? "ca";
      this.form.postal_code = this.$store.state.user?.postal_code ?? "";
    },
    onSuccess() {
      const user = cloneDeep(this.$store.state.user) as Account;
      user.address_1 = this.form.address_1;
      user.address_2 = this.form.address_2;
      user.city = this.form.city;
      user.state = this.form.state;
      user.country = this.form.country;
      user.postal_code = this.form.postal_code;
      this.$store.commit("setUser", user);
    }
  }
});
</script>
