<template>
  <div class="bg-steel-200 text-steel-900 border-steel-400 shrink-0 inline-flex items-center text-sm font-normal border rounded">
    <div
      class="py-2 pl-3"
      @click="showFilters"
    >
      <div class="whitespace-nowrap">
        <slot />
      </div>
    </div>
    <FilterListRemove @click="onRemove" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from "vue";
import { Filters } from "../../models/Filters";
import FilterListRemove from "./FilterListRemove.vue";

export default defineComponent({
  components: {
    FilterListRemove
  },
  props: {
    filter: {
      required: true,
      type: String as PropType<keyof Filters>
    }
  },
  emits: ["remove"],
  setup() {
    const showFilters = inject("showFilters") as () => void;
    return {
      showFilters
    };
  },
  computed: {
    filters() : Filters {
      return this.$store.getters.filters;
    }
  },
  methods: {
    onRemove() : void {
      if (this.filters[this.filter]) {
        this.$store.commit("unsetFilters", this.filter);
      }
      this.$emit("remove");
    }
  }
});
</script>
