<template>
  <div class="group last:border-b-0 px-3 py-3 border-b border-gray-200">
    <h3 class="mb-1.5 leading-tight text-gray-700">
      {{ title }}
    </h3>
    <div class="flex flex-wrap -m-0.5">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String
    }
  }
});
</script>
