<template>
  <div class="bg-gray-50 pt-safe relative min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('modify_my_email')"
        :subtitle="$t('modify_my_email_description')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
    >
      <EmailNotVerified />
    </BaseContainer>

    <BaseContainer
      paddingless-mobile
      size="2xl"
    >
      <EmailForm />
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

});
</script>
