<template>
  <BaseSideModal
    :model-value="visibleSorts"
    @update:model-value="$event == false ? hideSorts() : null"
  >
    <div class="shrink-0 sm:py-6 p-4 border-b border-gray-200">
      <h3
        class="text-xl font-semibold leading-6 text-gray-900"
      >
        {{ $t('sort') }}
      </h3>
    </div>

    <SearchSort
      :sort-key="Sort.proximity"
      :title="$t('sorts.proximity.title')"
      :description="$t('sorts.proximity.description')"
    />
    <SearchSort
      :sort-key="Sort.values"
      :title="$t('sorts.values.title')"
      :description="$t('sorts.values.description')"
    />
    <SearchSort
      :sort-key="Sort.connection"
      :title="$t('sorts.last_connection.title')"
      :description="$t('sorts.last_connection.description')"
    />

    <div class="shrink-0 p-4 mt-4 space-x-2">
      <BaseButton
        ref="applyButtonRef"
        class="btn-primary"
        @click="hideSorts"
      >
        {{ $t('apply') }}
      </BaseButton>
    </div>
  </BaseSideModal>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from "vue";
import SearchSort from "./SearchSort.vue";

import { Sort } from "../../models/Sort";

export default defineComponent({
  components: {
    SearchSort
  },
  setup() {
    const visibleSorts = inject("visibleSorts") as Ref<boolean>;
    const hideSorts = inject("hideSorts") as () => void;
    return {
      visibleSorts,
      hideSorts,
      Sort
    };
  }
});
</script>
