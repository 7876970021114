<template>
  <div class="bg-primary-50 flex items-center justify-center min-h-screen">
    <div class="max-w-md mx-auto">
      <div class="px-5 text-center">
        <img
          src="@/assets/img/logo/logo-icon-primary-600.svg"
          alt="Effet Tandem"
          class="inline w-12 mb-5"
        >

        <h1 class="font-display mb-2 text-2xl font-semibold leading-tight">
          {{ $t('page.blocked.title') }}
        </h1>

        <p class="text-primary-700 mb-6 text-lg leading-tight">
          {{ $t('page.blocked.line_1') }}
        </p>

        <button
          type="submit"
          class="btn btn-primary"
          @click="$auth.logout()"
        >
          {{ $t('logout') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["loaded"],
  mounted () {
    this.$emit("loaded");
  }
});
</script>
