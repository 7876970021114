
import { FacebookLogin } from "@capacitor-community/facebook-login";

class FacebookClient {

  async init() {
    await FacebookLogin.initialize({
      appId: import.meta.env.VITE_FACEBOOK_CLIENT_ID,
    });
  }

  async getToken(): Promise<string | null> {
    const FACEBOOK_PERMISSIONS = ["public_profile", "email"];

    const result = await FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS
    });

    return result.accessToken?.token ?? null;
  }

  async getProfile(): Promise<{
    id: string,
    email: string;
    first_name: string;
    last_name: string;
  }> {
    const result = await FacebookLogin.getProfile<{
      id: string,
      email: string;
      first_name: string;
      last_name: string;
    }>({ fields: ["email", "first_name", "last_name"] });

    return result;
  }
}

const facebookClient = new FacebookClient;

export { facebookClient };
