<template>
  <div class="flex items-center justify-center">
    <template
      v-if="size == 'small'"
    >
      <img
        v-if="gender == 'male'"
        src="@/assets/img/flowers/flower-male-small.svg"
        alt="Flower"
        @load="$emit('load')"
      >
      <img
        v-else-if="gender == 'female'"
        src="@/assets/img/flowers/flower-female-small.svg"
        alt="Flower"
        @load="$emit('load')"
      >
      <img
        v-else
        src="@/assets/img/flowers/flower-non_binary-small.svg"
        alt="Flower"
        @load="$emit('load')"
      >
    </template>
    <template v-else>
      <img
        v-if="gender == 'male'"
        src="@/assets/img/flowers/flower-male.svg"
        alt="Flower"
        @load="$emit('load')"
      >
      <img
        v-else-if="gender == 'female'"
        src="@/assets/img/flowers/flower-female.svg"
        alt="Flower"
        @load="$emit('load')"
      >
      <img
        v-else
        src="@/assets/img/flowers/flower-non_binary.svg"
        alt="Flower"
        @load="$emit('load')"
      >
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    gender: {
      required: true,
      type: String,
    },
    size: {
      default: "large",
      type: String
    }
  },
  emits: ["load"]
});
</script>
