<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />
    <TagInput
      :model-value="modelValue"
      :name="name"
      :placeholder="placeholder"
      :options="options"
      :label-key="labelKey"
      :value-key="valueKey"
      :min="min"
      :max="max"
      class="bg-white rounded"
      :class="[{
        'border-red-600': hasError(),
        'border-gray-300': !hasError(),
      }, inputClass]"
      @update:model-value="onUpdate"
    />
    <p
      v-if="hasError()"
      class="form-input-error mt-1"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";
import TagInput from "../components/elements/TagInput.vue";
import { Option, OptionValue } from "../types/types";

export default defineComponent({
  name: "VInputTag",
  components: {
    InputLabel,
    TagInput
  },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [Array, null] as PropType<OptionValue[]|null>
    },
    placeholder: {
      default: undefined,
      type: String,
    },
    options: {
      required: true,
      type: Array as PropType<Option[]>
    },
    labelKey: {
      required: true,
      type: String
    },
    valueKey: {
      required: true,
      type: String
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
  },
  methods: {
    onUpdate(selections: OptionValue[]) {
      this.inputListener(selections);
    }
  }
});
</script>
