import { NavigationGuardNext, RouteLocation } from "vue-router";
import { auth } from "@/services/auth";
import { store } from "@/store";
import { SubscriptionStatus } from "@/models/Account";
import { Step, Steps } from "@/views/onboarding/services/steps";
import { homeRoute } from "./redirections";

export function authenticated(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("authenticated", to, from);

  if (auth.guest()) {
    return next({
      name: "login",
      query: to.query
    });
  }

  return next();
}

export function guest(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("guest", to, from);

  if (auth.check()) {
    return next(homeRoute(store.state.user));
  }

  return next();
}

export function blocked(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("blocked", to, from);

  if (auth.guest()) {
    return next({
      name: "login",
      query: to.query
    });
  }

  if (!store.state.user?.blocked) {
    return next({
      name: "home",
      query: to.query
    });
  }

  return next();
}

export function notBlocked(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("notBlocked", to, from);

  if (auth.check() && store.state.user?.blocked) {
    return next({
      name: "blocked",
      query: to.query
    });
  }

  return next();
}

export function subscribed(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("subscribed", to, from);

  if (auth.guest()) {
    return next({
      name: "login",
      query: to.query
    });
  }

  if (store.state.user?.member?.subscription_status == SubscriptionStatus.inactive) {
    return next({
      name: "member.subscription",
      query: to.query
    });
  }

  return next();
}

export function isAmbassador(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("isAmbassador", to, from);

  if (auth.check() && store.state.user?.ambassador) {
    return next();
  }

  return next({
    name: "home",
    query: to.query
  });
}

export function isMember(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("isMember", to, from);

  if (auth.check() && store.state.user?.member) {
    return next();
  }

  return next({
    name: "home",
    query: to.query
  });
}

export function onboardingCompleted(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("onboardingCompleted", to, from);

  if (auth.guest()) {
    return next({
      name: "login",
      query: to.query
    });
  }

  if (!store.state.user?.member?.onboarding_completed) {
    return next({
      name: "onboarding",
      query: to.query
    });
  }

  return next();
}

export function onboardingGuestGuard(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("onboardingGuestGuard", to, from);

  if (auth.check()) {
    return next({
      name: "onboarding.terms", // redirect to first auth onboarding step
      query: to.query
    });
  }

  return next();
}

export async function onboardingAuthGuard(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {

  guardDebugger("onboardingAuthGuard", to, from);

  if (store.state.user?.member?.onboarding_completed) {
    return next({
      name: "member.search",
      query: to.query
    });
  }

  if (auth.guest()) {
    return next({
      name: "onboarding.home",
      query: to.query
    });
  }

  if (!store.state.user?.accepted_terms_date) {

    if (to.name != "onboarding.terms") {
      return next({
        name: "onboarding.terms",
        query: to.query
      });
    }

    return next();
  }

  const steps = (new Steps);
  await steps.init();
  const step = await steps.currentStepPath();

  if (to.name != "onboarding.step") {
    return next({
      name: "onboarding.step",
      params: {
        step: step
      },
      query: to.query
    });
  }

  const currentStepIndex = steps.steps.findIndex((s: Step) => s.path == step);
  const urlStepIndex = steps.steps.findIndex((s: Step) => s.path == to.params.step);

  if (urlStepIndex == -1) {
    return next({
      name: "onboarding.step",
      params: {
        step: step
      },
      query: to.query
    });
  }

  if (urlStepIndex > currentStepIndex) {
    return next({
      name: "onboarding.step",
      params: {
        step: step
      },
      query: to.query
    });
  }

  return next();
}

function guardDebugger(name: string, to: RouteLocation, from: RouteLocation) {

  if (import.meta.env.PROD) {
    return;
  }

  console.log(name, {
    guest: auth.guest(),
    to: to.fullPath,
    from: from.fullPath,
  });
}
