<template>
  <div class="sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0 space-y-5">
    <div>
      <h3 class="text-md mb-2 text-lg font-medium leading-tight">
        {{ $t('logout_component.title') }}
      </h3>
      <p class="leading-tight text-gray-600">
        {{ $t('logout_component.subtitle') }}
      </p>
    </div>

    <BaseButton
      class="btn-lg"
      @click="onLogout()"
    >
      {{ $t('logout') }}
    </BaseButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    async onLogout() {
      await this.$auth.logout();
      this.$router.push({ name: "login" });
    }
  }
});
</script>
