<template>
  <transition-group
    enter-active-class="transition duration-200 ease-out"
    enter-from-class="md:translate-y-3 -translate-y-3 opacity-0"
    enter-to-class="opacity-500 translate-200"
    leave-active-class="transition duration-200 ease-in delay-100"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="md:translate-y-3 -translate-y-3 opacity-0"
  >
    <div
      v-for="notification in $store.state.notification.items"
      :key="notification.id"
      class="z-notifications md:bottom-0 md:left-0 md:top-auto md:right-auto md:max-w-sm md:w-auto md:p-6 fixed top-0 left-0 w-full max-w-full px-6 py-4 pointer-events-none"
    >
      <MobileHeadSpacer v-if="mobile" />
      <NotificationItem
        :notification="notification"
      />
    </div>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from "vue";
import NotificationItem from "./NotificationItem.vue";
import MobileHeadSpacer from "./MobileHeadSpacer.vue";

export default defineComponent({
  components: {
    NotificationItem,
    MobileHeadSpacer
  },
  setup () {
    const mobile = inject("mobile") as Ref<boolean>;
    return {
      mobile
    };
  }
});
</script>
