<template>
  <router-link
    :to="`/member/personal-development/${article.id}`"
    class="group relative"
  >
    <div
      v-if="article.access_level == 'member'"
      class="absolute w-full z-[1] left-2 -top-4"
    >
      <div class="inline-flex items-center px-3 py-1 text-sm font-semibold text-white bg-blue-600 rounded shadow">
        <Icon
          class="-top-px relative inline mr-1"
          icon="heroicons-solid:lock-closed"
        />
        <span class="truncate">
          {{ $t('_article_access_levels.member') }}
        </span>
      </div>
    </div>
    <div
      v-else-if="article.access_level == 'vip'"
      class="absolute w-full z-[1] left-2 -top-4"
    >
      <div class="inline-flex items-center px-3 py-1 text-sm font-semibold text-white bg-yellow-600 rounded shadow">
        <Icon
          class="-top-px relative inline mr-1"
          icon="heroicons-solid:lock-closed"
        />
        <span class="truncate">
          {{ $t('_article_access_levels.vip') }}
        </span>
      </div>
    </div>
    <div
      v-else-if="$luxon.fromISO(article.created_at).diffNow('days').days > -7"
      class="absolute w-full z-[1] left-2 -top-4"
    >
      <div class="inline-flex items-center px-3 py-1 text-sm font-semibold text-white bg-green-600 rounded shadow">
        <span class="truncate">
          {{ $t('page.personal_development.new') }}
        </span>
      </div>
    </div>

    <div class="group-hover:shadow-2xl flex flex-col h-full overflow-hidden transition-shadow duration-150 rounded-lg shadow-lg">
      <div class="aspect-h-9 aspect-w-16 relative w-full">
        <img
          v-if="article.image_path"
          :src="$assetUrl(article.image_path, {resize: {width: 960, height: 540}})"
          :alt="article.title"
          class="absolute inset-0 block object-cover object-center w-full h-full"
        >
        <div
          v-else
          class="bg-steel-700 w-full h-full"
        />
      </div>

      <div class="lg:p-6 p-5">
        <h2 class="font-display mb-3 text-2xl font-semibold leading-5">
          {{ article.title }}
        </h2>
        <p class="sm:text-base sm:leading-tight mb-4 text-sm leading-tight text-gray-600">
          {{ article.subtitle }}
        </p>

        <div
          v-if="false"
          class="flex mb-4"
        >
          <template v-for="i in 5">
            <Icon
              v-if="i <= article.votes_avg_score"
              :key="i + 'full'"
              icon="mdi:star"
              class="block w-4 h-4 text-yellow-400"
            />
            <Icon
              v-else
              :key="i + 'empty'"
              icon="mdi:star"
              class="block w-4 h-4 text-gray-300"
            />
          </template>
        </div>
      </div>

      <div class="grow lg:p-6 flex items-end justify-between p-5">
        <div
          v-if="article.owner"
          class="flex items-center space-x-2"
        >
          <div class="shrink-0">
            <img
              v-if="article.owner.profile_image_path"
              class="sm:w-6 sm:h-6 object-cover w-5 h-5 rounded-full"
              :src="$assetUrl(article.owner.profile_image_path, {resize: {width: 50}})"
              :alt="article.owner.name"
            >
          </div>
          <p class="text-sm leading-tight text-gray-600">
            {{ $t('by') }} {{ article.owner.name }}
          </p>
        </div>
        <div class="text-gray-300">
          <Icon
            v-if="article.type == 'video'"
            icon="heroicons-outline:play"
            class="w-6 h-6"
          />
          <Icon
            v-else-if="article.type == 'article'"
            icon="heroicons-outline:document-text"
            class="w-6 h-6"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { Article } from "@/models/Article";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    article: {
      required: true,
      type: Object as PropType<Article>
    }
  }
});
</script>
