<template>
  <div class="pt-safe">
    <div class="px-6 pt-10 pb-20">
      <div class="max-w-xl mx-auto">
        <div class="mb-6">
          <HeaderLogo :back-url="backUrl" />
        </div>

        <slot />

        <OnboardingLayoutFooter />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OnboardingLayoutFooter from "./steps/OnboardingLayoutFooter.vue";
import HeaderLogo from "./components/HeaderLogo.vue";

export default defineComponent({
  components: { OnboardingLayoutFooter, HeaderLogo },
  props: {
    backUrl: {
      default: null,
      type: String,
    }
  },
});
</script>
