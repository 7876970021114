<template>
  <BaseContainer size="4xl">
    <div class="flex items-center justify-between mb-5">
      <h2 class="text-xl font-medium leading-tight">
        {{ $t('past_payments') }}
      </h2>
    </div>
  </BaseContainer>

  <BaseContainer
    size="4xl"
    paddingless-mobile
  >
    <BaseCard class="relative overflow-hidden">
      <div class="min-h-[64px]">
        <BaseLoadingCover
          v-model="loading"
          icon-class="text-primary-600 w-6 h-6"
        />
        <template v-if="ready">
          <table class="table w-full">
            <tbody>
              <tr
                v-for="balanceClaim in balanceClaims"
                :key="balanceClaim.id"
              >
                <td>{{ $luxon.fromISO(balanceClaim.updated_at).setLocale($i18n.locale).toFormat('DDD') }}</td>
                <td class="font-mono text-right">
                  {{ balanceClaim.amount.priceFormat() }}
                </td>
                <td class="text-right align-middle">
                  <a
                    :href="balanceClaim.pdf_url"
                    download
                    class="btn btn-white"
                  >
                    <Icon
                      icon="heroicons-solid:download"
                      class="w-5 h-5 text-gray-400"
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            v-if="balanceClaims.length == 0"
            class="p-6"
          >
            {{ $t('pages.ambassador.payments.no_payments') }}
          </div>
        </template>
      </div>
    </BaseCard>
  </BaseContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AmbassadorBalanceClaim } from "@/models/AmbassadorBalanceClaim";

export default defineComponent({
  data() {
    return {
      ready: false,
      loading: false,
      balanceClaims: [] as AmbassadorBalanceClaim[],
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.get("account/ambassador/balance-claims", {
        params: {
          filter: {
            status: "approved"
          }
        }
      })
        .then(response => {
          this.ready = true;
          this.balanceClaims = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
});
</script>
