import { createI18n } from "vue-i18n";

/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import Cookies from "js-cookie";

/**
 * Messages
 */

import en from "@/locales/en.json";
import fr from "@/locales/fr.json";

/**
 * Locales list
 */
export const locales = [
  { code: "fr", label: "Français" },
  { code: "en", label: "English" },
];

/**
 * Get locale from query param 'locale'
 *
 * @returns string|null
 */
const getQueryLocale = (): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (locales.map(l => l.code).includes(params.locale)) {
    return params.locale;
  }

  return null;
};

const getCookieLocale = (): string | null => {
  const locale = Cookies.get("tandem_locale");

  if (locale && locales.map(l => l.code).includes(locale)) {
    return locale;
  }

  return null;
};

/**
 * Get locale from user browser
 *
 * @returns string|null
 */
const getBrowserLocale = (): string | null => {
  if (navigator && navigator.language) {
    const locale = navigator.language.split("-")[0];

    if (locales.map(l => l.code).includes(locale)) {
      return locale;
    }
  }

  return null;
};

/**
 * Get the locale from query or browser
 * @returns string
 */
const getLocale = (): string => {
  const queryLocale = getQueryLocale();
  if (queryLocale) {
    return queryLocale;
  }

  const cookieLocale = getCookieLocale();
  if (cookieLocale) {
    return cookieLocale;
  }

  const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

  if (isBot) {
    return "fr";
  }

  const browserLocale = getBrowserLocale();
  if (browserLocale) {
    return browserLocale;
  }

  return "fr";
};

/**
 * Create i18n instance
 */
const i18n = createI18n({
  locale: getLocale(),
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "en",
  messages: {
    fr,
    en,
  }
});

export default i18n;
