<template>
  <div class="relative flex justify-center w-full">
    <div class="w-[220px] relative">
      <div class="text-primary-600 text-[86px] leading-1 flex justify-center font-bold tracking-tighter text-center">
        <div class="flower-wow-1">
          <div class="rotate-[-20deg] transform leading-none">
            W
          </div>
        </div>
        <div class="flower-wow-2">
          <div class="transform translate-y-[-14px] leading-none">
            O
          </div>
        </div>
        <div class="flower-wow-3">
          <div class="rotate-[20deg] transform leading-none">
            W
          </div>
        </div>
      </div>

      <div class="flowers absolute top-[30px] right-[-5px] flex justify-center w-full">
        <FlowerMatchIllustration
          :gender1="me.gender"
          :gender2="user.gender"
          class="w-[185px]"
        />
      </div>

      <div class="pt-[26px] flex items-center justify-center w-full">
        <div class="inline-flex items-center -space-x-5">
          <div class="avatar-1 w-24 h-24 block shadow-lg object-cover object-center overflow-hidden border-[3px] border-white rounded-full">
            <MemberAvatar
              :member="me.member"
              :size="200"
            />
          </div>
          <div class="avatar-2 w-24 h-24 block shadow-lg object-cover object-center overflow-hidden border-[3px] border-white rounded-full">
            <MemberAvatar
              :member="user.member"
              :size="200"
            />
          </div>
        </div>
      </div>

      <div class="flower-tail absolute top-[162px] right-[-6px] flex justify-center w-full">
        <img
          src="@/assets/img/flowers/flower-tail.svg"
          alt=""
          class="block w-[210px] max-w-none"
        >
      </div>

      <div class="pt-[32px] flower-text text-2xl font-bold w-full flex justify-center space-x-1.5">
        <span class="text-gray-700 capitalize">{{ $t('its') }} </span>
        <span class="text-primary-600 italic">{{ $t('mutual') }}!</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Conversation, ConversationUser } from "@/models/Conversation";
import { defineComponent, inject } from "vue";
import MemberAvatar from "../../profile/components/sections/components/MemberAvatar.vue";
import FlowerMatchIllustration from "@/plugins/icons/FlowerMatchIllustration.vue";

export default defineComponent({
  components: { MemberAvatar, FlowerMatchIllustration },
  setup() {
    const conversation = inject("conversation") as Conversation;
    const me = inject("me") as ConversationUser;
    const user = inject("user") as ConversationUser;
    return {
      conversation,
      me,
      user,
    };
  }
});
</script>

<style lang="scss" scoped>

@keyframes rotate-in-2-bck-cw {
  0% {
    transform: perspective(400px) translateZ(200px) rotate(-45deg);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

@keyframes rotate-in-2-bck-ccw {
  0% {
    transform: perspective(400px) translateZ(200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    transform: perspective(500px) translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.flower-wow-1 {
  animation: fade-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.8s;
}

.flower-wow-2 {
  animation: fade-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.8s;
}

.flower-wow-3 {
  animation: fade-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.8s;
}

.flowers {
  animation: fade-in-fwd 1.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both 0.3s;
}

.flower-tail {
  animation: fade-in-fwd 1.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both 0.3s;
}

.avatar-1 {
  animation: rotate-in-2-bck-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.1s;
}
.avatar-2 {
  animation: rotate-in-2-bck-ccw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.2s;
}

.flower-text {
  animation: fade-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both 0.2s;
}

</style>
