<template>
  <VForm
    :data="form"
    method="patch"
    url="account/phone"
    @success="$emit('success')"
  >
    <div class="mb-5">
      <v-input
        id="eid-modal-error-phone"
        v-model="form.phone"
        required
        name="phone"
        input-class="w-full"
        class="mb-2"
        :placeholder="$t('your_phone_number') + ' (' + $t('required').toLowerCase() + ')'"
      />

      <v-input
        v-model="form.phone_availability"
        name="phone_availability"
        required
        input-class="w-full"
        :placeholder="$t('best_time_to_contact_you') + ' (' + $t('required').toLowerCase() + ')'"
      />
    </div>

    <slot name="button" />
  </VForm>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["success"],
  data () {
    return {
      form: {
        phone: "",
        phone_availability: ""
      }
    };
  }
});
</script>
