<template>
  <AudioRecorder
    :max="max"
    @success="$emit('success', $event)"
  >
    <template #default="{recording, initializing, loading, toggleRecording, seconds, milliseconds}">
      <button
        class="relative w-full h-full"
        @click="toggleRecording"
      >
        <div
          class="absolute inset-0 w-full h-full transition-colors duration-200"
          :class="{
            'bg-primary-600 hover:bg-primary-100': recording,
          }"
        />

        <div
          v-if="recording"
          class="bg-primary-700 absolute top-0 left-0 h-full"
          :style="{
            width: (100 * milliseconds / (max * 1000)) + '%'
          }"
        />

        <div
          class="md:w-auto relative flex items-center w-full h-full space-x-3"
          :class="contentClass"
        >
          <div class="shrink-0">
            <div
              v-if="initializing || loading"
              class="w-7 h-7 flex items-center justify-center bg-white rounded-full"
            >
              <LoadingLine
                class="animate-spin w-5 h-5 text-blue-600"
              />
            </div>
            <div
              v-else-if="recording"
              class="w-7 h-7 flex items-center justify-center bg-white rounded-full"
            >
              <div
                class="animate-pulse shrink-0 block w-4 h-4 bg-red-600 rounded-sm"
              />
            </div>
            <div
              v-else
              class="w-7 h-7 flex items-center justify-center bg-red-600 rounded-full"
            >
              <Icon
                icon="heroicons-solid:microphone"
                class="w-4 h-4 text-white"
              />
            </div>
          </div>

          <div class="grow flex">
            <p v-if="initializing || loading">
              {{ $t('loading') }}...
            </p>
            <div
              v-else-if="recording"
              class="flex justify-end w-full"
            >
              <div class="px-1 py-0.5 font-mono text-sm leading-tight text-gray-600 bg-white rounded shadow-sm">
                {{ seconds.timerFormat() }}
              </div>
            </div>
            <p
              v-else
              class="font-semibold"
            >
              {{ $t('start_recording') }}
            </p>
          </div>
        </div>
      </button>
    </template>
  </AudioRecorder>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingLine from "@/plugins/icons/LoadingLine.vue";

export default defineComponent({
  components: {
    LoadingLine,
  },
  props: {
    max: {
      default: 30,
      type: Number
    },
    contentClass: {
      default: "px-4",
      type: String
    }
  },
  emits: ["success"]
});
</script>

