<template>
  <BaseCard class="relative mb-6 overflow-hidden">
    <BaseCardRow>
      <div class="mb-3">
        <h2 class="mb-px font-semibold leading-tight">
          {{ $t('_manual_referral_form.title') }}
        </h2>
        <p class="leading-tight text-gray-500">
          {{ $t('_manual_referral_form.text') }}
        </p>
      </div>
      <form @submit.prevent="sendReferralCode">
        <div class="flex items-center w-full -space-x-px">
          <input
            v-model="ambassadorCode"
            type="text"
            required
            name="ambassador_code"
            class="bg-white h-10 min-w-0 border-gray-200 placeholder:text-gray-400 rounded-l focus:z-[1]"
            :placeholder="$t('referral_code')"
          >

          <button
            type="submit"
            class="btn shrink-0 h-10 rounded-l-none"
          >
            {{ $t('submit') }}
          </button>
        </div>
      </form>
    </BaseCardRow>
    <BaseLoadingCover v-model="loading" />
  </BaseCard>
</template>

<script lang="ts">
import { getDeviceReference } from "@/services/deviceReference";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["success"],
  data() {
    return {
      ambassadorCode: "",
      loading: false,
    };
  },
  methods: {
    async sendReferralCode() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.post("referral", {
        device_ref: await getDeviceReference(),
        ambassador_code: this.ambassadorCode,
      })
        .then(() => {
          this.ambassadorCode = "";
          this.$emit("success");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
