<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />

    <BasePasswordInput
      :model-value="modelValue"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :required="required"
      :class="[{
        'border-red-600': hasError(),
        'border-gray-300': !hasError(),
      }, inputClass]"
      @input="onInput"
    />

    <p
      v-if="hasError()"
      class="form-input-error mt-1"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { get, isString, trim } from "lodash-es";
import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";

export default defineComponent({
  name: "VInputPassword",
  components: {
    InputLabel,
  },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [String,null] as PropType<string|null>,
    }
  },
  emits: ["validity"],
  data () {
    return {
      showPassword: false,
    };
  },
  computed: {
    normalizedModelValue() : string {
      if (isString(this.modelValue)) {
        return this.modelValue;
      }

      return "";
    },
  },
  methods: {
    onInput(event: Event|null) {
      const password = this.transformInputValue(event);
      this.inputListener(password);
    },
    transformInputValue(event: Event | null): string | null {
      if (event === null) {
        return null;
      }
      const value = get(event, "target.value", null);
      if (!isString(value)) {
        return null;
      }
      return trim(value as string);
    }
  }
});
</script>
