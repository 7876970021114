<template>
  <BaseModal
    :model-value="modelValue"
    width="max-w-sm w-full"
  >
    <div
      ref="cropperResizer"
      class="relative w-full overflow-hidden rounded-lg"
    >
      <div
        v-if="sourceImage && sizeCalculated"
      >
        <ImageCropper
          ref="imageCropper"
          :width="cropperSize"
          :height="cropperSize"
          :source-image="sourceImage"
        >
          <template #footer="{save, initializing, rotateLeft, rotateRight}">
            <div class="mt-1">
              <div class="flex justify-center space-x-2">
                <button
                  type="button"
                  :disabled="loading || initializing"
                  class="hover:enabled:bg-gray-300 disabled:opacity-30 inline-flex p-3 bg-gray-200 rounded-full appearance-none"
                  @click="rotateLeft()"
                >
                  <Icon
                    icon="mdi:rotate-left"
                    class="w-5 h-5"
                  />
                </button>
                <button
                  type="button"
                  :disabled="loading || initializing"
                  class="hover:enabled:bg-gray-300 disabled:opacity-30 inline-flex p-3 bg-gray-200 rounded-full appearance-none"
                  @click="rotateRight()"
                >
                  <Icon
                    icon="mdi:rotate-right"
                    class="w-5 h-5"
                  />
                </button>
              </div>
            </div>
            <div class="px-4 pb-5 mt-5">
              <div class="flex justify-center space-x-2">
                <button
                  type="button"
                  class="btn btn-white btn-lg"
                  @click="cancelCrop()"
                >
                  {{ $t('cancel') }}
                </button>
                <BaseButton
                  data-cy="cropper-modal-save"
                  type="button"
                  class="btn-primary btn-lg"
                  :loading="loading"
                  :disabled="loading || initializing"
                  @click="exportImageFromCropper(save)"
                >
                  {{ $t('save') }}
                </BaseButton>
              </div>
            </div>
          </template>
        </ImageCropper>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Croppie from "croppie";

const MAX_WIDTH = 384; // max-w-sm

export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean,
    },
    sourceImage: {
      default: "",
      type: String
    },
    afterSave: {
      default: (blob: Blob) : Promise<void> => {
        return new Promise((resolve) => {
          resolve();
        });
      },
      type: Function as PropType<(blob: Blob) => Promise<void>>
    },
  },
  emits: ["cancel"],
  data () {
    return {
      padding: 24,
      sizeCalculated: false,
      loading: false,
      cropperSize: MAX_WIDTH,
    };
  },
  mounted () {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize () {

      this.cropperSize = Math.min(window.innerWidth, MAX_WIDTH);

      this.sizeCalculated = true;
    },
    async exportImageFromCropper (save: (type?: Croppie.Type) => Promise<Blob|string|null>) {
      this.loading = true;

      // Give time to the loading state to render on the button before doing CPU intensive tasks
      setTimeout(async () => {

        try {
          const blob = await save("blob");

          if (blob instanceof Blob) {
            await this.afterSave(blob);
          }
        } finally {
          this.loading = false;
        }

      }, 100);
    },
    cancelCrop () {
      this.$emit("cancel");
    }
  }
});
</script>
