<template>
  <OnboardingLayout>
    <OnboardingTitle>
      {{ $t('pages.onboarding.terms.title') }}
    </OnboardingTitle>

    <OnboardingText>
      <p>{{ $t('pages.onboarding.terms.body') }}</p>
    </OnboardingText>

    <div class="mb-4" />

    <TermsText />

    <p class="mt-5 text-sm leading-snug text-gray-700">
      {{ $t('you_scroll_read_the_terms_and_conditions_before_accepting') }}
    </p>

    <div class="mt-6">
      <label
        for="subscribe_to_newsletter"
        class="flex items-start space-x-3 cursor-pointer"
      >
        <input
          id="subscribe_to_newsletter"
          v-model="subscribeToNewsletter"
          type="checkbox"
          name="subscribe_to_newsletter"
          class="input-checkbox relative mt-0.5 border-gray-600 border-[2px] rounded"
        >
        <p class="leading-tight">
          {{ $t('i_subscribe_to_the_newsletter') }}
        </p>
      </label>
    </div>

    <BaseButton
      id="onboarding-button-next-terms"
      type="button"
      :loading="loading"
      class="btn btn-primary btn-xl sm:w-auto w-full mt-8"
      @click="acceptTerms"
    >
      {{ $t('accept_terms_and_conditions') }}
    </BaseButton>
  </OnboardingLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OnboardingTitle from "./components/OnboardingTitle.vue";
import OnboardingText from "./components/OnboardingText.vue";
import { cloneDeep } from "lodash-es";
import { Account } from "@/models/Account";
import { Steps } from "./services/steps";
import OnboardingLayout from "./OnboardingLayout.vue";

export default defineComponent({
  components: {
    OnboardingTitle,
    OnboardingText,
    OnboardingLayout
  },
  data () {
    return {
      loading: false,
      subscribeToNewsletter: true,
    };
  },
  created () {
    if (this.$store.state.user?.accepted_terms_date) {
      this.next();
    }
  },
  methods: {
    acceptTerms() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.subscribeToNewsletter) {
        try {
          this.$api.post("/account/subscribe-to-newsletter");
        } catch (e: any) {
          console.error(e);
        }
      }

      this.$api.post("/onboarding/accept-terms")
        .then(() => {
          const user = cloneDeep(this.$store.state.user) as Account;
          user.accepted_terms_date = this.$luxon.now().toISOTime();
          this.$store.commit("setUser", user);
          this.next();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async next() {

      const steps = new Steps;
      await steps.init();

      this.$router.push("/onboarding/step/" + steps.steps[0].path);
    }
  }
});
</script>
