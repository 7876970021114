<template>
  <form
    class="relative"
    @submit.prevent="submit()"
  >
    <slot
      :errors="errors"
      :loading="loading"
      :submit="submit"
    />
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <slot
        v-if="loading"
        name="loading"
      >
        <div class="absolute inset-0 flex items-center justify-center w-full h-full">
          <div
            class="opacity-80 absolute inset-0 w-full h-full"
            :class="loadingMaskClass"
          />
          <LoadingLine class="animate-spin relative w-6 h-6 text-blue-600" />
        </div>
      </slot>
    </transition>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadingLine from "../icons/LoadingLine.vue";
import { serialize } from "object-to-formdata";
import { notificator } from "@/services/notificator";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { api } from "@/services/api";

type NextFunction = () => void;

enum Method {
  post = "post",
  patch = "patch",
  put = "put",
  delete = "delete",
}

enum DataFormat {
  json = "json",
  formData = "formData"
}

export default defineComponent({
  name: "VForm",
  components: { LoadingLine },
  props: {
    url: {
      required: true,
      type: String
    },
    method: {
      required: true,
      type: String as PropType<Method>,
      validator: (value: string) => {
        return Object.values(Method).includes(value as Method);
      }
    },
    data: {
      required: true,
      type: Object as PropType<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      }>
    },
    httpClient: {
      default: null,
      type: [Object,Function] as PropType<AxiosInstance|null>
    },
    format: {
      type: String as PropType<DataFormat>,
      default: DataFormat.json,
      validator: (value: string) => {
        return Object.values(DataFormat).includes(value as DataFormat);
      }
    },
    beforeSubmit: {
      default: (next: NextFunction) => {
        next();
      },
      type: Function as PropType<(next: NextFunction) => void>
    },
    successHandler: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: (response: any) => {
        const message = response.data.message ?? "" as string;

        if (!message) {
          return;
        }
        notificator.notify({
          message: message,
          type: Type.success,
        });
      },
      type: Function as PropType<(response: any) => void>
    },
    errorHandler: {
      default: (error: AxiosError) => {
        error;
      },
      type: Function as PropType<(error: AxiosError) => void>
    },
    loadingMaskClass: {
      default: "bg-gray-50",
      type: String
    },
  },
  emits: ["error", "success"],
  data() {
    return {
      loading: false,
      errors: {} as {
        [key: string]: string[];
      }
    };
  },
  methods: {
    submit() {
      this.beforeSubmit(this.query);
    },
    query() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      let method = this.method as Method;
      let data = this.data;
      let headers = { "Content-Type": "application/json" };

      if (this.format == "formData") {

        method = Method.post;

        data = serialize(this.data, {
          nullsAsUndefineds: false,
          booleansAsIntegers: true,
          allowEmptyArrays: true
        });

        if (this.method !== Method.post) {
          data.append("_method", this.method);
        }

        headers = {
          "Content-Type": "multipart/form-data",
        };
      }

      let httpClient = api;

      if (this.httpClient) {
        httpClient = this.httpClient;
      }

      httpClient[method](this.url, data, { headers: headers })
        .then(async response => {

          this.errors = {};

          await this.successHandler(response);

          this.loading = false;

          this.$emit("success", response);
        })
        .catch((error: AxiosError<AxiosResponse<any>>) => {

          console.error(error);

          this.loading = false;

          if (error.response && error.response.status == 422) {
            this.loadErrors(error);
          }

          this.errorHandler(error);

          this.$emit("error", error);
        });
    },
    loadErrors(error: AxiosError): void {
      this.errors = error?.response?.data.errors ?? {};
    },
    clearErrors(name = null): void {
      if (name == null) {
        this.errors = {};
      }
      else {
        delete this.errors[name];
      }
    }
  },
});
</script>
