<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="p-8">
      <h2 class="mb-3 text-lg font-semibold leading-5 text-gray-900">
        {{ $t('page.profile.unblock_modal.title', {x: firstName}) }}
      </h2>

      <p class="mb-6 leading-snug text-gray-700">
        {{ $t('page.profile.unblock_modal.description') }}
      </p>

      <div>
        <VForm
          :data="form"
          method="delete"
          :url="`member-blocks/${memberBlockId}`"
          loading-mask-class="bg-white"
          @success="onSuccess"
        >
          <div class="xs:flex xs:space-x-3 xs:space-y-0 space-y-3">
            <button
              type="submit"
              class="btn btn-primary btn-lg xs:w-auto w-full"
            >
              {{ $t('unblock') }}
            </button>

            <button
              type="button"
              class="btn btn-lg xs:w-auto w-full"
              @click="close()"
            >
              {{ $t('cancel') }}
            </button>
          </div>
        </VForm>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    memberBlockId: {
      required: true,
      type: String
    },
    firstName: {
      required: true,
      type: String
    }
  },
  emits: ["update:modelValue", "unblocked"],
  data () {
    return {
      form: {}
    };
  },
  methods: {
    onSuccess () {
      this.close();
      this.$emit("unblocked");
    },
    close () {
      this.$emit("update:modelValue", false);
    }
  }
});
</script>
