import { Filters } from "@/views/member/search/models/Filters";
import { Sort } from "@/views/member/search/models/Sort";
import type { Answer } from "./Answer";
import { Gender, SexualOrientation } from "./User";

interface Account {
  id: string,
  first_name: string,
  last_name: string,
  full_name: string,
  email: string,
  age: number,
  phone: string | null,
  birthday: string,
  gender: Gender,
  sexual_orientations: SexualOrientation[],
  gender_i18n: string,
  locale: string,
  address_1: string | null,
  address_2: string | null,
  city: string | null,
  state: string | null,
  country: string | null,
  postal_code: string | null,
  eid_score: number | null,
  authentication_flag: "success" | "warning" | "fail" | null,
  article_access_level: "vip" | "member" | "public",
  eid_tries: number | null,
  has_taxes: boolean,
  gst: string | null,
  pst: string | null,
  nas: string | null,
  blocked: boolean,
  email_verified_at: string,
  accepted_terms_date: string,
  member: AccountMember | null,
  ambassador: AccountAmbassador | null,
  metadata: {
    first_search: boolean
  },
  settings: {
    filters?: Filters,
    sort?: Sort,
    anonymous_mode?: boolean,
    notifications_priority_message: boolean,
    notifications_secondary_message: boolean,
  },
  active_subscription?: {
    id: string,
    started_at: string,
    product_id: string,
    product?: {
      id: string,
      name: string,
      description: string,
      price: number,
      number_of_months: number,
    }
  }
}

interface AccountMember {
  id: string,
  avatar_path: string | null,
  title: string | null,
  about: string | null,
  seeking: string | null,
  paused: boolean,
  suspended: boolean,
  suspended_reason: string | null,
  visibility: Visibility,
  answers: Answer[],
  trial_end_date: string | null,
  subscription_end_date: string | null,
  subscription_status: SubscriptionStatus,
  onboarding_step: string,
  onboarding_completed: boolean,
}

interface AccountAmbassador {
  id: string,
  code: string,
  requires_nas: boolean,
  referral_link: string,
  objective: string | null,
}

enum Visibility {
  private = "private",
  public = "public",
}

enum SubscriptionStatus {
  active = "active",
  inactive = "inactive",
  trial = "trial",
}

export type { Account, AccountMember, AccountAmbassador };
export { Visibility, SubscriptionStatus };
