import { defineComponent } from "vue";
import Form from "../Form.vue";

export default defineComponent({
  props: {
    name: {
      required: true,
      type: String
    },
    placeholder: {
      default: "",
      type: String
    },
    label: {
      default: "",
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      default: false,
      type: Boolean
    },
    autofocus: {
      default: false,
      type: Boolean
    },
    inputClass: {
      default: "",
      type: String
    },
    preventSubmit: {
      default: false,
      type: Boolean,
    }
  },
  emits: ["update:modelValue"],
  computed: {
    form(): typeof Form | null {
      let parent = this.$parent;
      for (let i = 0; i < 10; i++) {
        if (parent == null) {
          throw new Error(`A v-input component must be in a ${Form.name} component`);
        }
        if (parent.$options.name == Form.name) {
          return parent as unknown as typeof Form;
        }
        parent = parent.$parent;
      }
      return null;
    },
    errors() {
      if (!this.form) {
        return {};
      }

      return this.form.errors;
    },
    labelValue(): string {
      if (this.label != "" &&
        this.label != null &&
        this.label != undefined) {
        return this.label;
      }

      if (this.label === "") {
        return "";
      }

      if (this.$te(this.name)) {
        return this.$t(this.name);
      }

      return this.name.capitalize();
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputListener(payload: any) {
      this.clearErrors();
      this.$emit("update:modelValue", payload);
    },
    errorMessage(name: string | null = null) {
      if (name == null) {
        name = this.name;
      }
      if (this.hasError(name)) {
        return this.errors[name][0];
      }
      return null;
    },
    hasError(name: string | null = null) {
      if (name == null) {
        name = this.name;
      }
      return Object.keys(this.errors).length > 0 &&
        Object.prototype.hasOwnProperty.call(this.errors, name) &&
        this.errors[name].length > 0;
    },
    clearErrors(name: string | null = null) {
      if (name == null) {
        name = this.name;
      }
      this.form?.clearErrors(name);
    }
  }
});
