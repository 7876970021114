<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />
    <div
      class="flex flex-wrap -m-1"
      :class="[inputClass]"
    >
      <div
        v-for="option in options"
        :key="option.value"
        class="p-1"
      >
        <button

          type="button"
          class="btn"
          :class="[buttonClass,{
            'btn-steel-outline': modelValue == option.value,
          }]"
          :disabled="disabled"
          @click="inputListener(transformInputValue(option))"
        >
          {{ option.label }}
        </button>
      </div>
    </div>
    <p
      v-if="hasError()"
      class="form-input-error mt-2"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";

interface Option {
  value: OptionValue,
  label: string
}

type OptionValue =  string|number;

export default defineComponent({
  name: "VInputRadioButton",
  components: { InputLabel },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [String,Number,null] as PropType<OptionValue|null>,
    },
    options: {
      required: true,
      type: Array as PropType<Option[]>
    },
    buttonClass: {
      default: "",
      type: String
    },
  },
  methods: {
    transformInputValue(option: Option|null): OptionValue|null {
      if (option == null || option.value == null || option.value == "") {
        return null;
      }

      if (!this.required && option.value == this.modelValue) {
        return null;
      }

      return option.value;
    }
  }
});
</script>
