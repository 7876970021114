<template>
  <div class="md:flex grow hidden overflow-x-hidden">
    <div class="flex w-full space-x-1 overflow-x-auto">
      <FilterItem
        v-if="filters.sexual_orientations && filters.sexual_orientations.length > 0"
        filter="age"
      >
        {{ $t('page.search.filters.genders.title') }}: {{ filters.sexual_orientations.map((g: any) => $t(g)).join(', ') }}
      </FilterItem>

      <FilterItem
        v-if="filters.age && filters.age.length == 2"
        filter="age"
      >
        {{ $t('age') }}: {{ filters.age[0] }} {{ $t('years_old') }} - {{ filters.age[1] }} {{ $t('years_old') }}
      </FilterItem>

      <FilterItem
        v-if="filters.distance"
        filter="distance"
        @remove="() => {
          $store.commit('unsetFilters', 'latitude');
          $store.commit('unsetFilters', 'longitude');
          $store.commit('unsetFilters', 'location_label');
        }"
      >
        {{ $t('distance') }}: {{ $display.distance(filters.distance) }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__objective && filters.question__objective.length > 0"
        filter="question__objective"
      >
        {{ $t('page.search.filters.objective.title') }}: {{ getOptionsLabel('objective') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__languages && filters.question__languages.length > 0"
        filter="question__languages"
      >
        {{ $t('page.search.filters.language.title') }}: {{ getOptionsLabel('languages') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__family_situation && filters.question__family_situation.length > 0"
        filter="question__family_situation"
      >
        {{ $t('page.search.filters.family_situation.title') }}: {{ getOptionsLabel('family_situation') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__kids_living_with && filters.question__kids_living_with.length"
        filter="question__kids_living_with"
      >
        {{ $t('page.search.filters.kids_living_with.title') }}: {{ getOptionsLabel('kids_living_with') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__want_to_have_children && filters.question__want_to_have_children.length"
        filter="question__want_to_have_children"
      >
        {{ $t('page.search.filters.wants_children.title') }}: {{ getOptionsLabel('want_to_have_children') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__education_level && filters.question__education_level.length"
        filter="question__education_level"
      >
        {{ $t('page.search.filters.education_level.title') }}: {{ getOptionsLabel('education_level') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__current_occupation && filters.question__current_occupation.length"
        filter="question__current_occupation"
      >
        {{ $t('page.search.filters.current_occupation.title') }}: {{ getOptionsLabel('current_occupation') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__alcohol_consumption_frequency && filters.question__alcohol_consumption_frequency.length"
        filter="question__alcohol_consumption_frequency"
      >
        {{ $t('page.search.filters.alcohol_consumption_frequency.title') }}: {{ getOptionsLabel('alcohol_consumption_frequency') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__smoking && filters.question__smoking.length"
        filter="question__smoking"
      >
        {{ $t('page.search.filters.smoking.title') }}: {{ getOptionsLabel('smoking') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__smoking_frequency && filters.question__smoking_frequency.length"
        filter="question__smoking_frequency"
      >
        {{ $t('page.search.filters.smoking_frequency.title') }}: {{ getOptionsLabel('smoking_frequency') }}
      </FilterItem>

      <FilterItem
        v-if="filters.smoking_tolerance_level && filters.smoking_tolerance_level"
        filter="smoking_tolerance_level"
      >
        {{ $t('page.search.filters.smoking_tolerance_level.title') }}: {{ $t('yes') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__food_preferences && filters.question__food_preferences.length"
        filter="question__food_preferences"
      >
        {{ $t('page.search.filters.food_preferences.title') }}: {{ getOptionsLabel('food_preferences') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__personality_traits && filters.question__personality_traits.length"
        filter="question__personality_traits"
      >
        {{ $t('page.search.filters.personality_traits.title') }}: {{ getOptionsLabel('personality_traits') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__values && filters.question__values.length"
        filter="question__values"
      >
        {{ $t('page.search.filters.values.title') }}: {{ getOptionsLabel('values') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__religious_beliefs && filters.question__religious_beliefs.length"
        filter="question__religious_beliefs"
      >
        {{ $t('page.search.filters.religious_belief.title') }}: {{ getOptionsLabel('religious_beliefs') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__religion && filters.question__religion.length"
        filter="question__religion"
      >
        {{ $t('religion') }}: {{ getOptionsLabel('religion') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__personal_development_importance && filters.question__personal_development_importance.length"
        filter="question__personal_development_importance"
      >
        {{ $t('page.search.filters.personal_development_importance.title') }}: {{ getOptionsLabel('personal_development_importance') }}
      </FilterItem>

      <FilterItem
        v-if="filters.accepts_persons_with_limitations && filters.accepts_persons_with_limitations"
        filter="accepts_persons_with_limitations"
      >
        {{ $t('page.search.filters.accepts_persons_with_limitations.title') }}: {{ $t('yes') }}
      </FilterItem>

      <!-- Legacy -->
      <FilterItem
        v-if="filters.question__accepts_persons_with_limitations && filters.question__accepts_persons_with_limitations.length"
        filter="question__accepts_persons_with_limitations"
      >
        {{ $t('page.search.filters.accepts_persons_with_limitations.title') }}: {{ getOptionsLabel('accepts_persons_with_limitations') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__areas_of_interest && filters.question__areas_of_interest.length"
        filter="question__areas_of_interest"
      >
        {{ $t('page.search.filters.areas_of_interest.title') }}: {{ getOptionsLabel('areas_of_interest') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__height && filters.question__height.min && filters.question__height.max"
        class="pr-3"
        filter="question__height"
      >
        {{ $t('page.search.filters.height.title') }}: {{ $display.height(filters.question__height.min) }} - {{ $display.height(filters.question__height.max) }}
      </FilterItem>

      <FilterItem
        v-if="filters.keywords && filters.keywords.length"
        filter="keywords"
      >
        {{ $t('page.search.filters.keyword_research.title') }}: {{ filters.keywords.join(', ') }}
      </FilterItem>

      <FilterItem
        v-if="filters.question__silhouette && filters.question__silhouette.length"
        filter="question__silhouette"
      >
        {{ $t('page.search.filters.silhouette.title') }}: {{ getOptionsLabel('silhouette') }}
      </FilterItem>
    </div>
  </div>
</template>

<script lang="ts">

import { QuestionOption } from "@/models/QuestionOption";
import { get } from "lodash-es";
import { defineComponent } from "vue";
import { Filters } from "../../models/Filters";
import FilterItem from "./FilterItem.vue";

export default defineComponent({
  components: { FilterItem },
  computed: {
    filters () : Filters {
      return this.$store.getters.filters;
    }
  },
  methods: {
    getOptionsLabel(questionName: string) : string|null {
      const options = get(this.$store.getters.filters, "question__" + questionName) as string[];

      if (!options) {
        return null;
      }

      if (options.length == 0) {
        return null;
      }

      return options
        .map((o: string) => this.getOptionLabel(questionName, o))
        .join(", ");
    },
    getOptionLabel(questionName: string, value: string) : string {
      const option  = this.$store.getters["question/option"](questionName, value) as QuestionOption|null;

      if (option) {
        return option.label;
      }

      return value;
    },
  }
});
</script>

