<template>
  <div>
    <BaseSkeleton class="aspect-w-1 aspect-h-1 w-full mb-3" />
    <BaseSkeleton class="w-full h-8" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
