<template>
  <component
    :is="componentName"
    ref="button"
    :to="{name: link.route}"
    class="flex cursor-pointer"
  >
    <div
      class="relative h-full"
    >
      <div
        class="group inline-flex items-center h-full px-1 pt-1 text-sm font-medium border-b-2"
        :class="{
          'text-primary-700 border-primary-500': linkIsActive,
          'text-gray-600 border-transparent hover:border-primary-500': !linkIsActive
        }"
        @click="onClick"
      >
        <div class="hidden lg:block mr-1.5">
          <div
            v-show="!linkIsActive"
          >
            <component
              :is="link.iconInactive"
              class="w-6 h-6 text-gray-500"
            />
          </div>
          <div
            v-show="linkIsActive"
          >
            <component
              :is="link.iconActive"
              class="text-primary-600 w-6 h-6"
            />
          </div>
        </div>
        <div class="flex items-center space-x-2 leading-none">
          <div class="text-base font-medium leading-none">
            {{ $t(link.title) }}
          </div>
          <div
            v-if="link.count && link.count()"
            class="rounded-xl shrink-0 px-1.5 py-px text-xs font-semibold text-white bg-red-600"
          >
            {{ link.count() }}
          </div>
          <div v-if="link.subnav">
            <Icon
              icon="heroicons-solid:chevron-down"
              class="w-5 h-5"
            />
          </div>
        </div>
      </div>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-form-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <div
          v-if="link.subnav"
          v-show="active"
          class="left-1/2 sm:px-0 absolute z-10 w-screen max-w-md px-2 mt-3 transform -translate-x-1/2"
        >
          <div class="ring-1 ring-black ring-opacity-5 overflow-hidden rounded-lg shadow-lg">
            <div class="sm:gap-8 sm:p-8 relative grid gap-6 px-5 py-6 bg-white">
              <template
                v-for="subnav in link.subnav"
                :key="subnav.title"
              >
                <component
                  :is="subnav.route ? 'router-link' : 'a'"
                  :to="subnav.route"
                  :href="subnav.href"
                  class="hover:bg-gray-50 flex items-start p-3 -m-3 text-left transition duration-150 ease-in-out rounded-lg"
                  @click="onSubNavClick(subnav)"
                >
                  <Icon
                    class="text-primary-600 flex-shrink-0 w-6 h-6"
                    :icon="subnav.icon"
                  />
                  <div class="ml-4">
                    <p class="text-base font-semibold text-gray-900">
                      {{ $t(subnav.title) }}
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      {{ $t(subnav.subtitle) }}
                    </p>
                  </div>
                </component>
              </template>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Link } from "./models/Link";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  props: {
    link: {
      required: true,
      type: Object as PropType<Link>
    }
  },
  data () {
    return {
      active: false,
    };
  },
  computed: {
    componentName() : string {
      if (this.link.subnav) {
        return "button";
      }
      return "router-link";
    },
    linkIsActive() : boolean {
      if (this.link.exact) {
        return this.$route.name == this.link.route;
      }

      if (this.$route.name?.toString() && this.$route.name.toString().startsWith(this.link.route)) {
        return true;
      }
      return false;
    }
  },
  mounted () {
    onClickOutside(this.$refs.button as HTMLElement, () => {
      this.active = false;
    });
  },
  methods: {
    onClick(e: MouseEvent) {
      if (this.link.subnav && !this.active) {
        e.preventDefault();
        this.active = true;
      }
    },
    onSubNavClick (subnav: any) {
      this.active = false;
    }
  }
});
</script>
