import { createStore } from "vuex";
import { Account } from "@/models/Account";
import { api, api_, http } from "@/services/api";
import { cloneDeep, isPlainObject, keyBy } from "lodash-es";
import { Filters } from "@/views/member/search/models/Filters";
import { Sort } from "@/views/member/search/models/Sort";
import { auth, AuthStrategy } from "@/services/auth";
import i18n from "@/plugins/i18n";
import * as Sentry from "@sentry/vue";

import { AuthState, defaultAuthState, authModule } from "./modules/auth";
import { defaultNotificationState, notificationModule, NotificationState } from "./modules/notification";
import { defaultQuestionState, questionModule, QuestionState } from "./modules/questions";
import { conversationModule, ConversationState, defaultConversationState } from "./modules/conversation";
import { statusModule, defaultStatusState, StatusState } from "./modules/status";
import { dailyQuoteModule, DailyQuoteState, defaultDailyQuoteState } from "./modules/dailyQuote";
import { countriesModule, CountriesState, defaultCountriesState } from "./modules/countries";
import { defaultProductState, productModule, ProductState } from "./modules/product";
import { Answer } from "@/models/Answer";
import { createDeviceReference, getDeviceReference } from "@/services/deviceReference";
import { getRoute } from "@/services/utils";
import { Term } from "@/models/Term";

export type AnswerList = { [question_id: string]: Answer }

export interface State {
  initialized: boolean,
  user: Account | null,
  term: Term | null,
  ambassadorCodeReferral: string | null,
  sentAmbassadorCodes: string[],
  questions: QuestionState,
  auth: AuthState,
  notification: NotificationState,
  conversation: ConversationState,
  status: StatusState,
  dailyQuote: DailyQuoteState,
  countries: CountriesState,
  product: ProductState,
}

export const store = createStore<State>({
  state() {
    return {
      initialized: false,
      user: null,
      term: null,
      ambassadorCodeReferral: null,
      sentAmbassadorCodes: [],
      questions: defaultQuestionState,
      auth: defaultAuthState,
      notification: defaultNotificationState,
      conversation: defaultConversationState,
      status: defaultStatusState,
      dailyQuote: defaultDailyQuoteState,
      countries: defaultCountriesState,
      product: defaultProductState,
    };
  },
  getters: {
    anonymousMode(state: State): boolean {
      if (!state.user) {
        return false;
      }

      return state.user.settings.anonymous_mode ?? false;
    },
    filters(state: State): Filters {
      if (!state.user) {
        return {};
      }

      return state.user.settings.filters ?? {};
    },
    sort(state: State): Sort {
      if (!state.user) {
        return Sort.proximity;
      }

      return state.user.settings.sort ?? Sort.proximity;
    },
    answers(state: State): AnswerList {
      return keyBy((state.user?.member?.answers ?? []) as Answer[], "question_id");
    },
  },
  mutations: {
    initialized(state: State, value: boolean) {
      state.initialized = value;
    },
    ambassadorCodeReferral(state: State, ambassadorCodeReferral: string | null) {
      state.ambassadorCodeReferral = ambassadorCodeReferral;
    },
    sentAmbassadorCodesPush(state: State, code: string) {
      state.sentAmbassadorCodes.push(code);
    },
    setUser(state: State, user: Account | null) {
      if (user && !isPlainObject(user?.settings.filters)) {
        user.settings.filters = {};
      }

      state.user = user;

      Sentry.setUser(user);

      if (user) {
        i18n.global.locale = user.locale as typeof i18n.global.locale;
      }
    },
    setTerm(state: State, term: Term | null) {
      state.term = term;
    },
    setUserMemberAnswers(state: State, answers: Answer[]) {
      if (state.user?.member) {
        state.user.member.answers = answers;
      }
    },
    setAnonymousMode(state: State, value: boolean) {
      if (state.user) {
        state.user.settings.anonymous_mode = value;
      }
    },
    setFilters(state: State, filters: Filters) {
      if (state.user) {
        state.user.settings.filters = cloneDeep(filters);
      }
    },
    resetFilters(state: State) {
      if (state.user) {
        state.user.settings.filters = {};
      }
    },
    unsetFilters(state: State, key: keyof Filters) {
      if (state.user && state.user.settings.filters) {
        delete state.user.settings.filters[key as keyof Filters];
      }
    },
    setSort(state: State, value: Sort) {
      if (state.user) {
        state.user.settings.sort = value;
      }
    },
  },
  actions: {

    /**
     * This action is always called on first load,
     * wether the user is authenticated or not
     *
     * It initialized the auth module from the local storage.
     * If the user is logged in, it then tries to fetch the user.
     */
    async init({ commit, dispatch }): Promise<void> {

      console.log("init...");

      /**
       * Create or Get unique device reference
       * in local storage (web) or storage (native)
       */
      await createDeviceReference();

      /**
       * Fetch terms
       */
      await dispatch("fetchTerm");

      /**
      * To authenticate the SPA using the session strategy, the app should first make a request to the
      * /sanctum/csrf-cookie endpoint to initialize CSRF protection for the application.
      */
      if (auth.strategy == AuthStrategy.session) {
        await http.get(getRoute("sanctum/csrf-cookie"));
      }

      console.log("auth init...");

      await auth.init();

      console.log("auth init success", auth.check());

      if (auth.check()) {
        try {
          await this.dispatch("fetchUser", false);
        } catch (e: any) {
          console.error(e);
          // If the request fails, set initialized to true and logout
          await auth.setLoggedOut(false);
        }
      }

      commit("initialized", true);
    },

    /**
     * This action fetch the user. It can get called many times.
     * Since it uses the api service, if a 401 or 419 status code is returned,
     * the user will be redirected the the login page
     */
    fetchUser({ commit }, redirectToLogin = true): Promise<Account | null> {

      let httpClient = api;

      if (!redirectToLogin) {
        httpClient = api_;
      }

      console.log("Fetching user (redirect to login: " + redirectToLogin + ")");

      return new Promise((resolve, reject) => {
        httpClient.get("account", {
          headers: {
            "Accept-Language": "",
          }
        })
          .then(response => {
            const user = response.data.data as Account;
            commit("setUser", user);
            commit("status/set", user);
            resolve(null);
          })
          .catch((error: Error) => {
            console.error("Could no fetch user");
            console.error(error);
            commit("setUser", null);
            reject(error);
          });
      });
    },

    fetchTerm({ commit }): Promise<Term | null> {
      return new Promise((resolve, reject) => {
        api.get(getRoute("/api/content/terms"))
          .then(response => {
            commit("setTerm", response.data.data);
            resolve(response.data.data);
          })
          .catch((error: Error) => {
            console.error(error);
            commit("setUser", null);
            reject(error);
          });
      });
    },

    async saveReferral({ state, commit }): Promise<void> {

      if (!state.ambassadorCodeReferral) {
        return;
      }

      const code = state.ambassadorCodeReferral;

      if (state.sentAmbassadorCodes.includes(code)) {
        return;
      }

      commit("sentAmbassadorCodesPush", code);

      try {
        await api.post("referral", {
          device_ref: await getDeviceReference(),
          ambassador_code: code,
        });
      } catch (error: any) {
        console.error(error);
      }

      commit("ambassadorCodeReferral", null);
    },

    async updateLanguage({ state, commit, dispatch }, locale: typeof i18n.global.locale) {

      // Language must bet changed before making API calls

      if (state.user) {
        await api.patch("account/language", {
          locale: locale
        });

        const user = cloneDeep(state.user);
        user.locale = locale;

        commit("setUser", user);
      }

      i18n.global.locale = locale;

      // Refresh models in state with new language

      const requests = [
        dispatch("fetchTerm"),
        dispatch("dailyQuote/fetch"),
      ];

      if (state.user) {
        requests.push(dispatch("fetchUser"));
        requests.push(dispatch("question/fetch"));
      }

      await Promise.all(requests);
    },

    updateSearchSettings({ getters }) {
      api.patch("account/settings/search", {
        filters: getters.filters,
        sort: getters.sort,
      });
    },

    updateAnonymousMode({ getters, commit }, value: boolean) {
      commit("setAnonymousMode", value);
      api.patch("account/settings/anonymous-mode", {
        anonymous_mode: getters.anonymousMode,
      });
    },
  },
  modules: {
    auth: authModule,
    notification: notificationModule,
    question: questionModule,
    conversation: conversationModule,
    status: statusModule,
    dailyQuote: dailyQuoteModule,
    countries: countriesModule,
    product: productModule,
  }
});
