<template>
  <div class="z-menu fixed top-0 left-0 w-full">
    <StatusBar
      v-if="$store.state.status.status"
      class="w-full"
    />
    <div
      v-else
      class="pt-safe w-full bg-white"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StatusBar from "./StatusBar.vue";

export default defineComponent({
  components: { StatusBar }
});
</script>
