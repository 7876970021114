<template>
  <div
    v-if="max && (!min || text.length >= min)"
    class="text-sm"
    :class="{
      'text-gray-500': text.length <= max,
      'text-red-600': text.length > max,
    }"
  >
    {{ text.length }}/{{ max }}
  </div>
  <div
    v-if="min && text.length < min"
    class="text-sm text-red-600"
  >
    {{ $t('min_x_characters', {x: min}) }} ({{ text.length }}/{{ min }})
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      required: true,
      type: String
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    }
  }
});
</script>
