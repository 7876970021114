<template>
  <OnboardingLayout>
    <div v-if="ready">
      <div v-if="currentStepIndex !== null && currentStep && currentComponent">
        <div class="w-full h-1.5 mb-8 rounded-full bg-gray-200 overflow-hidden">
          <div
            class="bg-primary-600 h-full transition-all duration-75"
            :style="{width: progress + '%'}"
          />
        </div>

        <component
          :is="currentComponent"
          :key="currentStep.path"
        />
      </div>
    </div>
  </OnboardingLayout>
</template>

<script lang="ts">
/* eslint-disable vue/no-unused-components */
import { computed, defineComponent, provide, Ref, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Step, StepComponent, Steps } from "./services/steps";
import StepGender from "./steps/StepGender.vue";
import StepQuestion from "./steps/StepQuestion.vue";
import StepSexualOrientation from "./steps/StepSexualOrientation.vue";
import StepIntroductionVideo from "./steps/StepIntroductionVideo.vue";
import StepIntroduction from "./steps/StepIntroduction.vue";
import StepShortQuestions from "./steps/StepShortQuestions.vue";
import StepLongQuestions from "./steps/StepLongQuestions.vue";
import StepAudioIntroduction from "./steps/StepAudioIntroduction.vue";
import StepAuthentication from "./steps/StepAuthentication.vue";
import StepSamplePicture from "./steps/StepSamplePicture.vue";
import StepPicturesVideo from "./steps/StepPicturesVideo.vue";
import StepPictures from "./steps/StepPictures.vue";
import StepPayment from "./steps/StepPayment.vue";
import { api } from "@/services/api";
import OnboardingLayout from "./OnboardingLayout.vue";

export default defineComponent({
  components: {
    StepGender,
    StepQuestion,
    StepSexualOrientation,
    StepIntroductionVideo,
    StepIntroduction,
    StepShortQuestions,
    StepLongQuestions,
    StepAudioIntroduction,
    StepAuthentication,
    StepSamplePicture,
    StepPicturesVideo,
    StepPictures,
    StepPayment,
    OnboardingLayout
  },
  setup () {
    const router = useRouter();
    const route = useRoute();
    const ready = ref(false);
    const stepService = new Steps;
    const steps = ref([]) as Ref<Step[]>;

    stepService.init().then(() => {
      steps.value = stepService.steps;
      ready.value = true;
    });

    const currentStepIndex = computed(() : number|null => {
      const index = steps.value.findIndex(s => s.path == route.params.step);

      if (index !== -1) {
        return index;
      }

      return null;
    });

    const progress = computed(() : number => {
      if (currentStepIndex.value === null) {
        return 0;
      }
      return 100 * (currentStepIndex.value + 0.5) / steps.value.length;
    });

    const currentStep = computed(() : Step|null => {

      if (currentStepIndex.value === null) {
        return null;
      }

      const step = steps.value[currentStepIndex.value];

      if (step !== null) {
        return step;
      }

      return null;
    });

    const currentComponent = computed(() : StepComponent|null => {
      if (currentStep.value) {
        return currentStep.value.component;
      }
      return null;
    });

    const previousStep = () : void => {
      if (currentStepIndex.value === null) {
        return;
      }

      if (currentStepIndex.value === 0) {
        return;
      }

      const newIndex = currentStepIndex.value - 1;

      const newStep = steps.value[newIndex];

      router.push(`/onboarding/step/${newStep.path}`);
    };

    const nextStep = () : void => {

      if (currentStepIndex.value === null) {
        return;
      }

      if (currentStepIndex.value >= steps.value.length - 1) {
        router.push("/onboarding/success");
        return;
      }

      const newIndex = currentStepIndex.value + 1;

      const newStep = steps.value[newIndex];

      try {
        api.patch("onboarding/step", {
          step: newStep.path
        });
      } catch (e: any) {
        console.error(e);
      }

      router.push(`/onboarding/step/${newStep.path}`);
    };

    provide("nextStep", nextStep);
    provide("previousStep", previousStep);
    provide("currentStep", currentStep);

    return {
      ready,
      steps,
      currentStepIndex,
      currentStep,
      currentComponent,
      progress,
      previousStep,
      nextStep,
    };
  }
});
</script>
