<template>
  <router-link
    :to="`/member/messages/${conversation.id}`"
    class="focus:outline-none group block mb-1 overflow-hidden text-left rounded-md outline-none appearance-none"
    :class="{
      'bg-white hover:bg-gray-100': !selected,
      'bg-gray-100': selected,
    }"
    @click="$emit('click', conversation)"
  >
    <div
      class="flex px-2 py-3"
    >
      <div class="w-[76px] relative shrink-0 mr-2">
        <div class="flex h-full">
          <div
            v-if="conversation.flower_match"
            class="-top-1 relative flex -space-x-6"
          >
            <div
              class="shrink-0 self-end object-cover object-center w-12 h-12 overflow-hidden border-2 rounded-full shadow-lg"
              :class="{
                'border-white': !selected,
                'border-gray-100': selected,
              }"
            >
              <MemberAvatar
                :member="me?.member"
                :size="100"
              />
            </div>
            <div
              class="shrink-0 relative self-start object-cover object-center w-12 h-12 overflow-hidden border-2 rounded-full shadow-lg"
              :class="{
                'border-white': !selected,
                'border-gray-100': selected,
              }"
            >
              <MemberAvatar
                :member="user?.member"
                :size="100"
              />
            </div>
          </div>

          <div
            v-else
            class="relative flex -space-x-4"
          >
            <div
              class="w-14 h-14 shrink-0 self-stretch object-cover overflow-hidden rounded-full"
              :class="{
                'border-white': !selected,
                'border-gray-100': selected,
              }"
            >
              <MemberAvatar
                :member="user?.member"
                :size="100"
              />
            </div>

            <div
              v-if="oneFlowerSent"
              class="shrink-0 relative self-end w-8 h-8"
              :class="{
                'border-white group-hover:border-gray-100': !selected,
                'border-gray-100': selected,
              }"
            >
              <FlowerIllustration
                size="small"
                :gender="flowerGender"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="grow relative mr-3">
        <div class="absolute top-0 right-0">
          <span class="text-sm text-gray-500">{{ lastActivity }}</span>
        </div>
        <h3
          v-if="user"
          class="pr-14 block mb-1 text-lg font-semibold leading-tight text-gray-900"
        >
          {{ user.first_name }}
        </h3>
        <div class="h-9 block overflow-hidden text-sm leading-tight">
          <p :class="{'text-gray-900 font-medium': !seen, 'text-gray-500': seen}">
            <span v-if="conversation.last_message && conversation.last_message.content">
              {{ conversation.last_message.content_text }}
            </span>
            <span v-else-if="!(me?.pivot.sent_flower) && user?.pivot.sent_flower">
              {{ $t('chat.received_flower.title', {user: user?.first_name}) }}
            </span>
            <span v-else-if="me?.pivot.sent_flower && !(user?.pivot.sent_flower)">
              {{ $t('chat.you_sent_a_flower.title') }}
            </span>
            <span v-else-if="me?.pivot.sent_flower && user?.pivot.sent_flower">
              {{ $t('flower_match_tagline') }}
            </span>
          </p>
        </div>
      </div>

      <div class="shrink-0 relative w-4">
        <div
          v-if="!seen"
          class="pt-1.5"
        >
          <div class="w-3 h-3 bg-red-600 rounded-full" />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Conversation, ConversationUser } from "@/models/Conversation";
import FlowerIllustration from "@/plugins/icons/FlowerIllustration.vue";
import humanizeDuration from "humanize-duration";
import MemberAvatar from "../../profile/components/sections/components/MemberAvatar.vue";

export default defineComponent({
  components: {
    FlowerIllustration,
    MemberAvatar
  },
  props: {
    conversation: {
      required: true,
      type: Object as PropType<Conversation>
    },
    selected: {
      default: false,
      type: Boolean
    }
  },
  emits: ["click"],
  data () {
    return {
      now: this.$luxon.now(),
      timeoutId: 0 as any
    };
  },
  computed: {
    seen() {
      return this.$store.getters["conversation/seen"](this.conversation.id);
    },
    me(): ConversationUser | null {
      return this.conversation.users.find(u => u.is_me) ?? null;
    },
    user(): ConversationUser | null {
      return this.conversation.users.find(u => !u.is_me) ?? null;
    },
    oneFlowerSent(): boolean {
      const numberOfFlowers = this.conversation.users.filter(u => u.pivot.sent_flower).length;
      return numberOfFlowers < this.conversation.users.length && numberOfFlowers > 0;
    },
    flowerGender () : string {
      if (!this.oneFlowerSent) {
        return "";
      }

      const usersWhoSentFlowers = this.conversation.users.filter(u => u.pivot.sent_flower);
      const lastUserWhoSentFlower = usersWhoSentFlowers[usersWhoSentFlowers.length - 1];
      return lastUserWhoSentFlower.gender;
    },
    lastActivity() : string {
      if (!this.conversation.last_message) {
        return "";
      }
      const diff = this.now.diff(this.$luxon.fromISO(this.conversation.last_message.created_at), "seconds").toMillis();

      if (diff < 60 * 1000) {
        return this.$t("just_now");
      }
      return humanizeDuration(diff, { language: this.$i18n.locale, largest: 1, maxDecimalPoints: 0 });
    }
  },
  created () {
    this.timeoutId = setInterval(() => {
      this.now = this.$luxon.now();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timeoutId);
  }
});
</script>
