<template>
  <DailyQuoteFooter />
  <div class="py-10 bg-white border-t border-gray-300">
    <BaseContainer>
      <nav
        class="flex flex-wrap justify-center -mx-5 -my-2"
        aria-label="Footer"
      >
        <div class="px-5 py-2">
          <a
            :href="$websiteUrl('/')"
            target="_blank"
            class="hover:text-gray-900 text-base text-gray-500"
          >
            Effet&nbsp;Tandem
          </a>
        </div>
        <div class="px-5 py-2">
          <a
            :href="$websiteUrl('contact')"
            target="_blank"
            class="hover:text-gray-900 text-base text-gray-500"
          >
            {{ $t('contact_us') }}
          </a>
        </div>
      </nav>
      <p class="mt-8 text-base text-center text-gray-400">
        &copy; {{ $luxon.now().year }} Effet&nbsp;Tandem, {{ $t('all_rights_reserved') }}
      </p>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    //
  }
});
</script>
