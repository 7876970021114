<template>
  <img :src="$assetUrl('placeholders/avatar.png', {resize: {height: size, width: size}})">
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    size: {
      required: true,
      type: Number
    }
  }
});
</script>
