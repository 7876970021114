<template>
  <div class="w-full h-full px-2 py-2 space-y-2 bg-white">
    <div
      v-for="i in 20"
      :key="i"
    >
      <BaseSkeleton class="w-full h-24" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
});
</script>
