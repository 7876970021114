<template>
  <div class="border-b border-gray-200">
    <button
      class="flex w-full py-6 bg-white border-none outline-none appearance-none"
      @click="opened = !opened"
    >
      <div class="grow">
        <div class="flex items-center space-x-2">
          <h3 class="text-lg font-semibold leading-tight text-left text-gray-900">
            {{ title }}
          </h3>
          <div
            v-if="active"
            class="shrink-0 w-2 h-2 bg-green-500 rounded-full"
          />
        </div>
      </div>
      <div class="shrink-0">
        <div
          class="transition-transform bg-transparent border-none outline-none appearance-none"
          :class="{
            'rotate-180': opened,
          }"
        >
          <Icon
            icon="heroicons-solid:chevron-down"
            class="w-5 h-5 text-gray-700"
          />
        </div>
      </div>
    </button>

    <div
      v-if="opened"
      class="pb-6"
    >
      <p
        v-if="subtitle"
        class="mb-6 leading-tight text-gray-500"
      >
        {{ subtitle }}
      </p>

      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { uuid } from "@/services/uuid";
import { defineComponent, inject, onBeforeUnmount, Ref, ref } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      default: "",
      type: String
    },
    active: {
      default: false,
      type: Boolean
    }
  },
  setup (props) {

    const opened = ref(props.active) as Ref<boolean>;

    const listenerId = uuid();

    const resetEventListeners = inject("resetEventListeners") as {[id: string]: {(): void}};
    resetEventListeners[listenerId] = () => {
      opened.value = false;
    };

    onBeforeUnmount(() => {
      delete resetEventListeners[listenerId];
    });

    return {
      opened
    };
  },
});
</script>
