<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />
    <div :class="inputWrapperClass">
      <input
        ref="input"
        :value="modelValue"
        :type="type"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :required="required"
        :autofocus="autofocus"
        class="rounded"
        :class="[{
          'bg-gray-100 cursor-not-allowed text-gray-500': disabled,
          'border-red-600': hasError(),
          'border-gray-300': !hasError(),
        }, inputClass]"
        :autocomplete="autocomplete ? 'on' : 'off'"
        @keydown.enter="onEnter"
        @input="inputListener(transformInputValue($event))"
      >
    </div>

    <div class="sm:space-x-3 flex flex-wrap mt-1">
      <p
        v-if="hasError()"
        class="form-input-error grow sm:order-1 order-2"
      >
        {{ errorMessage() }}
      </p>
      <div class="grow sm:w-auto sm:order-2 sm:text-right order-1 w-full text-left">
        <WordCount
          :text="normalizedModelValue"
          :min="min"
          :max="max"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { get, isString, trim } from "lodash-es";
import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";
import WordCount from "../components/utils/WordCount.vue";

export default defineComponent({
  name: "VInput",
  components: { InputLabel, WordCount },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [String,null] as PropType<string|null>,
    },
    type: {
      type: String,
      default: "text"
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    autocomplete: {
      default: true,
      type: Boolean
    },
    inputWrapperClass: {
      default: "",
      type: String
    }
  },
  computed: {
    normalizedModelValue() : string {
      if (isString(this.modelValue)) {
        return this.modelValue;
      }

      return "";
    }
  },
  methods: {
    transformInputValue(event: Event | null): string | null {
      if (event === null) {
        return null;
      }
      const value = get(event, "target.value", null);
      if (!isString(value)) {
        return null;
      }
      return trim(value as string);
    },
    onEnter(e: Event) {
      if (this.preventSubmit) {
        e.preventDefault();
        return;
      }
    }
  }
});
</script>
