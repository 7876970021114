export const USER_BLACKLIST_ERROR = 600;

export const EID_API_ERROR = 800;
export const EID_BLACKLIST_ERROR = 801;
export const EID_MAX_TRY_ERROR = 802;
export const EID_NO_MATCH_ERROR = 803;
export const EID_SERVER_ERROR = 804;
export const EID_WRONG_BIRTHDAY = 805;
export const EID_SAME_NAME_AND_BIRTHDAY = 806;

export const UNSUBSCRIBED = "UNSUBSCRIBED";
export const SUSPENDED = "SUSPENDED";
