<template>
  <div class="sm:py-20 relative w-full min-h-screen text-center">
    <img
      src="@/assets/img/empty-states/pexels-cats-coming-674580.jpg"
      alt="Tree Tandem"
      class="sm:block absolute inset-0 hidden object-cover object-center w-full h-full"
    >

    <div class="sm:block opacity-20 absolute inset-0 hidden w-full h-full bg-gray-900" />

    <BaseContainer
      size="2xl"
      paddingless-mobile
    >
      <div class="sm:p-10 sm:rounded-lg sm:shadow-lg bg-opacity-80 relative px-6 py-10 bg-white">
        <h1 class="font-display mb-6 text-4xl font-semibold">
          {{ $t('state_paused.title') }}
        </h1>

        <p class="mb-2 leading-normal text-gray-600">
          {{ $t('state_paused.message') }}
        </p>

        <p class="mb-8 leading-normal text-gray-600">
          {{ $t('state_paused.message_2') }}
        </p>

        <p class="mb-8 leading-normal text-gray-600">
          {{ $t('state_paused.message_3') }}
        </p>

        <div class="sm:flex-row sm:space-x-4 sm:space-y-0 flex flex-col justify-center space-y-3">
          <router-link
            to="/member/personal-development"
            class="btn btn-white-outline btn-xl"
          >
            {{ $t('blossom') }}
          </router-link>
          <router-link
            to="/member/settings/pause"
            class="btn btn-primary btn-xl"
          >
            {{ $t('state_paused.button') }}
          </router-link>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
