<template>
  <slot
    name="header"
    :question="question"
  />

  <div v-if="asString">
    <p class="leading-tight text-gray-600 whitespace-pre-wrap">
      {{ asString }}
    </p>
  </div>
  <div v-else-if="asSize">
    <p class="leading-tight text-gray-600 whitespace-pre-wrap">
      {{ $display.height(asSize) }}
    </p>
  </div>
  <div v-else-if="asOptions && asOptions.length">
    <div class="flex flex-wrap -m-0.5 leading-normal text-gray-600">
      <span
        v-for="option in asOptions"
        :key="option.id"
        class="p-0.5"
      >
        <div class="px-2 py-px whitespace-pre border border-gray-200 rounded">
          <Icon
            v-if="option.properties.iconify"
            class="text-steel-700 inline mr-2"
            :icon="option.properties.iconify"
          />
          <span>{{ option.label }}</span>
        </div>
      </span>
    </div>
  </div>
  <div
    v-else
    class="text-gray-400"
  >
    —
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import { Question, QuestionType } from "@/models/Question";
import { QuestionOption } from "@/models/QuestionOption";
import { Answer } from "@/models/Answer";

export default defineComponent({
  props: {
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    answer: {
      required: true,
      type: Object as PropType<Answer>
    },
  },
  computed: {
    asString () : string|false {
      if ([QuestionType.input, QuestionType.textarea].includes(this.question.type.input_type) && this.answer.value) {
        return this.answer.value as string;
      }
      return false;
    },
    asSize () : number|false {
      if (this.question.type.input_type == QuestionType.size && this.answer.value) {
        return (+this.answer.value) as number;
      }
      return false;
    },
    asOptions () : QuestionOption[]|false {
      if ([QuestionType.select, QuestionType.multi_select].includes(this.question.type.input_type) && this.answer.options.length) {
        return this.answer.options as QuestionOption[];
      }
      return false;
    },
  }
});
</script>
