<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="xs:px-8 rounded-2xl relative px-6 py-8 overflow-hidden">
      <div class="flex items-start justify-between">
        <h2 class="mb-3 text-lg font-semibold leading-5 text-gray-900">
          {{ $t('page.profile.share_member_modal.title', {x: firstName}) }}
        </h2>

        <div class="shrink-0 -top-2 relative pl-2">
          <button
            type="button"
            class="hover:bg-gray-100 hover:text-gray-900 transition-color inline-flex items-center justify-center text-gray-700 duration-150 bg-transparent border-none rounded-full appearance-none"
            @click="close"
          >
            <Icon
              class="w-8 h-8"
              icon="mdi:close"
            />
          </button>
        </div>
      </div>

      <BaseAlert
        v-if="error"
        type="danger"
      >
        <p>{{ error }}</p>
      </BaseAlert>

      <template v-else>
        <p class="mb-6 leading-snug text-gray-700">
          {{ $t('page.profile.share_member_modal.description') }}
        </p>

        <BaseClipboard
          v-if="url"
          :value="url"
        />
      </template>

      <BaseLoadingCover v-model="loading" />
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { get } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    memberId: {
      required: true,
      type: String
    },
    firstName: {
      required: true,
      type: String
    }
  },
  emits: ["update:modelValue"],
  data () {
    return {
      loading: false,
      url: "",
      error: "",
    };
  },
  watch: {
    modelValue () {
      if (this.modelValue) {
        this.getLink();
      }
    }
  },
  methods: {
    getLink () {

      if (this.loading) {
        return;
      }

      this.loading = true;
      this.url = "";
      this.error = "";

      this.$api_.post("members/" + this.memberId + "/links")
        .then(response => {
          this.url = response.data.member_shareable_link.url;
        })
        .catch(error => {
          this.error = this.$t("page.profile.share_member_modal.error");

          const errorMessage = get(error, "response.data.message");

          if (errorMessage) {
            this.error = errorMessage;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close () {
      this.$emit("update:modelValue", false);
    }
  }
});
</script>
