<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />
    <select
      :value="modelValue"
      :name="name"
      :disabled="disabled"
      :required="required"
      class="rounded"
      :class="[{
        'border-red-600': hasError(),
        'border-gray-300': !hasError(),
      }, inputClass]"
      @change="inputListener(transformInputValue($event))"
    >
      <option
        v-if="required"
        selected
        disabled
        hidden
        value=""
      >
        {{ placeholder ? placeholder : $t('select_an_option') }}
      </option>
      <slot />
      <option
        v-if="!required"
        value=""
      >
        {{ placeholder ? placeholder : $t('i_prefer_not_to_answer') }}
      </option>
    </select>
    <p
      v-if="hasError()"
      class="form-input-error mt-1"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";
import { get } from "lodash-es";

export default defineComponent({
  name: "VInputSelect",
  components: { InputLabel },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [String,Number,null] as PropType<string|number|null>,
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformInputValue(event: Event): any {
      if (event === null) {
        return null;
      }
      const value = get(event, "target.value", null);
      if (value === null) {
        return null;
      }
      return value;
    }
  }
});
</script>
