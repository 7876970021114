<template>
  <div>
    <BaseCard>
      <BaseCardRow
        v-for="question in questions"
        :key="question.id"
        size="sm"
        data-cy="question-items"
      >
        <QuestionItem
          :question="question"
          :answers="answers"
          :max="max"
          :disabled="isDisabled(question)"
        />
      </BaseCardRow>
    </BaseCard>

    <slot
      :is-disabled="isDisabled"
      :has-answered="hasAnswered"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Question } from "@/models/Question";
import { AnswerList } from "@/store";

export default defineComponent({
  props: {
    questions: {
      required: true,
      type: Object as PropType<Question[]>
    },
    answers: {
      required: true,
      type: Object as PropType<AnswerList>
    },
    max: {
      default: null,
      type: [Number,null] as PropType<number|null>
    }
  },
  computed: {
    answeredQuestionIds () : string[] {
      return this.questions
        .filter(q => {
          const answer = this.answers[q.id];
          return answer != null;
        })
        .map(q => q.id);
    },
    hasAnswered () : boolean {
      return this.answeredQuestionIds.length > 0;
    }
  },
  methods: {
    isDisabled (question: Question) : boolean {
      if (this.max === null) {
        return false;
      }
      if (this.answeredQuestionIds.length < this.max) {
        return false;
      }
      return !this.answeredQuestionIds.includes(question.id);
    }
  }
});
</script>
