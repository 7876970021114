<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <g transform="matrix(1.25,0,0,1.25,0,0)">
      <g>
        <ellipse
          cx="16.73"
          cy="20.68"
          rx="4.99"
          ry="2.11"
          transform="translate(-6.27 7.32) rotate(-20.88)"
          stroke="currentColor"
          style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px"
        />
        <ellipse
          cx="7.41"
          cy="20.68"
          rx="2.11"
          ry="4.99"
          transform="translate(-14.55 20.23) rotate(-69.12)"
          stroke="currentColor"
          style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px"
        />
        <line
          x1="12.07"
          y1="14.78"
          x2="12.07"
          y2="23.14"
          stroke="currentColor"
          style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px"
        />
        <circle
          cx="12.07"
          cy="8.8"
          r="2.25"
          stroke="currentColor"
          style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px"
        />
        <path
          d="M20.32,8.8a3,3,0,0,0-3.07-3,3,3,0,1,0-5.18-3,3,3,0,1,0-5.19,3,3,3,0,1,0,0,6,3,3,0,1,0,5.19,3,3,3,0,1,0,5.18-3A3,3,0,0,0,20.32,8.8Z"
          stroke="currentColor"
          style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
