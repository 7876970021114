import { Capacitor } from "@capacitor/core";
import { registerSW } from "virtual:pwa-register";
import { Router } from "vue-router";

// Flag if need PWA refresh
let needPWARefresh = false;

export default async (router: Router): Promise<void> => {

  if (Capacitor.getPlatform() == "web" && import.meta.env.VITE_SERVICE_WORKER == "true") {

    const updateSW = registerSW({
      onNeedRefresh() {
        needPWARefresh = true;
        console.log("Needs refresh");
      },
      onOfflineReady() {
        console.log("Offline mode ready");
      },
      onRegistered(r) {
        r && setInterval(() => {
          // Check if service worker was updated...
          r.update();
        }, 60 * 1000);
      }
    });

    router.afterEach(async () => {

      // Reload if new service worker
      if (needPWARefresh) {
        await updateSW();
      }

    });
  }
};
