<template>
  <div
    class="flex items-start w-full p-4 rounded-md"
    :class="[background, text]"
  >
    <div
      v-if="showIcon"
      class="mr-3"
    >
      <Icon
        v-if="type == 'warning'"
        icon="heroicons-solid:exclamation"
        :class="iconClass"
      />
      <Icon
        v-else-if="type == 'danger'"
        icon="heroicons-solid:exclamation-circle"
        :class="iconClass"
      />
      <Icon
        v-else-if="type == 'success'"
        icon="heroicons-solid:check-circle"
        :class="iconClass"
      />
      <Icon
        v-else-if="type == 'info'"
        icon="heroicons-solid:information-circle"
        :class="iconClass"
      />
    </div>

    <div>
      <h3
        v-if="title"
        class="mb-1 font-medium leading-tight"
      >
        {{ title }}
      </h3>

      <div
        v-if="$slots.default"
        :class="[text, 'leading-tight']"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: false,
      default: "",
      type: String
    },
    type: {
      default: "success",
      type: String
    },
    showIcon: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    background() {
      if (this.type == "info") {
        return "bg-blue-50";
      }
      if (this.type == "success") {
        return "bg-green-50";
      }
      if (this.type == "danger") {
        return "bg-red-50";
      }
      if (this.type == "warning") {
        return "bg-yellow-50";
      }
      return "bg-gray-900";
    },
    text() {
      if (this.type == "info") {
        return "text-blue-700";
      }
      if (this.type == "success") {
        return "text-green-700";
      }
      if (this.type == "warning") {
        return "text-yellow-700";
      }
      if (this.type == "danger") {
        return "text-red-700";
      }
      return "text-white";
    },
    iconClass () {
      return this.icon + " w-5 h-5";
    },
    icon() {
      if (this.type == "info") {
        return "text-blue-400";
      }
      if (this.type == "success") {
        return "text-green-500";
      }
      if (this.type == "warning") {
        return "text-yellow-500";
      }
      if (this.type == "danger") {
        return "text-red-500";
      }
      return "text-white";
    }
  },
});
</script>
