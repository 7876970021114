<template>
  <ProtectionLayout :force-show="forceShow">
    <PageProfileContent :member-id="($route.params.member as string)" />
  </ProtectionLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageProfileContent from "./PageProfileContent.vue";
import ProtectionLayout from "../ProtectionLayout.vue";

export default defineComponent({
  components: {
    PageProfileContent,
    ProtectionLayout
  },
  computed: {
    forceShow() : boolean {
      return this.$store.state.user?.member?.id == this.$route.params.member;
    }
  }
});
</script>
