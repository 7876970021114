<template>
  <div
    v-if="conversation"
    class="grow static flex flex-col w-full h-full overflow-hidden bg-white"
  >
    <BaseConversationHeader />

    <div class="grow relative flex flex-col overflow-hidden">
      <div
        ref="body"
        body-scroll-lock-scrollable
        class="grow static block overflow-x-hidden overflow-y-auto transition-colors"
        :class="{'bg-gray-50': conversation.flower_match}"
      >
        <div class="sm:pb-8 pb-10">
          <div class="sm:px-10 sm:py-6 px-3 py-3">
            <BaseMessage
              v-for="(message, i) in conversation.messages"
              :key="message.id"
              :message="message"
              :previous-message="getMessageByIndex(i - 1)"
            />
          </div>
        </div>
      </div>
      <div
        v-show="typing"
        class="bottom-3 left-3 absolute z-[1]"
      >
        <img
          src="/img/chat/typing-indicator.svg"
          alt="typing..."
          class="h-8"
          @load="scrollBottom"
        >
      </div>
    </div>

    <BaseConversationFooter />
  </div>

  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-200 ease-in delay-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center w-full h-full"
    >
      <div class="absolute inset-0 w-full h-full bg-white opacity-100" />
      <BaseSpinner />
    </div>
  </transition>
</template>

<script lang="ts">
import { Conversation, ConversationUser } from "@/models/Conversation";
import { computed, defineComponent, inject, ref } from "vue";
import BaseMessage from "./BaseMessage.vue";
import BaseConversationFooter from "./footer/BaseConversationFooter.vue";
import BaseConversationHeader from "./BaseConversationHeader.vue";
import { get, trim } from "lodash";
import { SUSPENDED, UNSUBSCRIBED } from "@/services/errorCodes";
import { uuid } from "@/services/uuid";
import { ConversationMessage } from "@/models/ConversationMessage";
import { useWindowFocus } from "@vueuse/core";

export default defineComponent({
  components: {
    BaseMessage,
    BaseConversationFooter,
    BaseConversationHeader,
  },
  provide() {
    return {
      user: computed(() => this.user),
      me: computed(() => this.me),
      conversation: computed(() => this.conversation),
      softFetch: this.softFetch,
      scrollBottom: this.scrollBottom,
      resetTypingCountdown: this.resetTypingCountdown,
      addMessage: this.addMessage,
      removeMessage: this.removeMessage,
      setMemberBlockId: this.setMemberBlockId,

    };
  },
  setup () {
    const focused = useWindowFocus();
    const mustFetch = ref(false);

    const deleteConversationById = inject("deleteConversationById") as (id: string) => void;
    const setCurrentConversationId = inject("setCurrentConversationId") as (priority: string, id: string) => void;

    return {
      deleteConversationById,
      setCurrentConversationId,
      focused,
      mustFetch,
    };
  },
  data () {
    return {
      fetching: false,
      loading: false,
      conversation: null as Conversation | null,
      typingCountdown: 0,
      typing: true,
      typingCountDownIntervalId: 0 as any,
      channel: this.$echo.private("user." + this.$store.state.user?.id ?? ""),
    };
  },
  computed: {
    me () : ConversationUser|null {
      return this.conversation?.users.find(u => u.is_me) ?? null;
    },
    user () : ConversationUser|null {
      return this.conversation?.users.find(u => !u.is_me) ?? null;
    },
    receivedFlower() : boolean {
      if (this.conversation?.flower_match) {
        return false;
      }
      if (!this.user) {
        return false;
      }
      return this.user.pivot.sent_flower;
    }
  },
  watch: {
    // Fetch on window focus
    focused (focused) {
      if (focused && this.mustFetch) {
        this.softFetch();
      }
    }
  },
  created () {

    this.fetch();

    this.channel.listen(".conversations.messages.created", this.onEventConversationMessageCreated);

    const increment = 100;

    this.typingCountDownIntervalId = setInterval(() => {
      if (this.typingCountdown > 0) {
        this.typing = true;
        this.typingCountdown = Math.max(this.typingCountdown - increment, 0);
      }

      if (this.typingCountdown == 0) {
        this.typing = false;
      }
    }, increment);
  },
  beforeUnmount () {
    clearInterval(this.typingCountDownIntervalId);
    this.channel.stopListening(".conversations.messages.created", this.onEventConversationMessageCreated);
  },
  mounted () {
    this.scrollBottom();
  },
  methods: {
    softFetch() {
      this.fetch(false);
    },
    fetch(hardLoading = true) {

      if (!this.$route.params.conversation) {
        return;
      }

      if (this.fetching) {
        return;
      }

      this.fetching = true;

      if (hardLoading) {
        this.loading = true;
      }

      const conversationId = this.$route.params.conversation as string;

      this.$api.get(`/conversations/${conversationId}`)
        .then((response: any) => {
          this.conversation = response.data.data as Conversation;
          this.scrollBottom();
          this.markAsSeen(conversationId);
          if (this.me) {
            this.setCurrentConversationId(this.me.pivot.priority, this.conversation.id);
          }
        })
        .catch(async (error: Error) => {

          console.error(error);

          const code = get(error, "response.data.code");
          if ([UNSUBSCRIBED, SUSPENDED].includes(code)) {
            this.$store.dispatch("fetchUser");
          }

          const status = get(error, "response.status");

          if (status == 404) {

            this.deleteConversationById(conversationId);

            this.$router.push({
              name: "member.messages"
            });
          }

          this.conversation = null;
        })
        .finally(() => {
          this.fetching = false;
          this.loading = false;
        });
    },
    scrollBottom() {
      this.$nextTick(() => {
        if (this.$refs.body instanceof HTMLElement) {
          this.$refs.body.scrollTop = this.$refs.body.scrollHeight;
        }
      });
    },
    resetTypingCountdown() {
      this.typingCountdown = 1000;
    },
    markAsSeen(conversationId: string) {
      this.$store.commit("conversation/setSeen", conversationId);
    },
    setMemberBlockId(userId: string, value: string|null) {
      if (!this.conversation) {
        return;
      }

      const index = this.conversation.users.findIndex(u => {
        return u.id == userId;
      });

      if (index == -1) {
        return;
      }

      this.conversation.users[index].member.member_block_id = value;
    },
    addMessage(message: string) : string|null {

      if (!this.conversation) {
        return null;
      }

      const id = uuid();

      this.conversation.messages?.push({
        id: id,
        content: trim(message),
        content_text: trim(message),
        is_me: true,
        flower: false,
        flower_match: false,
        created_at: this.$luxon.now().toISO(),
      });

      const userIndex = this.conversation.users.findIndex(u => u.id == this.me?.id);

      if (userIndex != -1) {
        this.conversation.users[userIndex].pivot.has_responded = true;
      }

      this.scrollBottom();

      return id;
    },
    removeMessage(id: string|null) {
      if (!this.conversation) {
        return;
      }

      if (!id) {
        return;
      }

      this.conversation.messages = this.conversation.messages?.filter(m => {
        return m.id != id;
      }) ?? undefined;
    },
    getMessageByIndex(index: number) : ConversationMessage|null {
      if (index < 0) {
        return null;
      }
      if (this.conversation?.messages == undefined) {
        return null;
      }
      return this.conversation.messages[index] ?? null;
    },
    onEventConversationMessageCreated(data : any) {
      if (
        data.id == this.$route.params.conversation &&
            data.user_id != this.$store.state.user?.id
      ) {
        if (this.focused) {
          this.softFetch();
        } else {
          this.mustFetch = true;
        }
      }
    }
  },
});
</script>
