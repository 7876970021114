<template>
  <OnboardingTitle>
    {{ $t('step.short_question.title') }}
  </OnboardingTitle>

  <OnboardingText class="mb-6">
    {{ $t('step.short_question.text') }}
  </OnboardingText>

  <QuestionItems
    :questions="shortQuestions"
    :answers="$store.getters['answers']"
  >
    <template #default="{hasAnswered}">
      <div class="mt-10">
        <StepButtons
          :skippable="!hasAnswered"
          @skip="nextStep"
          @previous="previousStep"
          @next="nextStep"
        />
      </div>
    </template>
  </QuestionItems>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import { Question } from "@/models/Question";
import OnboardingText from "../components/OnboardingText.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: { OnboardingTitle, StepButtons, OnboardingText },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  },
  data () {
    return {
      currentQuestionId: null as string|null
    };
  },
  computed: {
    shortQuestions () : Question[] {
      return this.$store.getters["question/getByType"]("ShortQuestionType") as Question[];
    }
  }
});
</script>
