import { DailyQuote } from "@/models/DailyQuote";
import i18n from "@/plugins/i18n";
import { api, http } from "@/services/api";
import { getRoute } from "@/services/utils";

export interface DailyQuoteState {
  dailyQuote: DailyQuote | null
}

export const defaultDailyQuoteState = {
  dailyQuote: null,
} as DailyQuoteState;

export const dailyQuoteModule = {
  namespaced: true,
  state() {
    return defaultDailyQuoteState;
  },
  mutations: {
    set(state: DailyQuoteState, dailyQuote: DailyQuote | null) {
      state.dailyQuote = dailyQuote;
    },
  },
  actions: {
    fetch(store: any): Promise<null> {
      return new Promise((resolve, reject) => {
        api.get(getRoute("/api/content/daily-quote"), { params: { locale: i18n.global.locale } })
          .then((response: any) => {
            store.commit("set", response.data.data);
            resolve(null);
          })
          .catch((error: Error) => {
            reject(error);
          });
      });
    },
  }
};
