<template>
  <div class="relative">
    <OnboardingTitle>
      {{ $t('step.introduction_video.title') }}
    </OnboardingTitle>

    <ResponsiveIframe
      class="mb-8 bg-gray-200"
      :url="`https://www.youtube.com/embed/vadQLT8bZv0?hl=${$i18n.locale}&iv_load_policy=3&modestbranding=1`"
    />

    <StepButtons
      @previous="previousStep"
      @next="nextStep"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: { OnboardingTitle, StepButtons },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  }
});
</script>
