<template>
  <main-layout :before-created="beforeCreated" />
</template>

<script lang="ts">

import { defineComponent } from "vue";
import MainLayout from "../MainLayout.vue";

export default defineComponent({
  components: {
    MainLayout
  },
  methods: {
    async beforeCreated() {
      await Promise.all([
        this.$store.dispatch("countries/fetch"),
      ]);
    }
  }
});
</script>
