<template>
  <div>
    <MainLayout
      :before-created="beforeCreated"
      @loaded="$emit('loaded')"
    />

    <VideoChatCallModal
      v-if="videoChatData"
      v-model="showVideoChatModal"
      :user="videoChatData.user"
      :conversation-id="videoChatData.conversation_id"
    />
  </div>
</template>

<script lang="ts" setup>

import MainLayout from "../MainLayout.vue";
import VideoChatCallModal from "../../components/elements/VideoChatCallModal.vue";
import { onBeforeUnmount, Ref, ref, defineEmits } from "vue";
import { echo } from "@/services/echo";
import { store } from "@/store";
import { useRoute } from "vue-router";

defineEmits(["loaded"]);

const route = useRoute();

const showVideoChatModal = ref(false);
const videoChatData = ref(null) as Ref<null|any>;

const channel = echo.private("user." + store.state.user?.id);
channel.listen(".conversations.messages.created", onEventConversationMessageCreated);
channel.listen(".conversations.video-chat.call", onEventConversationVideoChatCall);

onBeforeUnmount(() => {
  channel.stopListening(".conversations.messages.created", onEventConversationMessageCreated);
  channel.stopListening(".conversations.video-chat.call", onEventConversationVideoChatCall);
});

async function beforeCreated() {
  await Promise.all([
    store.dispatch("question/fetch"),
    store.dispatch("dailyQuote/fetch"),
    store.dispatch("countries/fetch"),
    store.dispatch("conversation/fetchUnseenConversations")
  ]);
}

function onEventConversationMessageCreated(data: any) {
  if (data.user_id != store.state.user?.id) {
    store.dispatch("conversation/fetchUnseenConversations");
  }
}

function onEventConversationVideoChatCall(data: any) {
  if (route.name == "member.messages.conversation.video-chat" && route.params.conversation == data.conversation_id) {
    return;
  }

  // Can't send notification to myself
  if (data.user?.id == store.state.user?.id) {
    return;
  }
  videoChatData.value = data;
  showVideoChatModal.value = true;
}
</script>
