import Echo from "laravel-echo";

import Pusher, { Channel } from "pusher-js";
import { api } from "@/services/api";

window.Pusher = Pusher;

const echo = new Echo({
  broadcaster: "pusher",
  key: import.meta.env.VITE_PUSHER_KEY,
  wsHost: import.meta.env.VITE_PUSHER_HOST,
  wsPort: import.meta.env.VITE_PUSHER_PORT,
  wssPort: import.meta.env.VITE_PUSHER_PORT,
  forceTLS: import.meta.env.VITE_PUSHER_PORT == "443",
  encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  authorizer: (channel: Channel) => {
    return {
      authorize: (socketId: string, callback: (status: boolean, data: any) => void) => {
        api.post("broadcasting/auth", {
          socket_id: socketId,
          channel_name: channel.name
        })
          .then((response: any) => {
            callback(false, response.data);
          })
          .catch((error: any) => {
            callback(true, error);
          });
      }
    };
  },
});

export { echo };
