<template>
  <div
    class="md:grid md:grid-cols-3 md:gap-6 pb-8"
    :data-error="error"
  >
    <div class="md:col-span-1">
      <h3 class="md:text-xl text-lg font-medium leading-6 text-gray-900">
        {{ title }}
      </h3>
      <p
        v-if="description"
        class="mt-1 leading-5 text-gray-600"
      >
        {{ description }}
      </p>
    </div>
    <div class="md:mt-0 md:col-span-2 mt-5">
      <div
        v-if="boxed"
        class="bg-white border rounded shadow-sm"
        :class="{'border-red-600': error, ' border-gray-200': !error}"
      >
        <div class="sm:p-6 p-3">
          <slot />
        </div>
        <div
          v-if="button"
          class="bg-gray-50 px-6 py-3 text-right"
        >
          <button class="btn btn-primary">
            <span>{{ $t("save") }}</span>
          </button>
        </div>
      </div>
      <div v-else>
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String
    },
    description: {
      default: null,
      type: String
    },
    button: {
      default: false,
      type: Boolean
    },
    boxed: {
      default: true,
      type: Boolean
    },
    error: {
      default: false,
      type: Boolean
    }
  }
});
</script>
