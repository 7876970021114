<template>
  <component
    :is="layoutComponent"
    v-if="loaded"
    :back-url="backUrl"
  >
    <slot />
  </component>

  <NotificationList />
</template>

<script lang="ts">

import { computed, defineComponent, inject, Ref } from "vue";
import OnboardingLayoutDesktop from "./OnboardingLayoutDesktop.vue";
import OnboardingLayoutMobile from "./OnboardingLayoutMobile.vue";
import NotificationList from "@/layouts/NotificationList.vue";
import { useHead } from "@vueuse/head";
import i18n from "@/plugins/i18n";
import Cookies from "js-cookie";
import { Gender } from "@/models/User";
import { isArray, isString } from "lodash";
import { DateTime } from "luxon";

export default defineComponent({
  components: {
    OnboardingLayoutDesktop,
    OnboardingLayoutMobile,
    NotificationList
  },
  props: {
    backUrl: {
      default: null,
      type: String,
    }
  },
  emits: ["loaded"],
  setup() {
    const mobile = inject("mobile") as Ref<boolean>;

    useHead({
      // Can be static or computed
      title: computed(() => {
        return i18n.global.t("meta_title");
      }),
      meta: [
        {
          name: "description",
          content: computed(() => i18n.global.t("meta_description")),
        },
      ],
    });

    return {
      mobile
    };
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    layoutComponent () : string {
      if (this.mobile) {
        return "OnboardingLayoutMobile";
      }
      return "OnboardingLayoutDesktop";
    }
  },
  async created() {

    const routeGender = this.$route.query.gender;
    const expiry = DateTime.now().plus({weeks: 1}).toJSDate();

    if (isString(routeGender) && Object.values(Gender).includes(routeGender as Gender)) {
      Cookies.set("onboarding.gender", routeGender as string, {
        expires: expiry,
      });
    }

    const routeSexualOrientations = this.$route.query["sexual_orientations[]"];

    if (isArray(routeSexualOrientations)) {
      Cookies.set("onboarding.sexual_orientations", routeSexualOrientations.join(","), {
        expires: expiry,
      });
    }

    await this.$store.dispatch("countries/fetch");

    this.loaded = true;
    this.$emit("loaded");
  },
});
</script>
