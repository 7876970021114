<template>
  <router-link
    class="relative flex flex-col items-center justify-center flex-1 px-0.5 pb-1"
    :to="{name: link.route}"
  >
    <template #default="{ isActive, isExactActive }">
      <div
        v-show="!linkIsActive(isActive, isExactActive)"
        class="text-gray-500 mb-[3px]"
      >
        <component
          :is="link.iconInactive"
          class="w-6 h-6"
        />
      </div>
      <div
        v-show="linkIsActive(isActive, isExactActive)"
        class="text-primary-500 mb-[3px]"
      >
        <component
          :is="link.iconActive"
          class="w-6 h-6"
        />
      </div>
      <div
        class="text-center text-[11px] h-[11px] leading-none line-clamp-1"
        :class="{
          'text-primary-500': linkIsActive(isActive, isExactActive),
          'text-gray-400': !linkIsActive(isActive, isExactActive)
        }"
      >
        {{ $t(link.title) }}
      </div>
      <div
        v-if="link.count && link.count()"
        class="right-1/2 top-[2px] absolute translate-x-1/2"
      >
        <div class="left-4 relative flex items-center justify-center px-1 py-px text-xs font-medium leading-none text-white bg-red-500 rounded-full">
          {{ link.count() }}
        </div>
      </div>
    </template>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Link } from "./models/Link";

export default defineComponent({
  props: {
    link: {
      required: true,
      type: Object as PropType<Link>
    }
  },
  methods: {
    linkIsActive(isActive : boolean, isExactActive: boolean) : boolean {
      if (this.link.exact) {
        return isExactActive;
      }

      return isActive;
    }
  }
});
</script>
