<template>
  <BaseAlert
    v-if="!$store.state.user?.email_verified_at"
    class="relative mb-6 overflow-hidden border border-yellow-400"
    type="warning"
  >
    <BaseLoadingCover
      v-model="sendingLink"
      backdrop-class="bg-opacity-90 bg-yellow-100"
      icon-class="w-10 h-10 text-yellow-600"
    />

    <p
      class="mb-6 text-base"
      v-html="$t('pages.onboarding.email_verification.body.line_1', {email: $store.state?.user?.email}).markdownToHtml('font-semibold underline text-yellow-900')"
    />

    <h2 class="mb-3 font-semibold text-yellow-900">
      {{ $t('pages.onboarding.email_verification.issue.title') }}
    </h2>

    <p class="mb-3 text-base">
      {{ $t('pages.onboarding.email_verification.issue.line_1', {from: 'info@effettandem.com'}) }}
    </p>

    <p class="text-base">
      {{ $t('pages.onboarding.email_verification.issue.line_2') }}
    </p>

    <div class="mt-4 mb-4">
      <button
        type="button"
        class="p-0 font-medium text-yellow-900 underline bg-transparent appearance-none"
        @click="resendLink()"
      >
        {{ $t('resend_email') }}
      </button>
    </div>
  </BaseAlert>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      sendingLink: false,
    };
  },
  methods: {
    resendLink () {

      if (this.sendingLink) {
        return;
      }

      this.sendingLink = true;

      this.$api.post("email-verification/send-link")
        .then(response => {
          console.info(response.data);
        })
        .finally(() => {
          this.sendingLink = false;
        });
    },
  }
});
</script>
