<template>
  <svg
    v-once
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5731 3.13336C11.83 2.95555 12.1701 2.95555 12.427 3.13336L22.177 9.88336C22.4467 10.0701 22.5638 10.4105 22.4659 10.7236C22.3681 11.0368 22.0781 11.25 21.7501 11.25H20.2501C19.8358 11.25 19.5001 11.5858 19.5001 12V18.75C19.5001 19.9926 18.4927 21 17.2501 21H6.75005C5.50741 21 4.50006 19.9926 4.50006 18.75V12C4.50006 11.5858 4.16427 11.25 3.75006 11.25H2.25006C1.92199 11.25 1.63199 11.0368 1.53417 10.7236C1.43635 10.4105 1.55341 10.0701 1.82315 9.88336L11.5731 3.13336ZM12 15.75C13.2426 15.75 14.25 14.7426 14.25 13.5C14.25 12.2574 13.2426 11.25 12 11.25C10.7574 11.25 9.75 12.2574 9.75 13.5C9.75 14.7426 10.7574 15.75 12 15.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
