<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="2xl">
      <BaseTitle
        :previous="false"
        :title="$t('modify_my_password')"
        :subtitle="$t('modify_my_password_description')"
      />
    </BaseContainer>

    <BaseContainer
      size="2xl"
    >
      <PasswordForm />
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

});
</script>
