<template>
  <div class="bg-gray-50 sm:py-20 relative w-full h-full min-h-screen pb-16 overflow-hidden">
    <div class="max-w-lg mx-auto">
      <div class="px-10 py-6 text-center bg-white border border-gray-200 rounded-lg shadow-md">
        <img
          src="@/assets/img/logo/logo-icon-primary-600.svg"
          alt="Effet Tandem"
          class="inline w-12 mb-5"
        >

        <h1 class="font-display mb-5 text-2xl font-semibold leading-tight">
          {{ $t('state_suspended.title') }}
        </h1>

        <template v-if="reason == 'incomplete_gallery'">
          <div class="mb-6 text-lg leading-tight text-gray-700">
            <p class="mb-3">
              {{ $t('state_suspended.incomplete_gallery.line_1') }}
            </p>
            <p class="mb-3">
              {{ $t('state_suspended.incomplete_gallery.line_2') }}
            </p>
            <p class="mb-3">
              {{ $t('state_suspended.incomplete_gallery.line_3') }}
            </p>
            <router-link
              to="/member/settings/pictures"
              class="btn btn-primary btn-lg mt-6"
            >
              {{ $t('state_suspended.incomplete_gallery.cta') }}
            </router-link>
          </div>
        </template>

        <template v-else>
          <p class="mb-6 text-lg leading-tight text-gray-700">
            {{ $t('state_suspended.other.line_1') }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    reason () : string|null {

      if (!this.$store.state.user) {
        return null;
      }

      if (!this.$store.state.user.member) {
        return null;
      }

      return this.$store.state.user.member.suspended_reason;
    }
  }
});
</script>
