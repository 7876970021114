<template>
  <div class="md:py-0 md:border-none md:mt-6 py-8 border-b border-gray-200">
    <h2
      v-if="$slots.title"
      class="md:px-0 px-4 mb-3 text-lg font-medium text-gray-700"
    >
      <slot name="title" />
    </h2>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

});
</script>
