import Form from "./Form.vue";
import Text from "./inputs/Text.vue";
import Password from "./inputs/Password.vue";
import PasswordWithValidation from "./inputs/PasswordWithValidation.vue";
import Textarea from "./inputs/Textarea.vue";
import InputNumber from "./inputs/Number.vue";
import Select from "./inputs/Select.vue";
import RadioButton from "./inputs/RadioButton.vue";
import Tag from "./inputs/Tag.vue";
import Radio from "./inputs/Radio.vue";
import Checkbox from "./inputs/Checkbox.vue";
import MultiSelect from "./inputs/MultiSelect.vue";
import Size from "./inputs/Size.vue";
import Date from "./inputs/Date.vue";
import Slider from "./inputs/Slider.vue";
import { App } from "vue";

export default {
  install: (app: App): void => {
    app.component("VForm", Form);

    app.component("VInput", Text);
    app.component("VInputPassword", Password);
    app.component("VInputPasswordWithValidation", PasswordWithValidation);
    app.component("VInputTextarea", Textarea);
    app.component("VInputNumber", InputNumber);
    app.component("VInputSelect", Select);
    app.component("VInputRadioButton", RadioButton);
    app.component("VInputTag", Tag);
    app.component("VInputRadio", Radio);
    app.component("VInputCheckbox", Checkbox);
    app.component("VInputMultiSelect", MultiSelect);
    app.component("VInputSize", Size);
    app.component("VInputDate", Date);
    app.component("VInputSlider", Slider);
  }
};
