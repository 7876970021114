<template>
  <div class="bottom-menu-mobile-safe z-menu fixed left-0 w-full py-2 bg-white border-t border-gray-200 shadow-md">
    <div class="w-full overflow-x-auto">
      <div class="flex">
        <div class="flex px-4 space-x-2">
          <div>
            <button
              type="button"
              class="btn btn-xs btn-secondary-outline flex items-center rounded"
              @click="showFilters()"
            >
              <Icon
                icon="heroicons-outline:adjustments"
                class="w-5 h-5 mr-2"
              />
              <span class="whitespace-nowrap mr-1">{{ $t('filters') }}</span>
              <span
                v-if="numberOfFilters"
                class="bg-primary-600 px-1.5 ml-2 text-xs text-white rounded-full"
              >{{ numberOfFilters }}</span>
            </button>
          </div>

          <div>
            <button
              type="button"
              class="btn btn-xs btn-secondary-outline flex items-center rounded"
              @click="showSorts()"
            >
              <Icon
                icon="heroicons-outline:sort-descending"
                class="w-5 h-5 mr-2"
              />
              <span class="whitespace-nowrap">{{ $t('sort') }}</span>
            </button>
          </div>

          <div>
            <AnonymousMode
              class="grow-0 shrink-0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, inject } from "vue";
import AnonymousMode from "./AnonymousMode.vue";

export default defineComponent({
  components: {
    AnonymousMode,
  },
  setup() {
    const numberOfFilters = inject("numberOfFilters") as number;
    const showSorts = inject("showSorts") as () => void;
    const showFilters = inject("showFilters") as () => void;

    return {
      numberOfFilters,
      showSorts,
      showFilters
    };
  }
});
</script>
