import { InjectionKey } from "vue";
import { Store, useStore as baseUseStore } from "vuex";
import { store } from "./store";
import type { State, AnswerList } from "./store";

export const key: InjectionKey<Store<State>> = Symbol();

export { store, State, AnswerList };

export function useStore() {
  return baseUseStore(key);
}
