<template>
  <div class="w-full">
    <div class="h-menu-mobile w-full" />
    <div class="pb-safe w-full" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
