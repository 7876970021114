<template>
  <div class="bg-gray-50 pt-safe pb-safe">
    <div class="pb-16">
      <BaseContainer
        size="4xl"
        paddingless-mobile
      >
        <BaseCard class="mb-6 overflow-hidden">
          <VForm
            :data="form"
            url="account/ambassador"
            method="patch"
            @success="onSuccess()"
          >
            <BaseCardRow>
              <h2 class="text-lg font-medium">
                {{ $t('contact_information') }}
              </h2>
            </BaseCardRow>

            <BaseCardRow>
              <AddressForm v-model="form" />

              <VInput
                v-model="form.phone"
                :label="$t('phone')"
                name="phone"
                input-class="w-full"
              />
            </BaseCardRow>
            <BaseCardRow>
              <h2 class="text-md mb-3 font-medium">
                {{ $t('pages.ambassador.objective_title') }}
              </h2>
              <VInputSelect
                v-model="form.objective"
                name="objective"
                input-class="w-full"
                class="mb-2"
                required
              >
                <option value="for_fun">
                  {{ $t('pages.ambassador.objective.for_fun') }}
                </option>
                <option value="additional_income">
                  {{ $t('pages.ambassador.objective.additional_income') }}
                </option>
                <option value="fundraising">
                  {{ $t('pages.ambassador.objective.fundraising') }}
                </option>
              </VInputSelect>
            </BaseCardRow>
            <BaseCardRow>
              <h2 class="text-md mb-3 font-medium">
                {{ $t('tax_numbers') }}
              </h2>

              <BaseAlert
                type="warning"
                class="mb-4"
              >
                {{ $t('ambasador_taxes_notice') }}
              </BaseAlert>

              <VInput
                v-model="form.gst"
                :label="$t('gst_hst')"
                name="gst"
                input-class="w-full"
                class="mb-2"
              />
              <VInput
                v-model="form.pst"
                :label="$t('pst_qst')"
                name="pst"
                input-class="w-full"
              />

              <div
                v-if="requiresNas"
                id="sin"
                class="mt-2"
              >
                <VInput
                  v-if="updateNas"
                  v-model="form.nas"
                  name="nas"
                  :label="$t('SIN')"
                  input-class="w-full"
                />
                <div v-else>
                  <InputLabel :label="$t('SIN')" />
                  <div class="flex mb-1">
                    <input
                      type="text"
                      disabled
                      class="grow w-full border-gray-300 rounded-l"
                      :value="user.nas"
                    >
                    <button
                      class="shrink-0 btn btn-gray btn-gray-outline flex items-center justify-center px-3 border-l-0 rounded-l-none"
                      type="button"
                      @click="setUpdateNas()"
                    >
                      {{ $t('update') }}
                    </button>
                  </div>
                </div>
              </div>
            </BaseCardRow>
            <BaseCardRow>
              <button
                type="submit"
                class="btn btn-primary btn-lg"
              >
                {{ $t('save') }}
              </button>
            </BaseCardRow>
          </VForm>
        </BaseCard>

        <div
          id="email-not-verified"
          class="anchor"
        />

        <EmailNotVerified v-if="!$store.state.user?.member" />

        <div
          v-if="!$store.state.user?.member"
          class="mb-6"
        >
          <EmailForm />
        </div>

        <BaseCard
          v-if="!$store.state.user?.member"
          class="mb-6 overflow-hidden"
        >
          <BaseCardRow>
            <h2 class="text-lg font-semibold">
              {{ $t('modify_my_password') }}
            </h2>
          </BaseCardRow>
          <BaseCardRow>
            <PasswordForm />
          </BaseCardRow>
        </BaseCard>

        <template v-if="!$store.state.user?.member">
          <BaseCard class="mb-6">
            <BaseCardRow>
              <LogoutButton />
            </BaseCardRow>
          </BaseCard>
          <BaseCard class="mb-6">
            <BaseCardRow>
              <DeleteAccount />
            </BaseCardRow>
          </BaseCard>
        </template>
      </BaseContainer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Account, AccountAmbassador } from "@/models/Account";
import InputLabel from "@/plugins/form/components/utils/InputLabel.vue";
import { cloneDeep } from "lodash-es";
import { hideString } from "@/services/utils";

interface Form {
  address_1: string|null,
  address_2: string|null,
  city: string|null,
  state: string|null,
  country: string|null,
  postal_code: string|null,
  phone: string|null,
  gst: string|null,
  pst: string|null,
  nas?: string,
  objective: string|null,
}

export default defineComponent({
  name: "PageAmbassadorSettings",
  components: { InputLabel },
  data() {
    return {
      updateNas: false,
      form: {
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        country: "ca",
        postal_code: null,
        phone: null,
        gst: null,
        pst: null,
        objective: null,
      } as Form
    };
  },
  computed: {
    user () : Account {
      return this.$store.state.user as Account;
    },
    ambassador () : AccountAmbassador|null|undefined {
      return this.$store.state.user?.ambassador;
    },
    requiresNas() : boolean {
      return this.ambassador?.requires_nas ?? false;
    },
    hasNas() : boolean {
      return this.user.nas != "" && this.user.nas != null;
    }
  },
  created () {
    this.form.address_1 = this.user.address_1;
    this.form.address_2 = this.user.address_2;
    this.form.city = this.user.city;
    this.form.state = this.user.state;
    this.form.country = this.user.country ?? "ca";
    this.form.postal_code = this.user.postal_code;
    this.form.phone = this.user.phone;
    this.form.gst = this.user.gst;
    this.form.pst = this.user.pst;
    this.form.objective = this.user?.ambassador?.objective ?? null;

    if (this.requiresNas && !this.hasNas) {
      this.setUpdateNas();
    }
  },
  methods: {
    setUpdateNas() {
      this.form.nas = "";
      this.updateNas = true;
    },
    unsetUpdateNas() {
      delete this.form.nas;
      this.updateNas = false;
    },
    onSuccess() {
      const user = cloneDeep(this.$store.state.user) as Account;
      user.address_1 = this.form.address_1;
      user.address_2 = this.form.address_2;
      user.city = this.form.city;
      user.state = this.form.state;
      user.country = this.form.country;
      user.postal_code = this.form.postal_code;
      user.gst = this.form.gst;
      user.pst = this.form.pst;
      user.has_taxes = this.form.gst != "" && this.form.pst != "";
      if (this.updateNas) {
        user.nas = hideString(this.form.nas as string);
      }
      if (user.ambassador) {
        user.ambassador.objective = this.form.objective;
      }
      this.$store.commit("setUser", user);
      this.unsetUpdateNas();
    }
  },
});
</script>
