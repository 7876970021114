<template>
  <h2 class="mb-1 text-xl font-semibold leading-tight">
    {{ questionText }}&nbsp;<span
      v-if="question.required"
      class="text-red-600"
    >*</span>
    <span
      v-if="!question.required"
      class="text-sm font-normal text-gray-500 lowercase"
    >({{ $t('optional') }})</span>
  </h2>
  <p
    v-if="question.description"
    class="mb-3 leading-tight text-gray-600"
  >
    {{ question.description }}
  </p>

  <BaseAlert
    v-if="question.notice"
    class="mt-3 mb-6 leading-tight text-gray-600"
    type="info"
  >
    <div class="whitespace-pre-wrap">
      {{ question.notice }}
    </div>
  </BaseAlert>

  <template v-if="question.type.input_type == QuestionType.input">
    <VInput
      class="mt-3"
      :model-value="data.value"
      :name="name"
      input-class="w-full"
      :required="question.required"
      @update:model-value="onUpdateText"
    />
  </template>
  <template v-if="question.type.input_type == QuestionType.textarea">
    <VInputTextarea
      class="mt-3"
      :model-value="data.value"
      :name="name"
      input-class="w-full"
      :min="question.type.min"
      :max="question.type.max"
      :maxlength="question.type.max"
      :rows="question.type.rows"
      :required="question.required"
      :placeholder="textareaPlaceholder"
      @update:model-value="onUpdateText"
    />
  </template>
  <template v-if="question.type.input_type == QuestionType.select">
    <VInputRadioButton
      class="mt-3"
      :model-value="data.value"
      :name="name"
      :required="question.required"
      button-class="shadow"
      :options="question.options.map((o: any) => {return {value: o.id, label: o.label}})"
      @update:model-value="onUpdateSelect"
    />
  </template>
  <template v-if="question.type.input_type == QuestionType.multi_select">
    <p
      v-if="(!question.type.max || question.type.max > 0) && question.type.min != question.type.max"
      class="flex items-center text-sm text-gray-500"
    >
      <Icon
        icon="heroicons-outline:information-circle"
        class="inline w-3 h-3 mr-1"
      />
      {{ $t('you_can_select_more_than_one') }}
    </p>

    <VInputMultiSelect
      :model-value="data.value"
      :name="name"
      input-class="w-full"
      :required="question.required"
      :options="formattedOptions(question.options)"
      label-key="label"
      value-key="id"
      class="mt-5"
      :wrapper-class="hasManyOptions ? '-m-2 justify-center' : '-m-1'"
      :item-class="hasManyOptions ? 'p-2 shrink-0 w-1/2 sm:w-1/3' : 'p-1'"
      :button-class="hasManyOptions ? 'w-full shadow h-full' : 'shadow'"
      :quantity-notice-class="hasManyOptions ? 'text-center mt-5' : ''"
      :min="question.type.min"
      :max="question.type.max"
      @update:model-value="onUpdateMultiSelect"
    />
  </template>
  <template v-if="question.type.input_type == QuestionType.size">
    <VInputSize
      class="mt-3"
      :model-value="data.value ? +data.value : null"
      :name="name"
      :min="question.type.min"
      :max="question.type.max"
      input-class="w-full"
      :required="question.required"
      @update:model-value="setValue"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Question, QuestionType } from "@/models/Question";
import { QuestionOption } from "@/models/QuestionOption";
import { Option, OptionValue } from "@/plugins/form/types/types";
import { AnswerData, AnswerValue } from "./types";

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as PropType<AnswerData>
    },
    name: {
      required: true,
      type: String
    }
  },
  emits: ["update", "submit"],
  setup () {
    return {
      QuestionType: QuestionType
    };
  },
  computed: {
    question() : Question {
      return this.$store.getters["question/listById"][this.data.question_id];
    },
    hasManyOptions() : boolean {
      return this.question.options.length > 11;
    },
    questionText () : string {
      const optionIds = this.$store.state.user?.member?.answers.map(a => a.options.map(o => o.id)).flat() ?? [];

      const altQuestion = (this.question.properties.alt_question_texts ?? []).find(alt => {
        return alt.option_ids.filter(id => optionIds.includes(id)).length > 0;
      });

      if (!altQuestion) {
        return this.question.text;
      }

      let altQuestionText = altQuestion.text[this.$i18n.locale];

      if (altQuestionText) {
        return altQuestionText;
      }

      let texts = Object.values(altQuestion.text) as string[];

      if (texts.length) {
        return texts[0];
      }

      return this.question.text;
    },
    textareaPlaceholder () : string {
      if (this.question.type.name == "LongQuestionType") {
        return this.$t("_placeholder_long_question_type");
      }
      if (this.question.type.name == "ShortQuestionType") {
        return this.$t("_placeholder_short_question_type");
      }
      return "";
    }
  },
  methods: {
    onUpdateText(data: string|null) {
      this.setValue(data);
    },
    onUpdateSelect(data: string|null) {
      this.setValue(data);
    },
    onUpdateMultiSelect(data: OptionValue[]) {
      this.setValue(data);
    },
    setValue (value: AnswerValue) {
      this.$emit("update", value);
    },
    formattedOptions(options: QuestionOption[]) : Option[] {
      return options
        .map(option => {
          return {
            data: option,
            cancel_others: option.properties?.cancel_others ?? false,
            iconify: option.properties?.iconify ?? null,
            icon_path: option.properties?.icon_path ?? null,
          };
        });
    },
    preferNotToAnswer () {
      this.setValue(null);
      this.$nextTick(() => {
        this.$emit("submit");
      });
    }
  }
});
</script>
