<template>
  <div class="relative">
    <OnboardingTitle margin-bottom="mb-2">
      {{ $t('step.pictures.title') }}
    </OnboardingTitle>

    <OnboardingText class="mb-6">
      {{ $t('step.pictures.text') }}
    </OnboardingText>

    <BaseAlert
      type="info"
      class="mb-3 border border-blue-400"
    >
      {{ $t('step.pictures.warning') }}
    </BaseAlert>

    <BaseAlert
      type="info"
      class="mb-6 border border-blue-400"
    >
      <p class="mb-1">
        {{ $t('step.pictures.main_photo_notice.text') }}
      </p>
      <ul class="list-disc list-inside">
        <li>{{ $t('step.pictures.main_photo_notice.line_1') }};</li>
        <li>{{ $t('step.pictures.main_photo_notice.line_2') }};</li>
        <li>{{ $t('step.pictures.main_photo_notice.line_3') }};</li>
        <li>{{ $t('step.pictures.main_photo_notice.line_4') }};</li>
        <li>{{ $t('step.pictures.main_photo_notice.line_5') }};</li>
        <li>{{ $t('step.pictures.main_photo_notice.line_6') }}.</li>
      </ul>
    </BaseAlert>

    <div class="mb-10">
      <PicturesForm
        width-classes="w-1/2 xs:w-1/3"
        allow-empty
        @update="onUpdate"
      />
    </div>

    <StepButtons
      @previous="previousStep"
      @next="onNext"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { MemberGalleryItem } from "@/models/Member";
import OnboardingText from "../components/OnboardingText.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: { OnboardingTitle, StepButtons, OnboardingText },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  },
  data () {
    return {
      minPictures: 2,
      pictures: [] as MemberGalleryItem[]
    };
  },
  methods: {
    onUpdate (pictures: MemberGalleryItem[]) {
      this.pictures = pictures;
    },
    onNext () {
      if (this.pictures.length < this.minPictures) {
        this.$notify({
          type: Type.warning,
          message: this.$t("you_must_upload_at_least_x_pictures", {x: this.minPictures})
        });
        return;
      }

      this.nextStep();
    }
  }
});
</script>
