<template>
  <ProfileSection v-if="answer && options.length">
    <template #title>
      {{ $t('values') }}
    </template>
    <ProfileSectionBox>
      <ProfileSectionBoxRow>
        <ul class="text-steel-700 font-display space-y-1 text-2xl font-semibold leading-tight">
          <ProfileSectionValue
            v-for="option in options"
            :key="option.id"
            :answer="answer"
            :option="option"
          />
        </ul>
      </ProfileSectionBoxRow>
    </ProfileSectionBox>
  </ProfileSection>
</template>

<script lang="ts">
import { Answer } from "@/models/Answer";
import { Member } from "@/models/Member";
import { QuestionOption } from "@/models/QuestionOption";
import { defineComponent, inject, PropType } from "vue";
import { AnswerWithQuestion } from "../../types";
import ProfileSection from "./ProfileSection.vue";
import ProfileSectionBox from "./ProfileSectionBox.vue";
import ProfileSectionBoxRow from "./ProfileSectionBoxRow.vue";
import ProfileSectionValue from "./ProfileSectionValue.vue";

export default defineComponent({
  components: {
    ProfileSection,
    ProfileSectionBox,
    ProfileSectionBoxRow,
    ProfileSectionValue
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    }
  },
  setup () {
    const answerByName = inject("answerByName") as (name: string) => AnswerWithQuestion|null;

    return {
      answerByName,
    };
  },
  computed: {
    answer () : Answer|null {
      return this.answerByName("values");
    },
    options () :  QuestionOption[] {
      if (!this.answer) {
        return [];
      }
      return this.answer.options;
    }

  }
});
</script>
