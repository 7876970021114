<template>
  <ImageUploader
    button-class="text-primary-500 hover:bg-gray-100 hover:text-primary-600 inline-flex items-center justify-center w-10 h-10 transition-colors duration-150 rounded-full"
    @success="onSuccess"
  >
    <template #default="props">
      <Icon
        v-if="!(loading || props.selecting || props.uploading)"
        icon="heroicons-solid:photograph"
        class="w-6 h-6"
      />
    </template>
    <template #loading="props">
      <LoadingLine
        v-if="loading || props.selecting || props.uploading"
        class="animate-spin text-primary-500 w-5 h-5"
      />
    </template>
  </ImageUploader>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Conversation } from "@/models/Conversation";
import LoadingLine from "@/plugins/icons/LoadingLine.vue";

export default defineComponent({
  components: {
    LoadingLine,
  },
  setup () {
    const conversation = inject("conversation") as Conversation;
    const softFetch = inject("softFetch") as () => void;
    return {
      conversation,
      softFetch
    };
  },
  data () {
    return {
      loading: false,
    };
  },
  methods: {
    onSuccess(key: string) {

      this.loading = true;

      this.$api.post(`conversations/${this.conversation.id}/messages`, {
        type: "image",
        key: key
      })
        .then(() => {
          this.softFetch();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
});
</script>
