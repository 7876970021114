<template>
  <BaseButton
    ref="button"
    type="button"
    :disabled="disabled"
    :loading="loading"
    class="btn-lg flex items-center text-gray-800"
    :style="{paddingTop: '0px', paddingBottom: '0px', paddingLeft: '15px'}"
    :class="{'opacity-50 cursor-not-allowed': member.cant_send_flower_message}"
    @click="sendFlower"
  >
    <FlowerIllustration
      :gender="member.user.gender"
      size="small"
      class="sm:mr-3 w-12 h-12"
    />
    <span class="sm:inline hidden">{{ $t('send_a_flower') }}</span>
  </BaseButton>

  <SendFlowerConfirmation
    v-model="openModal"
    @send="sendRequest"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Member } from "@/models/Member";
import FlowerIllustration from "@/plugins/icons/FlowerIllustration.vue";

export default defineComponent({
  components: {
    FlowerIllustration
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      openModal: false,
    };
  },
  mounted () {
    if (this.member.cant_send_flower_message && this.$refs.button) {

      const message = this.$display.cantSendFlowerMessage(this.member.cant_send_flower_message);

      this.$tippy((this.$refs.button as any).$el as HTMLElement, {
        content: message,
      });
    }
  },
  methods: {
    sendFlower() {

      if (this.member.cant_send_flower_message) {
        return;
      }

      if (this.loading) {
        return;
      }

      this.openModal = true;
    },
    sendRequest () {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api.post("conversations", {
        user_id: this.member.user.id,
        flower: true,
      })
        .then((response: any) => {
          this.$router.push(`/member/messages/${response.data.id}`);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
