<template>
  <div>
    <InputLabel
      v-if="labelValue"
      :label="labelValue"
      :required="required"
    />
    <textarea
      ref="input"
      :value="normalizedModelValue"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :rows="rows"
      class="block mb-0 rounded"
      :class="[{
        'border-red-600': hasError(),
        'border-gray-300': !hasError(),
      }, inputClass]"
      @input="onInput"
    />
    <div class="sm:space-x-3 flex flex-wrap mt-1">
      <p
        v-if="hasError()"
        class="form-input-error grow sm:order-1 order-2"
      >
        {{ errorMessage() }}
      </p>
      <div class="grow sm:w-auto sm:order-2 sm:text-right order-1 w-full text-left">
        <WordCount
          :text="normalizedModelValue"
          :min="min"
          :max="max"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";
import InputLabel from "../components/utils/InputLabel.vue";
import WordCount from "../components/utils/WordCount.vue";
import { get, isString, trim } from "lodash-es";

export default defineComponent({
  name: "VInputTextarea",
  components: { InputLabel, WordCount },
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: [String,null] as PropType<string|null>,
    },
    type: {
      type: String,
      default: "text"
    },
    rows: {
      default: 3,
      type: Number
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    maxlength: {
      default: undefined,
      type: Number,
    },
  },
  computed: {
    normalizedModelValue() : string {
      if (isString(this.modelValue)) {
        return this.modelValue;
      }

      return "";
    }
  },
  methods: {
    onInput(event: Event | null) {

      if (event === null) {
        return;
      }

      let value = get(event, "target.value", null);

      if (!isString(value)) {
        return;
      }

      if (this.maxlength && value.length > this.maxlength) {
        value = value.substring(0, this.maxlength);

        if (this.$refs.input) {
          (this.$refs.input as HTMLInputElement).value = value;
        }
      }

      this.inputListener(value);
    }
  }
});
</script>
