<template>
  <div
    class="md:shadow-lg relative overflow-hidden"
    :class="roundedClass"
  >
    <MemberAvatarWrapper
      :member="member"
      reveal
    >
      <div
        v-if="numberOfSlides > 0"
        class="profile-swiper transition-all"
      >
        <div
          ref="swiper"
          class="swiper"
        >
          <div class="swiper-wrapper">
            <div
              v-for="media in member.gallery"
              :key="media.id"
              class="swiper-slide"
            >
              <div class="aspect-w-8 aspect-h-8 relative">
                <!-- Image -->
                <img
                  :src="$assetUrl(media.base_path, {resize: {width: 400, height: 400}})"
                  alt="Image"
                  class="absolute inset-0 object-cover w-full h-full"
                >
                <!-- Top Gradient -->
                <div class="bg-gradient-to-b from-black opacity-40 absolute top-0 left-0 w-full h-20" />
                <!-- Bottom Gradient -->
                <div class="absolute top-0 left-0 flex flex-col justify-end w-full h-full">
                  <div class="bg-gradient-to-t from-black opacity-40 w-full h-20" />
                </div>

                <!-- Check -->
                <div
                  v-if="media.status == 'portrait' || media.status == 'authenticated'"
                  class="absolute top-0 right-0 flex justify-end"
                >
                  <div class="p-5">
                    <div class="ring-[3px] ring-opacity-30 ring-white box-border flex items-center justify-center w-8 h-8 bg-green-600 rounded-full">
                      <Icon
                        icon="heroicons-solid:check"
                        class="w-6 h-6 text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            ref="pagination"
            class="swiper-pagination"
          />
        </div>
      </div>

      <MemberAvatarPlaceholder
        v-else
        :size="800"
      />
    </MemberAvatarWrapper>
  </div>
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import Swiper from "swiper";
import { defineComponent, PropType } from "vue";
import MemberAvatarPlaceholder from "./components/MemberAvatarPlaceholder.vue";
import MemberAvatarWrapper from "./components/MemberAvatarWrapper.vue";

export default defineComponent({
  components: {
    MemberAvatarPlaceholder,
    MemberAvatarWrapper
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>
    },
    anonymousMode: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      swiper: null as Swiper|null,
      reveal: false,
      roundedClass: "md:rounded-md"
    };
  },
  computed: {
    numberOfSlides () {
      return this.member.gallery.length;
    }
  },
  mounted () {
    this.initSwiper();
  },
  beforeUnmount () {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
  methods: {
    initSwiper() {
      const ref = this.$refs.swiper as HTMLElement|null;
      if (ref) {
        this.swiper = this.$swiper(ref, {
          loop: this.numberOfSlides > 1 ? true : false,
          pagination: {
            el: this.$refs.pagination as HTMLElement,
            type: "bullets",
            clickable: true,
          },
        }) as any;
      }
    }
  }
});
</script>

<style lang="scss">
.profile-swiper {
  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    padding: 8px 16px;
  }
  .swiper-pagination-bullet {
    @apply bg-white;
    @apply rounded;
    @apply shadow-xl;
    width: auto;
    flex-grow: 1;
    height: 8px;
    max-width: 40px;
  }
}
</style>
