<template>
  <QuestionForm
    v-if="question"
    ref="questionForm"
    :question="question"
    :answers="$store.getters['answers']"
    @success="nextStep"
  >
    <template #footer="{completed, skippable, skip}">
      <div class="mt-8">
        <StepButtons
          :skippable="skippable"
          :next-disabled="!completed"
          @previous="previousStep"
          @skip="skip()"
        />
      </div>
    </template>
  </QuestionForm>
</template>

<script lang="ts">
import { Question } from "@/models/Question";
import { defineComponent, inject } from "vue";
import { Step } from "../services/steps";
import StepButtons from "../components/StepButtons.vue";

export default defineComponent({
  components: {
    StepButtons
  },
  setup () {
    const step = inject("currentStep") as Step;
    const previousStep = inject("previousStep") as () => void;
    const nextStep = inject("nextStep") as () => void;

    return {
      step,
      previousStep,
      nextStep,
    };
  },
  computed: {
    question(): Question|null {
      const questionId = this.step.data?.question_id;

      if (!questionId) {
        return null;
      }

      return this.$store.getters["question/listById"][questionId] as Question;
    }
  }
});
</script>
