<template>
  <BaseCard>
    <BaseCardRow>
      <h2 class="mb-5 text-lg font-semibold">
        {{ $t('modify_my_email') }}
      </h2>

      <div class="bg-gray-50 p-5 mb-6 border rounded-md">
        <h3 class="text-md">
          <span class="font-normal">
            {{ $t('your_current_email') }} :
          </span>
          <span class="font-semibold">
            {{ $store.state.user?.email }}
          </span>
        </h3>
      </div>
      <VForm
        :data="form"
        url="account/email"
        method="patch"
        loading-mask-class="bg-opacity-80 bg-white"
        @success="onSuccess"
      >
        <VInputPassword
          v-model="form.password"
          :label="$t('password')"
          :placeholder="$t('password')"
          name="password"
          required
          input-class="w-full"
          class="mb-4"
        />
        <VInput
          v-model="form.email"
          :label="$t('enter_your_new_email_address')"
          :placeholder="$t('enter_email_address')"
          type="email"
          name="email"
          required
          input-class="w-full"
          class="mb-4"
        />
        <VInput
          v-model="form.email_confirmation"
          :label="$t('re_enter_your_new_email_address')"
          :placeholder="$t('re_enter_email_address')"
          type="email"
          name="email_confirmation"
          required
          input-class="w-full"
          class="mb-6"
        />
        <button
          type="submit"
          class="btn xs:w-auto btn-primary w-full"
        >
          {{ $t('update') }}
        </button>
      </VForm>
    </BaseCardRow>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data () {
    return {
      form: {
        password: null,
        email: null,
        email_confirmation: null,
      }
    };
  },
  methods: {
    onSuccess() {
      this.form.password = null;
      this.form.email = null;
      this.form.email_confirmation = null;
      this.$store.dispatch("fetchUser");
    }
  }
});
</script>
