<template>
  <div class="relative">
    <OnboardingTitle>
      {{ $t('step.pictures_video.title') }}
    </OnboardingTitle>

    <ResponsiveIframe
      class="mb-8"
      :url="`https://www.youtube.com/embed/WSacm6XIJoE?hl=${$i18n.locale}&iv_load_policy=3&modestbranding=1`"
    />

    <OnboardingText class="mb-6">
      {{ $t('step.pictures_video.text_1') }}
    </OnboardingText>

    <BaseAlert
      type="info"
      class="mb-10"
    >
      {{ $t('step.pictures_video.text_2') }}
    </BaseAlert>

    <StepButtons
      @previous="previousStep"
      @next="nextStep"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import OnboardingTitle from "../components/OnboardingTitle.vue";
import StepButtons from "../components/StepButtons.vue";
import OnboardingText from "../components/OnboardingText.vue";
import { Step } from "../services/steps";

export default defineComponent({
  components: { OnboardingTitle, StepButtons, OnboardingText },
  setup() {
    const step = inject("currentStep") as Step;
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStep") as () => void;
    return {
      step,
      nextStep,
      previousStep,
    };
  }
});
</script>
