<template>
  <div class="h-10" />
  <div class="bg-gray-50 border-t border-gray-200">
    <div class="relative py-16 overflow-hidden">
      <img
        src="@/assets/img/flowers/flowers-gray-600.svg"
        alt="Flower daily quote"
        class="opacity-10 left-1/2 top-10 absolute h-[150%] -translate-x-1/2"
      >

      <div class="relative">
        <div
          v-if="$store.state.dailyQuote.dailyQuote"
          class="max-w-2xl mx-auto text-center"
        >
          <div

            class="sm:p-12 sm:rounded-xl px-8 py-12 mb-8 bg-white shadow-xl"
          >
            <h2 class="font-display mb-8 text-3xl font-semibold text-gray-900">
              {{ $t('daily_quote') }}
            </h2>

            <q class="font-display text-2xl font-medium text-gray-900">
              {{ $store.state.dailyQuote.dailyQuote.text }}
            </q>

            <p
              v-if="$store.state.dailyQuote.dailyQuote.author"
              class="sm:text-base sm:leading-tight mt-3 text-sm leading-tight text-gray-800"
            >
              - {{ $store.state.dailyQuote.dailyQuote.author }}
            </p>
            <p
              v-else-if="$store.state.dailyQuote.dailyQuote.author_type == 'member' && $store.state.dailyQuote.dailyQuote.member?.user"
              class="sm:text-base sm:leading-tight mt-3 text-sm leading-tight text-gray-800"
            >
              - {{ $store.state.dailyQuote.dailyQuote.member?.user.first_name }}
            </p>
            <p
              v-else-if="$store.state.dailyQuote.dailyQuote.author_type == 'unknown'"
              class="sm:text-base sm:leading-tight mt-3 text-sm leading-tight text-gray-800"
            >
              - {{ $t('unknown_author') }}
            </p>
          </div>
          <router-link
            v-if="$store.state.dailyQuote.dailyQuote.member && $store.state.dailyQuote.dailyQuote.member.user"
            :to="$urls.member($store.state.dailyQuote.dailyQuote.member)"
            class="inline-flex items-center justify-center py-2 pl-3 pr-6 space-x-3"
          >
            <div class="shrink-0 relative w-10 h-10 overflow-hidden rounded-full">
              <MemberAvatar
                :size="50"
                :member="$store.state.dailyQuote.dailyQuote.member"
              />
            </div>
            <div class="text-left">
              <p class="font-semibold leading-tight">
                {{ $t('submitted_by_x', {name: $store.state.dailyQuote.dailyQuote.member.user.first_name}) }}
              </p>
              <p class="text-sm leading-tight text-gray-700">
                {{ $store.state.dailyQuote.dailyQuote.member.user.age }} {{ $t('years_old') }}, {{ $store.state.dailyQuote.dailyQuote.member.user.city }}
              </p>
            </div>
          </router-link>
        </div>

        <div
          v-else
          class="font-display px-4 text-2xl font-semibold leading-tight text-center text-gray-900"
        >
          {{ $t('no_quote_today') }}
        </div>

        <div
          v-if="$store.state.user?.member"
          class="sm:text-base px-5 mt-8 text-sm leading-snug text-center text-gray-900"
        >
          <span class="mr-2">
            {{ $t('daily_quote_cta.title') }}
          </span>
          <router-link
            to="/member/settings/daily-quote"
            class="font-semibold underline"
          >
            {{ $t('daily_quote_cta.button') }}
          </router-link>.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MemberAvatar from "@/views/member/profile/components/sections/components/MemberAvatar.vue";

export default defineComponent({
  components: { MemberAvatar },
  setup() {
    //
  }
});
</script>
