<template>
  <div class="bg-gray-50 pt-safe min-h-screen pb-10">
    <BaseContainer size="3xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.visibility.pause.title')"
        :subtitle="$t('page.settings.menu.visibility.pause.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="3xl"
      paddingless-mobile
    >
      <BaseCard>
        <BaseCardRow>
          <div class="relative">
            <BaseLoadingCover
              v-model="loading"
            />
            <label
              for="about"
              class="block mb-1 text-lg font-semibold leading-5"
            >
              <span>{{ $t('page.settings.pause.title') }}</span>
            </label>

            <p class="mb-5 leading-tight text-gray-600">
              {{ $t('page.settings.pause.description') }}
            </p>

            <BaseSwitch
              v-model="form.pause"
              @update:model-value="onUpdate"
            />
          </div>
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { pauseAccount } from "@/services/pause";

export default defineComponent({
  data() {
    return {
      loading: false,
      form: {
        pause: this.$store.state.user?.member?.paused ?? false
      }
    };
  },
  methods: {
    onUpdate() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      pauseAccount(this.form.pause)
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>
