<template>
  <div>
    <div
      v-if="audio"
      class="flex space-x-2"
    >
      <AudioPlayerStandard
        :audio="audio"
      />
      <BaseButton
        class="btn btn-outline h-[42px] rounded-lg"
        type="button"
        :loading="deleting"
        @click="deleteAudio()"
      >
        {{ $t('delete') }}
      </BaseButton>
    </div>

    <div v-else>
      <div
        class="bg-gray-50 hover:bg-gray-100 relative flex h-16 overflow-hidden border border-gray-200 rounded-lg shadow-sm"
      >
        <AudioRecorderStandard
          :max="MAX"
          content-class="px-5"
          @success="onSuccess"
        />
        <BaseLoadingCover
          :model-value="uploading"
          icon-class="w-5 h-5 text-blue-600"
        />
      </div>

      <div class="flex items-center mt-3 leading-tight text-gray-600">
        <Icon
          icon="heroicons-outline:information-circle"
          class="inline w-5 h-5 mr-1.5 text-gray-500"
        />
        <span>{{ $t('x_seconds_recording_maximum', {x: 15}) }}</span>
      </div>
    </div>

    <slot
      name="footer"
      :deleteAudio="deleteAudio"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const MAX = 15;

export default defineComponent({
  data () {
    return {
      uploading: false,
      deleting: false,
      MAX: MAX,
      audio: null as string|null
    };
  },
  created () {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$api.get("account/member/audio-introduction")
        .then(response => {
          this.audio = response.data.audio_introduction?.url ?? null;
        });
    },
    deleteAudio() {

      if (this.deleting) {
        return;
      }

      this.deleting = true;
      this.$api.delete("account/member/audio-introduction")
        .then(() => {
          this.audio = null;
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    async onSuccess(key: string) {
      this.uploading = true;

      try {

        await this.$api.post("account/member/audio-introduction", {
          audio_introduction: key
        });

        this.fetch();

      } finally {
        this.uploading = false;
      }
    }
  },
});
</script>

