<template>
  <div class="w-full">
    <div class="pt-safe" />
    <div
      v-if="$store.state.status.status"
      class="h-status-mobile shrink-0 w-full"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
