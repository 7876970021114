<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="xs:px-8 rounded-2xl relative px-6 py-8 overflow-hidden">
      <div>
        <h2 class="mb-0.5 text-lg font-semibold leading-tight text-gray-900">
          {{ $t('page.profile.call_init_modal.title', {name: firstName}) }}
        </h2>
        <p class="leading-tight text-gray-500">
          {{ $t('page.profile.call_init_modal.subtitle') }}
        </p>
      </div>

      <hr class="my-6">

      <div class="flex space-x-5">
        <div class="grow">
          <h3 class="mb-0.5 text-base font-semibold leading-tight text-gray-900">
            {{ $t('page.profile.call_init_modal.switch.title', {name: firstName}) }}
          </h3>
          <p class="text-sm leading-tight text-gray-500">
            {{ $t('page.profile.call_init_modal.switch.notice') }}
          </p>
        </div>
        <div class="shrink-0">
          <BaseSwitch
            v-model="authorized"
            @update:model-value="onSwitchUpdate"
          />
        </div>
      </div>

      <hr class="my-6">

      <button
        v-if="authorized && videoChatAuthorized"
        class="btn btn-primary w-full mb-2 truncate"
        @click="call()"
      >
        {{ $t('call_x', {name: firstName.limit(20)}) }}
      </button>
      <div
        v-if="authorized && !videoChatAuthorized"
        class="animate-pulse inline-flex items-center justify-center w-full h-10 px-3 mb-2 text-gray-700 truncate bg-gray-100 rounded"
      >
        {{ $t('pending_names_authorization', {name: firstName.limit(20)}) }}
      </div>

      <button
        class="btn btn-white w-full"
        @click="close()"
      >
        {{ $t('done') }}
      </button>


      <BaseLoadingCover v-model="loading" />
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { debounce } from "lodash";
import { defineComponent, onBeforeUnmount, PropType, ref } from "vue";
import { ConversationUser } from "@/models/Conversation";
import { echo } from "@/services/echo";
import { api } from "@/services/api";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
    conversationId: {
      required: true,
      type: String
    },
    firstName: {
      required: true,
      type: String
    },
    me: {
      required: true,
      type: Object as PropType<ConversationUser>,
    }
  },
  emits: ["update:modelValue", "update"],
  setup (props) {
    const channel = echo.private("user." + props.me.id);
    channel.listen(".conversations.video-chat.authorized", onEventVideoChatAuthorized);

    const videoChatAuthorized = ref(false);

    function onEventVideoChatAuthorized() {
      getVideoChatAuthorization();
    }

    function getVideoChatAuthorization() {
      api.get(`conversations/${props.conversationId}/video-chat/authorized`)
        .then(response => {
          videoChatAuthorized.value = response.data.data.authorized;
        });
    }

    onBeforeUnmount(() => {
      channel.stopListening(".conversations.video-chat.authorized", onEventVideoChatAuthorized);
    });

    getVideoChatAuthorization();

    return {
      channel,
      videoChatAuthorized,
    };
  },
  data () {
    return {
      loading: false,
      authorized: this.me.pivot.authorize_video_chat,
    };
  },
  computed: {
    onSwitchUpdate() {
      return debounce(this.authorize, 300);
    }
  },
  methods: {
    authorize () {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$api_.patch("conversations/" + this.conversationId + "/authorize-video-chat", {
        authorize_video_chat: this.authorized
      })
        .then(() => {
          this.$emit("update", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:modelValue", false);
    },
    call() {
      this.$router.push(`/member/messages/${this.conversationId}/video-chat`);
    },
  }
});
</script>
