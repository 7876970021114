
import { Capacitor } from "@capacitor/core";
import { GoogleAuth } from "@reslear/capacitor-google-auth";

class GoogleClient {

  async init() {
    await GoogleAuth.initialize({
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID_WEB,
      scopes: ["profile", "email"],
      grantOfflineAccess: false,
    });
  }

  async getToken(): Promise<string | null> {
    const user = await GoogleAuth.signIn();

    return user.authentication.idToken;
  }

  clientId(): string {

    // On iOS, the Google ID must be the platform specific client ID
    if (Capacitor.getPlatform() == "ios") {
      return import.meta.env.VITE_GOOGLE_CLIENT_ID_IOS;
    }

    return import.meta.env.VITE_GOOGLE_CLIENT_ID_WEB;
  }

}

const googleClient = new GoogleClient;

export { googleClient };
