<template>
  <div class="w-full overflow-x-auto">
    <div class="flex">
      <WidgetResponseButton
        v-for="response in responses"
        :key="response"
        @click="writeMessage(response)"
      >
        {{ response }}
      </WidgetResponseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Conversation } from "@/models/Conversation";
import WidgetResponseButton from "./WidgetResponseButton.vue";

export default defineComponent({
  components: {
    WidgetResponseButton
  },
  emits: ["writeMessage"],
  setup () {
    const conversation = inject("conversation") as Conversation;
    return {
      conversation,
    };
  },
  data() {
    return {
      responses: [
        this.$t("chat.responses_0"),
        this.$t("chat.responses_1"),
      ]
    };
  },
  methods: {
    writeMessage(message: string) {
      this.$emit("writeMessage", message);
    }
  }
});
</script>
