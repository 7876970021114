<template>
  <BaseButton
    :id="id"
    :type="type"
    :disabled="disabled"
    :loading="loading"
    class="btn btn-lg btn-primary sm:btn-xl xs:w-auto w-full"
    @click="$emit('click')"
  >
    <span>
      {{ normalizedText }}
    </span>
    <Icon
      class="-right-2 sm:inline relative hidden w-5 h-5 text-white"
      icon="mdi:chevron-right"
    />
  </BaseButton>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    type: {
      default: "button",
      type: String as PropType<"submit" | "button" | undefined>
    },
    disabled: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    },
    text: {
      default: "",
      type: String
    },
    id: {
      default: "",
      type: String
    }
  },
  emits: ["click"],
  computed: {
    normalizedText () : string {
      if (this.text) {
        return this.text;
      }
      return this.$t("next");
    }
  }
});
</script>
