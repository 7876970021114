<template>
  <div class="group">
    <div class="relative">
      <component
        :is="url ? 'router-link' : 'div'"
        :to="url"
        class="aspect-w-1 group-hover:shadow-lg aspect-h-1 relative block mb-2 overflow-hidden transition-all duration-200 bg-gray-100 rounded-lg shadow-md"
      >
        <div class="absolute inset-0 w-full h-full">
          <MemberAvatar
            :member="member"
          />
        </div>
      </component>

      <div class="bottom-0 space-x-1.5 h-auto right-2 translate-y-1/2 absolute top-auto left-auto w-auto inline-flex items-center justify-end">
        <div
          v-if="member.audio_introduction_url"
          class="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-lg"
        >
          <button
            type="button"
            class="hover:scale-105 transition-transform appearance-none"
            @click="play"
          >
            <Icon
              icon="heroicons-solid:volume-up"
              class="w-6 h-6 text-gray-600"
            />
          </button>
        </div>
        <LikeButton
          :member="member"
        >
          <template #default="{active, toggleLike}">
            <button
              type="button"
              class="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-lg"
              @click="toggleLike"
            >
              <AnimatedHeart
                :active="active"
                class="w-10 h-10"
              />
            </button>
          </template>
        </LikeButton>
      </div>
    </div>

    <div class="flex mb-2">
      <component
        :is="url ? 'router-link' : 'div'"
        :to="url"
        class="grow block"
      >
        <div
          v-if="member.deleted"
          class="mb-2 text-xl font-semibold leading-tight"
        >
          {{ $t('deleted_user') }}
        </div>
        <div
          v-else-if="member.unavailable"
          class="mb-2 text-xl font-semibold leading-tight"
        >
          {{ $t('unavailable_user') }}
        </div>
        <h2
          v-else
          class="sm:mb-0 mb-1"
        >
          <span class="sm:text-lg mr-1 text-xl font-semibold leading-tight">{{ member.user.first_name }}</span>
          <svg
            v-if="member.user.online"
            viewBox="0 0 100 100"
            class="inline-block w-[9px] h-[9px] text-green-500"
          >
            <g>
              <circle
                fill="currentColor"
                r="50"
                cx="50"
                cy="50"
              />
            </g>
          </svg>
        </h2>

        <div
          v-if="hiddenData"
          class="w-full h-4 mb-3 bg-gray-100 rounded"
        />

        <p
          v-else
          class="mb-3 text-sm leading-none text-gray-600"
        >
          <span class="whitespace-nowrap">{{ member.user.age }} {{ $t('years_old') }}</span> •
          <span class="whitespace-nowrap">{{ member.user.city }}</span>
          <template v-if="member.user.distance">
            • <span class="whitespace-nowrap">{{ $display.publicDistance(member.user.distance) }}</span>
          </template>
        </p>

        <div class="relative mb-2">
          <div
            v-if="hiddenData"
            class="w-full h-10 bg-gray-100 rounded"
          />
          <h3
            v-else
            class="text-base leading-[1.1] text-gray-800"
          >
            “{{ member.title }}”
          </h3>
        </div>

        <p
          v-if="showValues"
          class="text-primary-500 mt-1.5 text-sm font-normal"
          v-html="$tc('x_values_in_common', {count: member.common_values_count ?? 0})"
        />
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import { Member } from "@/models/Member";
import { defineComponent, PropType } from "vue";
import MemberAvatar from "@/views/member/profile/components/sections/components/MemberAvatar.vue";

export default defineComponent({
  components: {
    MemberAvatar
  },
  props: {
    member: {
      required: true,
      type: Object as PropType<Member>,
    },
    displayMode: {
      default: "default",
      type: String
    },
    anonymousMode: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    url () :string|null {
      if (!this.member.can.view) {
        return null;
      }
      return this.$urls.member(this.member);
    },
    hiddenData () : boolean {
      return this.member.deleted || this.member.unavailable;
    },
    showValues () : boolean {
      if (this.hiddenData) {
        return false;
      }
      return this.displayMode == "values" || this.anonymousMode;
    }
  },
  methods: {
    play () {
      if (!this.member.audio_introduction_url) {
        return;
      }
      const audio = new Audio(this.member.audio_introduction_url);
      this.$audio.play(audio);

    },
  },
});
</script>
