/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { auth } from "@/services/auth";
import { getRoute } from "@/services/utils";
import { notificator } from "@/services/notificator";
import { Type } from "@/services/notificator/models/NotificationConfig.interface";
import { tokenStore } from "./auth/tokenStore";
import { echo } from "./echo";
import { get, isObject } from "lodash-es";
import { store } from "@/store";
import i18n from "@/plugins/i18n";

/*
 |--------------------------------------------------------------------------
 | HTTP Request Service
 |--------------------------------------------------------------------------
 */

const http = axios.create({
  withCredentials: true,
});

http.interceptors.request.use(sendLocale);

export { http };

/*
|--------------------------------------------------------------------------
| API Request Service
|--------------------------------------------------------------------------
*/

const api_ = axios.create({
  withCredentials: true,
  baseURL: getRoute("api/app"),
});

api_.interceptors.request.use(sendLocale);
api_.interceptors.request.use(serializeParameters);
api_.interceptors.request.use(addToken);
api_.interceptors.request.use(addSocketId);

export { api_ };

const api = axios.create({
  withCredentials: true,
  baseURL: getRoute("api/app"),
});

api.interceptors.request.use(sendLocale);
api.interceptors.request.use(serializeParameters);
api.interceptors.request.use(addToken);
api.interceptors.request.use(addSocketId);
api.interceptors.response.use(response => response, showNotificationOnError);
api.interceptors.response.use(response => response, logoutOnError);

export { api };

/*
|--------------------------------------------------------------------------
| Interceptors
|--------------------------------------------------------------------------
*/

/**
 * Serialize parameters
 * @param config
 * @returns
 */
export async function sendLocale(config: AxiosRequestConfig<any>) {
  if (config.headers && config.headers["Accept-Language"] === undefined) {
    config.headers["Accept-Language"] = store.state.user?.locale ?? i18n.global.locale;
  }

  return config;
}

/**
 * Serialize parameters
 * @param config
 * @returns
 */
export async function serializeParameters(config: AxiosRequestConfig<any>) {
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: "comma",
      encode: import.meta.env.PROD ? true : false,
    });
  };

  return config;
}

/**
 * Add token
 * @param config
 * @returns
 */
export async function addToken(config: AxiosRequestConfig<any>) {
  if (config.headers) {
    if (auth.strategy == "token") {
      const token = await tokenStore.get();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
  }

  return config;
}

/**
 * Add Socket id
 * @param config
 * @returns
 */
export async function addSocketId(config: AxiosRequestConfig<any>) {
  if (config.headers) {
    config.headers["X-Socket-ID"] = echo.socketId();
  }

  return config;
}

/**
 * Create an error response handler
 */
export async function logoutOnError(error: any) {

  const status = findStatus(error.response);

  // If auth error, redirect to login
  if (status === 419 || status === 401) {
    await auth.setLoggedOut(true);
  }

  // Reject the Promise
  return Promise.reject(error);
}

/**
 * Show error notification
 */
export async function showNotificationOnError(error: any) {

  const message = findMessage(error);

  notificator.notify({
    message: message,
    type: Type.danger
  });

  // Reject the Promise
  return Promise.reject(error);
}

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
|
*/

function findStatus(response: any): number | null {
  return get(response, "status", null) as number | null;
}

function findMessage(error: any): string {
  // Default message
  const defaultMessage = "Whoops! An error has occurred.";

  // No response returned, this may be a big error
  if (!isObject(error.response)) {
    return defaultMessage;
  }

  if (!isObject(error.response.data)) {
    return defaultMessage;
  }

  if (get(error.response.data, "message")) {
    return error.response.data.message;
  }

  if (get(error.response.data, "errors.message")) {
    return error.response.data.errors.message;
  }

  if (get(error.response.data, "error.message")) {
    return error.response.data.error.message;
  }

  return defaultMessage;
}

