<template>
  <div class="bg-gray-50 pt-safe relative min-h-screen pb-20">
    <BaseContainer size="3xl">
      <BaseTitle
        :previous="false"
        :title="$t('page.settings.menu.profile.sample_picture.subtitle')"
        padding-bottom="pb-5"
      />

      <p class="mb-8">
        {{ $t('page.settings.sample_picture.text_1') }}
        <span class="underline">{{ $t('page.settings.sample_picture.text_2') }}</span>
      </p>

      <div class="mb-10">
        <SamplePictureForm />
      </div>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent} from "vue";
export default defineComponent({
});
</script>
