<template>
  <BaseCardRow>
    <VInputSelect
      :model-value="typeValue"
      :name="'secondary_conversation_rules.' + index + '.question_id'"
      input-class="w-full"
      required
      :placeholder="$t('select_a_question')"
      @update:model-value="onTypeUpdate"
    >
      <option
        v-for="option in typeOptions"
        :key="option.value"
        :value="option.value"
        :disabled="isDisabled(option)"
      >
        {{ option.label }}
      </option>
    </VInputSelect>

    <VInputMultiSelect
      v-if="showOptionsSelect"
      :model-value="modelValue.options"
      required
      :name="'secondary_conversation_rules.' + index + '.options'"
      label-key="label"
      value-key="value"
      :wrapper-class="hasManyOptions ? '-m-1.5 justify-center' : '-m-1'"
      :item-class="hasManyOptions ? 'p-1.5 shrink-0 w-1/2 sm:w-1/4' : 'p-1'"
      :button-class="hasManyOptions ? 'w-full shadow h-full' : 'shadow'"
      :options="selectedQuestionOptions"
      @update:model-value="onOptionsUpdate"
    />

    <div
      v-if="showHeightInput"
      class="mt-4"
    >
      <VInputSlider
        :model-value="heightValue"
        required
        :name="'secondary_conversation_rules.' + index + '.value'"
        :min="minHeight"
        :max="maxHeight"
        range
        reversed
        :format="(value: number) => $display.height(value)"
        @update:model-value="onHeightUpdate"
      />
    </div>

    <div
      v-if="modelValue.field == 'age'"
      class="mt-4"
    >
      <VInputSlider
        :model-value="ageValue"
        required
        :name="'secondary_conversation_rules.' + index + '.value'"
        :min="minAge"
        :max="maxAge"
        range
        reversed
        :format="(value: number) => value + ' ' + $t('years_old')"
        @update:model-value="onAgeUpdate"
      />
    </div>

    <div
      v-if="modelValue.field == 'distance'"
      class="mt-4"
    >
      <VInputSlider
        :model-value="distanceValue"
        required
        :name="'secondary_conversation_rules.' + index + '.value'"
        :min="minDistance"
        :max="maxDistance"
        reversed
        :format="(value: number) => $display.distance(value)"
        @update:model-value="onDistanceUpdate"
      />
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div class="mt-6 leading-snug text-gray-600">
      <div
        v-if="modelValue.field == 'age' && ageValue"

        v-html="$t('page.settings.secondary_conversation_rules.summary_age', {
          min: ageValue[0] + ' ' + $t('years_old'),
          max: ageValue[1] + ' ' + $t('years_old'),
        }).markdownToHtml('font-semibold text-gray-900 underline')"
      />

      <div
        v-if="selectedQuestion && selectedQuestion.name == 'height' && heightValue"
        v-html="$t('page.settings.secondary_conversation_rules.summary_height', {
          min: $display.height(heightValue[0]),
          max: $display.height(heightValue[1]),
        }).markdownToHtml('font-semibold text-gray-900 underline')"
      />

      <div
        v-if="modelValue.field == 'distance' && distanceValue"
        v-html="$t('page.settings.secondary_conversation_rules.summary_distance', {
          distance: $display.distance(distanceValue),
        }).markdownToHtml('font-semibold text-gray-900 underline')"
      />

      <div
        v-if="selectedQuestion && selectedOptions.length"
        v-html="$t('page.settings.secondary_conversation_rules.summary_question', {
          answers: $display.list(selectedOptions.map((o: any) => o.data.label), 'or'),
          question: selectedQuestion.text
        }).markdownToHtml('font-semibold text-gray-900 underline')"
      />
    </div>

    <button
      type="button"
      class="mt-4 text-gray-900 underline"
      @click="$emit('remove')"
    >
      {{ $t('remove') }}
    </button>
  </BaseCardRow>
</template>

<script lang="ts">

import { Question } from "@/models/Question";
import { SecondaryConversationRule } from "@/models/SecondaryConversationRule";
import { ConversationRuleRequest, TypeOption } from "./types";
import { Option } from "@/plugins/form/types/types";
import { cloneDeep, isNumber, isString } from "lodash-es";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Object as PropType<ConversationRuleRequest>
    },
    index: {
      required: true,
      type: Number
    },
    secondaryConversationRule: {
      default: null,
      type: Object as PropType<SecondaryConversationRule|null>
    },
    typeOptions: {
      required: true,
      type: Array as PropType<TypeOption[]>
    },
    selectedTypeOptions: {
      required: true,
      type: Array as PropType<TypeOption[]>
    },
    questionPrefix: {
      required: true,
      type: String,
    },
    fieldPrefix: {
      required: true,
      type: String,
    },
  },
  emits: ["update:model-value", "remove"],
  setup () {
    const minHeight = 122;
    const maxHeight = 213;
    const minAge = 18;
    const maxAge = 99;
    const minDistance = 3;
    const maxDistance = 300;

    return {
      minHeight,
      maxHeight,
      minAge,
      maxAge,
      minDistance,
      maxDistance,
    };
  },
  computed: {
    selectedQuestion () : Question|null {
      return this.$store.getters["question/getById"](this.modelValue.question_id) as Question|null;
    },
    selectedQuestionOptions () : Option[] {
      if (!this.selectedQuestion) {
        return [];
      }

      return this.selectedQuestion.options.map(option => {
        return {
          data: {
            value: option.id,
            label: option.label,
          },
          icon_path: option.properties.icon_path,
          iconify: option.properties.iconify,
        };
      });
    },
    selectedOptions() : Option[] {
      return this.selectedQuestionOptions.filter(o => this.modelValue.options.includes(o.data.value));
    },
    hasManyOptions() : boolean {
      return this.selectedQuestionOptions.length > 11;
    },
    showOptionsSelect() : boolean {
      if (!this.selectedQuestion) {
        return false;
      }
      if (this.selectedQuestion.options.length == 0) {
        return false;
      }
      return true;
    },
    showHeightInput() : boolean {
      return this.selectedQuestion?.name == "height";
    },
    typeValue() :string|null {
      if (this.modelValue.question_id) {
        return this.questionPrefix + this.modelValue.question_id;
      }
      if (this.modelValue.field) {
        return this.fieldPrefix + this.modelValue.field;
      }
      return null;
    },
    heightValue() :[number,number] {
      return this.rangeValue(this.minHeight, this.maxHeight);
    },
    ageValue() :[number,number] {
      return this.rangeValue(this.minAge, this.maxAge);
    },
    distanceValue () : number {
      if (isString(this.modelValue.value)) {
        return parseInt(this.modelValue.value);
      }
      if (isNumber(this.modelValue.value)) {
        return this.modelValue.value;
      }

      return this.maxDistance;
    }
  },
  methods: {
    onTypeUpdate(value: string|null) {
      const modelValue = cloneDeep(this.modelValue);

      modelValue.question_id = null;
      modelValue.field = null;
      modelValue.options = [];
      modelValue.value = null;

      if (value?.includes(this.questionPrefix)) {
        modelValue.question_id = value.replace(this.questionPrefix, "");
      }

      if (value?.includes(this.fieldPrefix)) {
        modelValue.field = value.replace(this.fieldPrefix, "");
      }

      if (this.modelValue.field == "height") {
        this.onHeightUpdate(undefined);
      } else if (this.modelValue.field == "age") {
        this.onAgeUpdate(undefined);
      } else if (this.modelValue.field == "distance") {
        this.onDistanceUpdate(undefined);
      }

      this.update(modelValue);
    },
    onOptionsUpdate(options: string[]) {
      const modelValue = cloneDeep(this.modelValue);
      modelValue.options = options;
      this.update(modelValue);
    },
    onHeightUpdate(heightRange: number[]|undefined) {
      this.onRangeUpdate(heightRange, this.minHeight, this.maxHeight);
    },
    onAgeUpdate(ageRange: number[]|undefined) {
      this.onRangeUpdate(ageRange, this.minAge, this.maxAge);
    },
    onDistanceUpdate(distance: number|undefined) {
      const modelValue = cloneDeep(this.modelValue);
      if (distance) {
        modelValue.value = distance;
      } else {
        modelValue.value = this.maxDistance;
      }
      this.update(modelValue);
    },
    rangeValue (min: number, max: number) : [number,number] {
      if (this.modelValue.value == null) {
        return [min, max];
      }
      const range = (this.modelValue.value + "").split(",").map(v => +v);

      if (range.length != 2) {
        return [min, max];
      }

      return range as [number,number];
    },
    onRangeUpdate(range: number[]|undefined, min: number, max: number) {
      const modelValue = cloneDeep(this.modelValue);
      if (range === undefined) {
        modelValue.value = [min, max].join(",");
      } else {
        modelValue.value = range.join(",");
      }
      this.update(modelValue);
    },
    update(modelValue: ConversationRuleRequest) {
      this.$emit("update:model-value", modelValue);
    },
    isDisabled(option: TypeOption) : boolean {
      if (option.value == this.fieldPrefix + this.modelValue.field) {
        return false;
      }
      if (option.value == this.questionPrefix + this.modelValue.question_id) {
        return false;
      }
      return this.selectedTypeOptions.find(t => t.value == option.value) !== undefined;
    }
  }
});
</script>
