class AudioPlayer {

  public audio: HTMLAudioElement | null = null;

  play(audio: HTMLAudioElement): void {

    if (this.audio) {
      this.audio.removeEventListener("canplaythrough", this.onLoad.bind(this));
      this.audio.pause();
    }

    this.audio = audio;

    this.audio.addEventListener("canplaythrough", this.onLoad.bind(this));
  }

  private onLoad() {
    if (this.audio) {
      this.audio.play();
    }
  }
}

export default new AudioPlayer;
export { AudioPlayer };
