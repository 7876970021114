<template>
  <div class="bg-gray-50 pt-safe">
    <BaseContainer size="3xl">
      <BaseTitle
        :previous="false"
        :title="$t('settings')"
        :subtitle="$t('page.settings.subtitle')"
      />
    </BaseContainer>

    <BaseContainer
      size="3xl"
    >
      <router-link
        v-if="$store.state.user?.member"
        :to="`/member/profile/${$store.state.user?.member.id}`"
        class="btn btn-primary btn-lg flex items-center justify-center w-full mb-8"
      >
        <Icon
          icon="heroicons-outline:eye"
          class="w-6 h-6 mr-2"
        />
        <span class="font-semibold">{{ $t('preview_my_profile') }}</span>
      </router-link>
    </BaseContainer>

    <BaseContainer
      size="3xl"
      paddingless-mobile
    >
      <SettingsGroup
        :title="$t('page.settings.menu.profile.title')"
        class="mb-6"
      >
        <SettingsGroupItem
          :to="{'name': 'member.settings.profile'}"
          :title="$t('page.settings.menu.profile.profile.title')"
          :subtitle="$t('page.settings.menu.profile.profile.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:user-circle"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.pictures'}"
          :title="$t('page.settings.menu.profile.pictures.title')"
          :subtitle="$t('page.settings.menu.profile.pictures.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:photograph"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.sample_picture'}"
          :title="$t('page.settings.menu.profile.sample_picture.title')"
          :subtitle="$t('page.settings.menu.profile.sample_picture.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:camera"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
      </SettingsGroup>

      <SettingsGroup
        :title="$t('page.settings.menu.subscription.title')"
        class="mb-6"
      >
        <SettingsGroupItem
          :to="{'name': 'member.subscription'}"
          :title="$t('page.settings.menu.subscription.subscription.title')"
          :subtitle="$t('page.settings.menu.subscription.subscription.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:credit-card"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          v-if="!native"
          :to="{'name': 'member.settings.payment_source'}"
          :title="$t('page.settings.menu.subscription.payment_methods.title')"
          :subtitle="$t('page.settings.menu.subscription.payment_methods.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:credit-card"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
      </SettingsGroup>

      <SettingsGroup
        :title="$t('security')"
        class="mb-6"
      >
        <SettingsGroupItem
          :to="{'name': 'member.settings.blocked_users'}"
          :title="$t('page.settings.menu.security.blocks.title')"
          :subtitle="$t('page.settings.menu.security.blocks.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:ban"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.email'}"
          :title="$t('modify_my_email')"
          :subtitle="$t('modify_my_email_description')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:mail"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.password'}"
          :title="$t('modify_my_password')"
          :subtitle="$t('modify_my_password_description')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:lock-open"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
      </SettingsGroup>

      <SettingsGroup
        :title="$t('page.settings.menu.visibility.title')"
        class="mb-6"
      >
        <SettingsGroupItem
          :to="{'name': 'member.settings.visibility'}"
          :title="$t('page.settings.menu.visibility.visibility.title')"
          :subtitle="$t('page.settings.menu.visibility.visibility.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:eye"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.pause'}"
          :title="$t('page.settings.menu.visibility.pause.title')"
          :subtitle="$t('page.settings.menu.visibility.pause.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:pause"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.anonymous_mode'}"
          :title="$t('page.settings.menu.preferences.anonymous_mode.title')"
          :subtitle="$t('page.settings.menu.preferences.anonymous_mode.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:eye-off"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.secondary_conversation_rules'}"
          :title="$t('page.settings.menu.visibility.inbox.title')"
          :subtitle="$t('page.settings.menu.visibility.inbox.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:shield-check"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
      </SettingsGroup>

      <SettingsGroup
        :title="$t('page.settings.menu.preferences.title')"
        class="mb-6"
      >
        <SettingsGroupItem
          :to="{'name': 'member.settings.notifications'}"
          :title="$t('page.settings.menu.preferences.communications.title')"
          :subtitle="$t('page.settings.menu.preferences.communications.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:mail-open"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.address'}"
          :title="$t('page.settings.menu.preferences.address.title')"
          :subtitle="$t('page.settings.menu.preferences.address.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:home"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.language'}"
          :title="$t('page.settings.menu.preferences.language.title')"
          :subtitle="$t('page.settings.menu.preferences.language.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:translate"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
        <SettingsGroupItem
          :to="{'name': 'member.settings.resources'}"
          :title="$t('page.settings.menu.preferences.resources.title')"
          :subtitle="$t('page.settings.menu.preferences.resources.subtitle')"
        >
          <template #icon>
            <Icon
              icon="heroicons-outline:information-circle"
              class="w-6 h-6"
            />
          </template>
        </SettingsGroupItem>
      </SettingsGroup>
    </BaseContainer>

    <BaseContainer
      size="3xl"
      paddingless-mobile
    >
      <BaseCard class="mb-6">
        <BaseCardRow>
          <h2 class="mb-1 text-lg font-semibold leading-tight">
            {{ $t('page.settings.contact_us.title') }}
          </h2>
          <p class="mb-2 leading-tight text-gray-600">
            {{ $t('page.settings.contact_us.subtitle') }}
          </p>

          <a
            :href="$websiteUrl('contact')"
            class="text-gray-600 underline"
          >
            <Icon
              class="inline mr-1"
              icon="heroicons-solid:external-link"
            />
            <span>{{ $t('contact_us') }}</span>
          </a>
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>

    <BaseContainer
      size="3xl"
      paddingless-mobile
    >
      <BaseCard class="mb-6">
        <BaseCardRow>
          <LogoutButton />
        </BaseCardRow>
      </BaseCard>
    </BaseContainer>

    <BaseFooter />
  </div>
</template>

<script lang="ts">
import { Capacitor } from "@capacitor/core";
import { defineComponent } from "vue";
import SettingsGroup from "./components/SettingsGroup.vue";
import SettingsGroupItem from "./components/SettingsGroupItem.vue";

export default defineComponent({
  components: {
    SettingsGroup,
    SettingsGroupItem,
  },
  data () {
    return {
      native: Capacitor.isNativePlatform()
    };
  }
});
</script>
