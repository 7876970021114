<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition duration-200 ease-in delay-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <LoadingPage v-show="!ready || !viewReady" />
  </transition>
  <router-view
    v-if="ready"
    @loaded="viewReady = true"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, provide, onBeforeUnmount } from "vue";
import LoadingPage from "@/layouts/LoadingPage.vue";
import { SplashScreen } from "@capacitor/splash-screen";
import mq from "./composables/mq";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useRouter } from "vue-router";
import { store } from "./store";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { api_ } from "./services/api";
import { Storage } from "@capacitor/storage";
import { auth } from "./services/auth";
import { Capacitor } from "@capacitor/core";
import { Badge } from "@robingenz/capacitor-badge";

export default defineComponent({
  components: {
    LoadingPage,
  },
  setup() {
    let ready = ref(false);
    let viewReady = ref(false);

    onMounted(() => {
      ready.value = true;
      SplashScreen.hide();
    });

    const { mobile, width, breakpoint } = mq();

    provide("mobile", mobile);
    provide("breakpoint", breakpoint);
    provide("windowWidth", width);

    const router = useRouter();

    App.addListener("appUrlOpen", async (event: URLOpenListenerEvent) => {

      const url = new URL(event.url);

      const urlSearchParams = new URLSearchParams(url.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      const route = {
        path: url.pathname,
        query: params
      };

      let ambassadorCodeReferral = params.ambassador as string | undefined;

      if (params.reference) {
        ambassadorCodeReferral = params.reference;
      }

      if (ambassadorCodeReferral) {
        store.commit("ambassadorCodeReferral", ambassadorCodeReferral);
      }

      // Force re init
      store.commit("initialized", false);

      router.push(route);
    });


    if (Capacitor.isNativePlatform()) {
      // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
        // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration",
        (token: Token) => {
          Storage.set({
            key: "push_notification_token",
            value: token.value
          });

          if (auth.check()) {
            api_.post("account/push-notification-tokens", {
              token: token.value
            });
          }
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError",
        (error: any) => {
          console.error("Error on registration: " + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener("pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          console.log("Push received: " + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener("pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          console.log("Push action performed: " + JSON.stringify(notification));

          // Remove badge count
          Badge.clear();
        }
      );
    }

    onBeforeUnmount(async () => {
      await App.removeAllListeners();
    });

    return {
      ready,
      viewReady,
    };
  }
});
</script>
