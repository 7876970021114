<template>
  <Switch
    :model-value="$store.getters.anonymousMode"
    class="btn btn-white-outline btn-xs flex items-center rounded"
    @update:model-value="updateAnonymousMode"
  >
    <Icon
      v-if="$store.getters.anonymousMode"
      icon="heroicons-solid:eye"
      class="absolute w-6 h-6"
    />
    <Icon
      v-else
      icon="heroicons-solid:eye-off"
      class="absolute w-6 h-6"
    />
    <span class="whitespace-nowrap ml-8 text-sm font-medium">
      <span v-if="$store.getters.anonymousMode">{{ $t('picture_mode') }}</span>
      <span v-else>{{ $t('anonymous_mode') }}</span>
    </span>
  </Switch>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Switch } from "@headlessui/vue";

export default defineComponent({
  components: {
    Switch,
  },
  methods: {
    updateAnonymousMode(value: boolean) {
      this.$store.dispatch("updateAnonymousMode", value);
    }
  }
});
</script>
