<template>
  <transition
    enter-active-class="duration-100 ease-out"
    enter-from-class="translate-y-20 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="duration-100 ease-in"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-20 opacity-0"
  >
    <div
      v-if="scroll > showAfterScroll"
      class="fixed left-0 z-20 w-full"
      :class="{
        'top-0 md:top-menu-desktop-safe': position == 'top',
        'md:bottom-0 bottom-menu-mobile-safe': position == 'bottom',
      }"
    >
      <slot :scroll="scroll" />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

type BaseFixedPosition = "top"|"bottom"

export default defineComponent({
  props: {
    position: {
      default: "top",
      type: String as PropType<BaseFixedPosition>
    },
    showAfterScroll: {
      default: 0,
      type: Number,
    }
  },
  data () {
    return {
      scroll: 0
    };
  },
  created () {
    document.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll () {
      this.scroll = window.scrollY;
    }
  }
});
</script>
