<template>
  <nav class="fixed top-0 left-0 w-full bg-white">
    <StatusBar v-if="$store.state.status" />

    <div class="h-menu-desktop flex flex-col">
      <div
        v-if="!$store.state.status.status"
        class="bg-primary-500 h-1"
      />

      <div class="grow border-b border-gray-200">
        <BaseContainer class="h-full">
          <div class="flex justify-between h-full">
            <div class="lg:px-0 flex px-2">
              <a
                :href="$websiteUrl('/')"
                class="shrink-0 flex items-center"
              >
                <img
                  class="lg:w-52 w-44 block"
                  src="@/assets/img/logo/logo-name-gray.svg"
                  alt="Tandem"
                >
              </a>
              <div class="lg:space-x-7 flex ml-6 -my-px space-x-5">
                <slot />
              </div>
            </div>
            <div class="sm:ml-6 sm:flex sm:items-center hidden">
              <!-- Profile dropdown -->
              <Menu
                as="div"
                class="relative ml-3"
              >
                <div>
                  <MenuButton class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 flex items-center max-w-xs text-sm bg-white rounded-full">
                    <span class="sr-only">Open user menu</span>
                    <img
                      class="w-8 h-8 rounded-full"
                      :src="$assetUrl(avatarPath, {resize: {width: 50, height: 50}})"
                      alt=""
                    >
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition duration-200 ease-out"
                  enter-from-class="scale-95 opacity-0"
                  enter-to-class="scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="scale-100 opacity-100"
                  leave-to-class="scale-95 opacity-0"
                >
                  <MenuItems class="ring-1 ring-black ring-opacity-5 focus:outline-none absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg">
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                      v-slot="{ active }"
                    >
                      <div :class="{'border-b border-gray-200 pb-1 mb-1': item.border}">
                        <component
                          :is="component(item)"
                          :to="item.to ? item.to : undefined"
                          :href="item.href ? item.href : undefined"
                          :type="component(item) == 'button' ? 'button' : undefined"
                          class="block w-full px-4 py-2 m-0 text-sm text-left text-gray-700 no-underline outline-none appearance-none cursor-pointer"
                          :class="[active ? 'bg-gray-100' : 'bg-transparent']"
                          @click="item.action !== undefined ? item.action() : () => {}"
                        >
                          {{ item.name }}
                        </component>
                      </div>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </BaseContainer>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import i18n from "@/plugins/i18n";
import router from "@/router";
import { auth } from "@/services/auth";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { defineComponent } from "vue";
import StatusBar from "./StatusBar.vue";

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    StatusBar
  },
  computed: {
    userNavigation () {

      const links = [];

      if (this.$store.state.user?.member && this.$store.state.user.member.onboarding_completed) {
        links.push({ name: i18n.global.t("settings"), to: "/member/settings", border: true  });
      } else if (this.$store.state.user?.ambassador) {
        links.push({ name: i18n.global.t("refer_your_friends"), to: "/ambassador" });
        links.push({ name: i18n.global.t("settings"), to: "/ambassador/settings", border: true });
      }

      links.push({
        name: "Effet Tandem",
        href: this.$websiteUrl("/"),
      });

      links.push({
        name: this.$t("contact_us"),
        href: this.$websiteUrl("/contact"),
        border: true,
      });

      links.push({
        name: i18n.global.t("logout"),
        action: async () => {
          await auth.logout();
          router.push({ name: "login" });
        }
      });

      return links;
    },
    avatarPath () : string {
      const placeholder = "placeholders/avatar.png";

      if (!this.$store.state.user?.member) {
        return placeholder;
      }

      return this.$store.state.user?.member.avatar_path ?? placeholder;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component (item: any) {
      if (item.to) {
        return "router-link";
      }
      if (item.href) {
        return "a";
      }
      return "button";
    }
  }
});
</script>
