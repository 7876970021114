<template>
  <InfoModal
    :model-value="modelValue"
    video-url="https://www.youtube.com/embed/DMY7goMYcr0"
    :title="$t('send_flower_confirmation.title')"
    :button-text="$t('send')"
    @update:model-value="$emit('update:modelValue', $event)"
    @confirm="send"
  >
    <div class="text-lg leading-tight text-center text-gray-600">
      <p class="">
        {{ $t('send_flower_confirmation.line_1') }}
      </p>
    </div>
  </InfoModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean
    }
  },
  emits: ["update:modelValue", "send"],
  methods: {
    send() {
      this.$emit("update:modelValue", false);
      this.$emit("send");
    }
  }
});

</script>
