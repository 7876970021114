<template>
  <div>
    <div class="relative flex items-start">
      <div class="relative flex items-center mt-0.5">
        <div
          v-if="ping && !modelValue"
          class="border-primary-500 animate-ping absolute w-full h-full border rounded"
        />
        <input
          :id="uuid"
          :aria-describedby="`${uuid}-description`"
          :name="name"
          type="checkbox"
          :checked="modelValue"
          class="text-primary-600 bg-white relative border-[2px] border-gray-500 rounded cursor-pointer"
          :class="{
            'h-4 w-4': size == 'md',
            'h-5 w-5': size == 'lg',
            'focus:ring-primary-500': !ping,
            'focus:ring-0 focus:outline-none': ping,
          }"
          @change="onChange"
        >
      </div>
      <div class="ml-3 text-sm">
        <label
          :for="uuid"
          class="cursor-pointer"
        >
          <p class="font-medium text-gray-700" />
          {{ labelValue }}<span
            v-if="required"
            class="text-red-600"
          > *</span>
          <div
            :id="`${uuid}-description`"
            class="text-gray-600"
          >
            <slot
              v-if="!description"
              name="description"
            />
            <span v-else>
              {{ description }}
            </span>
          </div>
        </label>
      </div>
    </div>

    <p
      v-if="hasError()"
      class="form-input-error mt-2"
    >
      {{ errorMessage() }}
    </p>
  </div>
</template>

<script lang="ts">

import { v4 } from "uuid";
import { defineComponent, PropType } from "vue";
import InputBase from "./InputBase";

export default defineComponent({
  name: "VInputCheckbox",
  extends: InputBase,
  props: {
    modelValue: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    description: {
      default: "",
      type: String
    },
    size: {
      default: "md",
      type: String,
    },
    ping: {
      default: false,
      type: Boolean,
    }
  },
  setup () {
    const uuid = v4();

    return { uuid };
  },
  methods: {
    onChange(e: any) {
      this.inputListener(e.target?.checked ?? false);
    }
  }
});
</script>
