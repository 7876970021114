<template>
  <div>
    <div
      v-show="!showUpdateForm"
      class="bg-gray-50 px-5 py-4 border border-gray-300 rounded-md"
    >
      <p class="mb-2 text-sm font-medium leading-snug text-gray-600">
        {{ $t('search_is_done_from_this_address') }} :
      </p>

      <div class="flex items-start space-x-2">
        <div class="shrink-0 w-8 h-8 flex items-center justify-center bg-gray-200 top-0.5 relative text-gray-500 rounded-full">
          <Icon icon="heroicons-outline:location-marker" />
        </div>
        <p
          v-if="$store.state.user"
          class="font-light leading-tight"
        >
          {{ $store.state.user.address_1 }}, {{ $store.state.user.city }}, {{ $store.state.user.state }}
        </p>
      </div>

      <button
        type="button"
        class="text-light mt-3 text-sm underline"
        @click="showUpdateForm = true"
      >
        {{ $t('update_address') }}
      </button>
    </div>

    <div v-show="showUpdateForm">
      <input
        ref="addressField"
        type="text"
        class="w-full border-gray-300 rounded"
        :placeholder="$t('search_a_new_address')"
        @keydown.enter="onEnter"
      >

      <button
        type="button"
        class="mt-2 text-sm text-gray-700 underline"
        @click="useDefaultAddress()"
      >
        {{ $t('use_my_default_address') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, Ref, ref, defineEmits, PropType, defineProps, watch } from "vue";
import { Filters } from "../../../models/Filters";

const props = defineProps({
  filters: {
    required: true,
    type: Object as PropType<Filters>
  }
});

const showUpdateForm = ref(false);
const addressField = ref(null) as Ref<null|HTMLInputElement>;

// eslint-disable-next-line no-undef
let autocomplete = null as google.maps.places.Autocomplete|null;

const emit = defineEmits(["update"]);

watch(
  () => props.filters.location_label,
  () => {
    updateState();
  }
);

onMounted(() => {

  updateState();

  // eslint-disable-next-line no-undef
  autocomplete = new google.maps.places.Autocomplete(addressField.value as HTMLInputElement, {
    fields: ["address_components", "geometry.location"],
    types: ["address"],
    componentRestrictions: { country: "ca" },
  });

  autocomplete.addListener("place_changed", () => {
    if (!autocomplete) {
      return;
    }
    const place = autocomplete.getPlace();

    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    const label = addressField.value?.value ?? null;

    emit("update", {
      latitude: lat,
      longitude: lng,
      label: label,
    });
  });
});


function updateState() {
  if (props.filters.location_label && props.filters.latitude && props.filters.longitude) {
    if (addressField.value) {
      addressField.value.value = props.filters.location_label + "";
    }
    showUpdateForm.value = true;
  } else {
    if (addressField.value) {
      addressField.value.value = "";
    }
    showUpdateForm.value = false;
  }
}


function useDefaultAddress() {
  showUpdateForm.value = false;
  if (addressField.value) {
    addressField.value.value = "";
  }

  emit("update", null);
}

function onEnter(e: Event) {
  e.preventDefault();
  return;
}

</script>
