<template>
  <label
    class="block"
  >
    <input
      v-model="proxyChecked"
      :value="value"
      type="checkbox"
      class="-left-5 absolute"
    >
    <BaseButton
      :class="[active ? 'btn-steel-outline' : 'btn-white-outline']"
      as="div"
    >
      {{ label }}
    </BaseButton>
  </label>
</template>

<script lang="ts">

import { defineComponent, PropType } from "vue";
import { CheckBoxValue, CheckBoxValues } from "./types";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: Array
    },
    label: {
      required: true,
      type: String
    },
    value: {
      required: false,
      default: undefined,
      type: [String,Number] as PropType<CheckBoxValue>
    },
    active: {
      required: true,
      type: Boolean
    }
  },
  emits: ["update:modelValue"],
  computed: {
    proxyChecked: {
      get() {
        return this.modelValue;
      },
      set(val: CheckBoxValues) {
        this.$emit("update:modelValue", val);
      },
    },
  }
});
</script>
