<template>
  <AuthLayout>
    <h1 class="font-display mt-6 text-3xl font-bold leading-none text-gray-900">
      {{ $t('sign_in') }}
    </h1>

    <div
      v-if="!$route.query.hide_register"
      class="mt-2 leading-tight"
    >
      <h2 class="text-gray-900 mb-0.5 font-medium">
        {{ $t('you_dont_have_an_account') }}  <router-link
          to="/onboarding"
          class="hover:text-gray-500 decoration-gray-300 underline-offset-1 font-normal text-gray-600 underline"
        >
          {{ $t('register_now') }}
        </router-link>
      </h2>
    </div>

    <div class="mt-10">
      <LoginForm
        :email="($route.query.email as string) ?? ''"
        @loaded="initialize()"
      />

      <div class="mt-10 text-[10px] text-gray-400">
        {{ version }}
      </div>
    </div>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AuthLayoutVue from "./AuthLayout.vue";

export default defineComponent({
  components: {
    AuthLayout: AuthLayoutVue,
  },
  emits: ["loaded"],
  data () {
    return {
      version: import.meta.env.PACKAGE_VERSION,
      initialized: false,
    };
  },
  methods: {
    initialize () {
      this.initialized = true;
      this.$emit("loaded");
    }
  }
});
</script>
