<template>
  <div>
    <div class="p-2 border border-gray-300 rounded min-h-[46px]">
      <div class="flex flex-wrap -m-1">
        <div
          v-for="selection in selections"
          :key="selection.value"
          class="p-1"
        >
          <div
            class="flex items-stretch border rounded"
            :class="selectionClass(selection)"
          >
            <div class="py-px pl-3 pr-1">
              {{ selection.label }}
            </div>
            <button
              type="button"
              class="shrink-0 flex items-center justify-center pl-1 pr-3 text-sm bg-transparent border-0 outline-none appearance-none"
              @click="dontLooseFocus($event, () => removeOption(selection))"
              @mousedown="dontLooseFocus"
            >
              ✕
            </button>
          </div>
        </div>
        <div class="grow p-1">
          <input
            ref="input"
            :value="keywords"
            type="text"
            :placeholder="$t('select_an_item')"
            class="h-7 p-0 min-w-[50px] focus:outline-none focus:shadow-none focus:border-none focus:ring-0 w-full border-none shadow-none outline-none"
            @focus="onTextFocus"
            @blur="onTextBlur"
            @input="onTextInput"
            @keydown="onTextKeydown"
          >
        </div>
      </div>
    </div>
    <div class="relative">
      <div
        v-show="showDropdown"
        class="absolute w-full top-1 bg-white border border-gray-300 rounded z-[1] shadow-md p-1 max-h-[214px] overflow-y-auto"
      >
        <ul>
          <li
            v-for="option in filteredOptions"
            :key="option.value"
            class="block"
          >
            <button
              class="focus:outline-none block w-full px-2 py-1 text-left border-none rounded-sm appearance-none cursor-pointer"
              :class="optionClass(option)"
              type="button"
              tabindex="-1"
              @click="dontLooseFocus($event, () => addOption(option))"
              @mousedown="dontLooseFocus"
            >
              {{ option.label }}
            </button>
          </li>
        </ul>
        <div
          v-if="filteredOptions.length == 0"
          class="p-5 text-center text-gray-600"
        >
          {{ $t('no_result') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { get } from "lodash-es";
import { defineComponent } from "vue";
import { NormalizedOption } from "../../types/types";
import BaseMultiSelect from "./BaseMultiSelect";

export default defineComponent({
  extends: BaseMultiSelect,
  data() {
    return {
      timerId: 0 as any,
      keywords: "",
      showDropdown: false,
      selectionIndex: 0,
      selectionToDelete: null as NormalizedOption|null,
    };
  },
  computed: {
    inputElement() : HTMLInputElement {
      return this.$refs.input as HTMLInputElement;
    },
    filteredOptions(): NormalizedOption[] {
      return Object.values(this.normalizedOptions)
        .filter(option => {
          return option.label.toLowerCase().includes(this.keywords.toLowerCase());
        })
        .filter(option => {
          return this.selectionList[option.value] == undefined;
        });
    },
    optionActive(): NormalizedOption | null {
      return this.filteredOptions[Math.min(this.selectionIndex, this.filteredOptions.length - 1)] ?? null;
    },
  },
  methods: {
    dontLooseFocus(event: Event, next: null|(() => void) = null) {
      event.preventDefault();
      this.inputElement.focus();
      if (next) {
        next();
      }
    },
    beforeAddOption() {
      this.selectionToDelete = null;
      this.clearInput();
    },
    afterUpdate() {
      this.validateSelectionIndex();
    },
    clearInput() {
      this.keywords = "";
    },
    onTextFocus() {
      clearTimeout(this.timerId);
      this.showDropdown = true;
    },
    onTextBlur() {
      this.timerId = setTimeout(() => {
        this.showDropdown = false;
      }, 10);
    },
    onTextInput(event: Event) {
      this.selectionIndex = 0;
      this.keywords = get(event, "target.value");
    },
    onTextKeydown(event: KeyboardEvent) {
      const key = event.key;
      if (key === "Backspace" && this.keywords == "") {
        this.attemptRemoveLastSelection();
        return;
      }
      if (key === "ArrowDown") {
        if (this.selectionIndex < this.filteredOptions.length - 1) {
          this.selectionIndex++;
        } else {
          this.selectionIndex = 0;
        }
        return;
      }
      if (key === "ArrowUp") {
        if (this.selectionIndex > 0) {
          this.selectionIndex--;
        } else {
          this.selectionIndex = Math.max(0,this.filteredOptions.length - 1);
        }
        return;
      }
      if (key === "Enter") {
        event.preventDefault();
        if (this.optionActive) {
          this.addOption(this.optionActive);
        }
        return;
      }
    },
    attemptRemoveLastSelection() {
      const optionValueToDelete = this.normalizedModelValue[this.normalizedModelValue.length - 1] ?? null;

      if (this.selectionToDelete && optionValueToDelete && optionValueToDelete == this.selectionToDelete.value) {
        this.removeOption(this.selectionToDelete);
        this.selectionToDelete = null;
        return;
      }

      this.selectionToDelete = this.selectionList[optionValueToDelete] ?? null;
    },
    validateSelectionIndex() {
      // Wait for filteredOptions to update
      this.$nextTick(() => {
        this.selectionIndex = Math.max(
          0,
          Math.min(
            this.selectionIndex,
            this.filteredOptions.length - 1
          )
        );
      });
    },
    selectionClass(selection: NormalizedOption): string {
      if (this.selectionToDelete && this.selectionToDelete.value == selection.value) {
        return "bg-red-200 border-red-300 text-red-800";
      }
      return "bg-gray-200 border-gray-300";
    },
    optionClass(option: NormalizedOption) {
      if (this.optionActive && this.optionActive.value == option.value) {
        return "bg-gray-200 hover:bg-gray-200";
      }
      return "bg-white hover:bg-gray-100";
    }
  }
});
</script>
