<template>
  <div class="p-0.5">
    <span
      ref="button"
      class="inline-block px-2 py-px leading-tight whitespace-pre border rounded"
      :class="{
        'bg-steel-100 font-medium text-steel-900 border-steel-400': active,
        'bg-white text-gray-900 border-gray-200': !active
      }"
    >
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { Answer } from "@/models/Answer";
import { QuestionOption } from "@/models/QuestionOption";
import { defineComponent, inject, PropType } from "vue";

export default defineComponent({
  props: {
    answer: {
      default: null,
      type: [Object,null] as PropType<Answer|null>
    },
    option: {
      default: null,
      type: [Object,null] as PropType<QuestionOption|null>
    }
  },
  setup () {
    const answerOptionInCommon = inject("answerOptionInCommon") as (answer: Answer, option: QuestionOption) => boolean;
    return {
      answerOptionInCommon
    };
  },
  computed: {
    active () :boolean {
      if (!this.answer) {
        return false;
      }
      if (!this.option) {
        return false;
      }
      return this.answerOptionInCommon(this.answer, this.option);
    }
  },
  mounted() {
    if (this.active) {
      this.$tippy(this.$refs.button as HTMLElement, {
        content: this.$t("you_have_this_in_common"),
      });
    }
  }
});
</script>
