<template>
  <component
    :is="status.url ? 'router-link' : 'div'"
    v-if="status"
    :to="status.url"
    class="block w-full shadow-sm"
    :class="{
      'bg-red-600 text-white': status.type == 'danger',
      'bg-yellow-400 text-yellow-900': status.type == 'warning',
      'bg-blue-200 text-blue-800': status.type == 'info',
      'cursor-pointer': status.url
    }"
  >
    <div class="pt-safe" />
    <div class="md:h-status-desktop h-status-mobile flex items-center justify-center px-4 font-semibold">
      <Icon
        icon="heroicons-solid:exclamation-circle"
        class="w-5 h-5 mr-2"
      />
      {{ status?.message }}
    </div>
  </component>
</template>

<script lang="ts">
import { Status } from "@/services/status/models/Status";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    status () : Status|null {
      return this.$store.state.status.status;
    }
  }
});
</script>
