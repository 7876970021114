<template>
  <SectionArticles
    :title="$t('pages.personalDevelopment.video.title')"
    :subtitle="$t('pages.personalDevelopment.video.description')"
    :filters="{
      access_level: 'public,member',
      type: 'video',
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SectionArticles from "./SectionArticles.vue";

export default defineComponent({
  name: "PageArticlesVideo",
  components: { SectionArticles }
});
</script>
