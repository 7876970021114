<template>
  <div>
    <div
      class="flex space-x-6"
      :class="{
        'opacity-30': disabled
      }"
    >
      <div class="grow">
        <h3 class="mb-1 text-base font-medium leading-tight">
          {{ question.text }}
        </h3>
        <AnswerItems
          v-for="(answer, i) in questionAnswers"
          :key="i"
          :question="question"
          :answer="answer"
          :answers="answers"
        />
        <div v-if="questionAnswers.length == 0">
          <p class="text-gray-400">
            —
          </p>
        </div>
      </div>
      <div
        ref="tooltip"
        class="shrink-0 flex"
      >
        <button
          data-cy="edit"
          type="button"
          class="disabled:cursor-not-allowed disabled:hover:bg-gray-100 hover:bg-gray-200 flex items-center justify-center w-10 h-10 text-gray-700 bg-gray-100 border-0 rounded-full outline-none appearance-none"
          :disabled="disabled"
          @click="onClick"
        >
          <Icon
            icon="heroicons-solid:pencil"
            class="w-6 h-6"
          />
        </button>
      </div>
    </div>
    <QuestionModal
      v-model="isOpen"
      :question="question"
      :answers="answers"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Question } from "@/models/Question";
import { Answer } from "@/models/Answer";
import { AnswerList } from "@/store";

export default defineComponent({
  props: {
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    answers: {
      required: true,
      type: Object as PropType<AnswerList>
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    max: {
      default: null,
      type: [Number,null] as PropType<number|null>
    }
  },
  data () {
    return {
      isOpen: false,
      tippy: null as any
    };
  },
  computed: {
    questionAnswers()  : Answer[] {
      const answers = [] as Answer[];

      const mainAnswer = this.getAnswer(this.question);

      if (!mainAnswer) {
        return [];
      }

      answers.push(mainAnswer);

      return answers;
    },
  },
  watch: {
    disabled () {
      this.updateTippy();
    },
    max () {
      this.updateTippy();
    }
  },
  mounted () {
    this.tippy = this.$tippy(this.$refs.tooltip as HTMLElement, {
      content: ""
    });
    this.updateTippy();
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return;
      }
      this.isOpen = true;
    },
    updateTippy () {
      if (this.tippy) {
        if (this.disabled) {
          this.tippy.enable();
          this.tippy.setContent(this.$t("you_can_answer_up_to_x_questions", {x: this.max}));
        } else {
          this.tippy.disable();
        }
      }
    },
    getAnswer(question: Question) : Answer|null {

      const answer = this.answers[question.id] ?? null;

      if (!answer) {
        return null;
      }

      return answer;
    }
  }
});
</script>
