<template>
  <SwitchGroup>
    <div class="inline-flex items-center space-x-3">
      <Switch
        :model-value="modelValue"
        :class="[modelValue ? bg : 'bg-gray-400', 'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ', focus]"
        @update:model-value="update"
      >
        <span
          aria-hidden="true"
          :class="[modelValue ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200']"
        />
      </Switch>
      <SwitchLabel
        v-if="$slots.default"
        class="cursor-pointer"
      >
        <slot />
      </SwitchLabel>
    </div>
  </SwitchGroup>
</template>

<script lang="ts">

import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    modelValue: {
      default: false,
      type: Boolean
    },
    color: {
      default: "primary",
      type: String
    },
    size: {
      default: "base",
      type: String
    }
  },
  emits: ["update:modelValue"],
  computed: {
    bg() {
      if (this.color == "primary") {
        return "bg-primary-500";
      }

      return "bg-gray-500";
    },
    focus() {
      if (this.color == "primary") {
        return "focus:ring-primary-500";
      }
      return "focus:ring-gray-300";
    }
  },
  methods: {
    update(payload: any) {
      this.$emit("update:modelValue", payload);
    }
  }
});
</script>
